import React from 'react';

import { withRouter } from 'next/router';
import { inject } from 'mobx-react';
import Link from 'next/link';
import { routes } from 'env/routes';
export const CoworkerAvatar = inject('appStore')(
  withRouter(
    ({
      appStore,
      router,
      link,
      linkClassName,
      imgClassName,
      user_id,
      coworker_id,
      title,
      size,
    }) => {
      const { business } = appStore;
      const Img = () => (
        <img
          src={
            user_id > 0
              ? `${business.NativeHomeUrlWithLanguage}/user/getavatar/?id=${user_id}&h=${size}&w=${size}&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`
              : `${business.NativeHomeUrlWithLanguage}/coworker/getavatar/?id=${coworker_id}&h=${size}&w=${size}&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`
          }
          alt={title}
          className={imgClassName}
        />
      );
      return link ? (
        <Link
          as={routes.directory_member_by_id({
            Id: coworker_id,
            FullName: title,
          })}
          href={routes.directory_member_by_id('[member_id]')}
        >
          <a className={linkClassName}>
            <Img />
          </a>
        </Link>
      ) : (
        <Img />
      );
    }
  )
);
