import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import asForm from 'ui/components/forms/asForm';
import InputField from 'ui/components/forms/InputField';
import TextAreaInputField from 'ui/components/forms/TextAreaInputField';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import withCustomComponent from 'ui/components/withCustomComponent';
import { hideNewPrivateMessageModal } from './NewPrivateMessage';

export const NewPrivateMessageForm = withCustomComponent(
  'NewPrivateMessageForm'
)(
  asForm(
    inject(
      'appStore',
      'authStore',
      'communityStore'
    )(
      withRouter(
        observer(
          ({
            router,
            newCommunityBoardMessage,
            updateProperty,
            communityStore,
            member,
            authStore,
            appStore,
            componentName,
          }) => {
            const { t } = useTranslation();
            const { isLoggedIn } = authStore;
            const { isSavingNewCommunityBoardMessage } = communityStore;

            const saveNewMessage = () => {
              communityStore
                .saveNewCommunityBoardMessage({
                  isPrivate: true,
                  messageToCoworkerId: member.Id,
                })
                .then(() => {
                  hideNewPrivateMessageModal();
                  appStore.setPopMessage(
                    t('Your message has been sent to {{Salutation}}.', {
                      Salutation: member.Salutation,
                    })
                  );
                })
                .catch((err) =>
                  appStore.setPopMessage(
                    t(
                      'Sorry, your message could not be sent to {{Salutation}}. ' +
                        err,
                      {
                        Salutation: member.Salutation,
                      }
                    )
                  )
                );
            };

            return (
              <>
                {!isLoggedIn && (
                  <>
                    <div
                      className="alert alert--credentials"
                      role="alert"
                      data-component-name={componentName}
                    >
                      <h3 className="h6">
                        {t(
                          'Sign in or join to start a conversation with {{Salutation}}',
                          { Salutation: member.Salutation }
                        )}
                      </h3>
                      <div className="btn-toolbar">
                        <Link
                          href={{
                            pathname: routes.login,
                            query: {
                              returnurl: routes.directory_member_by_id(member),
                            },
                          }}
                        >
                          <a className="btn btn--default btn--sm">
                            {t('Sign in')}
                          </a>
                        </Link>
                      </div>
                    </div>
                  </>
                )}
                {isLoggedIn && (
                  <>
                    <h2
                      className="h4 section__title"
                      data-component-name={componentName}
                    >
                      {t('Start a private conversation with {{Salutation}}', {
                        Salutation: member.Salutation,
                      })}
                    </h2>
                    <div className="your-input">
                      <form
                        className="form your-input__form"
                        id="new-message-form"
                        noValidate
                      >
                        <div className="form-group">
                          <InputField
                            id="newCommunityBoardMessage-subject"
                            label={t('Topic')}
                            name="Subject"
                            placeholder={t('Keep it relevant, less is more...')}
                            errors={
                              communityStore.newCommunityBoardMessageValidation
                                .errors.Subject
                            }
                            value={newCommunityBoardMessage.Subject}
                            onChange={updateProperty}
                          />
                        </div>
                        <div className="form-group">
                          <TextAreaInputField
                            label={t('Message')}
                            name="Message"
                            placeholder={t('Keep it relevant, less is more...')}
                            errors={
                              communityStore.newCommunityBoardMessageValidation
                                .errors.Message
                            }
                            value={newCommunityBoardMessage.Message}
                            onChange={updateProperty}
                          />
                        </div>
                        <div className="form-group">
                          <InputField
                            id="newCommunityBoardMessage-tags"
                            label={t('Tags')}
                            name="Tags"
                            placeholder={t('Keep it relevant, less is more...')}
                            errors={
                              communityStore.newCommunityBoardMessageValidation
                                .errors.Tags
                            }
                            value={newCommunityBoardMessage.Tags}
                            onChange={updateProperty}
                          />

                          <small className="help-block">
                            {t(
                              'Help others find your posts by tagging them. Separate tags by commas (,).'
                            )}
                          </small>
                        </div>
                        <div className="form-actions">
                          <button
                            className="btn btn--default btn--sm"
                            disabled={isSavingNewCommunityBoardMessage}
                            onClick={(ev) => {
                              if (isSavingNewCommunityBoardMessage) return;
                              ev.preventDefault();
                              saveNewMessage();
                            }}
                          >
                            {isSavingNewCommunityBoardMessage ? (
                              <LoadingSpinner />
                            ) : (
                              t('Send Private Message')
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </>
                )}
              </>
            );
          }
        )
      )
    ),
    'newCommunityBoardMessage'
  )
);
