import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import FullPageLayout from 'ui/layouts/FullPageLayout';
import {TourSteps} from 'ui/_pages/tour/TourSteps';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('TourPageLayout')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class TourPageLayout extends Component {
  render() {
    const { step, t, appStore, authStore } = this.props;
    const { business } = appStore;
    const { customer } = authStore;

    return (
      <FullPageLayout
        steps={TourSteps(step, t)}
        title={t('Request a tour of {{Name}}', { Name: t(business.Name) })}
      >
        {this.props.children}
      </FullPageLayout>
    );
  }
}

export default TourPageLayout;
