import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import {routeInfo, routes} from 'env/routes';
import Breadcrumbs from 'ui/components/Breadcrumbs';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import {withRouter} from 'next/router';
import moment from 'moment';
import {action, observable, toJS} from 'mobx';
import * as prices from 'env/utils/prices';
import BooleanInputField from 'ui/components/forms/BooleanInputField';
import FunnelTitle from 'ui/_pages/funnel/Title';

const formData = observable({
  acceptTermsAndConditions: null,
});

let CartSummary = props => {
  const {
    t,
    router,
    appStore,
    authStore,
    planStore,
    invoicingStore,
    checkoutStore,
    validationStore,
  } = props;
  const {isLoggedIn} = authStore;
  const {hasLoadedPricePlans, pricePlans} = invoicingStore;
  const {invoicePreview} = checkoutStore;

  useEffect(() => {
    if (!invoicingStore.hasLoadedPricePlans) {
      invoicingStore.loadPricePlans({
        customer: authStore.customer,
        tariffGuid: planStore.plan.UniqueId,
      });
    }

    checkoutStore.loadInvoicePreview().then((invoice) => {
      if (invoice?.WasSuccessful === false) {
        appStore.setPopMessage(t(invoice.Message));
      } else if (!invoice) {
        checkoutStore.loadBasketInvoice().then(() => {
          checkoutStore.clearBasket();
          router.push(routes.signup_payment);
        });
      }
    }).catch((err) => {
      appStore.setPopMessage(
          t('Sorry, we could not load this page. {{err}}', {err}),
      );
    });

    // Set up validation
    validationStore.setup('planCartSummary', formData);
  }, []);

  const onNext = (e) => {
    e.preventDefault();

    validationStore.validate('planCartSummary');

    if (!validationStore.validation.planCartSummary.isValid) {
      const allErrors = Object.values(validationStore.validation.planCartSummary.errors).
          flat(3).
          map(e => `- ${e}`).
          join('<br/>');

      appStore.setPopMessage(
          t('Sorry, fulfill all required fields') + '<br/><br/>' + allErrors,
      );

      return;
    }

    router.push(routes.store_products({from: 'plans'}));
  };

  const pricePlan = invoicingStore.getPricePlan({id: checkoutStore.selectedPlans[0]});

  if (!(pricePlan && invoicePreview && validationStore.validation.planCartSummary)) {
    return <LoadingLayout/>;
  }

  return (
      <DefaultLayout>
        <main className="content content--custom pt-0" role="main">
          <div className="our-breadcrumbs mb-16 mb-md-48">
            <div className="container">
              <nav aria-label="breadcrumb">
                <Breadcrumbs
                    steps={[
                      {
                        ...routeInfo.plans_plan,
                        route: routeInfo.plans_plan.route(planStore.plan.UniqueId),
                        class: 'done',
                      },
                      {name: routeInfo.store_products.name},
                      {name: routeInfo.checkout.name},
                      /*{name: routeInfo.plans_payment.name},
                      {name: routeInfo.plans_confirmation.name},*/
                    ]}
                />
              </nav>
            </div>
          </div>
          <div className="container">
            <div className="funnel-steps-wrapper">
              <FunnelTitle
                title={pricePlan.Name}
              />
              <div className="funnel-steps-body">
                <div className="row">
                  <div className="col-md-5 col-lg-3 col-xl-2">
                    <div className="mb-20">
                      <img className="img-fluid"
                           src={pricePlan.CfImage_1}
                           alt=""/>
                    </div>
                  </div>
                  <div className="col-lg-9 col-xl-10">
                    <div className="row">
                      <div className="col-md-6 col-lg-6 col-xl-5 mb-16 mb-lg-0">
                        <div className="mb-4">
                          <span>{t('Rental start')}: </span>
                          <span className="text-secondary">
                            {moment(invoicePreview.Lines[0].SaleDate).format('D MMMM YYYY')}
                          </span>
                        </div>
                        <div className="mb-4">
                          <span>{t('Minimum months for trial')}: </span>
                          <span className="text-secondary">
                            {invoicePreview.Lines.length} {t('Months')}
                          </span>
                        </div>
                        <div className="mb-4">
                          <span>{t('Next period')}: </span>
                          <span className="text-secondary">
                            {/*1 Monat, ausser Dezember*/}
                            {moment(invoicePreview.Lines[1].SaleDate).format('D MMMM YYYY')}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6 col-xl-4 ml-auto">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <div>
                            {t('Monthly rent')}
                          </div>
                          <div>
                            {prices.format(prices.excludeTax(pricePlan.Price, pricePlan.TaxRate))}
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <div>
                            {t('VAT')}
                          </div>
                          <div>
                            {prices.format(prices.retrieveTax(pricePlan.Price, pricePlan.TaxRate),
                                {currencyCode: false})}
                          </div>
                        </div>
                        <hr className="my-16"/>
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <div>
                            {t('Monthly rent')}
                          </div>
                          <div>
                            <b>{prices.format(pricePlan.Price)}</b>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="mt-16 mb-20"/>
                    <div className="row">
                      <div className="col-lg-6 col-xl-5 mt-16 mt-lg-0 order-1 order-md-0">
                        <div>
                          <p className="mb-8">
                            {t('Advanced payment')}
                          </p>
                          <div className="funnel-steps-advance-payment-information">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipisicing elit. A animi at consectetur
                              dignissimos
                              distinctio laborum magni modi, nobis nulla obcaecati quod repudiandae temporibus totam
                              ullam
                              unde? Enim fugit ipsum sequi!
                            </p>
Support
￼
immodea-development
1
￼
!
￼￼
Stas2
Plan
Products

                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-xl-4 ml-auto">
                        <div className="d-flex align-items-center justify-content-between mb-16">
                          <div className="funnel-steps-price-total-title">
                            {invoicePreview.Lines.length} {t('Months')}
                          </div>
                          <div className="color funnel-steps-price-total">
                            <strong>
                              {prices.format(invoicePreview.TotalAmount)}
                            </strong>
                          </div>
                        </div>
                        <div className="custom-control custom-checkbox mb-4 fs-14 pl-0">
                          <BooleanInputField
                              id="acceptTermsAndConditions"
                              name="acceptTermsAndConditions"
                              label={t('I agree to the AGB7s and data protection regulations')}
                              labelClassName={'fs-14 text-gray-700'}
                              errors={validationStore.validation.planCartSummary.errors.acceptTermsAndConditions}
                              value={formData.acceptTermsAndConditions}
                              onChange={action((name, value) => {
                                formData[name] = value;
                              })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div className="step-buttons">
          <div className="container">
            <div className="btn-group justify-content-end">
              <Link href="/plans/create-account">
                <a className="btn btn-gray">
                  {t('Cancel booking')}
                </a>
              </Link>
              <a
                  href="#"
                  className="btn"
                  onClick={onNext}
              >
                {t('Continue')}
              </a>
            </div>
          </div>
        </div>
      </DefaultLayout>
  );
};

CartSummary = withCustomComponent('CartSummary')(
    withRouter(
        withTranslation()(
            inject('appStore', 'authStore', 'checkoutStore', 'invoicingStore', 'planStore', 'validationStore')(
                observer(CartSummary),
            ),
        ),
    ),
);

export default CartSummary;