import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { linkTo } from 'env/utils/strings';

export const EventsPageZeroCase = withCustomComponent('EventsPageZeroCase')(
  inject(
    'appStore',
    'eventsStore'
  )(
    withRouter(
      observer(({ router, eventsStore, componentName }) => {
        const { t } = useTranslation();
        const { eventsPage } = eventsStore;
        return (
          eventsPage.CalendarEvents.length == 0 && (
            <div
              data-component-name={componentName}
              className="text-center pt-32"
            >
              <i className="icon-date-availability fs-64 text-gray-600"></i>
              <h3 className="mt-12 mb-24">{t('There are no events to display')}</h3>
              {eventsPage.PastEvents == false && (
                <Link href={linkTo(router, { page: 1, pastEvents: true })}>
                  <a className="btn">
                    {t('Check what you missed')}
                  </a>
                </Link>
              )}
            </div>
          )
        );
      })
    )
  )
);
