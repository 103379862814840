import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import dynamic from 'next/dynamic';

import withCustomComponent from 'ui/components/withCustomComponent';

import { CommunityBoardItemUserImage } from 'ui/_pages/community/list/item/CommunityBoardItemUserImage';
import { CommunityBoardItemTitle } from 'ui/_pages/community/list/item/CommunityBoardItemTitle';
import { CommunityBoardItemMetadata } from 'ui/_pages/community/list/item/CommunityBoardItemMetadata';
import { CommunityBoardItemDropdown } from 'ui/_pages/community/list/item/CommunityBoardItemDropdown';

import { CommunityBoardItemMessage } from 'ui/_pages/community/list/item/CommunityBoardItemMessage';
import { CommunityBoardItemTags } from 'ui/_pages/community/list/item/CommunityBoardItemTags';
import { CommunityBoardListFiles } from 'ui/_pages/community/list/CommunityBoardListFiles';
import { CommunityBoardItemReputation } from 'ui/_pages/community/list/item/CommunityBoardItemReputation';

import { CommunityBoardItemLastMessage } from 'ui/_pages/community/list/item/CommunityBoardItemLastMessage';
import { CommunityBoardItemComment } from 'ui/_pages/community/list/item/CommunityBoardItemComment';

import { CommunityBoardItemParticipantList } from 'ui/_pages/community/list/item/CommunityBoardItemParticipantList';
import { CommunityBoardItemLastUpdatedBy } from 'ui/_pages/community/list/item/CommunityBoardItemLastUpdatedBy';
import { CommunityBoardItemSeeRepliesButton } from './item/CommunityBoardItemSeeRepliesButton';
import { CommunityBoardItemVideoLinks } from './item/CommunityBoardItemVideoLinks';

const ScrollableSection = dynamic(() => import('react-scrollable-url'), {
  ssr: false,
});
export const CommunityBoardItem = withCustomComponent('CommunityBoardItem')(
  inject(
    'appStore',
    'authStore',
    'communityStore'
  )(
    withRouter(
      observer(
        ({
          turndownService,
          communityStore,
          componentName,
          thread,
          threadDetails,
          guests,
          children,
        }) => {
          const { t } = useTranslation();

          return (
            <ScrollableSection
              name={`#thread-${thread.Id}`}
              title={thread.Subject}
            >
              <div
                data-component-name={componentName}
                id={`thread-${thread.Id}`}
                className={`community-board-item ${
                  thread.MutedForUser ? 'community-board-item--muted' : ''
                } ${threadDetails ? 'community-board-item--details' : ''}`}
              >
                <div className="community-board-item__left">
                  <header className="media community-board-item__header">
                    <CommunityBoardItemUserImage thread={thread} />
                    <div className="media-body">
                      <div className="media-body--l">
                        <CommunityBoardItemTitle thread={thread} />
                        <CommunityBoardItemMetadata thread={thread} />
                      </div>
                      <div className="media-body--r">
                        <CommunityBoardItemDropdown thread={thread} />
                      </div>
                    </div>
                  </header>

                  {!thread.MutedForUser && (
                    <>
                      <div className="community-board-item__content">
                        <CommunityBoardItemVideoLinks thread={thread} />
                        <CommunityBoardItemMessage
                          turndownService={turndownService}
                          thread={thread}
                          threadDetails={threadDetails}
                        />
                        <CommunityBoardItemTags thread={thread} />
                        <CommunityBoardListFiles thread={thread} />
                        <CommunityBoardItemReputation
                          guests={guests}
                          thread={thread}
                        />
                      </div>
                      <footer className="community-board-item__footer">
                        {children ? (
                          <>
                            {children}
                            {thread.MessageCount > thread.Messages?.length && (
                              <CommunityBoardItemSeeRepliesButton
                                thread={thread}
                              />
                            )}
                          </>
                        ) : (
                          <CommunityBoardItemLastMessage
                            thread={thread}
                            turndownService={turndownService}
                          />
                        )}
                        <CommunityBoardItemComment
                          thread={thread}
                          newCommunityBoardReply={
                            communityStore.newCommunityBoardReply
                          }
                        />
                      </footer>
                    </>
                  )}
                </div>

                {threadDetails != true && !thread.MutedForUser && (
                  <div className="community-board-item__right">
                    <aside className="community-board-list-item__aside">
                      <CommunityBoardItemParticipantList thread={thread} />
                      <CommunityBoardItemLastUpdatedBy thread={thread} />
                    </aside>
                  </div>
                )}
              </div>
            </ScrollableSection>
          );
        }
      )
    )
  )
);
