import { lowerFirstLetter } from 'env/utils/strings';
import { action } from 'mobx';
import Agent from 'env/agent';
import { addTagManagerEvent } from 'env/TagManager';
import { getStores } from './stores';

const loadDataHelper = ({
  store,
  agentKey,
  key,
  path,
  params,
  onDataReceived,
}) => {
  var agent = new Agent(store.host);
  store[`isLoading${key}Lock`] = true; //this prop is not observable, no need to wrap in action
  if (params) store[`hasLoaded${key}`] = false;
  store[`isLoading${key}`] = true;

  return agent[agentKey][`get${key}`](params)
    .then(
      action((data) => {
        if (onDataReceived) onDataReceived(data);

        const returnData = path ? data[path] : data;

        if (!onDataReceived) store[lowerFirstLetter(key)] = returnData;
        return returnData;
      })
    )
    .finally(
      action(() => {
        store[`isLoading${key}`] = false;
        store[`hasLoaded${key}`] = true;
        store[`isLoading${key}Lock`] = false;
      })
    );
};

const saveDateHelper = ({
  store,
  agentKey,
  key,
  data,
  validation,
  validationSchema,
  modelToValidate,
}) => {
  var agent = new Agent(store.host);
  if (validation) {
    const validationKey = `${lowerFirstLetter(key)}Validation`;
    const toValidate = modelToValidate || store[lowerFirstLetter(key)];
    store[validationKey] = validation
      .reset()
      .validate(toValidate, validationSchema);
    if (!store[validationKey].isValid) {
      const allErrors = Object.values(store[validationKey].errors)
        .map((e) => `- ${e}`)
        .flat(3)
        .join('<br/>');

      return Promise.reject(allErrors);
    }
  }

  //Prevent the same request being fired again while on for the same store and is running
  if (store[`isSaving${key}Lock`] === true) return Promise.reject();
  store[`isSaving${key}Lock`] = true; //this prop is not observable, no need to wrap in action

  store[`isSaving${key}`] = true;
  var dataToSubmit = data || store[lowerFirstLetter(key)];
  return agent[agentKey][`save${key}`](dataToSubmit)
    .then(
      action((data) => {
        if (!data) return data;
        if (data.ErrorMessage) return Promise.reject(data.ErrorMessage);
        return data;
      })
    )
    .finally(
      action((data) => {
        const stores = getStores();
        if (stores) {
          addTagManagerEvent({
            name: `nexudus:saved:${agentKey}.${key}`,
            customer: stores.authStore.customer,
          });
        }

        store[`isSaving${key}`] = false;
        store[`isSaving${key}Lock`] = false;
        return data;
      })
    );
};

export { saveDateHelper, loadDataHelper };
