import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import {privateRoute} from 'env/privateRoute';
import {withRouter} from 'next/router';
import {withCustomComponent} from 'ui/components/withCustomComponent';
import {TeamDetailsForm} from 'ui/_pages/profile/TeamDetailsForm';
import {TeamMemberPermissionsList} from 'ui/_pages/profile/TeamMemberPermissionsList';
import {TeamInviteLink} from 'ui/_pages/profile/TeamInviteLink';
import Head from 'next/head';

@withCustomComponent('ProfileTeamPage')
@privateRoute
@withRouter
@withTranslation()
@inject('appStore', 'authStore', 'bookingsStore')
@observer
class ProfileTeamPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {authStore, t, appStore} = this.props;

    if (!authStore.hasLoadedCustomer)
      authStore.loadCustomer().catch(() => null);

    authStore.loadTeamProfiles().catch((err) =>
      appStore.setPopMessage(
        t('Sorry, we could not load your current teams.', {err}),
      ),
    );
  }

  saveTeam(team) {
    const {authStore, t, appStore} = this.props;
    authStore.saveTeamProfile(team).catch((err) =>
      appStore.setPopMessage(
        t('Sorry, we could not save your team details.', {err}),
      ),
    );
  }

  render() {
    const {t, appStore, authStore} = this.props;
    const {customer} = authStore;
    const {hasLoadedCustomer, hasLoadedTeamProfiles} = authStore;
    const hasLoadedData = hasLoadedCustomer && hasLoadedTeamProfiles;

    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={`${t('My teams')} - ${t(appStore.business.Name)}`}
      >
        <main className="content content--custom pt-0" role="main">
          {!hasLoadedData && (
            <form id="profile-form">
              <fieldset className="section--settings" id="your-team">
                <legend className="h3 section__title">
                  <LoadingSpinner/>
                </legend>
              </fieldset>
            </form>
          )}
          {hasLoadedData && (
            <>
              <div className="portal-title-wrapper">
                <div className="container portal-title-container">
                  <h1 className="portal-title">
                    {t('Team')}
                  </h1>
                </div>
              </div>
              <div className="container">
                {authStore.teamProfiles.length === 0 && (
                  <div className="card card-dashboard mb-32">
                    <div className="alert alert-info fs-14">
                      {t('You are part of the following teams: {{TeamNames}}', {
                        TeamNames: customer.TeamNames.split(',').join(', '),
                      })}
                    </div>
                  </div>
                )}
                {authStore.teamProfiles.map((profile, i) => (
                  <form id="profile-form" key={i}>
                    <h3>{profile.Team.Name}</h3>
                    <TeamMemberPermissionsList
                      team={profile.Team}
                      members={profile.Team.AllTeamMembers.sort((a, b) =>
                        a.FullName.localeCompare(b.FullName),
                      )}
                      saveTeam={() => this.saveTeam(profile.Team)}
                    />

                    <TeamInviteLink team={profile.Team}/>

                    <TeamDetailsForm
                      saveTeam={() => this.saveTeam(profile.Team)}
                      team={profile.Team}
                    />
                  </form>
                ))}
              </div>
            </>
          )}
        </main>
      </DefaultLayout>
    );
  }
}

export default ProfileTeamPage;
