import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

import CardEvent from 'ui/components/cards/CardEvent';

export const EventsPageList = withCustomComponent('EventsPageList')(
  inject(
    'appStore',
    'eventsStore'
  )(
    withRouter(
      observer(({ router, componentName, eventsStore }) => {
        const { t } = useTranslation();
        const { eventsPage } = eventsStore;

        return (
          <ul
            data-component-name={componentName}
            className="list-grid list-grid--50 list-grid--lg-100"
          >
            {eventsPage.CalendarEvents.map((event, i) => (
              <li key={i}>
                <CardEvent event={event} sizeMedium={true} />
              </li>
            ))}
          </ul>
        );
      })
    )
  )
);
