import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import { privateRoute } from 'env/privateRoute';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { withRouter } from 'next/router';
import Link from 'next/link';
import { routes } from 'env/routes';
import { HelpDeskMessageDetails } from 'ui/_pages/support/HelpDeskMessageDetails';
import { ReplyCount } from 'ui/_pages/support/ReplyCount';
import { HelpDeskCommentsList } from 'ui/_pages/support/HelpDeskCommentsList';
import { NewSupportMessageReplyForm } from 'ui/_pages/support/NewSupportMessageReplyForm';

import { withCustomComponent } from 'ui/components/withCustomComponent';

@withCustomComponent('SupportMessagePage')
@privateRoute
@withTranslation()
@withRouter
@inject('appStore', 'supportStore')
@observer
class SupportMessagePage extends Component {
  componentDidMount() {
    const { appStore, router, supportStore } = this.props;
    var id = router.query.message_id;
    supportStore.loadMessage(id).catch((err) => {
      appStore.setPopMessage(
        t('Sorry, we could not load this page. {{err}}', { err })
      );
    });
  }
  render() {
    const { t, appStore, supportStore } = this.props;
    const { business } = appStore;
    const { reply, hasLoadedMessage, message: messageContainer } = supportStore;

    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={t('Request help - {{Name}}', { Name: t(business.Name) })}
      >
        <main className="content content--custom">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-1 d-none d-xl-block">
                <Link href={routes.support}>
                  <a className="btn btn-single-icon btn-white">
                    <i className="icon-go-back"></i>
                  </a>
                </Link>
              </div>
              <div className="col-12 col-xl-10">
                {!hasLoadedMessage && <LoadingSpinner />}
                {hasLoadedMessage && (
                  <>
                    <div className="card h-auto">
                      <HelpDeskMessageDetails
                        messageContainer={messageContainer}
                      />
                    </div>
                    <div className="card h-auto pl-16 pl-md-24 pr-16 pr-md-24 pb-24 pt-4 mt-24">
                      <ReplyCount message={messageContainer.Message} />
                      <HelpDeskCommentsList
                        message={messageContainer.Message}
                      />
                      <div
                        style={{
                          cursor: !messageContainer.Message.Closed
                            ? null
                            : 'not-allowed',
                          opacity: !messageContainer.Message.Closed
                            ? '100%'
                            : '33%',
                        }}
                      >
                        <NewSupportMessageReplyForm
                          reply={reply}
                          message={messageContainer.Message}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default SupportMessagePage;
