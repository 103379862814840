import React from 'react';
import {observer, inject} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import LanguageBar from 'ui/components/LanguageBar';

const LanguageSection = withCustomComponent('LanguageSection')(
  inject('appStore')(
    observer(({router, appStore, componentName}) => {
      const {t} = useTranslation();

      return (
        <div className="card-item-wrapper">
          <div className="card-item-header">
            <h5 className="card-item-header-title">
              {t('Language')}
            </h5>
          </div>
          <div className="card-item-body">
            <LanguageBar/>
          </div>
        </div>
      );
    })
  )
);

export default LanguageSection;
