import React, {Component} from 'react';
import {observer, inject} from 'mobx-react';
import _ from 'lodash';
import {withRouter} from 'next/router';
import {withTranslation} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import {routes} from 'env/routes';
import ActiveLink from 'ui/components/ActiveLink';

@withCustomComponent('ProductsHeader')
@withTranslation()
@withRouter
@inject('appStore', 'invoicingStore')
@observer
class ProductsHeader extends Component {
  render() {
    const {t, componentName, invoicingStore} = this.props;
    const {allProductsByGroup} = invoicingStore;

    return (
      <div data-component-name={componentName}>
        <div className="portal-title-wrapper">
          <div className="container portal-title-container">
            <h1 className="portal-title">
              {t('Products and services')}
            </h1>
          </div>
        </div>
        {allProductsByGroup.length > 0 && (
          <>
            <div className="container">
              <div className="meetings-header">
                <div className="list-inline-32 sm-column">
                  <div className="d-flex align-items-center w-sm-100">
                <span className="text-gray-700 fs-14 mw-sm-64px mr-24 mr-md-12">
                  {t('Category:')}
                </span>
                    <select
                      onChange={(ev) =>
                        invoicingStore.setProductFilterTag(ev.target.value)
                      }
                      name="type"
                      className="custom-select custom-select-sm w-sm-100"
                    >
                      <option value="">{t('All')}</option>
                      {allProductsByGroup.map((group, i) => {
                        if (group.name !== 'no-tag') {
                          return <option key={i} value={group.name}>{t(group.name)}</option>
                        }
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default ProductsHeader;
