import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import moment from 'moment';
import { withRouter } from 'next/router';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import { NoAccessNotice } from 'ui/components/NoAccessNotice';

import TeamProfilePageHeader from 'ui/_pages/directory/teams/TeamProfilePageHeader';
import { TeamProfilePageBody } from 'ui/_pages/directory/teams/TeamProfilePageBody';
import TeamProfilePageSide from 'ui/_pages/directory/teams/TeamProfilePageSide';
import { routes } from 'env/routes';

@withRouter
@withCustomComponent('DirectorTeamProfileDetailsPage')
@withTranslation()
@inject('appStore', 'communityStore', 'authStore')
@observer
class DirectorTeamProfileDetailsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    const { router, t, appStore, communityStore } = this.props;
    const $ = window.$;

    communityStore
      .loadTeamPage(router.query)
      .then(this.initTwitter)
      .catch((err) => {
        console.log('Sorry, we could not load this page:', err);
        this.setState({
          error: t('Sorry, we could not load this page. {{err}}', { err: '' }),
        });
      });
  }

  initTwitter = () => {
    //Twitter
    !(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0],
        p = /^http:/.test(d.location) ? 'http' : 'https',
        el = d.getElementById(id);

      //if (el) el.parentNode.removeChild(el);

      js = d.createElement(s);
      js.id = id;
      js.src = p + '://platform.twitter.com/widgets.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'twitter-wjs');
  };

  render() {
    const { t, communityStore, appStore } = this.props;
    const { business } = appStore;
    const { teamPage } = communityStore;
    if (!teamPage || !teamPage.Team) return <LoadingLayout />;
    const team = teamPage.Team;

    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={t('Company Profile - {{TeamName}} - {{Name}}', {
          TeamName: team.Name,
          Name: t(business.Name),
        })}
      >
        <main className="content content--custom" role="main">
          <div className="container">
            <div className="mb-20">
              <Link href={routes.directory_teams}>
                <a className="btn btn-icon btn-white">
                  <i className="icon-go-back"></i>
                  {t('Back')}
                </a>
              </Link>
            </div>
            <NoAccessNotice error={this.state.error} />
            <div className="row">
              <div className="col-xl-9">
                <TeamProfilePageHeader team={team} />
                <TeamProfilePageBody team={team} />
              </div>
              <div className="col-xl-3">
                <hr className="mt-40 mb-32 d-block d-xl-none" />
                <TeamProfilePageSide team={team} />
              </div>
            </div>
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default DirectorTeamProfileDetailsPage;
