import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { routes } from 'env/routes';
import Link from 'next/link';
import { removeHtml } from 'env/utils/strings';
import withCustomComponent from 'ui/components/withCustomComponent';
import CardDashboard from 'ui/components/cards/CardDashboard';
import LoadingWidget from 'ui/_pages/home/components/LoadingWidget/LoadingWidget';

@withCustomComponent('DiscountsWidget')
@withTranslation()
@inject('appStore', 'contentStore')
@observer
class DiscountsWidget extends Component {
  componentDidMount() {
    const { contentStore } = this.props;
    contentStore.loadCommunityPerks();

    const $ = window.$;
    $('[data-toggle="tooltip"]').tooltip('dispose');
    $('[data-toggle="tooltip"]').tooltip({
      html: true,
    });
  }

  render() {
    const { t, componentName, contentStore, appStore } = this.props;
    const { hasLoadedCommunityPerks, communityPerks } = contentStore;
    const perks = _.take(communityPerks, 3);

    if (!hasLoadedCommunityPerks)
      return <LoadingWidget className="" title={t('Perks and benefits')} />;

    if (!perks.length) return null;

    return (
      <CardDashboard
        headerMainTitle={t('Perks and benefits')}
        footerActionTitle={t('View all')}
        footerActionUrl={routes.perks}
        componentName={componentName}
      >
        {perks.length > 0 ? (
          <div className="dashboard-discounts-widget d-flex align-items-center justify-content-around my-16 h-100">
            {perks.map((perk, i) => (
              <Link
                href={routes.perk_by_id()}
                as={routes.perk_by_id(perk)}
                key={i}
              >
                <a
                  className="btn btn-discount"
                  data-toggle="tooltip"
                  title={removeHtml(perk.Title)}
                  key={i}
                >
                  <span
                    className="img"
                    style={{
                      backgroundImage: `url(${appStore.business.NativeHomeUrlWithLanguage}/communityperks/GetImage?id=${perk.Id}&w=64&h=64&cache=${perk.UpdatedOn})`,
                    }}
                  ></span>
                  <span className="badge">
                    <i className="icon-discount"></i>
                  </span>
                </a>
              </Link>
            ))}
          </div>
        ) : (
          <p className="text-gray-700 mt-16">
            {t('You have no perks or discounts available.')}
          </p>
        )}
      </CardDashboard>
    );
  }
}
export default DiscountsWidget;
