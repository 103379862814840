import React from 'react';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { CardCourse } from 'ui/_pages/courses/CardCourse';

export const CoursesPageList = withCustomComponent('CoursesPageList')(
  inject(
    'appStore',
    'coursesStore'
  )(
    withRouter(
      observer(({ componentName, coursesStore, courses, isMyCourses }) => {
        if(!courses) {
          courses = coursesStore.coursesPage.Courses;
        }

        return (
          <ul
            data-component-name={componentName}
            className="list-grid list-grid--50 list-grid--lg-100"
          >
            {courses.map((courseContainer, i) => (
              <li key={i}>
                <CardCourse courseContainer={courseContainer} sizeMedium={true} isMyCourse={isMyCourses}/>
              </li>
            ))}
          </ul>
        );
      })
    )
  )
);
