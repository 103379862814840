import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import DatePicker from 'react-widgets/DatePicker';
import moment from 'moment';
import i18next from 'i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import { Localization } from 'react-widgets/cjs';

@withCustomComponent('DateInputField')
@withTranslation()
@observer
class DateInputField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      touched: false,
      isCalendarOpen: false,
      isFocused: false,
      // the following handles the calendar toggling twice when
      // clicking the calendar icon and entering focus at the same time
      hasJustFocused: false,
    };
  }

  componentDidMount() {
    const input = this.props;

    if (input.autocomplete) {
      $('.rw-widget-picker .rw-widget-input').attr(
        'autocomplete',
        input.autocomplete
      );
    }
  }

  onChange = (date) => {
    this.setState({
      touched: true,
      isCalendarOpen: false,
    });

    if (!date) {
      this.props.onChange(this.props.name, null);
      return;
    }
    const input = this.props;
    let currentDate = input.value ?? new Date();
    let clone = moment(currentDate).clone().toDate();
    if (input.showTimeSelect) {
      clone.setMinutes(date.getMinutes());
      clone.setHours(date.getHours());
    } else {
      clone.setYear(date.getFullYear());
      clone.setMonth(date.getMonth());
      clone.setDate(date.getDate());
    }

    this.props.onChange(this.props.name, clone);
  };

  onFocus = () => {
    this.setState({
      touched: true,
      isCalendarOpen: true,
      isFocused: true,
      hasJustFocused: true,
    });
  };

  render() {
    const input = this.props;
    const { t } = this.props;
    const hasErrors = input.errors && input.errors.length > 0;
    const { touched } = this.state;

    const dateIconElement = (
      <i
        className='icon-icon-calendar fs-24 gray-new-primary'
        onClick={(e) => {
          e.stopPropagation();
          if (!this.state.hasJustFocused && this.state.isFocused) {
            this.setState({ isCalendarOpen: !this.state.isCalendarOpen });
          } else {
            this.setState({ hasJustFocused: false });
          }
        }}
      ></i>
    );

    return (
      <div
        className={`form-group ${
          input.className ? input.className : ''
        } custom-datepicker date`}
      >
        {input.label && (
          <label className='control-label' id={input.id}>
            {input.label}
          </label>
        )}

        <DatePicker
          aria-labelledby={input.id}
          step={15}
          messages={{
            moveToday: t('Today'),
            moveForward: t('Move forward'),
            moveForward: t('Move backward'),
          }}
          value={
            input.value && moment(input.value).isValid
              ? moment(input.value).toDate()
              : null
          }
          onChange={(value) => {
            this.onChange(value);
          }}
          onSelect={() => this.setState({ isCalendarOpen: false })}
          onFocus={this.onFocus}
          onBlur={() =>
            this.setState({ isCalendarOpen: false, isFocused: false })
          }
          open={this.state.isCalendarOpen}
          containerClassName={`form-control ${
            this.state.isFocused ? 'focused' : ''
          }`}
          /*
          Raises the error
          date={input.showTimeSelect != true}*/
          selectIcon={dateIconElement}
          disabled={input.disabled == true}
          {...this.props.customProps}
        ></DatePicker>

        {hasErrors && (
          <div className='help-block validation-error'>{input.errors.join(', ')}</div>
        )}
        {input.helpText && (
          <small class='form-text text-muted'>{input.helpText}</small>
        )}
      </div>
    );
  }
}

DateInputField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default DateInputField;
