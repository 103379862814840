import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation, Trans } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import InputField from 'ui/components/forms/InputField';
import BooleanInputField from 'ui/components/forms/BooleanInputField';
import asForm from 'ui/components/forms/asForm';
import NxMentionsInput from 'ui/components/forms/NxMentionsInput/NxMentionsInput';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import SelectField from 'ui/components/forms/SelectField';
import ScrollableAnchor from 'react-scrollable-anchor';
import withCustomComponent from 'ui/components/withCustomComponent';

export const CommunityBoardReplyForm = withCustomComponent(
  'CommunityBoardReplyForm'
)(
  asForm(
    inject(
      'appStore',
      'authStore',
      'communityStore'
    )(
      withRouter(
        observer(
          ({
            thread,
            updateProperty,
            newCommunityBoardReply,
            appStore,
            componentName,
            communityStore,
          }) => {
            const { t } = useTranslation();

            const { isSavingNewCommunityBoardReply } = communityStore;
            const { isSignalRConnected } = appStore;

            const saveNewMessage = () => {
              communityStore
                .saveNewCommunityBoardReply(thread.Id)
                .catch((err) =>
                  appStore.setPopMessage(
                    t('Sorry, your message could not be sent. ' + err)
                  )
                );
            };

            return (
              <form novalidate data-component-name={componentName}>
                <div className="form-group">
                  <NxMentionsInput
                    label={t('Message')}
                    name="Message"
                    placeholder={t(
                      "Invite other people to this conversation by mentioning them: type the '@' symbol followed by their name."
                    )}
                    errors={
                      communityStore.newCommunityBoardReplyValidation.errors
                        .Message
                    }
                    value={newCommunityBoardReply.Message}
                    onChange={updateProperty}
                    onKeyDown={(ev) => {
                      if (
                        (ev.keyCode == 10 || ev.keyCode == 13) &&
                        (ev.ctrlKey || ev.metaKey)
                      ) {
                        if (isSavingNewCommunityBoardReply) return;
                        ev.preventDefault();
                        saveNewMessage();
                      }
                    }}
                  />
                  <small
                    className="help-block validation-error"
                    data-bind="validationMessage: message"
                  ></small>
                  <p className="help-block">
                    {t(
                      "Invite other people to this conversation by mentioning them: type the '@' symbol followed by their name."
                    )}
                  </p>
                </div>
                <div className="form-actions">
                  <button
                    className="btn btn--default btn--sm"
                    disabled={
                      !isSignalRConnected || isSavingNewCommunityBoardReply
                    }
                    onClick={(ev) => {
                      if (!isSignalRConnected) return;
                      if (isSavingNewCommunityBoardReply) return;
                      ev.preventDefault();
                      saveNewMessage();
                    }}
                  >
                    {isSavingNewCommunityBoardReply ? (
                      <LoadingSpinner />
                    ) : !isSignalRConnected ? (
                      t('Connecting...')
                    ) : (
                      t('Send reply')
                    )}
                  </button>
                </div>
              </form>
            );
          }
        )
      )
    ),
    'newCommunityBoardReply'
  )
);
