import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import moment from 'moment';
import { withRouter } from 'next/router';

@withRouter
@withCustomComponent('LegacyEventCatchAllPage')
@withTranslation()
@observer
class LegacyEventCatchAllPage extends Component {}

LegacyEventCatchAllPage.getInitialProps = ({ asPath, query, res }) => {
  if (res) {
    res.writeHead(302, {
      Location: asPath.replace(
        `/events/view/${query.event_id}/${query.slug}`,
        `/events/${query.event_id}/${query.slug}`
      ),
    });
    res.end();
  }

  return {};
};

export default LegacyEventCatchAllPage;
