import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { EventTicketsPageTicketsListItem } from 'ui/_pages/events/tickets/EventTicketsPageTicketsListItem';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';
export const EventTicketsPageTicketsList = withCustomComponent(
  'EventTicketsPageTicketsList'
)(
  inject(
    'appStore',
    'authStore',
    'eventsStore'
  )(
    withRouter(
      observer(({ authStore, router, appStore, eventsStore }) => {
        const { t } = useTranslation();
        const { business } = appStore;
        const { eventTicketsPage } = eventsStore;
        const event = eventTicketsPage.Event;

        const total = _.sumBy(event.EventProducts, (p) =>
          p.Quantity * p.Price
        );

        return (
          <div className="bg-gray-100 rounded p-16 p-md-24">
            <h5 className="mb-24">{t('Choose your tickets')}</h5>
            {event.EventProducts.map((product, i) => (
              <EventTicketsPageTicketsListItem key={i} product={product} />
            ))}
            {total > 0 &&
              <div className="mt-24 d-flex align-items-center justify-content-between">
                <div className="fs-14 text-gray-700">
                  {t('Total')}
                </div>
                <h4 className="mb-0 pl-16">
                  <LocalizedPrice
                    amount={total}
                    currency={business.Currency.Code}
                  />
                </h4>
              </div>
            }
          </div>
        );
      })
    )
  )
);
