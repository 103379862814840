import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import {action, observable} from 'mobx';
import {withTranslation} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import {withRouter} from 'next/router';
import {routeInfo, routes} from 'env/routes';
import authStore from 'env/stores/authStore';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import Breadcrumbs from 'ui/components/Breadcrumbs';
import moment from 'moment';
import 'react-image-lightbox/style.css';
import PlanDetails from 'ui/_pages/plan/PlanDetails';

const formData = observable({
  startDate: moment().startOf('day').toDate(),
  // Disable plan T&C
  //agreedTc: false,
});

let Plan = props => {
  const {
    router,
    t,
    authStore,
    appStore,
    planStore,
    invoicingStore,
    checkoutStore,
    validationStore,
  } = props;
  const {business, configuration} = appStore;
  const {isLoggedIn} = authStore;
  const planGuid = router.query.guid;
  const {selectedPlans} = checkoutStore;
  const [pricePlan, setPricePlan] = useState(invoicingStore.getPricePlan({guid: planGuid}));

  useEffect(() => {
    let cancel = false;

    // Load Price plan
    if (!invoicingStore.hasLoadedPricePlans) {
      invoicingStore.loadPricePlans({
        customer: authStore.customer,
        tariffGuid: planGuid,
      }).then(data => {
        if (cancel) return;

        const pricePlan = data.PricePlans.find(item => item.UniqueId === planGuid);

        // Remember the plan
        planStore.plan.Id = pricePlan.Id;
        planStore.plan.UniqueId = pricePlan.UniqueId;

        setPricePlan(invoicingStore.getPricePlan({guid: planGuid}));
      });
    }

    // Load products
    invoicingStore.loadStoreProducts({selectedPlans});

    // Set up validation
    validationStore.setup('planOverview', formData);

    return () => {
      cancel = true;
    };
  }, []);

  // Update basket on any update
  useEffect(() => {
    addToBasket();
  }, [pricePlan]);

  const addToBasket = () => {
    if (!pricePlan || !formData.startDate) {

      return;
    }

    checkoutStore.addToBasket({
      type: 'contract',
      data: {
        tariff: pricePlan,
        startDate: formData.startDate,
      },
    });
  };

  const onNext = (e) => {
    e.preventDefault();

    validationStore.validate('planOverview');

    if (!validationStore.validation.planOverview.isValid) {
      const allErrors = Object.values(validationStore.validation.planOverview.errors).flat(3).map(e => `- ${e}`).join('<br/>');

      appStore.setPopMessage(
        t('Sorry, fulfill all required fields') + '<br/><br/>' + allErrors,
      );

      return;
    }

    addToBasket();

    let url = routes.plans_create_account;

    if (isLoggedIn) {
      url = invoicingStore.storeProducts.products.length ? routes.store_products({from: 'plans'}) : routes.checkout;
    }

    router.push(url);
  };

  if (!(pricePlan && invoicingStore.hasLoadedStoreProducts && validationStore.validation.planOverview)) {
    return <LoadingLayout/>;
  }

  return (
    <DefaultLayout
      title={t('Product - {{Name}}', {Name: t(business.Name)})}>
      <main className="content content--custom pt-0" role="main">
        <div className="our-breadcrumbs mb-16 mb-md-48">
          <div className="container">
            <nav aria-label="breadcrumb">
              <Breadcrumbs
                steps={[
                  routeInfo.plans_plan,
                  ...!isLoggedIn ? [{name: routeInfo.plans_create_account.name}] : [],
                  ...invoicingStore.storeProducts.products.length > 0 ? [{name: routeInfo.store_products.name}] : [],
                  {name: routeInfo.checkout.name},
                ]}
              />
            </nav>
          </div>
        </div>
        <div className="container">
          <div className="funnel-steps-wrapper">
            <PlanDetails
              service={pricePlan}
              isLoading={false}
              startDate={formData.startDate}
              design="modern"
              showStartDate
              hideButton
              showGallery
              displayAgreements
              onChangeDate={action(value => {
                formData.startDate = value || moment().startOf('day').toDate();
              })}
              onAgreedTc={action(value => {
                formData.agreedTc = value;
              })}
            />
          </div>
        </div>
      </main>
      <div className="step-buttons">
        <div className="container">
          <div className="btn-group justify-content-end">
            <a
              href="#"
              className="btn btn-gray"
              onClick={e => {
                e.preventDefault();
                router.back();
              }}
            >
              {t('Back')}
            </a>
            <a
              href="#"
              className="btn"
              onClick={onNext}
            >
              {t('Next')}
            </a>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

Plan = withCustomComponent('Plan')(
  withRouter(
    withTranslation()(
      inject('appStore', 'authStore', 'invoicingStore', 'planStore', 'checkoutStore', 'validationStore')(
        observer(Plan),
      ),
    ),
  ),
);

export default Plan;