import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import moment from 'moment';
import { withRouter } from 'next/router';
import { routes } from 'env/routes';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { withCustomComponent } from 'ui/components/withCustomComponent';
import { removeHtml } from 'env/utils/strings';

import { TeamRelatedProfile } from 'ui/_pages/directory/teams/TeamRelatedProfile';

@withCustomComponent('TeamProfilePageSide')
@withRouter
@withTranslation()
@inject('appStore')
@observer
class TeamProfilePageSide extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidMount() { }

  render() {
    const { t, componentName, appStore, team } = this.props;

    // BB-TODO: Get similar teams from api
    const relatedTeams = [];

    return (
      <div
        data-component-name={componentName}
        className="directory-team-profile-page-side"
      >

        {team.HomeSpaceName &&
          <div className="card location">
            <h5>{t('Location')}</h5>
            <div className="d-flex align-item-center">
              <i className="icon-business-spaces fs-24 text-gray-600 mr-8"></i>
              <span>{team.HomeSpaceName}</span>
            </div>
          </div>
        }

        {(team.ProfileWebsite ||
          team.Twitter ||
          team.Linkedin ||
          team.Facebook ||
          team.Skype) &&
          <div className="card connect">
            <h5>{t('Connect')}</h5>
            <ul>
              {team.ProfileWebsite && (
                <li>
                  <a
                    target="_blank"
                    href={team.ProfileWebsite}
                  >
                    <i className="icon-external-link"></i>
                    <span>{team.ProfileWebsite}</span>
                  </a>
                </li>
              )}
              {team.Twitter && (
                <li>
                  <a
                    target="_blank"
                    href={`http://twitter.com/${team.Twitter}`}
                  >
                    <i className="icon-social-twitter"></i>
                    <span>{t('Twitter')}</span>
                  </a>
                </li>
              )}
              {team.Linkedin && (
                <li>
                  <a
                    target="_blank"
                    href={team.Linkedin}
                  >
                    <i className="icon-social-linkedin"></i>
                    <span>{t('LinkedIn')}</span>
                  </a>
                </li>
              )}
              {team.Facebook && (
                <li>
                  <a
                    target="_blank"
                    href={team.Facebook}
                  >
                    <i className="icon-social-facebook"></i>
                    <span>{t('Facebook')}</span>
                  </a>
                </li>
              )}
              {team.Skype && (
                <li>
                  <a
                    target="_blank"
                    href={`callto://${team.Skype}`}
                  >
                    <i className="icon-skype"></i>
                    <span>{t('Skype')}</span>
                  </a>
                </li>
              )}
            </ul>
          </div>
        }

        {(relatedTeams && relatedTeams.length > 0) &&
          <div className="card similar-profiles">
            <h5>{t('Similar companies')}</h5>
            {relatedTeams.map((relatedTeam, j) => (
              <TeamRelatedProfile key={j} team={relatedTeam} className="mb-16" />
            ))}
          </div>
        }

        {team.Twitter &&
          <div className="card twitter">
            <h5>
              {t('{{Salutation}} on Twitter', {
                Salutation: removeHtml(team.Name),
              })}
            </h5>
            <a
              className="twitter-timeline"
              href={`https://twitter.com/@${team.Twitter.replace(
                '@',
                ''
              )}`}
              data-tweet-limit="2"
              data-widget-id="430399305858285569"
              data-chrome="noheader nofooter transparent noborders"
              data-screen-name={team.Twitter.replace('@', '')}
              data-show-replies="false"
            >
              Tweets by @{team.Twitter.replace('@', '')}
            </a>
          </div>
        }

      </div>
    );
  }
}
export default TeamProfilePageSide;
