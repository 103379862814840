import React, { Component } from 'react';
import Link from 'next/link';
import ActiveLink from 'ui/components/ActiveLink';
import { routes } from 'env/routes';
import withCustomComponent from 'ui/components/withCustomComponent';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { linkTo } from 'env/utils/strings';

@withCustomComponent('DirectoryHeader')
@withTranslation()
@withRouter
@inject('appStore', 'communityStore')
@observer
class DirectoryHeader extends Component {
  handleSearchBySelectChange = (e) => {
    e.preventDefault();
    const { router } = this.props;

    switch (e.target.name) {
      case 'type':
        router.push(
          linkTo(router, {
            onlyNew: null,
            onlyAdmins: null,
            onlyCheckedIn: null,
            [e.target.value]: e.target.value == 0 ? null : true,
          })
        );
        break;
      case 'tag':
        router.push(
          linkTo(router, {
            page: 1,
            tag: e.target.value == 0 ? null : e.target.value,
          })
        );
        break;
      default:
        router.push(
          linkTo(router, {
            page: 1,
            [e.target.name]: e.target.value == 0 ? null : e.target.value,
          })
        );
    }
  };

  render() {
    const {
      topTags,
      customFields,
      t,
      componentName,
      router,
      appStore,
      communityStore,
      showType = true,
    } = this.props;
    const { configuration } = appStore;
    const { membersDirectory, teamsDirectory } = communityStore;

    const members = membersDirectory.AllMembersByName || [];
    const membersCount = members.length > 0 ? members.length : '';

    const teams = teamsDirectory.TeamsByName || [];
    const teamsCount = teams.length > 0 ? teams.length : '';

    const doSearch = (searchTerm) => {
      router.push(`${router.pathname}?query=${encodeURIComponent(searchTerm)}`);
    };

    return (
      <div data-component-name={componentName} className="mb-32">
        <div className="d-block d-md-flex align-items-center">
          {configuration['Directory.DirectoryRecords'] !== '2' && (
            <ActiveLink href={routes.directory_members} activeOnRootPath>
              <a className="btn btn-link btn-op-02 align-items-baseline tdn mr-24 mr-md-32">
                <h2 className="mb-0 mr-6">{t('Members')}</h2>
                {/* <h5 className="mb-0 d-none d-md-block">{membersCount}</h5> */}
              </a>
            </ActiveLink>
          )}

          {configuration['Directory.DirectoryRecords'] !== '3' && (
            <ActiveLink href={routes.directory_teams} activeOnRootPath>
              <a className={`btn btn-link btn-op-02 align-items-baseline tdn`}>
                <h2 className="mb-0 mr-6">{t('Companies')}</h2>
                {/* <h5 className="mb-0 d-none d-md-block">{teamsCount}</h5> */}
              </a>
            </ActiveLink>
          )}
        </div>

        <div className="mt-20">
          <ul className="list-inline-32 sm-column">
            {showType && (
              <li className="d-flex align-items-center w-sm-100">
                <label
                  className="text-gray-700 fs-14 mr-12 mw-sm-64px"
                  htmlFor="select-directory-type"
                >
                  {t('Type')}:
                </label>
                <select
                  name="type"
                  id="select-directory-type"
                  value={
                    router.query.onlyNew
                      ? 'onlyNew'
                      : router.query.onlyAdmins
                      ? 'onlyAdmins'
                      : router.query.onlyCheckedIn
                      ? 'onlyCheckedIn'
                      : 0
                  }
                  onChange={this.handleSearchBySelectChange}
                  className="custom-select custom-select-sm w-sm-100"
                >
                  <option value="0">{t('All')}</option>
                  <option value="onlyNew">{t('New Members')}</option>
                  <option value="onlyAdmins">{t('Staff')}</option>
                  <option value="onlyCheckedIn">{t('Checked in')}</option>
                </select>
              </li>
            )}
            {topTags && (
              <li className="d-flex align-items-center w-sm-100" id="top-tags">
                <label
                  className="text-gray-700 fs-14 mr-12 mw-sm-64px"
                  htmlFor="select-skills"
                >
                  {t('Skills')}
                </label>
                <select
                  name="tag"
                  id="select-skills"
                  value={router.query.tag ? router.query.tag : 0}
                  onChange={this.handleSearchBySelectChange}
                  className="custom-select custom-select-sm w-sm-100"
                >
                  <option value="0">{t('All')}</option>
                  {topTags.map(
                    (tag, i) =>
                      tag.Tag &&
                      tag.Tag !== 'null' && (
                        <option key={i} value={tag.Tag}>
                          {tag.Tag.charAt(0).toUpperCase() + tag.Tag.slice(1)}
                        </option>
                      )
                  )}
                </select>
              </li>
            )}

            {customFields && (
              <>
                {customFields.map((cf, index) => (
                  <li
                    className="d-flex align-items-center w-sm-100"
                    id={`custom-field-${cf.FieldName}`}
                    key={index}
                  >
                    {cf.FieldType === 'Dropdown' && (
                      <>
                        <label
                          className="text-gray-700 fs-14 mr-12 mw-sm-64px"
                          htmlFor={`select-custom-field-${cf.FieldName}`}
                        >
                          {cf.NameInSearch}:
                        </label>
                        <select
                          name={cf.FieldName}
                          id={`select-custom-field-${cf.FieldName}`}
                          value={
                            router.query[cf.FieldName]
                              ? router.query[cf.FieldName]
                              : 0
                          }
                          onChange={this.handleSearchBySelectChange}
                          className="custom-select custom-select-sm w-sm-100"
                        >
                          <option value="0">{t('All')}</option>
                          {cf.AvailableOptionArray.map((opt, i) => (
                            <option key={i} value={opt}>
                              {opt}
                            </option>
                          ))}
                        </select>
                      </>
                    )}
                    {cf.FieldType === 'Boolean' && <></>}
                  </li>
                ))}
              </>
            )}

            <li className="ml-auto w-sm-100">
              <div className="d-flex align-items-center justify-content-between bg-white rounded p-4 fs-14 shadow-sm">
                <input
                  id="query"
                  className="w-100 border-0 outline-0"
                  defaultValue={router.query.query}
                  placeholder={t('Search...')}
                  onKeyDown={(ev) => {
                    if (ev.keyCode == 10 || ev.keyCode == 13) {
                      ev.preventDefault();
                      doSearch(ev.target.value);
                    }
                  }}
                />
                <button
                  className="btn btn-xs py-0 fs-18 btn-gray"
                  onClick={(e) => {
                    e.preventDefault();
                    doSearch(document.getElementById('query').value);
                  }}
                >
                  <i className="icon-search"></i>
                </button>
              </div>
            </li>
          </ul>
        </div>

        <hr className="mt-20 mb-0" />
      </div>
    );
  }
}
export default DirectoryHeader;
