import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const RotateScreenAlert = withCustomComponent('RotateScreenAlert')(
  inject('appStore')(
    withRouter(
      observer(({ router, appStore, componentName }) => {
        const { t } = useTranslation();

        return (
          <div
            data-component-name={componentName}
            className="alert alert-danger fs-14 mt-24 mt-md-0 d-block d-md-none"
          >
            <strong>{t('Heads up!')}</strong>{' '}
            {t(
              'Rotate your device to a horizontal position to see the full calendar.'
            )}
          </div>
        );
      })
    )
  )
);
