import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import moment from 'moment';
import { withRouter } from 'next/router';

@withRouter
@withCustomComponent('LegacyCatchAllPage')
@withTranslation()
@observer
class LegacySignupProductsCatchAllPage extends Component {}

LegacySignupProductsCatchAllPage.getInitialProps = ({ asPath, query, res }) => {
  if (res) {
    res.writeHead(302, {
      Location: `/signup/products?product_id=${query.productid}`,
    });
    res.end();
  }

  return {};
};

export default LegacySignupProductsCatchAllPage;
