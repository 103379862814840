import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { BasketItem } from 'ui/_pages/checkout/basketItems/BasketItem';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';
import moment from 'moment';

export const InvoiceLineBasketItem = withCustomComponent('ProductBasketItem')(
  inject(
    'appStore',
    'checkoutStore'
  )(
    withRouter(
      observer(({ router, basketItem, checkoutStore, appStore }) => {
        const { t } = useTranslation();
        const data = basketItem;
        const { business } = appStore;

        return (
          <BasketItem
            basketItem={basketItem}
            showRemoveButton={false}
            id={data.IdString}
            header={`${data.Quantity}x ${data.Description}`}
            times={
              data.TariffName
                ? t('Starting on: {{startDate}}', {
                    startDate: moment(
                      checkoutStore.invoicePreview.InvoiceFromDate
                    ).format('LL'),
                  })
                : ''
            }
            total={
              <>
                <LocalizedPrice
                  currency={business.Currency.Code}
                  amount={data.SubTotal}
                />
              </>
            }
          />
        );
      })
    )
  )
);
