import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import { withRouter } from 'next/router';
import { removeHtml } from 'env/utils/strings';
import { LessonPageMetaData } from 'ui/_pages/courses/lesson/LessonPageMetaData';
import { LessonPageDetails } from 'ui/_pages/courses/lesson/LessonPageDetails';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { LessonMetaTags } from 'ui/_pages/courses/lesson/LessonMetaTags';
import LessonNavigationMenu from 'ui/_pages/courses/lesson/LessonNavigationMenu';
import { routes } from 'env/routes';
import Link from 'next/link';
import GraduationModal from 'ui/_pages/courses/GraduationModal';
import { LessonPageComments } from 'ui/_pages/courses/lesson/LessonPageComments';

@withCustomComponent('LessonDetailsPage')
@withRouter
@withTranslation()
@inject('appStore', 'authStore', 'coursesStore')
@observer
class LessonDetailsPage extends Component {
  componentDidMount() {
    this.loadLesson();
    this.loadLessons();
    this.loadCourse();
  }

  loadLesson = () => {
    const { t, appStore, router, coursesStore } = this.props;
    const { Lesson } = coursesStore.lessonPage;

    const courseId = router.query.course_id;
    const lessonId = router.query.lesson_id;

    if (!Lesson || Lesson.Id != lessonId) {
      coursesStore.loadLessonPage(courseId, lessonId).catch((err) => {
        appStore.setPopMessage(
          t('Sorry, we could not load this page. {{err}}', { err })
        );
      });
    }
  };

  loadLessons = () => {
    const { t, appStore, router, coursesStore } = this.props;
    const { Lessons } = coursesStore.lessonsPage;

    if (!Lessons) {
      coursesStore.loadLessonsPage(router.query.course_id).catch((err) => {
        appStore.setPopMessage(
          t('Sorry, we could not load this page. {{err}}', { err })
        );
      });
    }
  };

  loadCourse = () => {
    const { t, appStore, router, coursesStore } = this.props;
    const { coursePage } = coursesStore;

    const courseId = router.query.course_id;
    if (coursePage.Course.Id != courseId) {
      coursesStore.loadCoursePage(courseId).catch((err) => {
        appStore.setPopMessage(
          t('Sorry, we could not load this page. {{err}}', { err })
        );
      });
    }
  };

  componentDidUpdate() {
    this.loadLesson();
    this.loadLessons();
    this.loadCourse();
  }

  render() {
    const { router, t, coursesStore, appStore } = this.props;
    const { business } = appStore;
    const { Course, CommunityGroup } = coursesStore.coursePage;
    const lesson = coursesStore.lessonPage.Lesson;
    const lessons = coursesStore.lessonsPage.Lessons;
    const isLoading =
      !lesson ||
      lesson.Id != router.query.lesson_id ||
      !lessons ||
      !coursesStore.coursePage.CanAccessCourse;

    if (!isLoading && !coursesStore.coursePage.CanAccessCourse) {
      router.push(routes.invoices);
    }

    return (
      <>
        <LessonMetaTags />

        <GraduationModal />

        <DefaultLayout
          componentName={this.props.componentName}
          title={t('{{LessonTitle}} - {{Name}}', {
            LessonTitle:
              !isLoading && lesson ? removeHtml(lesson.Title) : '...',
            Name: t(business.Name),
          })}
        >
          <main className="content content--custom lesson-details" role="main">
            <div className="container">
              {!isLoading && (
                <div className="mb-20">
                  <Link
                    href={routes.course_by_id('[course_id]')}
                    as={routes.course_by_id(Course)}
                  >
                    <a className="btn btn-icon btn-white">
                      <i className="icon-go-back"></i>
                      {t('Back')}
                    </a>
                  </Link>
                </div>
              )}

              <div className="row">
                <div className="col-lg-4 lesson-details__sticky-nav">
                  {!isLoading && <LessonNavigationMenu />}

                  {!isLoading && Course.HasCommunityGroup && (
                    <div className="card h-auto p-16 p-md-24 mb-24 view-discussion">
                      <div className="row align-items-center justify-content-between">
                        <div>
                          <h5 className="help-text font-weight-bold">
                            {t('Need help?')}
                          </h5>
                        </div>

                        <div>
                          <Link
                            href={routes.forum_by_group('[group_id]')}
                            as={routes.forum_by_group(CommunityGroup.Id)}
                          >
                            <a className="btn">
                              <i className="icon icon-my-discussion-board ml-4 mr-8"></i>
                              {t('View Course Discussion')}
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-lg-8">
                  {isLoading && <LoadingSpinner />}
                  {!isLoading && (
                    <>
                      <div className="card h-auto">
                        <LessonPageMetaData
                          videosContainer={this.videosContainer}
                        />
                        <LessonPageDetails
                          videosContainer={this.videosContainer}
                        />
                      </div>

                      <LessonPageComments />
                    </>
                  )}

                  <div style={{ marginBottom: 250 }}></div>
                </div>
              </div>
            </div>
          </main>
        </DefaultLayout>
      </>
    );
  }
}

export default LessonDetailsPage;
