import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {withCustomComponent} from 'ui/components/withCustomComponent';
import {checkTerms} from 'env/checkTerms';
import {withTranslation} from 'react-i18next';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import {withRouter} from 'next/router';
import {privateRoute} from 'env/privateRoute';
import {routes} from 'env/routes';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import {BookingDetails} from 'ui/_pages/bookings/calendar/BookingDetails';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import {MyBookingsTable} from 'ui/_pages/profile/myBookings/MyBookingsTable';
import {CalendarSynSection} from 'ui/_pages/profile/myBookings/CalendarSyncSection';
import PortalPageTitle from 'ui/components/Header/PortalPageTitle';

let MyBookingsPage = props => {
  const {
    router,
    t,
    authStore,
    appStore,
    bookingsStore,
  } = props;

  const {booking, upcomingBookings, hasLoadedUpcomingBookings} = bookingsStore;
  const {business, configuration} = appStore;
  const {customer} = authStore;

  useEffect(() => {
    bookingsStore.loadUpcomingBookings().catch(() => {
      appStore.setPopMessage(t('Could not load your bookings.'));
    });

    const $ = window.$;
    $('#bookings-booking-form').modal('hide');

    const {booking_id} = router.query;
    const {isLoadingBooking, booking} = bookingsStore;

    if (booking_id > 0) {
      if (!isLoadingBooking && (booking == null || booking.Id !== booking_id)) {
        bookingsStore.loadBooking(booking_id);
      }
    } else if (booking && booking.Id > 0) {
      bookingsStore.closeBooking();
    }
  }, []);

  const closeBooking = () => {
    router.query = '0';
    router.push(routes.my_resources);
  };

  /*useEffect(() => {
    const {booking_id} = router.query;
    const {isLoadingBooking, booking} = bookingsStore;

    if (booking_id > 0) {
      if (!isLoadingBooking && (booking == null || booking.Id !== booking_id)) {
        bookingsStore.loadBooking(booking_id);
      }
    } else if (booking && booking.Id > 0) {
      bookingsStore.closeBooking();
    }
  });*/

  const hasLoadedData = hasLoadedUpcomingBookings;

  if (!(hasLoadedData)) {
    return <LoadingLayout/>;
  }

  return <DefaultLayout componentName={props.componentName}>
    <main className="content content--custom pt-0" role="main">
      <PortalPageTitle
          title={t('My bookings')}
      />

      <div className="container">
        <BookingDetails close={() => closeBooking()}/>

        {!hasLoadedData && (
            <form id="profile-form">
              <fieldset className="section--settings" id="your-plan">
                <legend className="h3 section__title">
                  <LoadingSpinner/>
                </legend>
              </fieldset>
            </form>
        )}

        {hasLoadedData && (
            <form className="profile-form">
              <MyBookingsTable
                  change_route={routes.resource_by_id}
              />
              <CalendarSynSection/>
            </form>
        )}
      </div>
    </main>
  </DefaultLayout>;
};

export default MyBookingsPage = withCustomComponent('MyBookingsPage')(
    privateRoute(
        checkTerms(
            withRouter(
                withTranslation()(
                    inject('appStore', 'authStore', 'bookingsStore')(
                        observer(MyBookingsPage),
                    ),
                ),
            ),
        ),
    ),
);
