import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import ProfilePageLayout from 'ui/layouts/ProfilePageLayout';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { privateRoute } from 'env/privateRoute';
import { withRouter } from 'next/router';
import { routes } from 'env/routes';
import { withCustomComponent } from 'ui/components/withCustomComponent';
import ResponsiveTable from 'ui/components/tables/ResponsiveTable';

import { ZoomIntegrationSection } from 'ui/_pages/profile/myBookings/ZoomIntegrationSection';
import { GoogleCalendarIntegrationSection } from 'ui/_pages/profile/myBookings/GoogleCalendarIntegrationSection';
import { OutlookIntegrationSection } from 'ui/_pages/profile/myBookings/OutlookIntegrationSection';
import Head from 'next/head';

@withCustomComponent('IntegrationsPage')
@privateRoute
@withRouter
@withTranslation()
@inject('appStore', 'authStore', 'bookingsStore')
@observer
class IntegrationsPage extends Component {
  componentDidMount() {
    const { t, appStore, bookingsStore } = this.props;
    bookingsStore.loadUpcomingBookings().catch(() => {
      appStore.setPopMessage(t('Could not load your bookings.'));
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { router, bookingsStore } = this.props;
    const { booking_id } = router.query;
    const { isLoadingBooking, booking } = bookingsStore;
    if (booking_id > 0) {
      if (!isLoadingBooking && (booking == null || booking.Id != booking_id)) {
        bookingsStore.loadBooking(booking_id);
      }
    } else if (booking && booking.Id > 0) {
      bookingsStore.closeBooking();
    }
  }

  closeBooking = () => {
    const { router } = this.props;
    router.push(routes.my_bookings);
  };

  render() {
    const { t, authStore, appStore, bookingsStore } = this.props;
    const { hasLoadedUpcomingBookings } = bookingsStore;
    const { configuration } = appStore;
    const hasLoadedData = hasLoadedUpcomingBookings;

    return (
      <ProfilePageLayout componentName={this.props.componentName}>
        <Head>
          <title>
            {t('My bookings')} - {t(appStore.business.Name)}
          </title>
        </Head>
        <div>
          {!hasLoadedData && (
            <form id="profile-form">
              <fieldset className="section--settings" id="your-plan">
                <legend className="h3 section__title">
                  <LoadingSpinner />
                </legend>
              </fieldset>
            </form>
          )}
          {hasLoadedData && (
            <div className="card card-dashboard mb-32">
              <form className="profile-form">
                <fieldset>
                  <h5 className="section__title">{t('Integrations')}</h5>
                  <ResponsiveTable className="table--sm--no-thead">
                    <tbody>
                      {configuration['Zoom.Enabled'] && (
                        <ZoomIntegrationSection />
                      )}
                      <GoogleCalendarIntegrationSection />
                      {/* <AppleCalendarIntegrationSection /> */}
                      <OutlookIntegrationSection />
                    </tbody>
                  </ResponsiveTable>
                </fieldset>
              </form>
            </div>
          )}
        </div>
      </ProfilePageLayout>
    );
  }
}

export default IntegrationsPage;
