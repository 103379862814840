import React, {Component} from 'react';
import {PropTypes} from 'prop-types';
import {observer, inject} from 'mobx-react';
import {shouldShowField} from 'ui/components/forms/formFieldHelpers';
import InputField from 'ui/components/forms/InputField';
import asForm from 'ui/components/forms/asForm';
import ActionButton from 'ui/components/forms/ActionButton';
import {withTranslation} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('SocialNetworksSection')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class SocialNetworksSection extends Component {
  static propTypes = {
    customer: PropTypes.object.isRequired,
  };

  shouldShowFieldWithCustomer = (fieldName) => {
    const {customer, appStore} = this.props;
    const {configuration} = appStore;

    return shouldShowField(configuration, fieldName, customer);
  };

  render() {
    const {authStore, customer, updateProperty, t} = this.props;
    return (
      <fieldset componentName={this.props.componentName} id="social-networks">
        <div className="card-item-wrapper">
          <div className="card-item-header">
            <h5 className="card-item-header-title">
              {t('Social Networks')}
            </h5>
          </div>
          <div className="card-item-body">
            <div className="row">
              <div className="form-group col-12 ">
                <InputField
                  id="customer-twitter"
                  label="Twitter"
                  name="Twitter"
                  errors={authStore.customerValidation.errors.Twitter}
                  value={customer.Twitter}
                  onChange={updateProperty}
                  inputProps={{
                    placeholder: '@twitter_handle',
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-12 ">
                <InputField
                  id="customer-facebook"
                  label="Facebook"
                  name="Facebook"
                  errors={authStore.customerValidation.errors.Facebook}
                  inputProps={{placeholder: 'https://facebook.com/your_page'}}
                  value={customer.Facebook}
                  onChange={updateProperty}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-12 ">
                <InputField
                  id="customer-linkedin"
                  label="Linkedin"
                  name="Linkedin"
                  errors={authStore.customerValidation.errors.Linkedin}
                  value={customer.Linkedin}
                  inputProps={{placeholder: 'https://linkedin.com/your_page'}}
                  onChange={updateProperty}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-12 ">
                <InputField
                  id="customer-skype"
                  label="Skype"
                  name="Skype"
                  errors={authStore.customerValidation.errors.Skype}
                  value={customer.Skype}
                  inputProps={{placeholder: 'skype_username'}}
                  onChange={updateProperty}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-12 ">
                <InputField
                  id="customer-github"
                  label="Github"
                  name="Github"
                  errors={authStore.customerValidation.errors.Github}
                  value={customer.Github}
                  inputProps={{
                    placeholder: 'https://github.com/github_user',
                  }}
                  onChange={updateProperty}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-12 ">
                <InputField
                  id="customer-flickr"
                  label="Flickr"
                  name="Flickr"
                  errors={authStore.customerValidation.errors.Flickr}
                  value={customer.Flickr}
                  inputProps={{
                    placeholder: 'https://flickr.com/your_page',
                  }}
                  onChange={updateProperty}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-12 ">
                <InputField
                  id="customer-instagram"
                  label="Instagram"
                  name="Instagram"
                  errors={authStore.customerValidation.errors.Instagram}
                  value={customer.Instagram}
                  inputProps={{
                    placeholder: 'https://instagram.com/your_page',
                  }}
                  onChange={updateProperty}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-12 ">
                <InputField
                  id="customer-vimeo"
                  label="Vimeo"
                  name="Vimeo"
                  errors={authStore.customerValidation.errors.Vimeo}
                  value={customer.Vimeo}
                  inputProps={{
                    placeholder: 'https://vimeo.com/your_page',
                  }}
                  onChange={updateProperty}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-12 ">
                <InputField
                  id="customer-blogger"
                  label="Blogger"
                  name="Blogger"
                  errors={authStore.customerValidation.errors.Blogger}
                  value={customer.Blogger}
                  inputProps={{
                    placeholder: 'https://blogger.com/your_page',
                  }}
                  onChange={updateProperty}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-12 ">
                <InputField
                  id="customer-tumblr"
                  label="Tumblr"
                  name="Tumblr"
                  errors={authStore.customerValidation.errors.Tumblr}
                  value={customer.Tumblr}
                  inputProps={{
                    placeholder: 'https://tumblr.com/your_page',
                  }}
                  onChange={updateProperty}
                />
              </div>
            </div>
          </div>
        </div>
        <ActionButton
          action={() => this.props.saveCustomer()}
          busy={authStore.isSavingCustomerWithUser}
        />
      </fieldset>
    );
  }
}

export default asForm(SocialNetworksSection, 'customer');
