import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Link from 'next/link';
import _ from 'lodash';
import { routes } from 'env/routes';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import withCustomComponent from 'ui/components/withCustomComponent';
import CardEvent from 'ui/components/cards/CardEvent';
import CardBlogPost from 'ui/components/cards/CardBlogPost';

@withCustomComponent('HomeEventsArticlesSection')
@withTranslation()
@withRouter
@inject('appStore', 'eventsStore', 'blogStore')
@observer
class HomeEventsArticlesSection extends Component {
  componentDidMount() {
    const { router, eventsStore, blogStore } = this.props;
    eventsStore.loadUpcomingEvents().catch(() => null);
    eventsStore.loadMyEvents().catch(() => null);
    blogStore.loadBlogPosts({ ...router.query }).catch(() => null);
  }
  render() {
    const { t, componentName, appStore, eventsStore, blogStore } = this.props;

    const { upcomingEvents, myEvents } = eventsStore;
    const { blogPosts } = blogStore;

    const events = _.take(upcomingEvents, 2);
    const blogs = _.take(blogPosts.BlogPosts, 3);

    if (events.length === 0 && blogs.length === 0) return <></>;

    return (
      <div
        data-component-name={componentName}
        className={`home-events-articles-section`}
      >
        <div className="container">
          <h3 className="mb-48">{t('Community')}</h3>

          {events.length > 0 && (
            <>
              <div className="row align-items-center mt-40 mb-16">
                <div className="col-8">
                  <h6 className="mb-0">{t(`Upcoming events`)}</h6>
                </div>
                <div className="col-4 text-right lh-1">
                  <Link href={routes.events}>
                    <a className="btn btn-link text-gray-900 fw-500">
                      {t('See all')}
                    </a>
                  </Link>
                </div>
              </div>
              <ul className="list-grid list-grid--50 list-grid--md-100 events">
                {events.map((event, i) => (
                  <li key={i}>
                    <CardEvent
                      myEvents={myEvents}
                      event={event}
                      sizeMedium={true}
                    />
                  </li>
                ))}
              </ul>
            </>
          )}

          {blogs.length > 0 && (
            <>
              <div className="row align-items-center mt-40 mb-16">
                <div className="col-8">
                  <h6 className="mb-0">{t(`Featured articles`)}</h6>
                </div>
                <div className="col-4 text-right lh-1">
                  <Link href={routes.posts}>
                    <a className="btn btn-link text-gray-900 fw-500">
                      {t('See all')}
                    </a>
                  </Link>
                </div>
              </div>
              <ul className="list-grid list-grid--33 list-grid--md-50 list-grid--sm-100 blogposts">
                {blogs.map((blogPost, i) => (
                  <li key={i}>
                    <CardBlogPost post={blogPost} />
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
    );
  }
}
export default HomeEventsArticlesSection;
