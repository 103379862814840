import React, {Component} from 'react';
import {PropTypes} from 'prop-types';
import {observer, inject} from 'mobx-react';
import {action} from 'mobx';
import {withTranslation} from 'react-i18next';
import BooleanInputField from 'ui/components/forms/BooleanInputField';
import ActionButton from 'ui/components/forms/ActionButton';
import asForm from 'ui/components/forms/asForm';
import withCustomComponent from 'ui/components/withCustomComponent';
import ResponsiveTable from 'ui/components/tables/ResponsiveTable';

@withCustomComponent('NotificationsSection')
@withTranslation()
@inject('authStore', 'appStore')
@observer
class NotificationsSection extends Component {
  static propTypes = {
    customer: PropTypes.object.isRequired,
  };
  @action updateCustomerProperty = (key, value) => {
    this.props.customer[key] = value;
  };

  render() {
    const {t, authStore, customer, user, updateProperty} = this.props;
    const {appStore} = this.props;
    const {business} = appStore;
    return (
      <fieldset
        data-component-name={this.props.componentName}
        id="notifications-settings"
      >
        <div className="card-item-wrapper">
          <div className="card-item-header">
            <h5 className="card-item-header-title">
              {t('When to receive notifications')}
            </h5>
          </div>
          <div className="card-item-body">
            <div className="table-responsive">
              <table className="table--sm--no-thead w-100">
                <tbody>
                <tr>
                  <td>
                    <BooleanInputField
                      id="customer-signUpToNewsletter"
                      label={t(
                        'I would like to receive occasional and relevant updates from {{Name}} by email.',
                        {Name: t(business.Name)}
                      )}
                      name="SignUpToNewsletter"
                      errors={
                        authStore.customerValidation.errors.SignUpToNewsletter
                      }
                      value={customer.SignUpToNewsletter}
                      onChange={(key, value) =>
                        this.updateCustomerProperty(key, value ? true : false)
                      }
                      className="custom-switch--reversed my-4"
                      type="switch"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <BooleanInputField
                      id="customer-onNewWallPost"
                      label={t(
                        'When a new message is posted in the home page wall.'
                      )}
                      name="OnNewWallPost"
                      errors={authStore.customerValidation.errors.OnNewWallPost}
                      value={user.OnNewWallPost}
                      onChange={updateProperty}
                      className="custom-switch--reversed my-4"
                      type="switch"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <BooleanInputField
                      id="customer-onNewBlogComment"
                      label={t('When a new comment is posted in the blog.')}
                      name="OnNewBlogComment"
                      errors={
                        authStore.customerValidation.errors.OnNewBlogComment
                      }
                      value={user.OnNewBlogComment}
                      onChange={updateProperty}
                      className="custom-switch--reversed my-4"
                      type="switch"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <BooleanInputField
                      id="customer-onNewEventComment"
                      label={t('When a new comment is posted in an event.')}
                      name="OnNewEventComment"
                      errors={
                        authStore.customerValidation.errors.OnNewEventComment
                      }
                      value={user.OnNewEventComment}
                      onChange={updateProperty}
                      className="custom-switch--reversed my-4"
                      type="switch"
                    />
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="card-item-wrapper">
          <div className="card-item-header">
            <h5 className="card-item-header-title">
              {t('Alerts about conversations in the community board')}
            </h5>
          </div>
          <div className="card-item-body">
            <div className="table-responsive">
              <table className="table--sm--no-thead w-100">
                <tbody>
                <tr>
                  <td>
                    <BooleanInputField
                      id="customer-receiveCommunityDigest"
                      label={t(
                        'Send me an update in the morning if there are new messages (~8am).'
                      )}
                      name="ReceiveCommunityDigest"
                      errors={
                        authStore.customerValidation.errors.ReceiveCommunityDigest
                      }
                      value={user.ReceiveCommunityDigest}
                      onChange={updateProperty}
                      className="custom-switch--reversed my-4"
                      type="switch"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <BooleanInputField
                      id="customer-receiveEveryMessage"
                      label={t(
                        'Send me a notification shortly after every message. You can still mute individual conversations.'
                      )}
                      name="ReceiveEveryMessage"
                      errors={
                        authStore.customerValidation.errors.ReceiveEveryMessage
                      }
                      value={user.ReceiveEveryMessage}
                      onChange={updateProperty}
                      className="custom-switch--reversed my-4"
                      type="switch"
                    />
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ActionButton
          action={() => this.props.saveCustomer()}
          busy={authStore.isSavingCustomerWithUser}
        />
      </fieldset>
    )
      ;
  }
}

export default asForm(NotificationsSection, 'user');
