import { required, pattern } from 'validx';
import { toLocalTime } from 'env/utils/dateUtil';
import global from 'global';

export const planCartSummaryValidationSchema = () => {
  const t = global.t;

  return {
    // The schema maps to the fields we are validating.
    acceptTermsAndConditions: [required({msg: t('You must agree on conditions to continue')})],
  };
};
