import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import {routes} from 'env/routes';

@withCustomComponent('FaqPage')
@withTranslation()
@inject('contentStore', 'appStore', 'authStore')
@observer
class FaqPage extends Component {
  componentDidMount() {
    const {contentStore} = this.props;
    contentStore.loadFaqArticles();
  }

  render() {
    const {t, contentStore, appStore} = this.props;
    const {business} = appStore;
    const {articlesByGroup} = contentStore;

    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={t('Help') + ' - ' + t(business.Name)}
      >
        <main className="content content--custom faq pt-0" role="main">
          <div className="portal-title-wrapper">
            <div className="container portal-title-container"><h1
              className="portal-title">
              {t('Help')}
            </h1>
            </div>
          </div>
          <div className="container pb-32">
            {articlesByGroup.map((group, i) => group.group === 'Help' && (
              <React.Fragment key={i}>
                {group.articles.map((article, i) => (
                  <article
                    key={i}
                    className="help-item"
                  >
                    <Link
                      href={routes.help_by_id()}
                      as={routes.help_by_id(article)}
                    >
                      <a className="help-item-name">
                        <i className="icon-help"></i>
                        {article.Title}
                      </a>
                    </Link>
                  </article>
                ))}
              </React.Fragment>
            ))}
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default FaqPage;
