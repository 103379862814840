import React from 'react';

let FunnelTitle = props => {
  return (
    <div className="funnel-steps-header">
      <h1 className="funnel-steps-header-title">
        {props.title}
      </h1>
    </div>
  );
};

export default FunnelTitle;