import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { CoursesPageCommentsListItem } from './CoursesPageCommentsListItem';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

export const CoursesPageCommentsList = withCustomComponent(
  'CoursesPageCommentsList'
)(
  inject(
    'appStore',
    'coursesStore'
  )(
    withRouter(
      observer(({ router, appStore, coursesStore }) => {
        const { t } = useTranslation();
        const course = coursesStore.coursePage.Course;

        return (
          <>
            {course.Comments.length > 0 &&
              <ul className="list-unstyled mb-0">
                <TransitionGroup className="comments-list">
                  {course.Comments.map((comment, i) => (
                    <CSSTransition
                      key={comment.Id}
                      timeout={200}
                      classNames="comment"
                    >
                      <li className="mb-8">
                        <CoursesPageCommentsListItem comment={comment} />
                      </li>
                    </CSSTransition>
                  ))}
                </TransitionGroup>
              </ul>
            }
          </>
        );
      })
    )
  )
);
