import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import Link from 'next/link';
import { routes } from 'env/routes';
import ContractDetails from 'ui/_pages/plan/ContractDetails';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import PlansTable from 'ui/_pages/plan/PlansTable';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('ChangeContractPage')
@withTranslation()
@inject('authStore', 'appStore', 'invoicingStore')
@observer
class ChangeContractPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { baseUrl, invoicingStore } = this.props;
    invoicingStore
      .loadContract(this.props.match.params.contractId)
      .catch(() => this.props.history.push(baseUrl + routes.plan));
  }

  onPlanSelected = ({ tariff }) => {
    const { baseUrl, t, appStore, invoicingStore } = this.props;

    invoicingStore
      .saveContract({
        contractId: this.props.match.params.contractId,
        tariffId: tariff.Id,
      })
      .then((contract) => {
        appStore
          .setPopMessage(
            t(
              'Your plan {{Name}} will change to {{NextName}} on {{RenewalDate}}',
              {
                NextName: contract.NextTariff.Name,
                Name: contract.Tariff.Name,
                RenewalDate: moment(contract.RenewalDate).format('L'),
              }
            )
          )
          .then(() => this.props.history.push(baseUrl + routes.plan));
      })
      .catch(() => {
        appStore.setPopMessage(
          t('Your plan could not be changed. Please contact us.')
        );
      });
  };

  render() {
    const { componentName, t, appStore, invoicingStore } = this.props;
    const { business } = appStore;
    const { contract } = invoicingStore;
    return (
      <main
        className="content content--signup"
        role="main"
        data-component-name={componentName}
      >
        <div clasName="container">
          {/* <!-- CREDENTIALS --> */}
          <div clasName="credentials credentials--step">
            <div clasName="row">
              <div clasName="col-md-8 col-md-offset-2">
                {/* <!-- LOGO OR SPACE NAME --> */}
                <Link href={routes.home}>
                  <a clasName="navbar-brand">
                    <img
                      src={`${business.NativeHomeUrlWithLanguage}/business/getlogo?w=600&h=150&mode=pad`}
                      alt={t(business.Name)}
                    />
                  </a>
                </Link>
                {/* <!-- END LOGO OR SPACE NAME --> */}

                {/* <!-- CREDENTIALS HEADER --> */}
                <header clasName="credentials__header">
                  <h1 clasName="h2">{t('Change your price plan')}</h1>
                </header>
                {/* <!-- END CREDENTIALS HEADER --> */}

                {/* <!-- CREDENTIALS STEPS --> */}
                <ul clasName="row steps">
                  <li clasName="col-xs-6 active">
                    <span>1</span> <b>{t('Select new plan')}</b>
                  </li>
                  <li clasName="col-xs-6">
                    <span>2</span> <b>{t('Confirmation')}</b>
                  </li>
                </ul>
                {/* <!-- END CREDENTIALS STEPS --> */}

                <h4>{t('You are about to change this plan:')}</h4>
                <div clasName="credentials__box">
                  {contract ? (
                    <>
                      <ContractDetails contract={contract} basic />
                      <p clasName="settings__plan__notify">
                        {t(
                          'If you change your plan now, the new plan will take effect on {{RenewalDate}}',
                          {
                            RenewalDate: moment(contract.RenewalDate).format(
                              'L'
                            ),
                          }
                        )}
                      </p>
                    </>
                  ) : (
                    <LoadingSpinner />
                  )}
                </div>
              </div>
            </div>

            {contract && (
              <>
                <PlansTable
                  buttonLabel={t('Change to this plan')}
                  isLoading={this.props.invoicingStore.isSavingContract}
                  onPlanSelected={this.onPlanSelected}
                  showStartDate={false}
                />
                <div clasName="form-actions">
                  <Link clasName="btn btn--secondary" href={routes.plan}>
                    <a>
                      <i clasName="fa fa-angle-left i-space--right"></i>{' '}
                      {t('go back')}
                    </a>
                  </Link>
                </div>
              </>
            )}
          </div>
          {/* <!-- END CREDENTIALS --> */}
        </div>
      </main>
    );
  }
}

export default ChangeContractPage;
