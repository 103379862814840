import React, {useState, useEffect, useRef} from 'react';
import {inject, observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {withRouter} from 'next/router';
import {withCheckTerms} from 'env/withCheckTerms';
import usePrevious from 'env/usePrevious';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import Link from 'next/link';
import {routes} from 'env/routes';
import BookingHeader from 'ui/_pages/bookings/components/BookingHeader';
import {NoAccessNotice} from 'ui/components/NoAccessNotice';
import {CardBookingsAvailable} from 'ui/components/cards/CardBookingsAvailable';
import {BookingDetails} from 'ui/_pages/bookings/calendar/BookingDetails';
import {action} from 'mobx';
import moment from 'moment';

let ResourcesPage = ({appStore, bookingsStore, router, componentName}) => {
  const [error, setError] = useState(null);
  const [showAvailability, setShowAvailability] = useState(false);
  const prevQuery = usePrevious(router.query);
  const componentUnmounted = useRef(false);
  const {t} = useTranslation();

  const {business} = appStore;
  const {resourceSearch, isLoadingResourceSearch} = bookingsStore;

  useEffect(() => {
    if (componentUnmounted.current) return;

    const {resource_id, desk_id} = router.query;

    bookingsStore.loadResources().then(() => {
      if (resource_id) {
        bookingsStore.newBooking({
          resourceId: resource_id ? parseInt(resource_id) : null,
          floorPlanDeskId: desk_id ? parseInt(desk_id) : null,
        });
      }
    }).catch(() => null);

    // Check if there are any passed in parameters
    if (router.query.date) {
      action(() => {
        bookingsStore.fromTime = moment(router.query.date).startOf('day').add(moment().hour() + 1, 'hour').toDate();
        bookingsStore.toTime = moment(router.query.date).
          startOf('day').
          add(moment().hour() + 1, 'hours').
          add(router.query.duration ?? 60, 'minutes').
          toDate();

        bookingsStore.loadResourceSearch({...router.query}).catch((err) => {
          setError(
            t(
              'We could not load any available resources at this time.',
            ),
          );
        });
      })();
      setShowAvailability(true);
    } else if (router.query.fromTime) {
      action(() => {
        bookingsStore.fromTime = moment(router.query.fromTime).toDate();
      })();
      setShowAvailability(true);
      bookingsStore.loadResourceSearch({...router.query}).catch((err) => {
        setError(
          t('We could not load any available resources at this time.'),
        );
      });
    } else if (router.query.toTime) {
      action(() => {
        bookingsStore.toTime = moment(router.query.toTime).toDate();
      })();

      setShowAvailability(true);
      bookingsStore.loadResourceSearch({...router.query}).catch((err) => {
        setError(
          t('We could not load any available resources at this time.'),
        );
      });
    } else {
      bookingsStore.loadResourceSearch({...router.query}).catch((err) => {
        setError(
          t('We could not load any available resources at this time.'),
        );
      });
    }

    return () => {
      componentUnmounted.current = true;
    }
  }, []);

  useEffect(() => {
    const currentQuery = router.query;
    if (JSON.stringify(currentQuery) != JSON.stringify(prevQuery))
      doSearch();
  }, [router]);

  const doSearch = () => {
    bookingsStore.loadResourceSearch({...router.query}).catch((err) => {
      appStore.setPopMessage(
        t('Sorry, we could not load this page. {{err}}', {err}),
      );
    });
  };

  const selectShift = (shiftId) => {
    bookingsStore.selectShift(shiftId);
  };

  const selectDates = (dates) => {
    bookingsStore.selectDates(dates);
  };

  return (
    <DefaultLayout
      componentName={componentName}
      title={t('Find available spaces- {{Name}}', {
        Name: t(business.Name),
      })}
    >
      <main className="content content--custom pt-0" role="main">
        <BookingHeader
          availabilityToggle={(e) =>
            setShowAvailability({showAvailability: e})
          }
          doSearch={doSearch}
          selectShift={selectShift}
          selectDates={selectDates}
          shifts={bookingsStore.shifts}
          isLoadingItems={false}
          shiftId={bookingsStore.shiftId}
          fromTime={bookingsStore.fromTime}
          toTime={bookingsStore.toTime}
        />
        <div className="container">

          <div className="meetings-list">
            {isLoadingResourceSearch && <LoadingSpinner/>}
            <NoAccessNotice error={error}/>
            {!isLoadingResourceSearch &&
            resourceSearch?.Resources?.length > 0 ? (
              <ul className="list-grid list-grid--50 list-grid--lg-100">
                {resourceSearch.Resources.map((resource, i) => (
                  <li key={i}>
                    <CardBookingsAvailable
                      showAvailability={showAvailability}
                      resource={resource}
                      doSearch={doSearch}
                    />
                  </li>
                ))}
              </ul>
            ) : (
              <>
                {!isLoadingResourceSearch && (
                  <div className="alert alert-outline">
                    <i className="icon-calendar-view"></i>
                    <h5>{t(`No available booking resources.`)}</h5>
                    <p>
                      {t(
                        'Please try resource search with different search criteria. You can also try to search for available booking through:',
                      )}
                    </p>
                    <div className="btn-group">
                      <Link href={routes.calendar}>
                        <a className="btn btn-outline-gray btn-icon">
                          <i className="icon-calendar-view"></i>
                          {t('Calendar view')}
                        </a>
                      </Link>
                      <Link href={routes.floorplan}>
                        <a className="btn btn-outline-gray btn-icon">
                          <i className="icon-floorplan-view"></i>
                          {t('Floor plan view')}
                        </a>
                      </Link>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          <BookingDetails/>
        </div>
      </main>
    </DefaultLayout>
  );
};

export default ResourcesPage = withCustomComponent('ResourcesPage')(
  withCheckTerms(
    inject(
      'appStore',
      'bookingsStore',
    )(
      withRouter(
        observer(ResourcesPage),
      ),
    ),
  ),
);
