import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { CoworkerAvatar } from 'ui/components/CoworkerAvatar';
import { removeHtml } from 'env/utils/strings';
import { CommunityBoardReplyForm } from 'ui/_pages/community/thread/CommunityBoardReplyForm';
import ScrollableAnchor from 'react-scrollable-anchor';

export const ThreadReplySection = withCustomComponent('ThreadReplySection')(
  inject(
    'appStore',
    'authStore',
    'communityStore'
  )(
    withRouter(
      observer(({ authStore, communityStore, componentName }) => {
        const { t } = useTranslation();
        const { threadPage } = communityStore;
        const { customer, isLoggedIn } = authStore;
        const thread = threadPage && threadPage.Thread;

        return (
          <ScrollableAnchor id={`reply-form`}>
            <div data-component-name={componentName}>
              {!isLoggedIn && (
                <Link
                  href={{
                    pathname: routes.login,
                    query: {
                      returnurl: encodeURIComponent(
                        routes.thread_by_id(thread)
                      ),
                    },
                  }}
                >
                  <a className="btn btn--primary btn--sm">
                    {t('Sign in to join this conversation')}
                  </a>
                </Link>
              )}
              {isLoggedIn && (
                <>
                  <div className="your-input">
                    <div className="user-badge">
                      <h3 className="h6 user-badge__name">
                        {t('Leave a reply')}
                      </h3>
                      <p>{removeHtml(thread.Subject)}</p>
                      <CoworkerAvatar
                        size={96}
                        link
                        linkClassName={'avatar user-badge__avatar'}
                        coworker_id={customer.Id}
                      />
                    </div>

                    <CommunityBoardReplyForm
                      thread={thread}
                      newCommunityBoardReply={
                        communityStore.newCommunityBoardReply
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </ScrollableAnchor>
        );
      })
    )
  )
);
