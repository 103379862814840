/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, useEffect } from 'react';
import { inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { isServer } from 'env/ssr/ServerSideRenderManager';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('ForteAchPaymentButton')
@withTranslation()
@inject('appStore', 'authStore')
class ForteAchPaymentButton extends Component {
  render() {
    const { t, appStore, authStore, invoice, componentName } = this.props;
    const { business, configuration } = appStore;
    const { customer } = authStore;

    return (
      <a
        data-component-name={componentName}
        className="btn btn-icon btn-link text-gray-900"
        api_login_id={configuration['Forte.GatewayApiLoginId']}
        allowed_methods="echeck"
        billing_name_attr="edit"
        billing_name={invoice.BillToName}
        billing_company_name_attr="edit"
        billing_company_name={customer.CompanyName}
        billing_street_line1_attr="edit"
        billing_street_line1={invoice.BillToAddress}
        billing_locality_attr="edit"
        billing_locality={invoice.BillToCity}
        billing_postal_code_attr="edit"
        billing_postal_code={invoice.BillToPostCode}
        billing_region_attr="edit"
        billing_region={customer.StateForInvoice}
        billing_email_address_attr="edit"
        billing_email_address={customer.Email}
        save_token="true"
        method="sale"
        callback="forteCallback"
        version_number="1.0"
        total_amount={invoice.TransactionTotalAmount.toFixed(2)}
        utc_time={invoice.UtcTime}
        order_number={invoice.InvoiceNumber}
        signature={invoice.ForteSignature}
      >
        <i aria-hidden="true" className="icon-safe-box"></i>
        {t('Pay by Bank Transfer')}
        <style>
          {`
            /* this style needs to be here... */
            .fortebtn {
              margin-top: 10px;
              margin-bottom: 10px;
              background-color: transparent;
              border: none;
              padding: 0;
              color: #949494;
          }
        `}
        </style>
      </a>
    );
  }
}
export default ForteAchPaymentButton;
