// The order of the routes is important here to make
// sure the Breadcrumbs component renders the different
// path items in the correct order. Items towards the bottom show
// before items towards the top of the array in the list

import {slugify} from './utils/strings';
import global from 'global';

const {t = (txt) => txt} = global;
// of breadcrumbs. All routes must have a route (pathname) and a name.
export const routeInfo = {
  faq_by_id: {
    route: (article = {}) =>
      `/faq/${article.Id > 0 ? article.Id : '[article_id]'}/${
        article.Id > 0 ? slugify(article.Title) : '[slug]'
      }`,
    name: t('FAQ Article'),
  },
  faq: {route: '/faq', name: t('Frequent Questions')},

  help_by_id: {
    route: (article = {}) =>
      `/help/${article.Id > 0 ? article.Id : '[article_id]'}/${
        article.Id > 0 ? slugify(article.Title) : '[slug]'
      }`,
    name: t('Help Article'),
  },
  help: {route: '/help', name: t('Help')},
  perk_by_id: {
    route: (article = {}) =>
      `/perks/${article.Id > 0 ? article.Id : '[perk_id]'}/${
        article.Id > 0 ? slugify(article.Title) : '[slug]'
      }`,
    name: t('Perks and benefits'),
  },
  perks: {route: '/perks', name: t('Perks and benefits')},
  contact: {route: '/contact', name: t('Contact')},
  about: {route: '/about', name: t('About us')},
  invoices: {route: '/profile/invoices', name: t('My invoices')},
  metrics: {route: '/profile/metrics', name: t('My metrics')},
  purchases: {route: '/profile/purchases', name: t('Purchases')},
  plan: {route: '/profile/plan', name: t('My plan')},
  integrations: {route: '/profile/integrations', name: t('Integrations')},
  integrations_zoom: {
    route: '/integrations/zoom/complete',
    name: t('Zoom'),
  },
  integrations_office365: {
    route: '/integrations/office365/complete',
    name: t('Office 365'),
  },
  plan_cancel: {
    route: (contractId) => `/profile/plan/cancel/${contractId}`,
    name: t('Cancel contract'),
  },
  custom_page: {
    route: (page_slug) => `/pages/${page_slug}`,
    name: '',
  },
  plan_change: {
    route: (contractId) => `/profile/plan/change/${contractId}`,
    name: t('Change contract'),
  },
  notifications: {route: '/profile/notifications', name: t('Notifications')},
  deliveries: {route: '/profile/deliveries', name: t('My deliveries')},
  files: {route: '/profile/files', name: t('My files')},
  visitors: {route: '/profile/visitors', name: t('My visitors')},
  my_bookings: {route: '/profile/bookings', name: t('My bookings')},

  resources: {route: '/bookings/search', name: t('Search')},
  my_resources: {
    route: '/bookings/my',
    name: t('Booking')
  },
  resource_by_id: {
    route: id => `/bookings/my/${id ? id : '[booking_id]'}`,
    name: t('Booking')
  },
  floorplan: {route: '/bookings/floorplans', name: t('Floor plans')},
  floorplan_by_id: {
    route: (floorplan) =>
      `/bookings/floorplans/${
        floorplan.Id > 0 ? floorplan.Id : '[bookingId]'
      }/${floorplan.Id > 0 ? slugify(floorplan.Name) : '[slug]'}`,
    name: t('Floor plans'),
  },
  floorplan_mobile: {
    route: (floorplan, query) =>
      `/bookings/floorPlanMobile?fromTime=${query.fromTime}&toTime=${query.toTime}&floorplan_id=${floorplan.Id}&height=${query.height}`,
    name: t('Floor plans mobile'),
  },
  passport_floor_plans: {
    route: '/bookings/passportFloorPlans',
    name: t('Passport floor plans'),
  },
  capacity: {route: '/bookings/capacity', name: t('Available space')},
  resources_map: {route: '/bookings/map', name: t('Resources map')},
  calendar: {route: '/bookings/calendar', name: t('Bookings calendar')},

  calendar_by: {
    route: ({resource_type_id, resource_id}) =>
      `/bookings/calendar?${
        resource_type_id ? `resource_type_id=${resource_type_id}&` : ''
      }${resource_id ? `resource_id=${resource_id}&` : ''}`,
    name: t('Bookings calendar'),
  },
  calendar_by_resource_id: {
    route: (resource_id) => `/bookings/calendar?resource_id=${resource_id}`,
    name: t('Resource Calendar'),
  },
  booking_by_id: {
    route: (bookingId) => `/profile/bookings/${bookingId}`,
    name: t('Change booking'),
  },
  virtual_rooms_by_id: {
    route: (room_id) => `/videorooms/room/${room_id}`,
    name: t('Access Virtual Room'),
  },
  virtual_rooms_team_stream: {
    route: (room_id, team_id) =>
      `/videorooms/room/${room_id}?team_id=${team_id}`,
    name: t('Team Virtual Room'),
  },
  virtual_rooms_my_stream: {
    route: '/videorooms/room/me',
    name: t('My Virtual Room'),
  },
  virtual_rooms: {route: '/videorooms', name: t('Virtual Rooms')},
  my_events: {route: '/profile/events', name: t('My events')},
  events_calendar: {route: '/events/calendar', name: t('Calendar')},
  event_by_category: {
    route: (categoryId) => `/events?categoryId=${categoryId}`,
    name: t('Events'),
  },
  event_by_id: {
    route: (event = {}) =>
      `/events/${event.Id ? event.Id : '[event_id]'}/${
        event.Id ? slugify(event.Name) : '[slug]'
      }`,
    name: t('Event details'),
  },
  tickets_by_id: {
    route: (event) =>
      `/events/${event.Id ? event.Id : '[event_id]'}/${
        event.Id ? slugify(event.Name) : '[slug]'
      }/tickets`,
    name: t('Event tickets'),
  },
  events: {route: '/events', name: t('Events')},
  my_courses: {
    route: '/courses/my-courses',
    name: t('My Courses'),
  },
  lesson_by_id: {
    route: (course = {}, lesson_id = '') =>
      `/courses/${course.Id ? course.Id : '[course_id]'}/${
        course.Id ? slugify(course.Title) : '[slug]'
      }/${lesson_id ? lesson_id : '[lesson_id]'}`,
    name: t('Course lesson'),
  },
  course_by_group: {
    route: (groupName) => `/courses?group=${groupName}`,
    name: t('Courses'),
  },
  course_by_id: {
    route: (course = {}) =>
      `/courses/${course.Id ? course.Id : '[course_id]'}/${
        course.Id ? slugify(course.Title) : '[slug]'
      }`,
    name: t('Course details'),
  },
  courses: {route: '/courses', name: t('Courses')},
  post_by_id: {
    route: (post = {}) =>
      `/blog/${post.Id > 0 ? post.Id : '[post_id]'}/${
        post.Id > 0 ? slugify(post.Title) : '[slug]'
      }`,
    name: t('Article'),
  },
  posts_by_user: {
    route: (userId) => `/blog/user/${userId}`,
    name: t('Articles by author'),
  },
  posts_by_category: {
    route: (categoryId) => `/blog/category/${categoryId}`,
    name: t('Articles by category'),
  },
  posts: {route: `/blog`, name: t('Articles')},

  tour: {route: '/tour', name: t('Request a tour')},
  tour_complete: {route: '/tour/complete', name: t('Tour request')},
  contact: {route: '/contact', name: t('Contact')},
  login: {route: '/login', name: t('Sign in')},

  proposal_payment: {
    route: '/proposal/payment ',
    name: t('Proposal payment'),
  },
  proposal_summary: {route: '/proposal/summary', name: t('Proposal summary')},
  signup_payment: {route: '/signup/payment', name: t('Payment')},
  signup_summary: {route: '/signup/summary', name: t('Summary')},
  signup_products: {route: '/signup/products', name: t('Choose add-ons')},
  signup_plan: {route: '/signup/plan', name: t('Choose a plan')},

  signup_payment: {route: '/signup/payment', name: t('Payment')},
  signup_restore: {route: '/signup/restore', name: t('Restore your account')},
  signup_complete: {route: '/signup/complete', name: t('Sign-up complete')},

  signup_plan_link: {
    route: (plan) => `/signup?tariff_guid=${plan.UniqueId}`,
    name: t('Join'),
  },

  signup: {route: '/signup', name: t('Join')},

  support_new: {route: '/support/new', name: t('New help message')},
  support_message: {
    route: (message_id) => `/support/${message_id}`,
    name: t('Help message details'),
  },
  support: {route: '/support', name: t('Your help messages')},
  logout: {route: '/logout', name: t('Log out')},
  about: {route: '/about', name: t('About us')},

  directory_members: {
    route: '/directory/members',
    name: t('Directory'),
  },
  directory_member_by_id: {
    route: (customer) =>
      `/directory/members/${customer.Id ? customer.Id : '[member_id]'}/${
        customer.Id ? slugify(customer.FullName) : '[slug]'
      }`,
    name: t('Member details'),
  },
  directory_team_by_id: {
    route: (team) =>
      `/directory/companies/${team.Id ? team.Id : '[team_id]'}/${
        team.Id ? slugify(team.Name) : '[slug]'
      }`,
    name: t('Company details'),
  },
  directory_teams: {
    route: '/directory/companies',
    name: t('Company directory'),
  },

  forum_by_group: {
    route: (group_id = {}) => `/community/group/${group_id}`,
    name: t('Discussion boards'),
  },
  thread_by_id: {
    route: (thread = {}) =>
      `/community/thread/${thread.Id ? thread.Id : '[thread_id]'}/${
        thread.Id ? slugify(thread.Subject) : '[slug]'
      }`,
    name: t('Message details'),
  },
  forum_start: {route: '/community/start', name: t('New conversation')},
  my_threads: {route: '/community/me', name: t('My conversations')},
  private_threads: {
    route: '/community/private',
    name: t('Private conversations'),
  },
  forum: {route: '/community', name: t('Discussion boards')},
  store: {route: '/store/products', name: t('Products and services')},
  store_products: {
    route: (params = {}) => {
      let qs = new URLSearchParams();
      Object.entries(params).forEach(([k, v]) => qs.set(k, v));
      qs = qs.toString();

      return `/store/funnel-products${qs ? `?${qs}` : ''}`;
    },
    name: t('Products'),
  },
  team_dashboard: {route: '/dashboards/team', name: t('Teams dashboard')},
  community_dashboard: {
    route: '/dashboards/community',
    name: t('Discussion boards'),
  },
  passport_video: {
    route: '/community/PassportVideoPage',
    name: t('Passport video page'),
  },
  profile_team: {route: '/profile/team', name: t('My team')},
  profile: {route: '/profile', name: t('My account')},
  checkout_payment: {route: '/checkout/payment', name: t('Payment')},
  checkout_complete: {route: '/checkout/complete', name: t('Complete')},
  signup_complete: {route: '/signup/complete', name: t('Complete')},
  checkout: {route: '/checkout', name: t('My basket')},
  legal_terms: {route: '/legal/terms', name: t('Terms and conditions')},
  legal_privacy: {route: '/legal/privacy', name: t('Privacy policy')},
  legal_cookies: {route: '/legal/cookies', name: t('Cookies policy')},
  home: {route: '/', name: t('Home')},

  plans: {
    route: '/plans/search',
    name: t('Plans'),
  },
  plans_my_plans: {
    route: '/plans/my-plans',
    name: t('My Plans'),
  },
  plans_plan: {
    route: (guid = '') => {
      return `/plans/${guid ? guid : '[guid]'}`;
    },
    name: t('Plan'),
  },
  plans_create_account: {route: '/plans/create-account', name: t('Create account')},
  plans_cart_summary: {route: '/plans/cart-summary', name: t('Cart summary')},
  plans_payment: {route: '/plans/payment', name: t('Payment')},
  plans_confirmation: {route: '/plans/confirmation', name: t('Confirmation')},
  plans_plan_change: {
    route: contractId => `/plans/change/${contractId ? contractId : '[contract_id]'}`,
    name: t('Change contract'),
  },

  contracts_benefits: {route: '/contracts/benefits', name: t('My benefits')},
  contracts_invoices: {route: '/contracts/invoices', name: t('Invoices')},
};

// This export make it easier to use the route hrefs in Link components:
// <Link href={routes.home}></Link>
export const routes = Object.keys(routeInfo).map((ri, i) => ri).reduce((acc, cur, i) => {
  acc[cur] = routeInfo[cur].route;
  return acc;
}, {});
