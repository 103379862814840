import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import { withCheckTerms } from 'env/withCheckTerms';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import { NoAccessNotice } from 'ui/components/NoAccessNotice';
import usePrevious from 'env/usePrevious';
import { routes } from 'env/routes';
import { withRouter } from 'next/router';

import BookingHeader from 'ui/_pages/bookings/components/BookingHeader';

import FloorPlanCanvas from 'ui/_pages/bookings/floorplan/FloorPlanCanvas';
import { FloorPlanItemFilters } from 'ui/_pages/bookings/floorplan/FloorPlanItemFilters';
import ArchilogicFloorPlanCanvas from 'ui/_pages/bookings/floorplan/ArchilogicFloorPlanCanvas';
import { FloorPlanBackdrop } from 'ui/_pages/bookings/floorplan/FloorPlanBackdrop';
import { FloorPlanTypeFilters } from 'ui/_pages/bookings/floorplan/FloorPlanTypeFilters';

const FloorPlanById = withCustomComponent('FloorPlan')(
  withCheckTerms(
    inject(
      'appStore',
      'authStore',
      'floorPlanStore'
    )(
      withRouter(
        observer(({ appStore, floorPlanStore, router, componentName }) => {
          const [error, setError] = useState(null);
          const prevQuery = usePrevious(router.query);
          const { t } = useTranslation();

          const { floorPlans, showTypeFilters, currentFloorPlan } =
            floorPlanStore;
          const { business } = appStore;

          useEffect(() => {
            floorPlanStore
              .loadItems()
              .catch((err) => {
                setError(t('We could not load the floor plans at this time.'));
              })
              .then(() => {
                loadFloorPlan();
              });

            return () => floorPlanStore.selectFloorPlan(null);
          }, []);

          useEffect(() => {
            const currentQuery = router.query;
            if (JSON.stringify(currentQuery) != JSON.stringify(prevQuery)) {
              loadFloorPlan();
            }
          }, [router.query]);

          const doSearch = () => {
            floorPlanStore.loadItems().catch((err) => {
              setError(t('We could not load the floor plans at this time.'));
            });
          };

          const selectShift = (shiftId) => {
            floorPlanStore.selectShift(shiftId);
          };

          const selectDates = (dates) => {
            floorPlanStore.selectDates(dates);
          };

          const selectFloorPlan = (floorPlan) => {
            router.push(
              routes.floorplan_by_id('[floorplan_id]'),
              routes.floorplan_by_id(floorPlan)
            );
          };

          const loadFloorPlan = () => {
            var foundPlan = floorPlanStore.floorPlans.find(
              (fp) => fp.floorPlan.Id == router.query.floorplan_id
            );

            if (foundPlan) floorPlanStore.selectFloorPlan(foundPlan?.floorPlan);
            else floorPlanStore.selectFloorPlan(null);
          };

          return (
            <DefaultLayout
              componentName={componentName}
              title={t('Floor plans - {{Name}}', { Name: t(business.Name) })}
            >
              <main className="content content--custom" role="main">
                <div className="container">
                  <BookingHeader
                    doSearch={doSearch}
                    selectShift={selectShift}
                    selectDates={selectDates}
                    shifts={floorPlanStore.shifts}
                    isLoadingItems={floorPlanStore.isLoadingItems}
                    shiftId={floorPlanStore.shiftId}
                    fromTime={floorPlanStore.fromTime}
                    toTime={floorPlanStore.toTime}
                    hideResourceFilters={true}
                  />

                  <div>
                    <FloorPlanItemFilters selectFloorPlan={selectFloorPlan} />
                    <NoAccessNotice error={error} />
                    {showTypeFilters && (
                      <FloorPlanBackdrop>
                        <FloorPlanTypeFilters />
                      </FloorPlanBackdrop>
                    )}
                    {!currentFloorPlan && (
                      <FloorPlanBackdrop>
                        <div
                          className="alert alert-light p-24 js-floorplans-alert"
                          role="alert"
                        >
                          {floorPlans.length > 0 && (
                            <div className="text-gray-900 fw-500 mb-20">
                              {t(
                                'Start by selecting a floor to see available items.'
                              )}
                            </div>
                          )}
                          {floorPlans.length === 0 && (
                            <div className="text-gray-900 fw-500 mb-20">
                              {t("You don't have access to any floor plans")}
                            </div>
                          )}
                          {floorPlans
                            .sort((a, b) =>
                              a.floorPlan.Name.localeCompare(b.floorPlan.Name)
                            )
                            .map((fp, i) => (
                              <p key={i}>
                                <a
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    selectFloorPlan(fp.floorPlan);
                                  }}
                                  href="#"
                                >
                                  {fp.floorPlan.Name}
                                </a>
                                {' →'}
                              </p>
                            ))}
                        </div>
                      </FloorPlanBackdrop>
                    )}
                    {currentFloorPlan && (
                      <div style={{ display: showTypeFilters ? 'none' : null }}>
                        {!currentFloorPlan.ArchilogicUniqueId && (
                          <div className="floorplans">
                            <FloorPlanCanvas
                              selectFloorPlan={selectFloorPlan}
                            />
                          </div>
                        )}
                        {currentFloorPlan.ArchilogicUniqueId && (
                          <ArchilogicFloorPlanCanvas
                            selectFloorPlan={selectFloorPlan}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </main>
            </DefaultLayout>
          );
        })
      )
    )
  )
);

export default FloorPlanById;
