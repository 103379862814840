import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import Link from 'next/link';
import { routes } from 'env/routes';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';
import withCustomComponent from 'ui/components/withCustomComponent';
@withCustomComponent('FeaturedPerks')
@withTranslation()
@inject('appStore', 'authStore', 'contentStore')
@observer
class FeaturedPerks extends Component {
  componentDidMount() {
    const { t, contentStore } = this.props;
    contentStore.loadCommunityPerks().catch(() => null);
  }

  render() {
    const { match, t, appStore, contentStore } = this.props;
    const { business } = appStore;
    const { homeCommunityPerks } = contentStore;

    const perks = _.take(homeCommunityPerks, 3);
    if (perks.length === 0) return <></>;
    return (
      <div data-component-name={this.props.componentName}>
        <h2 className="dashboard-body__title">{t('Perks and benefits')}</h2>

        <ul className="row events-list">
          {perks.map((perk, i) => {
            return (
              <li
                key={i}
                className="col-xxs-12 col-xs-6 col-md-4 events-list__item"
              >
                <Link href={routes.perk_by_id(perk)}>
                  <a className="events-list__cover">
                    <img
                      src={`${business.NativeHomeUrlWithLanguage}/communityperks/getlargeimage?id=${perk.Id}&w=720&h=540&mode=pad&cache=${perk.UpdatedOn}`}
                      alt={perk.Title}
                    />
                  </a>
                </Link>
                <div className="events-list__box">
                  <h3 className="h4 events-list__title">
                    <Link href={routes.perk_by_id(perk)}>{perk.Title}</Link>
                  </h3>
                  <span>{perk.SummaryText}</span>
                  <div className="form-actions btn-toolbar">
                    {perk.FullText && (
                      <Link
                        href={routes.perk_by_id()}
                        as={routes.perk_by_id(perk)}
                      >
                        <a className="btn btn--primary btn--xs">
                          {t('Read more')}
                        </a>
                      </Link>
                    )}
                    <a
                      className="btn btn--secondary btn--xs"
                      href={`${business.NativeHomeUrlWithLanguage}/communityperks/claim/?pid=${perk.UniqueId}`}
                    >
                      {t('Claim')}
                    </a>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        <hr />
      </div>
    );
  }
}

export default FeaturedPerks;
