import React from 'react';
import Link from 'next/link';
import _ from 'lodash';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const CommunityBoardItemParticipantList = withCustomComponent(
  'CommunityBoardItemParticipantList'
)(
  inject('appStore')(
    withRouter(
      observer(({ thread, componentName, appStore }) => {
        const { t } = useTranslation();
        const { business } = appStore;

        const participants = thread.Participants.filter(
          (participantId, i) => thread.Participants.indexOf(participantId) == i
        );
        const displayParticipantsNo = 8;

        return (
          <div
            data-component-name={componentName}
            className="community-board-item-participant-list mb-12"
          >
            <div className="mb-12">
              {t('Participants')}
              {` (${participants.length}) :`}
            </div>
            <ul className="list-avatars">
              {_.take(participants, displayParticipantsNo).map(
                (participantId, i) => (
                  <li key={i}>
                    <Link
                      href={{
                        pathname: routes.directory_member_by_id('[member_id]'),
                      }}
                      as={{
                        pathname: routes.directory_member_by_id({
                          Id: participantId,
                          FullName: thread.FullNames[i],
                        }),
                      }}
                    >
                      <a
                        className="avatar avatar--40 avatar--border-2-s-200"
                        data-toggle="tooltip"
                        title={thread.FullNames[i]}
                        style={{
                          backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${participantId}?h=80&w=80&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png)`,
                        }}
                      ></a>
                    </Link>
                  </li>
                )
              )}
              {participants.length > displayParticipantsNo && (
                <li className="rest">
                  {'+'}
                  {participants.length - displayParticipantsNo}
                </li>
              )}
            </ul>
          </div>
        );
      })
    )
  )
);
