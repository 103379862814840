/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { action } from 'mobx';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import withCustomComponent from 'ui/components/withCustomComponent';
import { routes } from 'env/routes';
import { withRouter } from 'next/router';
import moment from 'moment';
import { isWeekend } from 'env/utils/dateUtil';

@withRouter
@withCustomComponent('BookingFormToolBar')
@withTranslation()
@inject('appStore', 'authStore', 'bookingsStore', 'checkoutStore')
@observer
class BookingFormToolBar extends Component {
  updateBooking = this.props.updateBooking;
  showBookingModal = this.props.showBookingModal;
  hideBookingModal = this.props.hideBookingModal;
  constructor(props) {
    super(props);

    this.state = {};
  }

  getResource(booking) {
    const { bookingsStore } = this.props;
    const { networkResources } = bookingsStore;
    return (
      booking &&
      _(networkResources)
        .filter((r) => r.Id == booking.ResourceId)
        .first()
    );
  }

  addSplitBookingToBasket = async (data, fromTime, toTime) => {
    const { t, appStore, authStore, bookingsStore, checkoutStore } = this.props;
    const { customer } = authStore;
    const { basket } = checkoutStore;
    var items = basket.length + moment(toTime).diff(moment(fromTime), 'days');

    if (items > 30) {
      appStore.setPopMessage(
        t('You can request a maximum of 30 bookings in a single transaction')
      );
      return Promise.reject();
    }

    for (
      let date = fromTime;
      moment(date).startOf('day') <= moment(toTime).startOf('day');
      date = moment(date).clone().add(1, 'day').toDate()
    ) {
      const thisFromTime = moment(date).clone().toDate();
      const thisToTime = moment(date).clone().toDate();

      thisFromTime.setHours(fromTime.getHours());
      thisFromTime.setMinutes(fromTime.getMinutes());
      thisToTime.setHours(toTime.getHours());
      thisToTime.setMinutes(toTime.getMinutes());

      const includeWeekends = isWeekend(fromTime) || isWeekend(toTime);

      if (!includeWeekends && isWeekend(date)) continue;

      const booking = {
        ...data,
        Id: null,
        FromTime: thisFromTime,
        ToTime: thisToTime,
        ResourceName: data.ResourceName,
        ResourceId: data.ResourceId,
        Notes: bookingsStore.booking?.Notes,
        BookingVisitors: bookingsStore.booking?.BookingVisitors,
        SelectedShift: bookingsStore.booking?.SelectedShift,
        IncludeZoomInvite: bookingsStore.booking?.IncludeZoomInvite,
      };

      const price = await bookingsStore.loadBookingPriceDebounced(booking);

      checkoutStore.addToBasket({
        type: 'booking',
        data: booking,
        resourceProducts: bookingsStore.resourceProducts,
        bookingPrice: price,
        previewInvoice: false,
      });
    }

    if (customer != null) return checkoutStore.loadInvoicePreview();

    return Promise.resolve();
  };

  addToBasket = async ({ goToBasket = true }) => {
    const { router, bookingsStore, authStore, checkoutStore } = this.props;
    const { isLoadingBookingPrice, resourceProducts, bookingPrice, booking } =
      bookingsStore;

    if (goToBasket) router.push(routes.checkout);

    const { customer } = authStore;
    if (isLoadingBookingPrice) return;
    const resource = this.getResource(booking);
    const hasShift = resource.Shifts?.length > 0;

    if (hasShift) {
      await this.addSplitBookingToBasket(
        booking,
        booking.FromTime,
        booking.ToTime
      );
    } else
      checkoutStore.addToBasket({
        type: 'booking',
        data: booking,
        bookingPrice: bookingPrice,
        resourceProducts: resourceProducts,
        previewInvoice: customer != null,
      });

    this.hideBookingModal();
    bookingsStore.closeNewBooking();
    this.props.close();
  };

  refreshFullCalendar() {
    var fc = window.getFullCalendarRef && window.getFullCalendarRef();
    if (fc) {
      let calendarApi = fc.current.getApi();
      calendarApi.refetchEvents();
    }
  }

  saveBooking = async () => {
    const { t, appStore, router, bookingsStore, authStore, checkoutStore } =
      this.props;
    const { isLoadingBookingPrice, bookingPrice, booking, resourceProducts } =
      bookingsStore;
    const { customer } = authStore;
    const { configuration } = appStore;
    const cancelUnpaidMembers =
      configuration['Bookings.CancelUnPaidBookingsForSubscribers'];
    const cancelUnpaidContacts =
      configuration['Bookings.CancelUnPaidBookingsForNonSubscribers'];
    const isMember = customer.IsMember;

    if (isLoadingBookingPrice) return;

    //New booking
    if (!(booking?.Id > 0)) {
      this.hideBookingModal();
      router.push(routes.checkout);

      const resource = this.getResource(booking);
      const hasShift = resource.Shifts?.length > 0;

      if (hasShift)
        await this.addSplitBookingToBasket(
          booking,
          booking.FromTime,
          booking.ToTime
        );
      else
        await checkoutStore.addToBasket({
          type: 'booking',
          data: booking,
          bookingPrice: bookingPrice,
          resourceProducts: resourceProducts,
          previewInvoice: customer != null,
        });

      bookingsStore.closeNewBooking();
      this.props.close();

      return;
    }

    //Existing booking
    bookingsStore
      .saveBooking()
      .then((result) => {
        bookingsStore.loadUpcomingBookings().catch(() => {
          //swallow exceptions in this case.
        });
        this.hideBookingModal();
        appStore.setPopMessage(result.Message).then(() => {
          if (result.Status !== 200) this.showBookingModal();
          else {
            this.props.close();

            if (
              (isMember && cancelUnpaidMembers) ||
              (!isMember && cancelUnpaidContacts)
            ) {
              router.push(routes.invoices);
            }
          }
        });
      })
      .then(() => {
        this.refreshFullCalendar();
      })
      .catch(() => {
        this.hideBookingModal();
        appStore
          .setPopMessage(
            t('Could not save this booking. Please try again later.')
          )
          .then(this.showBookingModal);
      });
  };

  getCurrentBooking = () => {
    const { bookingsStore } = this.props;
    const { booking } = bookingsStore;
    return booking;
  };

  @action deleteBooking() {
    const booking = this.getCurrentBooking();
    const { t, appStore, bookingsStore } = this.props;
    this.hideBookingModal();
    appStore
      .setYesNotQuestion(t('Would you like to cancel this booking?'))
      .then((response) => {
        if (response) {
          bookingsStore
            .deleteBooking(booking.Id)
            .then((result) => {
              if (result?.Status == 500)
                appStore.setPopMessage(
                  result.Message
                    ? t(result.Message)
                    : t('Please contact this space to change this booking.')
                );
            })
            .then(() => bookingsStore.loadUpcomingBookings())
            .then(() => {
              this.refreshFullCalendar();
            })
            .then(() => this.props.close())
            .catch(() => {
              this.hideBookingModal();
              appStore
                .setPopMessage(
                  t('Could not cancel this booking. Please try again later.')
                )
                .then(this.showBookingModal);
            });
        } else {
          this.showBookingModal();
        }
      });
  }

  render() {
    const { t, componentName, appStore, authStore, bookingsStore } = this.props;
    const {
      isSavingBookingToCreate,
      isSavingBookingToUpdate,
      isLoadingBookingPrice,
      bookingPrice,
      booking,
    } = bookingsStore;
    const { isLoggedIn, customer } = authStore;
    const { configuration } = appStore;
    const isSavingBooking = isSavingBookingToCreate || isSavingBookingToUpdate;

    const isCorporateDashboard = appStore.corporateDashboard;

    const bookingCanBeMade =
      bookingPrice && bookingPrice.IsAvailable && !bookingPrice.Message;

    const disableButtons =
      !bookingCanBeMade || isLoadingBookingPrice || isSavingBooking;

    const resource = this.getResource(booking);

    const addToBasketButton = (
      <button
        disabled={disableButtons}
        onClick={() =>
          !disableButtons && this.addToBasket({ goToBasket: !isLoggedIn })
        }
        id="add-to-basket-button"
        className="btn btn-outline d-flex w-100 mt-8"
        type="button"
      >
        {t('Add to basket')}
      </button>
    );
    if (!booking) return <></>;
    return (
      <div
        data-component-name={componentName}
        className="booking-form-tool-bar"
      >
        {!isCorporateDashboard && (
          <>
            {isLoggedIn &&
              !booking.Id > 0 &&
              configuration['Bookings.RequestDrafts'] && (
                <div className="mt-12">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="proposal"
                      disabled={disableButtons}
                      checked={booking.Tentative}
                      onChange={(ev) =>
                        !disableButtons &&
                        this.updateBooking('Tentative', ev.target.checked)
                      }
                    />
                    <label
                      className="custom-control-label fs-12 pt-3"
                      htmlFor="proposal"
                    >
                      {t(
                        'I am not ready to book, send me a proposal with the details of this booking instead.'
                      )}
                    </label>
                  </div>
                </div>
              )}
          </>
        )}

        <div className="mt-24 confirm-booking-button">
          {resource && isLoggedIn && !booking.inBasket && (
            <button
              onClick={() => !disableButtons && this.saveBooking()}
              disabled={disableButtons}
              className="btn d-flex w-100 mt-8"
              type="button"
            >
              {!isSavingBooking &&
                !booking.Tentative &&
                !resource.RequiresConfirmation && (
                  <span>{t('Confirm this booking')}</span>
                )}

              {!isSavingBooking &&
                !booking.Tentative &&
                resource.RequiresConfirmation && (
                  <span>{t('Request booking')}</span>
                )}

              {!isSavingBooking && booking.Tentative && (
                <span>{t('Send proposal')}</span>
              )}
              {isSavingBooking && <LoadingSpinner />}
            </button>
          )}

          {(!isLoggedIn || booking.inBasket) && addToBasketButton}

          <a
            onClick={(ev) => {
              ev.preventDefault();
              this.props.close();
            }}
            href="#"
            className="btn btn-outline d-flex w-100 mt-8 close-booking-button"
          >
            {t('Close')}
          </a>

          {isLoggedIn && booking.Id == 0 && <>{addToBasketButton}</>}

          {!booking.inBasket && booking.Id > 0 && (
            <button
              disabled={disableButtons}
              onClick={() => !disableButtons && this.deleteBooking()}
              className="btn d-flex w-100 mt-8 btn-outline cancel-booking-button"
              type="button"
            >
              {t('Cancel this booking')}
            </button>
          )}
        </div>
      </div>
    );
  }
}
export default BookingFormToolBar;
