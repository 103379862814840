import React, {useEffect} from 'react';
import Link from 'next/link';
import {routes} from 'env/routes';
import {useTranslation} from 'react-i18next';
import {withRouter} from 'next/router';
import {inject, observer} from 'mobx-react';
import ScrollableAnchor from 'react-scrollable-anchor';
import withCustomComponent from 'ui/components/withCustomComponent';
import {LocalizedPrice} from 'env/utils/NumbersLocalization';
import PlansTable from 'ui/_pages/plan/PlansTable';

let HomePlansSection = ({router, authStore, invoicingStore, appStore, componentName}) => {
  const {t} = useTranslation();
  const {homePage} = appStore;
  const isVirtualDashboard = appStore.virtualDashboard;

  const onPlanSelected = (plan) => {
    router.push(
        /*routes.signup_plan_link('[tariff_guid]'),
        routes.signup_plan_link(plan.tariff)*/
        routes.plans_plan(),
        routes.plans_plan(plan.tariff.UniqueId),
    );
  };

  return (<>
    {appStore.homePage?.PricePlans?.length > 0 && (
        <ScrollableAnchor id={'priceplans'}>
          <div
              data-component-name={componentName}
              className="home-plans-section"
          >
            <div className="container">
              <h2 className="h1 mb-32 text-center">
                {isVirtualDashboard
                    ? t('Plans and Benefits')
                    : t('Our plans')}
              </h2>
              <PlansTable
                  onPlanSelected={(plan) => onPlanSelected(plan)}
                  hideTitle={true}
                  showStartDate={false}
              />
            </div>
          </div>
        </ScrollableAnchor>
    )}
  </>);
};

HomePlansSection = withCustomComponent('HomePlansSection')(
    inject('authStore', 'appStore', 'invoicingStore')(
        withRouter(
            observer(HomePlansSection),
        ),
    ),
);

export default HomePlansSection;
