import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import asForm from 'ui/components/forms/asForm';
import BooleanInputField from 'ui/components/forms/BooleanInputField';

export const NewsletterSignUpCheckbox = asForm(
  withCustomComponent('NewsletterSignUpCheckbox')(
    inject(
      'authStore',
      'appStore'
    )(
      withRouter(
        observer(
          ({
            componentName,
            updateProperty,
            customer,
            authStore,
            appStore,
          }) => {
            const { t } = useTranslation();
            const { business } = appStore;
            return (
              <div data-component-name={componentName}>
                <BooleanInputField
                  label={t(
                    'I would like to receive occasional and relevant updates from {{Name}} by email.',
                    { Name: t(business.Name) }
                  )}
                  name="SignUpToNewsletter"
                  value={customer.SignUpToNewsletter}
                  onChange={updateProperty}
                  type="checkbox"
                />
              </div>
            );
          }
        )
      )
    )
  ),
  'customer'
);
