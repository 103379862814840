import React, {useEffect, useState} from 'react';
import Link from 'next/link';
import {routes} from 'env/routes';
import {useTranslation} from 'react-i18next';
import {withRouter} from 'next/router';
import {inject, observer} from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import ReactPaginate from 'react-paginate';
import copy from 'copy-to-clipboard';

let ContactsWidget = props => {
  const {
    appStore,
    authStore,
    bookingsStore,
    team,
    members,
    itemsPerPage = 10,
  } = props;
  const {t} = useTranslation();
  const {customer} = authStore;
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(null);
  const [itemOffset, setItemOffset] = useState(null);
  const [order, setOrder] = useState({name: 'Active', direction: 'desc'});

  // Pagination handlers
  useEffect(() => {
    let list = team.AllTeamMembers.sort((a, b) => {
      if (order.name === 'Active') {
        if (order.direction === 'asc')
          return a[order.name] ? 1 : -1;

        return !a[order.name] ? 1 : -1;
      }

      return 0;
    });

    setCurrentItems(list.slice(itemOffset, itemOffset + itemsPerPage));
    setPageCount(Math.ceil(list.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, order]);

  const handlePageClick = e => {
    setItemOffset(e.selected * itemsPerPage);
  };

  if (!team) {
    return <></>;
  }

  if (itemOffset === null) {
    setItemOffset(0);
  }

  return (
    <div className="card-item-wrapper">
      <div className="card-item-header">
        <h2 className="card-item-header-title-dashboard">
          {t('Team members')} {team.Name}
        </h2>
        {/*<div className="btn-group justify-content-end">
          <Link href={routes.resources}>
            <a className="btn btn-gray">
              {t('Add/Remove member')}
            </a>
          </Link>
        </div>*/}
      </div>

      <div className="table-wrap table-wrap-tablet table-responsive">
        <table className="table table-custom mb-0">
          <thead>
          <tr>
            <th>
              {t('Name')}
            </th>
            <th>
              {t('Email')}
            </th>
            <th className="position-relative sorting-item">
              <span
                className={order.direction ? order.direction : ''}
                onClick={() => {
                  setOrder({name: 'Active', direction: order.direction === 'desc' ? 'asc' : 'desc'});
                }}
              >ORDER {order ? order.direction : ''} </span>
              {t('Status')}
              <i className="icon-sorting align-middle fs-18"></i>
            </th>
          </tr>
          </thead>
          <tbody>
          {currentItems.map((member, i) => {
            return <tr key={i}>
              <td data-title="Name">
                {member.FullName}
              </td>
              <td data-title="Email">
                {member.Email}
                <i
                  className="icon-copy ml-10 fs-18 align-middle" data-toggle="tooltip" data-placement="top" title={t('Copy')}
                  onClick={() => copy(member.Email)}
                ></i>
              </td>
              <td data-title="Status">
                {member.Active ? (
                  <div className="d-inline-flex table-item-status">
                    {t('Active')}
                  </div>
                ) : (
                  <div className="d-inline-flex table-item-status table-item-status-cancelled">
                    {t('Not active')}
                  </div>
                )}
              </td>
            </tr>;
          })}
          </tbody>
        </table>
      </div>
      <nav>
        <ReactPaginate
          breakLabel="..."
          nextLabel={(
            <span aria-hidden="true">
                <i className="icon-chevron-right"></i>
              </span>
          )}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={(
            <span aria-hidden="true">
                <i className="icon-chevron-right"></i>
              </span>
          )}
          renderOnZeroPageCount={null}
          className="pagination justify-content-center"
          previousLinkClassName="btn prev"
          nextLinkClassName="btn next"
          pageLinkClassName="btn"
          activeClassName="active"
          breakLinkClassName="dots"
        />
      </nav>
    </div>
  );
};

export default ContactsWidget = withCustomComponent('ContactsWidget')(
  inject('appStore', 'authStore', 'bookingsStore')(
    withRouter(
      observer(ContactsWidget),
    ),
  ),
);
