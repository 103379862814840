import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { linkTo } from 'env/utils/strings';

export const BlogPageFilters = withCustomComponent('BlogPageFilters')(
  inject(
    'appStore',
    'blogStore'
  )(
    withRouter(
      observer(({ router, blogStore, componentName }) => {
        const { t } = useTranslation();
        const { blogPosts } = blogStore;

        const selectChange = (e) => {
          router.push(
            linkTo(router, {
              page: 1,
              [e.target.name]: e.target.value == 0 ? null : e.target.value,
            })
          );
        };

        const doSearch = (searchTerm) => {
          router.push(linkTo(router, { ...router.query, query: searchTerm }));
        };

        return (
          <div
            data-component-name={componentName}
            className="blog-page-filters"
          >
            <div className="list-inline-32 sm-column mt-16">
              <div className="d-flex align-items-center w-sm-100">
                <label className="text-gray-700 fs-14 mr-12" htmlFor="select-category">
                  {t('Categories')}
                  {':'}
                </label>
                <select
                  name="categoryId"
                  id="select-category"
                  value={blogPosts.Category ? blogPosts.Category.Id : 0}
                  onChange={(e) => {
                    e.preventDefault();
                    selectChange(e);
                  }}
                  className="custom-select custom-select-sm w-sm-100"
                >
                  <option value="0">{t('All')}</option>
                  {blogPosts.Categories.map((category, i) => (
                    <option key={i} value={category.Id}>
                      {category.Title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="ml-auto w-sm-100">
                <div className="d-flex align-items-center justify-content-between bg-white rounded p-4 fs-14 shadow-sm">
                  <input
                    id="query"
                    className="w-100 border-0 outline-0"
                    defaultValue={router.query.query}
                    title={t('Search articles by title or summary')}
                    placeholder={t('Search...')}
                    onKeyDown={(ev) => {
                      if (ev.keyCode == 10 || ev.keyCode == 13) {
                        ev.preventDefault();
                        doSearch(ev.target.value);
                      }
                    }}
                  />
                  <button
                    className="btn btn-xs py-0 fs-18 btn-gray"
                    onClick={(e) => {
                      e.preventDefault();
                      doSearch(document.getElementById('query').value);
                    }}
                  >
                    <i className="icon-search"></i>
                  </button>
                </div>
              </div>
            </div>

            <hr className="mt-20 mb-40" />
          </div>
        );
      })
    )
  )
);
