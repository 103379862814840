import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
@observer
class BooleanInputField extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.props.onChange(
      event.target.name,
      event.target.checked ? 'true' : null
    );
  }

  render() {
    const input = this.props;
    const hasErrors = input.errors && input.errors.length > 0;

    return (
      <>
        <div className={`custom-control custom-${input.type} ${input.className ? input.className : ''}`}>
          <input type="checkbox" className="custom-control-input" id={input.id ? input.id : input.name} name={input.name} onChange={this.onChange} checked={input.value === 'true' || input.value === true} />
          <label className={`custom-control-label ${input.labelClassName ? input.labelClassName : ''}`} htmlFor={input.id ? input.id : input.name}>{input.label}</label>
        </div>
        {hasErrors && (
          <small className="fs-12 lh-13 text-red mt-8">
            {input.errors.join(', ')}
          </small>
        )}
      </>
    );
  }
}

BooleanInputField.propTypes = {
  onChange: PropTypes.func.isRequired
};

BooleanInputField.defaultProps = {
  type: 'checkbox'
};

export default BooleanInputField;
