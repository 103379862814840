import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import { routes } from 'env/routes';
import { withRouter } from 'next/router';
import { removeHtml } from 'env/utils/strings';
import LoadingSpinner from 'ui/components/LoadingSpinner';

import { BlogMetaTags } from 'ui/_pages/blog/BlogMetaTags';
import { BlogPostMetaData } from 'ui/_pages/blog/BlogPostMetaData';
import { BlogPostDetails } from 'ui/_pages/blog/BlogPostDetails';
import { BlogPostAuthor } from 'ui/_pages/blog/BlogPostAuthor';
import { BlogPostComments } from 'ui/_pages/blog/BlogPostComments';

const BlogDetailsPage = withCustomComponent('BlogDetailsPage')(
  inject('appStore', 'blogStore')(
    withRouter(
      observer(
        ({appStore, blogStore, router, componentName}) => {
          const { t } = useTranslation();
          const { business } = appStore;
          const { blogPostPage } = blogStore;
          const blogPost = blogPostPage.BlogPost;
          const isLoading = !blogPost || blogPost.Id != router.query.post_id;

          useEffect(() => {
            loadBlogPost();
          });

          const loadBlogPost = () => {
            const postId = router.query.post_id;
        
            // Read any notifications about this record
            appStore.dismissNotification(postId).catch(() => null);
        
            if (blogPostPage.BlogPost.Id != postId) {
              blogStore.loadBlogPostPage(postId).catch((err) => {
                appStore.setPopMessage(
                  t('Sorry, we could not load this article. {{err}}', { err })
                );
              });
            }
          };

          return (
            <DefaultLayout
              componentName={componentName}
              title={t('{{Title}} - {{Name}}', {
                Title: !isLoading && blogPost ? removeHtml(blogPost.Title) : '...',
                Name: t(business.Name),
              })}
            >
              <main className="content content--custom" role="main">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-xl-1 d-none d-xl-block">
                      <Link href={routes.posts}>
                        <a className="btn btn-single-icon btn-white">
                          <i className="icon-go-back"></i>
                        </a>
                      </Link>
                    </div>
                    <div className="col-12 col-xl-10">
                      {isLoading && <LoadingSpinner />}
                      {!isLoading && (
                        <>
                          <div className="card h-auto blog-content">
                            <BlogMetaTags post={blogPost} />
                            <BlogPostMetaData post={blogPost} />
                            <BlogPostDetails post={blogPost} />
                            <BlogPostAuthor post={blogPost} />
                          </div>
                          <div className="card h-auto blog-comments mt-24">
                            <BlogPostComments />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </main>
            </DefaultLayout>
          );
        }
      )
    )
  )
)

export default BlogDetailsPage;
