/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {Trans, withTranslation} from 'react-i18next';
import {withRouter} from 'next/router';
import {routes} from 'env/routes';
import {LocalizedPrice} from 'env/utils/NumbersLocalization';
import DateInputField from 'ui/components/forms/DateInputField';
import BooleanInputField from 'ui/components/forms/BooleanInputField';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import {inject, observer} from 'mobx-react';
import i18next from 'i18next';
import moment from 'moment';
import withCustomComponent from 'ui/components/withCustomComponent';
import {PlanFrequencyLabel} from 'ui/_pages/plan/PlanFrequencyLabel';
import clip from 'text-clipper';
import Link from 'next/link';
import * as prices from 'env/utils/prices';
import {action, observable} from 'mobx';
import Fancybox from './fancybox.js';

const formData = observable({
  startDate: null,
});

let PlanDetails = props => {
  let {
    isLoading,
    match,
    service,
    t,
    showStartDate,
    startDate,
    appStore,
    validationStore,
    router,
    // Design & layout
    design,
    showGallery,
    buttonLabel,
    buttonAsLink,
    buttonLink,
    hideButton,
    displayAgreements,
    // Actions
    onPlanSelected,
    onShowTermsFor,
    onShowDescriptionFor,
    onChangeDate,
    onAgreedTc,
  } = props;

  const [clicked, setClicked] = useState(false);
  const [agreedTc, agreedTcClicked] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [planImages, setPlanImages] = useState([
    service.CfImage_1,
    service.CfImage_2,
    service.CfImage_3,
  ]);

  const shouldBeDisabled = !displayAgreements &&
    (isLoading || (appStore.configuration['Members.CanSignup'] && service.TermsAndConditions && !agreedTc));
  const clippedHtml = clip(service.Description, 100, {
    html: true,
    maxLines: 2,
  });
  const isClipped = service.Description?.length > clippedHtml?.length;
  const isCorporateDashboard = appStore.corporateDashboard;

  buttonLabel = appStore.configuration['Members.CanSignup'] ? (buttonLabel || t('Join')) : t('Contact Us');

  useEffect(() => {
    // Set up validation
    validationStore.setup('planDetails', formData);

    if (typeof onAgreedTc === 'function') {
      onAgreedTc(displayAgreements ? true : !shouldBeDisabled);
    }
  }, []);

  useEffect(action(() => {
    formData.startDate = startDate;
  }), [props.startDate]);

  useEffect(() => {
    if (typeof onAgreedTc === 'function') {
      onAgreedTc(displayAgreements ? true : !shouldBeDisabled);
    }
  }, [agreedTc]);

  function convertToSlug(Text) {
    return Text.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
  }

  if (!(validationStore.validation.planDetails)) {
    return <LoadingSpinner/>;
  }

  let layout = (
    <div className="card p-16 p-md-24 d-flex flex-direction-column justify-content-between">
      <div className="pb-40">
        <h5>{service.Name}</h5>

        {isCorporateDashboard == true ? (<></>) : (<>
          <h3 className="mb-0">
            {LocalizedPrice({
              lang: i18next.language,
              currency: service.Currency.Code,
              amount: service.PriceDecimal,
            })}
          </h3>
          <p className="text-gray-700 mb-24">
            <PlanFrequencyLabel service={service}/>
          </p>
        </>)}

        <p className="fs-14 text-gray-700"
           dangerouslySetInnerHTML={{__html: clippedHtml}}
        >
        </p>
        {isClipped && (
          <div>
            <a
              onClick={(ev) => {
                ev.preventDefault();
                onShowDescriptionFor(service);
              }}
              className="btn btn-link text-gray-700 fs-14 tdu"
              href="#"
            >
              {t('Read more')}
            </a>
          </div>
        )}
      </div>

      <div>
        {(!displayAgreements && appStore.configuration['Members.CanSignup'] && service.TermsAndConditions) && (
          <div className="mb-8">
            <BooleanInputField
              label={
                <Trans>
                  <span>I agree to these </span>
                  <a
                    href="#"
                    onClick={(ev) => {
                      ev.preventDefault();
                      onShowTermsFor(service);
                    }}
                  >
                    terms and conditions
                  </a>
                </Trans>
              }
              id={service.Id + '-TermsAndConditions'}
              labelClassName={'fs-12 pt-4'}
              value={agreedTc}
              onChange={(prop, checked) => {
                agreedTcClicked(checked);
              }}
              type="checkbox"
            ></BooleanInputField>
          </div>
        )}

        {(showStartDate != false && appStore.configuration['Members.CanSignup']) && (
          <DateInputField
            id={service.Id + '-FromTime'}
            label={t('Choose a start date')}
            name="startDate"
            value={startDate}
            onChange={(prop, value) => onChangeDate(value)}
            className={`mb-8`}
            disabled={shouldBeDisabled}
            customProps={{
              min: new Date(),
            }}
          />
        )}

        <a
          href="#"
          onClick={(ev) => {
            if (shouldBeDisabled) return;
            ev.preventDefault();
            setClicked(true);

            if (!appStore.configuration['Members.CanSignup']) {
              router.push(routes.contact);
              return;
            }

            if (typeof onPlanSelected === 'function') {
              onPlanSelected({
                tariff: service,
                startDate: moment(startDate).startOf('day').toDate(),
              });
            }
          }}
          className={`btn w-100 ${shouldBeDisabled ? 'disabled' : ''}`}
        >
          {clicked && isLoading ? <LoadingSpinner/> : buttonLabel || t('Join')}
        </a>
      </div>
    </div>
  );

  if (design === 'modern') {
    let description = <>
      <p className="mb-8">
        {t('Description')}
      </p>
      <div className="funnel-steps-advance-payment-information">
        <div dangerouslySetInnerHTML={{__html: service.Description ?? ''}}></div>
      </div>
    </>;

    layout = (
      <div className="card-item-wrapper">
        <div className="card-item-header d-none d-md-flex">
          <h2 className="card-item-header-title">
            {service.Name}
          </h2>
        </div>
        <div className="card-item-body">
          <div className="row">
            <div className={showGallery ? 'col-md-6' : 'col-md-6 col-lg-3 col-xl-2'}>
              {showGallery ? (
                <div className="plan-gallery mb-24 mb-md-0">
                  <Fancybox>
                    <a className="image-grid-col-2 image-grid-row-2" data-fancybox="gallery" href={service.CfImage_1}>
                      <img className="img-fluid" alt=""
                           src={service.CfImage_1}/>
                    </a>
                    <a data-fancybox="gallery" href={service.CfImage_2}>
                      <img className="img-fluid" alt=""
                           src={service.CfImage_2}/>
                    </a>
                    <a data-fancybox="gallery" href={service.CfImage_3}>
                      <img className="img-fluid" alt=""
                           src={service.CfImage_3}/>
                    </a>
                  </Fancybox>
                </div>
              ) : (
                <div className="mb-20 mt-n20 mx-n20 mt-md-0 mx-md-0">
                  <img className="img-fluid w-100"
                       src={service.CfImage_1}
                       alt=""/>
                </div>
              )}
            </div>
            <div className={showGallery ? 'col-md-6' : 'col-lg-9 col-xl-10'}>
              <div className="row">
                {!showGallery && (<div className="col-md-12 col-lg-6 col-xl-5">
                  <h2 className="plan-title-mobile fs-18 d-block d-md-none">
                    {service.Name}
                  </h2>
                  {description}
                </div>)}
                <div className={showGallery ? 'col-md-12' : 'col-md-12 col-lg-6 col-xl-4 ml-auto'}>
                  <hr className="my-16 d-block d-md-none"/>
                  {/*Start date*/}
                  {(showStartDate != false && appStore.configuration['Members.CanSignup']) && (<>
                    <p className="funnel-steps-select-time-title">
                      {t('Select a start date')}
                    </p>
                    <DateInputField
                      id={service.Id + '-FromTime'}
                      /*label={t('Choose a start date')}*/
                      name="startDate"
                      value={startDate}
                      onChange={action((prop, value) => {
                        onChangeDate(value);
                      })}
                      className={`mb-8`}
                      /*disabled={shouldBeDisabled}*/
                      customProps={{
                        min: new Date(),
                      }}
                      errors={validationStore.validation.planDetails.errors.startDate}
                    />
                  </>)}
                  <div className="d-flex align-items-center justify-content-between mb-4 mt-24">
                    <div>
                      {t('Monthly rent')}
                    </div>
                    <div>
                      {prices.format(prices.excludeTax(service.PriceDecimal, service.TaxRate))}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                      {t('VAT')}
                    </div>
                    <div>
                      {prices.format(prices.retrieveTax(service.PriceDecimal, service.TaxRate),
                        {currencyCode: false})}
                    </div>
                  </div>
                  <hr className="my-16"/>
                  <div className="d-flex align-items-center justify-content-between mb-16">
                    <div>
                      {t('Monthly rent including VAT')}
                    </div>
                    <div className="color funnel-steps-price-total fs-18">
                      <strong>
                        {prices.format(service.TotalPrice)}
                      </strong>
                    </div>
                  </div>
                  {showGallery && description}
                  {(!displayAgreements && appStore.configuration['Members.CanSignup'] && service.TermsAndConditions) && (
                    <div className="mb-8">
                      <BooleanInputField
                        label={
                          <Trans>
                            <span>I agree to these </span>
                            <a
                              href="#"
                              onClick={(ev) => {
                                ev.preventDefault();
                                onShowTermsFor(service);
                              }}
                            >
                              {t('terms and conditions')}
                            </a>
                          </Trans>
                        }
                        id={service.Id + '-TermsAndConditions'}
                        labelClassName={'fs-12 pt-4'}
                        value={agreedTc}
                        onChange={(prop, checked) => {
                          agreedTcClicked(checked);
                        }}
                        type="checkbox"
                      ></BooleanInputField>
                    </div>
                  )}
                  {!hideButton && (<div className="btn-group flex-wrap flex-md-nowrap">
                    {buttonAsLink ? (
                      <Link href={shouldBeDisabled ? '' : buttonLink}>
                        <a className={`btn btn-gray w-100 ${shouldBeDisabled ? 'disabled' : ''}`}>
                          {clicked && isLoading ? <LoadingSpinner/> : buttonLabel || t('Join')}
                        </a>
                      </Link>
                    ) : (
                      <a
                        href="#"
                        onClick={(ev) => {
                          if (shouldBeDisabled) return;

                          ev.preventDefault();
                          setClicked(true);

                          if (!appStore.configuration['Members.CanSignup']) {
                            router.push(routes.contact);
                            return;
                          }

                          if (typeof onPlanSelected === 'function') {
                            onPlanSelected({
                              tariff: service,
                              startDate: moment(startDate).startOf('day').toDate(),
                            });
                          }
                        }}
                        className={`btn w-100 ${shouldBeDisabled ? 'disabled' : ''}`}
                      >
                        {clicked && isLoading ? <LoadingSpinner/> : buttonLabel || t('Join')}
                      </a>
                    )}
                  </div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return layout;
};

PlanDetails = withCustomComponent('PlanDetails')(
  withRouter(
    withTranslation()(
      inject('appStore', 'validationStore')(
        observer(PlanDetails),
      ),
    ),
  ),
);

export default PlanDetails;
