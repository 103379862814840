import React, {Component} from 'react';

import {withTranslation} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import moment from 'moment';

@withCustomComponent('CreditDetails')
@withTranslation()
class CreditDetails extends Component {
  componentDidMount() {
    const $ = window.$;
    if ($('.js-table-settings').length > 0) {
      $('.js-table-settings-link')
        .not('.processed')
        .addClass('processed')
        .on('click', function (e) {
          $(this)
            .parent()
            .toggleClass('open')
            .parent()
            .parent()
            .next()
            .toggleClass('open');
          e.preventDefault();
        });
    }
  }

  render() {
    const {group, t} = this.props;
    return (
      <>
        <tr className="tr-parent js-table-settings-tr-parent">
          <th data-title={t('Item')}>
            <div className="table--settings__handler">
              <a
                href="#"
                className="table--settings__title js-table-settings-link"
              >
                <span className="icon-add caret"></span>
                {group.CreditDetailsList.length} {'x '}
                {group.CreditName != null && group.CreditName}
                {group.CreditName == null && 'Credit'}
              </a>
              {group.CreditDescription != null && (
                <small>{group.CreditDescription}</small>
              )}
            </div>
          </th>
          <td data-title={t('Quantity')}>
            {group.TotalHours > 0 && (
              <span>{t('{{count}} hour', {count: group.TotalHours})} </span>
            )}
            {group.TotalDays > 0 && (
              <span>{t('{{count}} day', {count: group.TotalDays})} </span>
            )}
            {group.TotalMonths > 0 && (
              <span>{t('{{count}} month', {count: group.TotalMonths})} </span>
            )}
            {group.TotalWeeks > 0 && (
              <span>{t('{{count}} week', {count: group.TotalWeeks})} </span>
            )}
            {group.TotalUses > 0 && (
              <span>{t('{{count}} use', {count: group.TotalUses})} </span>
            )}
            {!group.TotalHours > 0 && group.Count > 0 && (
              <span>{t('{{count}} pass', {count: group.Count})} </span>
            )}
            {group.TotalCredit > 0 && (
              <span>
                {t('{{count}} credit', {count: group.TotalCredit})}{' '}
              </span>
            )}
          </td>
          <td data-title={t('Remaining')}>
            {group.MinutesLeft > 0 && (
              <span>{t('{{count}} hour', {count: group.HoursLeft})} </span>
            )}
            {group.DaysLeft > 0 && (
              <span>{t('{{count}} day', {count: group.DaysLeft})} </span>
            )}
            {group.MonthsLeft > 0 && (
              <span>{t('{{count}} month', {count: group.MonthsLeft})} </span>
            )}
            {group.WeeksLeft > 0 && (
              <span>{t('{{count}} week', {count: group.WeeksLeft})} </span>
            )}
            {group.UsesLeft > 0 && (
              <span>{t('{{count}} use', {count: group.UsesLeft})} </span>
            )}
            {!group.MinutesLeft > 0 && group.PassesLeft > 0 && (
              <span>{t('{{count}} pass', {count: group.PassesLeft})} </span>
            )}
            {group.RemainingCredit > 0 && (
              <span>
                {t('{{count}} credit', {count: group.RemainingCredit})}{' '}
              </span>
            )}
          </td>
        </tr>
        <tr className="tr-child">
          <td colSpan="3">
            <ul className="table--settings__details-list">
              {group.CreditDetailsList.map((tp, i) => (
                <li key={i}>
                  {tp.TimePass && tp.TimePass.Name}
                  {tp.ExtraService && tp.ExtraService.Name}
                  {tp.Name}
                  <small>
                    {tp.Used && tp.UsedDate != null && (
                      <>
                        {t('You used this benefit on {{UsedDate, L}}', {
                          UsedDate: moment(tp.UsedDate).format('LL'),
                        })}
                      </>
                    )}
                    {tp.ExpireDate != null && (
                      <>
                        {tp.Used && tp.UsedDate != null && <br/>}
                        {t('Expires on {{ExpireDate, L}}', {
                          ExpireDate: moment(tp.ExpireDate).format('LL'),
                        })}
                      </>
                    )}
                    {tp.ExpireDate == null && <>{t('Never expires.')}</>}
                  </small>
                </li>
              ))}
            </ul>
          </td>
        </tr>
      </>
    );
  }
}

export default CreditDetails;
