/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';
import withCustomComponent from 'ui/components/withCustomComponent';
@withCustomComponent('AdditionalChargesRow')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class AdditionalChargesRow extends Component {
  render() {
    const { appStore, benefit, componentName } = this.props;
    const { business } = appStore;

    return (
      <tr data-component-name={componentName}>
        <td>
          <div className="d-flex align-items-center">
            <img
              className="avatar mr-8"
              src={`${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${benefit.CoworkerId}?h=64&w=64&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}
            />
            <span>{benefit.CoworkerFullName}</span>
          </div>
        </td>
        <td className="text-right">
          {LocalizedPrice({
            currency: business.Currency.Code,
            amount: benefit.TotalUninvoiced,
          })}
        </td>
      </tr>
    );
  }
}

export default AdditionalChargesRow;
