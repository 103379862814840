import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {withRouter} from 'next/router';
import {inject, observer} from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const TeamInviteLink = withCustomComponent(
  'TeamInviteLink'
)(
  inject(
    'appStore',
    'authStore'
  )(
    withRouter(
      observer(({appStore, team}) => {
        const {t} = useTranslation();
        const {business} = appStore;

        useEffect(() => {
          initToolTip();
        }, []);

        const initToolTip = () => {
          const tooltipEl = $('#invite-link-copy-to-clipboard');

          if (tooltipEl) {
            tooltipEl
              .tooltip('dispose')
              .tooltip({
                html: true,
                title: t('Copy link to clipboard'),
              });
          }
        };

        const changeTooltip = (title, showImmediately) => {
          const tooltipEl = $('#invite-link-copy-to-clipboard');

          if (tooltipEl) {
            tooltipEl
              .tooltip('dispose')
              .tooltip({
                title: t(title),
              });

            if (showImmediately) {
              tooltipEl.tooltip('show');
            }
          }
        };

        return (
          <fieldset>
            <div className="card-item-wrapper">
              <div className="card-item-header">
                <h5 className="card-item-header-title">
                  {t('Invite Link')}
                </h5>
              </div>
              <div className="card-item-body">
                <div className="form-group">
                  <label className="control-label" htmlFor="Team_SignupLink">
                    {t('Invite link')}
                  </label>
                  <div className="input-group">
                    <input
                      readOnly="readonly"
                      className="form-control"
                      type="text"
                      id="Team_SignupLink"
                      value={`${business.HomeUrl}/signup/${team.UniqueId}`}
                    />
                    <span className="input-group-append">
                    <CopyToClipboard
                      text={`${business.HomeUrl}/signup/${team.UniqueId}`}
                      onCopy={() => {
                        changeTooltip('Copied!', true);

                        $('#invite-link-copy-to-clipboard').on(
                          'hidden.bs.tooltip',
                          (e) => {
                            changeTooltip('Copy link to clipboard');
                          }
                        );
                      }}
                    >
                      <i
                        className="icon-ticket"
                        id="invite-link-copy-to-clipboard"
                        style={{cursor: 'pointer'}}
                        data-toggle="tooltip"
                      ></i>
                    </CopyToClipboard>
                  </span>
                  </div>
                  <small>
                    {t(
                      'You can send this link to anyone who you want to add to your team'
                    )}
                  </small>
                </div>
              </div>
            </div>
          </fieldset>
        );

      })
    )
  )
);