import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import router, { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import DateInputField from 'ui/components/forms/DateInputField';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { action } from 'mobx';
import moment from 'moment';
export const FloorPlanDateFilters = withCustomComponent('FloorPlanDateFilters')(
  inject(
    'appStore',
    'floorPlanStore'
  )(
    withRouter(
      observer(({ floorPlanStore, componentName, appStore }) => {
        const { t } = useTranslation();

        const doSearch = () => {
          floorPlanStore.loadItems().catch((err) => {
            appStore.setPopMessage(
              t('Sorry, we could not load this page. {{err}}', { err })
            );
          });
        };

        const updateByShift = (shiftId) => {
          floorPlanStore.selectShift(shiftId);
          doSearch();
        };

        const updateDates = action((name, value) => {
          //If we are moving the fromTime, shift the toTime by the same amount.
          if (name == 'fromTime') {
            var diff = moment(value).diff(floorPlanStore[name], 'minutes');
            floorPlanStore.toTime = moment(floorPlanStore.toTime)
              .add(diff, 'minutes')
              .toDate();
          }

          floorPlanStore[name] = value;
          doSearch();
        });

        const { shiftId, isLoadingItems, shifts } = floorPlanStore;
        const hasShifts = shifts.length > 0;
        return (
          <form
            data-component-name={componentName}
            className="form form--floorplans"
          >
            <div className="row">
              <div className="col-sm-3 col-md-2">
                <div className="form-group">
                  <DateInputField
                    label={t('From date')}
                    icon="fa-calendar"
                    name="fromTime"
                    value={floorPlanStore.fromTime}
                    onChange={updateDates}
                  />
                </div>
              </div>
              {!hasShifts && (
                <div className="col-sm-3 col-md-2">
                  <div className="form-group">
                    <DateInputField
                      showTimeSelect={true}
                      label={t('From time')}
                      icon="fa-clock-o"
                      name="fromTime"
                      value={floorPlanStore.fromTime}
                      onChange={updateDates}
                    />
                  </div>
                </div>
              )}
              <div className="col-sm-3 col-md-2">
                <div className="form-group">
                  <DateInputField
                    label={t('Until date')}
                    icon="fa-calendar"
                    name="toTime"
                    value={floorPlanStore.toTime}
                    onChange={updateDates}
                  />
                </div>
              </div>
              {!hasShifts && (
                <div className="col-sm-3 col-md-2">
                  <div className="form-group">
                    <DateInputField
                      showTimeSelect={true}
                      label={t('Until time')}
                      icon="fa-clock-o"
                      name="toTime"
                      value={floorPlanStore.toTime}
                      onChange={updateDates}
                    />
                  </div>
                </div>
              )}
              {hasShifts && (
                <div className="col-sm-6 col-md-4">
                  <div className="form-group">
                    <label className="control-label" htmlFor="select-time">{t('Time')}</label>
                    <select
                      value={shiftId}
                      id="select-time"
                      onChange={(ev) => updateByShift(ev.target.value)}
                      className="form-control"
                    >
                      {shifts.map((item, i) => (
                        <option value={item.id} key={i}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              <div className="col-md-3">
                <div className="form-actions">
                  <button
                    onClick={(ev) => {
                      ev.preventDefault();
                      doSearch();
                      ev.target.blur();
                    }}
                    className="btn btn--default btn--sm"
                    type="button"
                  >
                    {isLoadingItems ? (
                      <LoadingSpinner />
                    ) : (
                      t('Find available spaces')
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        );
      })
    )
  )
);
