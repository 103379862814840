import ReactDOMServer from 'react-dom/server';
import _ from 'lodash';
import { roundToTwoDecimals } from 'env/utils/numbers';

export const initYouTubeVideos = () => {
  const $ = window.$;
  const videos = $('iframe')
    ?.toArray()
    .filter((el) =>
      el.src ? el.src.includes('youtube.com/embed') : false
    )
    .map((el) => {
      let url = new URL(el.src);

      if (!url.searchParams.has('enablejsapi')) {
        url.searchParams.set('enablejsapi', 1);
      }

      el.src = url;
      return el;
    });

  return videos;
};

export const initVideoEvents = (videos, courseId, setCompletedVideosCount, completedVideosCount, router) => {
  const $ = window.$;
  
  for (let i = 0; i < videos.length; i++) {
    let wasCompletedBefore = localStorage.getItem(videos[i].src + courseId + '-completed') === 'true';
    if (wasCompletedBefore) {
      setCompletedVideosCount(
        (completedVideosCount) => completedVideosCount + 1
      );
    }

    let isCompleted = wasCompletedBefore;

    // add progress text and visualizer below video
    $(videos[i]).parent()
    .after(`<span class="vid-completed-msg-${i} ${!isCompleted && 'invisible'}" id="vid-completed-msg-${i}">${t('You watched 90% or more of this video!')}</span>`)
    .after(
      `<div class="vid-visual-progress vid-visual-progress-container-${i} position-relative invisible" id="vid-visual-progress-container-${i}"></div>`
    );

    let watchedSeconds = localStorage.getItem(videos[i].src + courseId) || 0;
    watchedSeconds = parseInt(watchedSeconds);
    let lastProgress = watchedSeconds; // how much progress the user made in the previous session

    let updateProgressBarInterval;

    let elapsedFromStartInterval; // interval that counts seconds from start of time range
    let elapsedFromStart = 0;

    let duration, threshold, playbackRate;

    // add events for this video
    let player = new YT.Player(videos[i], {
      events: {
        onStateChange: onPlayerStateChange,
        onPlaybackRateChange: onPlaybackRateChange,
        onError: onError,
      },
    });

    let timeRanges = localStorage.getItem(videos[i].src + courseId + '-timeranges');
    timeRanges = timeRanges ? JSON.parse(timeRanges) : [];

    let currentTimeRangeIndex = timeRanges.length;

    addSaveEvents(timeRanges, videos[i].src + courseId + '-timeranges', player, router);

    function onPlayerStateChange(event) {
      handleTimeRanges(event.data);

      if (
        event.data == YT.PlayerState.BUFFERING ||
        event.data == YT.PlayerState.PAUSED ||
        event.data == YT.PlayerState.ENDED
      ) {
        clearInterval(updateProgressBarInterval);
        updateProgressBarInterval = null;
        return;
      }

      if (
        event.data == YT.PlayerState.PLAYING &&
        !isCompleted
      ) {
        $(`.vid-visual-progress-container-${i}`).removeClass('invisible');

        duration = player.getDuration();
        playbackRate = player.getPlaybackRate();
        threshold = duration * 0.9;

        updateProgressBar();
        updateProgressBarInterval = setInterval(updateProgressBar, 1000 / playbackRate);
      }
    }

    function onPlaybackRateChange(event) {
      playbackRate = event.data;

      if(player.getCurrentTime() > lastProgress) {
        // progressInterval = setInterval(
        //   checkWatchProgress,
        //   1000 / playbackRate,
        //   completedVideosCount,
        //   url
        // );
      }
    }

    function onError(event) {
      console.log('Error with YouTube video', event.data);
    }

    function handleTimeRanges(playerState) {
      if(playerState == YT.PlayerState.PLAYING) {
        const currTime = Math.round(player.getCurrentTime());
        const currTimeRange = timeRanges[currentTimeRangeIndex];
        const playbackRate = player.getPlaybackRate();

        if(currTimeRange == undefined) {
          timeRanges[currentTimeRangeIndex] = {
            start: currTime
          };

          if(!elapsedFromStartInterval) {
            elapsedFromStartInterval = setInterval(
              () => elapsedFromStart++,
              1000 / playbackRate
            );
          }

          return;
        }

        // if currTimeRange has an end, we create a new time range
        if(typeof currTimeRange.end === 'number') {
          currentTimeRangeIndex++;

          timeRanges[currentTimeRangeIndex] = {
            start: currTime
          };

          if(!elapsedFromStartInterval) {
            elapsedFromStartInterval = setInterval(
              () => elapsedFromStart++,
              1000 / playbackRate
            );
          }
        }
      }

      // every time the user skips to a part of the video,
      // the state always becomes PAUSED immediately before becoming PLAYING
      if(playerState == YT.PlayerState.PAUSED || playerState == YT.PlayerState.ENDED) {
        const currTime = Math.round(player.getCurrentTime());
        const currTimeRange = timeRanges[currentTimeRangeIndex];

        let newEndTime = currTimeRange.start + elapsedFromStart;

        timeRanges[currentTimeRangeIndex] = {
          ...currTimeRange,
          end: newEndTime
        };

        clearInterval(elapsedFromStartInterval);
        elapsedFromStartInterval = null;
        elapsedFromStart = 0;
      }
    }

    function generateProgressBars() {
      const progressBars = [];
      const sortedTimeRanges = _.sortBy(timeRanges, (timeRange) => timeRange.start);

      let leftOffset = 0;

      for (let i = 0; i < sortedTimeRanges.length; i++) {
        const end = typeof sortedTimeRanges[i].end === 'number' ? sortedTimeRanges[i].end : Math.round(player.getCurrentTime());
        const timeRangeValue = end - sortedTimeRanges[i].start + 1;
        const timeRangePercentage = isNaN(timeRangeValue) ? 0 : roundToTwoDecimals(timeRangeValue * 100 / duration);

        // if an earlier time range exists
        if(i > 0) {
          const prevEnd = sortedTimeRanges[i-1].end || Math.round(player.getCurrentTime());
          const difference = sortedTimeRanges[i].start - prevEnd;

          leftOffset += roundToTwoDecimals((prevEnd - sortedTimeRanges[i-1].start) * 100 / duration);

          // width of the gap
          const gapPercentage = roundToTwoDecimals(difference * 100 / duration);
          leftOffset += gapPercentage;
        }
        progressBars.push(
          <div className="progress-bar bg-success position-absolute" style={{left: leftOffset + '%', width: timeRangePercentage + '%', height: '100%'}}></div>
        );
      }

      return progressBars;
    }

    function updateProgressBar() {
      if(checkCompletion()) {
        completeVideo();
      }

      const visualProgressContainer = $(`#vid-visual-progress-container-${i}`);
      visualProgressContainer.empty();
      visualProgressContainer.append(
        ReactDOMServer.renderToString(generateProgressBars(i))
      );
    }

    function checkCompletion() {
      let watchedSeconds = 0;
      const sortedTimeRanges = _.sortBy(timeRanges, (timeRange) => timeRange.start);

      for (let i = 0; i < sortedTimeRanges.length; i++) {
        if(i > 0) {
          let prevEnd = sortedTimeRanges[i-1].end ?? 0;
          watchedSeconds -= getOverlapLeft(sortedTimeRanges[i].start, prevEnd);
        }

        if(isNaN(sortedTimeRanges[i].end)) {
          const currTime = Math.round(player.getCurrentTime());
          watchedSeconds += currTime - sortedTimeRanges[i].start;

          continue;
        }

        watchedSeconds += sortedTimeRanges[i].end - sortedTimeRanges[i].start;
      }

      if (watchedSeconds >= threshold && !isCompleted) {
        return true;
      }
    }
    
    function completeVideo() {
      isCompleted = true;
      localStorage.setItem(
        videos[i].src +
          courseId +
          '-completed',
        'true'
      );

      setCompletedVideosCount(
        (completedVideosCount) => completedVideosCount + 1
      );

      $(`#vid-completed-msg-${i}`).removeClass('invisible');
    }

    /**
     * Get the value of the overlapping between currTimeRange start and prevTimeRange end
     * 
     */
    function getOverlapLeft(currStart, prevEnd) {
      let overlapValue = 0;

      if(currStart < prevEnd) {
        overlapValue = prevEnd - currStart;

        if(overlapValue < 0) {
          overlapValue = 0;
        }
      }

      return overlapValue;
    }
  }
};

function addEndToTimeRange(timeRanges, player) {
  timeRanges.forEach(timeRange => {
    if(typeof timeRange.end !== 'number') {
      timeRange.end = Math.round(player.getCurrentTime());
    }
  })

  return timeRanges;
}

function addSaveEvents(timeRanges, saveKey, player, router) {
  //saving time ranges before route change
  const handleRouteChange = () => {
    saveTimeRanges(timeRanges, saveKey, player);
    router.events.off('routeChangeStart', handleRouteChange);
    window.onbeforeunload = null;
  }

  const oldonbeforeunload = window.onbeforeunload;
  if(typeof window.onbeforeunload !== 'function') {
    window.onbeforeunload = handleRouteChange;
  } else {
    window.onbeforeunload = () => {
      if(oldonbeforeunload) {
        oldonbeforeunload();
      }

      handleRouteChange();
    }
  }

  router.events.on('routeChangeStart', handleRouteChange);
}

function saveTimeRanges(timeRanges, saveKey, player) {
  timeRanges = addEndToTimeRange(timeRanges, player);
  
  localStorage.setItem(
    saveKey,
    JSON.stringify(timeRanges)
  );
}