import React, {Component} from 'react';
import moment from 'moment';
import {inject, observer} from 'mobx-react';
import {withTranslation, Trans} from 'react-i18next';
import Link from 'next/link';
import {routes} from 'env/routes';
import {LocalizedPrice} from 'env/utils/NumbersLocalization';
import withCustomComponent from 'ui/components/withCustomComponent';
import * as prices from '../../../env/utils/prices';
import {toJS} from 'mobx';

@withCustomComponent('ContractDetails')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class ContractDetails extends Component {
  componentDidMount() {
    const {authStore} = this.props;
    const {hasLoadedCustomer} = authStore;
    if (!hasLoadedCustomer) authStore.loadCustomer();
  }

  render() {
    const {t, contract, appStore, authStore} = this.props;
    const {configuration} = appStore;
    const {customer} = authStore;
    const isCorporateDashboard = appStore.corporateDashboard;

    if (!customer) return <></>;

    const usesGoCardless =
      customer.HasGoCardlessContractNumber && customer.EnableGoCardlessPayments;
    const hasCardNumber = customer.CardNumber && customer.CardNumber.length > 0;

    return (
      <div data-component-name={this.props.componentName}>
        <div className="card-item-header d-none d-md-flex">
          <h2 className="card-item-header-title">
            {contract.Tariff.Name}
          </h2>
        </div>
        <div className="card-item-body">
          <div className="row">
            <div className="col-md-6 col-lg-3 col-xl-2">
              <div className="mb-20 mt-n20 mx-n20 mt-md-0 mx-md-0">
                <img className="img-fluid w-100"
                     src={contract.Tariff.CfImage_1}
                     alt=""/>
              </div>
            </div>
            <div className="col-lg-9 col-xl-10">
              <div className="row">
                <div className="col-md-12 col-lg-6 col-xl-5">
                  <h2 className="plan-title-mobile fs-18 d-block d-md-none">
                    {contract.Tariff.Name}
                  </h2>
                  <p className="mb-8">
                    {t('Description')}
                  </p>
                  <div className="funnel-steps-advance-payment-information">
                    <div
                      dangerouslySetInnerHTML={{__html: contract.Tariff.Description ?? ''}}></div>
                  </div>
                  <hr/>
                  {!isCorporateDashboard && contract.CancellationDate == null && (
                    <>
                      {usesGoCardless && (
                        <>
                          <p className="fs-14 text-gray-700 mb-0 mt-16 mb-20">
                            <Trans t={t}>
                              The next charge of{' '}
                              {{NextPriceFormatted: contract.NextPriceFormatted}}{' '}
                              will be paid using your Direct Debit agreement on{' '}
                              {{
                                RenewalDate: moment(contract.RenewalDate).format(
                                  'L'
                                ),
                              }}
                              . We will also add any unpaid charges to this invoice,
                              like bookings or day passes you may have purchased
                              before that date.
                            </Trans>
                            <br/>
                          </p>
                        </>
                      )}
                      {!usesGoCardless && hasCardNumber && (
                        <>
                          <p className="fs-14 text-gray-700 mb-0 mt-16 mb-20">
                            <Trans t={t}>
                              The next charge of{' '}
                              {{NextPriceFormatted: contract.NextPriceFormatted}}{' '}
                              will be placed on your card ending on '
                              {{CardNumber: customer.CardNumber}}' on{' '}
                              {{
                                RenewalDate: moment(contract.RenewalDate).format(
                                  'L'
                                ),
                              }}
                              . We will also add any unpaid charges to this invoice,
                              like bookings or day passes you may have purchased
                              before that date.
                            </Trans>
                            <br/>
                          </p>
                        </>
                      )}
                      {!usesGoCardless &&
                        !hasCardNumber &&
                        customer.HasSetupRegularPayments && (
                          <>
                            <p className="fs-14 text-gray-700 mb-0 mt-16">
                              <Trans t={t}>
                                The next charge of{' '}
                                {{
                                  NextPriceFormatted: contract.NextPriceFormatted,
                                }}{' '}
                                will be paid using '
                                {{
                                  RegularPaymentProvider:
                                  contract.RegularPaymentProvider,
                                }}
                                ' on{' '}
                                {{
                                  RenewalDate: moment(contract.RenewalDate).format(
                                    'L'
                                  ),
                                }}
                                . We will also add any unpaid charges to this
                                invoice, like bookings or day passes you may have
                                purchased before that date.
                              </Trans>
                              <br/>
                            </p>
                          </>
                        )}
                    </>
                  )}
                </div>
                <div className="col-md-12 col-lg-6 col-xl-4 ml-auto contact-details-price-wrapper">
                  {/*<hr className="my-16 d-block d-md-none"/>
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>{t('Monthly rent')}</div>
                    <div>{prices.format(prices.excludeTax(contract.Price, contract.Tariff.TaxRate))}</div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>{t('VAT')}</div>
                    <div>
                      {prices.format(prices.retrieveTax(contract.Price, contract.Tariff.TaxRate), {currencyCode: false})}
                    </div>
                  </div>
                  <hr className="my-16"/>
                  <div className="d-flex align-items-center justify-content-between mb-16">
                    <div>{t('Monthly rent including VAT')}</div>
                    <div className="color funnel-steps-price-total fs-18"><strong>
                      {contract.PriceFormatted}
                    </strong></div>
                  </div>*/}
                  {!isCorporateDashboard && (
                    <>
                      <h3 className="mb-0">
                        {contract.Tariff.InvoiceInMonths && (
                          <>
                            {LocalizedPrice({
                              currency: contract.Tariff.Currency.Code,
                              amount: contract.Price,
                            })}
                          </>
                        )}
                        {!contract.Tariff.InvoiceInMonths && (
                          <>{contract.PriceFormatted}</>
                        )}
                      </h3>
                      <p className="text-gray-700">
                        {contract.Tariff.InvoiceInMonths && (
                          <>
                            {t('every {{count}} month', {
                              count: contract.Tariff.InvoicePeriod,
                            })}
                          </>
                        )}
                        {!contract.Tariff.InvoiceInMonths && (
                          <>
                            {t('every {{count}} week', {
                              count: contract.Tariff.InvoicePeriod,
                            })}
                          </>
                        )}
                      </p>
                    </>
                  )}
                  <div>
                    {contract.Active ? (
                      <div className="tag d-inline-flex bg-green text-white m-0">
                        {t('Active')}
                      </div>
                    ) : (
                      <div className="p-12 bg-gray-200 text-gray-900 rounded fs-14 lh-14">
                        <Trans t={t}>
                          <b>This subscription has not started yet.</b>{' '}
                          <span>
                        This plan is set to start on{' '}
                            {{date: moment(contract.StartDate).format('L')}}.
                      </span>{' '}
                          <span>
                        You will not have access to any of its benefits before
                        that date.
                      </span>
                        </Trans>
                      </div>
                    )}
                  </div>
                  {contract.Tariff.Id != contract.NextTariff.Id && (
                    <div>
                      <div
                        className="alert alert-warning d-inline-block mb-0 fs-14 mt-16"
                        role="alert"
                      >
                        <Trans t={t}>
                          <span>We will change your plan to </span>
                          <span>
                        <b>{{newPlan: contract.NextTariff.Name}}</b> on{' '}
                            {{date: moment(contract.RenewalDate).format('L')}}.
                      </span>
                        </Trans>
                      </div>
                    </div>
                  )}
                  {contract.AbsoluteCancellationDate != null && (
                    <div>
                      <div
                        className="alert alert-warning d-inline-block mb-0 fs-14 mt-16"
                        role="alert"
                      >
                        <Trans t={t}>
                          <b>This subscription has an end date.</b>
                          <br/> We will cancel this subscription on{' '}
                          {{
                            date: moment(contract.AbsoluteCancellationDate).format(
                              'L'
                            ),
                          }}
                          .
                        </Trans>
                      </div>
                    </div>
                  )}
                  <div className="card-divider"></div>
                  {!this.props.basic &&
                    configuration['Members.AllowTariffChange'] &&
                    !contract.Tariff.DisablePortalCancellations && (
                      <div className="btn-group btn-group-tablet flex-wrap flex-lg-wrap flex-xl-nowrap mt-12">
                        <Link
                          href={routes.plans_plan_change('[contract_id]')}
                          as={routes.plans_plan_change(contract.Id)}
                        >
                          <a className="btn w-100 w-xl-50">{t('Change this plan')}</a>
                        </Link>
                        {!contract.AbsoluteCancellationDate && (
                          <Link
                            href={routes.plan_cancel('[contract_id]')}
                            as={routes.plan_cancel(contract.Id)}
                          >
                            <a className="btn btn-gray w-100 w-xl-50">
                              {t('Cancel subscription')}
                            </a>
                          </Link>
                        )}
                        <div>
                          {/*
                {configuration['Members.CanSignup'] &&
                  !configuration['Members_UseInvitationLink'] && (
                    <Link href={routes.signup_plan}>
                      <a className="btn btn-icon  ml-8">
                        <i className="icon-add"></i>
                        {t('Join a new plan')}
                      </a>
                    </Link>
                  )}
                  */}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*<div className="">
          <div className="row">
            <div className="col-md-6">
              <h5>{contract.Tariff.Name}</h5>
              {!isCorporateDashboard && (
                <>
                  <h3 className="mb-0">
                    {contract.Tariff.InvoiceInMonths && (
                      <>
                        {LocalizedPrice({
                          currency: contract.Tariff.Currency.Code,
                          amount: contract.Price,
                        })}
                      </>
                    )}
                    {!contract.Tariff.InvoiceInMonths && (
                      <>{contract.PriceFormatted}</>
                    )}
                  </h3>
                  <p className="text-gray-700">
                    {contract.Tariff.InvoiceInMonths && (
                      <>
                        {t('every {{count}} month', {
                          count: contract.Tariff.InvoicePeriod,
                        })}
                      </>
                    )}
                    {!contract.Tariff.InvoiceInMonths && (
                      <>
                        {t('every {{count}} week', {
                          count: contract.Tariff.InvoicePeriod,
                        })}
                      </>
                    )}
                  </p>
                </>
              )}
              <div>
                {contract.Active ? (
                  <div className="tag d-inline-flex bg-green text-white m-0">
                    {t('Active')}
                  </div>
                ) : (
                  <div className="p-12 bg-gray-200 text-gray-900 rounded fs-14 lh-14">
                    <Trans t={t}>
                      <b>This subscription has not started yet.</b>{' '}
                      <span>
                        This plan is set to start on{' '}
                        {{ date: moment(contract.StartDate).format('L') }}.
                      </span>{' '}
                      <span>
                        You will not have access to any of its benefits before
                        that date.
                      </span>
                    </Trans>
                  </div>
                )}
              </div>
              {contract.Tariff.Id != contract.NextTariff.Id && (
                <div>
                  <div
                    className="alert alert-warning d-inline-block mb-0 fs-14 mt-16"
                    role="alert"
                  >
                    <Trans t={t}>
                      <span>We will change your plan to </span>
                      <span>
                        <b>{{ newPlan: contract.NextTariff.Name }}</b> on{' '}
                        {{ date: moment(contract.RenewalDate).format('L') }}.
                      </span>
                    </Trans>
                  </div>
                </div>
              )}
              {contract.AbsoluteCancellationDate != null && (
                <div>
                  <div
                    className="alert alert-warning d-inline-block mb-0 fs-14 mt-16"
                    role="alert"
                  >
                    <Trans t={t}>
                      <b>This subscription has an end date.</b>
                      <br /> We will cancel this subscription on{' '}
                      {{
                        date: moment(contract.AbsoluteCancellationDate).format(
                          'L'
                        ),
                      }}
                      .
                    </Trans>
                  </div>
                </div>
              )}
              {!isCorporateDashboard && contract.CancellationDate == null && (
                <>
                  {usesGoCardless && (
                    <>
                      <p className="fs-14 text-gray-700 mb-0 mt-16">
                        <Trans t={t}>
                          The next charge of{' '}
                          {{ NextPriceFormatted: contract.NextPriceFormatted }}{' '}
                          will be paid using your Direct Debit agreement on{' '}
                          {{
                            RenewalDate: moment(contract.RenewalDate).format(
                              'L'
                            ),
                          }}
                          . We will also add any unpaid charges to this invoice,
                          like bookings or day passes you may have purchased
                          before that date.
                        </Trans>
                        <br />
                      </p>
                    </>
                  )}
                  {!usesGoCardless && hasCardNumber && (
                    <>
                      <p className="fs-14 text-gray-700 mb-0 mt-16">
                        <Trans t={t}>
                          The next charge of{' '}
                          {{ NextPriceFormatted: contract.NextPriceFormatted }}{' '}
                          will be placed on your card ending on '
                          {{ CardNumber: customer.CardNumber }}' on{' '}
                          {{
                            RenewalDate: moment(contract.RenewalDate).format(
                              'L'
                            ),
                          }}
                          . We will also add any unpaid charges to this invoice,
                          like bookings or day passes you may have purchased
                          before that date.
                        </Trans>
                        <br />
                      </p>
                    </>
                  )}
                  {!usesGoCardless &&
                    !hasCardNumber &&
                    customer.HasSetupRegularPayments && (
                      <>
                        <p className="fs-14 text-gray-700 mb-0 mt-16">
                          <Trans t={t}>
                            The next charge of{' '}
                            {{
                              NextPriceFormatted: contract.NextPriceFormatted,
                            }}{' '}
                            will be paid using '
                            {{
                              RegularPaymentProvider:
                                contract.RegularPaymentProvider,
                            }}
                            ' on{' '}
                            {{
                              RenewalDate: moment(contract.RenewalDate).format(
                                'L'
                              ),
                            }}
                            . We will also add any unpaid charges to this
                            invoice, like bookings or day passes you may have
                            purchased before that date.
                          </Trans>
                          <br />
                        </p>
                      </>
                    )}
                </>
              )}
            </div>
            <div
              className="col-md-6 fs-14 text-gray-700 pt-16 pt-md-0 fr-view"
              dangerouslySetInnerHTML={{ __html: contract.Tariff.Description }}
            ></div>
          </div>
        </div>

        {!this.props.basic &&
          configuration['Members.AllowTariffChange'] &&
          !contract.Tariff.DisablePortalCancellations && (
            <div className="d-flex align-items-center justify-content-between mt-12">
              <div>
                <Link
                  href={routes.plan_change('[contract_id]')}
                  as={routes.plan_change(contract.Id)}
                >
                  <a className="btn ">{t('Change this plan')}</a>
                </Link>
                {!contract.AbsoluteCancellationDate && (
                  <Link
                    href={routes.plan_cancel('[contract_id]')}
                    as={routes.plan_cancel(contract.Id)}
                  >
                    <a className="btn btn-outline-gray  ml-8">
                      {t('Cancel subscription')}
                    </a>
                  </Link>
                )}
              </div>
              <div>

                {configuration['Members.CanSignup'] &&
                  !configuration['Members_UseInvitationLink'] && (
                    <Link href={routes.signup_plan}>
                      <a className="btn btn-icon  ml-8">
                        <i className="icon-add"></i>
                        {t('Join a new plan')}
                      </a>
                    </Link>
                  )}

              </div>
            </div>
          )}*/}
      </div>
    );
  }
}

export default ContractDetails;
