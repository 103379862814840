/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import withCustomComponent from 'ui/components/withCustomComponent';
import { getErrorDescription } from '../BookingErrorCodes';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';
import { PriceTaxStatus } from 'ui/components/PriceTaxStatus';

@withCustomComponent('BookingStatus')
@withTranslation()
@inject('appStore', 'authStore', 'bookingsStore')
@observer
class BookingStatus extends Component {
  updateBooking = this.props.updateBooking;

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t, authStore, appStore, bookingsStore } = this.props;
    const { isLoadingBookingPrice, hasLoadedBookingPrice, bookingPrice } =
      bookingsStore;
    const { configuration } = appStore;
    const { isLoggedIn, customer } = authStore;
    const isCorporateDashboard = appStore.corporateDashboard;

    return (
      <>
        {isLoadingBookingPrice && (
          <div className="mt-24">
            <div className="d-flex justify-content-between">
              <div className="fs-24 fw-500 text-gray-700">
                {t('Loading...')}
              </div>
              <div className="fs-24 fw-500">
                &nbsp;
                <LoadingSpinner className="d-inline-block h-25" />
              </div>
            </div>
          </div>
        )}
        {bookingPrice && hasLoadedBookingPrice && (
          <>
            {!isCorporateDashboard &&
              bookingPrice.Price &&
              bookingPrice.Price.indexOf('-1') === -1 &&
              bookingPrice.IsAvailable && (
                <>
                  {bookingPrice.CreditPrice !== null &&
                  bookingPrice.UsedCreditDecimal > 0 &&
                  bookingPrice.PriceDecimal == 0 ? (
                    <div className="mt-24" id="booking-price-credit">
                      <div className="d-flex justify-content-between">
                        <div className="fs-24 fw-500 text-gray-700">
                          {t('Total')}
                        </div>
                        <div className="fs-24 fw-500">
                          {t('{{count}} credit', {
                            count: bookingPrice.UsedCreditDecimal,
                          })}
                        </div>
                      </div>
                    </div>
                  ) : bookingPrice.AvailableCreditDecimal ? (
                    <>
                      <div
                        className="mt-24 booking-price"
                        id="booking-price-amount"
                      >
                        <div className="d-flex justify-content-between">
                          <div className="funnel-steps-price-total-title text-uppercase">
                            {t('Total')}
                          </div>
                          <div className="fs-24 fw-500">
                            <LocalizedPrice
                              currency={bookingPrice.Currency.Code}
                              amount={
                                bookingPrice.UsedCreditDecimal +
                                bookingPrice.PriceDecimal
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className="mt-12 booking-credit"
                        id="booking-price-credit"
                      >
                        <div className="d-flex justify-content-between">
                          <div className="fs-24 fw-500 text-gray-700">
                            {t('Credit')}
                          </div>
                          <div className="fs-24 fw-500">
                            <LocalizedPrice
                              currency={bookingPrice.Currency.Code}
                              amount={-bookingPrice.UsedCreditDecimal}
                            />
                          </div>
                        </div>
                      </div>

                      <hr className="my-8" />

                      <div
                        className="booking-credit-due"
                        id="booking-price-due"
                      >
                        <div className="d-flex justify-content-between">
                          <div className="fs-24 fw-500 text-gray-700">
                            {t('Due')}
                          </div>
                          <div className="fs-24 fw-500">
                            <LocalizedPrice
                              currency={bookingPrice.Currency.Code}
                              amount={bookingPrice.PriceDecimal}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div
                      className="mt-24 booking-price"
                      id="booking-price-total"
                    >
                      <div className="d-flex justify-content-between">
                        <div className="funnel-steps-price-total-title">
                          {t('Total')}
                        </div>
                        <div className="funnel-steps-price-total"><strong>{bookingPrice.Price}</strong></div>
                      </div>
                    </div>
                  )}
                  <PriceTaxStatus />
                  {isLoggedIn && (
                    <>
                      {configuration[
                        'Bookings.CancelUnPaidBookingsForNonSubscribers'
                      ] &&
                        customer.IsContact && (
                          <div className="alert alert-warning mt-24 mb-10">
                            <p className="m-0 fw-500 lh-13 fs-14">
                              {t(
                                'Once you complete this booking, you will be asked to pay this amount before it is confirmed. '
                              )}
                            </p>
                          </div>
                        )}
                      {configuration[
                        'Bookings.CancelUnPaidBookingsForSubscribers'
                      ] &&
                        customer.IsMember && (
                          <div className="alert alert-warning mt-24 mb-10">
                            <p className="m-0 fw-500 lh-13 fs-14">
                              {t(
                                'Once you complete this booking, you will be asked to pay this amount before it is confirmed. '
                              )}
                            </p>
                          </div>
                        )}
                    </>
                  )}
                </>
              )}

            {!bookingPrice.IsAvailable && bookingPrice.Message && (
              <div className="alert alert-danger mt-24 mb-0" role="alert">
                <p
                  className="m-0 fw-500 lh-13 fs-14"
                  dangerouslySetInnerHTML={{
                    __html: getErrorDescription(
                      bookingPrice?.Resource?.ErrorCode,
                      bookingPrice.Message,
                      t
                    ),
                  }}
                ></p>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}
export default BookingStatus;
