import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import moment from 'moment';
import { CoworkerAvatar } from 'ui/components/CoworkerAvatar';

export const BlogPostMetaData = withCustomComponent('BlogPostMetaData')(
  inject(
    'appStore',
    'blogStore'
  )(
    withRouter(
      observer(({ post, componentName, router, appStore, blogStore }) => {
        const { t } = useTranslation();

        return (
          <div
            data-component-name={componentName}
            className="blog-post-meta-data p-16 p-md-24 p-lg-32 mb-8"
          >
            <div className="category-date fs-14 text-gray-700">
              {post.BlogCategories.filter((c) => c.Id != undefined).length >
                0 && (
                <div className="category">
                  {post.BlogCategories.filter((c) => c.Id != undefined).map(
                    (category, i) => (
                      <span key={i}>
                        <Link
                          href={{
                            pathname: routes.posts,
                            query: { categoryId: category.Id },
                          }}
                        >
                          <a>{category.Title}</a>
                        </Link>
                      </span>
                    )
                  )}
                </div>
              )}
              <span className="date">
                {moment(post.PublishDate).format('ll')}
              </span>
            </div>
            <div className="title mt-12">
              <h2 className="mb-0">{post.Title}</h2>
            </div>
            <div className="author mt-20 ">
              {post.PostedBy && (
                <div className="d-flex align-items-center">
                  <CoworkerAvatar
                    link={true}
                    user_id={post.PostedBy.Id}
                    size={64}
                    title={post.PostedBy.FulLName}
                    imgClassName="avatar mr-8"
                  />
                  <Link
                    href={{
                      pathname: routes.directory_member_by_id('[member_id]'),
                      query: {
                        ...router.query,
                        page: 1,
                      },
                    }}
                    as={{
                      pathname: routes.directory_member_by_id(post.PostedBy),
                      query: {
                        ...router.query,
                        page: 1,
                      },
                    }}
                  >
                    <a className="fs-14 text-gray-700">
                      {post.PostedBy.FullName}
                    </a>
                  </Link>
                </div>
              )}
            </div>
          </div>
        );
      })
    )
  )
);
