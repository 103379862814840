import React from 'react';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import Head from 'next/head';
import { removeHtml, shorten } from 'env/utils/strings';
export const BlogMetaTags = withCustomComponent('BlogMetaTags')(
  inject('appStore')(
    withRouter(
      observer(({ post, appStore }) => {
        const { t } = useTranslation();
        const { business, configuration } = appStore;
        return (
          <Head>
            <meta name="twitter:card" content="summary" />
            <meta
              name="twitter:site"
              content={`@${configuration['Social.Twitter']}`}
            />
            <meta
              name="twitter:title"
              content={`${shorten(removeHtml(post?.Title), 70)}`}
            />
            <meta
              name="twitter:description"
              content={`${shorten(removeHtml(post?.SummaryText), 200)}`}
            />
            <meta
              name="twitter:creator"
              content={`@${configuration['Social.Twitter']}`}
            />
            {post?.HasLargeImage && (
              <meta
                name="twitter:image:src"
                content={`${business.NativeHomeUrlWithLanguage}/Blog/GetSmallImage?id=${post.Id}&w=800&cache=${post.UpdatedOn}`}
              />
            )}

            <meta
              property="og:url"
              key="og:url"
              content={`${business.HomeUrl}/${routes.post_by_id(post)}?public`}
            />
            <meta
              property="og:title"
              key="og:title"
              content={removeHtml(post?.Title)}
            />
            {post?.HasLargeImage && (
              <meta
                property="og:image"
                key="og:image"
                content={`${business.NativeHomeUrlWithLanguage}/Blog/GetSmallImage?id=${post.Id}&w=600h=314&mode=Pad&cache=${post.UpdatedOn}`}
              />
            )}
            <meta
              property="og:image:type"
              content="image/jpeg"
              key="og:image:type"
              content="image/jpeg"
            />
            <meta
              property="og:description"
              key="og:description"
              content={removeHtml(post?.SummaryText)}
            />
            <meta
              property="og:site_name"
              key="og:site_name"
              content={business.Title}
            />
          </Head>
        );
      })
    )
  )
);
