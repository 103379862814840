import React, { Component } from 'react';
import dynamic from 'next/dynamic';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import { withRouter } from 'next/router';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import Head from 'next/head';

import LoadingLayout from 'ui/layouts/LoadingLayout';
import { isServer } from 'env/ssr/ServerSideRenderManager';

const LoadableComponent = dynamic(
  () => import('env/LoadableComponent/LoadableComponent'),
  { ssr: false }
);
@withRouter
@withCustomComponent('CustomPage')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class CustomPage extends Component {
  componentDidMount() {
    this.loadCustomPage();
  }

  componentDidUpdate(prevProps, prevState) {
    const { router } = this.props;
    const prevQuery = prevProps.router.query;
    const currentQuery = router.query;
    if (JSON.stringify(currentQuery) != JSON.stringify(prevQuery)) {
      this.loadCustomPage();
    }
  }

  loadCustomPage = () => {
    const { router, t, appStore } = this.props;
    const { customPage } = appStore;
    if (customPage?.Permalink != router.query.page_slug[0]) {
      const params = {
        name: router.query.page_slug[0],
        customerController:
          router.query.page_slug.length > 1 ? router.query.page_slug[1] : null,
        customerAction:
          router.query.page_slug.length > 2
            ? router.query.page_slug[2]
            : 'index',
      };
      appStore
        .loadCustomPage(params)
        .catch(() => {
          appStore.setPopMessage(t('Sorry, we could not load this page'));
        })
        .then((data) => {
          if (data.Page.Name.indexOf('.htm') > -1)
            this.runScripts(data.FileContents);
        })
        .catch((ex) => {
          console.log(`ERROR RUNNING PAGE SCRIPT: ${ex}`);
        });
    }
  };

  runScripts = (html) => {
    $(html)
      .filter('script')
      .each(function (e, i) {
        if (i.innerHTML) {
          eval(i.innerHTML);
        } else {
          const head = document.getElementsByTagName('head')[0];
          head.appendChild(i);
        }
      });
  };

  render() {
    const { t, appStore } = this.props;
    const { business, customPage, isLoadingCustomPage } = appStore;
    const hasLoadedData = customPage && customPage.Page && !isLoadingCustomPage;

    if (!hasLoadedData)
      return (
        <DefaultLayout componentName={this.props.componentName}>
          <main className="content content--custom" role="main">
            <div>
              <div className="container">
                <LoadingLayout />
              </div>
            </div>
          </main>
        </DefaultLayout>
      );

    if (customPage.Page.Name.indexOf('.jsx') > -1) {
      if (isServer()) return <div></div>;

      return (
        <LoadableComponent
          loadingComponent={<LoadingSpinner />}
          url={`https://${appStore.host}/en/page/${customPage.Page.Name}?raw=true`}
        />
      );
    }

    return (
      <DefaultLayout componentName={this.props.componentName}>
        <Head>
          <title>{`${customPage.Page.Title} - ${t(business.Name)}`}</title>
        </Head>
        <main className="content content--pages" role="main">
          <div className="custom-page-content">
            <div className="container">
              {!hasLoadedData && <LoadingSpinner />}
              {hasLoadedData && (
                <>
                  <div className="intro">
                    {customPage.Page.Title.length < 100 ? (
                      <h1>{customPage.Page.Title}</h1>
                    ) : (
                      <h2>{customPage.Page.Title}</h2>
                    )}
                    {customPage.Page.HasImage && (
                      <div
                        className="img"
                        style={{
                          backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/Page/Image/${customPage.Page.Id}?=1150&h=440&mode=max)`,
                        }}
                      ></div>
                    )}
                  </div>
                  <div
                    className="content fr-view"
                    dangerouslySetInnerHTML={{
                      __html: customPage.Page.Description,
                    }}
                  ></div>
                </>
              )}
            </div>
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default CustomPage;
