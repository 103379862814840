/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'next/router';
import { routes } from 'env/routes';
import Link from 'next/link';
import RecaptchaSection from 'ui/_pages/signup/RecaptchaSection';
import PersonalDetailsSection from 'ui/_pages/tour/PersonalDetailsSection';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { SignupPageTermsAndConditions } from '../signup/SignupPageTermsAndConditions';
import withCustomComponent from 'ui/components/withCustomComponent';
import HomeLocationSection from '../signup/HomeLocationSection';

@withCustomComponent('TourFormStep')
@withRouter
@withTranslation()
@inject('appStore', 'authStore')
@observer
class TourFormStep extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  saveForm = () => {
    const { router, authStore, appStore } = this.props;
    const utm_source = router.query.utm_source;
    const utm_medium = router.query.utm_medium;
    const utm_campaign = router.query.utm_campaign;
    const utm_content = router.query.utm_content;
    const utm_term = router.query.utm_term;
    authStore
      .saveCustomer({
        router: router,
        recaptcha: this.state.recaptcha,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term,
        createUser: false,
      })
      .then((result) => {
        router.push(routes.tour_complete);
      })
      .catch((err) => {
        if (err.data && err.data.RedirectTo)
          return router.push(err.data.RedirectTo);

        appStore.setPopMessage(
          t('Sorry, we could not load this page') + '<br/><br/>' + err
        );
      });
  };

  render() {
    const { t, isSavingCustomerWithUser, customer, componentName, appStore } =
      this.props;
    const calendlyEnabled = appStore.configuration['Calendly.Enabled'];
    const calendlyUrl = appStore.configuration['Calendly.TourEventUrl'];
    return (
      <div data-component-name={componentName}>
        <h4 className="d-flex align-items-center mb-24">
          <i className="icon-request-date mr-8"></i>
          <span>{t('Request a tour')}</span>
        </h4>

        {calendlyEnabled && (
          <div className="border-bottom">
            <iframe
              style={{ border: 0, width: '100%', height: 800 }}
              src={`${calendlyUrl}?hide_landing_page_details=1&hide_event_type_details=1&hide_landing_page_details=1`}
            ></iframe>
          </div>
        )}
        {!calendlyEnabled && (
          <>
            <h5 className="bold mb-16">{t('Personal details')}</h5>

            <HomeLocationSection customer={customer} />
            <PersonalDetailsSection
              customer={customer}
              setIsValidDate={(value) => this.setState({ isValidDate: value })}
            />
            <SignupPageTermsAndConditions customer={customer} />
            <RecaptchaSection
              customer={customer}
              verifyCallback={(value) => {
                this.setState({ recaptcha: value });
              }}
            />

            <div className="d-flex align-item-center justify-content-end mt-24">
              <Link href="/">
                <a className="btn btn-white mr-8">{t('Cancel')}</a>
              </Link>
              <a
                onClick={(ev) => {
                  ev.preventDefault();
                  if(!this.state.isValidDate) return;
                  this.saveForm();
                }}
                className={`btn btn-icon ${
                  isSavingCustomerWithUser || !this.state.isValidDate
                    ? 'disabled'
                    : null
                }`}
                href="#"
              >
                {isSavingCustomerWithUser ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <i className="icon-email-delivery"></i>
                    {t('Request a tour')}
                  </>
                )}
              </a>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default TourFormStep;
