/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import asForm from 'ui/components/forms/asForm';

@withCustomComponent('HomeLocationSection')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class HomeLocationSection extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const { appStore } = this.props;

    appStore.loadBusinesses(false);
  }

  componentDidUpdate(prevProps, prevState) {
    const { customer, updateProperty } = this.props;

    if (customer?.InvoicingBusinessId) {
      const leafBusinesses = this.getLeafBusinesses();
      const foundBiz = leafBusinesses.find(
        (b) => b.Id == customer.InvoicingBusinessId
      );
      if (!foundBiz && leafBusinesses[0]) {
        updateProperty('InvoicingBusinessId', parseInt(leafBusinesses[0].Id));
      }
    }
  }

  getLeafBusinesses = () => {
    const { appStore } = this.props;
    const { businesses, business } = appStore;
    return businesses.filter(
      (b) => b.Id == business.Id || b.Businesses.length == 0
    );
  };

  render() {
    const { appStore, updateProperty, customer } = this.props;
    const { businesses } = appStore;
    const leafBusinesses = this.getLeafBusinesses();
    if (leafBusinesses.length <= 1) return <></>;
    if (!customer) return <></>;
    return (
      <>
        <form className="form">
          <div className="form-group required">
            <label>{t('Home location')}</label>
            <select
              name={'InvoicingBusinessId'}
              value={customer.InvoicingBusinessId}
              onChange={(ev) =>
                updateProperty(ev.target.name, parseInt(ev.target.value))
              }
              className="form-control"
            >
              {leafBusinesses.map((biz, i) => (
                <option key={i} value={biz.Id}>
                  {biz.Name}
                </option>
              ))}
            </select>
          </div>
        </form>
      </>
    );
  }
}

export default asForm(HomeLocationSection, 'customer');
