import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import moment from 'moment';
import { withRouter } from 'next/router';
import { routes } from 'env/routes';
import { privateRoute } from 'env/privateRoute';

@privateRoute
@withRouter
@withCustomComponent('VideoRoomStreamPage')
@withTranslation()
@inject('appStore', 'authStore', 'communityStore')
@observer
class VideoRoomStreamPage extends Component {
  constructor() {
    super();
    this.state = {};
  }
  async componentDidMount() {
    const { router, authStore, appStore, communityStore } = this.props;
    const { business } = appStore;
    const me = await authStore.loadMe();
    const { team_id, room_id } = router.query;
    const roomUrl =
      room_id == 'me'
        ? `${business.HomeUrlWithLanguage}/VideoRooms/MyStream`
        : room_id == 'team'
        ? `${business.HomeUrlWithLanguage}/VideoRooms/TeamStream?id=${team_id}`
        : `${business.HomeUrlWithLanguage}/VideoRooms/Stream?id=${room_id}`;
    const wrappedUrl = `${business.HomeUrlWithLanguage}/user/login?t=${
      me.AccessToken
    }&redirectUrl=${encodeURIComponent(roomUrl)}`;

    window.open(wrappedUrl);
    router.push(routes.virtual_rooms);
  }

  close = () => {
    const { router } = this.props;
    router.push(routes.virtual_rooms);
  };

  render() {
    const { t, appStore } = this.props;
    const { business } = appStore;
    const { roomUrl } = this.state;
    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={t('Virtual rooms - {{Name}}', { Name: t(business.Name) })}
      >
        <main className="content content--custom" role="main">
          <div className="container">
            <h2>{t('Loading, please wait...')}</h2>
            {roomUrl && (
              <p>
                <Trans>
                  We are loading this virtual room. Click{' '}
                  <a href={roomUrl}>here</a> if you are not redirected within a
                  few seconds.
                </Trans>
              </p>
            )}
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default VideoRoomStreamPage;
