import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const HomeServicesSection = withCustomComponent('HomeServicesSection')(
  inject('appStore')(
    withRouter(
      observer(({ router, appStore, componentName }) => {
        const { t } = useTranslation();
        const { configuration } = appStore;
        const { virtualDashboard, corporateDashboard } = appStore;
        return (
          <div
            data-component-name={componentName}
            className={`home-services-section ${
              virtualDashboard ? 'home-services-section--virtual' : ''
            }`}
          >
            <div className="container">
              {virtualDashboard ? (
                <h2 className="h3 mb-32 mb-md-48">
                  {t('What you can find in our community')}
                </h2>
              ) : (
                <h2 className="h1 mb-64 mb-md-84 text-center">
                  {t('Our services')}
                </h2>
              )}
              <ul className="list-grid list-grid--33 list-grid--md-50 list-grid--sm-100">
                {configuration['HomePage.ShowWorkspaceService'] && (
                  <li>
                    <div className="services-list__item">
                      <i
                        className={`icon-${
                          virtualDashboard ? 'clap' : 'desks'
                        }`}
                      ></i>
                      <h4>
                        {configuration['HomePage.WorkSpaceShortText']
                          ? t(configuration['HomePage.WorkSpaceShortText'])
                          : virtualDashboard
                          ? t('Events')
                          : t('Workspace')}
                      </h4>
                      <p>
                        {configuration['HomePage.WorkSpaceLongText']
                          ? t(configuration['HomePage.WorkSpaceLongText'])
                          : virtualDashboard
                          ? t(
                              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.'
                            )
                          : t(
                              'Flexible plans from hot-desks all the way to your own private room or office.'
                            )}
                      </p>
                    </div>
                  </li>
                )}
                {configuration['HomePage.ShowBookingsService'] && (
                  <li>
                    <div className="services-list__item">
                      <i
                        className={`icon-${
                          virtualDashboard ? 'book-edit' : 'whiteboard'
                        }`}
                      ></i>
                      <h4>
                        {configuration['HomePage.BookingsShortText']
                          ? t(configuration['HomePage.BookingsShortText'])
                          : virtualDashboard
                          ? t('Blog')
                          : t('Conference Rooms')}
                      </h4>
                      <p>
                        {configuration['HomePage.BookingsLongText']
                          ? t(configuration['HomePage.BookingsLongText'])
                          : virtualDashboard
                          ? t(
                              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.'
                            )
                          : t(
                              'Real-time availability for all our meeting rooms. Request and manage bookings online.'
                            )}
                      </p>
                    </div>
                  </li>
                )}
                {configuration['HomePage.ShowCommunityService'] && (
                  <li>
                    <div className="services-list__item">
                      <i
                        className={`icon-${
                          virtualDashboard ? 'community' : 'zoom'
                        }`}
                      ></i>
                      <h4>
                        {configuration['HomePage.CommunityShortText']
                          ? t(configuration['HomePage.CommunityShortText'])
                          : virtualDashboard
                          ? t('Members')
                          : t('Online member network')}
                      </h4>
                      <p>
                        {configuration['HomePage.CommunityLongText']
                          ? t(configuration['HomePage.CommunityLongText'])
                          : virtualDashboard
                          ? t(
                              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.'
                            )
                          : t(
                              'Easily connect with other members of the community. Use the directories to find the right skills and promote your services.'
                            )}
                      </p>
                    </div>
                  </li>
                )}
                {configuration['HomePage.ShowChildCareService'] && (
                  <li>
                    <div className="services-list__item">
                      <i
                        className={`icon-${
                          virtualDashboard ? 'zoom' : 'community'
                        }`}
                      ></i>
                      <h4>
                        {configuration['HomePage.ChildCareShortText']
                          ? t(configuration['HomePage.ChildCareShortText'])
                          : virtualDashboard
                          ? t('Virtual Rooms')
                          : t('Childcare')}
                      </h4>
                      <p>
                        {configuration['HomePage.ChildCareLongText']
                          ? t(configuration['HomePage.ChildCareLongText'])
                          : virtualDashboard
                          ? t(
                              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.'
                            )
                          : t(
                              'Professional childcare services available from the age of two.'
                            )}
                      </p>
                    </div>
                  </li>
                )}
                {configuration['HomePage.ShowBenefitsService'] && (
                  <li>
                    <div className="services-list__item">
                      <i
                        className={`icon-${
                          virtualDashboard ? 'products' : 'discount'
                        }`}
                      ></i>
                      <h4>
                        {configuration['HomePage.BenefitsShortText']
                          ? t(configuration['HomePage.BenefitsShortText'])
                          : virtualDashboard
                          ? t('Plans and Benefits')
                          : t('Benefits and discounts')}
                      </h4>
                      <p>
                        {configuration['HomePage.BenefitsLongText']
                          ? t(configuration['HomePage.BenefitsLongText'])
                          : virtualDashboard
                          ? t(
                              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.'
                            )
                          : t(
                              'All members enjoy discounted rates, credits and benefits as part of their membership.'
                            )}
                      </p>
                    </div>
                  </li>
                )}
                {!corporateDashboard &&
                  configuration['HomePage.ShowEventsService'] && (
                    <li>
                      <div className="services-list__item">
                        <i
                          className={`icon-${
                            virtualDashboard ? 'perk-benefits' : 'calendar-view'
                          }`}
                        ></i>
                        <h4>
                          {configuration['HomePage.EventsShortText']
                            ? t(configuration['HomePage.EventsShortText'])
                            : virtualDashboard
                            ? t('Perks and Discount')
                            : t('Weekly events')}
                        </h4>
                        <p>
                          {configuration['HomePage.EventsLongText']
                            ? t(configuration['HomePage.EventsLongText'])
                            : virtualDashboard
                            ? t(
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.'
                              )
                            : t(
                                'Connect and learn in our curated events and meet-ups.'
                              )}
                        </p>
                      </div>
                    </li>
                  )}
              </ul>
            </div>
          </div>
        );
      })
    )
  )
);
