import React, { useState } from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import moment from 'moment';
import asForm from 'ui/components/forms/asForm';

export const EventTicketsPageAttendeeListItem = asForm(
  withCustomComponent('EventTicketsPageAttendeeListItem')(
    inject('appStore')(
      withRouter(
        observer(({ updateProperty, product, attendee }) => {
          const { t } = useTranslation();

          return (
            <div className="row align-items-center mb-20">
              <div className="col-12 col-md-4 pb-8 pb-md-0">
                <div className="form-group mb-0">
                  <label className="h5 mb-0">
                    {product.Name}
                  </label>
                </div>
              </div>
              <div className="col-6 col-md-4">
                <div className="form-group mb-0">
                  <label className="sr-only">{t('Attendee full name')}</label>
                  <input
                    value={attendee.FullName}
                    name="FullName"
                    onChange={(ev) =>
                      updateProperty(ev.target.name, ev.target.value)
                    }
                    type="text"
                    className="form-control"
                    placeholder={t('Attendee full name')}
                  />
                </div>
              </div>
              <div className="col-6 col-md-4">
                <div className="form-group mb-0">
                  <label className="sr-only">{t('Attendee email')}</label>
                  <input
                    type="email"
                    name="Email"
                    onChange={(ev) =>
                      updateProperty(ev.target.name, ev.target.value)
                    }
                    value={attendee.Email}
                    className="form-control"
                    placeholder={t('Attendee email')}
                  />
                </div>
              </div>
            </div>
          );
        })
      )
    )
  ),
  'attendee'
);
