import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import ProposalPageLayout from 'ui/layouts/ProposalPageLayout';
import { withRouter } from 'next/router';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import { FormPageDetails } from 'ui/_pages/forms/FormPageDetails';

@withRouter
@withCustomComponent('FormPagePreviewPage')
@withTranslation()
@inject('appStore', 'communityStore')
@observer
class FormPagePreviewPage extends Component {
  componentDidMount() {
    const { t, router, appStore, communityStore } = this.props;
    communityStore
      .loadFormPagePreviewPage(router.query.formPage_guid)
      .then((data) => {
        if (!data.FormPageRequest)
          appStore.setPopMessage(t('We could not load this form.'));
      })
      .catch((ex) => {
        appStore.setPopMessage(t('We could not load this form.'));
      });
  }

  render() {
    const { t, appStore, communityStore } = this.props;
    const { business } = appStore;
    const { formPagePreviewPage, isLoadingFormPagePreviewPage } =
      communityStore;
    if (!formPagePreviewPage || isLoadingFormPagePreviewPage)
      return <LoadingLayout />;

    if (!formPagePreviewPage.FormPageRequest) return <></>;

    const formPage = formPagePreviewPage.FormPageRequest.FormPageDrop;
    return (
      <ProposalPageLayout
        componentName={this.props.componentName}
        title={formPage.Name}
        description={formPage.Description}
      >
        <FormPageDetails formPagePage={formPagePreviewPage} />
      </ProposalPageLayout>
    );
  }
}

export default FormPagePreviewPage;
