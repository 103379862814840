import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { routes } from 'env/routes';
import Link from 'next/link';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('CardBlogPost')
@withTranslation()
@inject('appStore')
@observer
class CardBlogPost extends Component {
  static propTypes = {
    post: PropTypes.object.isRequired,
  };

  render() {
    const { t, componentName, appStore, post } = this.props;

    return (
      <div
        className={`card card-blog-post`}
        data-component-name={componentName}
      >
        <Link
          href={routes.post_by_id('[post_id]')}
          as={routes.post_by_id(post)}
        >
          <a
            className="card-blog-post__image"
            style={{
              backgroundImage: `url(${appStore.business.NativeHomeUrlWithLanguage}/blog/getlargeimage?id=${post.Id}&w=960&h=520&cache=${post.UpdatedOn})`,
            }}
            alt={post.Title}
          ></a>
        </Link>
        <div className="card-blog-post__content">
          <div className="card-blog-post__main">
            <div className="card-blog-post__meta">
              {post.BlogCategories.filter((c) => c.Id != undefined).length >
                0 && (
                <div className="category">
                  {post.BlogCategories.filter((c) => c.Id != undefined).map(
                    (category, i) => (
                      <span key={i}>
                        <Link href={routes.posts_by_category(category.Id)}>
                          <a key={i}>{category.Title}</a>
                        </Link>
                      </span>
                    )
                  )}
                </div>
              )}
              <span className="date">
                {moment(post.PublishDate).format('ll')}
              </span>
            </div>
            <Link
              href={routes.post_by_id('[post_id]')}
              as={routes.post_by_id(post)}
            >
              <a className="card-blog-post__title">{post.Title}</a>
            </Link>
            <p
              className="card-blog-post__description"
              dangerouslySetInnerHTML={{
                __html: post.SummaryText,
              }}
            ></p>
          </div>
          {post.PostedBy && post.PostedBy.Id != undefined && (
            <div className="card-blog-post__author">
              <Link href="#">
                <a className="user">
                  <img
                    className="avatar"
                    src={`${appStore.business.NativeHomeUrlWithLanguage}/user/getavatar/${post.PostedBy.Id}?h=32&w=32&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}
                    alt={post.PostedBy.FullName}
                  />
                  <span>{post.PostedBy.FullName}</span>
                </a>
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CardBlogPost;
