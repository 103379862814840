import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import moment from 'moment';
import SignupPageLayout from 'ui/layouts/SignupPageLayout';
import PlansTable from 'ui/_pages/plan/PlansTable';
import { withRouter } from 'next/router';
import { routes } from 'env/routes';
import { privateRoute } from 'env/privateRoute';
import LoadingSpinner from 'ui/components/LoadingSpinner';

@privateRoute
@withRouter
@withCustomComponent('SignupPlanPage')
@withTranslation()
@inject('appStore', 'authStore', 'checkoutStore', 'invoicingStore')
@observer
class SignupPlanPage extends Component {
  componentDidMount() {
    const { t, invoicingStore, authStore, appStore, router } = this.props;
    invoicingStore
      .loadPricePlans({
        customer: authStore.customer,
        tariffGuid: router.query.tariff_guid,
      })
      .then((data) => {
        var noPlansAvailable =
          data.PricePlans.length == 0 && !data.SelectedTariff;
        if (noPlansAvailable) router.push(routes.signup_products);
      })
      .catch((err) => {
        appStore.setPopMessage(t('Sorry, we could not load this page.'));
      });
  }

  onPlanSelected = (plan) => {
    const { router, checkoutStore } = this.props;

    checkoutStore.addToBasket({
      type: 'contract',
      data: plan,
    });

    router.push(routes.signup_products);
  };
  render() {
    const { t, invoicingStore, appStore } = this.props;
    const { configuration } = appStore;
    const { pricePlans, hasLoadedPricePlans } = invoicingStore;
    const hasPlans =
      pricePlans.SelectedTariff || pricePlans.PricePlans.length > 0;

    return (
      <SignupPageLayout componentName={this.props.componentName} step={2}>
        {!hasLoadedPricePlans && <LoadingSpinner />}
        {hasLoadedPricePlans && hasPlans && (
          <>
            <PlansTable
              showStartDate={true}
              buttonLabel={t('Select a plan')}
              onPlanSelected={this.onPlanSelected}
            />
            {!configuration['Signup.MemberMustChooseAPricePlan'] &&
              configuration['Signup.ShowStoreOnSignUp'] && (
                <>
                  <p>{t('Not ready to join a plan?')}</p>
                  <div class="form-actions">
                    <Link href={routes.signup_products}>
                      <a class="btn btn--primary" href="#">
                        {t('View other products')}
                        <i className="fa fa-angle-right i-space--left"></i>
                      </a>
                    </Link>
                  </div>
                </>
              )
            }

            {!configuration['Signup.MemberMustChooseAPricePlan'] &&
              !configuration['Signup.ShowStoreOnSignUp'] && (
                <>
                  <div class="form-actions">
                    <Link href={routes.signup_summary}>
                      <a class="btn btn--primary" href="#">
                        {t('Continue')}
                      </a>
                    </Link>
                  </div>
                </>
              )
            }
          </>
        )}
      </SignupPageLayout>
    );
  }
}

export default SignupPlanPage;
