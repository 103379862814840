import StoreBase from './storeBase';
import {action, observable, toJS} from 'mobx';
import {validationContext} from 'validx';
import {reactionWithOldValue} from '../utils/reactionWithOldValue';

const validation = validationContext();

class ValidationStore extends StoreBase {

  constructor({data, host} = {}) {
    super({data, host});
  }

  @observable data = {};
  @observable schemes = [];
  @observable validation = {};
  @observable validates = {};

  @action setup(name, data) {
    this.data[name] = data;
    this.schemes[name] = require(`./validationSchemas/${name}ValidationSchema`)[`${name}ValidationSchema`];
    this.validation[name] = this[`${name}Validation`] = validation.reset();
    this.validates[name] = action(() => {
      this.validation[name] = validation.reset().validate(this.data[name], this.schemes[name]());
    });

    reactionWithOldValue(
      () => toJS(this.data[name]),
      (data, oldData) => {
        this.validation[name] = validation.reset().validate(data, this.schemes[name]());
      },
    );
  }

  @action validate(name) {
    this.validation[name] = validation.reset().validate(this.data[name], this.schemes[name]());
  }

}

export default ValidationStore;
