import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import Link from 'next/link';
import FullPageLayout from 'ui/layouts/FullPageLayout';
import { withRouter } from 'next/router';
import { routes } from 'env/routes';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import { privateRoute } from 'env/privateRoute';

@privateRoute
@withRouter
@withCustomComponent('EsignPage')
@withTranslation()
@inject('appStore', 'authStore', 'invoicingStore')
@observer
class EsignPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasLoadedAccessToken: false,
    };
  }

  componentDidMount() {
    const { router, t, authStore, appStore, invoicingStore } = this.props;
    const { business } = appStore;

    const script = document.createElement('script');
    script.src =
      'https://s3.amazonaws.com/cdn.hellosign.com/public/js/hellosign-embedded.LATEST.min.js';
    script.addEventListener('load', () => {
      invoicingStore
        .loadSignaturePage(router.query.document_id)
        .then((data) => {
          if (!data.File) {
            appStore
              .setPopMessage(t('Sorry, we could not load this document.'))
              .then(() => {
                router.push(routes.home);
              });
          }

          var needsWrapping =
            !router.query.back &&
            !data.IsComplete &&
            window.location.host.indexOf('localhost') == -1 &&
            business.NativeHomeUrl.toLowerCase().indexOf(
              window.location.host.toLowerCase()
            ) == -1;

          if (needsWrapping) {
            setTimeout(async () => {
              const me = await authStore.loadMe();
              var returnUrl = `${business.NativeHomeUrl}${router.asPath}`;
              var redirectUrl = `${business.NativeHomeUrl}/login?t=${
                me.AccessToken
              }&redirectUrl=${encodeURIComponent(returnUrl)}`;
              window.location = redirectUrl;
            }, 5000);
          } else {
            this.setState({ hasLoadedAccessToken: true });
          }

          HelloSign.init(data.EsignClientId);
          return data;
        })
        .catch((err) => {
          appStore.setPopMessage(
            t('Sorry, we could not load this document. {{err}}', { err })
          );
        });
    });
    document.body.appendChild(script);
  }

  openHelloSignDialog = () => {
    const { invoicingStore, router, appStore } = this.props;
    const { signaturePage } = invoicingStore;
    const { business } = appStore;
    HelloSign.open({
      debug: process.env.NODE_ENV !== 'production',
      skipDomainVerification: process.env.NODE_ENV !== 'production',
      url: signaturePage.ESignUrl,
      allowCancel: true,
      uxVersion: 2,
      messageListener: function (eventData) {
        if (eventData.event == HelloSign.EVENT_CANCELED) {
          window.location = `${business.HomeUrl}/${router.asPath}?back=true`;
        }
        if (eventData.event == HelloSign.EVENT_SIGNED) {
          HelloSign.close();
          window.location = `${business.HomeUrl}/${router.asPath}?back=true`;
        }
      },
    });
  };

  render() {
    const { t, appStore, invoicingStore } = this.props;
    const { business } = appStore;
    const { hasLoadedSignaturePage, signaturePage } = invoicingStore;

    if (
      !hasLoadedSignaturePage ||
      !signaturePage.File ||
      !this.state.hasLoadedAccessToken
    )
      return <LoadingLayout />;

    return (
      <FullPageLayout
        componentName={this.props.componentName}
        title={t('Document signature from {{Name}}', {
          Name: t(business.Name),
        })}
      >
        <div className="mw-840 pb-24">
          {signaturePage.IsComplete && (
            <div className="alert alert-success" role="alert">
              <b>{t('Thank you, this document has been signed.')}</b>{' '}
              {t('We will email you the signed copy.')}
            </div>
          )}
          {!signaturePage.IsComplete && (
            <>
              <h4 className="d-flex align-items-center mb-24">
                <i className="icon-invoice fs-24 mr-8"></i>
                {t('Document Signature')}
              </h4>
              <p>
                {t(
                  '{{Name}} is requesting you to sign the following document',
                  { Name: t(business.Name) }
                )}
              </p>
            </>
          )}
          <div className="card py-20 px-24 h-auto">
            <strong>{t('File:')}</strong> {signaturePage.File.Name}
            {signaturePage.File.Description && (
              <p className="mt-16 mb-0">
                <strong>{t('Description:')}</strong>
                <br />
                {signaturePage.File.Description}
              </p>
            )}
          </div>
          <div className="btn-toolbar justify-content-end mt-24">
            {!signaturePage.IsComplete && (
              <button
                onClick={(ev) => {
                  ev.preventDefault();
                  this.openHelloSignDialog();
                }}
                className="btn btn-lg"
              >
                {t('Review and sign this document')}
              </button>
            )}
            {signaturePage.IsComplete && signaturePage.File.ProposalUniqueId && (
              <Link
                href={`${routes.proposal_summary}?proposal_id=${signaturePage.File.ProposalUniqueId}`}
              >
                <a href={`#`} className="btn btn-lg">
                  {t('Continue')}
                </a>
              </Link>
            )}
            {signaturePage.IsComplete && !signaturePage.File.ProposalUniqueId && (
              <Link href={routes.profile}>
                <a href={`#`} className="btn btn-lg">
                  {t('My account')}
                </a>
              </Link>
            )}
          </div>
        </div>
      </FullPageLayout>
    );
  }
}

export default EsignPage;
