import React, { Component } from 'react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'next/router';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import Link from 'next/link';
import { routes } from 'env/routes';
import _ from 'lodash';

@withCustomComponent('OpenPositions')
@withTranslation()
@withRouter
@inject('appStore', 'communityStore')
@observer
class OpenPositions extends Component {
  componentDidMount() {
  }

  render() {
    const { t, appStore, router } = this.props;

    return (
      <div
        data-component-name={this.props.componentName}
        className="about-page-open-positions"
      >
        <div className="container">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-md-12 col-lg-6 col-xl-5 mb-32">
                <img className="img-fluid rounded" src="/img/open-positions.jpg" />
                <small className="fs-12 text-gray-600 mt-8">{t('Team Meet Up at Kalkyo')}</small>
              </div>
              <div className="col-md-12 col-lg-1 col-xl-1">
              </div>
              <div className="col-md-12 col-lg-5 col-xl-4">
                <h4>{t('Our Team is growing, come and join us in our journey.')}</h4>
                <p className="mb-32">{t('Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.')}</p>
                <a className="btn btn-lg btn-outline" href="#">{t('See Open Positions')}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default OpenPositions;
