import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import { privateRoute } from 'env/privateRoute';
import { withCustomComponent } from 'ui/components/withCustomComponent';
import { TeamDashboard } from 'ui/_pages/home/TeamDashboard';
import Head from 'next/head';

@withCustomComponent('TeamDashboardPage')
@privateRoute
@withTranslation()
@inject('appStore')
class TeamDashboardPage extends Component {
  render() {
    const { appStore, t } = this.props;
    const { business } = appStore;
    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={t('Team Dashboard - {{Name}}', { Name: t(business.Name) })}
      >
        <Head>
          <title>
            {t('Team dashboard')} - {t(appStore.business.Name)}
          </title>
        </Head>
        <main
          data-component-name={this.props.componentName}
          className="content content--dashboard"
          role="main"
        >
          <TeamDashboard />
        </main>
      </DefaultLayout>
    );
  }
}

export default TeamDashboardPage;
