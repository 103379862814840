import React from 'react';
import {observer} from 'mobx-react';
import {withCustomComponent} from 'ui/components/withCustomComponent';
import {checkTerms} from 'env/checkTerms';
import MyBookingsPage from 'pages/bookings/my';

let MyBookingDetailsPage = props => {
  return <MyBookingsPage componentName={props.componentName}/>;
};

export default MyBookingDetailsPage = withCustomComponent('MyBookingDetailsPage')(
    checkTerms(
        observer(MyBookingDetailsPage),
    ),
);
