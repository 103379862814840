import React, {Component} from 'react';
import {observer, inject} from 'mobx-react';
import AppearanceSection from 'ui/_pages/profile/AppearanceSection';
import AccountDetailsSection from 'ui/_pages/profile/AccountDetailsSection';
import BillingDetailsSection from 'ui/_pages/profile/BillingDetailsSection';
import CustomFieldsSection from 'ui/_pages/profile/CustomFieldsSection';
import ProfessionalProfileSection from 'ui/_pages/profile/ProfessionalProfileSection';
import RegisteredDevicesSection from 'ui/_pages/profile/RegisteredDevicesSection';
import SocialNetworksSection from 'ui/_pages/profile/SocialNetworksSection';
import PasswordSection from 'ui/_pages/profile/PasswordSection';
import NotificationsSection from 'ui/_pages/profile/NotificationsSection';
import LanguageSection from 'ui/_pages/profile/LanguageSection';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('CustomerProfileForm')
@inject('authStore', 'appStore')
@observer
class   CustomerProfileForm extends Component {
  componentDidMount() {
    const {loadCustomer, authStore} = this.props;
    const {isLoggedIn} = authStore;

    authStore.loadCustomer();
    if (isLoggedIn) authStore.loadCustomFields();
    else authStore.loadSignupCustomFields();
  }

  saveCustomer = () => {
    const {appStore, authStore} = this.props;
    authStore.saveCustomer().catch((err) => {
      appStore.setPopMessage(err);
    });
  };

  render() {
    const {appStore, authStore} = this.props;
    const isCorporateDashboard = appStore.corporateDashboard;
    const {
      hasLoadedCustomer,
      isLoggedIn,
      customFieldsGroups,
      signupCustomFieldsGroups,
    } = authStore;
    const groups = isLoggedIn ? customFieldsGroups : signupCustomFieldsGroups;
    if (!hasLoadedCustomer) return <></>;

    return (
      <form
        data-component-name={this.props.componentName}
        id="profile-form"
        action="#"
        className="form"
      >
        <AppearanceSection
          saveCustomer={this.saveCustomer}
          customer={authStore.customer}
        />

        <AccountDetailsSection
          saveCustomer={this.saveCustomer}
          customer={authStore.customer}
        />
        {!isCorporateDashboard &&
          <BillingDetailsSection
            saveCustomer={this.saveCustomer}
            customer={authStore.customer}
          />
        }
        {groups.length > 0 && (
          <CustomFieldsSection
            saveCustomer={this.saveCustomer}
            customer={authStore.customer}
          />
        )}
        {/*<ProfessionalProfileSection*/}
        {/*  saveCustomer={this.saveCustomer}*/}
        {/*  customer={authStore.customer}*/}
        {/*/>*/}
        <RegisteredDevicesSection
          saveCustomer={this.saveCustomer}
          customer={authStore.customer}
        />
        {/*<SocialNetworksSection*/}
        {/*  saveCustomer={this.saveCustomer}*/}
        {/*  customer={authStore.customer}*/}
        {/*/>*/}
        <PasswordSection
          saveCustomer={this.saveCustomer}
          customer={authStore.customer}
        />
        {/*<NotificationsSection*/}
        {/*  saveCustomer={this.saveCustomer}*/}
        {/*  customer={authStore.customer}*/}
        {/*  user={authStore.me}*/}
        {/*/>*/}
        <LanguageSection/>
      </form>
    );
  }
}

export default CustomerProfileForm;
