import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import CardDashboardSmall from 'ui/components/cards/CardDashboardSmall';
import withCustomComponent from 'ui/components/withCustomComponent';
import { routes } from 'env/routes';

@withCustomComponent('CheckinWidgetSmall')
@withTranslation()
@inject('appStore', 'communityStore')
@observer
class CheckinWidgetSmall extends Component {
  componentDidMount() {
    const { communityStore } = this.props;
    communityStore.loadCheckedInMetrics().catch(() => null);
  }

  render() {
    const { t, appStore, communityStore, oneLiner } = this.props;
    const { configuration, business } = appStore;

    const { checkedInMetrics } = communityStore;
    const total = checkedInMetrics.counted + checkedInMetrics.checkedIn;
    const capacity = checkedInMetrics.capacity;

    const teamCount =
      checkedInMetrics?.network &&
      _.sum(
        checkedInMetrics.network.map((metric) =>
          _.sumBy(Object.keys(metric.byTeam), (m) => metric.byTeam[m])
        )
      );

    const svg = (primaryColor) => (
      <svg
        width="98px"
        height="80px"
        version="1.1"
        id="CheckinWidgetSvg"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 97.6 79.4"
        enableBackground="new 0 0 97.6 79.4"
      >
        <g>
          <circle
            id="Oval"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#F5F5F5"
            cx="54.9"
            cy="41.6"
            r="37.9"
          />
          <g>
            <path
              id="Path_2_"
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M36,76.5H19.3c-9.8,0-17.8-7.9-17.8-17.8V41
				c0-9.8,7.9-17.8,17.8-17.8H36c9.8,0,17.8,7.9,17.8,17.8v17.8C53.7,68.5,45.8,76.5,36,76.5z"
            />
            <path
              fill={primaryColor}
              d="M36,21.7H19.3C8.6,21.7,0,30.3,0,41v17.8C0,69.3,8.6,78,19.3,78H36c5.1,0,10-2,13.6-5.6
				c3.6-3.6,5.6-8.5,5.6-13.6V41C55.2,30.3,46.6,21.7,36,21.7z M52.2,58.7c0,4.3-1.7,8.4-4.8,11.5S40.3,75,36,75H19.3
				C10.3,75,3,67.7,3,58.7V41c0-9,7.3-16.3,16.3-16.3H36c9,0,16.3,7.3,16.3,16.3V58.7z"
            />
          </g>
          <path
            id="Path_1_"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M21.7,42.4v3V42.4z"
          />
          <path
            fill={primaryColor}
            d="M21.7,46.9c-0.8,0-1.5-0.7-1.5-1.5v-3c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v3
			C23.2,46.2,22.5,46.9,21.7,46.9z"
          />
          <path
            id="Path_3_"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M33.5,42.4v3V42.4z"
          />
          <path
            fill={primaryColor}
            d="M33.5,46.9c-0.8,0-1.5-0.7-1.5-1.5v-3c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5v3
			C35,46.2,34.4,46.9,33.5,46.9z"
          />
          <path
            id="Path_5_"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M27.6,60.2c-6.5,0-10.4-3.9-10.4-3.9L27.6,60.2z"
          />
          <g>
            <path
              id="Path_8_"
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M27.6,60.2c-6.5,0-10.4-3.9-10.4-3.9"
            />
            <path
              fill={primaryColor}
              d="M27.6,61.7c-7,0-11.2-4.1-11.4-4.3c-0.6-0.6-0.6-1.5,0-2.1s1.5-0.6,2.1,0c0,0,3.6,3.4,9.3,3.4
				c0.8,0,1.5,0.7,1.5,1.5S28.5,61.7,27.6,61.7z"
            />
          </g>
          <path
            id="Path_7_"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M38,56.3c0,0-3.9,3.9-10.4,3.9L38,56.3z"
          />
          <g>
            <path
              id="Path_9_"
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M38,56.3c0,0-3.9,3.9-10.4,3.9"
            />
            <path
              fill={primaryColor}
              d="M27.6,61.7c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5c5.8,0,9.3-3.4,9.3-3.4c0.6-0.6,1.5-0.6,2.1,0
				s0.6,1.5,0,2.1C38.9,57.6,34.6,61.7,27.6,61.7z"
            />
          </g>
          <g>
            <path
              id="Path_11_"
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M92.1,5.5L92.1,5.5c6,6,6,15.7,0,21.7l-8.3,8.3
				c-1.4,1.4-3.7,1.4-5.1,0l-8.3-8.3c-6-6-6-15.7,0-21.7l0,0C76.4-0.5,86.1-0.5,92.1,5.5z"
            />
            <path
              fill={primaryColor}
              d="M81.3,37.5c-1.2,0-2.4-0.4-3.3-1.3l-8.3-8.3c-3.1-3.1-4.8-7.2-4.8-11.5s1.7-8.5,4.8-11.5
				c6.4-6.4,16.7-6.4,23.1,0c3.1,3.1,4.8,7.2,4.8,11.5s-1.7,8.5-4.8,11.5l-8.3,8.3C83.7,37,82.5,37.5,81.3,37.5z M81.3,2
				C77.6,2,74,3.4,71.2,6.2C68.5,8.9,67,12.5,67,16.3s1.5,7.4,4.2,10.1l8.3,8.3c1,1,2.7,1,3.7,0l8.3-8.3c2.7-2.7,4.2-6.3,4.2-10.1
				s-1.5-7.4-4.2-10.1l0,0C88.6,3.4,85,2,81.3,2z"
            />
          </g>
          <polyline
            id="Path_10_"
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            points="86.3,14.1 80.3,20.1 77.4,17.2 		"
          />
          <path
            fill={primaryColor}
            d="M80.3,21.1c-0.3,0-0.5-0.1-0.7-0.3l-2.9-2.9c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l2.2,2.2l5.3-5.3
			c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-6,6C80.8,21,80.6,21.1,80.3,21.1z"
          />
          <path
            fill={primaryColor}
            d="M84.1,57.8c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.5-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7l1.4-1.4
			c0.4-0.4,1-0.4,1.4,0c0.2,0.2,0.3,0.5,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7l-1.4,1.4C84.6,57.7,84.4,57.8,84.1,57.8z"
          />
          <g>
            <path
              id="Path_14_"
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M76.6,65l3.7,11.1c0.2,0.5,0.6,0.9,1.1,0.9
				c0.5,0.1,1-0.2,1.3-0.7l2.3-3.8c0.2-0.3,0.5-0.6,0.8-0.8l3.8-2.3c0.5-0.3,0.7-0.8,0.7-1.3c-0.1-0.5-0.4-1-0.9-1.1l-11.1-3.7
				c-0.5-0.2-1,0-1.4,0.3C76.6,64,76.4,64.5,76.6,65z"
            />
            <path
              fill={primaryColor}
              d="M81.6,78.1c-0.1,0-0.2,0-0.3,0c-0.9-0.1-1.7-0.7-2-1.6l-3.7-11.1c-0.3-0.9-0.1-1.8,0.6-2.4
				c0.6-0.6,1.6-0.9,2.4-0.6l11.1,3.7c0.9,0.3,1.5,1.1,1.6,2c0.1,0.9-0.3,1.8-1.1,2.3l-3.8,2.3c-0.2,0.1-0.4,0.3-0.5,0.5l-2.3,3.8
				C83.2,77.6,82.4,78.1,81.6,78.1z M77.9,64.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.4l0,0l3.7,11.1c0,0.1,0.2,0.2,0.3,0.2
				c0.1,0,0.3,0,0.3-0.2l2.3-3.8c0.3-0.5,0.7-0.9,1.2-1.2l3.8-2.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1-0.1-0.3-0.2-0.3L78,64.3
				C78,64.2,77.9,64.2,77.9,64.2z"
            />
          </g>
          <path
            fill={primaryColor}
            d="M89.5,77.2c-0.3,0-0.5-0.1-0.7-0.3L85,73.2c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l3.8,3.8c0.4,0.4,0.4,1,0,1.4
			C90,77.1,89.8,77.2,89.5,77.2z"
          />
          <path
            fill={primaryColor}
            d="M77.1,54.9c-0.5,0-1-0.4-1-1l0-2c0-0.6,0.4-1,1-1c0,0,0,0,0,0c0.5,0,1,0.4,1,1l0,2
			C78.1,54.4,77.7,54.9,77.1,54.9C77.1,54.9,77.1,54.9,77.1,54.9z"
          />
          <path
            fill={primaryColor}
            d="M70.1,57.7c-0.3,0-0.5-0.1-0.7-0.3L68,56.1c-0.2-0.2-0.3-0.5-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7
			c0.4-0.4,1-0.4,1.4,0l1.4,1.4c0.2,0.2,0.3,0.5,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7C70.6,57.6,70.3,57.7,70.1,57.7z"
          />
          <path
            fill={primaryColor}
            d="M65.2,64.8c-0.5,0-1-0.4-1-1c0,0,0,0,0,0c0-0.5,0.4-1,1-1l2,0c0.5,0,1,0.4,1,1c0,0,0,0,0,0
			c0,0.5-0.4,1-1,1L65.2,64.8C65.2,64.8,65.2,64.8,65.2,64.8z"
          />
          <path
            fill={primaryColor}
            d="M68.7,73.2c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7l1.4-1.4
			c0.4-0.4,1-0.4,1.4,0c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7l-1.4,1.4C69.2,73.1,68.9,73.2,68.7,73.2z"
          />
        </g>
      </svg>
    );

    return (
      <CardDashboardSmall
        title={t('Members onsite')}
        url={routes.capacity}
        mainValue={total}
        subValue={capacity}
        zeroMessage={t('No one at {{Name}} yet!', { Name: t(business.Name) })}
        svg={svg('var(--bg-color)')}
        oneLiner={oneLiner}
      >
        {teamCount > 0 && (
          <small>
            {t('{{count}} colleague are on site today', { count: teamCount })}
          </small>
        )}
      </CardDashboardSmall>
    );
  }
}
export default CheckinWidgetSmall;
