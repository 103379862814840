import React from 'react';
import {useTranslation} from 'react-i18next';
import {withRouter} from 'next/router';
import {inject, observer} from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import InputField from 'ui/components/forms/InputField';
import {TeamAvatar} from 'ui/components/TeamAvatar';
import asForm from 'ui/components/forms/asForm';
import FileInputField from 'ui/components/forms/FileInputField';
import BooleanInputField from 'ui/components/forms/BooleanInputField';
import ActionButton from 'ui/components/forms/ActionButton';
import MarkdownInputField from 'ui/components/forms/MarkdownInputField';
import TagsInputField from 'ui/components/forms/TagsInputField';

export const TeamDetailsForm = asForm(
  withCustomComponent('TeamDetailsForm')(
    inject(
      'appStore',
      'authStore'
    )(
      withRouter(
        observer(
          ({
             router,
             appStore,
             authStore,
             saveTeam,
             updateProperty,
             team,
             componentName,
           }) => {
            const {t} = useTranslation();
            const {business, hasLoadedProfileTags, profileTags} = appStore;

            //Group the tags in groups of 2. This helps with the layout below
            var groupedTags = _(profileTags)
              .groupBy(function (element) {
                const index = profileTags.indexOf(element);
                return Math.floor(index / 2);
              })
              .toArray()
              .value();

            return (
              <fieldset>
                <div className="card-item-wrapper">
                  <div className="card-item-header">
                    <h5 className="card-item-header-title">
                      {t('Team details')}
                    </h5>
                  </div>
                  <div className="card-item-body">
                    <div className="form-group mb-32">
                      <div className="row">
                        <div className="col-12 col-md-3 pb-16 pb-md-0">
                          <TeamAvatar
                            team_id={team.Id}
                            size={320}
                            title={team.Name}
                            imgClassName={'avatar avatar--160'}
                          />
                        </div>
                        <div className="col-12 col-md-9">
                          <FileInputField
                            name="base64teamLogo"
                            label={t(
                              'File smaller than 10MB and at least 400px by 400px.'
                            )}
                            labelClassName={'mb-8'}
                            onChange={updateProperty}
                          />
                          <p className="fs-14 text-gray-700 mt-8 mb-12 mxw-500px">
                            {t(
                              'This image will be shown in the members/teams directory and your profile page if you choose to share it with other members. It will also help us recognize you!'
                            )}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group required">
                          <InputField
                            id={team.Id + '-team-name'}
                            label={t('Name')}
                            name="Name"
                            value={team.Name}
                            onChange={updateProperty}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group required">
                          <InputField
                            id={team.Id + '-team-website'}
                            label={t('Website')}
                            name="ProfileWebsite"
                            value={team.ProfileWebsite}
                            onChange={updateProperty}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <MarkdownInputField
                        id={team.Id + '-team-about'}
                        label={t('About {{Name}}', {Name: team.Name})}
                        name="ProfileSummary"
                        value={team.ProfileSummary}
                        onChange={updateProperty}
                      />
                      <small>
                        {t(
                          'You can use MarkDown syntax to give format to your team summary'
                        )}
                      </small>
                    </div>

                    {hasLoadedProfileTags && !profileTags && (
                      <div className="form-group">
                        <TagsInputField
                          id={team.Id + '-team-tags'}
                          label={t('Industries / Markets')}
                          name="ProfileTags"
                          value={team.ProfileTags}
                          onChange={updateProperty}
                        />
                      </div>
                    )}

                    {hasLoadedProfileTags &&
                      profileTags &&
                      profileTags.length > 0 && (
                        <>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group mb-4">
                                <label
                                  className="control-label"
                                  htmlFor="ProfileTags"
                                >
                                  {t('Industries / Markets')}
                                </label>
                              </div>
                            </div>
                          </div>
                          {groupedTags.map((group, g) => (
                            <div key={g} className="row">
                              {group.map((tag, i) => (
                                <div key={i} className="col-md-6">
                                  <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={team.Id + '-' + tag}
                                      name={tag}
                                      onChange={(ev) =>
                                        ev.target.checked
                                          ? updateProperty('ProfileTagsList', [
                                            ...team.ProfileTagsList,
                                            tag.toLowerCase(),
                                          ])
                                          : updateProperty(
                                            'ProfileTagsList',
                                            _.without(
                                              team.ProfileTagsList,
                                              tag.toLowerCase()
                                            )
                                          )
                                      }
                                      checked={
                                        team.ProfileTagsList &&
                                        team.ProfileTagsList.indexOf(
                                          tag.toLowerCase()
                                        ) > -1
                                      }
                                    />
                                    <label
                                      className="custom-control-label fs-14"
                                      htmlFor={team.Id + '-' + tag}
                                    >
                                      {tag}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                        </>
                      )}

                    <div className="row mt-32 mb-24">
                      <div className="col-sm-12">
                        <BooleanInputField
                          id={team.Id + '-team-profileIsPublic'}
                          label="Include this profile in the company directory."
                          name="ProfileIsPublic"
                          value={team.ProfileIsPublic}
                          onChange={updateProperty}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {saveTeam && (
                  <ActionButton
                    action={() => saveTeam()}
                    busy={authStore.isSavingTeamProfile}
                  />
                )}
              </fieldset>
            );
          }
        )
      )
    )
  ),
  'team'
);
