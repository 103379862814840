import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Trans, withTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import Link from 'next/link';
import { routes } from 'env/routes';
import withCustomComponent from 'ui/components/withCustomComponent';

import CardEvent from 'ui/components/cards/CardEvent';
import CardPerkDiscount from 'ui/components/cards/CardPerkDiscount';
import NewPrivateMessage, {
  showNewPrivateMessageModal,
} from 'ui/_pages/directory/members/NewPrivateMessage';

@withCustomComponent('NewMemberCard')
@withTranslation()
@inject('appStore')
@observer
class NewMemberCard extends Component {
  componentDidMount() {}

  render() {
    const { t, appStore, member } = this.props;
    const { business } = appStore;

    return (
      <div
        data-component-name={this.props.componentName}
        className="virtual-notification-card"
      >
        <div className="card h-auto p-16 p-md-24 mb-24">
          <header className="media mb-32">
            <Link
              href={{ pathname: routes.directory_member_by_id('[member_id]') }}
              as={{
                pathname: routes.directory_member_by_id({
                  Id: member.Id,
                  FullName: member.FullName,
                }),
              }}
            >
              <a
                className="avatar avatar--48"
                style={{
                  backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${member.Id}?h=96&w=96&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png)`,
                }}
              ></a>
            </Link>
            <div className="media-body d-flex justify-content-between pl-16">
              <div className="text-gray-600">
                <div className="fw-500">
                  <Trans>
                    <a href="#">{member.FullName}</a>
                    <span> joined </span>
                    <span>{t(business.Name)}. </span>
                    <span>Give them a warm welcome.</span>
                  </Trans>
                </div>
                <div className="fs-14 lh-14">
                  {moment(
                    member.RegistrationDateUtc ?? member.CreatedOnUtc
                  ).fromNow()}
                </div>
              </div>
            </div>
          </header>

          <footer>
            <div className="btn-group">
              <a
                onClick={() => showNewPrivateMessageModal(member.Id)}
                className="btn "
              >
                {t('Say hello')}
              </a>
              <NewPrivateMessage member={member} />
              <Link
                href={{
                  pathname: routes.directory_member_by_id('[member_id]'),
                }}
                as={{
                  pathname: routes.directory_member_by_id({
                    Id: member.Id,
                    FullName: member.FullName,
                  }),
                }}
              >
                <a className="btn  btn-white">{t('View profile')}</a>
              </Link>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default NewMemberCard;
