import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { TeamAvatar } from 'ui/components/TeamAvatar';
import { removeHtml } from 'env/utils/strings';
export const TeamRelatedProfile = withCustomComponent('TeamRelatedProfile')(
  inject('appStore')(
    withRouter(
      observer(({ componentName, router, appStore, team, className }) => {
        const { t } = useTranslation();
        const { business } = appStore;
        return (
          <div
            data-component-name={componentName}
            className={`media team-related-profile ${className}`}>
            <TeamAvatar
              team_id={team.Id}
              size={96}
              title={team.FulLName}
              imgClassName="avatar avatar--48"
            />
            <div className="media-body ml-8">
              <Link
                as={routes.directory_team_by_id(team)}
                href={routes.directory_team_by_id('[team_id]')}
              >
                <a className="text-gray-900">{removeHtml(team.Name)}</a>
              </Link>
              <div className="fs-14 text-gray-600">
                {team.AllTeamMembers.length > 0 &&
                  <>
                    {t('{{AllTeamMembers}} team members', {
                      AllTeamMembers: team.AllTeamMembers.length,
                    })}
                  </>
                }
              </div>
            </div>
          </div>
        );
      })
    )
  )
);
