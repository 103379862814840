import React, { Component } from 'react';
import { observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { withRouter } from 'next/router';
import ReactPlayer from 'react-player';

@withRouter
@withCustomComponent('PassportVideoPage')
@observer
class PassportVideoPage extends Component {
    render() {
        const { router } = this.props;
        let widthValue = router.query.w ? parseInt(router.query.w) : 300;

        return (
            <div>
                <ReactPlayer controls width={`${widthValue}px`} height={`${widthValue / 16 * 9}px`} url={router.query.url} />
            </div>
        );
    }
}

export default PassportVideoPage;
