import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Link from 'next/link';
import _ from 'lodash';
import { routes } from 'env/routes';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';

import CardBlogPost from 'ui/components/cards/CardBlogPost';
import LoadingWidget from 'ui/_pages/home/components/LoadingWidget/LoadingWidget';

@withCustomComponent('FeaturedBlogPostWidget')
@withTranslation()
@inject('appStore', 'blogStore')
@observer
class FeaturedBlogPostWidget extends Component {
  componentDidMount() {
    const { blogStore } = this.props;
    blogStore.loadBannerBlogPosts().catch((ex) => null);
  }

  render() {
    const { t, appStore, blogStore } = this.props;
    const { hasLoadedBannerBlogPosts, bannerBlogPosts } = blogStore;

    if (!hasLoadedBannerBlogPosts)
      return <LoadingWidget title={t('Featured articles')} />;

    if (bannerBlogPosts.length === 0) return <></>;

    const firstBannerBlogPosts = _.first(bannerBlogPosts);

    return (
      <div className="col mxw-xl-50 mt-40">
        <div
          data-component-name={this.props.componentName}
          className="dashboard-featured-blog-post-widget"
        >
          <h6>{t('Featured articles')}</h6>
          <CardBlogPost post={firstBannerBlogPosts} />
          <div className="mt-16">
            <Link href={routes.posts}>
              <a className="text-gray-900">{t('See all articles')}</a>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default FeaturedBlogPostWidget;
