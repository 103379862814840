import React, { Component } from 'react';
import LoadingSpinner from 'ui/components/LoadingSpinner';

const LoadingWidget = ({ title, className = 'col mxw-xl-50 mt-40 mb-20' }) => {
  return (
    <div className={className}>
      <h6>{title}</h6>
      <div className="card card-dashboard">
        <LoadingSpinner />
      </div>
    </div>
  );
};

export default LoadingWidget;
