import TagManager from 'react-gtm-module';

let isEnabled = false;

export const initGoogleTagManager = (tmCode) => {
  if (!tmCode || tmCode.indexOf('GTM-') == -1) return;

  const tagManagerArgs = {
    gtmId: tmCode,
    dataLayerName: 'PageDataLayer',
  };
  TagManager.initialize(tagManagerArgs);

  window.addTagManagerEvent = addTagManagerEvent;
  isEnabled = true;
};

export const addTagManagerEvent = ({ customer, name, route, data }) => {
  if (!isEnabled) return;
  if (name.indexOf('Auth.Me') > -1) return;
  if (name.indexOf('Auth.Notifications') > -1) return;
  const tagManagerArgs = {
    dataLayer: {
      event: name,
      customerId: customer?.Id,
      isMember: customer?.IsMember,
      plans: customer?.TariffNames,
      teams: customer?.TeamNames,
      page: route,
      data: data && JSON.parse(JSON.stringify(data)),
    },
    dataLayerName: 'PageDataLayer',
  };
  console.log(`TM:`, tagManagerArgs);
  TagManager.dataLayer(tagManagerArgs);
};
