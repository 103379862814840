import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import withScript from 'env/utils/withScript';
import withCustomComponent from 'ui/components/withCustomComponent';
import {addTagManagerEvent} from 'env/TagManager';

@withCustomComponent('SpreedlyPaymentForm')
@withTranslation()
@withScript('https://core.spreedly.com/iframe/iframe-v1.min.js')
@inject('authStore', 'invoicingStore', 'appStore')
@observer
class SpreedlyPaymentForm extends Component {
  initSpreedly = () => {
    //Make sure to run this code only once!

    if (window.SpreedlyFormInit) return;
    window.SpreedlyFormInit = true;

    const {t, onPaymentComplete, invoicingStore, authStore, appStore} =
      this.props;

    const {configuration} = appStore;
    const {unpaidInvoices} = invoicingStore;
    const $ = window.$;
    const Spreedly = window.Spreedly;
    const spreedlyEnvKey = configuration['System.SpreedlyEnvironmentKey'];

    const processSingleInvoice = async () => {
      await invoicingStore.loadInvoicesPage();
      await authStore.loadCustomer();

      const checkSpreedly = () => {
        if (window.Spreedly) {
          $(`#spreedlyButton-${unpaidInvoices[0].Id}`).click();
          return;
        } else setTimeout(checkSpreedly, 500);
      };
      setTimeout(checkSpreedly, 500);
    };

    const resetButtonStatus = () => {
      $('#spreedly-ccpay-button').attr('disabled', null);
      $('#spreedly-ccpay-button').text(t('Update payment details'));
    };

    Spreedly.init(spreedlyEnvKey, {
      numberEl: 'spreedly-number',
      cvvEl: 'spreedly-cvv',
    });

    Spreedly.on('ready', function () {
      Spreedly.setStyle('number', 'width:225px;');
      resetButtonStatus();
    });

    Spreedly.on('errors', (errors) => {
      if (errors.length > 0) {
        appStore.setPopMessage(errors[0].message);
      }
      resetButtonStatus();
    });

    Spreedly.on('paymentMethod', function (token, pmData) {
      var tokenField = document.getElementById('payment_method_token');
      tokenField.setAttribute('value', token);

      const agent = appStore.getAgent();
      agent.requests
        .post('/payments/SpreedlyPaymentToken', {
          id: window.clickedInvoice ?? 0,
          token: token,
          payment_data: JSON.stringify(pmData),
        })
        .then((response) => {
          resetButtonStatus();
          if (response.ErrorMessage) {
            appStore.setPopMessage('Sorry, we could not process your payment');

            addTagManagerEvent({
              name: 'nexudus:payments:fail',
              customer: authStore.customer,
              data: response.ErrorMessage,
            });

            return;
          }
          if (window.clickedInvoice > 0) {
            window.clickedInvoice = null;

            addTagManagerEvent({
              name: 'nexudus:payments:success',
              customer: authStore.customer,
              data: invoices.find((i) => i.Id == window.clickedInvoice),
            });

            appStore.setPopMessage(
              'Thank you, we have processed your payment.'
            );
          } else {
            if (unpaidInvoices.length == 1) {
              processSingleInvoice();
              return;
            }
            const message =
              unpaidInvoices.length > 1
                ? t(
                  "Thank you, we have updated your payment details. Please click 'Pay by card' to use these details to pay an invoice"
                )
                : t('Thank you, we have updated your payment details.');

            addTagManagerEvent({
              name: 'nexudus:payments:updated',
              customer: authStore.customer,
            });

            appStore.setPopMessage(message).then(() => {
              location.reload();
              return;
            });
          }

          if (onPaymentComplete) onPaymentComplete();
        });
    });

    $('#spreedly-ccpay-button').click(function () {
      var url = $(this).attr('data-payurl');

      if ($('#spreedly-ccpay-button').attr('disabled')) return;
      $('#spreedly-ccpay-button').attr('disabled', 'disabled');
      $('#spreedly-ccpay-button').text(t('Please wait...'));

      var paymentMethodFields = [
        'first_name',
        'last_name',
        'month',
        'year',
        'address1',
        'city',
        'country',
        'zip',
        'state',
      ];
      var requiredFields = {};
      for (var i = 0; i < paymentMethodFields.length; i++) {
        var field = paymentMethodFields[i];
        requiredFields[field] = document.getElementById(field).value;
      }

      Spreedly.tokenizeCreditCard(requiredFields);
    });
  };

  componentWillUnmount() {
    window.SpreedlyFormInit = false;
  }

  componentDidMount() {
    const checkSpreedly = () => {
      if (window.Spreedly) this.initSpreedly();
      else setTimeout(checkSpreedly, 1000);
    };
    setTimeout(checkSpreedly, 1000);

    const {authStore} = this.props;
    const {hasLoadedTeams, hasLoadedPayingMember} = authStore;
    if (!hasLoadedTeams) authStore.loadTeams();
    if (!hasLoadedPayingMember) authStore.loadPayingMember();
  }

  render() {
    const {returnUrl, smallForm, t, authStore, appStore, componentName} =
      this.props;
    const {business} = appStore;
    const {teams, customer, payingMember} = authStore;
    const now = new Date().getUTCFullYear();
    const years = Array(now - (now - 20))
      .fill('')
      .map((v, idx) => now + idx);

    const customerCardNumberPlaceholder = '#### #### #### ####';
    let customerCardNumberArray = [];

    if (customer.CardNumber && customerCardNumberPlaceholder.length > customer.CardNumber.length) {
      for(let i = customerCardNumberPlaceholder.length - 1, j = customer.CardNumber.length - 1; i > 0; i--) {
        if (customerCardNumberPlaceholder[i] === ' ') {
          customerCardNumberArray.unshift(customerCardNumberPlaceholder[i]);
          continue;
        }

        if (j >= 0) {
          customerCardNumberArray.unshift(customer.CardNumber[j])
          j--;
          continue;
        }

        customerCardNumberArray.unshift(customerCardNumberPlaceholder[i]);
      }
    }

    return (
      <>
        <div className="card-item-wrapper">
          <div className="card-item-header">
            <h5 className="card-item-header-title">
              {t('Credit/Debit card payments')}
            </h5>

            <button type="button" className="btn btn-gray-light" data-toggle="modal"
                    data-target="#cc-billing-info-modal">
              {t("New card's billing details")}
            </button>
          </div>
          <div className="card-item-body">
            <div id="device-fingerprint" className="hidden"></div>

            <div className="modal" id="challenge-modal" tabIndex="-1" role="dialog">
              <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h3 className="modal-title" id="modal-message-text">
                      {t('Additional payment information required')}
                    </h3>
                  </div>
                  <div className="modal-body">
                    <div id="challenge"></div>
                  </div>
                </div>
              </div>
            </div>

            {/* <!-- PAYMENT INFORMATION --> */}
            {teams.length > 0 && (
              <div className="alert alert-info fs-14 lh-14">
                <strong>{t('Heads up!')}</strong>{' '}
                {t('You are part of the following team: ')}
                <strong>{teams.map((x) => x.Name).join(', ')}</strong>.
                {payingMember &&
                  t('Any bills will be paid by: {{FullName}}', {
                    FullName: payingMember.FullName,
                  })}
              </div>
            )}

            {customer.CardNumber && (
              <div className="alert alert-info fs-14 lh-14" role="alert">
                {t(
                  'The card we have on your file ends in "{{CardNumber}}". You can use the form below to enter a new set of payment details.',
                  {
                    CardNumber: customer.CardNumber,
                  }
                )}
              </div>
            )}

            <div className="credit-card-information">
              <div className="credit-card-item-form">
                <form
                  data-component-name={componentName}
                  className="form"
                  id="payment-form"
                  acceptCharset="UTF-8"
                  action="#"
                  method="POST"
                  onSubmit={() => false}
                >
                  <input
                    type="hidden"
                    name="token"
                    id="payment_method_token"
                    defaultValue=""
                  />
                  <div className="row">
                    <div className={smallForm ? 'col-md-6' : 'col-md-6'}>
                      <div className="form-group">
                        <label htmlFor="">{t('First name')}</label>
                        <input
                          type="text"
                          id="first_name"
                          className="form-control"
                          name="credit_card[first_name]"
                          defaultValue={!customer.CardNumber ? customer.GuessedFirstNameForInvoice : ''}
                        />
                      </div>
                    </div>
                    <div className={smallForm ? 'col-md-6' : 'col-md-6'}>
                      <div className="form-group">
                        <label htmlFor="">{t('Last name')}</label>
                        <input
                          type="text"
                          id="last_name"
                          className="form-control"
                          name="credit_card[last_name]"
                          defaultValue={!customer.CardNumber ? customer.GuessedLastNameForInvoice : ''}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="spreedly-number">
                          {t('Card Number')}
                        </label>
                        <div
                          id="spreedly-number"
                          className="form-control"
                          style={{paddingTop: '1rem'}}
                        ></div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="">{t('Expires')}</label>
                        <div className="form-group--date-wrapper">
                          <div className="form-row">
                            <div className="col-7">
                              <select
                                type="text"
                                id="month"
                                className="form-control"
                                name="credit_card[month]"
                                placeholder="MM"
                              >
                                <option></option>
                                <option value="01">01 - {t('January')}</option>
                                <option value="02">02 - {t('February')}</option>
                                <option value="03">03 - {t('March')}</option>
                                <option value="04">04 - {t('April')}</option>
                                <option value="05">05 - {t('May')}</option>
                                <option value="06">06 - {t('June')}</option>
                                <option value="07">07 - {t('July')}</option>
                                <option value="08">08 - {t('August')}</option>
                                <option value="09">09 - {t('September')}</option>
                                <option value="10">10 - {t('October')}</option>
                                <option value="11">11 - {t('November')}</option>
                                <option value="12">12 - {t('December')}</option>
                              </select>
                            </div>
                            <div className="col-5">
                              <select
                                type="text"
                                id="year"
                                className="form-control"
                                name="credit_card[year]"
                              >
                                <option></option>
                                {years.map((year, i) => (
                                  <option key={i} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          htmlFor="spreedly-cvv"
                          title={t('Security Code')}
                        >
                          {t('CVV')}
                        </label>
                        <div
                          id="spreedly-cvv"
                          className="form-control"
                          style={{paddingTop: '1rem'}}
                        ></div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div
              className="modal custom-modal"
              id="cc-billing-info-modal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
              data-backdrop="static"
              data-keyboard="false"
            >
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">
                      {t("New card's billing details")}
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <i className="icon-close-large"></i>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="">{t('Billing Address')}</label>
                          <textarea
                            className="form-control"
                            id="address1"
                            rows="3"
                            name="credit_card[address1]"
                            defaultValue={!customer.CardNumber ? customer.AddressForInvoice : ''}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="">{t('City')}</label>
                          <input
                            className="form-control"
                            id="city"
                            name="credit_card[city]"
                            type="text"
                            defaultValue={!customer.CardNumber ? customer.CityForInvoice : ''}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="">{t('State')}</label>
                          <input
                            className="form-control"
                            id="state"
                            name="credit_card[state]"
                            type="text"
                            defaultValue={!customer.CardNumber ? customer.StateForInvoice : ''}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="">{t('Zip / Postcode')}</label>
                          <input
                            className="form-control"
                            id="zip"
                            name="credit_card[zip]"
                            type="text"
                            defaultValue={!customer.CardNumber ? customer.PostCodeForInvoice : ''}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="">{t('Country')}</label>
                          <input
                            className="form-control"
                            id="country"
                            name="credit_card[country]"
                            type="text"
                            defaultValue={!customer.CardNumber ? business.Country.Name : ''}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn" data-dismiss="modal" aria-label="Close">
                      {t('Close')}
                    </button>
                  </div>
                </div>
              </div>
            </div>


            {((appStore.configuration['Spreedly.KeepCardDetailsForMembers'] &&
                customer.IsMember) ||
              (appStore.configuration['Spreedly.KeepCardDetailsForContacts'] &&
                customer.IsContact)) && (
              <div className="alert alert-warning fs-14 lh-14 mt-10" role="alert">
                <strong>{t('Heads up!')}</strong>{' '}
                {t(
                  'By clicking "Update payment details", you authorize "{{Name}}" to automatically debit your bank account for any due invoices and, if necessary, credit your account to correct any erroneous debits.',
                  {Name: t(business.Name)}
                )}
              </div>
            )}
          </div>
        </div>
        <div className="form-actions btn-toolbar">
          {customer.CardNumber && this.props.btnToolbarContentBefore ? this.props.btnToolbarContentBefore : ''}
          {returnUrl && (
            <button
              type="button"
              id="spreedly-ccpay-button"
              data-payurl={`${
                business.NativeHomeUrlWithLanguage
              }/payments/SpreedlyPaymentToken/0?okRedirectUrl=${encodeURIComponent(
                returnUrl
              )}}&errorRedirectUrl=${encodeURIComponent(
                window.location.pathname
              )}`}
              disabled="disabled"
              className={`btn ${customer.CardNumber ? 'btn-gray-light' : ''}`}
            >
              {t('Update payment details')}
            </button>
          )}
          {!returnUrl && (
            <button
              type="button"
              id="spreedly-ccpay-button"
              data-payurl={`${business.NativeHomeUrlWithLanguage}/payments/SpreedlyPaymentToken/0`}
              disabled="disabled"
              className={`btn ${customer.CardNumber ? 'btn-gray-light' : ''}`}
            >
              {t('Update payment details')}
            </button>
          )}
          {customer.CardNumber && this.props.btnToolbarContentAfter ? this.props.btnToolbarContentAfter : ''}
        </div>
        <div>
          {business.TransactionFee > 0 && (
            <small>
              {t('Card payments are subject to a {{TransactionFee}}% fee.', {
                TransactionFee: business.TransactionFee,
              })}
            </small>
          )}
        </div>

        {/* <!-- END PAYMENT INFORMATION --> */}
      </>
    );
  }
}

export default SpreedlyPaymentForm;
