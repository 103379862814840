import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const PlanFrequencyLabel = withCustomComponent('PlanFrequencyLabel')(
  inject('appStore')(
    withRouter(
      observer(({ service }) => {
        const { t } = useTranslation();

        return (
          <>
            {service.InvoiceEvery > 0 &&
              t('every {{count}} month', {
                count: service.InvoiceEvery,
              })}

            {service.InvoiceEveryWeeks > 0 &&
              t('every {{count}} week', {
                count: service.InvoiceEveryWeeks,
              })}
          </>
        );
      })
    )
  )
);
