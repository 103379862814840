import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const NoAccessNotice = withCustomComponent('NoAccessNotice')(
  inject('appStore')(
    withRouter(
      observer(({ error, componentName, style }) => {
        const { t } = useTranslation();

        return (
          <>
            {error && (
              <div
                data-component-name={componentName}
                style={style}
                className="alert alert-info my-20"
                role="alert"
              >
                {error}
              </div>
            )}
          </>
        );
      })
    )
  )
);
