import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';

export const EventPageTickets = withCustomComponent('EventPageTickets')(
  inject(
    'appStore',
    'eventsStore'
  )(
    withRouter(
      observer(({ router, appStore, eventsStore }) => {
        const { t } = useTranslation();
        const event = eventsStore.eventPage.Event;

        return (
          event.TicketsPage && (
            <div className="card event-page-tickets p-16 p-md-24 h-auto mb-24">
              {!event.HasTickets && (
                <Link
                  href={event.TicketsPage}
                >
                  <a
                    className="btn btn-lg w-100"
                  >
                    {t('Get tickets')}
                  </a>
                </Link>
              )}
              {event.HasTickets && (
                <Link
                  as={routes.tickets_by_id(event)}
                  href={routes.tickets_by_id('[event_id]')}
                >
                  <a
                    className="btn btn-lg w-100"
                  >
                    {t('Get tickets')}
                  </a>
                </Link>
              )}
            </div>
          )
        );
      })
    )
  )
);
