import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

import CardDirectoryMemberTeam from 'ui/components/cards/CardDirectoryMemberTeam';

export const MembersList = withCustomComponent('MembersList')(
  inject(
    'appStore',
    'communityStore'
  )(
    withRouter(
      observer(({ componentName, communityStore }) => {
        const { membersDirectory } = communityStore;
        const members = membersDirectory.AllMembersByName || [];
        return (
          <ul data-component-name={componentName} className="list-grid list-grid--25 list-grid--lg-33 list-grid--md-50 list-grid--sm-100">
            {members.map((m, i) => (
              <li key={i}>
                <CardDirectoryMemberTeam member={m} />
              </li>
            ))}
          </ul>
        );
      })
    )
  )
);
