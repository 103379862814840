import numeral from 'numeral';

export function format(price, options) {
  price = normalizeNumber(price);

  let {currencyCode} = options ?? {};

  if (currencyCode !== false && !currencyCode) {
    currencyCode = `${currencyCode ?? 'CHF'} `;
  } else {
    currencyCode = '';
  }

  numeral.locale(null);

  let formattedPrice = numeral(price).format(`0,0.00`);
  formattedPrice = formattedPrice.replaceAll(' ', '’');//.replaceAll(',', '.');
  formattedPrice = `${currencyCode}${formattedPrice}`;

  return formattedPrice;
}

export function excludeTax(price, taxRate) {
  price = normalizeNumber(price);
  taxRate = normalizeNumber(taxRate);

  return price * 100 / (100 + taxRate);
}

export function retrieveTax(price, taxRate) {
  price = normalizeNumber(price);
  taxRate = normalizeNumber(taxRate);

  return price - excludeTax(price, taxRate);
}

export function normalizeNumber(val) {
  if (typeof val === 'string') {
    val = val.replaceAll(',', '.');
  }

  return _.toNumber(val);
}