import React, {useEffect} from 'react';
import Link from 'next/link';
import {routes} from 'env/routes';
import {useTranslation} from 'react-i18next';

import {inject, observer} from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import {withRouter} from 'next/router';
import FunnelTitle from '../funnel/Title';
import {toJS} from 'mobx';
import * as prices from '../../../env/utils/prices';

let CheckoutComplete = props => {
  const {
    router,
    authStore,
    appStore,
    invoicingStore,
    contentStore,
    componentName,
  } = props;
  const {t} = useTranslation();
  const {invoice_id} = router.query;
  const {customer} = authStore;
  const {configuration} = appStore;

  const invoice = invoicingStore.invoices.find(item => item.Id === parseInt(invoice_id));
  const article = contentStore.faqArticles.find(item => item.GroupName === 'Zahlungsanleitung');

  if (!article) {
    return <b>{t(`Can't find an article.`)}</b>;
  }

  return (
    <div data-component-name={componentName} className="mt-32">
      {/*<div className="thyp-wrapper">
              <div className="thyp-icon">
                <i className="icon-confetti-c"></i>
              </div>

              <h4 className="my-12 fw-400">{t('Thank you!')}</h4>

              {!customer?.Active && (
                <p className="fs-14 text-gray-700 mxw-400px mx-auto">
                  {t('All the details are sent to your email.')}
                </p>
              )}
              {configuration['PublicWebSite.MyAccount'] && (
                <div className="d-inline-flex justify-content-center mt-12">
                  <Link href={routes.home}>
                    <a className="btn">
                      {t('Back to the dashboard')}
                    </a>
                  </Link>
                </div>
              )}
            </div>*/}
      <div className="funnel-steps-wrapper">
        <FunnelTitle
          title={t('Confirmation')}
        />
        <div className="funnel-steps-body">
          {invoice && <>
            {invoice.Lines.map((line, i) => <div key={i} className="mb-4">
              <div className="mb-4">
                <span dangerouslySetInnerHTML={{__html: line.Description}}></span>
                <span>: </span>
                <span className="text-secondary"
                >{prices.format(line.SubTotal + line.TaxAmount)}</span>
              </div>
            </div>)}
            <hr className="my-16 my-lg-40"/>
          </>}
          <div className="fs-14">
            <h2 className="fs-18">
              <strong>
                {t('Payment instructions')}
              </strong>
            </h2>
            <div className="article-content" dangerouslySetInnerHTML={{__html: article.FullText}}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutComplete = withCustomComponent('CheckoutComplete')(
  inject('authStore', 'appStore', 'invoicingStore', 'contentStore')(
    withRouter(
      observer(CheckoutComplete),
    ),
  ),
);
