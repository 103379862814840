import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import CardDashboardSmall from 'ui/components/cards/CardDashboardSmall';
import withCustomComponent from 'ui/components/withCustomComponent';
import { routes } from 'env/routes';

@withCustomComponent('DeliveriesWidgetSmall')
@withTranslation()
@inject('appStore', 'deliveriesStore')
@observer
class DeliveriesWidgetSmall extends Component {
  componentDidMount() {
    const { t, appStore, deliveriesStore } = this.props;
    deliveriesStore.loadMyDeliveries().catch(() => null);
  }

  render() {
    const { t, appStore, deliveriesStore } = this.props;

		const { myDeliveries, hasLoadedMyDeliveries } = deliveriesStore;
		const deliveries = hasLoadedMyDeliveries && myDeliveries.UncollectedDeliveries;

		if (!(deliveries?.length > 0)) return null;

    const svg = (primaryColor) => (
      <svg
        width="96px"
        height="81px"
        version="1.1"
        id="InvoicesWidgetSvg"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 95.2 80.6"
        enableBackground="new 0 0 95.2 80.6"
      >
        <g>
          <circle
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#F5F5F5"
            cx="55.4"
            cy="43"
            r="37.2"
          />
          <g>
            <circle
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              cx="78.8"
              cy="16.4"
              r="10.8"
            />
            <path
              fill={primaryColor}
              d="M78.8,28.2c-6.5,0-11.8-5.3-11.8-11.8S72.3,4.6,78.8,4.6s11.8,5.3,11.8,11.8S85.4,28.2,78.8,28.2z
			 M78.8,6.6c-5.4,0-9.8,4.4-9.8,9.8c0,5.4,4.4,9.8,9.8,9.8c5.4,0,9.8-4.4,9.8-9.8C88.6,11,84.2,6.6,78.8,6.6z"
            />
          </g>
          <path
            fill={primaryColor}
            d="M66.5,26.6c-0.3,0-0.6-0.1-0.8-0.4c-4.4-5.8-4.4-13.9,0-19.7c0.3-0.4,1-0.5,1.4-0.2s0.5,1,0.2,1.4
		c-3.8,5.1-3.8,12.2,0,17.3c0.3,0.4,0.2,1.1-0.2,1.4C67,26.6,66.8,26.6,66.5,26.6z"
          />
          <path
            fill={primaryColor}
            d="M91.2,26.6c-0.2,0-0.4-0.1-0.6-0.2c-0.4-0.3-0.5-1-0.2-1.4c3.8-5.1,3.8-12.2,0-17.3
		c-0.3-0.4-0.2-1.1,0.2-1.4C91,6,91.6,6.1,92,6.5c4.4,5.8,4.4,13.9,0,19.7C91.8,26.5,91.5,26.6,91.2,26.6z"
          />
          <path
            fill={primaryColor}
            d="M78.9,32.8c-3.5,0-7-1.1-9.9-3.3c-0.4-0.3-0.5-1-0.2-1.4c0.3-0.4,1-0.5,1.4-0.2c5.1,3.8,12.2,3.8,17.3,0
		c0.4-0.3,1.1-0.2,1.4,0.2c0.3,0.4,0.2,1.1-0.2,1.4C85.8,31.7,82.3,32.8,78.9,32.8z"
          />
          <path
            fill={primaryColor}
            d="M69.6,5.1c-0.3,0-0.6-0.1-0.8-0.4c-0.3-0.4-0.2-1.1,0.2-1.4c5.8-4.4,13.9-4.4,19.7,0c0.4,0.3,0.5,1,0.2,1.4
		c-0.3,0.4-1,0.5-1.4,0.2C82.4,1,75.3,1,70.2,4.9C70,5,69.8,5.1,69.6,5.1z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M75.8,13.3l6.2,6.2L75.8,13.3z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M81.9,13.3l-6.2,6.2L81.9,13.3z"
          />
          <path
            fill={primaryColor}
            d="M80.3,16.4l2.4-2.4c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L78.9,15l-2.4-2.4c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4
		l2.4,2.4l-2.4,2.4c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l2.4-2.4l2.4,2.4c0.2,0.2,0.5,0.3,0.7,0.3
		s0.5-0.1,0.7-0.3c0.4-0.4,0.4-1,0-1.4L80.3,16.4z"
          />
          <path
            fill={primaryColor}
            d="M75.7,80.6c-2.3,0-4.5-0.9-6.1-2.5c-3.4-3.4-3.4-8.9,0-12.2l9.8-9.8c2.4-2.4,6.4-2.4,8.8,0
		c2.4,2.4,2.4,6.4,0,8.8l-9.8,9.8c-1.5,1.5-4,1.5-5.5,0c-1.5-1.5-1.5-4,0-5.5l7.4-7.4c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-7.4,7.4
		c-0.7,0.7-0.7,1.9,0,2.6c0.7,0.7,1.9,0.7,2.6,0l9.8-9.8c1.7-1.7,1.7-4.4,0-6c-1.7-1.7-4.4-1.7-6,0L71,67.3c-2.6,2.6-2.6,6.8,0,9.4
		c1.3,1.3,2.9,1.9,4.7,1.9c1.8,0,3.4-0.7,4.7-1.9l5.9-5.9c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-5.9,5.9C80.2,79.7,78.1,80.6,75.7,80.6z
		"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M7.4,57.6V31.1c0-3.3,2.6-5.9,5.9-5.9h29.5
		c3.3,0,5.9,2.6,5.9,5.9v26.6L48.5,68h-41"
          />
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M7.4,67.2V32.8c0-4.2,2.6-7.6,5.9-7.6h29.5
			c3.3,0,5.9,3.4,5.9,7.6v34.4"
            />
            <path
              fill={primaryColor}
              d="M42.9,23.7H13.3c-4.1,0-7.4,4.1-7.4,9.1v34.4c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5V32.8
			c0-3.3,2-6.1,4.4-6.1h29.5c2.4,0,4.4,2.8,4.4,6.1v34.4c0,0.8,0.7,1.5,1.5,1.5c0.8,0,1.5-0.7,1.5-1.5V32.8
			C50.3,27.8,46.9,23.7,42.9,23.7z"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M31,35.5l-5.7,5.7L31,35.5z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M25.2,35.5l5.7,5.7L25.2,35.5z"
          />
          <path
            fill={primaryColor}
            d="M30.2,38.4l1.8-1.8c0.6-0.6,0.6-1.5,0-2.1c-0.6-0.6-1.5-0.6-2.1,0l-1.8,1.8l-1.8-1.8
		c-0.6-0.6-1.5-0.6-2.1,0c-0.6,0.6-0.6,1.5,0,2.1l1.8,1.8l-1.8,1.8c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4
		l1.8-1.8l1.8,1.8c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L30.2,38.4z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M19.2,50.3h17.7H19.2z"
          />
          <path
            fill={primaryColor}
            d="M36.9,51.8H19.2c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h17.7c0.8,0,1.5,0.7,1.5,1.5S37.8,51.8,36.9,51.8z"
          />
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M1.5,72.4V62.1c0-2.4,2-4.4,4.4-4.4h5.5
			c1.2,0,2.3,0.5,3.1,1.3l2.9,2.9c1.1,1.1,2.6,1.7,4.2,1.7h12.8c1.6,0,3.1-0.6,4.2-1.7l2.4-2.4c1.1-1.1,2.6-1.7,4.2-1.7h4.9
			c2.4,0,4.4,2,4.4,4.4v10.3c0,3.3-2.6,5.9-5.9,5.9H7.4C4.1,78.3,1.5,75.7,1.5,72.4z"
            />
            <path
              fill={primaryColor}
              d="M48.8,79.8H7.4c-4.1,0-7.4-3.3-7.4-7.4V62.1c0-3.3,2.7-5.9,5.9-5.9h5.5c1.6,0,3.1,0.6,4.2,1.7l2.9,2.9
			c0.8,0.8,2,1.3,3.1,1.3h12.8c1.2,0,2.3-0.5,3.1-1.3l2.4-2.4c1.4-1.4,3.3-2.2,5.2-2.2h4.9c3.3,0,5.9,2.7,5.9,5.9v10.3
			C56.2,76.5,52.8,79.8,48.8,79.8z M5.9,59.1c-1.6,0-2.9,1.3-2.9,2.9v10.3c0,2.4,2,4.4,4.4,4.4h41.4c2.4,0,4.4-2,4.4-4.4V62.1
			c0-1.6-1.3-2.9-2.9-2.9h-4.9c-1.2,0-2.3,0.5-3.1,1.3l-2.4,2.4c-1.4,1.4-3.3,2.2-5.2,2.2H21.7c-2,0-3.8-0.8-5.2-2.2L13.6,60
			c-0.5-0.5-1.3-0.9-2.1-0.9H5.9z"
            />
          </g>
        </g>
      </svg>
    );

    return (
      <div className="col-md-6 col-xl-3 mt-12">
        <CardDashboardSmall
          title={t('Deliveries')}
          url={routes.deliveries}
          mainValue={deliveries != undefined ? deliveries.length : 0}
          zeroMessage={t('You have no deliveries.')}
          svg={svg('var(--bg-color)')}
        />
      </div>
    );
  }
}
export default DeliveriesWidgetSmall;
