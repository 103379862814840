import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
@withCustomComponent('DashboardBox')
@withTranslation()
class DashboardBox extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    actionUrl: PropTypes.string,
    actionTitle: PropTypes.string,
  };

  render() {
    const { t, title, url, actionUrl, viewMoreTitle, actionTitle } = this.props;
    return (
      <div
        data-component-name={this.props.componentName}
        className="box-shaded dashboard-box dashboard-box--same-height"
      >
        <div className="dashboard-box__inner">
          <div className="dashboard-box__head">
            <h3 className="dashboard-box__head__title">{title}</h3>
            {url && (
              <Link href={url}>
                <a href="#" className="dashboard-box__head__view-more">
                  {viewMoreTitle || t('View all')}
                </a>
              </Link>
            )}
          </div>
          {this.props.children}
        </div>
        {actionUrl && (
          <div className="dashboard-box__foot">
            <Link href={actionUrl}>
              <a href="#" className="btn btn--primary">
                {actionTitle}
              </a>
            </Link>
          </div>
        )}
      </div>
    );
  }
}

export default DashboardBox;
