import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Link from 'next/link';
import _ from 'lodash';
import { routes } from 'env/routes';
import { withTranslation } from 'react-i18next';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import withCustomComponent from 'ui/components/withCustomComponent';

import CardCommunityBoard from 'ui/components/cards/CardCommunityBoard';
import LoadingWidget from 'ui/_pages/home/components/LoadingWidget/LoadingWidget';

@withCustomComponent('CommunityBoardWidget')
@withTranslation()
@inject('appStore', 'communityStore')
@observer
class CommunityBoardWidget extends Component {
  componentDidMount() {
    const { communityStore } = this.props;
    communityStore.loadThreads({ page: 1, size: 2 }).catch((ex) => null);
  }

  render() {
    const { t, appStore, communityStore } = this.props;
    const { threads, hasLoadedThreads } = communityStore;

    if (!hasLoadedThreads)
      return <LoadingWidget title={t('Recent discussions')} />;

    if (!(threads?.length > 0)) return <></>;

    return (
      <div className="col mxw-xl-50 mt-40">
        <div
          data-component-name={this.props.componentName}
          className="dashboard-community-board-widget"
        >
          <h6>{t('Recent discussions')}</h6>
          {threads.map((thread, i) => (
            <CardCommunityBoard thread={thread} sizeSmall={true} key={i} />
          ))}
          <div className="mt-16">
            <Link href={routes.forum}>
              <a className="text-gray-900">{t('See all boards')}</a>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default CommunityBoardWidget;
