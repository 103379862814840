import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { withTranslation } from 'react-i18next';

@withTranslation()
class ActionButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clicked: false,
    };
  }

  handleAction = () => {
    this.setState({ clicked: true });
    this.props.action();
    return false;
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.busy && !this.props.busy) this.setState({ clicked: false });
  }

  render() {
    const { t, busy, label, busyLabel, className } = this.props;
    return (
      <button
        disabled={busy}
        className={`${className || 'btn'}`}
        onClick={this.handleAction}
        type="button"
      >
        {this.state.clicked && busy ? (
          <span>{busyLabel}</span>
        ) : (
          <span>{label ?? t('Save changes')}</span>
        )}
      </button>
    );
  }
}

ActionButton.defaultProps = {
  busyLabel: <LoadingSpinner />,
};

ActionButton.propType = {
  action: PropTypes.func.isRequired,
  label: PropTypes.string,
  busyLabel: PropTypes.string,
};

export default ActionButton;
