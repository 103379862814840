import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { removeHtml } from 'env/utils/strings';

export const BlogPostDetails = withCustomComponent('BlogPostDetails')(
  inject(
    'appStore',
    'blogStore'
  )(
    withRouter(
      observer(({ post, componentName, appStore, blogStore }) => {
        const { t } = useTranslation();
        const { business } = appStore;

        return (
          <div
            data-component-name={componentName}
            className="blog-post-details"
          >
            {post.HasLargeImage && (
              <div
                className="blog-post-details__image"
                style={{
                  backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/blog/GetLargeImage?id=${post.Id}&w=1500&cache=${post.UpdatedOn})`,
                }}
              ></div>
            )}
            <div
              className="blog-post-details__content fr-view"
              dangerouslySetInnerHTML={{
                __html: post.FullText,
              }}
            ></div>
          </div>
        );
      })
    )
  )
);
