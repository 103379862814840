import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import AuthenticatedLink from 'ui/components/AuthenticatedLink';

@withCustomComponent('BookingZoomForm')
@withTranslation()
@inject('appStore', 'authStore', 'bookingsStore')
@observer
class BookingZoomForm extends Component {
  updateBooking = this.props.updateBooking;

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false
    };
  }

  render() {
    const { t, appStore, authStore, bookingsStore } = this.props;
    const { configuration } = appStore;
    const { customer } = authStore;
    const { booking } = bookingsStore;

    if (!booking.ResourceId || !authStore.isLoggedIn) return <></>;

    return (
      <>
        {configuration['Zoom.Enabled'] &&
        this.state.isVisible && (
          <>
            {customer?.HasZoom && (
              <div className="mt-24">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="zoom"
                    checked={booking.IncludeZoomInvite ?? false}
                    onKeyDown={(e) => {
                      if(e.keyCode === 32) { // space key
                        this.updateBooking('IncludeZoomInvite', !booking.IncludeZoomInvite);
                      }
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="zoom"
                    id="js--checkbox-label"
                    onClick={() => {
                      this.updateBooking('IncludeZoomInvite', !booking.IncludeZoomInvite);
                    }}
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'Include a <b>Zoom Meeting</b> link for this booking.'
                      ),
                    }}
                  ></label>
                </div>
              </div>
            )}
          </>
        )}

        {configuration['Zoom.Enabled'] &&
        !this.state.isVisible &&
        !customer?.HasZoom && (
          <AuthenticatedLink
            href={`${appStore.business.NativeHomeUrlWithLanguage}/integrations/zoom/signin`}
            className="btn btn-gray btn-icon -sm mr-8 my-4"
          >
            <i className="icon-zoom"></i>
            {t('Connect my Zoom')}
          </AuthenticatedLink>
        )}

        {configuration['Zoom.Enabled'] &&
        !this.state.isVisible &&
        customer?.HasZoom && (
          <a
            href="#"
            className="btn btn-gray btn-icon -sm mr-8 my-4"
            onClick={() => this.setState({ isVisible: true })}
          >
            <i className="icon-zoom"></i>
            {t('Zoom Link')}
          </a>
        )}
      </>
    );
  }
}
export default BookingZoomForm;
