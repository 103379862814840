import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import LoadingLayout from 'ui/layouts/LoadingLayout';

export const ProposalDocument = withCustomComponent('ProposalDocument')(
  inject(
    'appStore',
    'invoicingStore'
  )(
    withRouter(
      observer(({ componentName, appStore, invoicingStore }) => {
        const { t } = useTranslation();
        const { business } = appStore;
        const { proposalView, isLoadingProposalView } = invoicingStore;
        const proposal = proposalView?.Proposal;
        if (!proposal || isLoadingProposalView) return <LoadingLayout />;
        return (
          <div data-component-name={componentName}>
            <iframe
              width="100%"
              height="600px"
              style={{ border: 0, padding: 0, margin: 0 }}
              src={`${business.NativeHomeUrlWithLanguage}/proposal/pdf?guid=${proposal.UniqueId}`}
            >
              {t('Loading...')}
            </iframe>
          </div>
        );
      })
    )
  )
);
