import React, { useEffect } from 'react';
import Link from 'next/link';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import usePrevious from 'env/usePrevious';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import withCustomComponent from 'ui/components/withCustomComponent';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { withRouter } from 'next/router';
import { routes } from 'env/routes';
import { initTimeAgo } from 'env/utils/dateUtil';
import { CommunityBoardReply } from 'ui/_pages/community/thread/CommunityBoardReply';
import { Pagination } from 'ui/components/listings/Pagination';
import TurndownService from 'turndown';
import { CommunityBoardItem } from 'ui/_pages/community/list/CommunityBoardItem';

const CommunityBoardStartPage = withCustomComponent('CommunityBoardStartPage')(
  inject('appStore', 'authStore', 'communityStore')(
    withRouter(
      observer(({appStore, communityStore, router, componentName}) => {
        const { t } = useTranslation();
        const prevQuery = usePrevious(router.query);

        const { business } = appStore;
        const { threadPage } = communityStore;
        const thread = threadPage && threadPage.Thread;
        const hasLoadedData =
          thread && thread.Id == parseInt(router.query.thread_id);
    
        const turndownService = new TurndownService();

        useEffect(() => {
          const { thread_id } = router.query;
          if (!thread_id) {
            router.push(routes.forum);
            return;
          }

          loadThread();
        }, []);

        useEffect(() => {
          const currentQuery = router.query;
          if (JSON.stringify(currentQuery) != JSON.stringify(prevQuery))
            loadThread();
        }), [router.query];

        const loadThread = () => {
          const { thread_id, page } = router.query;
      
          // Read any notifications about this record
          appStore.dismissNotification(thread_id).catch(() => null);
      
          communityStore
            .loadThreadPage({ thread_id, page })
            .then((data) => {
              //If the thread requires to be logged in or doesn't exist anymore
              if (data.Status == 302) {
                if (data.RedirectTo) {
                  router.push(data.RedirectTo.split('Index')[0]);
                  return;
                }
      
                router.push(
                  `${routes.login}?returnurl=${encodeURIComponent(router.asPath)}`
                );
              }
            })
            .then(() => {
              if (page > 1) window.location = '#comments-list';
            })
            .then(initTimeAgo)
            .catch((err) => {
              router.push(routes.forum);
              appStore.setPopMessage(
                t('Sorry, we could not load this conversation. {{err}}', { err })
              );
            });
        }

        return (
          <DefaultLayout
            componentName={componentName}
            title={t('{{Subject}} - {{Name}}', {
              Subject: thread ? thread.Subject : '...',
              Name: t(business.Name),
            })}
          >
            <main className="content content--custom" role="main">
              <div className="container">
                {!hasLoadedData && <LoadingSpinner />}
                {hasLoadedData && (
                  <div className="row">
                    <div className="d-none d-lg-block col-lg-1 col-xl-2">
                      <Link
                        href={
                          (thread.Private
                            ? routes.private_threads
                            : thread.GroupId > 0
                            ? routes.forum_by_group(thread.GroupId)
                            : routes.forum) +
                          `?top=${router.query._top}&page=${router.query._page}#thread-${thread.Id}`
                        }
                      >
                        <a className="btn btn-single-icon btn-white">
                          <i className="icon-go-back"></i>
                        </a>
                      </Link>
                    </div>
                    <div className="col-12 col-lg-10 col-xl-8">
                      <CommunityBoardItem
                        turndownService={turndownService}
                        thread={thread}
                        guests={threadPage.Guests}
                        clipIfNeeded={false}
                        threadDetails={true}
                      >
                        <ul className="list-unstyled mb-0 mt-12">
                          {threadPage.Messages.map((message, i) => (
                            <li className="mb-8" key={i}>
                              <CommunityBoardReply
                                turndownService={turndownService}
                                message={message}
                              />
                            </li>
                          ))}
                        </ul>
                        <Pagination
                          page={threadPage.Page}
                          records={threadPage.Messages}
                          top={threadPage.Top}
                        />
                      </CommunityBoardItem>
                    </div>
                  </div>
                )}
              </div>
            </main>
          </DefaultLayout>
        );
      })
    )
  )
)

export default CommunityBoardStartPage;
