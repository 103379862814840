import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import moment from 'moment';
import { withRouter } from 'next/router';

@withRouter
@withCustomComponent('LegacyBlogCatchAllPage')
@withTranslation()
@observer
class LegacyBlogCatchAllPage extends Component {}

LegacyBlogCatchAllPage.getInitialProps = ({ asPath, query, res }) => {
  if (res) {
    res.writeHead(302, {
      Location: asPath.replace(
        `/blog/read/${query.blog_id}/${query.slug}`,
        `/blog/${query.blog_id}/${query.slug}`
      ),
    });
    res.end();
  }

  return {};
};

export default LegacyBlogCatchAllPage;
