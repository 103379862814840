import React, {useEffect, useState} from 'react';
import Link from 'next/link';
import {routes} from 'env/routes';
import {useTranslation} from 'react-i18next';
import {withRouter} from 'next/router';
import {inject, observer} from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import moment from 'moment';
import ReactPaginate from 'react-paginate';

let MeetingsWidget = props => {
  const {
    componentName,
    appStore,
    authStore,
    bookingsStore,
    itemsPerPage = 5,
  } = props;
  const {t} = useTranslation();
  const {configuration, corporateDashboard} = appStore;
  const {upcomingBookings} = bookingsStore;
  const {customer} = authStore;
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(null);
  const [itemOffset, setItemOffset] = useState(null);

  useEffect(() => {
    if (!bookingsStore.hasLoadedUpcomingBookings) {
      bookingsStore.loadUpcomingBookings().then(data => {
        setItemOffset(0);
      }).catch(err => console.error(`Can't load UpcomingBookings.`, err));
    }
  }, []);

  // Pagination handlers
  useEffect(() => {
    setCurrentItems(upcomingBookings.slice(itemOffset, itemOffset + itemsPerPage));
    setPageCount(Math.ceil(upcomingBookings.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  const handlePageClick = e => {
    setItemOffset(e.selected * itemsPerPage);
  };

  return (
    <div className="card-item-wrapper">
      <div className="card-item-header">
        <h2 className="card-item-header-title-dashboard">
          {t('Your meetings')}
        </h2>
        <div className="btn-group justify-content-end">
          <Link href={routes.resources}>
            <a className="btn">
              {t('Book meeting')}
            </a>
          </Link>
        </div>
      </div>
      {upcomingBookings.length > 0 &&
        <div className="table-wrap table-wrap-tablet table-responsive">
          <table className="table table-custom mb-0">
            <thead>
            <tr>
              <th>
                {t('Meeting')}
              </th>
              <th>
                {t('Date')}
              </th>
              <th>
                {t('Progress')}
              </th>
              <th>
                {t('Status')}
              </th>
              <th>
                {t('Actions')}
              </th>
            </tr>
            </thead>
            <tbody>
            {upcomingBookings.length > 0 && currentItems.map((booking, i) => {
              let fromTime = moment(booking.FromTime);
              let toTime = moment(booking.ToTime);
              let progress = 0;

              if (moment().unix() >= fromTime.unix()) {
                progress = (moment().unix() - fromTime.unix()) / (toTime.unix() - fromTime.unix()) * 100;
                progress = Math.min(Math.round(progress), 100);
              }

              let rowClass = booking.Tentative ? 'table-item-row-cancelled' : '';

              return (<tr key={i}>
                <td data-title="Meeting" className="table-item-name">
                <span>
                  {booking.ResourceName}
                </span>
                </td>
                <td data-title="Date" className="table-item-dates">
                  <div>
                    {fromTime.format('HH:mm MMM. DD')} - {toTime.format('HH:mm MMM. DD')}
                  </div>
                </td>
                <td data-title="Progress" className={`table-item-progress ${rowClass}`}>
                  <div className="d-flex align-items-center">
                    <div className="progress mr-5">
                      <div className="progress-bar" role="progressbar" style={{width: `${progress}%`}}
                           aria-valuenow={progress}
                           aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <span>
                  {progress}%
                </span>
                  </div>
                </td>
                <td data-title="Status" className="table-item-status-wrapper">
              <span className={`table-item-status ${booking.Tentative ? 'table-item-status-cancelled' : ''}`}>
                {booking.Tentative ? 'Pending' : 'Confirmed'}
              </span>
                </td>
                <td data-title="Actions" className="table-item-actions fs-16">
                  {customer.Id === booking.CoworkerId ? (
                    <Link
                      href={routes.resource_by_id('[booking_id]')}
                      as={routes.resource_by_id(booking.Id)}
                    >
                      <a className="dropdown-item">
                        <i className="icon-pen mr-20"></i>
                        <i className="icon-chevron-right-new"></i>
                      </a>
                    </Link>
                  ) : (
                    <Link href={routes.calendar_by_resource_id(booking.Resource.Id)}>
                      <a className="dropdown-item">{t('View in calendar')}</a>
                    </Link>
                  )}
                </td>
              </tr>);
            })}
            </tbody>
          </table>
        </div>
      }
      {upcomingBookings.length === 0 && (
        <div className="card-item-body">
          <div className="alert" role="alert">
            <span className="icon-alert-2"></span>
            {t(`You don't have incoming meetings`)}
          </div>
        </div>
      )}
      <nav>
        <ReactPaginate
          breakLabel="..."
          nextLabel={(
            <span aria-hidden="true">
                <i className="icon-chevron-right"></i>
              </span>
          )}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={(
            <span aria-hidden="true">
                <i className="icon-chevron-right"></i>
              </span>
          )}
          renderOnZeroPageCount={null}
          className="pagination justify-content-center"
          previousLinkClassName="btn prev"
          nextLinkClassName="btn next"
          pageLinkClassName="btn"
          activeClassName="active"
          breakLinkClassName="dots"
        />
      </nav>
    </div>
  );
};

export default MeetingsWidget = withCustomComponent('MeetingsWidget')(
  inject('appStore', 'authStore', 'bookingsStore')(
    withRouter(
      observer(MeetingsWidget),
    ),
  ),
);
