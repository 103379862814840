import React from 'react';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const CoursesPageZeroCase = withCustomComponent('CoursesPageZeroCase')(
  inject(
    'appStore',
    'coursesStore'
  )(
    withRouter(
      observer(({ componentName, router, courses, message }) => {
        return (
          courses.length == 0 && (
            <div
              data-component-name={componentName}
              className="text-center pt-32"
            >
              <i className="icon-date-availability fs-64 text-gray-600"></i>
              <h3 className="mt-12 mb-24">{router.query.query ? t('No courses found for') + ` "${router.query.query}"` : message}</h3>
            </div>
          )
        );
      })
    )
  )
);
