import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { CoworkerAvatar } from 'ui/components/CoworkerAvatar';
import { EventsPageCommentsFormField } from './EventsPageCommentsFormField';

export const EventsPageCommentsForm = withCustomComponent('EventsPageCommentsForm')(
  inject(
    'appStore',
    'authStore',
    'eventsStore'
  )(
    withRouter(
      observer(
        ({
          authStore,
          router,
          appStore,
          eventsStore,
          componentName
        }) => {
          const { t } = useTranslation();
          const { newComment } = eventsStore;
          const event = eventsStore.eventPage.Event;
          const { isLoggedIn, customer } = authStore;

          return (
            <>
              {event.AllowComments && (
                <div
                  data-component-name={componentName}
                  className="comments-form"
                >
                  {!isLoggedIn && (
                    <Link
                      href={{
                        pathname: routes.login,
                        query: {
                          returnurl: encodeURIComponent(
                            routes.event_by_id(event)
                          ),
                        },
                      }}
                    >
                      <a className="btn btn-xs btn-outline mt-24">
                        {t('Sign in or join to post new comments')}
                      </a>
                    </Link>
                  )}
                  {isLoggedIn && (
                    <div className="d-flex">
                      <div className="pr-8 mt-24">
                        <CoworkerAvatar
                          size={80}
                          link
                          imgClassName={'avatar avatar--40'}
                          coworker_id={customer.Id}
                        />
                      </div>
                      <EventsPageCommentsFormField newComment={newComment}/>
                    </div>
                  )}
                </div>
              )}
            </>
          );
        }
      )
    )
  )
);
