import { required, pattern } from 'validx';
import _ from 'lodash';
import { toJS } from 'mobx';
import { getStores } from '../stores';
import global from 'global';

const strongRegex =
  /(?=.*[a-z])(?=.*[A-Z])(?=.*[ !"#$%&'\(\)\*\+\,-\.\/\:;<=>?@[\]^_`{|}~])(?=.{6,})/;
const phoneRegex = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]{6,}$/;
//3 = hidden
//2 = required
//1 = optional

const getRules = (customer, fieldName) => {
  const t = global.t;
  const cfg = getStores().appStore.configuration;
  const isNewCustomer = customer && !customer.Id > 0;

  const originalName = fieldName;
  if (customer.TourDate) return [];
  if (fieldName == 'ProfileWebsite') fieldName = 'ProfileWebiste'; //account for typo in the settings
  if (fieldName == 'ProfileTags') fieldName = 'PorfileTags'; //account for typo in the settings

  // Keep these here as they are needed to output the translation tokens
  const dumm1 = t('LandLine');
  const dumm2 = t('MobilePhone');
  const dumm3 = t('DateOfBirth');
  const dumm4 = t('CityName');
  const dumm5 = t('State');
  const dumm6 = t('PostCode');
  const dumm7 = t('BillingAddress');

  if (
    !isNewCustomer
      ? cfg[`Forms.${fieldName}`] === '2'
      : cfg[`Forms.${fieldName}`] === '2' ||
        cfg[`SignupForm.${fieldName}`] === '2'
  ) {
    const translatedFieldName = t(originalName);
    return [
      required({
        msg: t(`This field is required ({{fieldName}})`, {
          fieldName: translatedFieldName,
        }),
      }),
    ];
  }
  return [];
};

const getRulesForCustomFields = (customer, fieldIndex) => {
  const t = global.t;
  const authStore = getStores().authStore;
  const isNewCustomer = customer && !customer.Id > 0;
  const customFields = customer.TourDate
    ? authStore.tourCustomFields
    : isNewCustomer
    ? authStore.signupCustomFields
    : authStore.customFields;

  const foundField = _(customFields)
    .filter((x) => x.CustomFieldIndex === fieldIndex)
    .first();
  if (foundField && foundField.Required)
    return [required({ msg: t(`'${foundField.Name}' field is required`) })];

  return [];
};

const customerValidationSchema = (customer) => {
  const t = global.t;
  const isNewCustomer = customer && !customer.Id > 0;
  const cfg = getStores().appStore.configuration;
  const quickForm = customer.quickForm;
  const isEvent = customer.isEvent;
  const askBuyerAddress =
    customer.askBuyerAddress == undefined || customer.askBuyerAddress;

  return {
    // The schema maps to the fields we are validating.
    FullName: [required({ msg: t('Full name is required') })],
    CountryId: [required({ msg: t('Country is required') })],
    Salutation: [
      required({
        msg: t(
          "What should we call you?. For example, you can type 'John' or 'Mr. White'"
        ),
      }),
    ],
    Email: [
      required({ msg: t('Email is required') }),
      pattern({
        pattern: 'email',
        msg: t('This email address does not seem to be valid.'),
      }),
    ],
    NewPassword:
      !isNewCustomer && customer.NewPassword
        ? [
            pattern({
              pattern: strongRegex,
              msg: t(
                'Password must be at least 6 characters long and include lowercase (a-z), uppercase (A-Z) and symbols (!@#$%^&*)'
              ),
            }),
          ]
        : [],
    RepeatNewPassword:
      !isNewCustomer && customer.NewPassword
        ? [
            (opts) =>
              opts.value === customer.NewPassword || t('Passwords must match.'),
          ]
        : [],

    Password:
      cfg['Signup.AskForPassword'] && isNewCustomer && !customer.TourDate
        ? [
            required({ msg: t('Password is required') }),
            pattern({
              pattern: strongRegex,
              msg: t(
                'Password must be at least 6 characters long and include lowercase (a-z), uppercase (A-Z) and symbols (!@#$%^&*)'
              ),
            }),
          ]
        : [],
    PasswordConfirm:
      cfg['Signup.AskForPassword'] && isNewCustomer && !customer.TourDate
        ? [
            required({
              msg: t('Password confirmation is required'),
            }),
            (opts) =>
              opts.value === customer.Password || t('Passwords must match.'),
          ]
        : [],
    TourDate: [
      customer.isTour &&
        required({
          msg: 'Tour date is required',
        }),
    ],
    base64avatar:
      !isEvent &&
      isNewCustomer &&
      !customer.TourDate &&
      getRules(customer, 'Avatar'),
    LandLine:
      !isEvent && !customer.TourDate
        ? [
            ...getRules(customer, 'LandLine'),
            ...(customer.LandLine
              ? [
                  pattern({
                    pattern: phoneRegex,
                    msg: t('Please enter a valid phone number.'),
                  }),
                ]
              : []),
          ]
        : [],
    MobilePhone: [
      ...getRules(customer, 'MobilePhone'),
      ...(customer.MobilePhone
        ? [
            pattern({
              pattern: phoneRegex,
              msg: t('Please enter a valid mobile number.'),
            }),
          ]
        : []),
    ],
    DateOfBirth: !isEvent && getRules(customer, 'DateOfBirth'),
    Gender: !isEvent && getRules(customer, 'Gender'),
    Address: askBuyerAddress && getRules(customer, 'Address'),
    CityName: askBuyerAddress && getRules(customer, 'CityName'),
    State: askBuyerAddress && getRules(customer, 'State'),
    PostCode: askBuyerAddress && getRules(customer, 'PostCode'),
    BillingName: askBuyerAddress && getRules(customer, 'BillingName'),
    TaxIDNumber: askBuyerAddress && getRules(customer, 'TaxIDNumber'),
    ProfileWebsite: !quickForm && getRules(customer, 'ProfileWebiste'),
    BillingAddress: getRules(customer, 'BillingAddress'),
    BillingCityName: getRules(customer, 'BillingCityName'),
    BillingState: getRules(customer, 'BillingState'),
    BillingPostCode: getRules(customer, 'BillingPostCode'),
    BillingEmail: getRules(customer, 'BillingEmail'),
    CompanyName: !quickForm && getRules(customer, 'CompanyName'),
    BusinessArea: !quickForm && getRules(customer, 'BusinessArea'),
    Position: !quickForm && getRules(customer, 'Position'),
    ProfileSummary: !quickForm && getRules(customer, 'ProfileSummary'),
    ProfileTags: !quickForm && getRules(customer, 'ProfileTags'),
    Custom1: getRulesForCustomFields(customer, 1),
    Custom2: getRulesForCustomFields(customer, 2),
    Custom3: getRulesForCustomFields(customer, 3),
    Custom4: getRulesForCustomFields(customer, 4),
    Custom5: getRulesForCustomFields(customer, 5),
    Custom6: getRulesForCustomFields(customer, 6),
    Custom7: getRulesForCustomFields(customer, 7),
    Custom8: getRulesForCustomFields(customer, 8),
    Custom9: getRulesForCustomFields(customer, 9),
    Custom10: getRulesForCustomFields(customer, 10),
    Custom11: getRulesForCustomFields(customer, 11),
    Custom12: getRulesForCustomFields(customer, 12),
    Custom13: getRulesForCustomFields(customer, 13),
    Custom14: getRulesForCustomFields(customer, 14),
    Custom15: getRulesForCustomFields(customer, 15),
    Custom16: getRulesForCustomFields(customer, 16),
    Custom17: getRulesForCustomFields(customer, 17),
    Custom18: getRulesForCustomFields(customer, 18),
    Custom19: getRulesForCustomFields(customer, 19),
    Custom20: getRulesForCustomFields(customer, 20),
    Custom21: getRulesForCustomFields(customer, 21),
    Custom22: getRulesForCustomFields(customer, 22),
    Custom23: getRulesForCustomFields(customer, 23),
    Custom24: getRulesForCustomFields(customer, 24),
    Custom25: getRulesForCustomFields(customer, 25),
    Custom26: getRulesForCustomFields(customer, 26),
    Custom27: getRulesForCustomFields(customer, 27),
    Custom28: getRulesForCustomFields(customer, 28),
    Custom29: getRulesForCustomFields(customer, 29),
    Custom30: getRulesForCustomFields(customer, 30),
  };
};

export { customerValidationSchema };
