import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import _ from 'lodash';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import {HomePageDashboardHeader} from 'ui/_pages/home/components/DashboardHeader';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import FeaturedBlogPost from 'ui/_pages/home/components/FeaturedBlogPost';
import UpcomingEvents from 'ui/_pages/home/components/UpcomingEvents';
import LatestConversations from 'ui/_pages/home/components/LatestConversations';
import {privateRoute} from 'env/privateRoute';
import {withCustomComponent} from 'ui/components/withCustomComponent';
import {NoAccessNotice} from 'ui/components/NoAccessNotice';
import {FeaturedBlogBanner} from 'ui/_pages/home/components/FeaturedBlogBanner';
import FeaturedPerks from 'ui/_pages/home/components/FeaturedPerks';

@withCustomComponent('CommunityDashboard')
@privateRoute
@withTranslation()
@inject('appStore', 'blogStore')
@observer
class CommunityDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    const { t, blogStore } = this.props;

    blogStore.loadBlogPosts({ size: 4 }).catch((err) => {
      this.setState({
        error: t("You currently don't have access to the community dashboard."),
      });
    });
    blogStore.loadBannerBlogPosts().catch((err) => {
      this.setState({
        error: t("You currently don't have access to the community dashboard."),
      });
    });
  }

  render() {
    const { appStore, blogStore, t } = this.props;

    const { hasLoadedBannerBlogPosts, hasLoadedBlogPosts, bannerBlogPosts } =
      blogStore;
    const { business } = appStore;
    const hasLoadedData = hasLoadedBannerBlogPosts;

    const firstBannerBlogPosts = _.first(bannerBlogPosts);
    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={`${t('Discussion boards')} - ${t(appStore.business.Name)}`}
      >
        <main className="content content--custom" role="main">
          <HomePageDashboardHeader />

          {!hasLoadedData && (
            <div className="dashboard-body dashboard-body--community">
              <LoadingSpinner />
            </div>
          )}

          {hasLoadedData && (
            <div className="dashboard-body dashboard-body--community">
              <FeaturedBlogBanner />
              <div className="container">
                {firstBannerBlogPosts && (
                  <FeaturedBlogPost post={firstBannerBlogPosts} />
                )}

                <NoAccessNotice
                  style={{ marginTop: 20 }}
                  error={this.state.error}
                />

                <UpcomingEvents />
                <LatestConversations />
                <FeaturedPerks />
              </div>
            </div>
          )}
        </main>
      </DefaultLayout>
    );
  }
}

export default CommunityDashboard;
