import React, {Component} from 'react';
import {PropTypes} from 'prop-types';
import {observer, inject} from 'mobx-react';
import InputField from 'ui/components/forms/InputField';
import ActionButton from 'ui/components/forms/ActionButton';
import asForm from 'ui/components/forms/asForm';
import {Trans, withTranslation} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('PasswordSection')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class PasswordSection extends Component {
  static propTypes = {
    customer: PropTypes.object.isRequired,
  };

  render() {
    const {t, appStore, authStore, customer, updateProperty} = this.props;

    return (
      <fieldset data-component-name={this.props.componentName} id="email-password">
        <div className="card-item-wrapper">
          {customer.AccessPincode && (
            <div className="card-item-header">
              <h5 className="card-item-header-title">
                {t('Your PIN and password')}
              </h5>
            </div>
          )}
          {!customer.AccessPincode && (
            <div className="card-item-header">
              <h5 className="card-item-header-title">
                {t('Your password')}
              </h5>
            </div>
          )}
          <div className="card-item-body">

            {/*{customer.AccessPincode && (*/}
            {/*  <div className="row">*/}
            {/*    <div className="col-sm-12">*/}
            {/*      <div className="form-group">*/}
            {/*        <InputField*/}
            {/*          id="customer-accessPincode"*/}
            {/*          label={t('Your check-in, printing and Internet access PIN')}*/}
            {/*          name="AccessPincode"*/}
            {/*          errors={authStore.customerValidation.errors.AccessPincode}*/}
            {/*          value={customer.AccessPincode}*/}
            {/*          inputProps={{*/}
            {/*            style: {*/}
            {/*              fontFamily: 'Courier',*/}
            {/*              fontWeight: 'bold',*/}
            {/*              fontSize: '1.5em',*/}
            {/*              letterSpacing: '6px',*/}
            {/*              textAlign: 'center',*/}
            {/*              width: '128px',*/}
            {/*              height: '64px',*/}
            {/*              background: '#000',*/}
            {/*              color: '#FFF',*/}
            {/*            },*/}
            {/*            readOnly: 'readonly',*/}
            {/*            disabled: 'disabled',*/}
            {/*          }}*/}
            {/*          onChange={updateProperty}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*)}*/}

            <div className="row">
              <div className="col-sm-12">
                <p>{t('To change your password, type your current password')}</p>
              </div>
            </div>

            <div className="form-group">
              <InputField
                id="customer-oldPassword"
                label={t('Current password')}
                name="OldPassword"
                type="password"
                errors={authStore.customerValidation.errors.OldPassword}
                value={customer.OldPassword}
                onChange={updateProperty}
              />
              <small className="help-block">
                <Trans>
                  <span>If you don't remember your current password: </span>
                  <a
                    onClick={(ev) => {
                      ev.preventDefault();
                      authStore
                        .loadResetPassword(customer.Email)
                        .then((data) => {
                          appStore.setPopMessage(
                            t(
                              'We have sent you an email to {{email}} to reset your password',
                              {email: customer.Email}
                            )
                          );
                        });
                    }}
                    href="#"
                  >
                    request a password reset email
                  </a>
                  <span>.</span>
                </Trans>
              </small>
            </div>

            {customer.OldPassword && (
              <div className="form-group">
                <InputField
                  id="customer-oldPassword"
                  label={t('New Password')}
                  name="NewPassword"
                  type="password"
                  errors={authStore.customerValidation.errors.NewPassword}
                  value={customer.NewPassword}
                  onChange={updateProperty}
                />
              </div>
            )}
            {customer.OldPassword && customer.NewPassword && (
              <div className="form-group">
                <InputField
                  id="customer-oldPassword"
                  label={t('Repeat new password')}
                  name="RepeatNewPassword"
                  type="password"
                  errors={
                    authStore.customerValidation.errors.RepeatNewPassword
                  }
                  value={customer.RepeatNewPassword}
                  onChange={updateProperty}
                />
              </div>
            )}
          </div>
        </div>
        {customer.OldPassword &&
          customer.NewPassword &&
          customer.RepeatNewPassword && (
            <ActionButton
              label={t('Change password')}
              action={() => this.props.saveCustomer()}
              busy={authStore.isSavingCustomerWithUser}
            />
          )}
      </fieldset>
    );
  }
}

export default asForm(PasswordSection, 'customer');
