import React, { useEffect } from 'react';

const withScript = (url) => (WrappedComponent) => (props) => {
  const getHash = () => {
    var hash = 0;
    for (var i = 0; i < url.length; i++) {
      var char = url.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  };

  useEffect(() => {
    const id = getHash();
    // if the script is already added, quit!
    if (document.getElementById(id)) return;

    const script = document.createElement('script');

    script.id = id;
    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);

  return <WrappedComponent {...props} />;
};

export default withScript;
