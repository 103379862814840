import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import LocationsMenu from 'ui/components/LocationsMenu/LocationsMenu';

export const ResourcePageFilters = withCustomComponent('ResourcePageFilters')(
  inject('appStore')(
    withRouter(
      observer(({ router, appStore }) => {
        const { t } = useTranslation();

        return (
          <div className="filters">
            <ul className="nav filters__nav">
              <li className="active pull-right">
                <LocationsMenu showCurrent={true} />
              </li>
            </ul>
          </div>
        );
      })
    )
  )
);
