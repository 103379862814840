import React, { Component } from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import ReactMarkdown from 'react-markdown';
import withCustomComponent from 'ui/components/withCustomComponent';
import { removeHtml } from 'env/utils/strings';

import CardCommunityBoard from 'ui/components/cards/CardCommunityBoard';
import CardEvent from 'ui/components/cards/CardEvent';

@withCustomComponent('ProfilePageBody')
@withRouter
@withTranslation()
@inject('appStore', 'authStore', 'eventsStore')
@observer
class ProfilePageBody extends Component {
  componentDidMount() {
    const { eventsStore } = this.props;
    eventsStore.loadUpcomingEvents().catch(() => null);
  }

  render() {
    const {
      t,
      componentName,
      router,
      member,
      customFields,
      threads,
      appStore,
      authStore,
      eventsStore,
    } = this.props;
    const page = parseInt(router.query.page ?? '1');
    const { business, configuration } = appStore;
    const { customer } = authStore;
    const { upcomingEvents } = eventsStore;
    const memberCustomFields = customFields.filter(
      (cf) => cf.FieldType == 'LongText' || cf.FieldType == 'Text'
    );

    // BB-TODO: Filter upcomingEvents and find only events that selected member is attending.
    const memberEvents = upcomingEvents;

    return (
      <div
        data-component-name={componentName}
        className="directory-profile-page-body"
      >
        {member.ProfileSummary && member.ProfileSummary != '' && (
          <div className="card about">
            {member.ProfileSummary && member.ProfileSummary != '' && (
              <>
                <h5>{t('About Me')}</h5>
                <ReactMarkdown source={removeHtml(member.ProfileSummary)} />
              </>
            )}
          </div>
        )}

        {memberCustomFields.map((field, i) => {
          return (
            member[field.FieldName] && (
              <div className="card about">
                <p key={i}>
                  <h5>{field.Name}</h5>
                  <ReactMarkdown source={member[field.FieldName]} />
                </p>
              </div>
            )
          );
        })}

        {(configuration['PublicWebSite.CommunityBoard'] && threads.length) >
          0 && (
          <>
            <hr className="mt-40 mb-32" />
            <div className="row align-items-center mb-20">
              <div className="col-8">
                <h6 className="mb-0">
                  {t('{{Salutation}} recent conversations', {
                    Salutation: member.Salutation,
                  })}
                </h6>
              </div>
              <div className="col-4 text-right lh-1">
                <Link href={routes.forum}>
                  <a className="btn btn-link text-gray-900 fw-500">
                    {t('See all')}
                    {`${threads.length > 2 ? ` (${threads.length})` : ''}`}
                  </a>
                </Link>
              </div>
            </div>
            <ul className="list-grid list-grid--50 list-grid--lg-100">
              {_.take(threads, 2).map((thread, i) => (
                <li key={i}>
                  <CardCommunityBoard thread={thread} sizeSmall={true} />
                </li>
              ))}
            </ul>
          </>
        )}

        {memberEvents.length > 0 && (
          <>
            <hr className="mt-40 mb-32" />
            <div className="row align-items-center mb-20">
              <div className="col-8">
                <h6 className="mb-0">{t('Industry events')}</h6>
              </div>
              <div className="col-4 text-right lh-1">
                <Link href={routes.events}>
                  <a className="btn btn-link text-gray-900 fw-500">
                    {t('See all')}
                    {`${
                      memberEvents.length > 2 ? ` (${memberEvents.length})` : ''
                    }`}
                  </a>
                </Link>
              </div>
            </div>
            <ul className="list-grid list-grid--50 list-grid--lg-100">
              {_.take(memberEvents, 2).map((event, i) => (
                <li key={i}>
                  <CardEvent event={event} sizeSmall={true} key={i} />
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    );
  }
}
export default ProfilePageBody;
