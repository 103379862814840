import React, { useEffect, useState } from 'react';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import Accordion from 'ui/components/Accordion';
import { CoworkerAvatar } from 'ui/components/CoworkerAvatar';

export const TeamsAttendeesAccordion = withCustomComponent('TeamsAttendeesAccordion')(
  inject('appStore', 'bookingsStore', 'authStore')(
    withRouter(
      observer(
        ({
          bookingsStore,
          authStore,
          teamsProfiles,
          teamsIsAdded,
          setTeamsIsAdded,
          addAttendee,
          removeAttendee
        }) => {
          const { booking } = bookingsStore;

          const initToolTips = () => {
            const $ = window.$;
        
            if ($('[data-toggle="invite-team-tooltip"]').length > 0) {
              $('[data-toggle="invite-team-tooltip"]').tooltip({
                html: true,
              });
            }
          };

          const handleAddAttendeeClick = (member, team) => {
            const isAdded = booking.BookingVisitors?.some(v => v.VisitorEmail === member.Email);

            if(isAdded) {
              removeAttendee(member.Email);
            } else {
              addAttendee(member.Id, member.FullName, member.Email);
            }
          }

          const handleAddTeamClick = (team) => {
            let newTeamsIsAdded = {...teamsIsAdded};

            if(newTeamsIsAdded[team.Name].isAdded) {
              team.AllTeamMembers.forEach(member => {
                if(member.Email === authStore.me.Email) return;

                removeAttendee(member.Email);
              });
            } else {
              team.AllTeamMembers.forEach(member => {
                if(member.Email === authStore.me.Email) return;
                
                const isMemberAdded = booking.BookingVisitors?.some(v => v.VisitorEmail === member.Email);
                if(isMemberAdded) return;

                addAttendee(member.Id, member.FullName, member.Email);
              });
            }
          }

          const isWholeTeamAdded = (allTeamMembers) => {
            for (let i = 0; i < allTeamMembers.length; i++) {
              const member = allTeamMembers[i];
              if(member.Email === authStore.me.Email) continue;

              const isMemberAdded = booking.BookingVisitors?.some(v => v.VisitorEmail === member.Email);
              if(!isMemberAdded) {
                return false;
              }
            }

            return true;
          }

          useEffect(() => {
            initToolTips();
          }, [])

          return (
            teamsProfiles.map(({Team: team}, i) => {
              return <Accordion
                id={team.Id}
                heading={team.Name}
                showDividerTop={false}
                key={`accordion-teams-${i}`}
                className='teams-accordion'
                actionButton={
                  <AddButton
                    baseIcon='icon-add'
                    onClick={(e) => {
                      e.stopPropagation();

                      const tooltip = window.$('[data-toggle="invite-team-tooltip"]');
                      if(tooltip.length > 0) {
                        if(teamsIsAdded[team.Name]?.isAdded) {
                          $('[data-toggle="invite-team-tooltip"]')
                          .tooltip('dispose')
                          .tooltip({
                            html: true,
                            title: t('Invite the entire team to this booking.')
                          });
                        } else {
                          $('[data-toggle="invite-team-tooltip"]').tooltip('dispose');
                        }
                      }

                      handleAddTeamClick(team);
                    }}
                    isAdded={teamsIsAdded[team.Name]?.isAdded}
                    classes={`add-team fs-16 p-4`}
                    dataToggle='invite-team-tooltip'
                    title={t('Invite the entire team to this booking.')}
                  />
                }
              >
                {team.AllTeamMembers && team.AllTeamMembers.map((member, i) => {
                  if(member.Email === authStore.me.Email) return null;

                  const isAdded = booking.BookingVisitors?.some(v => v.VisitorEmail === member.Email);
                  return (
                    <div className="d-flex align-items-center justify-content-between mb-20" key={member.Id}>
                      <div>
                        <CoworkerAvatar
                          title={member.FullName}
                          coworker_id={member.Id}
                          size={40}
                          imgClassName="avatar avatar--40"
                        />
                        <span className="fs-14 fw-500 ml-8 align-middle">{member.FullName}</span>
                      </div>

                      <AddButton
                        baseIcon='icon-attendee-add'
                        onClick={() => handleAddAttendeeClick(member, team)}
                        isAdded={isAdded}
                        classes='fs-24'
                      />
                    </div>
                  );
                })}
              </Accordion>
            })
          );
        }
      )
    )
  )
);

const AddButton = ({baseIcon, onClick, isAdded, classes = '', dataToggle = '', title = ''}) => (
  <a href="#" className="text-decoration-none" onClick={onClick} data-toggle={dataToggle} title={title}>
    <i
      className={`${isAdded ? 'attendee-added' : baseIcon} icon- align-middle ${classes}`}
      aria-hidden={true}
    >
    </i>
  </a>
);
