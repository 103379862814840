import { observable, action, autorun, reaction, toJS } from 'mobx';
import { loadDataHelper, saveDateHelper } from './storeHelpers';
import _ from 'lodash';

import StoreBase from './storeBase';
import { validationContext } from 'validx';
const validation = validationContext();

class CoursesStore extends StoreBase {
  constructor({ data, host } = {}) {
    super({ data, host });

    reaction(
      () => toJS(this.newComment),
      (newComment) => {
        this.newCommentValidation = validation
          .reset()
          .validate(newComment, commentValidationSchema(this, newComment));
      }
    );

    reaction(
      () => toJS(this.newLessonComment),
      (newLessonComment) => {
        this.newLessonCommentValidation = validation
          .reset()
          .validate(
            newLessonComment,
            commentValidationSchema(this, newLessonComment)
          );
      }
    );
  }

  @observable newComment = {};
  @observable newCommentValidation = { errors: {} };
  @observable isSavingNewComment = false;
  @action saveNewComment({ course_id }) {
    return saveDateHelper({
      store: this,
      agentKey: 'Courses',
      key: 'NewComment',
      validation: validation,
      validationSchema: commentValidationSchema(this, this.newComment),
      modelToValidate: this.newComment,
      data: { id: course_id, ...this.newComment },
    })
      .then(
        action(() => {
          this.newComment = {};
        })
      )
      .then(
        action(() => {
          this.newCommentValidation = { errors: {} };
        })
      );
  }

  @observable isDeletingComment = false;
  @action deleteComment(comment_id) {
    this.isDeletingComment = true;
    return this.getAgent()
      .Courses.deleteComment(comment_id)
      .then(
        action((data) => {
          if (data.ErrorMessage) throw new Error(data.ErrorMessage);
          return data;
        })
      )
      .finally(
        action((data) => {
          this.isDeletingComment = false;
          return data;
        })
      );
  }

  @observable newLessonComment = {};
  @observable newLessonCommentValidation = { errors: {} };
  @observable isSavingNewLessonComment = false;
  @action saveNewLessonComment({ lesson_id }) {
    return saveDateHelper({
      store: this,
      agentKey: 'Courses',
      key: 'NewLessonComment',
      validation: validation,
      validationSchema: commentValidationSchema(this, this.newLessonComment),
      modelToValidate: this.newLessonComment,
      data: { id: lesson_id, ...this.newLessonComment },
    })
      .then(
        action(() => {
          this.newLessonComment = {};
        })
      )
      .then(
        action(() => {
          this.newLessonCommentValidation = { errors: {} };
        })
      );
  }

  @observable isDeletingLessonComment = false;
  @action deleteLessonComment(comment_id) {
    this.isDeletingLessonComment = true;
    return this.getAgent()
      .Courses.deleteLessonComment(comment_id)
      .then(
        action((data) => {
          if (data.ErrorMessage) throw new Error(data.ErrorMessage);
          return data;
        })
      )
      .finally(
        action((data) => {
          this.isDeletingLessonComment = false;
          return data;
        })
      );
  }

  @observable isLoadingCoursesPage = false;
  @observable hasLoadedCoursesPage = false;
  @observable coursesPage = {
    Categories: [],
    Courses: [],
  };
  @action loadCoursesPage(params) {
    return loadDataHelper({
      store: this,
      agentKey: 'Courses',
      key: 'CoursesPage',
      params,
    });
  }

  @observable isLoadingCoursePage = false;
  @observable hasLoadedCoursePage = false;
  @observable coursePage = {
    Course: {},
  };
  @action loadCoursePage(course_id) {
    return loadDataHelper({
      store: this,
      agentKey: 'Courses',
      key: 'CoursePage',
      params: course_id,
    });
  }

  @observable isLoadingMyCourses = false;
  @observable hasLoadedMyCourses = false;
  @observable myCourses = {
    Categories: [],
    MyCourses: {},
  };
  @action loadMyCourses(params) {
    return loadDataHelper({
      store: this,
      agentKey: 'Courses',
      key: 'MyCourses',
      params,
    }).then(
      action((data) => {
        return data;
      })
    );
  }

  @observable isLoadingSignUp = false;
  @observable hasLoadedSignUp = false;
  @observable signUp = {};
  @action loadSignUp(course_id) {
    return loadDataHelper({
      store: this,
      agentKey: 'Courses',
      key: 'SignUp',
      params: course_id,
    });
  }

  @observable isLoadingLessonsPage = false;
  @observable hasLoadedLessonsPage = false;
  @observable lessonsPage = {
    LessonsPage: [],
  };
  @action loadLessonsPage(params) {
    return loadDataHelper({
      store: this,
      agentKey: 'Courses',
      key: 'LessonsPage',
      params,
    });
  }

  @observable isLoadingLessonPage = false;
  @observable hasLoadedLessonPage = false;
  @observable lessonPage = {
    LessonPage: {},
  };
  @action loadLessonPage(course_id, lesson_id) {
    return loadDataHelper({
      store: this,
      agentKey: 'Courses',
      key: 'LessonPage',
      params: { course_id, lesson_id },
    });
  }

  @action completeLesson(course_id, lesson_guid) {
    return this.getAgent()
      .Courses.putCompleteLesson(course_id, lesson_guid)
      .then(
        action((res) => {
          return res;
        })
      );
  }

  @observable shouldShowGraduationModal = false;
  @action async showGraduationModal(shouldShow) {
    this.shouldShowGraduationModal = shouldShow;

    return new Promise((resolve, reject) => {
      autorun((reaction) => {
        if (!this.shouldShowGraduationModal) {
          resolve();
          reaction.dispose();
        }
      });
    });
  }

  @observable videos = [];
  @action setVideos(videos) {
    this.videos = videos;
    return videos;
  }

  COMPLETION_TYPES = {
    NONE: 1,
    VISITED: 2,
    BUTTON: 3,
    VIDEO: 4,
  };
}
export default CoursesStore;
