import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import Head from 'next/head';
export const DefaultHead = withCustomComponent('DefaultHead')(
  withRouter(
    observer(({ appStore }) => {
      const { t } = useTranslation();
      const { business } = appStore;
      return (
        <Head>
          <meta
            key="og:type"
            content="website"
            property="og:type"
            content="website"
          />
          <meta key="og:url" property="og:url" content={business.HomeUrl} />
          <meta key="og:title" property="og:title" content={business.Name} />
          <meta
            key="og:description"
            property="og:description"
            content={business.Name}
          />
          <meta
            key="og:image"
            property="og:image"
            content={`${business.NativeHomeUrlWithLanguage}/business/getlogo`}
          />

          <meta
            key="twitter:card"
            content="photo"
            name="twitter:card"
            content="photo"
          />
          <meta
            key="twitter:site"
            name="twitter:site"
            content={business.HomeUrlWithLanguage}
          />
          <meta
            key="twitter:title"
            name="twitter:title"
            content={business.Name}
          />
          <meta
            key="twitter:image"
            name="twitter:image"
            content={`${business.NativeHomeUrlWithLanguage}/business/getlogo`}
          />
          <meta
            key="twitter:url"
            name="twitter:url"
            content={`${business.HomeUrlWithLanguage}`}
          />
        </Head>
      );
    })
  )
);
