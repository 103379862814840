import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { bookingsFeatures } from 'env/bookingsResources';

export const BookingFeaturesList = withCustomComponent('BookingFeaturesList')(
  inject('appStore')(
    withRouter(
      observer(({ data, router, appStore }) => {
        const { t } = useTranslation();

        useEffect(() => {
          initToolTips();
          return () => {
            const $ = window.$;
            $('[data-toggle="resource-tooltip"]').tooltip('dispose');
          };
        }, []);

        const initToolTips = () => {
          const $ = window.$;
          if ($('[data-toggle="resource-tooltip"]').length > 0) {
            $('[data-toggle="resource-tooltip"]').tooltip({
              html: true,
            });
          }
        };

        return (
          <>
            {bookingsFeatures(t).map((feature, i) => {
              return (
                (data[feature.key] || data[`Resource${feature.key}`]) && (
                  <li key={i}>
                    <span data-toggle="resource-tooltip" title={feature.name}>
                      <i className={`i-nexudus-${feature.icon}`}></i>
                    </span>
                  </li>
                )
              );
            })}
          </>
        );
      })
    )
  )
);
