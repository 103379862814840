import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import ProposalPageLayout from 'ui/layouts/ProposalPageLayout';
import { withRouter } from 'next/router';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import { SurveyDetails } from 'ui/_pages/surveys/SurveyDetails';

@withRouter
@withCustomComponent('SurveyPreviewPage')
@withTranslation()
@inject('appStore', 'communityStore')
@observer
class SurveyPreviewPage extends Component {
  componentDidMount() {
    const { t, router, appStore, communityStore } = this.props;
    communityStore
      .loadSurveyPreviewPage(router.query.survey_guid)
      .catch((ex) => {
        appStore.setPopMessage(t('We could not load this survey.'));
      });
  }

  render() {
    const { t, appStore, communityStore } = this.props;
    const { business } = appStore;
    const { surveyPreviewPage, isLoadingSurveyPreviewPage } = communityStore;
    if (!surveyPreviewPage || isLoadingSurveyPreviewPage)
      return <LoadingLayout />;
    const survey = surveyPreviewPage.SurveyRun.SurveyDrop;
    return (
      <ProposalPageLayout
        componentName={this.props.componentName}
        title={survey.Name}
        description={survey.Description}
      >
        <SurveyDetails surveyPage={surveyPreviewPage} />
      </ProposalPageLayout>
    );
  }
}

export default SurveyPreviewPage;
