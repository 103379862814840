import React, { useState, useEffect } from 'react';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { Marker, MarkerClusterer } from '@react-google-maps/api';

export const MarkersContainer = withCustomComponent('MarkersContainer')(
  inject(
    'appStore',
    'bookingsStore'
  )(
    withRouter(
      observer(
        ({
          appStore,
          resourcesToShow,
          bookingsStore,
          businessPosition,
          setHoveringOn,
        }) => {
          const clustererSvgBase64 = window.btoa(`
            <svg fill="#666666" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
            <circle cx="120" cy="120" opacity="1" r="70" />
            </svg>`);

          const bgColors = [
            '#28B95F',
            '#DCB40A',
            '#F0783C',
            '#F04B69',
            '#0064FA',
          ];

          const tempMarkerClusterer = (
            <MarkerClusterer
              gridSize={10}
              styles={[
                {
                  url: `data:image/svg+xml;base64,${clustererSvgBase64}`,
                  height: 45,
                  width: 45,
                  textColor: '#ffffff',
                  textSize: 14,
                  fontFamily: '"CircularXX", sans-serif',
                  fontWeight: 'normal',
                },
              ]}
            >
              {(clusterer) => createMarkers(clusterer)}
            </MarkerClusterer>
          );

          //setMarkerClusterer(tempMarkerClusterer);

          const createMarkers = (clusterer) => {
            let markers = [];
            let resources = resourcesToShow.sort(sortByName);
            for (let i = 0; i < resources.length; i++) {
              const r = resources[i];

              let position = businessPosition;

              if (r.Latitude !== 0 && r.Longitude !== 0) {
                position = new google.maps.LatLng(r.Latitude, r.Longitude);
              }

              const bgColor = bgColors[(r.Id % 10) % bgColors.length];
              const markerSvgBase64 = window.btoa(`
             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <circle cy="12" cx="12" fill="${bgColor}" r="12"></circle>
                <path fill="#fff" d="M12 2.25c-5.4 0-9.8 4.4-9.8 9.8s4.4 9.8 9.8 9.8 9.8-4.4 9.8-9.8-4.4-9.8-9.8-9.8zm-4.2 16.8v-6.1c0-.1.1-.3.3-.3h8c.1 0 .3.1.3.3v6.1c-1.2.8-2.7 1.2-4.3 1.2-1.7 0-3.1-.5-4.3-1.2zm5.7-9.3c.1 0 .3.1.3.3v1.3h-3.5v-1.4c0-.1.1-.3.3-.3h2.9v.1zm4.3 8.1v-4.9c0-1-.8-1.8-1.8-1.8h-.8v-1.2c0-1-.8-1.8-1.8-1.8h-.8v-1.7c0-.4-.3-.8-.8-.8s-.8.3-.8.8v1.8h-.8c-1 0-1.8.8-1.8 1.8v1.3h-.4c-1 0-1.8.8-1.8 1.8v4.9c-1.5-1.5-2.5-3.6-2.5-5.9 0-4.6 3.7-8.3 8.3-8.3s8.3 3.7 8.3 8.3c0 2.1-1 4.2-2.5 5.7z"></path>
                <path fill="#fff" d="M14.5 14.5h-5c-.4 0-.8.3-.8.8s.4.7.8.7h5c.4 0 .8-.3.8-.8s-.4-.7-.8-.7zM14.5 17h-5c-.4 0-.8.3-.8.8s.3.8.8.8h5c.4 0 .8-.3.8-.8s-.4-.8-.8-.8z"></path>
              </svg>
            `);

              markers.push(
                <Marker
                  key={r.Id}
                  clusterer={clusterer}
                  position={position}
                  cursor="pointer"
                  onClick={() => newBooking(r.Id)}
                  onMouseOver={() => setHoveringOn(i)}
                  onMouseOut={() => setHoveringOn(-1)}
                  onLoad={(marker) => {
                    addMarkerHoverEvents(marker, markerSvgBase64);
                    marker.setIcon({
                      url: `data:image/svg+xml;base64,${markerSvgBase64}`,
                      scaledSize: new google.maps.Size(30, 30),
                    });
                  }}
                />
              );
            }

            return markers;
          };

          const addMarkerHoverEvents = (marker, markerSvgBase64) => {
            google.maps.event.addListener(marker, 'mouseover', function () {
              marker.setIcon({
                url: `data:image/svg+xml;base64,${markerSvgBase64}`,
                scaledSize: new google.maps.Size(35, 35),
              });
            });

            google.maps.event.addListener(marker, 'mouseout', function () {
              marker.setIcon({
                url: `data:image/svg+xml;base64,${markerSvgBase64}`,
                scaledSize: new google.maps.Size(30, 30),
              });
            });
          };

          const newBooking = (resourceId) => {
            const foundResource = resourcesToShow.find(
              (r) => r.Id == resourceId
            );
            if (!foundResource) return;

            const business = appStore.businesses.find(
              (b) => b.Id == foundResource.BusinessId
            );
            if (!business) return;

            // If this resource is not for this location, open the booking form
            // in the other site.
            if (business.Id != appStore.business.Id) {
              window.open(
                business.HomeUrl +
                  `/bookings/search?resource_id=${foundResource.Id}`
              );
              return;
            }

            bookingsStore.newBooking({
              resourceId: resourceId,
              fromTime: bookingsStore.fromTime,
              toTime: bookingsStore.toTime,
            });
          };

          return <>{tempMarkerClusterer}</>;
        }
      )
    )
  )
);

const sortByName = (a, b) => {
  if (a.Name < b.Name) {
    return -1;
  }
  if (a.Name > b.Name) {
    return 1;
  }

  return 0;
};
