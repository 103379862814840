import { required, pattern } from 'validx';
import { toLocalTime } from 'env/utils/dateUtil';
import global from 'global';

export const planOverviewValidationSchema = () => {
  const t = global.t;

  return {
    // The schema maps to the fields we are validating.
    startDate: [required({msg: t('Start date is required')})],
    agreedTc: [required({msg: t('To continue the purchase, you must agree to the terms and conditions.')})]
  };
};
