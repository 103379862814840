import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const CoursePageImage = withCustomComponent('CoursePageImage')(
  inject(
    'appStore',
    'coursesStore'
  )(
    withRouter(
      observer(({ router, appStore, coursesStore }) => {
        const course = coursesStore.coursePage.Course;
        const { business } = appStore;
        return (
          <div className="article-content fr-view">
            {course.LargeImageFileName && (
              <img
                src={`${business.NativeHomeUrlWithLanguage}/learn/getCourselargeimage?id=${course.Id}&w=1500`}
                alt={course.Title}
              />
            )}
          </div>
        );
      })
    )
  )
);
