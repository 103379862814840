import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import {withTranslation} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('CardDashboard')
@withTranslation()
class CardDashboard extends Component {
  static propTypes = {
    headerMainTitle: PropTypes.string.isRequired,
    headerActionTitle: PropTypes.string,
    headerActionUrl: PropTypes.string,
    footerActionTitle: PropTypes.string,
    footerActionIcon: PropTypes.string,
    footerActionUrl: PropTypes.string,
    componentName: PropTypes.string,
  };

  render() {
    const {
      t,
      headerMainTitle,
      headerActionTitle, headerActionUrl,
      footerActionTitle, footerActionIcon, footerActionUrl,
      componentName
    } = this.props;

    return (
      <div
        data-component-name={componentName}
        className=""
      >
        <div className="card-item-wrapper">
          <div className="card-dashboard__header card-item-header">
            {headerMainTitle &&
              <h2 className="card-item-header-title-dashboard">
                {headerMainTitle}
              </h2>
            }
            {(headerActionTitle && headerActionUrl) &&
              <div className="btn-group justify-content-end">
                <Link href={headerActionUrl}>
                  <a className="btn btn btn-gray">{headerActionTitle}</a>
                </Link>
              </div>
            }
          </div>

          <div className="card-item-body">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default CardDashboard;
