import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { routes } from 'env/routes';
import Link from 'next/link';
import { withRouter } from 'next/router';
import withCustomComponent from 'ui/components/withCustomComponent';
import { ProgressCircle } from 'ui/components/ProgressCircle';

@withRouter
@withCustomComponent('LessonNavigationMenu')
@withTranslation()
@inject('authStore', 'appStore', 'coursesStore')
@observer
class LessonNavigationMenu extends Component {
  initLateralSmoothNav = () => {
    const $ = window.$;
    const { router } = this.props;
    if ($('#js-nav-smooth').length > 0) {
      $('#js-nav-smooth li.active a').on('click', (e) => {
        const id = $(e.target).attr('href').split('#').pop();
        if (id.indexOf('/') > -1) return;
        e.preventDefault();
      });
    }

    if ($('.js-link-select-navigation').length > 0) {
      $('.js-link-select-navigation select').on('change', function (e) {
        if ($(this).val() !== '') {
          router.push($(this).val());
        }
      });
    }
  };

  createList = (Lessons, Course, router) => {
    let list = [];

    for (let i = 0; i < Lessons.length; i++) {
      const lesson = Lessons[i];

      if (!lesson.Section) {
        const isActive = router.asPath === routes.lesson_by_id(Course, lesson.Id);

        list.push(
          <li key={i.toString()}>
            <LessonLink lesson={lesson} course={Course} isActive={isActive}/>
          </li>
        );
        continue;
      }

      let currSectionId = lesson.Section.Id;

      // if lesson has a section, prepare all lessons in that section
      let items = [];
      let j = i;
      let isActiveSection = false;
      while (j < Lessons.length && Lessons[j]?.Section?.Id === currSectionId) {
        //determining if any of the lessons in this section is active; if so, set to true only once
        if(!isActiveSection) {
          isActiveSection =  router.asPath === routes.lesson_by_id(Course, Lessons[j].Id);
        }

        const isActive = router.asPath === routes.lesson_by_id(Course, Lessons[j].Id);
        items.push(
          <li
            key={j.toString()}
          >
            <LessonLink lesson={Lessons[j]} course={Course} isActive={isActive}/>
          </li>
        );

        j++;
      }

      // add section name and respective lessons
      list.push(
        <li
          key={lesson.Section.Title}
          className={
            isActiveSection
              ? 'active'
              : null
        }>
          <Link
            href={`${routes.lesson_by_id(Course, lesson.Id)}?course_id=${
              Course.Id
            }&lesson_id=${lesson.Id}`}
            as={routes.lesson_by_id(Course, lesson.Id)}
          >
            <a><b>{lesson.Section.Title}</b></a>
          </Link>

          <ul className={!isActiveSection && 'd-none'}>{items}</ul>
        </li>
      );

      if (i < Lessons.length - 1) {
        i = j - 1; // skip to next element outside current section
      }
    }

    return list;
  };

  initToolTips = () => {
    const $ = window.$;
    $('[data-toggle="tooltip"]').tooltip('dispose');
    $('[data-toggle="tooltip"]').tooltip({
      html: true,
    });
  };

  componentDidMount() {
    this.initLateralSmoothNav();
    this.initToolTips();
  }

  render() {
    const { t, router, coursesStore } = this.props;
    const { Course } = coursesStore.coursePage;
    const { Lessons } = coursesStore.lessonsPage;
    const courseCompletionPercentage = Math.round(Lessons.filter(l => l.IsComplete).length * 100 / Lessons.length);

    return (
      <>
        <div className="card h-auto p-16 p-md-24 mb-24 lesson-navigation-container">
          <div className="nav-header row justify-content-between align-items-center">
            <div className="col-xl-6">
              <h6 className="title">{t('Course Outline')}</h6>
            </div>

            <div className="col-xl-6 text-xl-right">
              <span className="completion align-middle mr-12">{courseCompletionPercentage + '%'} {t('Completed')}</span>
              <ProgressCircle percentage={courseCompletionPercentage}/>
            </div>
          </div>

          <hr className="hidden-md"></hr>

          <nav
            data-component-name={this.props.componentName}
            className="nav-lateral lessons"
            id="js-nav-smooth"
            role="navigation"
          >
            <ul>{this.createList(Lessons, Course, router)}</ul>
          </nav>
        </div>

        <nav
          data-component-name={this.props.componentName}
          className="nav-lateral--mobile visible-xs js-link-select-navigation pb-24"
          role="navigation"
        >
          <select
            value={router.pathname}
            aria-label={t("Lessons navigation menu")}
            className="form-control nav-selector"
            id="nav-lateral-select"
          >
            {Lessons.map((lesson) => {
              return (
                <option
                  id={`nav-${lesson.Id}`}
                  value={`${routes.lesson_by_id(Course, lesson.Id)}?course_id=${
                    Course.Id
                  }&lesson_id=${lesson.Id}`}
                  disabled={!lesson.IsOpen}
                >
                  {lesson.Title}
                </option>
              );
            })}
          </select>
        </nav>
      </>
    );
  }
}

export default LessonNavigationMenu;

function LessonLink({ lesson, course, isActive }) {
  if (lesson.IsOpen) {
    return (
      <div className="lesson-link d-flex justify-content-between">
        <Link
          href={`${routes.lesson_by_id(course, lesson.Id)}?course_id=${
            course.Id
          }&lesson_id=${lesson.Id}`}
          as={routes.lesson_by_id(course, lesson.Id)}
        >
          <a className={isActive ? 'active' : ''}>{lesson.Title}</a>
        </Link>
        <i className={`icon p-4 rounded font-weight-bold ${ lesson.IsComplete ? 'icon-confimred green' : 'icon-pending yellow'}`}></i>
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-between locked">
        <span className="lesson-title">{lesson.Title}</span>
        <span className="icon icon-safe-box p-4 rounded font-weight-bold"
              data-toggle="tooltip"
              title={t('Please complete the previous lesson to continue.')}>
        </span>
      </div>
    );
  }
}
