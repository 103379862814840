import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import { withRouter } from 'next/router';
import ProposalPageLayout from 'ui/layouts/ProposalPageLayout';
import { replaceNewLines } from 'env/utils/strings';
import { ProposalDetailsTable } from 'ui/_pages/proposal/ProposalDetailsTable';
import { ProposalDocument } from 'ui/_pages/proposal/ProposalDocument';
import { routes } from 'env/routes';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import ContractDetails from 'ui/_pages/plan/ContractDetails';

@withRouter
@withCustomComponent('ProposalViewPage')
@withTranslation()
@inject('appStore', 'authStore', 'invoicingStore')
@observer
class ProposalViewPage extends Component {
  componentDidMount() {
    const { authStore, router, appStore, t, invoicingStore } = this.props;

    if (authStore.isLoggedIn) authStore.logout();

    invoicingStore.loadProposalView(router.query.proposal_guid).catch((err) => {
      appStore.setPopMessage(
        t('Sorry, we could not load this proposal. {{err}}', { err })
      );
    });
  }

  acceptProposal = () => {
    const { router, invoicingStore, appStore, t } = this.props;
    const { proposalView } = invoicingStore;
    const proposal = proposalView?.Proposal;

    if (!proposal) return;

    invoicingStore
      .loadAcceptProposal(proposal.UniqueId)
      .then((r) => {
        if (r.ErrorMessage) appStore.setPopMessage(r.ErrorMessage);
        else if (r.accessToken) {
          const returnUrl =
            r.file > 0
              ? `/legal/esign/${r.file}?proposal_id=${proposal.UniqueId}`
              : `${routes.proposal_summary}?proposal_id=${proposal.UniqueId}`;
          router.push({
            pathname: routes.login,
            query: {
              t: r.accessToken,
              returnurl:
                routes.signup +
                `?proposal_id=${
                  proposal.UniqueId
                }&returnurl=${encodeURIComponent(returnUrl)}`,
            },
          });
        }
      })
      .catch((err) => {
        appStore.setPopMessage(
          t('Sorry, we could not accept this proposal. {{err}}', { err })
        );
      });
  };

  render() {
    const { t, appStore, invoicingStore } = this.props;
    const { business } = appStore;
    const { proposalView, isLoadingProposalView, isLoadingAcceptProposal } =
      invoicingStore;
    const proposal = proposalView?.Proposal;
    if (!proposal || isLoadingProposalView) return <LoadingLayout />;

    return (
      <ProposalPageLayout
        componentName={this.props.componentName}
        title={t('Your proposal from {{Name}}', { Name: business?.Name })}
      >
        <div className="container settings__content">
          <div className="mw-840 pt-48 pb-100 pb-md-48">
            <fieldset>
              <h4 className="d-flex align-items-center mb-24">
                <i className="icon-invoice fs-24 mr-8"></i>
                {t('Proposal Summary')}
              </h4>

              <p>
                {t('Hi {{FullName}},', {
                  FullName: proposal.Coworker.FullName,
                })}
              </p>

              <p>
                {t(
                  "You're just about to join {{tariffName}} subscription at {{businessName}}. Your order code is {{reference}}. Enjoy your new experience.",
                  {
                    tariffName: proposal.Contracts.map((contract, i) => {
                      const coma =
                        proposal.Contracts.length == i + 1 ? '' : ', ';
                      return contract.Tariff.Name + coma;
                    }),
                    businessName: t(business.Name),
                    reference: proposal.Reference,
                  }
                )}
              </p>

              {proposal.Notes && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: replaceNewLines(proposal.Notes, '<br />'),
                  }}
                ></p>
              )}

              <p className="text-gray-600 fs-14 mb-0">
                {t(
                  "Please review the details of this proposal and click 'accept' below to continue."
                )}
              </p>

              {proposal.HasFile && (
                <p className="mt-16 mb-0">
                  <a
                    target="_blank"
                    className="btn"
                    href={`${business.NativeHomeUrlWithLanguage}/content/proposalFile?guid=${proposal.UniqueId}`}
                  >
                    {t('Download proposal documents')}
                  </a>
                </p>
              )}
            </fieldset>

            {proposal.HasFileToSend ? (
              <fieldset>
                <ProposalDocument />
              </fieldset>
            ) : (
              <ProposalDetailsTable />
            )}

            {proposal.DiscountDescription && (
              <div className="mt-24">
                {proposal.Contracts.map((contract, i) => (
                  <div className="d-flex justify-content-between mb-4">
                    <div className="text-green fw-500">
                      {contract.DiscountDescription}
                    </div>
                    <div className="text-green fw-500 fs-18 text-right">
                      {/* DiscountAmount */}
                    </div>
                  </div>
                ))}
                <p className="fs-12 text-gray-600">
                  {t('The discounts listed above will only apply at checkout.')}
                </p>
              </div>
            )}

            <div className="btn-group justify-content-end mt-40">
              {proposal.Responsible.Email && (
                <a
                  href={`mailto:${proposal.Responsible.Email}?subject=RE: Proposal ${proposal.Reference}`}
                  className="btn btn-lg btn-icon btn-outline"
                >
                  <i className="icon-email-delivery"></i>
                  {t('Email us')}
                </a>
              )}
              <a
                onClick={(ev) => {
                  ev.preventDefault();
                  this.acceptProposal();
                }}
                href="#"
                className="btn btn-lg px-32"
              >
                {isLoadingAcceptProposal ? (
                  <LoadingSpinner />
                ) : (
                  <>{t('Accept proposal')}</>
                )}
              </a>
            </div>
          </div>
        </div>
      </ProposalPageLayout>
    );
  }
}

export default ProposalViewPage;
