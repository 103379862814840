import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('CardDashboardSmall')
@withTranslation()
class CardDashboardSmall extends Component {
  static propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    mainValue: PropTypes.number,
    subValue: PropTypes.number,
    zeroMessage: PropTypes.string,
    svg: PropTypes.object,
    oneLiner: PropTypes.bool,
  };

  render() {
    const {
      t,
      title,
      url,
      mainValue,
      subValue,
      zeroMessage,
      svg,
      oneLiner,
    } = this.props;

    return oneLiner ? (
      <div
        data-component-name={this.props.componentName}
        className={`card card-dashboard-small--one-liner`}
      >
        {svg && <div className="card-dashboard-small__svg">{svg}</div>}
        {title && (
          <div className="card-dashboard-small__header">
            {url ? (
              <>
                <Link href={url}>
                  <a>{title}</a>
                </Link>
                <i className="icon-chevron-right"></i>
              </>
            ) : (
              <span>{title}</span>
            )}
          </div>
        )}
        <div className="card-dashboard-small__content">
          {mainValue > 0 ? (
            <div className="values">
              {mainValue && <h1>{mainValue}</h1>}
              {subValue && <h4>/{subValue}</h4>}
            </div>
          ) : (
            <>{zeroMessage && <p className="zero-message">{zeroMessage}</p>}</>
          )}
        </div>
      </div>
    ) : (
      <div
        data-component-name={this.props.componentName}
        className={`card card-dashboard-small ${
          oneLiner && 'card-dashboard-small--one-liner'
        }`}
      >
        {title && (
          <div className="card-dashboard-small__header">
            {url ? (
              <>
                <Link href={url}>
                  <a>{title}</a>
                </Link>
                <i className="icon-chevron-right"></i>
              </>
            ) : (
              <span>{title}</span>
            )}
          </div>
        )}
        <div className="card-dashboard-small__content">
          {mainValue > 0 ? (
            <div className="values">
              {mainValue && <h1>{mainValue}</h1>}
              {subValue && <h4>/{subValue}</h4>}
            </div>
          ) : (
            <>{zeroMessage && <p className="zero-message">{zeroMessage}</p>}</>
          )}
          {this.props.children}
        </div>
        {svg && <div className="card-dashboard-small__svg">{svg}</div>}
      </div>
    );
  }
}

export default CardDashboardSmall;
