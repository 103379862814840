/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {observer, inject} from 'mobx-react';
import {action} from 'mobx';
import asForm from 'ui/components/forms/asForm';
import InputField from 'ui/components/forms/InputField';
import TextAreaInputField from 'ui/components/forms/TextAreaInputField';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import {withRouter} from 'next/router';
import Link from 'next/link';
import {routes} from 'env/routes';
import FileInputField from 'ui/components/forms/FileInputField';
import withCustomComponent from 'ui/components/withCustomComponent';

let NewSupportMessageForm = props => {
  const {
    componentName,
    router,
    departments,
    newMessage,
    appStore,
    supportStore,
    updateProperty,
  } = props;
  const {t} = useTranslation();

  const submitNewMessage = () => {
    if (!newMessage.Subject) {
      appStore.setPopMessage(t('A subject is required'));
      return;
    }

    if (!newMessage.MessageText) {
      appStore.setPopMessage(t('A message is required'));
      return;
    }

    supportStore.submitNewMessage().then(() => {
      window.$('#support-message-modal').modal('hide');
      appStore.setPopMessage(
        t(
          'Thank you for your message. We will get back to you as soon as possible.',
        ),
      ).then(() => {
        router.push(routes.support);
      });
    }).catch((err) => {
      appStore.setPopMessage(
        err.data ? err.data.ErrorMessage : err,
      );
    });
  };

  const selectDepartment = action((department) => {
    supportStore.newMessage.helpDeskDepartment = {
      Id: department.Id,
      Name: department.Name,
    };
  });
  const {isSubmittingNewMessage} = supportStore;

  return (
    <form data-component-name={componentName}>
      <div className="card-item-wrapper">
        <div className="card-item-header">
          <h2 className="card-item-header-title">
            <i className="icon-ask-for-help mr-8"></i>
            <span>{t('Ask for Help')}</span>
          </h2>
        </div>
        <div className="card-item-body">
          <p className="mb-24 fs-14 text-gray-700">
            {t(
              'Create a new help request, and someone from our team will reply within the next 24 hours.',
            )}
          </p>

          {departments && departments.length > 0 && (
            <div className="form-group">
              <label>{t('Department')}</label>
              <div className="dropdown">
                <button
                  className="btn btn-select dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
              <span>
                {newMessage.helpDeskDepartment
                  ? newMessage.helpDeskDepartment.Name
                  : t('Select a department')}
              </span>{' '}
                  <span className="caret"></span>
                </button>
                <div className="dropdown-menu" role="menu">
                  {departments.map((department, i) => (
                    <a
                      key={i}
                      className="dropdown-item d-block h-auto py-8"
                      href="#"
                      onClick={(ev) => {
                        ev.preventDefault();
                        selectDepartment(department);
                      }}
                    >
                  <span className="d-block fw-500 text-gray-900">
                    {department.Name}
                  </span>
                      <span className="d-block fs-14 fw-400 text-gray-600 pt-2 ws-normal">
                    {department.Description}
                  </span>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          )}

          <div className="form-group">
            <InputField
              id="subject"
              label={t('Subject')}
              name="Subject"
              errors={supportStore.messageValidation.errors.Subject}
              value={newMessage.Subject}
              onChange={updateProperty}
              inputProps={{
                placeholder: t('i.e. Cold water tap dripping.'),
              }}
            />
            <div className="fs-12 text-gray-600 lh-13 mt-8">
              {t('This will serve as the title of your help request.')}
            </div>
          </div>

          <div className="form-group">
            <TextAreaInputField
              id="messageText"
              label={t('Message')}
              name="MessageText"
              errors={supportStore.messageValidation.errors.MessageText}
              value={newMessage.MessageText}
              onChange={updateProperty}
            />
            <div className="fs-12 text-gray-600 lh-13 mt-8">
              <div>
                {t(
                  'Try to be as detailed as possible when describing your issue.',
                )}
              </div>
            </div>
          </div>

          <div className="form-group">
            <FileInputField
              name="base64image"
              label={t('Attach Images or files')}
              onChange={updateProperty}
              multiple={true}
            />
            <div className="fs-12 text-gray-600 lh-13 mt-8">
              {t(
                'Max 10Mb. Hold Ctrl in Windows or Command in Mac to select more than one file',
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-48 pb-4">
        {/*<Link href={routes.support}>*/}
        {/*  <a className="btn btn-white mr-8">{t('Cancel')}</a>*/}
        {/*</Link>*/}
        <button
          className="btn btn-icon"
          onClick={(ev) => {
            ev.preventDefault();
            submitNewMessage();
          }}
        >
          {isSubmittingNewMessage ? (
            <LoadingSpinner/>
          ) : (
            <>
              <i className="icon-email-delivery"></i>
              {t('Submit your Request')}
            </>
          )}
        </button>
      </div>
    </form>
  );
};

export default NewSupportMessageForm = withCustomComponent('NewSupportMessageForm')(
  inject('appStore', 'supportStore')(
    withRouter(
      asForm(observer(NewSupportMessageForm), 'newMessage'),
    ),
  ),
);
