import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import DateInputField from 'ui/components/forms/DateInputField';
import { TimeInputField } from 'ui/components/forms/TimeInputField';
import withCustomComponent from 'ui/components/withCustomComponent';
import { InputRadioBox } from 'ui/components/forms/InputRadioBox';

@withCustomComponent('BookingDateTimeForm')
@withTranslation()
@inject('appStore', 'authStore', 'bookingsStore', 'floorPlanStore')
@observer
class BookingDateTimeForm extends Component {
  componentDidMount() {
    const { bookingsStore } = this.props;
    if (!bookingsStore.hasLoadedResources)
      bookingsStore.loadResources().then(this.selectInitialShift);
    else this.selectInitialShift();
  }

  selectInitialShift = () => {
    const { isSidePopup, floorPlanStore, bookingsStore } = this.props;
    const resource = this.getResource();
    const hasShifts = resource && resource.Shifts && resource.Shifts.length > 0;

    if (hasShifts) {
      const { shiftId, shifts } = isSidePopup ? floorPlanStore : bookingsStore;
      const $ = window.$;
      const foundShift =
        shiftId &&
        resource.Shifts.find((s) => _.trim(s.Name) == shifts[shiftId].title);

      const shiftToSelect = foundShift ?? resource.Shifts[0];
      this.selectShift(shiftToSelect.UniqueId);

      setTimeout(() => {
        $(`#shift-radio-${shiftToSelect.UniqueId}`).attr('checked', 'checked');
      }, 250);
    }
  };

  updateBooking = this.props.updateBooking;

  getCurrentBooking = () => {
    const { bookingsStore } = this.props;
    const { booking } = bookingsStore;
    return booking;
  };

  getResource() {
    const { bookingsStore } = this.props;
    const { booking, resources } = bookingsStore;
    return (
      booking &&
      _(resources)
        .filter((r) => r.Id === booking.ResourceId)
        .first()
    );
  }

  selectShift(shiftUniqueId) {
    if (!shiftUniqueId) return;

    const booking = this.getCurrentBooking();

    this.updateBooking('SelectedShift', shiftUniqueId);
    const resource = this.getResource();
    var shift = _(resource.Shifts)
      .filter((s) => s.UniqueId === shiftUniqueId)
      .first();

    var startHours = shift.Start.split(':')[0];
    var startMinutes = shift.Start.split(':')[1];
    var endHours = shift.End.split(':')[0];
    var endMinutes = shift.End.split(':')[1];
    let fromTime = new Date(booking.FromTime);
    let toTime = new Date(booking.ToTime);

    fromTime.setHours(startHours, startMinutes, 0, 0);
    toTime.setHours(endHours, endMinutes, 0, 0);

    // Overnight shifts
    if (fromTime > toTime) toTime = moment(toTime).add(1, 'day').toDate();

    this.updateBooking('FromTime', fromTime);
    this.updateBooking('ToTime', toTime);
  }

  selectDates = (selectingDate, date) => {
    const booking = this.getCurrentBooking();
    let dates = {};
    dates[selectingDate] = moment(date).toDate();

    if (selectingDate == 'fromTime') {
      if (moment(date) >= moment(booking.ToTime))
        dates.toTime = moment(date).add(60, 'minutes').toDate();
    }

    if (selectingDate == 'toTime') {
      if (moment(date) <= moment(booking.FromTime))
        dates.fromTime = moment(date).add(-60, 'minutes').toDate();
    }

    if (dates.fromTime) this.updateBooking('FromTime', dates.fromTime);
    if (dates.toTime) this.updateBooking('ToTime', dates.toTime);
  };

  render() {
    const { t, componentName, withBorder, isSidePopup } = this.props;
    const { bookingsStore, appStore } = this.props;
    const { booking } = bookingsStore;
    const { configuration } = appStore;
    const resource = this.getResource();
    const hasShifts = resource && resource.Shifts && resource.Shifts.length > 0;
    return (
      <div
        data-component-name={componentName}
        className="booking-date-time-form"
      >
        <h6 className="mb-8 modal-booking-details-title">{t('Select a time')}</h6>

        <div
          className={`${isSidePopup ? 'd-flex' : ''} justify-content-between`}
        >
          <div
            className={`date-picker-container ${
              isSidePopup ? '' : 'd-flex'
            } align-items-end justify-content-between fs-14 mb-12`}
          >
            <DateInputField
              className={`form-group-sm ${!withBorder && 'border-1'} ${
                !isSidePopup && 'w-50'
              } mb-0`}
              id="FromTime"
              label={t('Start') + ':'}
              name="FromTime"
              value={booking.FromTime}
              onChange={(prop, value) => {
                this.updateBooking(prop, value);
              }}
              customProps={{
                min: configuration['Bookings.AcceptPastBookings']
                  ? new Date(1900, 0, 1)
                  : new Date(),
              }}
            />

            {!hasShifts && (
              <TimeInputField
                id="FromTime"
                name="FromTime"
                classes={!isSidePopup && 'w-50 pl-12'}
                containerClasses={`${!withBorder && 'border-1'} ${
                  isSidePopup ? 'w-100 mt-12' : ''
                }`}
                value={booking.FromTime}
                onChange={(prop, value) => {
                  this.updateBooking(prop, value);
                }}
                allowPastDates
              />
            )}
          </div>

          <div
            className={`date-picker-container ${
              isSidePopup ? '' : 'd-flex'
            } align-items-end justify-content-between fs-14 mb-12`}
          >
            <DateInputField
              className={`form-group-sm ${!withBorder && 'border-1'} ${
                !isSidePopup && 'w-50'
              } mb-0`}
              id="ToTime"
              label={t('End') + ':'}
              name="ToTime"
              value={booking.ToTime}
              onChange={(prop, value) => {
                this.updateBooking(prop, value);
              }}
              customProps={{
                min: configuration['Bookings.AcceptPastBookings']
                  ? new Date(1900, 0, 1)
                  : new Date(),
              }}
            />

            {!hasShifts && (
              <TimeInputField
                id="ToTime"
                name="ToTime"
                classes={!isSidePopup && 'w-50 pl-12'}
                containerClasses={`${!withBorder && 'border-1'} ${
                  isSidePopup ? 'w-100 mt-12' : ''
                }`}
                value={booking.ToTime}
                onChange={(prop, value) => {
                  this.updateBooking(prop, value);
                }}
                allowPastDates
              />
            )}
          </div>
        </div>

        {hasShifts && (
          <div
            className={`d-flex align-items-center justify-content-between fs-14 ${
              !isSidePopup && 'mb-12'
            }`}
          >
            <div className="w-100">
              <div className="form-group mb-0">
                <label className="fw-400 text-gray-700">
                  {t('Time slots') + ':'}
                </label>
                <br />
                {isSidePopup ? (
                  <div className="row pb-4 m-0 btn-group btn-group-toggle justify-content-between">
                    {resource.Shifts.map((s, i) => (
                      <InputRadioBox
                        key={i}
                        name="shift-radios"
                        value={s.UniqueId}
                        label={s.Name}
                        onLabelClick={(ev) =>
                          this.selectShift(ev.target.dataset.value)
                        }
                        isActive={s.UniqueId === booking.SelectedShift}
                      />
                    ))}
                  </div>
                ) : (
                  <select
                    className={`form-control ${!withBorder && 'border-1'}`}
                    aria-label={t("Select shift")}
                    value={booking.SelectedShift}
                    onChange={(ev) => this.selectShift(ev.target.value)}
                  >
                    <option value="">-</option>
                    {resource.Shifts.map((s, i) => (
                      <option value={s.UniqueId} key={i}>
                        {s.Name}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
          </div>
        )}
        {/* <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="RecurrentBooking"
          />
          <label className="custom-control-label fs-14 pt-2" htmlFor="RecurrentBooking">{t('Make this booking recurrent')}</label>
        </div> */}
      </div>
    );
  }
}
export default BookingDateTimeForm;
