import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import Link from 'next/link';
import moment from 'moment';
import {withRouter} from 'next/router';
import FullPageLayout from 'ui/layouts/FullPageLayout';
import {routes} from 'env/routes';
import {ChangeContractHeader} from 'ui/_pages/plan/change/ChangeContractHeader';
import PlansTable from 'ui/_pages/plan/PlansTable';
import {withCustomComponent} from 'ui/components/withCustomComponent';

let Contract_id = props => {
  const {
    router,
    t,
    appStore,
    invoicingStore,
  } = props;

  useEffect(() => {
    const {router, invoicingStore} = props;
    invoicingStore.loadContract(router.query.contract_id).catch(() => router.push(routes.plan));
  }, []);

  const onPlanSelected = ({tariff}) => {
    invoicingStore.saveContract({
      contractId: router.query.contract_id,
      tariffId: tariff.Id,
    }).then((contract) => {
      appStore.setPopMessage(
        t(
          'Your plan {{Name}} will change to {{NextName}} on {{RenewalDate}}',
          {
            NextName: contract.NextTariff.Name,
            Name: contract.Tariff.Name,
            RenewalDate: moment(contract.RenewalDate).format('L'),
          },
        ),
      ).then(() => {
        router.push(routes.plans_my_plans);
      });
    }).catch(() => {
      appStore.setPopMessage(
        t('Your plan could not be changed. Please contact us.'),
      );
    });
  };

  const {contract} = invoicingStore;

  return (
    <FullPageLayout title={t('Change contract')}>
      {contract && (
        <>
          <ChangeContractHeader contract={contract}/>
          <PlansTable
            buttonLabel={t('Change to this plan')}
            isLoading={props.invoicingStore.isSavingContract}
            onPlanSelected={onPlanSelected}
            showStartDate={false}
            planDesign={'modern'}
          />
          <div className="mt-24 btn-group justify-content-end pb-28">
            <Link href={routes.plans_my_plans}>
              <a className="btn btn-outline">{t('Cancel')}</a>
            </Link>
          </div>
        </>
      )}
    </FullPageLayout>
  );
};

Contract_id = withCustomComponent('PlanChangePage')(
  withRouter(
    withTranslation()(
      inject('appStore', 'authStore', 'invoicingStore')(
        observer(Contract_id),
      ),
    ),
  ),
);

export default Contract_id;
