import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import withCustomComponent from 'ui/components/withCustomComponent';

import { CourseBasketItem } from 'ui/_pages/checkout/basketItems/CourseBasketItem/CourseBasketItem';
import { EventBasketItem } from 'ui/_pages/checkout/basketItems/EventBasketItem/EventBasketItem';
import { BookingBasketItem } from 'ui/_pages/checkout//basketItems/BookingBasketItem/BookingBasketItem';
import ContractBasketItem  from 'ui/_pages/checkout//basketItems/ContractBasketItem/ContractBasketItem';
import { ProductBasketItem } from 'ui/_pages/checkout//basketItems/ProductBasketItem/ProductBasketItem';
import { InvoiceLineBasketItem } from 'ui/_pages/checkout//basketItems/InvoiceLineBasketItem/InvoiceLineBasketItem';

export const BasketItemFactory = withCustomComponent('BasketItemFactory')(
  inject('appStore')(
    withRouter(
      observer(({ basketItem, router, appStore }) => {
        const { t } = useTranslation();
        if (basketItem.type === 'course')
          return <CourseBasketItem basketItem={basketItem} />;
        if (basketItem.type === 'event')
          return <EventBasketItem basketItem={basketItem} />;
        if (basketItem.type === 'booking')
          return <BookingBasketItem basketItem={basketItem} />;
        if (basketItem.type === 'contract')
          return <ContractBasketItem basketItem={basketItem} />;
        if (basketItem.type === 'product')
          return <ProductBasketItem basketItem={basketItem} />;
        if (basketItem.SubTotal != undefined)
          return <InvoiceLineBasketItem basketItem={basketItem} />;
        return <div className="alert alert-danger">Invalid basket item!</div>;
      })
    )
  )
);
