import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import moment from 'moment';
import Link from 'next/link';
import { routes } from 'env/routes';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('FeaturedBlogPost')
@withTranslation()
@inject('appStore', 'blogStore')
class FeaturedBlogPost extends Component {
  render() {
    const { t, post, componentName } = this.props;

    return (
      <>
        <div
          data-component-name={componentName}
          className="dashboard-event__body"
        >
          <div className="page-header page-header--centered page-header--dashboard">
            <ul className="list-inline page-header--dashboard__list">
              {post.PostedBy && (
                <li>
                  <Link href="#">
                    <a className="link--default">{post.PostedBy.FullName}</a>
                  </Link>
                  {' | '}
                  {moment(post.PublishDate).format('LL')}
                </li>
              )}
              {!post.PostedBy && <li>{moment(post.PublishDate).format('LL')}</li>}
              <li>
                <i className="fa fa-comments i-space--right--small"></i>
                {t('{{count}} comment', { count: post.Comments.length })}
              </li>
              {post.BlogCategories.map((category, i) => (
                <li>
                  <Link href={routes.posts_by_category(category.Id)}>
                    <a>
                      <span key={i} className="label label--default">
                        {category.Title}
                      </span>
                    </a>
                  </Link>
                </li>
              ))}
            </ul>

            <h1>{post.Title}</h1>
          </div>

          <div className="dashboard-event__body__summary">
            <p>{post.SummaryText}</p>
          </div>

          <div className="dashboard-event__body__excerpt">
            <p>
              <span
                className="fr-view"
                dangerouslySetInnerHTML={{
                  __html: post.FullText,
                }}
              ></span>{' '}
              <Link
                href={routes.post_by_id('[post_id]')}
                as={routes.post_by_id(post)}
              >
                <a className="link--default">{t('Read more')}</a>
              </Link>
            </p>
          </div>
        </div>
        <hr />
      </>
    );
  }
}

export default FeaturedBlogPost;
