import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { action } from 'mobx';
import withCustomComponent from 'ui/components/withCustomComponent';
import ProductsHeader from 'ui/_pages/store/ProductsHeader';

@withCustomComponent('BookingServicesForm')
@withTranslation()
@inject('appStore', 'bookingsStore')
@observer
class BookingServicesForm extends Component {
  componentDidMount() {
    const { bookingsStore } = this.props;

    if (!bookingsStore.hasLoadedResources) bookingsStore.loadResources();
  }

  loadBookingPrice = this.props.loadBookingPrice;

  @action updateProduct = (product, quantity) => {
    product.Quantity = quantity;
    product.Selected = quantity == 1;
    this.loadBookingPrice();
  };

  render() {
    const { t, componentName, appStore, bookingsStore, isSidePopup } =
      this.props;
    const { resourceProducts } = bookingsStore;

    if (resourceProducts.length <= 0) return <></>;

    const isCorporateDashboard = appStore.corporateDashboard;

    return (
      <div
        data-component-name={componentName}
        className={`${isSidePopup ? 'mt-10' : 'mt-24'} booking-services-form`}
      >
        <h6 className="mb-8">{t('Additional Services')}</h6>
        {resourceProducts.map((p, i) =>
          !p.RequestQuantity ? (
            <div
              className="d-flex align-items-center justify-content-between my-12"
              key={i}
            >
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={`resourceProduct${i}`}
                  defaultChecked={p.Quantity > 0}
                />
                <label
                  className="custom-control-label fs-14 pt-2"
                  id="js--checkbox-label"
                  htmlFor={`resourceProduct${i}`}
                  onClick={(ev) => {
                    this.updateProduct(p, p.Quantity === 0 ? 1 : 0);
                  }}
                >
                  {p.ProductName}
                </label>
              </div>
              <div className="text-gray-700 fs-14">
                {!isCorporateDashboard && <>{p.FormattedPrice}</>}
              </div>
            </div>
          ) : (
            <NumberInput
              key={i}
              product={p}
              quantity={p.Quantity}
              i={i}
              isCorporateDashboard={isCorporateDashboard}
              isSidePopup={isSidePopup}
              updateProduct={this.updateProduct}
            />
          )
        )}

        {isSidePopup && <hr className="divider mb-0 mt-24" />}
      </div>
    );
  }
}
export default BookingServicesForm;

const NumberInput = ({
  product,
  quantity,
  i,
  isCorporateDashboard,
  isSidePopup,
  updateProduct,
}) => {
  if (isSidePopup) {
    return (
      <div className="d-flex number-input-container align-items-center justify-content-between">
        <div class="d-flex number-input align-items-center">
          <button id="js--decrease"></button>

          <input
            id="js--number-input"
            className="number-input d-inline"
            type="number"
            min="0"
            max="100"
            onClick={(ev) => {
              updateProduct(product, parseInt(ev.target.value));
            }}
            value={quantity}
          />

          <button id="js--increase" class="plus"></button>

          <label className="fs-14 pt-2 ml-10">{product.ProductName}</label>
        </div>

        <div className="text-gray-700 fs-14">
          {!isCorporateDashboard && <>{product.FormattedPrice}</>}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="d-flex align-items-center justify-content-between fs-14 mb-12"
        key={i}
      >
        <div className="d-flex align-items-center">
          <select
            style={{ width: 60 }}
            aria-label={t('Select product quantity')}
            className="form-control form-control-sm border-0 mr-12"
            onChange={(ev) => updateProduct(product, parseInt(ev.target.value))}
            value={quantity}
          >
            {[...Array(!product.RequestQuantity ? 2 : 101).keys()].map((j) => (
              <option key={j} value={j}>
                {j}
              </option>
            ))}
          </select>
          {product.ProductName}
        </div>
        <div className="d-flex align-items-center">
          <div className="text-gray-700">
            {!isCorporateDashboard && <>{product.FormattedPrice}</>}
          </div>
        </div>
      </div>
    );
  }
};
