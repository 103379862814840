import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import MainMenu  from 'ui/components/MainMenu';
import MainHeader from 'ui/components/MainHeader';
import Footer from 'ui/components/Footer';
import withCustomComponent from 'ui/components/withCustomComponent';
import Head from 'next/head';

@withTranslation()
@withCustomComponent('DefaultLayout')
@inject('appStore', 'authStore', 'bookingsStore')
@observer
class DefaultLayout extends Component {
  componentDidMount() {
    const { authStore } = this.props;
    const { isLoggedIn } = authStore;
    const $ = window.$;
    if (isLoggedIn) {
      $('body').removeClass('lo');
      $('body').addClass('li');
    } else {
      $('body').removeClass('li');
      $('body').addClass('lo');
    }
  }

  render() {
    const { t, appStore, authStore, title } = this.props;

    return (
      <div data-component-name={this.props.componentName}>
        <Head>
          <meta charSet="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <title>{title}</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        </Head>
        <MainMenu />
        <MainHeader />
        {this.props.children}
        {/*<Footer />*/}
      </div>
    );
  }
}

export default DefaultLayout;
