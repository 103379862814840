import React, {Component} from 'react';
import {observer, inject} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import ActionButton from 'ui/components/forms/ActionButton';
import asForm from 'ui/components/forms/asForm';
import FileInputField from 'ui/components/forms/FileInputField';
import BooleanInputField from 'ui/components/forms/BooleanInputField';
import Link from 'next/link';
import {routes} from 'env/routes';
import {action} from 'mobx';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('AppearanceSection')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class AppearanceSection extends Component {
  @action updateCustomer(key, value) {
    const {customer, saveCustomer} = this.props;
    customer[key] = value;
    saveCustomer();
  }

  showStarIfRequired = (group, fieldName) => {
    const {appStore} = this.props;
    const {configuration} = appStore;
    return configuration[`${group}.${fieldName}`] === '2' ? '*' : '';
  };

  render() {
    const {t, customer, saveCustomer, authStore, appStore, updateProperty} =
      this.props;
    const {business, configuration} = appStore;

    const isNewCustomer = !customer.Id > 0;
    const {customerValidation} = authStore;

    const shouldShowAppearanceSection =
      !isNewCustomer ||
      (configuration['SignupForm.ShowProfileSection'] &&
        configuration['SignupForm.Avatar'] !== '3');

    if (!shouldShowAppearanceSection) return null;

    return (
      <fieldset
        data-component-name={this.props.componentName}
        id="your-appearance"
        className="pt-10"
      >
        <div className="card-item-wrapper">
          <div className="card-item-header">
            <h5 className="card-item-header-title">
              {t('Your Photo') +
                this.showStarIfRequired('SignupForm', 'Avatar')}
            </h5>
          </div>
          <div className="card-item-body">
            <div className="row">
              <div id="avatar" className="col-12 col-md-3 pb-16 pb-md-0">
                {!isNewCustomer && (
                  <img
                    className="avatar avatar--140"
                    src={`${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${customer.Id}?cache=${customer.UpdatedOn}&h=320&w=320`}
                    onError={(e) => {
                      e.target.onerror = null;
                      document.getElementById('avatar').remove();
                    }}
                  />
                )}
              </div>
              <div className="col-12 col-md-9">
                <FileInputField
                  name="base64avatar"
                  errors={customerValidation.errors.base64avatar}
                  label={t(
                    'File smaller than 10MB and at least 400px by 400px.'
                  )}
                  labelClassName={'mb-8'}
                  onChange={updateProperty}
                />
                <p className="fs-14 text-gray-700 mt-8 mb-12 mxw-500px">
                  {t(
                    'This image will be shown in the members directory and your profile page if you choose to share it with other members. It will also help us recognize you! File smaller than 10MB and at least 400px by 400px.'
                  )}
                </p>
                <div>
                  {!isNewCustomer && (
                    <button
                      className="btn btn btn-outline-gray btn-xs"
                      onClick={(e) => {
                        this.updateCustomer('DeleteAvatar', true);
                        e.preventDefault();
                      }}
                    >
                      {t('Remove my photo')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*<div className="card-item-wrapper">*/}
        {/*  <div className="card-item-header">*/}
        {/*    <h5 className="card-item-header-title mb-0">*/}
        {/*      {t('Profile page cover')}*/}
        {/*    </h5>*/}
        {/*  </div>*/}
        {/*  <div className="card-item-body">*/}
        {/*    <div className="row">*/}
        {/*      <div id="banner" className="col-12 col-md-3 pb-16 pb-md-0">*/}
        {/*        {!isNewCustomer && (*/}
        {/*          <img*/}
        {/*            className="avatar avatar--140"*/}
        {/*            src={`${business.NativeHomeUrlWithLanguage}/coworker/getbanner/${customer.Id}?cache=${customer.UpdatedOn}&h=320&w=320`}*/}
        {/*            onError={(e) => {*/}
        {/*              e.target.onerror = null;*/}
        {/*              document.getElementById('banner')?.remove();*/}
        {/*            }}*/}
        {/*          />*/}
        {/*        )}*/}
        {/*      </div>*/}
        {/*      <div className="col-12 col-md-9">*/}
        {/*        <FileInputField*/}
        {/*          name="base64banner"*/}
        {/*          label={t(*/}
        {/*            'File smaller than 10MB and at least 1200px by 300px.'*/}
        {/*          )}*/}
        {/*          labelClassName={'mb-8'}*/}
        {/*          onChange={updateProperty}*/}
        {/*        />*/}
        {/*        <p className="fs-14 text-gray-700 mt-8 mb-12 mxw-500px">*/}
        {/*          {t(*/}
        {/*            'This image will be shown as background banner in your profile page if you choose to share it with other members.'*/}
        {/*          )}*/}
        {/*        </p>*/}
        {/*        <div>*/}
        {/*          {!isNewCustomer && (*/}
        {/*            <button*/}
        {/*              className="btn btn btn-outline-gray btn-xs"*/}
        {/*              onClick={(e) => {*/}
        {/*                this.updateCustomer('DeleteBanner', true);*/}
        {/*                e.preventDefault();*/}
        {/*              }}*/}
        {/*            >*/}
        {/*              {t('Remove profile page cover')}*/}
        {/*            </button>*/}
        {/*          )}*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {
          saveCustomer && (
            <div className="d-flex align-items-center">
              <ActionButton
                action={() => saveCustomer()}
                busy={authStore.isSavingCustomerWithUser}
              />
              <Link
                as={{pathname: routes.directory_member_by_id(customer)}}
                href={{pathname: routes.directory_member_by_id('[member_id]')}}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-outline-gray  ml-8"
                >
                  {t('View my profile')}
                </a>
              </Link>
            </div>
          )
        }
      </fieldset>
    )
      ;
  }
}

export default asForm(AppearanceSection, 'customer');
