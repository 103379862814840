import React, {Component} from 'react';
import {observer, inject} from 'mobx-react';
import _ from 'lodash';
import moment from 'moment';
import Link from 'next/link';
import {withRouter} from 'next/router';
import {routes} from 'env/routes';
import {withTranslation} from 'react-i18next';
import {getColorForResource} from 'ui/_pages/bookings/calendar/ResourceColorHelper';
import {linkTo} from 'env/utils/strings';
import withCustomComponent from 'ui/components/withCustomComponent';
import ActiveLink from 'ui/components/ActiveLink';
import DateInputField from 'ui/components/forms/DateInputField';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import {bookingsFeatures} from 'env/bookingsResources';
import {TimeInputField} from 'ui/components/forms/TimeInputField';

@withCustomComponent('BookingHeader')
@withTranslation()
@withRouter
@inject('appStore', 'bookingsStore', 'floorPlanStore', 'checkoutStore')
@observer
class BookingHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtersExpanded: false,
      availabilityExpanded: false,
      allocation: props.router?.query?.allocation ?? '',
    };
  }

  handleSearchBySelectChange = (e) => {
    // Room features are queried as "proyector=true&catering=true"...
    // Resource and types are queried as type=123456&group=Rooms...

    //TODO: consider the option to filter by multiple features as in the app.
    const {router, t} = this.props;
    const href =
      e.target.name == 'feature'
        ? linkTo(
          router,
          bookingsFeatures(t).reduce((acc, cur) => {
            acc[cur.key] =
              cur.key == e.target.value && e.target.value ? 'true' : null;
            return acc;
          }, {})
        )
        : linkTo(router, {
          [e.target.name]: e.target.value,
        });
    e.preventDefault();
    router.push(href);
  };

  filtersToggle = (e) => {
    this.setState({
      filtersExpanded: !this.state.filtersExpanded,
    });
  };

  availabilityToggle = (e) => {
    this.setState({
      availabilityExpanded: !this.state.availabilityExpanded,
    });
  };

  render() {
    const {
      t,
      componentName,
      router,
      appStore,
      bookingsStore,
      floorPlanStore,
      doSearch,
      selectShift,
      selectDates,
      shifts,
      isLoadingItems,
      shiftId,
      fromTime,
      toTime,
      hideResourceFilters = false,
      checkoutStore,
    } = this.props;
    const {configuration} = appStore;
    const {basket} = checkoutStore;
    const {resourceGroups, resourceTypes, resourceSearch} = bookingsStore;
    const {floorPlans} = floorPlanStore;
    const resources = resourceSearch.Resources;
    const showAvailability = router.pathname === routes.calendar ? false : true;

    const updateByShift = (shiftId) => {
      selectShift(shiftId);
      doSearch();
    };

    const updateDates = (name, value) => {
      //If we are moving the fromTime, shift the toTime by the same amount.
      var updateObj = {};
      if (name == 'fromTime') {
        var diff = moment(value).diff(fromTime, 'minutes');
        updateObj.toTime = moment(toTime).add(diff, 'minutes').toDate();
      }

      updateObj[name] = value;
      selectDates(updateObj);
      doSearch();
    };

    const hasShifts = shifts ? shifts.length > 0 : false;
    const dateToday = new Date();
    const minHour = moment(fromTime).isSame(dateToday, 'day')
      ? moment(dateToday).minute() > 30
        ? moment(dateToday).set('minutes', 60).toDate()
        : moment(dateToday).set('minutes', 30).toDate()
      : moment(dateToday).startOf('day').toDate();
    const featuresToShow = bookingsFeatures(t)
      // Show only features that at least has one resource in them.
      .filter((feature) => resources.find((r) => r[feature.key]) != null);
    return (
      <div data-component-name={componentName} className="booking-header pt-0">
        <div className="portal-title-wrapper">
          <div className="container portal-title-container">
            <h1 className="portal-title">
              {t('Available resources')}
            </h1>
            <div className="resources-type-view-wrapper d-flex d-md-block justify-content-center">
              <ActiveLink href={routes.resources} activeOnRootPath={true}>
                <a className="resources-type-view btn btn-icon btn-md-xs">
                  <i className=" icon-icon-card-view"></i>
                  <span className="pl-4 pl-lg-0">{t('Card view')}</span>
                </a>
              </ActiveLink>
              <ActiveLink href={routes.calendar} activeOnRootPath={true}>
                <a className="resources-type-view btn btn-icon ml-0 ml-md-8 btn-md-xs">
                  <i className="icon-icon-calendar"></i>
                  <span className="pl-4 pl-lg-0">{t('Calendar view')}</span>
                </a>
              </ActiveLink>
              {floorPlans.length > 0 && (
                <ActiveLink href={routes.floorplan} activeOnRootPath={true}>
                  <a className="resources-type-view btn btn-icon ml-0 ml-md-8 btn-md-xs">
                    <i className="icon-floorplan-view"></i>
                    <span className="pl-4 pl-lg-0">{t('Floor plan view')}</span>
                  </a>
                </ActiveLink>
              )}
              {/*Map*/}
              {/*<ActiveLink href={routes.resources_map} activeOnRootPath={true}>
                <a
                  id="map_link"
                  className="resources-type-view btn  btn-icon ml-0 ml-md-8 btn-md-xs"
                >
                  <i className="icon-icon-map"></i>
                  <span className="pl-4 pl-lg-0">{t('Map')}</span>
                </a>
              </ActiveLink>*/}
            </div>
          </div>
        </div>

        {!hideResourceFilters && (
          <div className="container">
            <div className="meetings-header">
              <ul className="list-inline-32 sm-row">
                <li id="filter-button" className="ml-md-auto d-block d-md-none">
                  <button
                    onClick={() => this.filtersToggle()}
                    className="btn btn-icon btn-outline"
                  >
                    {this.state.filtersExpanded ? (
                      <>
                        <i className="icon-close"></i>
                        <span>{t('Hide filters')}</span>
                      </>
                    ) : (
                      <>
                        <i className="icon-icon-filter fs-12"></i>
                        <span>{t('Filters')}</span>
                      </>
                    )}
                  </button>
                </li>

                <>
                  <li
                    id="resource-filter"
                    className={`d-none align-items-center w-sm-100 d-md-flex ${
                      this.state.filtersExpanded ? 'd-flex' : ''
                    }`}
                  >
                    <label
                      className="resource-filter-label mw-sm-64px"
                      htmlFor="select-resource-group"
                    >
                      {t('Resource:')}
                    </label>
                    <select
                      name="group"
                      id="select-resource-group"
                      value={router.query.group ?? ''}
                      onChange={this.handleSearchBySelectChange}
                      className="custom-select custom-select-sm w-sm-100"
                    >
                      <option value="">{t('All')}</option>
                      {resourceGroups
                        .filter((g) => g.groupName)
                        .map((group, i) => (
                          <option key={i} value={group.groupName}>
                            {t(group.groupName)}
                          </option>
                        ))}
                    </select>
                  </li>

                  <li
                    id="type-filter"
                    className={`d-none align-items-center w-sm-100 d-md-flex ${
                      this.state.filtersExpanded ? 'd-flex' : ''
                    }`}
                  >
                    <label
                      className="resource-filter-label mw-sm-64px"
                      htmlFor="select-type"
                    >
                      {t('Type:')}
                    </label>
                    <select
                      name="type"
                      id="select-type"
                      value={router.query.type ?? ''}
                      onChange={this.handleSearchBySelectChange}
                      className="custom-select custom-select-sm w-sm-100"
                    >
                      <option value="">{t('All')}</option>
                      {resourceTypes.map((type, i) => (
                        <option key={i} value={type.Id}>
                          {t(type.Name)}
                        </option>
                      ))}
                    </select>
                  </li>
                  <li
                    id="capacity-filter"
                    className={`d-none align-items-center w-sm-100 d-md-flex ${
                      this.state.filtersExpanded ? 'd-flex' : ''
                    }`}
                  >
                    <label
                      className="resource-filter-label mw-sm-64px"
                      htmlFor="select-capacity"
                    >
                      {t('Capacity:')}
                    </label>
                    <select
                      name="allocation"
                      id="select-capacity"
                      value={this.state.allocation ?? ''}
                      onChange={(e) => {
                        this.handleSearchBySelectChange(e);
                        this.setState({allocation: e.target.value});
                      }}
                      className="custom-select custom-select-sm w-sm-100"
                    >
                      {[1, 2, 5, 10, 20, 50].map((i) => (
                        <option key={i} value={i}>
                          {t('{{capacity}} or more', {capacity: i})}
                        </option>
                      ))}
                    </select>
                  </li>
                  {featuresToShow.length > 0 && (
                    <li
                      id="features-filter"
                      className={`d-none align-items-center w-sm-100 d-md-flex ${
                        this.state.filtersExpanded ? 'd-flex' : ''
                      }`}
                    >
                    <span className="resource-filter-label mw-sm-64px">
                      {t('Features:')}
                    </span>
                      <select
                        name="feature"
                        value={this.state.feature ?? ''}
                        onChange={(e) => {
                          this.handleSearchBySelectChange(e);
                          this.setState({feature: e.target.value});
                        }}
                        className="custom-select custom-select-sm w-sm-100"
                      >
                        <option value="">{t('All')}</option>
                        {featuresToShow.map((feature, i) => (
                          <option key={i} value={feature.key}>
                            {feature.name}
                          </option>
                        ))}
                      </select>
                    </li>
                  )}
                </>

                {showAvailability && (
                  <li id="filter-search-button" className="ml-md-auto">
                    <button
                      onClick={() => this.availabilityToggle()}
                      className="btn btn-icon"
                    >
                      <span>{t('Search')}</span>
                      <i className="icon-search mr-0 ml-8"></i>
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}

        {hideResourceFilters ||
        (showAvailability && this.state.availabilityExpanded) ? (
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="meetings-header">
                  <form>
                    <ul className="list-inline-32 sm-column">
                      <li className="d-flex align-items-center w-sm-100">
                        <DateInputField
                          className="row sm-72-100"
                          label={t('Start date')}
                          name="fromTime"
                          value={fromTime}
                          onChange={updateDates}
                          customProps={{
                            min: configuration['Bookings.AcceptPastBookings']
                              ? new Date(1900, 0, 1)
                              : new Date(),
                          }}
                        />
                      </li>
                      {!hasShifts && (
                        <li className="d-flex align-items-center w-sm-100">
                          <TimeInputField
                            classes="row sm-72-100"
                            label={t('Start time')}
                            name="fromTime"
                            value={fromTime}
                            onChange={updateDates}
                            allowPastDates
                          />
                        </li>
                      )}

                      <li className="d-flex align-items-center w-sm-100">
                        <DateInputField
                          className="row sm-72-100"
                          label={t('End date')}
                          name="toTime"
                          value={toTime}
                          onChange={updateDates}
                          customProps={{
                            min: configuration['Bookings.AcceptPastBookings']
                              ? new Date(1900, 0, 1)
                              : new Date(),
                          }}
                        />
                      </li>
                      {!hasShifts && (
                        <li className="d-flex align-items-center w-sm-100">
                          <TimeInputField
                            classes="row sm-72-100"
                            label={t('End time')}
                            name="toTime"
                            value={toTime}
                            onChange={updateDates}
                            allowPastDates
                          />
                        </li>
                      )}

                      {hasShifts && (
                        <li className="d-flex align-items-center w-sm-100">
                        <span className="text-gray-700 fs-14 mr-12 mw-sm-72px">
                          {t('Timeslot:')}
                        </span>
                          <select
                            value={shiftId}
                            onChange={(ev) => updateByShift(ev.target.value)}
                            className="custom-select custom-select-sm w-sm-100"
                          >
                            {shifts.map((item, i) => (
                              <option value={item.id} key={i}>
                                {t(item.title)}
                              </option>
                            ))}
                          </select>
                        </li>
                      )}

                      <li className="ml-0 ml-md-auto w-sm-100">
                        <button
                          onClick={(ev) => {
                            ev.preventDefault();
                            doSearch();
                            ev.target.blur();
                            if (this.props.availabilityToggle)
                              this.props.availabilityToggle(true);
                          }}
                          className={`btn btn-icon w-sm-100 mt-8 mt-md-0 ${
                            basket.length > 0 ? 'mr-5 btn-outline' : ''
                          }`}
                          type="button"
                        >
                          {isLoadingItems ? (
                            <LoadingSpinner/>
                          ) : (
                            <>
                              <i className="icon-date-availability"></i>
                              {t('Find available spaces')}
                            </>
                          )}
                        </button>
                        {basket.length > 0 && (
                          <button
                            onClick={(ev) => {
                              router.push(routes.checkout);
                            }}
                            className="btn btn-icon w-sm-100 mt-8 mt-md-0 "
                            type="button"
                          >
                            <i className="icon-cart"></i>
                            {t('Checkout')}
                          </button>
                        )}
                      </li>
                    </ul>
                  </form>
                </div>
              </div>
            </div>

          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

export default BookingHeader;
