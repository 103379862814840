import React from 'react';
import moment from 'moment';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import ContractDetails from 'ui/_pages/plan/ContractDetails';
import {useTranslation} from 'react-i18next';
import SelectField from 'ui/components/forms/SelectField';
import TextAreaInputField from 'ui/components/forms/TextAreaInputField';
import {inject, observer} from 'mobx-react';
import Link from 'next/link';
import {routes} from 'env/routes';
import withCustomComponent from 'ui/components/withCustomComponent';

export const CancelContractHeader = withCustomComponent('CancelContractHeader')(
  inject(
    'appStore',
    'invoicingStore'
  )(
    observer(({updateContract, cancelContract, appStore, invoicingStore}) => {
      const {business} = appStore;
      const {contract, isCancellingContract} = invoicingStore;
      const {t} = useTranslation();
      return (
        <>
          <h4>{t('You are about to request to cancel this plan:')}</h4>
          <div className="credentials__box">
            {contract ? (
              <>
                <ContractDetails contract={contract} basic/>

                <hr className="form--divider"/>
                <p className="mxw-600px mt-20">
                  {t(
                    'Knowing why you decided to cancel your membership can help us improve our service and the community of users at {{Name}}. We appreciate your honest feedback.',
                    {Name: t(business.Name)}
                  )}
                </p>
                <div className="card-item-wrapper">
                  <div className="card-item-body">
                    <div className="form-group">
                      <SelectField
                        name="CancellationReason"
                        id="CancellationReason"
                        value={contract.CancellationReason}
                        onChange={(name, value) => updateContract(name, value)}
                        label={t('Main reason to cancel your membership')}
                      >
                        <option></option>
                        <option value="BusinessExpansion">
                          {t('Business expansion / not enough space')}
                        </option>
                        <option value="ChangeWorkEnvironment">
                          {t('Want to change work environment')}
                        </option>
                        <option value="LackCommunityInterations">
                          {t('Lack of community interactions')}
                        </option>
                        <option value="MovedToOtherSpace">
                          {t('Moved to another workspace')}
                        </option>
                        <option value="NewJobRelocation">
                          {t('Relocation / new job')}
                        </option>
                        <option value="OtherMembers">
                          {t('Other members of the community')}
                        </option>
                        <option value="PoorSpaceCondition">
                          {t('Poor space conditions / service')}
                        </option>
                        <option value="PriceTooHigh">
                          {t('Price is too high')}
                        </option>
                        <option value="Rellocated">
                          {t('Just temporarily in this area')}
                        </option>
                        <option value="Other">{t('Other')}</option>
                      </SelectField>
                    </div>
                    <div className="form-group">
                      <TextAreaInputField
                        name="CancellationNotes"
                        id="CancellationNotes"
                        value={contract.CancellationNotes}
                        onChange={(name, value) => updateContract(name, value)}
                        label={t('Any other comments or feedback')}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-end">
                  <Link href={routes.plan}>
                    <a className="btn btn-lg btn-outline">
                      {t('Keep this membership')}
                    </a>
                  </Link>

                  <button
                    onClick={(ev) => {
                      if (isCancellingContract) return;
                      ev.preventDefault();
                      cancelContract();
                    }}
                    type="button"
                    className="ml-md-24 mt-24 mt-md-0 btn btn-lg px-48"
                  >
                    {isCancellingContract ? (
                      <LoadingSpinner/>
                    ) : (
                      t('End membership on {{EarliestCancellationDate}}', {
                        EarliestCancellationDate: moment(
                          contract.EarliestCancellationDate
                        ).format('L'),
                      })
                    )}
                  </button>
                </div>
              </>
            ) : (
              <LoadingSpinner/>
            )}
          </div>
        </>
      );
    })
  )
);
