import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { BlogPostCommentsListItem } from 'ui/_pages/blog/comments/BlogPostCommentsListItem';

export const BlogPostCommentsList = withCustomComponent(
  'BlogPostCommentsList'
)(
  inject(
    'appStore',
    'blogStore'
  )(
    withRouter(
      observer(({ router, appStore, blogStore }) => {
        const { t } = useTranslation();
        const { blogPostPage } = blogStore;
        const blogPost = blogStore.blogPostPage.BlogPost;
        const { business } = appStore;

        return (
          <>
            {blogPost.Comments.length > 0 &&
              <ul className="list-unstyled mb-0">
                <TransitionGroup className="comments-list">
                  {blogPost.Comments.map((comment, i) => (
                    <CSSTransition
                      key={comment.Id}
                      timeout={200}
                      classNames="comment"
                    >
                      <li className="mb-8">
                        <BlogPostCommentsListItem comment={comment} />
                      </li>
                    </CSSTransition>
                  ))}
                </TransitionGroup>
              </ul>
            }
          </>
        );
      })
    )
  )
);
