import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';

@withTranslation()
@inject('appStore')
@observer
class YesNoModalDialog extends Component {
  respondYesNoQuestion = (response) => {
    this.props.appStore.respondYesNoQuestion(response);
  };

  componentDidUpdate(prevProps, prevState) {
    const $ = window.$;
    if (this.props.appStore.yesNoQuestion) {
      $('#modal-yes-no-message').modal({
        backdrop: 'static',
        keyboard: false,
        show: true,
      });
    } else {
      $('#modal-yes-no-message').modal('hide');
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div
        className="modal modal-yes-no-message in"
        id="modal-yes-no-message"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header"></div>
            <div className="modal-body text-center">
              <h4 className="mb-12">
                {this.props.appStore.yesNoQuestion}
              </h4>
              <p className="fs-14 text-gray-700 mb-0">
                {this.props.appStore.yesNoDescription}
              </p>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                className="btn"
                data-dismiss="modal"
                onClick={() => this.respondYesNoQuestion(true)}
                id="close-button"
                type="button"
              >
                {t('Yes')}
              </button>
              <button
                className="btn btn-outline mx-8"
                data-dismiss="modal"
                onClick={() => this.respondYesNoQuestion(false)}
                id="close-button"
                type="button"
              >
                {t('No')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default YesNoModalDialog;
