import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import asForm from 'ui/components/forms/asForm';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import NxMentionsInput from 'ui/components/forms/NxMentionsInput/NxMentionsInput';

export const CoursesPageCommentsFormField = asForm(
  withCustomComponent('CoursesPageCommentsFormField')(
    inject(
      'appStore',
      'authStore',
      'coursesStore'
    )(
      withRouter(
        observer(
          ({
            newComment,
            updateProperty,
            authStore,
            router,
            appStore,
            coursesStore,
            componentName
          }) => {
            const { t } = useTranslation();
            const { isSavingNewComment } = coursesStore;
            const course = coursesStore.coursePage.Course;

            const saveNewMessage = () => {
              coursesStore
                .saveNewComment({ course_id: course.Id })
                .then(() => coursesStore.loadCoursePage(course.Id))
                .catch((err) => {
                  appStore.setPopMessage(
                    t('Sorry, we could not post this comment. {{err}}', { err })
                  );
                });
            };

            return (
              <form data-component-name={componentName} noValidate className="d-flex col mt-20">
                <div className="w-100">
                  <div className="d-flex align-items-start justify-content-between border rounded p-4">
                    <div className="nx-mentions-input-wrapper w-100 pr-16">                              
                      <NxMentionsInput
                        label={null}
                        name="Comment"
                        placeholder={t('Write a comment ...')}
                        errors={coursesStore.newCommentValidation.errors.Comment}
                        value={newComment.Comment}
                        onChange={updateProperty}
                        onKeyDown={(ev) => {
                          if (
                            (ev.keyCode == 10 || ev.keyCode == 13) &&
                            (ev.ctrlKey || ev.metaKey)
                          ) {
                            if (isSavingNewComment) return;
                            ev.preventDefault();
                            saveNewMessage();
                          }
                        }}
                      />
                    </div>
                    <button
                      className="btn "
                      disabled={isSavingNewComment}
                      onClick={(ev) => {
                        if (isSavingNewComment) return;
                        ev.preventDefault();
                        saveNewMessage();
                        ev.target.blur();
                      }}
                    >
                      {isSavingNewComment ? (
                        <LoadingSpinner />
                      ) : (
                          t('Comment')
                        )}
                    </button>
                  </div>
                  <div className="text-gray-600 fs-12 lh-12 pt-4">
                    <small
                      className="d-block text-red"
                      data-bind="validationMessage: message"
                    ></small>
                    <p className="mb-0">
                      {t(
                        "Invite other people to this conversation by mentioning them: type the '@' symbol followed by their name."
                      )}
                    </p>
                  </div>
                </div>
              </form>
            );
          }
        )
      )
    )
  ),
  'newComment'
);
