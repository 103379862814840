import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'next/router';
import DirectoryLayout from 'ui/_pages/directory/DirectoryLayout';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { routes } from 'env/routes';
import { DirectoryZeroCase } from 'ui/_pages/directory/DirectoryZeroCase';
import { Pagination } from 'ui/components/listings/Pagination';
import { withCustomComponent } from 'ui/components/withCustomComponent';
import { TeamsList } from 'ui/_pages/directory/TeamsList';
import { NoAccessNotice } from 'ui/components/NoAccessNotice';

@withCustomComponent('TeamsDirectoryPage')
@withTranslation()
@withRouter
@inject('appStore', 'authStore', 'communityStore')
@observer
class TeamsDirectoryPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    const { t, router, communityStore } = this.props;
    communityStore
      .loadTeamsDirectory({ ...router.query })
      .catch((err) => {
        this.setState({
          error: t("You currently don't have access to the teams directory."),
        });
      })
      .then((data) => {
        if (data.RedirectTo?.indexOf('Members'))
          router.push(routes.directory_members);

        return data;
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { t, router, communityStore } = this.props;
    const prevQuery = prevProps.router.query;
    const currentQuery = router.query;
    if (JSON.stringify(currentQuery) != JSON.stringify(prevQuery))
      communityStore.loadTeamsDirectory({ ...router.query }).catch((err) => {
        this.setState({
          error: t("You currently don't have access to the teams directory."),
        });
      });
  }

  render() {
    const { communityStore, t, appStore, router } = this.props;
    const { business } = appStore;
    const { teamsDirectory, hasLoadedTeamsDirectory, isLoadingTeamsDirectory } =
      communityStore;

    const members = teamsDirectory.TeamsByName;
    const hasLoadedData = hasLoadedTeamsDirectory && !isLoadingTeamsDirectory;

    return (
      <DirectoryLayout
        componentName={this.props.componentName}
        loading={!hasLoadedData}
        topTags={teamsDirectory.TopTags}
        customFields={teamsDirectory.CustomFields}
        showType={false}
        title={t('Teams Directory - {{Name}}', { Name: t(business.Name) })}
      >
        <NoAccessNotice error={this.state.error} />
        {isLoadingTeamsDirectory && <LoadingSpinner />}
        {!isLoadingTeamsDirectory && (
          <>
            <DirectoryZeroCase
              members={members}
              showAllLink={routes.directory_teams}
              text={t('We found no companies')}
              showAllText={t('Show all companies')}
            />
            <TeamsList />
            <Pagination
              page={router.query.page}
              records={members}
              top={router.query.top}
            />
          </>
        )}
      </DirectoryLayout>
    );
  }
}

export default TeamsDirectoryPage;
