import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const HomeTopClaimFeaturesSection = withCustomComponent(
  'HomeTopClaimFeaturesSection'
)(
  inject(
    'appStore',
    'authStore'
  )(
    withRouter(
      observer(({ router, appStore, authStore, componentName }) => {
        const { t } = useTranslation();
        const { configuration, corporateDashboard } = appStore;
        const { customer } = authStore;

        const svgEventsIcon = (primaryColor) => (
          <svg
            width="61px"
            height="61px"
            viewBox="0 0 61 61"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Landing-Page"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Landing-Page_B"
                transform="translate(-266.000000, -1135.000000)"
              >
                <g id="Section" transform="translate(-8.000000, 704.000000)">
                  <g
                    id="Icons/Large/Claps"
                    transform="translate(274.000000, 431.000000)"
                  >
                    <g
                      id="Group-24"
                      transform="translate(21.000000, 2.000000)"
                      stroke={primaryColor}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                    >
                      <path
                        d="M15.867624,55.4721841 C21.2032138,56.1261612 26.7664958,54.3856554 30.8598976,50.2171956 L30.8598976,50.2171956 C35.1455728,45.8556324 37.00506,39.6016553 35.8185301,33.5536553 L32.6763762,17.5698392 C32.3095386,15.7005978 30.6954531,14.354023 28.8207864,14.354023 L28.8207864,14.354023 C26.6501198,14.354023 24.8892992,16.1460231 24.8892992,18.3551265 L24.8892992,22.1734254"
                        id="Path"
                      ></path>
                      <line
                        x1="22.971624"
                        y1="1.95678162"
                        x2="20.2393163"
                        y2="4.73747128"
                        id="Path"
                      ></line>
                      <line
                        x1="-3.70757556e-14"
                        y1="1.95678162"
                        x2="2.52991454"
                        y2="4.53149427"
                        id="Path"
                      ></line>
                      <line
                        x1="11.485812"
                        y1="2.44830975e-13"
                        x2="11.485812"
                        y2="3.63034484"
                        id="Path"
                      ></line>
                      <path
                        d="M17.8611967,18.1234024 L13.261812,13.4425748 C11.7792821,11.9337932 9.37586329,11.9337932 7.89586328,13.4425748 L7.89586328,13.4425748 C6.41333336,14.9513564 6.41333336,17.3973334 7.89586328,18.9035403"
                        id="Path"
                      ></path>
                    </g>
                    <g id="Group">
                      <polygon
                        id="Path"
                        points="0 0 61.0000003 0 61.0000003 61.0000003 0 61.0000003"
                      ></polygon>
                      <path
                        d="M17.2375834,20.1732084 C15.7481667,18.6837917 15.7481667,16.2692084 17.2375834,14.7823334 L17.2375834,14.7823334 C18.7270001,13.2929167 21.1415834,13.2929167 22.6284584,14.7823334 L32.5130001,24.6668751 L37.9038752,30.0577501 L37.9038752,23.2232084 C37.9038752,21.0424584 39.6728752,19.2734584 41.8536252,19.2734584 L41.8536252,19.2734584 C43.7370002,19.2734584 45.3585835,20.6027501 45.7271252,22.4480001 L48.5712502,36.6737085 C49.7658335,42.6440835 47.8977085,48.8177919 43.5895835,53.1233752 L43.5895835,53.1233752 C36.4780002,60.2349586 24.9464584,60.2349586 17.8348751,53.1233752 L10.9469584,46.2329169 L4.65633335,39.9422918 C3.16691668,38.4528752 3.16691668,36.0382918 4.65633335,34.5514168 L4.65633335,34.5514168 C6.14575003,33.0620001 8.56033337,33.0620001 10.0472084,34.5514168"
                        id="Path"
                        stroke="#999999"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M18.1373334,42.6390002 L6.45583336,30.9575001 C4.96641669,29.4680835 4.96641669,27.0535001 6.45583336,25.5666251 L6.45583336,25.5666251 C7.94525003,24.0772084 10.3598334,24.0772084 11.8467084,25.5666251"
                        id="Path"
                        stroke="#999999"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M23.5282084,37.2455835 L8.2527917,21.9701668 C6.76337503,20.4807501 6.76337503,18.0661667 8.2527917,16.5792917 L8.2527917,16.5792917 C9.74220837,15.0898751 12.1567917,15.0898751 13.6436667,16.5792917 L28.9190835,31.8547085"
                        id="Path"
                        stroke="#999999"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        );

        const svgDirectoryIcon = (primaryColor) => (
          <svg
            width="64px"
            height="46px"
            viewBox="0 0 64 46"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Landing-Page"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <g
                id="Landing-Page_B"
                transform="translate(-476.000000, -1141.000000)"
                strokeWidth="3"
              >
                <g id="Section" transform="translate(-8.000000, 704.000000)">
                  <g id="Group" transform="translate(486.000000, 439.000000)">
                    <circle
                      id="Oval"
                      stroke="#999999"
                      cx="18.3333333"
                      cy="10.3533333"
                      r="10"
                    ></circle>
                    <circle
                      id="Oval"
                      stroke={primaryColor}
                      fill={primaryColor}
                      cx="43.3333333"
                      cy="13.6866667"
                      r="6.66666667"
                    ></circle>
                    <path
                      d="M0,42 C0,35.5666667 5.21333333,30.3533333 11.6466667,30.3533333 L25.02,30.3533333 C31.4533333,30.3533333 36.6666667,35.5666667 36.6666667,42"
                      id="Path"
                      stroke="#999999"
                    ></path>
                    <path
                      d="M43.3333333,30.3533333 L48.3533333,30.3533333 C54.7866667,30.3533333 60,35.5666667 60,42 L60,42"
                      id="Path"
                      stroke={primaryColor}
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        );

        const svgBookingsIcon = (primaryColor) => (
          <svg
            width="70px"
            height="70px"
            viewBox="0 0 70 70"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Landing-Page"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Landing-Page_B"
                transform="translate(-685.000000, -1130.000000)"
              >
                <g id="Section" transform="translate(-8.000000, 704.000000)">
                  <g id="Path" transform="translate(693.000000, 426.000000)">
                    <polygon points="0 0 69.9453555 0 69.9453555 69.9453555 0 69.9453555"></polygon>
                    <line
                      x1="43.1511842"
                      y1="6.48451753"
                      x2="43.1511842"
                      y2="13.1511842"
                      stroke="#999999"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></line>
                    <line
                      x1="18.7067398"
                      y1="6.48451753"
                      x2="18.7067398"
                      y2="13.1511842"
                      stroke="#999999"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></line>
                    <line
                      x1="6.48451753"
                      y1="24.8178509"
                      x2="55.3734064"
                      y2="24.8178509"
                      stroke="#999999"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></line>
                    <path
                      d="M55.3734064,29.1511842 L55.3734064,18.928962 C55.3734064,13.8965916 51.2697953,9.81785087 46.2067398,9.81785087 L15.6511842,9.81785087 C10.5881286,9.81785087 6.48451753,13.8965916 6.48451753,18.928962 L6.48451753,46.2622953 C6.48451753,51.2946657 10.5881286,55.3734064 15.6511842,55.3734064 L28.9845175,55.3734064"
                      stroke="#999999"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M51.0018217,64.1165759 C43.759563,64.1165759 37.8870676,58.2469948 37.8870676,51.0018217 C37.8870676,43.9081969 43.9198545,37.8783244 51.0134793,37.8870581 C58.2528236,37.8928963 64.1165759,43.7624774 64.1165759,51.0018217 C64.1165759,58.2440804 58.2469948,64.1165759 51.0018217,64.1165759"
                      stroke={primaryColor}
                      strokeWidth="1.5"
                      fill={primaryColor}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <polyline
                      stroke="#FFFFFF"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      points="50.689982 46.5806924 50.689982 51.7041897 54.7176687 54.1610203"
                    ></polyline>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        );

        const svgMyAccountIcon = (primaryColor) => (
          <svg
            width="72px"
            height="72px"
            viewBox="0 0 72 72"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Landing-Page"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Landing-Page_B"
                transform="translate(-1108.000000, -1129.000000)"
              >
                <g id="Section" transform="translate(-8.000000, 704.000000)">
                  <g
                    id="Icons/Large/Members"
                    transform="translate(1116.000000, 425.000000)"
                  >
                    <g
                      id="Group-23"
                      transform="translate(1.875000, 25.667990)"
                      stroke={primaryColor}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                    >
                      <path
                        d="M16.3304401,10.3604198 C13.9603471,12.7305128 10.11768,12.7305128 7.74759003,10.3604198 C5.37749702,7.99032678 5.37749702,4.14765977 7.74759003,1.77756976 C10.117683,-0.592523252 13.9603501,-0.592523252 16.3304401,1.77756976 C18.7005331,4.14766277 18.7005331,7.99032978 16.3304401,10.3604198 Z"
                        id="Path"
                        fill={primaryColor}
                      ></path>
                      <path
                        d="M0,31.3320099 L0,28.0440098 C0,23.9010098 3.35700001,20.5440098 7.5,20.5440098 L9.90300004,20.5440098"
                        id="Path"
                      ></path>
                    </g>
                    <g
                      id="Group-22"
                      transform="translate(19.980000, 13.875000)"
                      stroke="#999999"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                    >
                      <path
                        d="M32.0370001,43.1250002 L32.0370001,38.3220001 C32.0370001,32.5230001 27.3360001,27.8220001 21.5370001,27.8220001 L10.5,27.8220001 C4.70100002,27.8220001 0,32.5230001 0,38.3220001 L0,43.1250002"
                        id="Path"
                      ></path>
                      <path
                        d="M22.6278001,15.9529201 C18.9783601,19.6023601 13.0614301,19.6023601 9.41196004,15.9529201 C5.76252002,12.3034801 5.76252002,6.38655003 9.41196004,2.73708001 C13.0614001,-0.912360004 18.9783301,-0.912360004 22.6278001,2.73708001 C26.2772401,6.38652003 26.2772401,12.3034501 22.6278001,15.9529201 Z"
                        id="Path"
                      ></path>
                    </g>
                    <g
                      id="Group-21"
                      transform="translate(52.017080, 25.667990)"
                      stroke="#999999"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                    >
                      <path
                        d="M18.1079198,31.3320099 L18.1079198,28.0440098 C18.1079198,23.9010098 14.7509198,20.5440098 10.6079198,20.5440098 L8.20491978,20.5440098"
                        id="Path"
                      ></path>
                      <path
                        d="M10.3604198,10.3604198 C7.99032678,12.7305128 4.14765977,12.7305128 1.77756976,10.3604198 C-0.592523252,7.99032678 -0.592523252,4.14765977 1.77756976,1.77756976 C4.14766277,-0.592523252 7.99032978,-0.592523252 10.3604198,1.77756976 C12.7305128,4.14766277 12.7305128,7.99032978 10.3604198,10.3604198 Z"
                        id="Path"
                      ></path>
                    </g>
                    <polygon
                      id="Path"
                      points="-1.50000051e-07 -1.50000051e-07 72.0000001 -1.50000051e-07 72.0000001 72.0000001 -1.50000051e-07 72.0000001"
                    ></polygon>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        );

        const svgAboutUsIcon = (primaryColor) => (
          <svg width="72" height="72" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd">
              <g transform="translate(7 9)" stroke-linecap="round" stroke-linejoin="round" stroke-width="3">
                <path d="M0,53.4193548 L0,38.3278571 C0,37.0422172 0.824542252,36 1.84166667,36 L13,36 M0,53.4193548 L13,53.4193548" stroke={primaryColor}/>
                <line x1="38.5" x2="38.5" y2="9" stroke="#999"/><path d="M29,20 L29,12.6666667 C29,10.6416226 30.7013179,9 32.8,9 L44.2,9 C46.2986819,9 48,10.6416226 48,12.6666667 L48,20" stroke="#999"/>
                <path d="M6,36 L6,31.3333333 C6,30.0446689 7.04809406,29 8.34098361,29 L13,29" stroke={primaryColor}/>
                <path d="M19,53 L19,24.409996 C19,21.9744227 20.7013179,20 22.8,20 L53.2,20 C55.2986819,20 57,21.9744227 57,24.409996 L57,53" stroke="#999"/>
                <line x1="29" y1="32.5" x2="48" y2="32.5" stroke="#999"/>
                <line x1="19" y1="53.5" x2="57" y2="53.5" stroke="#999"/>
                <line x1="29" y1="42.5" x2="48" y2="42.5" stroke="#999"/>
                <line x1="6" y1="45.5" x2="13.5" y2="45.5" stroke={primaryColor}/>
              </g>
              <rect fill={primaryColor} x="13.5" y="38" width="8" height="7"/>
            </g>
          </svg>
        );

        return (
          (configuration['HomePage.ShowClaimBar'] ||
            configuration['HomePage.ShowIconBar']) && (
            <div
              data-component-name={componentName}
              className={`home-top-claim-features-section ${
                corporateDashboard ? 'pt-0 pb-40' : ''
              }`}
            >
              <div className="container">
                {!corporateDashboard && configuration['HomePage.ShowClaimBar'] && (
                  <div className="home-top-claim-features-section__claim">
                    {configuration['HomePage.ClaimText'] && (
                      <h2 className="h1">
                        {t(configuration['HomePage.ClaimText'])}
                      </h2>
                    )}
                    {configuration['HomePage.ClaimSmallTextLogout'] && (
                      <Link href={routes.login}>
                        <a>
                          {t(configuration['HomePage.ClaimSmallTextLogout'])}
                        </a>
                      </Link>
                    )}
                  </div>
                )}
                {configuration['HomePage.ShowIconBar'] && (
                  <div className="home-top-claim-features-section__features">
                    <ul className="features-list">
                      {configuration['HomePage.AboutUsIcon'] &&
                      configuration['PublicWebSite.AboutUs'] && (
                        <li>
                          <Link href={routes.about}>
                            <a>
                              <div className="img-wrapper">
                                {svgAboutUsIcon('var(--bg-color)')}
                              </div>
                              <h4>
                                {t('About us')}
                              </h4>
                            </a>
                          </Link>
                        </li>
                      )}
                      {!corporateDashboard &&
                        configuration['HomePage.EventsIcon'] &&
                        configuration['PublicWebSite.Events'] && (
                          <li>
                            <Link href={routes.events}>
                              <a>
                                <div className="img-wrapper">
                                  {svgEventsIcon('var(--bg-color)')}
                                </div>
                                <h4>{t('Events')}</h4>
                              </a>
                            </Link>
                          </li>
                        )}
                      {configuration['HomePage.DirectoryIcon'] &&
                        configuration['PublicWebSite.Directory'] && (
                          <li>
                            <Link href={routes.directory_members}>
                              <a>
                                <div className="img-wrapper">
                                  {svgDirectoryIcon('var(--bg-color)')}
                                </div>
                                <h4>
                                  {corporateDashboard
                                    ? t('Directory')
                                    : t('Members')}
                                </h4>
                              </a>
                            </Link>
                          </li>
                        )}
                      {configuration['HomePage.BookingsIcon'] && (
                        <li>
                          <Link href={routes.resources}>
                            <a>
                              <div className="img-wrapper">
                                {svgBookingsIcon('var(--bg-color)')}
                              </div>
                              <h4>{t('Bookings')}</h4>
                            </a>
                          </Link>
                        </li>
                      )}
                      {configuration['HomePage.MyAccountIcon'] && (
                        <li>
                          <Link href={routes.forum}>
                            <a>
                              <div className="img-wrapper">
                                {svgMyAccountIcon('var(--bg-color)')}
                              </div>
                              <h4>
                                {corporateDashboard
                                  ? t('Discussion boards')
                                  : t('Community')}
                              </h4>
                            </a>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )
        );
      })
    )
  )
);
