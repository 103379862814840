/**
 * asForm Higher Order Component
 */
 import React from 'react';
 import { action } from 'mobx';
  
const asForm = (MyComponent, formDataProp) => (props) => {
  const updateProperty = action((key, value) => {
    props[formDataProp][key] = value;
  });
 
  return (
    <MyComponent {...props} updateProperty={updateProperty} />
  );
 };

 export default asForm;
