import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import dynamic from 'next/dynamic';
import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  BarChart,
  Bar,
  YAxis,
} from 'recharts';
import { adjustBrightness } from 'env/utils/cssHelpers';

@withCustomComponent('MetricsWidget')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class MetricsWidget extends Component {
  render() {
    const {
      t,
      title,
      small,
      appStore,
      formatter,
      authStore,
      metrics,
      teamName,
    } = this.props;
    const { customer } = authStore;

    const { configuration } = appStore;
    return (
      <div data-component-name={this.props.componentName}>
        {title && <h6>{title}</h6>}
        <ResponsiveContainer width={'100%'} height={300}>
          <BarChart
            data={metrics}
            margin={{ top: 15, right: 20, left: 0, bottom: 15 }}
          >
            <XAxis dataKey="date" tick={{ fontSize: 14 }} />
            <YAxis formatter={formatter} tick={{ fontSize: 14 }} />
            <CartesianGrid vertical={false} />
            <Tooltip formatter={formatter} />
            <Legend />

            <Bar
              type="monotone"
              name={t('Me')}
              dataKey="customer"
              fill={configuration['PrimaryWebColor']}
              radius={6}
            />

            {teamName && (
              <Bar
                type="monotone"
                name={teamName}
                dataKey="team"
                fill={adjustBrightness(configuration['PrimaryWebColor'], 40)}
                radius={6}
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
export default MetricsWidget;
