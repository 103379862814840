import React, { Component } from 'react';
import Link from 'next/link';
import { withTranslation } from 'react-i18next';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const HomePageDashboardHeader = withCustomComponent('DashboardHeader')(
  inject(
    'appStore',
    'authStore'
  )(
    withRouter(
      observer(({ router, appStore, authStore, componentName }) => {
        const { t } = useTranslation();
        const { customer } = authStore;
        const { configuration } = appStore;
        return (
          <div data-component-name={componentName} className="container">
            <div className="filters filters--borderless filters--marginless filters--lg">
              <ul className="nav filters__nav filters__nav--centered">
                <li className={router.pathname === '/' ? 'active' : null}>
                  <Link href={'/'}>
                    <a>{t('My dashboard')}</a>
                  </Link>
                </li>
                {customer?.TeamNames && (
                  <li
                    className={
                      router.pathname === routes.team_dashboard
                        ? 'active'
                        : null
                    }
                  >
                    <Link href={routes.team_dashboard}>
                      <a>{t('Team dashboard')}</a>
                    </Link>
                  </li>
                )}
                {configuration['PublicWebSite.CommunityBoard'] && (
                  <li
                    className={
                      router.pathname === routes.community_dashboard
                        ? 'active'
                        : null
                    }
                  >
                    <Link href={routes.community_dashboard}>
                      <a>{t('Community')}</a>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        );
      })
    )
  )
);
