import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import moment from 'moment';
export const CommunityBoardItemMetadata = withCustomComponent(
  'CommunityBoardItemMetadata'
)(
  inject('appStore')(
    withRouter(
      observer(({ thread, componentName }) => {
        const { t } = useTranslation();

        return (
          <div
            data-component-name={componentName}
            className="community-board-item-metadata"
          >
            <time
              dateTime={moment(thread.CreatedOnUtc).locale('en').format()}
              className="timeago"
            >
              {moment(thread.CreatedOnUtc + 'Z').format('L')}
            </time>{' '}
            {t('by')}{' '}
            <Link
              href={{
                pathname: routes.directory_member_by_id('[member_id]'),
              }}
              as={{
                pathname: routes.directory_member_by_id({
                  Id: thread.CoworkerId,
                  FullName: thread.FullName,
                }),
              }}
            >
              <a>{thread.FullName}</a>
            </Link>
          </div>
        );
      })
    )
  )
);
