import React, { Component } from 'react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'next/router';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import Link from 'next/link';
import { routes } from 'env/routes';
import _ from 'lodash';

import { DirectoryZeroCase } from 'ui/_pages/directory/DirectoryZeroCase';
import { MembersList } from 'ui/_pages/directory/MembersList';
import { Pagination } from 'ui/components/listings/Pagination';

@withCustomComponent('OurTeam')
@withTranslation()
@withRouter
@inject('appStore', 'communityStore')
@observer
class OurTeam extends Component {
  componentDidMount() {
    const { t, router, communityStore } = this.props;
    communityStore
      .loadMembersDirectory({ ...router.query, onlyAdmins: true })
      .then((data) => {
        return data;
      })
      .catch((err) => {});
  }

  render() {
    const { t, communityStore, router } = this.props;
    const {
      membersDirectory,
    } = communityStore;
    const members = membersDirectory.AllMembersByName;

    const svgTopIcon = (primaryColor) => (
      <svg width="72px" height="72px" version="1.1" viewBox="0 0 72 72">
        <g>
          <g id="Group-23" transform="translate(1.875000, 25.667990)">
            <path
              fill={primaryColor}
              d="M17.4,0.7c-3-3-7.8-3-10.7,0C5.3,2.1,4.5,4,4.5,6.1s0.8,3.9,2.2,5.4c1.5,1.5,3.4,2.2,5.4,2.2s3.9-0.7,5.4-2.2c1.4-1.4,2.2-3.3,2.2-5.4S18.8,2.1,17.4,0.7z"
            />
            <path
              fill={primaryColor}
              d="M0,32.8c-0.8,0-1.5-0.7-1.5-1.5V28c0-5,4-9,9-9h2.4c0.8,0,1.5,0.7,1.5,1.5S10.7,22,9.9,22H7.5c-3.3,0-6,2.7-6,6v3.3C1.5,32.2,0.8,32.8,0,32.8z"
            />
          </g>
          <g id="Group-22" transform="translate(19.980000, 13.875000)">
            <path
              fill="#999999"
              d="M32,44.6c-0.8,0-1.5-0.7-1.5-1.5v-4.8c0-5-4-9-9-9h-11c-5,0-9,4-9,9v4.8c0,0.8-0.7,1.5-1.5,1.5s-1.5-0.7-1.5-1.5v-4.8c0-6.6,5.4-12,12-12h11c6.6,0,12,5.4,12,12v4.8C33.5,44,32.9,44.6,32,44.6z"
            />
            <path
              fill="#999999"
              d="M16,20.2c-2.8,0-5.6-1.1-7.7-3.2c-4.2-4.2-4.2-11.1,0-15.3c2-2,4.8-3.2,7.7-3.2c2.9,0,5.6,1.1,7.7,3.2c4.2,4.2,4.2,11.1,0,15.3l0,0C21.6,19.1,18.8,20.2,16,20.2z M16,1.5c-2.1,0-4.1,0.8-5.5,2.3c-3.1,3.1-3.1,8,0,11.1c3.1,3.1,8,3.1,11.1,0c3.1-3.1,3.1-8,0-11.1C20.1,2.3,18.1,1.5,16,1.5z M22.6,16L22.6,16L22.6,16z"
            />
          </g>
          <g id="Group-21" transform="translate(52.017080, 25.667990)">
            <path
              fill="#999999"
              d="M18.1,32.8c-0.8,0-1.5-0.7-1.5-1.5V28c0-3.3-2.7-6-6-6H8.2c-0.8,0-1.5-0.7-1.5-1.5S7.4,19,8.2,19h2.4c5,0,9,4,9,9v3.3C19.6,32.2,18.9,32.8,18.1,32.8z"
            />
            <path
              fill="#999999"
              d="M6.1,13.6c-1.9,0-3.9-0.7-5.4-2.2C-0.7,10-1.5,8.1-1.5,6.1s0.8-3.9,2.2-5.4c3-3,7.8-3,10.7,0c1.4,1.4,2.2,3.3,2.2,5.4s-0.8,3.9-2.2,5.4C9.9,12.9,8,13.6,6.1,13.6z M6.1,1.5c-1.2,0-2.3,0.4-3.2,1.3C2,3.7,1.5,4.8,1.5,6.1c0,1.2,0.5,2.4,1.3,3.2c1.8,1.8,4.7,1.8,6.5,0c0.9-0.9,1.3-2,1.3-3.2c0-1.2-0.5-2.4-1.3-3.2C8.4,1.9,7.2,1.5,6.1,1.5z"
            />
          </g>
        </g>
      </svg>
    );

    if (!(members?.length > 0)) return <></>;

    return (
      <div
        data-component-name={this.props.componentName}
        className="about-page-our-team"
      >
        <div className="container">
          <div className="intro">
            {svgTopIcon('var(--bg-color)')}
            <h2>{t('Our Team')}</h2>
          </div>

          <>
            <DirectoryZeroCase
              members={members}
              showAllLink={routes.directory_members}
              text={t('We found no members')}
              showAllText={t('Show all members')}
            />
            <MembersList />
            <Pagination
              page={router.query.page}
              records={members}
              top={router.query.top}
            />
          </>
        </div>
      </div>
    );
  }
}
export default OurTeam;
