import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import moment from 'moment';
export const CommunityBoardItemLastUpdatedBy = withCustomComponent(
  'CommunityBoardItemLastUpdatedBy'
)(
  inject('appStore')(
    withRouter(
      observer(({ thread, componentName }) => {
        const { t } = useTranslation();

        return (
          <div
            data-component-name={componentName}
            className="community-board-item-last-updated-by text-gray-600 fs-14"
          >
            {thread.LastMessageCoworkerId > 0 && (
              <>
                <Link
                  href={routes.thread_by_id('[thread_id]')}
                  as={routes.thread_by_id(thread)}
                >
                  <a className="text-gray-600">{t('Last reply')}</a>
                </Link>{' '}
                <time
                  className="timeago"
                  dateTime={moment(thread.LastMessage).locale('en').format()}
                >
                  {moment(thread.LastMessage).format('L')}
                </time>{' '}
                {t('by')}{' '}
                <Link
                  href={{
                    pathname: routes.directory_member_by_id('[member_id]'),
                  }}
                  as={{
                    pathname: routes.directory_member_by_id({
                      Id: thread.LastMessageCoworkerId,
                      FullName: thread.LastMessageUserFullName,
                    }),
                  }}
                >
                  <a className="">
                    {thread.LastMessageUserFullName}
                  </a>
                </Link>
              </>
            )}
            {!thread.LastMessageCoworkerId > 0 && (
              <Link
                href={routes.thread_by_id('[thread_id]')}
                as={routes.thread_by_id(thread)}
              >
                <a className="text-gray-600">
                  {t('Be the first one to leave a reply')}
                </a>
              </Link>
            )}
          </div>
        );
      })
    )
  )
);
