import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import Head from 'next/head';

@withCustomComponent('ProposalPageLayout')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class ProposalPageLayout extends Component {
  render() {
    const { t, title, description, appStore, authStore } = this.props;
    const { business } = appStore;

    return (
      <div data-component-name={this.props.componentName}>
        <Head>
          <meta charSet="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <title>
            {title} - {t(business.Name)}
          </title>
        </Head>

        <header className="site-header site-header--lo">
          <div className="site-header__content">
            <nav className="navbar container">
              <a className="navbar-brand" href="/">
                <img
                  src={`${business.NativeHomeUrlWithLanguage}/business/getlogo?h=96&mode=pad`}
                  alt={t(business.Name)}
                  height="48"
                />
                <div className="spacer"></div>
                <span>{t(business.Name)}</span>
              </a>
            </nav>
          </div>
        </header>

        <main className="content content--proposal" role="main">
          {(title || description) && (
            <div className="bg-white pt-48 pb-32">
              <div className="container">
                {title && <h1 className="mb-24 mxw-540px">{title}</h1>}
                {description && (
                  <p className="fs-18 fw-500 lh-13 mxw-600px">{description}</p>
                )}
              </div>
            </div>
          )}

          {this.props.children}
        </main>
      </div>
    );
  }
}

export default ProposalPageLayout;
