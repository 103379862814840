/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import { apiRoot, platformRoot } from 'env/config';

import withScript from 'env/utils/withScript';

@withCustomComponent('KlarnaPaymentButton')
@withTranslation()
@inject('appStore', 'authStore')
class KlarnaPaymentButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      widgetShown: false,
      authorizing: false,
      processing: false,
    };

    this.authorizePayment = this.authorizePayment.bind(this);
    this.processPayment = this.processPayment.bind(this);
  }
  processPayment() {
    const { appStore, invoice } = this.props;
    const { business } = appStore;
    this.setState({ processing: true });

    const url = `/invoices/getKlarnaSession?invoiceId=${invoice.Id}`;
    const agent = appStore.getAgent();
    agent.requests
      .get(url)
      .then((invoice) => {
        if (invoice?.KlarnaSessionId) {
          this.setState({ widgetShown: true });

          Klarna.Payments.init({
            client_token: invoice.KlarnaSessionId,
          });

          Klarna.Payments.load(
            {
              container: `#klarna-payments-container-${invoice.Id}`,
            },
            function (res) {
              //noop
            }
          );

          this.setState({ processing: false });
        } else {
          appStore
            .setPopMessage(
              t(
                `Unknown error while processing your payment. Please try again.`
              )
            )
            .then(() => location.reload());
        }
      })
      .catch((err) => {
        appStore
          .setPopMessage(
            t(`Unknown error while processing your payment. Please try again.`)
          )
          .then(() => location.reload());
      });
  }

  authorizePayment() {
    const { invoice, appStore } = this.props;
    Klarna.Payments.authorize({}, null, (result) => {
      if (result.approved && result.authorization_token) {
        const url = `/payments/klarna?authorizationToken=${result.authorization_token}&invoiceUniqueId=${invoice.UniqueId}`;
        const agent = appStore.getAgent();
        agent.requests
          .post(url)
          .then((result) => {
            if (result.RedirectURL) {
              window.location.href = result.RedirectURL;
            } else {
              appStore
                .setPopMessage(
                  t(
                    `Unknown error while processing your payment. Please try again. (${result.FailedResultReason})`
                  )
                )
                .then(() => location.reload());
            }
          })
          .catch((err) => {
            appStore
              .setPopMessage(
                t(
                  `Unknown error while processing your payment. Please try again.`
                )
              )
              .then(() => location.reload());
          });
      }
    });
  }

  render() {
    const { t, invoice, componentName } = this.props;

    return (
      <>
        {!this.state.widgetShown && (
          <a
            href="#"
            data-component-name={componentName}
            className="btn btn-icon btn-link text-gray-900 mb-20 ml-20 "
            onClick={() => !this.state.processing && this.processPayment()}
          >
            <i aria-hidden="true" className="icon-credit-card"></i>{' '}
            {this.state.processing
              ? t('Loading, please wait...')
              : t('Pay with Klarna')}
          </a>
        )}
        {
          <tr>
            <td colSpan={5}>
              <div id={`klarna-payments-container-${invoice.Id}`}></div>
              {this.state.widgetShown && (
                <>
                  {' '}
                  <a
                    href="#"
                    data-component-name={componentName}
                    className="btn btn-icon text-gray-900 mt-10"
                    onClick={this.authorizePayment}
                  >
                    <i aria-hidden="true" className="icon-credit-card"></i>{' '}
                    {this.state.authorizing
                      ? t('Completing purchase...')
                      : t('Complete payment')}
                  </a>
                  <div className="mb-20"></div>
                </>
              )}
            </td>
          </tr>
        }
      </>
    );
  }
}
export default KlarnaPaymentButton;
