import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import ProfilePageLayout from 'ui/layouts/ProfilePageLayout';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { privateRoute } from 'env/privateRoute';
import { withRouter } from 'next/router';
import ResponsiveTable from 'ui/components/tables/ResponsiveTable';
import moment from 'moment';
import DeliveryImageModal from 'ui/_pages/deliveries/DeliveryImageModal';
import { withCustomComponent } from 'ui/components/withCustomComponent';
import Head from 'next/head';

@withCustomComponent('DeliveriesPage')
@privateRoute
@withRouter
@withTranslation()
@inject('appStore', 'authStore', 'deliveriesStore')
@observer
class DeliveriesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDelivery: null,
    };
  }

  componentDidMount() {
    const { t, appStore, deliveriesStore } = this.props;
    deliveriesStore.loadMyDeliveries().catch(() => {
      appStore.setPopMessage(t('Could not load your deliveries.'));
    });
  }
  render() {
    const { t, appStore, deliveriesStore } = this.props;

    const { myDeliveries, hasLoadedMyDeliveries } = deliveriesStore;

    const { authStore } = this.props;
    const { customer } = authStore;

    const hasLoadedData = hasLoadedMyDeliveries;
    const deliveries =
      hasLoadedMyDeliveries &&
      _.orderBy(
        myDeliveries?.Deliveries ?? [],
        ['Collected', 'CreatedOn'],
        ['asc', 'desc']
      );

    return (
      <ProfilePageLayout componentName={this.props.componentName}>
        <Head>
          <title>
            {t('My deliveries')} - {t(appStore.business.Name)}
          </title>
        </Head>
        <>
          {!hasLoadedData && (
            <form id="profile-form">
              <fieldset className="section--settings" id="your-plan">
                <legend className="h3 section__title">
                  <LoadingSpinner />
                </legend>
              </fieldset>
            </form>
          )}
          {hasLoadedData && (
            <form className="profile-form">
              {this.state.showDelivery && (
                <DeliveryImageModal
                  close={(ev) => {
                    ev.preventDefault();
                    this.setState({ showDelivery: null });
                  }}
                  delivery={this.state.showDelivery}
                />
              )}
              <div className="card card-dashboard mb-32">
                <fieldset>
                  <h5 className="section__title mb-8">{t('My deliveries')}</h5>
                  <p className="mxw-500px fs-14 text-gray-700 lh-14 mb-20">
                    <>
                      {customer.BillingName != customer.FullName &&
                        t(
                          'To make sure we can match your deliveries correctly, make sure they are addressed to "{{BillingName}}" or "{{FullName}}".',
                          {
                            BillingName: customer.BillingName,
                            FullName: customer.FullName,
                          }
                        )}
                      {customer.BillingName == customer.FullName &&
                        t(
                          'To make sure we can match your deliveries correctly, make sure they are addressed to "{{FullName}}".',
                          {
                            FullName: customer.FullName,
                          }
                        )}
                    </>
                  </p>

                  {deliveries.length === 0 && (
                    <p className="alert alert-info">
                      <b>{t('You have no deliveries.')}</b>
                    </p>
                  )}

                  {deliveries.length > 0 && (
                    <ResponsiveTable>
                      <thead>
                        <tr>
                          <th>{t('Reference')}</th>
                          <th>{t('Location')}</th>
                          <th>{t('Date')}</th>
                          <th>{t('Status')}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <>
                          {deliveries.map((delivery, i) => {
                            return (
                              <tr key={i}>
                                <td valign="middle">
                                  <div className="py-0 py-md-8">
                                    <div className="fw-500">
                                      {delivery.Name}
                                    </div>
                                    {delivery.Notes && (
                                      <small className="mt-2 fs-14">
                                        {delivery.Notes}
                                      </small>
                                    )}
                                  </div>
                                </td>
                                <td valign="middle">{delivery.Location}</td>
                                <td valign="middle">
                                  <div className="py-0 py-md-8">
                                    {t('Received on {{CreatedOn}}', {
                                      CreatedOn: moment(
                                        delivery.CreatedOnUtc
                                      ).format('L'),
                                    })}
                                    {!delivery.Collected &&
                                      delivery.RequiresSignature && (
                                        <small className="mt-2 fs-14">
                                          {t('Requires signature')}
                                        </small>
                                      )}
                                  </div>
                                </td>
                                <td valign="middle">
                                  {!delivery.Collected && (
                                    <span className="tag with-icon-left m-0 d-inline-flex bg-red-200 pl-8 pl-xl-4">
                                      <i className="icon-alert d-none d-xl-block"></i>
                                      <span>{t('Pending')}</span>
                                    </span>
                                  )}
                                  {delivery.Collected && (
                                    <span className="tag with-icon-left m-0 d-inline-flex bg-green-200 pl-8 pl-xl-4">
                                      <i className="icon-check d-none d-xl-block"></i>
                                      <span>{t('Collected')}</span>
                                    </span>
                                  )}
                                </td>
                                <td
                                  valign="middle"
                                  className="text-left text-md-right"
                                >
                                  {delivery.HasImage && (
                                    <a
                                      href="#"
                                      className="btn btn-link btn-xs text-gray-900 mb-2"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        this.setState({
                                          showDelivery: delivery,
                                        });
                                      }}
                                      src={delivery.ImageUrl}
                                    >
                                      <i className="icon-social-instagram mr-4 fs-18 d-none d-xl-block"></i>
                                      {t('View details')}
                                    </a>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      </tbody>
                    </ResponsiveTable>
                  )}
                </fieldset>
              </div>
            </form>
          )}
        </>
      </ProfilePageLayout>
    );
  }
}

export default DeliveriesPage;
