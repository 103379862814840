import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { CoworkerAvatar } from 'ui/components/CoworkerAvatar';
export const RelatedProfile = withCustomComponent('RelatedProfile')(
  inject('appStore')(
    withRouter(
      observer(({ componentName, router, appStore, member, className }) => {
        const { t } = useTranslation();
        const { business } = appStore;

        return (
          <div
            data-component-name={componentName}
            className={`media related-profile ${className}`}
          >
            <CoworkerAvatar
              link={true}
              title={member.FullName}
              coworker_id={member.Id}
              size={96}
              imgClassName="avatar avatar--48"
            />
            <div className="media-body ml-8">
              <Link
                href={{
                  pathname: routes.directory_member_by_id('[member_id]'),
                  query: {
                    ...router.query,
                    page: 1,
                  },
                }}
                as={{
                  pathname: routes.directory_member_by_id(member),
                  query: {
                    ...router.query,
                    page: 1,
                  },
                }}
              >
                <a className="text-gray-900">{member.FullName}</a>
              </Link>
              <div className="fs-14 text-gray-600">
                {member.Position}
                {member.Position && member.CompanyName && <> {t('at')} </>}
                {member.CompanyName}
              </div>
            </div>
          </div>
        );
      })
    )
  )
);
