/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
@withCustomComponent('DeliveryImageModal')
@withTranslation()
@observer
class DeliveryImageModal extends Component {
  componentDidMount() {
    window.$('#delivery-form').modal({
      backdrop: 'static',
      keyboard: false,
      show: true,
    });
  }

  componentWillUnmount() {
    window.$('#delivery-form').modal('hide');
  }

  render() {
    const { t, delivery, componentName } = this.props;
    return (
      <div
        data-component-name={componentName}
        className="modal"
        id="delivery-form"
        role="dialog"
        aria-labelledby="modal-bookLabel"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <button className="close" onClick={this.props.close}>
              <i className="icon-close"></i>
            </button>
            <div className="modal-header">
              <h4 className="m-0" id="modal-bookLabel">
                {t('Delivery scan')}
              </h4>
            </div>
            <div className="modal-body">
              <img
                style={{ maxWidth: '100%' }}
                alt={delivery.Name}
                src={delivery.ImageUrl}
              />
            </div>
            <div className="modal-footer">
              <a
                onClick={this.props.close}
                href="#"
                className="btn"
              >
                {t('Close')}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DeliveryImageModal;
