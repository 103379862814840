import {useStaticRendering} from 'mobx-react';
import AppStore from './appStore';
import AuthStore from './authStore';
import BookingsStore from './bookingsStore';
import InvoicingStore from './invoicingStore';
import EventsStore from './eventsStore';
import CoursesStore from './coursesStore';
import BlogStore from './blogStore';
import CommunityStore from './communityStore';
import deliveriesStore from './deliveriesStore';
import FilesStore from './filesStore';
import SupportStore from './supportStore';
import CheckoutStore from './checkoutStore';
import FloorPlanStore from './floorplanStore';
import {isServer} from 'env/ssr/ServerSideRenderManager';
import ContentStore from './contentStore';
import PlanStore from './planStore';
import ValidationStore from './validataionStore';
import QrPaymentStore from './qrPaymentStore';

useStaticRendering(isServer());

let store = null;
export const getStores = () => store;

export default function initializeStore({stores, host}) {
  //If running in the server, create new stores
  if (isServer()) {
    return {
      appStore: new AppStore({host}),
      authStore: new AuthStore({host}),
      bookingsStore: new BookingsStore({host}),
      invoicingStore: new InvoicingStore({host}),
      eventsStore: new EventsStore({host}),
      coursesStore: new CoursesStore({host}),
      blogStore: new BlogStore({host}),
      communityStore: new CommunityStore({host}),
      deliveriesStore: new deliveriesStore({host}),
      filesStore: new FilesStore({host}),
      supportStore: new SupportStore({host}),
      checkoutStore: new CheckoutStore({host}),
      floorPlanStore: new FloorPlanStore({host}),
      contentStore: new ContentStore({host}),
      planStore: new PlanStore({host}),
      validationStore: new ValidationStore({host}),
      qrPaymentStore: new QrPaymentStore({host}),
    };
  }

  //If running in the client, create new stores and hydrate
  //based on the data passed in from the server.
  if (store === null) {
    store = {
      appStore: new AppStore({data: stores.appStore}),
      authStore: new AuthStore({data: stores.authStore}),
      bookingsStore: new BookingsStore({data: stores.bookingsStore}),
      invoicingStore: new InvoicingStore({
        data: stores.invoicingStore,
      }),
      eventsStore: new EventsStore({data: stores.eventsStore}),
      coursesStore: new CoursesStore({data: stores.coursesStore}),
      blogStore: new BlogStore({data: stores.blogStore}),
      communityStore: new CommunityStore({
        data: stores.communityStore,
      }),
      deliveriesStore: new deliveriesStore({
        data: stores.deliveriesStore,
      }),
      filesStore: new FilesStore({
        data: stores.filesStore,
      }),
      supportStore: new SupportStore({data: stores.supportStore}),
      checkoutStore: new CheckoutStore({data: stores.checkoutStore}),
      floorPlanStore: new FloorPlanStore({
        data: stores.floorPlanStore,
      }),
      contentStore: new ContentStore({data: stores.contentStore}),
      planStore: new PlanStore({data: stores.planStore}),
      validationStore: new ValidationStore({data: stores.validationStore}),
      qrPaymentStore: new QrPaymentStore({data: stores.validationStore}),
    };
  }

  return store;
}
