import React from 'react';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';

let PortalPageTitle = props => {
  return (
    <div className="portal-title-wrapper">
      <div className="container portal-title-container">
        <h1 className="portal-title">
          {props.title}
        </h1>
      </div>
    </div>
  );
};

export default PortalPageTitle;
