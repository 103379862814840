import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import Link from 'next/link';
import { routes } from 'env/routes';
import withCustomComponent from 'ui/components/withCustomComponent';

import CardEvent from 'ui/components/cards/CardEvent';
import CardPerkDiscount from 'ui/components/cards/CardPerkDiscount';

@withCustomComponent('DiscountFeedCard')
@withTranslation()
@inject('appStore')
@observer
class DiscountFeedCard extends Component {
  componentDidMount() {}

  render() {
    const { t, appStore, perk } = this.props;
    const { business } = appStore;

    return (
      <div
        data-component-name={this.props.componentName}
        className="virtual-notification-card"
      >
        <div className="card h-auto p-16 p-md-24 mb-24">
          <header className="media mb-32">
            <div
              className="avatar avatar--48"
              style={{
                backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/business/getlogo?h=144&mode=pad)`,
              }}
            ></div>
            <div className="media-body d-flex justify-content-between pl-16">
              <div>
                <div className="fw-500 text-gray-900">
                  {t('A new discount is available: {{Title}}', {
                    Title: perk.Title,
                  })}
                </div>
                <div className="fs-14 lh-14 text-gray-600">
                  {moment(perk.CreatedOnUtc).format('LLL')}
                </div>
              </div>
            </div>
          </header>
          <div className="d-block mb-32">
            <CardPerkDiscount
              withoutButtons={true}
              perk={perk}
              sizeMedium={true}
              showCta={false}
            />
          </div>
          <footer>
            <div className="btn-group">
              <Link href={routes.perk_by_id(perk)}>
                <a className="btn ">{t('More details')}</a>
              </Link>
              <Link
                href={`${business.NativeHomeUrlWithLanguage}/communityperks/claim/?pid=${perk.UniqueId}`}
              >
                <a className="btn  btn-white">{t('Claim')}</a>
              </Link>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default DiscountFeedCard;
