import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { routes } from 'env/routes';
import Link from 'next/link';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
@withCustomComponent('EventRow')
@withTranslation()
@inject('appStore')
@observer
class EventRow extends Component {
  static propTypes = {
    ticket: PropTypes.object.isRequired,
  };

  render() {
    const { t, appStore, ticket, componentName } = this.props;

    return (
      <li
        className="media events-list-small__item"
        data-component-name={componentName}
      >
        <Link
          href={routes.event_by_id('[event_id]')}
          as={routes.event_by_id(ticket.CalendarEvent)}
        >
          <a className="events-list-small__cover">
            <img
              width={128}
              height={128}
              src={`${appStore.business.NativeHomeUrlWithLanguage}/events/getlargeimage?id=${ticket.CalendarEvent.Id}&w=256&h=256&cache=${ticket.CalendarEvent.UpdatedOn}`}
              alt={ticket.CalendarEvent.Name}
            />
          </a>
        </Link>
        <div className="media__body">
          <h4 className="h6 events-list-small__title">
            <Link
              href={routes.event_by_id('[event_id]')}
              as={routes.event_by_id(ticket.CalendarEvent)}
            >
              <a href="#">{ticket.CalendarEvent.Name}</a>
            </Link>
          </h4>
          <time className="events-list-small__time">
            {moment(ticket.CalendarEvent.StartDate).format('L')} at{' '}
            {moment(ticket.CalendarEvent.StartDate).format('LT')}
          </time>
          <p className="events-list-small__access">
            {t('Access code:')} {ticket.AttendeeCode}
          </p>
          <span className="label label--default--inverted">
            {ticket.EventProductName}
          </span>
        </div>
      </li>
    );
  }
}

export default EventRow;
