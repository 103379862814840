import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { routes } from 'env/routes';
import Link from 'next/link';
import { removeNewLines, shorten, removeHtml } from 'env/utils/strings';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('CardCommunityBoard')
@withTranslation()
@inject('appStore', 'communityStore')
@observer
class CardCommunityBoard extends Component {
  static propTypes = {
    thread: PropTypes.object.isRequired,
    sizeSmall: PropTypes.bool,
    sizeLarge: PropTypes.bool,
  };

  componentDidMount() {}

  render() {
    const {
      t,
      componentName,
      appStore,
      communityStore,
      thread,
      sizeSmall,
      sizeLarge,
    } = this.props;
    const { business } = appStore;

    const like = () => {
      communityStore.likeThread(thread);
    };

    return (
      <div
        className={`card card-community-board ${
          sizeSmall ? 'card-community-board--small' : ''
        } ${sizeLarge ? 'card-community-board--large' : ''}`}
        data-component-name={componentName}
      >
        {sizeSmall && (
          <>
            <div className="card-community-board--small__header">
              <Link
                href={{
                  pathname: routes.directory_member_by_id('[member_id]'),
                }}
                as={{
                  pathname: routes.directory_member_by_id({
                    Id: thread.CoworkerId,
                    FullName: thread.FullName,
                  }),
                }}
              >
                <a className="user">
                  <img
                    className="avatar"
                    src={`${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${thread.CoworkerId}?h=32&w=32&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}
                    alt={thread.FullName}
                  />
                  <span>{thread.FullName}</span>
                </a>
              </Link>
            </div>
            <div className="card-community-board--small__content">
              <Link
                href={routes.thread_by_id('[thread_id]')}
                as={routes.thread_by_id(thread)}
              >
                <a>{removeNewLines(removeHtml(thread.Subject))}</a>
              </Link>
              <p className="preview">
                {removeNewLines(removeHtml(thread.Message))}
              </p>
            </div>
            <div className="card-community-board--small__footer">
              <div className="reputation likes">
                {thread.LikedByUser ? (
                  <i className="icon-likes text-red"></i>
                ) : (
                  <i className="icon-likes"></i>
                )}{' '}
                <button
                  onClick={(ev) => {
                    ev.preventDefault();
                    like();
                  }}
                  className="btn btn-link text-gray-700"
                >
                  {thread.LikeCount == 0 && t('Like')}
                  {thread.LikeCount > 0 &&
                    t('{{count}} like', { count: thread.LikeCount })}
                </button>
              </div>
              {thread.MessageCount > 0 && (
                <div className="reputation replies">
                  <i className="icon-replies"></i>{' '}
                  <span>
                    {t('{{count}} reply', { count: thread.MessageCount })}
                  </span>
                </div>
              )}
            </div>
          </>
        )}
        {sizeLarge && <></>}
      </div>
    );
  }
}

export default CardCommunityBoard;
