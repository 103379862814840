import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import moment from 'moment';
import withCustomComponent from 'ui/components/withCustomComponent';
import _ from 'lodash';
import {LocalizedPrice} from 'env/utils/NumbersLocalization';

@withCustomComponent('ProductsSection')
@withTranslation()
@inject('appStore', 'authStore', 'invoicingStore')
@observer
class CreditUsageSection extends Component {
  render() {
    const {t, appStore, invoicingStore} = this.props;
    const {benefits} = invoicingStore;

    const bookingHistories = benefits.BookingHistories.map((h) => ({
      ...h,
      type: 'money',
    }));
    const extraServiceHistory = benefits.ExtraServiceHistories.map((h) => ({
      ...h,
      type: 'time',
    }));
    const allHistories = bookingHistories.concat(extraServiceHistory);
    const allUses = _.groupBy(
      allHistories.sort(
        (a, b) =>
          moment(b.Booking?.FromTimeUtc) - moment(a.Booking?.FromTimeUtc)
      ),
      (a) =>
        moment(a.Booking ? a.Booking.FromTimeUtc : a.CreatedOnUtc).format(
          'MMM YYYY'
        )
    );

    if (Object.keys(allUses).length == 0) return <></>;
    return (
      <fieldset
        data-component-name={this.props.componentName}
        className="section section--settings"
        id="credit-history"
      >
        <div className="card-item-wrapper">
          <div className="card-item-header">
            <h5 className="card-item-header-title">
              {t('Recent credit usage history')}
            </h5>
          </div>
          <div className="card-item-body">
            {Object.keys(allUses).map((m) => (
              <>
                <h5 className="section__title">{m}</h5>

                <table className="table table-striped table--settings">
                  <thead>
                  <tr>
                    <th>{t('Resource')}</th>
                    <th>{t('Date')}</th>
                    <th>{t('Hours')}</th>
                    <th>{t('By')}</th>
                    <th className="text-right">{t('Credit used')}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {allUses[m].map((h, i) => (
                    <tr key={i}>
                      <th>
                        {h.Booking?.FloorPlanDeskName ??
                          h.Booking?.ResourceName ??
                          t('Adjustment')}
                      </th>
                      <td>
                        {h.Booking
                          ? moment(h.Booking.FromTime).format('LL')
                          : '—'}
                      </td>
                      <td>
                        {h.Booking && (
                          <>
                            {moment(h.Booking.FromTime).format('LT')} -{' '}
                            {moment(h.Booking.ToTime).format('LT')}
                          </>
                        )}
                        {!h.Booking && '—'}
                      </td>

                      <td>{h.Booking.Coworker.FullName}</td>
                      <td className="text-right">
                        {h.type == 'money'
                          ? LocalizedPrice({
                            currency: appStore.business.Currency.Code,
                            amount: h.UsedCredit,
                          })
                          : h.UsedCredit}
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </>
            ))}
          </div>
        </div>
      </fieldset>
    );
  }
}

export default CreditUsageSection;
