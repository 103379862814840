import React, { useEffect } from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const CheckoutDiscountSection = withCustomComponent(
  'CheckoutDiscountSection'
)(
  inject(
    'appStore',
    'checkoutStore'
  )(
    withRouter(
      observer(({ componentName, checkoutStore }) => {
        const { t } = useTranslation();

        const { discountCode } = checkoutStore;

        const applyDiscount = (discountCode) => {
          checkoutStore.applyDiscount(discountCode);
          checkoutStore.loadInvoicePreview();
        };

        return (
          <div data-component-name={componentName} className="mb-24">
            <form>
              <h6 className="mb-8">{t('Discount code')}</h6>
              <div className="d-flex align-items-center justify-content-between bg-white rounded p-8 fs-14">
                <input
                  className="w-100 border-0 outline-0"
                  placeholder={t('Enter discount code')}
                  defaultValue={discountCode}
                  id="discount"
                />
                <button
                  className="btn btn-xs btn-gray"
                  onClick={(ev) => {
                    ev.preventDefault();
                    applyDiscount(document.getElementById('discount').value);
                  }}
                >
                  {t('Apply')}
                </button>
              </div>
            </form>
          </div>
        );
      })
    )
  )
);
