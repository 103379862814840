import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation, Trans} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import {removeHtml} from 'env/utils/strings';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import {withRouter} from 'next/router';
import LoadingLayout from 'ui/layouts/LoadingLayout';

@withRouter
@withCustomComponent('TermsPage')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class TermsPage extends Component {
  async componentDidMount() {
    const {authStore, appStore} = this.props;

    const user = appStore.me ?? (await authStore.loadMe());
    if (user != null) {
      if (!appStore.hasLoadedLegalStatus) appStore.loadLegalStatus();
    }
  }

  acceptTerms = async () => {
    const {router, appStore} = this.props;
    const continueTo =
      router.query.returnurl || router.query.redirectUrl || '/';
    await appStore.loadAcceptTerms();
    router.push(continueTo);
  };

  render() {
    const {t, appStore} = this.props;
    const {business, legalStatus, isLoadingAcceptTerms} = appStore;

    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={t('Terms and conditions - {{Name}}', {Name: t(business.Name)})}
      >
        <main className="content content--custom pt-0" role="main">
          <div className="portal-title-wrapper">
            <div className="container portal-title-container">
              <h1 className="portal-title">
                {t('Terms and conditions')}
              </h1>
            </div>
          </div>
          <div className="container">

            {business.TermsAndConditions && (
              <>
                <h4>{t('General Terms and Conditions')}</h4>
                <ReactMarkdown
                  source={removeHtml(business.TermsAndConditions)}
                />
              </>
            )}

            {legalStatus?.contractTerms &&
              legalStatus.contractTerms.map((contract, i) => (
                <div key={i}>
                  <h4>
                    {t('Terms and Conditions for {{TariffName}}', {
                      TariffName: contract.TariffName,
                    })}
                  </h4>
                  <ReactMarkdown
                    source={removeHtml(contract.TermsAndConditions)}
                  />
                </div>
              ))}

            {legalStatus && (
              <a
                disabled={isLoadingAcceptTerms}
                onClick={(ev) => {
                  if (isLoadingAcceptTerms) return;
                  ev.preventDefault();
                  this.acceptTerms();
                }}
                href="#"
                className="btn"
              >
                {isLoadingAcceptTerms ? (
                  <LoadingSpinner/>
                ) : (
                  t('Accept all terms and conditions')
                )}
              </a>
            )}
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default TermsPage;
