import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const CoursePageSocialLinks = withCustomComponent('CoursePageSocialLinks')(
  inject(
    'appStore',
    'coursesStore'
  )(
    withRouter(
      observer(({ router, appStore, coursesStore }) => {
        const { t } = useTranslation();
        const { coursePage } = coursesStore;
        const course = coursesStore.coursePage.Course;
        const { business } = appStore;

        return (
          <ul className="list-inline social-links">
            {course.WebAddress && (
              <li>
                <a href={course.WebAddress} className="link--google">
                  <i className="fa fa-globe i-space--right--small"></i>
                  {t('Website')}
                </a>
              </li>
            )}
            {course.FacebookPage && (
              <li>
                <a href={course.FacebookPage} className="link--facebook">
                  <i className="fa fa-facebook i-space--right--small"></i>
                  Facebook
                </a>
              </li>
            )}
          </ul>
        );
      })
    )
  )
);
