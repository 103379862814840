import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const PriceTaxStatus = withCustomComponent('PriceTaxStatus')(
  inject('appStore')(
    withRouter(
      observer(({ router, appStore, componentName }) => {
        const { t } = useTranslation();
        const { showTaxMessage, pricesIncludeTax } = appStore;
        return (
          <>
            {showTaxMessage && pricesIncludeTax && (
              <small className="text-gray">
                {t('Prices are inclusive of tax')}
              </small>
            )}
            {showTaxMessage && !pricesIncludeTax && (
              <small className="text-gray">
                {t('Prices are exclusive of tax')}
              </small>
            )}
          </>
        );
      })
    )
  )
);
