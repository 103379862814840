import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import { removeNewLines, removeHtml } from 'env/utils/strings';
import withCustomComponent from 'ui/components/withCustomComponent';
export const CommunityBoardItemTags = withCustomComponent(
  'CommunityBoardItemTags'
)(
  inject('appStore')(
    withRouter(
      observer(({ thread, componentName }) => {
        return (
          (thread.GroupId > 0 || thread.Tags.length > 0) &&
          <div
            data-component-name={componentName}
            className="community-board-item-tags"
          >
            <ul className="list-tags mb-16">
              {thread.GroupId > 0 && (
                <li>
                  <Link
                    href={{
                      pathname: routes.forum_by_group('[group_id]'),
                    }}
                    as={{
                      pathname: routes.forum_by_group(thread.GroupId),
                    }}
                  >
                    <a className="text-gray-900">{thread.GroupName}</a>
                  </Link>
                </li>
              )}
              {thread.Tags.map((tag, i) => (
                <li key={i}>
                  <Link
                    href={{
                      pathname: routes.forum,
                      query: { tag: tag },
                    }}
                  >
                    <a className="text-gray-900">{tag}</a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        );
      })
    )
  )
);
