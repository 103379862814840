import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { removeHtml } from 'env/utils/strings';
import { CoworkerAvatar } from 'ui/components/CoworkerAvatar';

export const BlogPostAuthor = withCustomComponent('BlogPostAuthor')(
  inject(
    'appStore',
    'blogStore'
  )(
    withRouter(
      observer(({ post, componentName, blogStore }) => {
        const { t } = useTranslation();

        const member = post.PostedBy;

        return (
          post.PostedBy && (
            <div
              data-component-name={componentName}
              className="blog-post-author"
            >
              <h5>{t('Written by')}</h5>
              <div className="d-flex align-items-center">
                <CoworkerAvatar
                  user_id={member.Id}
                  size={200}
                  title={member.FulLName}
                  imgClassName="avatar avatar--50"
                />
                <div className="pl-16">
                  <h4 className="mb-0">{member.FullName}</h4>
                  <div className="text-gray-700">
                    {(member.Position || member.CompanyName) && (
                      <>
                        {member.Position}
                        {member.Position && member.CompanyName && (
                          <> {t('at')} </>
                        )}
                        {member.CompanyName}
                      </>
                    )}
                  </div>
                  <div className="text-gray-700">
                    {(member.CityName || member.CountryId) && (
                      <p>
                        {member.CityName}
                        {countries && (
                          <>
                            {member.CityName && member.CountryId && ', '}
                            {memberCountry && memberCountry.Name}
                          </>
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        );
      })
    )
  )
);
