import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import {
  bookingsFeatures,
  bookingsFloorPlanTypes,
} from 'env/bookingsResources';

export const FloorPlanTypeFilters = withCustomComponent('FloorPlanTypeFilters')(
  inject('floorPlanStore')(
    withRouter(
      observer(({ floorPlanStore, componentName }) => {
        const { t } = useTranslation();
        const { currentFloorPlan } = floorPlanStore;
        const updateTypeFilter = (type, value) => {
          floorPlanStore.updateTypeFilter(type, value);
        };

        if (!currentFloorPlan.Items) return <></>;

        return (
          <div
            data-component-name={componentName}
            className="floorplans__filters-modal js-floorplans-filters-modal"
          >
            <div className="floorplans__filters-modal__inner">
              <div className="floorplans__filters-modal__header">
                <h4 className="floorplans__filters-modal__title">
                  {t('Type')}
                </h4>
                <a
                  onClick={(ev) => {
                    ev.preventDefault();
                    floorPlanStore.clearTypeFilters();
                  }}
                  href="#"
                  className="link--default link--underlined"
                >
                  {t('Show all')}
                </a>
              </div>
              <ul className="floorplans__filters-modal__list">
                {bookingsFloorPlanTypes(t).map((item, i) => {
                  const count = currentFloorPlan.Items.filter(
                    (floorPlanItem) => floorPlanItem.ItemType == item.id
                  ).length;
                  //if (count <= 0) return <></>
                  return (
                    <li key={i} className="mb-3">
                      <div className="custom-control custom-checkbox">
                        <input
                          className="custom-control-input"
                          id={`type-${i}`}
                          onChange={(ev) => {
                            updateTypeFilter(item.key, ev.target.checked);
                          }}
                          type="checkbox"
                          checked={floorPlanStore[`show${item.key}`]}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`type-${i}`}
                        >
                          {item.name} {`(${count})`}
                        </label>
                      </div>
                    </li>
                  );
                })}
              </ul>
              <div className="floorplans__filters-modal__header">
                <h4 className="floorplans__filters-modal__title">Services</h4>
                <a
                  onClick={(ev) => {
                    ev.preventDefault();
                    floorPlanStore.clearFeatureFilters();
                  }}
                  href="#"
                  className="link--default link--underlined"
                >
                  {t('Clear')}
                </a>
              </div>
              <ul className="floorplans__filters-modal__list">
                {bookingsFeatures(t).map((feature, i) => {
                  const count = currentFloorPlan.Items.filter(
                    (item) => item[`Resource${feature.key}`]
                  ).length;
                  return (
                    <li key={i} className="mb-3">
                      <div className="custom-control custom-checkbox">
                        <input
                          className="custom-control-input"
                          id={`services-${i}`}
                          onChange={(ev) => {
                            updateTypeFilter(feature.key, ev.target.checked);
                          }}
                          type="checkbox"
                          checked={floorPlanStore[`show${feature.key}`]}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`services-${i}`}
                        >
                          {feature.name} {`(${count})`}
                        </label>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="floorplans__filters-modal__actions">
              <a
                onClick={(ev) => {
                  ev.preventDefault();
                  floorPlanStore.setShowTypeFilters(false);
                }}
                href="#"
                className="btn btn--default btn--sm btn-block"
              >
                {t('Apply filters')}
              </a>
            </div>
          </div>
        );
      })
    )
  )
);
