import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import {withCustomComponent} from 'ui/components/withCustomComponent';
import MetricsWidget from 'ui/_pages/profile/metrics/MetricsWidget';
import moment from 'moment';
import {LocalizedPrice} from 'env/utils/NumbersLocalization';

@withCustomComponent('TeamMetricsWidget')
@withTranslation()
@inject('appStore', 'authStore', 'invoicingStore')
@observer
class TeamMetricsWidget extends Component {
  componentDidMount() {
    const { invoicingStore, appStore, teamId } = this.props;
    invoicingStore.loadMetrics(teamId).catch((err) => {
      appStore.setPopMessage(
        t('Sorry, we could not load your metrics. {{err}}', { err })
      );
    });
  }

  render() {
    const { t, invoicingStore, appStore, teamName, authStore } = this.props;
    const { configuration } = appStore;
    const { customer } = authStore;
    const { metrics } = invoicingStore;

    const bookingData = _.map(metrics, (d) => {
      return {
        date: moment(d.MonthDate).format('MMM YYYY'),
        team:
          Math.round((d.TeamBookingMinutes / 60 + Number.EPSILON) * 100) / 100,
        customer:
          Math.round((d.BookingMinutes / 60 + Number.EPSILON) * 100) / 100,
      };
    });

    const revenueData = _.map(metrics, (d) => {
      return {
        date: moment(d.MonthDate).format('MMM YYYY'),
        team: d.TeamInvoicedAmount,
        customer: d.InvoicedAmount,
      };
    });

    const accessData = _.map(metrics, (d) => {
      return {
        date: moment(d.MonthDate).format('MMM YYYY'),
        team:
          Math.round((d.TeamCheckingMinutes / 60 + Number.EPSILON) * 100) / 100,
        customer:
          Math.round((d.CheckingMinutes / 60 + Number.EPSILON) * 100) / 100,
      };
    });
    const isCorporateDashboard = appStore.corporateDashboard;
    return (
      <div data-component-name={this.props.componentName} className="row">
        {!isCorporateDashboard && configuration['Members.ViewInvoices'] && (
          <div className="col-lg-12 col-xl-4 mt-12">
            <div className="card p-16 p-md-24">
              <MetricsWidget
                teamName={teamName}
                formatter={(value) =>
                  LocalizedPrice({
                    currency: metrics[0].CurrencyCode,
                    amount: value,
                  })
                }
                metrics={revenueData}
                title={t('Payments in the last 12 months')}
                small={true}
              />
            </div>
          </div>
        )}

        <div
          className={`col-lg-12 ${
            isCorporateDashboard ? 'col-xl-6' : 'col-xl-4'
          } mt-12`}
        >
          <div className="card p-16 p-md-24">
            <MetricsWidget
              teamName={teamName}
              formatter={(value) => t('{{count}} hour', { count: value })}
              metrics={bookingData}
              title={t('Booked time in the last 12 months')}
              small={true}
            />
          </div>
        </div>

        <div
          className={`col-lg-12 ${
            isCorporateDashboard ? 'col-xl-6' : 'col-xl-4'
          } mt-12`}
        >
          <div className="card p-16 p-md-24">
            <MetricsWidget
              teamName={teamName}
              formatter={(value) => t('{{count}} hour', { count: value })}
              metrics={accessData}
              title={t('Access time in the last 12 months')}
              small={true}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default TeamMetricsWidget;
