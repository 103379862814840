import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import ProposalPageLayout from 'ui/layouts/ProposalPageLayout';
import { withRouter } from 'next/router';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import { SurveyDetails } from 'ui/_pages/surveys/SurveyDetails';
import { SurveySubmitted } from 'ui/_pages/surveys/SurveySubmitted';

@withRouter
@withCustomComponent('SurveyPage')
@withTranslation()
@inject('appStore', 'communityStore')
@observer
class SurveyPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
    };
  }

  componentDidMount() {
    const { t, router, appStore, communityStore } = this.props;
    communityStore
      .loadSurveyPage(router.query.survey_guid)

      .catch((ex) => {
        appStore.setPopMessage(t('We could not load this survey.'));
      });
  }

  saveSurvey() {
    const { t, appStore, communityStore } = this.props;
    communityStore
      .saveSurveyPage()
      .then(() => {
        this.setState({ submitted: true });
      })
      .catch((ex) => {
        if (ex.validationErrors) appStore.setPopMessage(ex.validationErrors);
        else appStore.setPopMessage(t('We could not load this survey.'));
      });
  }
  render() {
    const { t, appStore, communityStore } = this.props;
    const { business } = appStore;
    const { surveyPage, isLoadingSurveyPage } = communityStore;
    if (!surveyPage || isLoadingSurveyPage) return <LoadingLayout />;
    const survey = surveyPage.SurveyRun.SurveyDrop;
    const submitted = this.state.submitted || surveyPage.SurveyRun.Submitted;
    return (
      <ProposalPageLayout
        componentName={this.props.componentName}
        title={survey.Name}
        description={survey.Description}
      >
        {submitted && <SurveySubmitted />}
        {!submitted && (
          <>
            <SurveyDetails
              surveyPage={surveyPage}
              saveSurvey={() => this.saveSurvey()}
            />
          </>
        )}
      </ProposalPageLayout>
    );
  }
}

export default SurveyPage;
