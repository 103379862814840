import React, {Component} from 'react';
import {observer, inject} from 'mobx-react';
import {withTranslation} from 'react-i18next';

@withTranslation()
@inject('appStore')
@observer
class PopUpMessageModal extends Component {
  closeDialog = () => {
    this.props.appStore.setPopMessage(null, null);
  };

  componentDidMount() {
    if (this.props.appStore.popupMessage) this.showModal();
  }

  showModal = () => {
    const $ = window.$;
    $('#modal-popup-message').modal({
      backdrop: 'static',
      keyboard: false,
      show: true,
    });
  };

  hideModal = () => {
    const $ = window.$;
    $('#modal-popup-message').modal('hide');
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.appStore.popupMessage) {
      this.showModal();
    } else {
      this.hideModal();
    }
  }

  render() {
    const {t} = this.props;
    return (
      <div
        className="modal modal-popup-message in"
        id="modal-popup-message"
        tabIndex="-1"
        role="dialog"
      >
        <div className={`modal-dialog modal-dialog-centered ${this.props.appStore.popupSize ? this.props.appStore.popupSize + '' : ''}`}
             role="document">
          <div className="modal-content">

            <div className="modal-header">
              <button className="close" onClick={this.closeDialog}>
                <i className="icon-close-large"></i>
              </button>
            </div>
            <div className="modal-body">
              <p
                className="text-center"
                dangerouslySetInnerHTML={{
                  __html: this.props.appStore.popupMessage,
                }}
              ></p>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                className="btn btn-gray"
                data-dismiss="modal"
                onClick={this.closeDialog}
                id="close-button"
                type="button"
              >
                {t('Okay, Got it!')}
              </button>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default PopUpMessageModal;
