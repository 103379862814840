export const SignupSteps = (activeIndex, t) => {
  return [
    {
      title: t('Your details'),
      active: activeIndex == 1,
    },
    {
      title: t('Your plan'),
      active: activeIndex == 2,
    },
    {
      title: t('Your add-ons'),
      active: activeIndex == 3,
    },
    {
      title: t('Summary'),
      active: activeIndex == 4,
    },
  ];
};

export const SignupStepsNoStore = (activeIndex, t) => {
  return [
    {
      title: t('Your details'),
      active: activeIndex == 1,
    },
    {
      title: t('Your plan'),
      active: activeIndex == 2,
    },
    {
      title: t('Summary'),
      active: activeIndex == 4,
    },
  ];
};