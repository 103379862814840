import React, { useEffect, useState } from 'react';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import Accordion from 'ui/components/Accordion';

export const PreviousGuestsAccordion = withCustomComponent('PreviousGuestsAccordion')(
  inject('appStore', 'bookingsStore')(
    withRouter(
      observer(
        ({
          appStore,
          bookingsStore,
          addAttendee,
          removeAttendee
        }) => {
        const { booking, previousVisitors } = bookingsStore;
        const { business } = appStore;

        if(!previousVisitors?.length) {
          return null;
        }

        const handleAddAttendeeClick = (member) => {
          const isAdded = booking.BookingVisitors?.some(v => v.VisitorEmail === member.Email);
          if(isAdded) {
            removeAttendee(member.Email);
          } else {
            addAttendee(member.Id, member.FullName, member.Email, '', member.DefaultAvatarUrl, member.GravatarHashedEmail);
          }
        }

        return (
          <Accordion id='prevGuests' heading={t('Previous Guests')} showDividerTop={false} key="accordion-prev-guests" className='prev-guests-accordion'>
            {previousVisitors.map(visitor => {
              const isAdded = booking.BookingVisitors?.some(v => v.VisitorEmail === visitor.Email);
              return (
                <div className="d-flex align-items-center justify-content-between mb-20" key={visitor.Id}>
                  <div className="d-flex align-items-center">
                    <img
                      src={`${business.NativeHomeUrlWithLanguage}/user/getavatarbyemail?email=${visitor.Email}&h=40&w=40&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}
                      alt={visitor.FullName + "'s avatar"}
                      className='avatar avatar--40'
                    />
                    <span className="fs-14 fw-500 ml-8 align-middle">{visitor.FullName}</span>
                  </div>

                  <AddButton
                    baseIcon='icon-attendee-add'
                    onClick={() => handleAddAttendeeClick(visitor)}
                    isAdded={isAdded}
                    classes='fs-24'
                  />
                </div>
              );
            })
            }
          </Accordion>
        );
      })
    )
  )
);

const AddButton = ({baseIcon, onClick, isAdded, classes = ''}) => (
  <a href="#" className="text-decoration-none" onClick={onClick}>
    <i
      className={`${isAdded ? 'attendee-added' : baseIcon} icon- align-middle ${classes}`}
      aria-hidden={true}
    >
    </i>
  </a>
);