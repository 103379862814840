/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import AuthenticatedLink from 'ui/components/AuthenticatedLink';
import withCustomComponent from 'ui/components/withCustomComponent';
import LoadingSpinner from "ui/components/LoadingSpinner";
import _ from "lodash";
import filesStore from "env/stores/filesStore";
import crypto from "crypto";
import {toJS} from 'mobx';
import moment from "moment";

@withCustomComponent('HostedPaymentProviderButton')
@withTranslation()
@inject('appStore', 'authStore', 'filesStore')
class HostedPaymentProviderButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      swissQRCode: {
        'is_loading': false,
        'is_loaded': false,
        'is_image': false,
        'is_pdf': false,
        'url': '',
        'file_name': '',
        'file_id': ''
      },
    };
  }

  getValidQRCodeFile(providerIndex) {
    const { qrInvoiceCustomerLanguage, invoice, myFiles } = this.props;

    const signature = this.createFileSignature();

    let validFiles = [];


    myFiles.map((file) => {
      if(
        'string' === typeof file.Description
        && file.Description.indexOf(';') > 0
      ) {

        const metaDataQR = file.Description.split(';');

        if (
          Array.isArray(metaDataQR)
          && metaDataQR.length >= 3
          && providerIndex === parseInt(metaDataQR[0])
          && (metaDataQR[1] === 'qrc' || metaDataQR[1] === 'qrb')
          && invoice.IdString === metaDataQR[2]
          && qrInvoiceCustomerLanguage === metaDataQR[4]
        ) {
          if (metaDataQR[3] === signature) {
            validFiles.push(file);
          }
        }
      }
    });

    if (validFiles.length === 1) {
      return validFiles[0];
    }

    if (validFiles.length > 1) {
      validFiles.sort(function(a, b) {
        return new Date(b.CreatedOnUtc) - new Date(a.CreatedOnUtc);
      });

      return validFiles[0];
    }

    return null;
  }

  isEnabledQRInvoiceService() {
    const { providerIndex, appStore } = this.props;

    const { configuration } = appStore;

    if (
      !configuration[`HostedPayments.Provider${providerIndex}.Secret`]
      || !configuration[`HostedPayments.Provider${providerIndex}.Url`]
    ) {
      return false;
    }

    return 0 === configuration[`HostedPayments.Provider${providerIndex}.Secret`].indexOf('qr-invoice.ch;', 0);
  }

  getTaxesArrays() {
    const { providerIndex, invoice, appStore } = this.props;

    const { configuration } = appStore;

    const qrSettings = configuration[`HostedPayments.Provider${providerIndex}.Secret`].split(';');

    const invoiceTaxes = toJS(invoice.TaxCategories);

    const importTaxesNames = qrSettings[2].split(',');

    let taxesVAT = [];

    let taxesVATImport = [];

    invoiceTaxes.map((taxCategory) => {
      if (importTaxesNames.includes(taxCategory.Name)) {
        taxesVATImport.push({
          'taxPercentage': taxCategory.Rate,
          'taxAmount': taxCategory.TaxSubTotal
        });
      } else {
        taxesVAT.push({
          'taxPercentage': taxCategory.Rate,
          'taxedNetAmount': taxCategory.SubTotal
        });
      }
    });

    return {
      'taxesVAT': taxesVAT,
      'taxesVATImport': taxesVATImport
    };
  }

  onQRInvoiceCreateBtnClick = async (e) => {
    e.preventDefault();

    // Check if the file just has been created
    // Page was not reloaded after first click of the button
    if (
      ( this.state.swissQRCode.is_loading && !this.state.swissQRCode.is_loaded )
      || this.state.swissQRCode.is_loaded
    ) {
      return null;
    }

    this.setState({
      swissQRCode: {
        'is_loading': true,
        'is_loaded': false,
        'is_image': false,
        'is_pdf': false,
        'url': '',
        'file_name': '',
        'file_id': ''
      }
    });

    const { invoiceQRFiles, stateSetInvoiceQRFiles, qrInvoiceCustomerLanguage, providerIndex, invoice, appStore } = this.props;

    const { business, configuration } = appStore;

    // Check valid QR file in the customer's files
    const validQRCodeFile = this.getValidQRCodeFile(providerIndex);

    if (null !== validQRCodeFile) {
      await this.loadFileForPreview(validQRCodeFile.Id, validQRCodeFile.Name);
      return null;
    }

    // No QR file found, prepare data and make request for QR creation
    const taxesVATDetails = this.getTaxesArrays();

    const dayDifference = moment(invoice.DueDate).diff(moment(invoice.CreatedOn), 'days');

    let paymentConditions = [];

    if (dayDifference >= 0) {
      paymentConditions.push({
        'eligiblePaymentPeriodDays': dayDifference,
        'cashDiscountPercentage': 0
      });
    }

    const data = {
      'invoice_id': invoice.Id,
      'customer_id': invoice.CoworkerId,
      'taxes_vat': JSON.stringify(taxesVATDetails.taxesVAT),
      'taxes_vat_import': JSON.stringify(taxesVATDetails.taxesVATImport),
      'payment_conditions': JSON.stringify(paymentConditions),
      'locale': qrInvoiceCustomerLanguage,
      'timezone_iana': business.SimpleTimeZone.Iana ?? null,
      'signature': this.createSignature(),
      'provider_index': providerIndex
    };

    let response = null;

    const formData = new FormData();

    for(const name in data) {
      formData.append(name, data[name]);
    }

    if (configuration[`HostedPayments.Provider${providerIndex}.Url`].indexOf('file_type=qrci')) {
      try {
        response = await appStore
          .getAgent()
          .requests.getBlob(`/invoices/print?guid=${invoice.UniqueId}`, `/${qrInvoiceCustomerLanguage}`)
        ;

        const content = response;
        formData.append('invoice_pdf', content, `${invoice.InvoiceNumber}.pdf`);

      } catch(err) {

        return appStore.setPopMessage(
          t('Sorry, something went wrong during QR file creation. Failed to load invoice. Please contact us to fix this error.')
        );
      }
    }

    await fetch(configuration[`HostedPayments.Provider${providerIndex}.Url`], {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: formData
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data) {
          return appStore.setPopMessage(
            t('Sorry, something went wrong during QR file creation. Please contact us to fix this error.')
          );
        }

        if(data.file_qr_invoice_id && parseInt(data.file_qr_invoice_id) > 0) {
          const qrInvoiceArrayItem = [{
            'invoice_id': data.invoice_id,
            'file_id': data.file_qr_invoice_id
          }];

          stateSetInvoiceQRFiles([...invoiceQRFiles, ...qrInvoiceArrayItem]);
        }

        this.loadFileForPreview(data.file_id, data.file_name);

      })
      .catch((err) => {
        this.setState({
          swissQRCode: {
            'is_loading': false,
            'is_loaded': false,
            'is_image': false,
            'is_pdf': false,
            'url': '',
            'file_name': '',
            'file_id': ''
          }
        });

        appStore.setPopMessage(
          t('Sorry, something went wrong during QR file creation. Please contact us to fix this error.')
        );
      })
    ;
  }

  loadFileForPreview = async(fileId, fileName) => {
    const { filesStore, appStore } = this.props;

    let blob;
    try {
      blob = await filesStore.loadFile(
        fileId
      );
    } catch(err) {
      this.setState({
        swissQRCode: {
          'is_loading': false,
          'is_loaded': true,
          'is_image': false,
          'is_pdf': false,
          'url': '',
          'file_name': '',
          'file_id': ''
        }
      });

      return appStore.setPopMessage(
        t('Sorry, we could not download QR file.')
      );
    }

    if (!blob) {
      this.setState({
        swissQRCode: {
          'is_loading': false,
          'is_loaded': true,
          'is_image': false,
          'is_pdf': false,
          'url': '',
          'file_name': '',
          'file_id': ''
        }
      });

      return appStore.setPopMessage(
        t('Sorry, we could not download QR file.')
      );
    }

    const fileURL = window.URL.createObjectURL(
      new Blob([blob], { type: blob.type })
    );

    const fileExt = fileName.split('.').pop();

    const imageExts = ['png', 'gif', 'jpeg', 'tiff', 'bmp'];

    this.setState({
      swissQRCode: {
        'is_loading': false,
        'is_loaded': true,
        'is_image': imageExts.includes(fileExt),
        'is_pdf': 'pdf' === fileExt,
        'url': fileURL,
        'file_name': fileName,
        'file_id': fileId
      }
    });
  }

  createSignature() {
    const { providerIndex, invoice, appStore } = this.props;

    const { configuration } = appStore;

    const crypto = require('crypto');

    const signature = _.join([
      invoice.CoworkerId,
      configuration[`HostedPayments.Provider${providerIndex}.Secret`],
      invoice.Id,
    ], '|');

    return crypto.createHash('sha256').update(signature).digest('hex');
  }

  createFileSignature() {
    const { invoice, appStore } = this.props;

    const { business } = appStore;

    const crypto = require('crypto');

    const taxesArrays = this.getTaxesArrays();

    const signature = _.join([
      invoice.CoworkerId,
      invoice.BillToCountry.Id,
      invoice.BillToPostCode,
      invoice.BillToCity,
      invoice.BillToAddress,
      invoice.BillToName,
      invoice.Id,
      invoice.InvoiceNumber,
      invoice.TotalAmount,
      invoice.DueAmount,
      invoice.Currency.Code,
      JSON.stringify(taxesArrays.taxesVAT),
      JSON.stringify(taxesArrays.taxesVATImport),
      business.BillingAccountCode
    ], '//');

    return crypto.createHash('sha256').update(signature).digest('hex');
  }

  render() {
    const { providerIndex, invoice, appStore, filesStore, componentName } = this.props;

    const { business, configuration } = appStore;

    const isEnabledQRInvoiceService = this.isEnabledQRInvoiceService();

    const qrSettings = isEnabledQRInvoiceService
      ? configuration[`HostedPayments.Provider${providerIndex}.Secret`].split(';')
      : false
    ;

    const isQRSettingsOk = Array.isArray(qrSettings) && qrSettings.length >= 2;

    return (
      <>
        {!isEnabledQRInvoiceService && (
          <AuthenticatedLink
            data-component-name={componentName}
            className="btn btn-icon btn-link text-gray-900"
            href={`${business.NativeHomeUrlWithLanguage}/callbacks/HostedPagePaymentsStart?providerKey=${providerIndex}&invoiceId=${invoice.Id}`}
          >
            <i aria-hidden="true" className="icon-credit-card"></i>
            {configuration[`HostedPayments.Provider${providerIndex}.Name`]}
          </AuthenticatedLink>
        )}
        {isEnabledQRInvoiceService && ! isQRSettingsOk && !filesStore.hasLoadedMyFiles && (
          <LoadingSpinner/>
        )}
        {isEnabledQRInvoiceService && isQRSettingsOk && filesStore.hasLoadedMyFiles && (
          <>
            <a
              id={`qrinvoiceButton-${providerIndex}-${invoice.Id}`}
              data-invoice-id={invoice.Id}
              data-component-name={componentName}
              data-toggle="modal"
              data-target={`#qrinvoice-modal-${providerIndex}-${invoice.Id}`}
              className="btn btn-icon btn-link text-gray-900"
              onClick={this.onQRInvoiceCreateBtnClick}
              href="#"
            >
              <i aria-hidden="true" className="icon-invoice"></i>
              {configuration[`HostedPayments.Provider${providerIndex}.Name`]}
            </a>
            <div
              id={`qrinvoice-modal-${providerIndex}-${invoice.Id}`}
              className="modal custom-modal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby={`${invoice.InvoiceNumber} - ${t('Swiss QR Code')}`}
              aria-hidden="true"
              data-backdrop="static"
              data-keyboard="false"
            >
              <div className="modal-dialog modal-dialog-centered modal-dialog_qr" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                      {`${invoice.InvoiceNumber} - ${t('Swiss QR Code')}`}
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <i className="icon-close-large"></i>
                    </button>
                  </div>
                  <div className="modal-body modal-body_qr">
                    <div className="modal-body-qr__previewer">
                      {this.state.swissQRCode.is_loading && (
                        <div className="modal-body-qr__previewer-loader">
                          <LoadingSpinner/>
                        </div>
                      )}
                      {this.state.swissQRCode.is_loaded && this.state.swissQRCode.url && (
                        <>
                          {this.state.swissQRCode.is_image && (
                            <img
                              src={this.state.swissQRCode.url}
                              className="img-fluid modal-body-qr__previewer-image"
                              alt=""
                            />
                          )}
                          {this.state.swissQRCode.is_pdf && (
                            <a
                              href={this.state.swissQRCode.url}
                              className="btn text-dark"
                              download={this.state.swissQRCode.file_name}
                            >
                              <i className="icon-downloads fs-20 mr-4 tdn text-dark"></i> {t('Download')} {t('PDF')}
                            </a>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="modal-footer">
                    {this.state.swissQRCode.is_loaded && this.state.swissQRCode.url && this.state.swissQRCode.is_image && (
                      <a
                        href={this.state.swissQRCode.url}
                        className="btn btn-white mr-4"
                        download={this.state.swissQRCode.file_name}
                      >
                        <i className="icon-downloads fs-20 mr-4 tdn"></i> {t('Download')}
                      </a>
                    )}
                    <button type="button" className="btn" data-dismiss="modal" aria-label="Close">
                      {t('Close')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
export default HostedPaymentProviderButton;
