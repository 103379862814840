import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const EventPageDetails = withCustomComponent('EventPageDetails')(
  inject(
    'appStore',
    'eventsStore'
  )(
    withRouter(
      observer(({ componentName, appStore, eventsStore }) => {
        const { t } = useTranslation();
        const event = eventsStore.eventPage.Event;
        if (!event.LongDescription || event.LongDescription == '') return <></>;

        return (
          <div
            data-component-name={componentName}
            className="event-page-details card p-16 p-md-32 pb-16 mt-24 h-auto fr-view"
            dangerouslySetInnerHTML={{
              __html: event.LongDescription,
            }}
          ></div>
        );
      })
    )
  )
);
