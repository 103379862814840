import { Component } from 'react';

export const dummyHocComponent = () => (WrappedComponent) => {
  class DummyHocComponent extends Component {
    render() {
      return (
        <WrappedComponent {...this.props}>
          {this.props.children}
        </WrappedComponent>
      );
    }
  }

  return DummyHocComponent;
};

export default dummyHocComponent;
