import React, {Component} from 'react';
import {PropTypes} from 'prop-types';
import {observer, inject} from 'mobx-react';
import CustomField from 'ui/_pages/profile/CustomField';
import {withTranslation} from 'react-i18next';
import ActionButton from 'ui/components/forms/ActionButton';
import asForm from 'ui/components/forms/asForm';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('CustomFieldsSection')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class CustomFieldsSection extends Component {
  static propTypes = {
    customer: PropTypes.object.isRequired,
  };

  render() {
    const {className, saveCustomer, t, authStore, customer, updateProperty} =
      this.props;
    const {
      hasLoadedSignupCustomFields,
      hasLoadedCustomFields,
      signupCustomFieldsGroups,
      customFieldsGroups,
      isLoggedIn,
    } = authStore;

    if (!hasLoadedCustomFields && !hasLoadedSignupCustomFields) return <></>;

    const groups = isLoggedIn ? customFieldsGroups : signupCustomFieldsGroups;

    return (
      <>
        {groups
          .sort((a, b) => a.group.localeCompare(b.group))
          .map((g, i) => {
            return (
              <fieldset
                data-component-name={this.props.componentName}
                key={i}
                id={`customfields-${i}`}
              >
                <div className="card-item-wrapper">
                  <div className="card-item-header">
                    <h5 className="card-item-header-title">
                      {g.group != 'null' ? g.group : t('Other Details')}
                    </h5>
                  </div>
                  {g.fields.map((field, i) => (
                    <div className="card-item-body">
                      <CustomField
                        key={i}
                        field={field}
                        customer={customer}
                        updateProperty={updateProperty}
                      />
                    </div>
                  ))}
                </div>
                {saveCustomer && (
                  <ActionButton
                    action={() => saveCustomer()}
                    busy={authStore.isSavingCustomerWithUser}
                  />
                )}
              </fieldset>
            );
          })}
      </>
    );
  }
}

export default asForm(CustomFieldsSection, 'customer');
