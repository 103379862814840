import React, {Component} from 'react';
import {observer, inject} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import {privateRoute} from 'env/privateRoute';
import withCustomComponent from 'ui/components/withCustomComponent';

import NotificationsSection from 'ui/_pages/profile/NotificationsSection';
import Head from 'next/head';

@withCustomComponent('NotificationsPage')
@privateRoute
@withTranslation()
@inject('authStore', 'appStore')
@observer
class NotificationsPage extends Component {
  componentDidMount() {
    const {loadCustomer, authStore} = this.props;
    const {isLoggedIn} = authStore;

    authStore.loadCustomer();
    if (isLoggedIn) authStore.loadCustomFields();
    else authStore.loadSignupCustomFields();
  }

  saveCustomer = () => {
    const {appStore, authStore} = this.props;
    authStore.saveCustomer().catch((err) => {
      appStore.setPopMessage(err);
    });
  };

  render() {
    const {t, authStore, appStore} = this.props;
    const {
      hasLoadedCustomer,
      isLoggedIn,
      customFieldsGroups,
      signupCustomFieldsGroups,
    } = authStore;
    const groups = isLoggedIn ? customFieldsGroups : signupCustomFieldsGroups;
    if (!hasLoadedCustomer) return <></>;

    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={`${t('My Notifications')} - ${t(this.props.appStore.business.Name)}`}
      >
        <main className="content content--custom pt-0" role="main">
          <div className="portal-title-wrapper">
            <div className="container portal-title-container"><h1 className="portal-title">
              {t('Notifications')}
            </h1>
            </div>
          </div>
          <div className="container">
            <NotificationsSection
              saveCustomer={this.saveCustomer}
              customer={authStore.customer}
              user={authStore.me}
            />
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default NotificationsPage;
