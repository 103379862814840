import React, {useEffect} from 'react';
import Link from 'next/link';
import {routes} from 'env/routes';
import {useTranslation} from 'react-i18next';
import {withRouter} from 'next/router';
import {inject, observer} from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

import CheckinWidgetSmall from 'ui/_pages/home/components/CheckinWidget/CheckinWidgetSmall';
import InvoicesWidgetSmall from 'ui/_pages/home/components/InvoicesWidget/InvoicesWidgetSmall';
import MessagesWidgetSmall from 'ui/_pages/home/components/MessagesWidget/MessagesWidgetSmall';
import EventsWidgetSmall from 'ui/_pages/home/components/EventsWidget/EventsWidgetSmall';
import DeliveriesWidgetSmall from 'ui/_pages/home/components/DeliveriesWidget/DeliveriesWidgetSmall';

import BookingsWidget from 'ui/_pages/home/components/BookingsWidget/BookingsWidget';

import EventsWidget from 'ui/_pages/home/components/EventsWidget/EventsWidget';
import CommunityBoardWidget from 'ui/_pages/home/components/CommunityBoardWidget/CommunityBoardWidget';
import FeaturedBlogPostWidget from 'ui/_pages/home/components/FeaturedBlogPostWidget/FeaturedBlogPostWidget';

import VisitorsWidget from 'ui/_pages/home/components/VisitorsWidget/VisitorsWidget';
import BenefitsWidget from 'ui/_pages/home/components/BenefitsWidget/BenefitsWidget';
import DiscountsWidget from 'ui/_pages/home/components/DiscountsWidget/DiscountsWidget';
import {toJS} from 'mobx';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import MeetingsWidget from './components/MeetingsWidget';
import ContactsWidget from './components/ContactsWidget';

let HomeDashboard = props => {
  const {
    componentName, appStore, authStore, bookingsStore,
  } = props;
  const {t} = useTranslation();
  const {configuration, corporateDashboard} = appStore;
  const {upcomingBookings} = bookingsStore;
  const {customer} = authStore;

  useEffect(() => {
    if (!authStore.isLoadingTeamProfiles && !authStore.hasLoadedTeamProfiles) {
      authStore.loadTeamProfiles().catch((err) =>
        appStore.setPopMessage(
          t('Sorry, we could not load your current teams.', {err}),
        ),
      );
    }
  }, []);

  return (<div data-component-name={componentName}>
    {/*  DASHBOARD BODY */}
    <div className="dashboard-body">
      <div className="container">
        <MeetingsWidget/>
        {!corporateDashboard && (<div
          id="home-benefits-widget">
          <BenefitsWidget
            headerActionUrl={routes.contracts_benefits}
            footerActionUrl={routes.contracts_benefits}
          />
        </div>)}

        {authStore.teamProfiles.length > 0 && (
          <div>
            {authStore.teamProfiles.map((profile, i) => (
              <ContactsWidget
                key={i}
                team={profile.Team}
                members={profile.Team.AllTeamMembers.sort((a, b) =>
                  a.FullName.localeCompare(b.FullName),
                )}
              />
            ))}
          </div>)}

        {/*<div className="row align-items-center ">
          <div className="col-lg-6">
            <h2 className="mb-0">
              {t('Hello')} {customer.GuessedFirstName},
            </h2>
            <div className="text-gray-700">
              {t('This is what we have got for you today.')}
            </div>
          </div>
          <div className="col-lg-6 text-lg-right pt-20 pt-lg-0">
            <Link href={routes.my_bookings}>
              <a className="btn btn-icon">
                <i className="icon-add"></i>
                {t('Manage bookings')}
              </a>
            </Link>
          </div>
        </div>*/}

        {/*<div className="row mt-20">
          {configuration['HomePage.ShowCheckedInMembers'] && (<div className="col-md-6 col-xl-3 mt-12">
            {<CheckinWidgetSmall/>}
          </div>)}
          {configuration['PublicWebSite.Deliveries'] && (<DeliveriesWidgetSmall/>)}
          {!corporateDashboard && configuration['Members.ViewInvoices'] && (<div className="col-md-6 col-xl-3 mt-12">
            {<InvoicesWidgetSmall/>}
          </div>)}
           <div className="col-md-6 col-xl-3 mt-12">
                    {<MessagesWidgetSmall />}
                  </div>
          {!corporateDashboard && configuration['PublicWebSite.Events'] && (<div className="col-md-6 col-xl-3 mt-12">
            {<EventsWidgetSmall/>}
          </div>)}
        </div>*/}

        {/*{<BookingsWidget/>}*/}

        {/*<div className="row">
          {!corporateDashboard && configuration['PublicWebSite.Events'] && <EventsWidget/>}
          <div className="w-100 d-lg-none"></div>
          <CommunityBoardWidget/>
          <div className="w-100 d-xl-none"></div>
          {configuration['Blog.Enabled'] && <FeaturedBlogPostWidget/>}
        </div>

        <hr className="mt-40 mb-0"/>

        <div className="row">
          {configuration['PublicWebSite.Visitors'] && (<div
            id="home-visitors-widget"
            className={`col-lg-6 ${corporateDashboard ? 'col-xl-6' : 'col-xl-4'} mt-40`}
          >
            <VisitorsWidget/>
          </div>)}
          <div
            id="home-discounts-widget"
            className={`col-lg-6 ${corporateDashboard ? 'col-xl-6' : 'col-xl-4 d-lg-none d-xl-block'} mt-40`}
          >
            <DiscountsWidget/>
          </div>
        </div>*/}
      </div>
    </div>
    {/*  END DASHBOARD BODY */}
  </div>);
};

export default HomeDashboard = withCustomComponent('HomeDashboard')(
  inject('appStore', 'authStore', 'bookingsStore')(withRouter(observer(HomeDashboard))));
