import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import { removeHtml } from 'env/utils/strings';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { withRouter } from 'next/router';
import LoadingLayout from 'ui/layouts/LoadingLayout';

@withRouter
@withCustomComponent('CookiesPolicyPage')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class CookiesPolicyPage extends Component {
  render() {
    const { t, appStore } = this.props;
    const { business, configuration } = appStore;

    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={t('Cookies Policy - {{Name}}', { Name: t(business.Name) })}
      >
        <main className="content content--custom bg-white mh-50vh" role="main">
          <div className="container">
            <div className="mw-840">
              <h1>{t('Cookies Policy')}</h1>
              <ReactMarkdown
                source={removeHtml(configuration['Legal.Cookies'])}
              />
            </div>
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default CookiesPolicyPage;
