import React, { useState, useRef } from 'react';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import ReactGeosuggest from 'react-geosuggest';

export const Geosuggest = withCustomComponent('Geosuggest')(
  inject('appStore')(
    withRouter(
      observer(({ appStore, setMapCenter }) => {
        const [active, setActive] = useState(false);
        
        const geosuggestRef = useRef();
        const geosuggest = geosuggestRef.current;

        const { t } = useTranslation();

        const onSuggestSelect = (suggestion) => {
          geosuggest.clear();

          if(!suggestion?.location) return;
          
          setMapCenter(suggestion.location);
        }

        return (
          <div className="ml-auto w-sm-100">
            <div className="d-flex align-items-center justify-content-between bg-white rounded p-4 pl-8 fs-14 shadow-sm">
                <ReactGeosuggest
                  placeholder={t('Search for a location...')}
                  onFocus={() => setActive(true)}
                  onBlur={() => setActive(false)}
                  autoActivateFirstSuggest={true}
                  ref={(el) => geosuggestRef.current = el}
                  onSuggestSelect={onSuggestSelect}
                  className={`${active ? `active` : ''}`}
                  inputClassName="w-100 border-0 outline-0"
                  suggestItemClassName={'item'}
                  suggestsClassName='dropdown-menu show'
                />

                <button
                  className="btn btn-xs py-0 fs-18 btn-gray"
                  onClick={(e) => {
                    e.preventDefault();
                    geosuggest.selectSuggest();
                  }}>
                  <i className="icon-search"></i>
                </button>
              </div>
          </div>
        );
      })
    )
  )
)

export default Geosuggest;
