export const TourSteps = (activeIndex, t) => {
  return [
    {
      title: t('Your details'),
      active: activeIndex == 1,
    },
    {
      title: t('Complete'),
      active: activeIndex == 2,
    },
  ];
};
