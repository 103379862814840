import React, { Component, useEffect } from 'react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Prism from 'prismjs';
import Link from 'next/link';
import moment from 'moment';

const DesignSystemWrapper = withCustomComponent('DesignSystem')(
  inject()(
    withRouter(
      observer(
        ({
          authStore,
          bookingsStore,
          componentName,
          resource,
        }) => {

          useEffect(() => {
            if (typeof window !== 'undefined') {
              Prism.highlightAll();
              const $ = window.$;
              $('[data-toggle="tooltip"]').tooltip({
                html: true,
              });
            }
          }, []);

          return (
            <DefaultLayout>
              <main className="content content--custom" role="main">
                <div className="container">
                  <h1 className="mb-48">Design System</h1>

                  <div className="mb-100">
                    <h2 className="border-bottom pb-24 mb-40">Buttons</h2>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn btn-lg">Large Button</button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn btn-lg">Large Button</button>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn">Medium Button</button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn">Medium Button</button>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn">Small Button</button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn">Small Button</button>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn btn-xs">Tiny Button</button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn btn-xs">Tiny Button</button>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn btn-lg btn-icon"><i className="icon-add"></i>Large Button & Icon</button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn btn-lg btn-icon">
  <i class="icon-add"></i>Large Button & Icon
</button>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn btn-icon"><i className="icon-add"></i>Medium Button & Icon</button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn btn-icon">
  <i class="icon-add"></i>Medium Button & Icon
</button>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn btn-icon"><i className="icon-add"></i>Small Button & Icon</button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn btn-icon">
  <i class="icon-add"></i>Small Button & Icon
</button>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn btn-xs btn-icon"><i className="icon-add"></i>Tiny Button & Icon</button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn btn-xs btn-icon">
  <i class="icon-add"></i>Tiny Button & Icon
</button>`}
                        </code></pre>
                      </div>
                    </div>

                  </div>

                  <div className="mb-100">
                    <h2 className="border-bottom pb-24 mb-40">Secondary Buttons</h2>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn btn-outline">Button Outlined</button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn btn-outline">Button Outlined</button>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn btn-outline-gray btn-icon"><i className="icon-add"></i>Gray Small Outlined Button & Icon</button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn btn-outline-gray btn-icon">
  <i class="icon-add"></i>Gray Small Outlined Button & Icon
</button>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn btn-white">Button White</button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn btn-white">Button White</button>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn btn-icon btn-white"><i className="icon-add"></i>Small Button White & Icon</button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn btn-icon btn-white">
  <i class="icon-add"></i>Small Button White & Icon
</button>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn btn-white-black">Button White Black</button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn btn-white-black">Button White Black</button>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn btn-gray">Button Grey</button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn btn-gray">Button Grey</button>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn btn-gray-light">Button Grey Light</button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn btn-gray-light">Button Grey Light</button>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn btn-gray-white">Button Grey White</button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn btn-gray-white">Button Grey White</button>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn btn-link">Link Button</button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn btn-link">Link Button</button>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn btn-link text-gray-900">Black Link Button</button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn btn-link text-gray-900">Black Link Button</button>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn btn-link text-gray-600">Gray Link Button</button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn btn-link text-gray-600">Gray Link Button</button>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <a href="#">Standard Link Text</a>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<a href="#">Standard Link Text</a>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <a href="#" className="text-gray-900">Black Link Text</a>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<a href="#" class="text-gray-900">Black Link Text</a>`}
                        </code></pre>
                      </div>
                    </div>

                  </div>

                  <div className="mb-100">
                    <h2 className="border-bottom pb-24 mb-40">Icon Buttons</h2>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn btn-single-icon btn-gray"><i className="icon-delete"></i></button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn btn-single-icon btn-gray">
  <i class="icon-delete"></i>
</button>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn btn-single-icon btn-white"><i className="icon-cart"></i></button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn btn-single-icon btn-white">
  <i class="icon-cart"></i>
</button>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn btn-single-icon btn-white-black"><i className="icon-cart"></i></button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn btn-single-icon btn-white-black">
  <i class="icon-cart"></i>
</button>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <button className="btn btn-single-icon btn-white-black active"><i className="icon-cart"></i><span className="badge">3</span></button>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<button class="btn btn-single-icon btn-white-black active">
  <i class="icon-cart"></i>
  <span class="badge">3</span>
</button>`}
                        </code></pre>
                      </div>
                    </div>

                  </div>

                  <div className="mb-100">
                    <h2 className="border-bottom pb-24 mb-40">Tab Menu</h2>

                    <div className="row py-24">
                      <div className="col-6">

                        <ul className="nav nav-s8">
                          <li className="nav-item">
                            <button className="btn btn-gray-light btn-icon">
                              <i className="icon-delete"></i>Small Button & Icon
                            </button>
                          </li>
                          <li className="nav-item">
                            <button className="btn btn-gray-light btn-icon active">
                              <i className="icon-cart"></i>Small Button & Icon
                            </button>
                          </li>
                          <li className="nav-item">
                            <button className="btn btn-gray-light btn-icon">
                              <i className="icon-business-spaces"></i>Small Button & Icon
                            </button>
                          </li>
                        </ul>

                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<ul class="nav nav-s8">
  <li class="nav-item">
    <button class="btn btn-gray-light btn-icon">
      <i class="icon-delete"></i>Small Button & Icon
    </button>
  </li>
  <li class="nav-item">
    <button class="btn btn-gray-light btn-icon active">
      <i class="icon-cart"></i>Small Button & Icon
    </button>
  </li>
  <li class="nav-item">
    <button class="btn btn-gray-light btn-icon">
      <i class="icon-business-spaces"></i>Small Button & Icon
    </button>
  </li>
</ul>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">

                        <ul className="nav nav-s4 nav-active-black">
                          <li className="nav-item">
                            <button className="btn btn-gray-light active">Month</button>
                          </li>
                          <li className="nav-item">
                            <button className="btn btn-gray-light">Week</button>
                          </li>
                          <li className="nav-item">
                            <button className="btn btn-gray-light">Day</button>
                          </li>
                        </ul>

                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<ul class="nav nav-s4 nav-active-black">
  <li class="nav-item">
    <button class="btn btn-gray-light active">Month</button>
  </li>
  <li class="nav-item">
    <button class="btn btn-gray-light">Week</button>
  </li>
  <li class="nav-item">
    <button class="btn btn-gray-light">Day</button>
  </li>
</ul>`}
                        </code></pre>
                      </div>
                    </div>

                  </div>

                  <div className="mb-100">
                    <h2 className="border-bottom pb-24 mb-40">Tags</h2>

                    <div className="row py-24">
                      <div className="col-6">
                        <ul className="list-tags">
                          <li>Hot Desk</li>
                          <li>Wi-fi</li>
                          <li className="with-icon-left"><i className="icon-check"></i>Wi-fi</li>
                          <li className="with-icon-left"><i className="icon-check text-gray-600"></i>Wi-fi</li>
                          <li className="with-icon-right">Wi-fi<i className="icon-check"></i></li>                          
                        </ul>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<ul class="list-tags">
  <li>Hot Desk</li>
  <li>Wi-fi</li>
  <li class="with-icon-left"><i class="icon-check"></i>Wi-fi</li>
  <li class="with-icon-left"><i class="icon-check text-gray-600"></i>Wi-fi</li>
  <li class="with-icon-right">Wi-fi<i class="icon-check"></i></li>
</ul>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <ul className="list-tags">
                          <li className="bg-orange-02"><i className="icon-cart"></i>Pending</li>
                          <li className="bg-green-02"><i className="icon-add"></i>Confirmed</li>
                          <li className="bg-red-02"><i className="icon-delete"></i>Error</li>
                        </ul>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<ul class="list-tags">
  <li class="bg-orange-02"><i class="icon-cart"></i>Pending</li>
  <li class="bg-green-02"><i class="icon-add"></i>Confirmed</li>
  <li class="bg-red-02"><i class="icon-delete"></i>Error</li>
</ul>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <ul className="list-tags">
                          <li className="bg-orange">Pending</li>
                          <li className="bg-green">Active</li>
                          <li className="bg-red">Error</li>
                        </ul>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<ul class="list-tags">
  <li class="bg-orange">Pending</li>
  <li class="bg-green">Active</li>
  <li class="bg-red">Error</li>
</ul>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">
                        <ul className="list-tags">
                          <li className="bg-orange-02" data-toggle="tooltip" title="This booking has not yet been confirmed!"><i className="icon-cart"></i>Pending</li>
                        </ul>
                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<ul class="list-tags">
  <li class="bg-orange-02" data-toggle="tooltip" title="This booking has not yet been confirmed!">
    <i class="icon-cart"></i>Pending
  </li>
</ul>`}
                        </code></pre>
                      </div>
                    </div>

                  </div>

                  <div className="mb-100">
                    <h2 className="border-bottom pb-24 mb-40">Switcher</h2>

                    <div className="row py-24">
                      <div className="col-6">

                        <div className="custom-control custom-switch">
                          <input type="checkbox" className="custom-control-input" id="customSwitch1" />
                          <label className="custom-control-label" htmlFor="customSwitch1">Toggle this switch element</label>
                        </div>

                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<div class="custom-control custom-switch">
  <input type="checkbox" class="custom-control-input" id="customSwitch1" />
  <label class="custom-control-label" for="customSwitch1">Toggle this switch element</label>
</div>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">

                        <div className="custom-control custom-switch">
                          <input type="checkbox" className="custom-control-input" id="customSwitch2" defaultChecked={true} />
                          <label className="custom-control-label" htmlFor="customSwitch2">Toggle this switch element</label>
                        </div>

                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<div class="custom-control custom-switch">
  <input type="checkbox" class="custom-control-input" id="customSwitch2" checked />
  <label class="custom-control-label" for="customSwitch2">Toggle this switch element</label>
</div>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">

                        <div className="custom-control custom-switch custom-switch--reversed">
                          <input type="checkbox" className="custom-control-input" id="customSwitch3" />
                          <label className="custom-control-label" htmlFor="customSwitch3">Toggle this reversed switch element</label>
                        </div>

                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<div class="custom-control custom-switch custom-switch--reversed">
  <input type="checkbox" class="custom-control-input" id="customSwitch3" />
  <label class="custom-control-label" for="customSwitch3">Toggle this reversed switch element</label>
</div>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">

                        <div className="custom-control custom-switch custom-switch--reversed">
                          <input type="checkbox" className="custom-control-input" id="customSwitch4" defaultChecked={true} />
                          <label className="custom-control-label" htmlFor="customSwitch4">Toggle this reversed switch element</label>
                        </div>

                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<div class="custom-control custom-switch custom-switch--reversed">
  <input type="checkbox" class="custom-control-input" id="customSwitch4" checked />
  <label class="custom-control-label" for="customSwitch4">Toggle this reversed switch element</label>
</div>`}
                        </code></pre>
                      </div>
                    </div>

                  </div>

                  <div className="mb-100">
                    <h2 className="border-bottom pb-24 mb-40">Checkbox</h2>

                    <div className="row py-24">
                      <div className="col-6">

                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="customCheck1" />
                          <label className="custom-control-label" htmlFor="customCheck1">Check this custom checkbox</label>
                        </div>

                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<div class="custom-control custom-checkbox">
  <input type="checkbox" class="custom-control-input" id="customCheck1" />
  <label class="custom-control-label" for="customCheck1">Check this custom checkbox</label>
</div>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">

                        <div className="custom-control custom-checkbox">
                          <input type="checkbox" className="custom-control-input" id="customCheck2" defaultChecked={true} />
                          <label className="custom-control-label" htmlFor="customCheck2">Check this custom checkbox</label>
                        </div>

                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<div class="custom-control custom-checkbox">
  <input type="checkbox" class="custom-control-input" id="customCheck2" checked />
  <label class="custom-control-label" for="customCheck2">Check this custom checkbox</label>
</div>`}
                        </code></pre>
                      </div>
                    </div>

                  </div>

                  <div className="mb-100">
                    <h2 className="border-bottom pb-24 mb-40">Radio</h2>

                    <div className="row py-24">
                      <div className="col-6">

                        <div className="custom-control custom-radio mb-8">
                          <input type="radio" id="customRadio1" name="customRadio" className="custom-control-input" />
                          <label className="custom-control-label" htmlFor="customRadio1">Toggle this custom radio</label>
                        </div>
                        <div className="custom-control custom-radio mb-8">
                          <input type="radio" id="customRadio2" name="customRadio" className="custom-control-input" defaultChecked={true} />
                          <label className="custom-control-label" htmlFor="customRadio2">Or toggle this other custom radio</label>
                        </div>

                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<div class="custom-control custom-radio mb-8">
  <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input" />
  <label class="custom-control-label" for="customRadio1">Toggle this custom radio</label>
</div>
<div class="custom-control custom-radio mb-8">
  <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input" checked />
  <label class="custom-control-label" for="customRadio2">Or toggle this other custom radio</label>
</div>`}
                        </code></pre>
                      </div>
                    </div>

                  </div>

                  <div className="mb-100">
                    <h2 className="border-bottom pb-24 mb-40">Select menu</h2>

                    <div className="row py-24">
                      <div className="col-6">

                        <select className="custom-select custom-select-sm">
                          <option blank="true">Open this select menu</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>

                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<select class="custom-select custom-select-sm">
  <option blank="true">Open this select menu</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">

                        <select className="custom-select">
                          <option blank="true">Open this select menu</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>

                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<select class="custom-select">
  <option blank="true">Open this select menu</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">

                        <select className="custom-select custom-select-lg">
                          <option blank="true">Open this select menu</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>

                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<select class="custom-select custom-select-lg">
  <option blank="true">Open this select menu</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>`}
                        </code></pre>
                      </div>
                    </div>

                  </div>

                  <div className="mb-100">
                    <h2 className="border-bottom pb-24 mb-40">Range</h2>

                    <div className="row py-24">
                      <div className="col-6">

                        <label htmlFor="customRange1">Example range</label>
                        <input type="range" className="custom-range" id="customRange1" />

                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                            {`<label for="customRange1">Example range</label>
<input type="range" class="custom-range" id="customRange1" />`}
                        </code></pre>
                      </div>
                    </div>

                  </div>

                  <div className="mb-100">
                    <h2 className="border-bottom pb-24 mb-40">Dropdown</h2>

                    <div className="row py-24">
                      <div className="col-6">

                        <div className="dropdown">
                          <button className="btn dropdown-toggle" data-toggle="dropdown">Dropdown button</button>
                          <div className="dropdown-menu">
                            <button className="dropdown-item">Action</button>
                            <a className="dropdown-item" href="#">Another action</a>
                            <button className="dropdown-item">Something else here</button>
                          </div>
                        </div>

                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<div class="dropdown">
  <button class="btn dropdown-toggle" data-toggle="dropdown">Dropdown button</button>
  <div class="dropdown-menu">
    <button class="dropdown-item">Action</button>
    <a class="dropdown-item" href="#">Another action</a>
    <button class="dropdown-item">Something else here</button>
  </div>
</div>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">

                        <div className="dropdown">
                          <button className="btn btn-white dropdown-toggle" data-toggle="dropdown">
                            <img className="avatar" src="./temp/human01.jpg" />
                            <span className="fs-16 pl-12">Adrian, Q.</span>
                            <span className="caret"></span>
                          </button>
                          <div className="dropdown-menu">
                            <button className="dropdown-item">My Bookings</button>
                            <button className="dropdown-item">My Tickets</button>
                            <button className="dropdown-item">Visitors</button>
                            <button className="dropdown-item">Deliveries</button>
                            <button className="dropdown-item">My Account</button>
                            <button className="dropdown-item"><i className="icon-delete"></i>Log Out</button>
                          </div>
                        </div>

                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<div class="dropdown">
  <button class="btn btn-white dropdown-toggle" data-toggle="dropdown">
    <img class="avatar" src="./temp/human01.jpg" />
    <span class="fs-16 pl-12">Adrian, Q.</span>
    <span class="caret"></span>
  </button>
  <div class="dropdown-menu">
    <button class="dropdown-item">My Bookings</button>
    <button class="dropdown-item">My Tickets</button>
    <button class="dropdown-item">Visitors</button>
    <button class="dropdown-item">Deliveries</button>
    <button class="dropdown-item">My Account</button>
    <button class="dropdown-item"><i class="icon-delete"></i>Log Out</button>
  </div>
</div>`}
                        </code></pre>
                      </div>
                    </div>

                  </div>

                  <div className="mb-100">
                    <h2 className="border-bottom pb-24 mb-40">Form</h2>

                    <div className="row py-24">
                      <div className="col-6">

                        <form>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label htmlFor="inputEmail4">Email</label>
                              <input type="email" className="form-control" id="inputEmail4" />
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="inputPassword4">Password</label>
                              <div className="input-group">
                                <input type="text" className="form-control" id="inputPassword4" />
                                <div className="input-group-append">
                                  <i className="icon-lock"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="inputAddress">Address</label>
                            <input type="text" className="form-control" id="inputAddress" placeholder="1234 Main St" />
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <label htmlFor="inputCity">City</label>
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">@</span>
                                </div>
                                <input type="text" className="form-control" id="inputCity" required />
                              </div>
                            </div>
                            <div className="form-group col-md-6">
                              <label htmlFor="inputState">State</label>
                              <select id="inputState" className="form-control">
                                <option blank="true">Choose...</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="exampleFormControlTextarea1">Example textarea</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                          </div>
                          <div className="form-group">
                            <div className="custom-control custom-checkbox">
                              <input type="checkbox" className="custom-control-input" id="customCheckForm1" />
                              <label className="custom-control-label" htmlFor="customCheckForm1">Include my profile in the members directory.</label>
                            </div>
                          </div>
                          <button type="submit" className="btn">Save changes</button>
                        </form>

                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<form>
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="inputEmail4">Email</label>
      <input type="email" class="form-control" id="inputEmail4" />
    </div>
    <div class="form-group col-md-6">
      <label for="inputPassword4">Password</label>
      <div class="input-group">
        <input type="text" class="form-control" id="inputPassword4" />
        <div class="input-group-append">
          <i className="icon-lock"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label for="inputAddress">Address</label>
    <input type="text" class="form-control" id="inputAddress" placeholder="1234 Main St" />
  </div>
  <div class="form-row">
    <div class="form-group col-md-6">
      <label for="inputCity">City</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text">@</span>
        </div>
        <input type="text" class="form-control" id="inputCity" required />
      </div>
    </div>
    <div class="form-group col-md-6">
      <label for="inputState">State</label>
      <select id="inputState" class="form-control">
        <option blank="true">Choose...</option>
        <option value="1">One</option>
        <option value="2">Two</option>
        <option value="3">Three</option>
      </select>
    </div>
  </div>
  <div class="form-group">
    <label for="exampleFormControlTextarea1">Example textarea</label>
    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
  </div>
  <div class="form-group">
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="customCheckForm1" />
      <label class="custom-control-label" for="customCheckForm1">Include my profile in the members directory.</label>
    </div>
  </div>
  <button type="submit" class="btn">Save changes</button>
</form>`}
                        </code></pre>
                      </div>
                    </div>

                  </div>

                  <div className="mb-100">
                    <h2 className="border-bottom pb-24 mb-40">Card</h2>

                    <div className="row py-24">
                      <div className="col-6">

                        <div className="card p-24">Card body</div>

                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<div class="card p-24">Card body</div>`}
                        </code></pre>
                      </div>
                    </div>

                    <div className="row py-24">
                      <div className="col-6">

                        <ul className="list-grid list-grid--33 list-grid--sm-100">
                          <li>
                            <div className="card p-24">Card grid</div>
                          </li>
                          <li>
                            <div className="card p-24">Card grid</div>
                          </li>
                          <li>
                            <div className="card p-24">Card grid</div>
                          </li>
                        </ul>

                      </div>
                      <div className="col-6">
                        <pre><code className="language-html">
                          {`<ul className="list-grid list-grid--33 list-grid--sm-100">
  <li>
    <div className="card p-24">Card grid</div>
  </li>
  <li>
    <div className="card p-24">Card grid</div>
  </li>
  <li>
    <div className="card p-24">Card grid</div>
  </li>
</ul>`}
                        </code></pre>
                      </div>
                    </div>

                  </div>




                </div>
                <hr />
              </main>
            </DefaultLayout>
          );
        }
      )
    )
  )
);

class DesignSystem extends Component {
  render() {
    return (<DesignSystemWrapper />);
  }
}
export default DesignSystem;