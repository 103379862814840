import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import moment from 'moment';
import Breadcrumbs from 'ui/components/Breadcrumbs';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { routes } from 'env/routes';

import CardVideoRoom from 'ui/components/cards/CardVideoRoom';

@withCustomComponent('VideoRoomsPage')
@withTranslation()
@inject('appStore', 'authStore', 'communityStore')
@observer
class VideoRoomsPage extends Component {
  componentDidMount() {
    const { communityStore, authStore } = this.props;

    if (authStore.isLoggedIn) {
      communityStore.loadVideoRooms().catch((err) => {
        appStore
          .setPopMessage(
            t('Sorry, we could not load this page. {{err}}', { err })
          )
          .then(() => router.push(routes.home));
      });
    }
  }

  render() {
    const { t, appStore, communityStore, authStore } = this.props;
    const { business } = appStore;
    const { isLoadingVideoRooms, videoRooms } = communityStore;
    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={t('Virtual rooms - {{Name}}', { Name: t(business.Name) })}
      >
        <main className="content content--custom" role="main">
          <div className="container">
            <div className="d-block d-md-flex align-items-center mb-16">
              <h2 className="mr-12 mb-8 mb-md-0">{t('Virtual Rooms')}</h2>
              {/* <div className="d-inline-flex tag with-icon-left bg-gray-300">
                <i className="icon-marker-pin"></i>
                <span className="fw-500">{business.Name}</span>
              </div> */}
            </div>
            <div className="fs-14 mb-40">
              <div className="">
                <b>{t('Always-on virtual rooms')}</b>
              </div>
              <p className="mb-0 mxw-600px text-gray-700">
                {t(
                  "These virtual rooms let you quickly jump on to a video call with any other members of the space or your team. We don't record or keep any data about these calls but keep in mind that any member can jump in and out of them at any time."
                )}
              </p>
            </div>

            {!authStore.isLoggedIn && (
              <div>
                <Trans>
                  Please{' '}
                  <a href={routes.login + '?returnurl=' + routes.virtual_rooms}>
                    log in
                  </a>{' '}
                  or{' '}
                  <a
                    href={routes.signup + '?returnurl=' + routes.virtual_rooms}
                  >
                    create an account
                  </a>{' '}
                  to access virtual rooms
                </Trans>
              </div>
            )}

            {isLoadingVideoRooms && <LoadingSpinner />}

            {authStore.isLoggedIn &&
              !isLoadingVideoRooms &&
              videoRooms.Rooms.length == 0 && (
                <div className="zero-case">
                  <h3>{t('There are no virtual rooms available.')}</h3>
                  <p>{t('Check back soon!')}</p>
                </div>
              )}

            {authStore.isLoggedIn &&
              !isLoadingVideoRooms &&
              videoRooms.Rooms.length > 0 && (
                <>
                  <ul className="list-grid list-grid--33 list-grid--lg-50 list-grid--sm-100">
                    {videoRooms.Rooms.map((room, i) => (
                      <li key={i}>
                        <CardVideoRoom room={room} />
                      </li>
                    ))}
                  </ul>
                  <p className="mt-40 fs-12 text-gray-700 mxw-400px">
                    <Trans>
                      This service is{' '}
                      <a target="_blank" href="https://jitsi.org/">
                        Powered by Jitsi
                      </a>
                      .
                    </Trans>
                  </p>
                </>
              )}
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default VideoRoomsPage;
