import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import moment from 'moment';
import { removeHtml } from 'env/utils/strings';

export const FaqArticleMetaData = withCustomComponent('FaqArticleMetaData')(
  inject('appStore')(
    withRouter(
      observer(({ componentName, article }) => {
        const { t } = useTranslation();

        return (
          <div
            data-component-name={componentName}
            className="mb-32"
          >
            <div class="d-flex align-items-center fs-14 text-gray-700 mb-12">
              {article.GroupName && (
                <>
                  {t(article.GroupName)}
                  <span className="mx-8">&#8226;</span>
                </>
              )}
              {t('Last updated on {{date}}', {
                date: moment(article.UpdatedOn).format('L'),
              })}
            </div>
            <h2>{article.Title}</h2>
          </div>
        );
      })
    )
  )
);
