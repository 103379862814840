import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const HomeCtaSection = withCustomComponent('HomeCtaSection')(
  inject('appStore')(
    withRouter(
      observer(({ router, appStore, componentName }) => {
        const { t } = useTranslation();
        const { business } = appStore;
        const isVirtualDashboard = appStore.virtualDashboard;

        return (
          <div
            data-component-name={componentName}
            className={`home-cta-section ${
              isVirtualDashboard ? 'home-cta-section--virtual' : ''
            }`}
          >
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-12 col-lg-5 col-xl-5 pb-48 pb-lg-0">
                  <h2>{t('Join {{Name}}', { Name: t(business.Name) })}</h2>
                  <p className="mb-32">{t(business.ShortIntroduction)}</p>
                  <a className="btn btn-lg w-75" href="#">
                    {t('Become a member')}
                  </a>
                  <div className="mt-16 text-gray-600">
                    <span className='text-gray-700'>{t('Already in?')}</span>
                    <a hraf="#" className="text-gray-900 tdu ml-5">
                      {t('Sign in here')}
                    </a>
                  </div>
                </div>
                <div className="col-md-12 col-lg-1 col-xl-1"></div>
                <div className="col-md-12 col-lg-6 col-xl-6 mb-32">
                  <img className="img-fluid rounded" src="/img/home-cta.jpg" />
                </div>
              </div>
            </div>
          </div>
        );
      })
    )
  )
);
