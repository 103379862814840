import React, { Component } from 'react';
import Head from 'next/head';
import Link from 'next/link';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import { withRouter } from 'next/router';
import { routes } from 'env/routes';
import { removeHtml, shorten } from 'env/utils/strings';
import LoadingSpinner from 'ui/components/LoadingSpinner';

import CardEvent from 'ui/components/cards/CardEvent';
import { EventPageDetails } from 'ui/_pages/events/event/EventPageDetails';
import { EventPageComments } from 'ui/_pages/events/event/EventPageComments';

import { EventPageTickets } from 'ui/_pages/events/event/EventPageTickets';
import { EventPageAttendees } from 'ui/_pages/events/event/EventPageAttendees';
import { EventPageSocialLinks } from 'ui/_pages/events/event/EventPageSocialLinks';
import { EventPageRelatedEvents } from 'ui/_pages/events/event/EventPageRelatedEvents';

@withCustomComponent('EventDetailsPage')
@withRouter
@withTranslation()
@inject('appStore', 'authStore', 'eventsStore')
@observer
class EventDetailsPage extends Component {
  componentDidMount() {
    this.loadEvent();
  }

  loadEvent = () => {
    const { t, appStore, router, eventsStore } = this.props;
    const { eventPage } = eventsStore;
    const eventId = router.query.event_id;

    if (!eventPage.Event && eventPage.RedirectTo) {
      router.push(routes.events);
      return;
    }

    if (eventPage.Event.Id != eventId) {
      // Read any notifications about this record
      appStore.dismissNotification(eventId).catch(() => null);

      eventsStore.loadEventPage(eventId).catch((err) => {
        appStore.setPopMessage(
          t('Sorry, we could not load this page. {{err}}', { err })
        );
      });
    }
  };

  componentDidUpdate() {
    this.loadEvent();
  }

  render() {
    const { router, t, eventsStore, appStore } = this.props;
    const { business, configuration } = appStore;
    const event = eventsStore.eventPage.Event;
    const isLoading = !event || event.Id != router.query.event_id;
    console.log(event);
    return (
      <>
        <Head>
          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:site"
            content={`@${configuration['Social.Twitter']}`}
          />
          <meta
            name="twitter:title"
            content={`${shorten(removeHtml(event?.Name), 70)}`}
          />
          <meta
            name="twitter:description"
            content={`${shorten(removeHtml(event?.ShortDescription), 200)}`}
          />
          <meta
            name="twitter:creator"
            content={`@${configuration['Social.Twitter']}`}
          />
          {event?.HasLargeImage && (
            <meta
              name="twitter:image:src"
              content={`${business.NativeHomeUrlWithLanguage}/Events/GetSmallImage?id=${event.Id}&w=800&cache=${event.UpdatedOn}`}
            />
          )}
          <meta
            property="og:url"
            key="og:url"
            content={`${business.HomeUrl}/${routes.event_by_id(event)}?public`}
          />
          <meta
            property="og:title"
            key="og:title"
            content={removeHtml(event?.Name)}
          />
          {event?.HasLargeImage && (
            <meta
              property="og:image"
              key="og:image"
              content={`${business.NativeHomeUrlWithLanguage}/Events/GetSmallImage?id=${event.Id}&w=600h=314&mode=Pad&cache=${event.UpdatedOn}`}
            />
          )}
          <meta
            property="og:image:type"
            content="image/jpeg"
            key="og:image:type"
            content="image/jpeg"
          />
          <meta
            property="og:description"
            key="og:description"
            content={removeHtml(event?.ShortDescription)}
          />
          <meta
            property="og:site_name"
            key="og:site_name"
            content={business.Name}
          />
        </Head>
        <DefaultLayout
          componentName={this.props.componentName}
          title={t('{{EventName}} - {{Name}}', {
            EventName: !isLoading && event ? removeHtml(event.Name) : '...',
            Name: t(business.Name),
          })}
        >
          <main className="content content--custom" role="main">
            <div className="container">
              {isLoading && <LoadingSpinner />}
              {!isLoading && (
                <>
                  <div className="mb-20">
                    <Link href={routes.events}>
                      <a className="btn btn-icon btn-white">
                        <i className="icon-go-back"></i>
                        {t('Back')}
                      </a>
                    </Link>
                  </div>
                  <div className="row mb-32">
                    <div className="col-xl-8 mb-24">
                      <CardEvent event={event} sizeLarge={true} />
                      <EventPageDetails />
                      <EventPageComments />
                    </div>
                    <div className="col-xl-4">
                      <EventPageTickets />
                      <EventPageAttendees />
                      <EventPageSocialLinks />
                      <EventPageRelatedEvents />
                    </div>
                  </div>
                </>
              )}
            </div>
          </main>
        </DefaultLayout>
      </>
    );
  }
}

export default EventDetailsPage;
