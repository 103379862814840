import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import {withRouter} from 'next/router';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import AllowancesSection from 'ui/_pages/plan/AllowancesSection';
import PortalPageTitle from 'ui/components/Header/PortalPageTitle';
import ProductsSection from 'ui/_pages/plan/ProductsSection';

let Benefits = props => {
  const {
    t,
    appStore,
    authStore,
    invoicingStore,
  } = props;

  useEffect(() => {
    invoicingStore.loadBenefits().catch((err) => {
      appStore.setPopMessage(
        t('Sorry, we could not load your benefits.', {err}),
      );
    });
  }, []);

  if (!(invoicingStore.hasLoadedBenefits)) {
    return <LoadingLayout/>;
  }

  return (
    <DefaultLayout>
      <main className="content content--custom pt-0" role="main">
        <PortalPageTitle
          title={t('Benefits and products')}
        />

        <div className="container">
          <AllowancesSection/>
          <ProductsSection/>
        </div>
      </main>
    </DefaultLayout>
  );
};

export default Benefits = withCustomComponent('Benefits')(
  withRouter(
    withTranslation()(
      inject('appStore', 'authStore', 'invoicingStore', 'checkoutStore')(
        observer(Benefits),
      ),
    ),
  ),
);
