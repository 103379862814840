import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import { shorten } from 'env/utils/strings';
import withCustomComponent from 'ui/components/withCustomComponent';
import ReactPlayer from 'react-player';
export const CommunityBoardVideoFile = withCustomComponent(
  'CommunityBoardVideoFile'
)(
  inject('appStore')(
    withRouter(
      observer(({ appStore, fileId }) => {
        const { t } = useTranslation();
        const { business } = appStore;

        return (
          <div>
            <ReactPlayer
              controls
              width={'100%'}
              url={[
                {
                  src: `${business.NativeHomeUrlWithLanguage}/Content/CommunityThreadFiles?id=${fileId}`,
                },
              ]}
            />
          </div>
        );
      })
    )
  )
);
