import React from 'react';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import ActiveLink from 'ui/components/ActiveLink';

export const CoursesPageHeader = withCustomComponent('CoursesPageHeader')(
  inject('appStore', 'authStore')(
    withRouter(
      observer(({ componentName, authStore, router }) => {
        const { t } = useTranslation();
        const { accessToken } = authStore;
        
        return (
          <div 
            data-component-name={componentName}
            className="d-block d-md-flex align-items-center justify-content-between"
          >
            <div className="d-block d-md-flex align-items-center">
              <ActiveLink
                href={routes.courses}
              >
                <a
                  className={`btn btn-link btn-op-02 align-items-baseline tdn mr-24 mr-md-32 ${
                    router.route === routes.courses ? 'active' : ''
                  }`}
                >
                  <h2 className="mb-0 mr-6">{t('Courses')}</h2>
                </a>
              </ActiveLink>

              {accessToken &&
                <ActiveLink
                  href={routes.my_courses}
                >
                  <a
                    className={`btn btn-link btn-op-02 align-items-baseline tdn`}
                  >
                    <h2 className="mb-0 mr-6">{t('My Courses')}</h2>
                  </a>
                </ActiveLink>
              }
            </div>
          </div>
        );
      })
    )
  )
);
