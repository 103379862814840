import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import {BasketItemFactory} from 'ui/_pages/checkout/basketItemFactory';
import {CheckoutDiscountSection} from 'ui/_pages/checkout/CheckoutDiscountSection';
import {DiscountInfoSection} from 'ui/_pages/checkout/DiscountInfoSection';
import {useTranslation} from 'react-i18next';

import {CheckoutClearButton} from 'ui/_pages/checkout/CheckoutClearButton';
import {CheckoutButton} from 'ui/_pages/checkout/CheckoutButton';
import {CheckoutTotalPrice} from 'ui/_pages/checkout/CheckoutTotalPrice';
import {BookingDetails} from 'ui/_pages/bookings/calendar/BookingDetails';
import {routes} from 'env/routes';
import {CheckoutZeroCase} from 'ui/_pages/checkout/CheckoutZeroCase';
import Link from 'next/link';
import {PriceTaxStatus} from 'ui/components/PriceTaxStatus';
import LoadingLayout from '../../ui/layouts/LoadingLayout';

let Checkout = props => {
  const {
    appStore,
    authStore,
    checkoutStore,
    componentName,
    router,
    invoicingStore,
  } = props;
  const {t} = useTranslation();

  const {business, configuration} = appStore;
  const {isLoggedIn} = authStore;
  const {basket, invoicePreview} = checkoutStore;
  const isCorporateDashboard = appStore.corporateDashboard;
  const [isInvoiceCreated, setIsInvoiceCreated] = useState(false);

  useEffect(() => {
    if (authStore.isLoggedIn && !checkoutStore.isLoadingInvoicePreview) {
      checkoutStore.loadInvoicePreview().catch((err) => {
        appStore.setPopMessage(
          t('Sorry, we could not load this page. {{err}}', {err}),
        );
      });
    }
  }, []);

  if (isInvoiceCreated) {
    return <LoadingLayout/>;
  }

  return (
    <DefaultLayout
      componentName={componentName}
      title={t('Checkout - {{Name}}', {Name: t(business.Name)})}
    >
      <main className="content content--custom pt-0" role="main">
        <BookingDetails/>
        <div className="portal-title-wrapper">
          <div className="container portal-title-container">
            <h1 className="portal-title">
              {basket.length > 0
                ? appStore.corporateDashboard
                  ? t('Summary')
                  : t('Your basket')
                : appStore.corporateDashboard
                  ? t('Summary')
                  : t('Your basket is empty')}
            </h1>
          </div>
        </div>
        <div className="container">

          {basket.length == 0 && <CheckoutZeroCase/>}

          {basket.length > 0 && (
            <div className="row">
              <div className="col-lg-8 col-xl-8">
                {basket.map((item, i) => (
                  <BasketItemFactory key={i} basketItem={item}/>
                ))}
                {!isCorporateDashboard &&
                  invoicePreview &&
                  invoicePreview.Lines && <DiscountInfoSection/>}
                <PriceTaxStatus/>
                {basket.length > 0 && <CheckoutClearButton/>}
              </div>
              <div className="mt-40 mt-lg-0 col-lg-4 offset-lg-0 col-xl-3 offset-xl-1">
                {!isCorporateDashboard && (
                  <>
                    {isLoggedIn && <CheckoutDiscountSection/>}
                    <CheckoutTotalPrice/>
                  </>
                )}
                {isLoggedIn && (
                  <CheckoutButton
                    completeUrl={routes.checkout_complete}
                    returnUrl={routes.checkout_payment}
                    onAfterCreatingInvoice={() => setIsInvoiceCreated(true)}
                  />
                )}
                {!isLoggedIn && (
                  <>
                    <Link
                      href={
                        routes.login +
                        '?returnurl=' +
                        encodeURIComponent(routes.checkout)
                      }
                    >
                      <a className="btn btn-lg d-flex w-100">
                        {t('Sign in')}
                      </a>
                    </Link>
                    {(configuration['Members.CanSignup'] ||
                      configuration['Bookings.EnableGuestBookings']) && (
                      <Link
                        href={
                          routes.signup +
                          '?returnurl=' + encodeURIComponent(routes.checkout)
                          /*(isLoggedIn
                            ? encodeURIComponent(routes.checkout)
                            : encodeURIComponent(routes.signup_summary))*/
                        }
                      >
                        <a className="btn btn-outline btn-lg d-flex w-100 mt-8">
                          {t('Create an account')}
                        </a>
                      </Link>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </main>
    </DefaultLayout>
  );
};

export default Checkout = withCustomComponent('Checkout')(
  inject('appStore', 'authStore', 'checkoutStore', 'invoicingStore')(
    observer(Checkout),
  ),
);
