import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import AuthStore from 'env/stores/authStore';
import ActiveLink from 'ui/components/ActiveLink';

export const CalendarHeader = withCustomComponent('CalendarHeader')(
  inject('authStore')(
    withRouter(
      observer(({ newBooking, authStore, componentName }) => {
        const { t } = useTranslation();
        const { isLoggedIn } = authStore;
        return (
          <>
            <div
              data-component-name={componentName}
              className="d-block d-md-flex align-items-center justify-content-between"
            >
              <div className="d-block d-md-flex align-items-center">
                <h2 className="mb-0 mr-12">{t('Events')}</h2>
              </div>

              <div className="d-flex d-md-block justify-content-between mt-20 mt-md-0">
                <ActiveLink href={routes.events} activeOnRootPath={true}>
                  <a className={`btn  btn-icon btn-gray-light btn-md-xs`}>
                    <i className="icon-card-view d-none d-lg-block"></i>
                    <span className="pl-4 pl-lg-0">{t('Card view')}</span>
                  </a>
                </ActiveLink>

                <ActiveLink
                  href={routes.events_calendar}
                  activeOnRootPath={true}
                >
                  <a className="btn  btn-icon btn-gray-light ml-0 ml-md-8 btn-md-xs">
                    <i className="icon-calendar-view d-none d-lg-block"></i>
                    <span className="pl-4 pl-lg-0">{t('Calendar view')}</span>
                  </a>
                </ActiveLink>
              </div>
            </div>

            <hr className="mt-40 mb-20" />
          </>
        );
      })
    )
  )
);
