/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
@withCustomComponent('VideoRoomModal')
@withTranslation()
@observer
class VideoRoomModal extends Component {
  componentDidMount() {
    window.$('#video-form').modal({
      backdrop: 'static',
      keyboard: false,
      show: true,
    });
  }

  componentWillUnmount() {
    window.$('#video-form').modal('hide');
  }

  render() {
    const { t, roomUrl } = this.props;
    return (
      <div
        className="modal in modal--book"
        id="video-form"
        role="dialog"
        aria-labelledby="modal-bookLabel"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                aria-label={t('Close')}
                onClick={this.props.close}
              >
                <span aria-hidden="true">&times;</span>
              </button>

              <h3 className="modal-title" id="modal-bookLabel">
                {t('Virtual Room')}
              </h3>
            </div>
            <div className="modal-body">
              <iframe
                allow="camera;microphone"
                style={{ border: 0, margin: 0, width: '100%', height: '60vh' }}
                src={roomUrl}
              />
              <div>
                <a href={roomUrl}>{t('Open in separate window')}</a>
              </div>
            </div>
            <div className="modal-footer">
              <a
                onClick={(ev) => {
                  ev.preventDefault();
                  this.props.close();
                }}
                href="#"
                className="btn btn--primary btn--sm"
              >
                {t('Close')}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoRoomModal;
