import React, { useState } from 'react';
import Link from 'next/link';
import _ from 'lodash';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { CommunityBoardListFile } from 'ui/_pages/community/list/CommunityBoardListFile';
import { shorten } from 'env/utils/strings';
import withCustomComponent from 'ui/components/withCustomComponent';
import { CommunityBoardVideoFile } from './CommunityBoardVideoFile';
export const CommunityBoardListFiles = withCustomComponent(
  'CommunityBoardListFiles'
)(
  inject('appStore')(
    withRouter(
      observer(({ componentName, appStore, thread }) => {
        const { business } = appStore;
        const { t } = useTranslation();
        const [lightboxOpen, setLightboxOpen] = useState(false);
        const [photoIndex, setPhotoIndex] = useState(0);
        const customModalStyle = {
          overlay: {
            zIndex: '100000',
          },
          content: {
            zIndex: '100000',
          },
        };

        const isVideo = (fileName) =>
          fileName.indexOf('.mp4') > -1 ||
          fileName.indexOf('.webm') > -1 ||
          fileName.indexOf('.ogv') > -1;

        const images = thread.FileIDs.reduce((acc, current, fileIndex) => {
          const fileName = thread.FileNames[fileIndex].toLowerCase();
          const isImage =
            fileName.indexOf('.jpg') > -1 ||
            fileName.indexOf('.png') > -1 ||
            fileName.indexOf('.gif') > -1;
          if (isImage) {
            acc.push(
              `${business.NativeHomeUrlWithLanguage}/Content/CommunityThreadFiles?id=${current}&w=2048`
            );
          }
          return acc;
        }, []);

        const files = thread.FileIDs.reduce((acc, current, fileIndex) => {
          const fileName = thread.FileNames[fileIndex].toLowerCase();

          if (!isVideo(fileName)) {
            acc.push(current);
          }
          return acc;
        }, []);

        const videos = thread.FileIDs.reduce((acc, current, fileIndex) => {
          const fileName = thread.FileNames[fileIndex].toLowerCase();
          const isVideo = fileName.indexOf('.mp4') > -1;
          if (isVideo) {
            acc.push(current);
          }
          return acc;
        }, []);

        const fileArray = thread.FileIDs;
        const maxShowFiles = 4;

        return (
          thread.FileIDs.length > 0 && (
            <div
              data-component-name={componentName}
              className={`community-board-list-files max-files-${maxShowFiles} array-legth-${fileArray.length}`}
            >
              {lightboxOpen && (
                <Lightbox
                  imageTitle={shorten(thread.Subject, 50)}
                  imageCaption={shorten(thread.Message, 100)}
                  mainSrc={images[photoIndex]}
                  nextSrc={
                    images.length > 1 &&
                    images[(photoIndex + 1) % images.length]
                  }
                  prevSrc={
                    images.length > 1 &&
                    images[(photoIndex + images.length - 1) % images.length]
                  }
                  onCloseRequest={() => {
                    setPhotoIndex(0);
                    setLightboxOpen(false);
                  }}
                  onMovePrevRequest={() =>
                    setPhotoIndex(
                      (photoIndex + images.length - 1) % images.length
                    )
                  }
                  onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % images.length)
                  }
                  reactModalStyle={customModalStyle}
                />
              )}

              {videos.map((fileId, i) => (
                <CommunityBoardVideoFile
                  thread={thread}
                  fileIndex={i}
                  fileId={fileId}
                />
              ))}

              <ul>
                {files.length >= maxShowFiles + 1 ? (
                  <>
                    {_.take(files, maxShowFiles - 1).map((fileId, i) => (
                      <li key={i}>
                        <CommunityBoardListFile
                          setLightboxOpen={(fileIndex) => {
                            setPhotoIndex(fileIndex);
                            setLightboxOpen(true);
                          }}
                          thread={thread}
                          fileIndex={i}
                          fileId={fileId}
                        />
                      </li>
                    ))}
                    <li className="other-files">
                      <CommunityBoardListFile
                        setLightboxOpen={(fileIndex) => {
                          setPhotoIndex(fileIndex);
                          setLightboxOpen(true);
                        }}
                        thread={thread}
                        fileIndex={maxShowFiles - 1}
                        fileId={files[maxShowFiles - 1]}
                        overlayValue={files.length - maxShowFiles}
                      />
                    </li>
                  </>
                ) : (
                  _.take(files, maxShowFiles).map((fileId, i) => (
                    <li key={i}>
                      <CommunityBoardListFile
                        setLightboxOpen={(fileIndex) => {
                          setPhotoIndex(fileIndex);
                          setLightboxOpen(true);
                        }}
                        thread={thread}
                        fileIndex={i}
                        fileId={fileId}
                      />
                    </li>
                  ))
                )}
              </ul>
              <div className="clearfix"></div>
              {/*
              <small className="help-block">
                {t(
                  'Please be careful when downloading these files, some file types may harm your computer.'
                )}
              </small>
              */}
            </div>
          )
        );
      })
    )
  )
);
