/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { action } from 'mobx';

import withCustomComponent from 'ui/components/withCustomComponent';
import asForm from 'ui/components/forms/asForm';
import { Multiselect } from 'react-widgets';
import { CoworkerAvatar } from 'ui/components/CoworkerAvatar';
import { TeamsAttendeesAccordion } from '../components/TeamsAttendeesAccordion';
import { PreviousGuestsAccordion } from '../components/PreviousGuestsAccordion';
import { NewAttendeeAccordion } from '../components/NewAttendeeAccordion';

@withCustomComponent('BookingVisitorsForm')
@withTranslation()
@inject('appStore', 'bookingsStore', 'communityStore', 'authStore')
@observer
class BookingVisitorsForm extends Component {
  updateBooking = this.props.updateBooking;

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      users: [],
      // this object is populated with teams and whether they're added
      teamsIsAdded: [],
      newAttendee: {},
    };
  }

  getCurrentBooking = () => {
    const { bookingsStore } = this.props;
    const { booking } = bookingsStore;
    return booking;
  };

  getResource = () => {
    const { bookingsStore } = this.props;
    const { booking, resources } = bookingsStore;
    return (
      booking &&
      _(resources)
        .filter((r) => r.Id === booking.ResourceId)
        .first()
    );
  };

  @action removeVisitorByEmail(email) {
    const { bookingsStore } = this.props;
    const booking = this.getCurrentBooking();
    booking.BookingVisitors = booking.BookingVisitors.filter(
      (v) => v.VisitorEmail !== email
    );

    bookingsStore.loadBookingPrice();
  }

  @action addVisitor(
    Id,
    FullName,
    Email,
    PhoneNumber,
    DefaultAvatarUrl = '',
    GravatarHashedEmail = ''
  ) {
    const { bookingsStore } = this.props;
    const booking = this.getCurrentBooking();
    booking.BookingVisitors.push({
      Id: Id || 0,
      VisitorFullName: FullName,
      VisitorEmail: Email,
      VisitorPhoneNumber: PhoneNumber,
      DefaultAvatarUrl,
      GravatarHashedEmail,
    });

    bookingsStore.loadBookingPrice();
  }

  loadPreviousVisitors = () => {
    const { t, bookingsStore, appStore } = this.props;
    bookingsStore
      .loadPreviousVisitors()
      .then((res) => {
        this.setState({
          users: toJS(res).map((el) => {
            el.Visitor = {};
            el.VisitorFullName = el.FullName;
            el.VisitorEmail = el.Email;
            return el;
          }),
        });
      })
      .catch((err) => null);
  };

  loadTeams = async () => {
    const { authStore, appStore } = this.props;

    await authStore.loadTeamProfiles().catch((err) => null);

    const { teamProfiles: teamsProfiles } = authStore;
    teamsProfiles.forEach((profile) =>
      this.loadAndSetTeamsIsAdded({ ...profile.Team })
    );
  };

  loadAndSetTeamsIsAdded = (team) => {
    let newTeamsIsAdded = { ...this.state.teamsIsAdded };
    newTeamsIsAdded[team.Name] = team.AllTeamMembers;
    this.setState({ teamsIsAdded: newTeamsIsAdded });
  };

  isWholeTeamAdded = (allTeamMembers) => {
    const { authStore, bookingsStore } = this.props;
    const { booking } = bookingsStore;

    for (let i = 0; i < allTeamMembers.length; i++) {
      const member = allTeamMembers[i];
      if (member.Email === authStore.me.Email) continue;

      const isMemberAdded = booking.BookingVisitors?.some(
        (v) => v.VisitorEmail === member.Email
      );
      if (!isMemberAdded) {
        return false;
      }
    }

    return true;
  };

  updateTeamCheckbox = () => {
    const { authStore } = this.props;
    const { teamProfiles: teamsProfiles } = authStore;
    teamsProfiles.forEach(({ Team: team }) => {
      const isAdded = this.isWholeTeamAdded(team.AllTeamMembers);

      let newTeamsIsAdded = { ...this.state.teamsIsAdded };
      newTeamsIsAdded[team.Name].isAdded = isAdded;
      this.setState({ teamsIsAdded: newTeamsIsAdded });
    });
  };

  componentDidMount() {
    const { bookingsStore } = this.props;
    if (!bookingsStore.hasLoadedResources) bookingsStore.loadResources();

    this.loadPreviousVisitors();
    this.loadTeams();
  }

  render() {
    const {
      t,
      bookingsStore,
      authStore,
      appStore,
      isSidePopup,
      updateProperty,
    } = this.props;
    const { booking } = bookingsStore;
    const { configuration } = appStore;
    const { teamProfiles: teamsProfiles } = authStore;

    if (!booking.ResourceId || !configuration['PublicWebSite.Visitors'])
      return <></>;

    return (
      <>
        {(this.state.isVisible || booking.BookingVisitors.length > 0) && (
          <>
            <h5>{t('Attendees')}</h5>

            <Multiselect
              dataKey='VisitorFullName'
              textField='VisitorFullName'
              value={booking.BookingVisitors.slice()}
              data={this.state.users.filter((u) => u.VisitorFullName)}
              placeholder={t('Type and select')}
              itemComponent={({ item }) => (
                <MultiselectItem item={item} business={appStore.business} />
              )}
              tagComponent={({ item }) => (
                <MultiselectItem
                  item={item}
                  business={appStore.business}
                  isBold={true}
                />
              )}
              onChange={(attendees, { action, dataItem: attendee }) => {
                if (action === 'insert') {
                  this.addVisitor(
                    attendee.Id,
                    attendee.FullName,
                    attendee.Email,
                    '',
                    attendee.DefaultAvatarUrl,
                    attendee.GravatarHashedEmail
                  );
                } else if (attendee) {
                  this.removeVisitorByEmail(attendee.VisitorEmail);
                }

                this.updateTeamCheckbox();
              }}
              messages={{
                emptyList: t('There are no items in this list'),
                emptyFilter: t('No items match your search'),
              }}
            />

            <PreviousGuestsAccordion
              addAttendee={(
                id,
                fullName,
                email,
                phoneNumber,
                defaultAvatarUrl,
                gravatarHashedEmail
              ) => {
                this.addVisitor(
                  id,
                  fullName,
                  email,
                  phoneNumber,
                  defaultAvatarUrl,
                  gravatarHashedEmail
                );
                this.updateTeamCheckbox();
              }}
              removeAttendee={(attendeeEmail) => {
                this.removeVisitorByEmail(attendeeEmail);
                this.updateTeamCheckbox();
              }}
            />

            <TeamsAttendeesAccordion
              teamsProfiles={toJS(teamsProfiles)}
              teamsIsAdded={this.state.teamsIsAdded}
              setTeamsIsAdded={(teamsIsAdded) =>
                this.setState({ teamsIsAdded })
              }
              addAttendee={(id, fullName, email, phoneNumber) => {
                this.addVisitor(id, fullName, email, phoneNumber);
                this.updateTeamCheckbox();
              }}
              removeAttendee={(attendeeEmail) => {
                this.removeVisitorByEmail(attendeeEmail);
                this.updateTeamCheckbox();
              }}
            />

            <NewAttendeeAccordion
              setUsers={(users) => this.setState({ users })}
              users={this.state.users}
              addAttendee={(id, fullName, email, phoneNumber) =>
                this.addVisitor(id, fullName, email, phoneNumber)
              }
              isSidePopup={isSidePopup}
            />
          </>
        )}

        {configuration['PublicWebSite.Visitors'] &&
          !this.state.isVisible &&
          !booking.BookingVisitors.length && (
            <a
              href='#'
              className='btn btn-gray btn-icon -sm mr-8 my-4'
              onClick={() => this.setState({ isVisible: true })}
            >
              <i className='icon-attendee-add'></i>
              {t('Add attendees')}
            </a>
          )}
      </>
    );
  }
}

export default asForm(BookingVisitorsForm);

const MultiselectItem = ({ item, business, isBold }) => {
  if (item.DefaultAvatarUrl) {
    return (
      <div className='d-flex align-items-center'>
        <img
          src={`${business.NativeHomeUrlWithLanguage}/user/getavatarbyemail?email=${item.Email}&h=16&w=16&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}
          className='avatar avatar--16'
        />
        <span className={`fs-12 ${isBold ? 'fw-500' : ''} ml-4`}>
          {item?.VisitorFullName}
        </span>
      </div>
    );
  } else {
    return (
      <div className='d-flex align-items-center'>
        <CoworkerAvatar
          title={item?.VisitorFullName}
          coworker_id={item?.Id}
          size={16}
          imgClassName='avatar avatar--16'
        />
        <span className={`fs-12 ${isBold ? 'fw-500' : ''} ml-4`}>
          {item?.VisitorFullName}
        </span>
      </div>
    );
  }
};
