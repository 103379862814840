import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
export const CommunityBoardItemReputation = withCustomComponent(
  'CommunityBoardItemReputation'
)(
  inject(
    'appStore',
    'authStore',
    'communityStore'
  )(
    withRouter(
      observer(
        ({ authStore, communityStore, thread, guests, componentName }) => {
          const { t } = useTranslation();
          const { isLoggedIn } = authStore;

          const follow = () => {
            communityStore.followThread(thread);
          };

          const like = () => {
            communityStore.likeThread(thread);
          };

          const comment = () => {};

          return (
            <div
              data-component-name={componentName}
              className="community-board-item-reputation"
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  {isLoggedIn && (
                    <a
                      onClick={(ev) => {
                        ev.preventDefault();
                        like();
                      }}
                      href="#"
                      className="btn btn-icon btn-link text-gray-700 mr-16"
                    >
                      {thread.LikedByUser ? (
                        <i className="icon-likes text-red mr-4"></i>
                      ) : (
                        <i className="icon-likes mr-4"></i>
                      )}
                      <span className="fs-14 lh-13">
                        {thread.LikeCount == 0 && t('Like')}
                        {thread.LikeCount > 0 &&
                          t('{{count}} like', { count: thread.LikeCount })}
                      </span>
                    </a>
                  )}
                  {thread.MessageCount > 0 && (
                    <div className="d-inline-flex align-items-center mr-16">
                      <i className="icon-replies fs-24 text-gray-900 mr-4"></i>{' '}
                      <span className="text-gray-700 fs-14 lh-13">
                        {t('{{count}} reply', { count: thread.MessageCount })}
                      </span>
                    </div>
                  )}
                  {thread.Private && (
                    <div className="d-none d-md-inline-flex align-items-center mr-16">
                      <i className="icon-lock fs-24 text-gray-900 mr-4"></i>
                      {guests && (
                        <span className="text-gray-700 fs-14 lh-13">
                          {t('Private message with {{guests}}', {
                            guests: guests.map((x) => x.FullName).join(', '),
                          })}
                        </span>
                      )}
                      {!guests && (
                        <span className="text-gray-700 fs-14 lh-13">
                          {t('Private message')}
                        </span>
                      )}
                    </div>
                  )}
                </div>

                <div className="d-flex align-items-center">
                  {isLoggedIn && (
                    <a
                      href="#"
                      onClick={(ev) => {
                        ev.preventDefault();
                        follow();
                      }}
                      className="btn  -xs btn-outline"
                    >
                      {thread.FollowedByUser && t('Unfollow')}
                      {!thread.FollowedByUser && t('Follow')}
                    </a>
                  )}
                </div>
              </div>
            </div>
          );
        }
      )
    )
  )
);
