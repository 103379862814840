import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import Link from 'next/link';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import { routes } from 'env/routes';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import CardBookingsBooked from 'ui/components/cards/CardBookingsBooked';
import LoadingWidget from 'ui/_pages/home/components/LoadingWidget/LoadingWidget';
import { BookingsZeroCase } from './BookingsZeroCase';

@withCustomComponent('BookingsWidget')
@withTranslation()
@inject('appStore', 'bookingsStore')
@observer
class BookingsWidget extends Component {
  componentDidMount() {
    const { team, bookingsStore } = this.props;
    if (team) {
      bookingsStore.loadTeamBookings().catch(() => null);
    }
    bookingsStore.loadUpcomingBookings().catch(() => null);
  }

  render() {
    const { t, team, showZeroCase = false, bookingsStore } = this.props;
    const { hasLoadedUpcomingBookings } = bookingsStore;

    let allBookings = bookingsStore.upcomingBookings;
    if (team) {
      allBookings = bookingsStore.teamBookings.concat(
        bookingsStore.upcomingBookings
      );
    }

    const hasBookings = allBookings.length > 0;
    const bookings = allBookings;

    if (!hasLoadedUpcomingBookings)
      return (
        <LoadingWidget
          title={team ? t(`Upcoming team bookings`) : t(`Upcoming bookings`)}
        />
      );

    return (
      <div data-component-name={this.props.componentName}>
        {!hasBookings && showZeroCase && <BookingsZeroCase />}
        {hasBookings && (
          <>
            <div className="row align-items-center mt-40 mb-4">
              <div className="col-8">
                <h6 className="mb-0">
                  {team ? t(`Upcoming team bookings`) : t(`Upcoming bookings`)}
                </h6>
              </div>
              <div className="col-4 text-right lh-1">
                <Link href={routes.my_bookings}>
                  <a className="btn btn-link text-gray-900 fw-500">
                    {t('My bookings')}
                  </a>
                </Link>
              </div>
            </div>
            <div className="row dashboard-bookings-widget">
              {bookings.map((booking, i) => (
                <div className="col-md-6 col-xl-4 mt-12" key={i}>
                  <CardBookingsBooked booking={booking} index={i} team={team} />
                </div>
              ))}
            </div>
            <hr className="mt-40 mb-0" />
          </>
        )}
      </div>
    );
  }
}
export default BookingsWidget;
