import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { routes } from 'env/routes';
import Link from 'next/link';
import { withRouter } from 'next/router';
import withCustomComponent from 'ui/components/withCustomComponent';
import { scrollToAnchorElement } from 'env/utils/scrolling';

@withRouter
@withCustomComponent('AccountNavigationMenu')
@withTranslation()
@inject('authStore', 'appStore', 'bookingsStore')
@observer
class AccountNavigationMenu extends Component {
  highlightCurrentPosition = () => {
    const $ = window.$;
    var safeHeaderHeight = $('.site-header').height() + 48;
    $('#js-nav-smooth li li').removeClass('active');
    var top = $(window).scrollTop();
    var anchors = $('#js-nav-smooth li.active a');

    for (var i = anchors.length - 1; i >= 0; i--) {
      var v = anchors[i];

      var href = $(v).attr('href');
      if (!href) continue;

      const id = href.split('#').pop();
      if (id.indexOf('/') > -1) continue;
      if (id.length === 0) continue;

      const $div = $('#' + id);
      if ($div.length === 0) continue;
      if ($div.is(':visible') === false) continue;

      if ($div.offset().top - safeHeaderHeight - 100 <= top) {
        $(v).parent().addClass('active');
        return;
      }
    }
  };

  initLateralSmoothNav = () => {
    const $ = window.$;
    const { router } = this.props;
    if ($('#js-nav-smooth').length > 0) {
      $('.content').scroll(() => {
        this.highlightCurrentPosition();
      });

      $('#js-nav-smooth li.active a').on('click', (e) => {
        const id = $(e.target).attr('href').split('#').pop();
        if (id.indexOf('/') > -1) return;
        try {
          scrollToAnchorElement(id);
        } catch {
          //noop
        }
        e.preventDefault();
      });
    }

    if ($('.js-link-select-navigation').length > 0) {
      $('.js-link-select-navigation select').on('change', function (e) {
        if ($(this).val() !== '') {
          router.push($(this).val());
        }
      });
    }

    this.highlightCurrentPosition();
  };

  componentDidMount() {
    this.initLateralSmoothNav();
  }

  render() {
    const { t, router, authStore, bookingsStore, appStore } = this.props;
    const { configuration, corporateDashboard } = appStore;
    const { customer } = authStore;
    const { customFieldsGroups } = authStore;
    const { resources } = bookingsStore;

    return (
      <>
        <nav
          data-component-name={this.props.componentName}
          className="nav-lateral"
          id="js-nav-smooth"
          role="navigation"
        >
          <ul>
            <li
              id={'account-nav-account'}
              className={
                router.pathname === routes.profile ||
                router.pathname === routes.notifications
                  ? 'active'
                  : null
              }
            >
              {configuration['PublicWebSite.MyAccount'] && (
                <Link href={routes.profile}>
                  <a href="#">{t('My Account')}</a>
                </Link>
              )}

              <ul
                className={
                  router.pathname === routes.profile ||
                  router.pathname === routes.notifications
                    ? null
                    : 'hide'
                }
              >
                <li>
                  <a href="#your-appearance">{t('Photo and cover')}</a>
                </li>
                <li>
                  <a href="#account-details">{t('Personal details')}</a>
                </li>
                {!corporateDashboard &&
                  configuration['Members.ViewInvoices'] &&
                  configuration['SignupForm.ShowBillingSection'] == 'true' && (
                    <li>
                      <a href="#billing-details">{t('Billing details')}</a>
                    </li>
                  )}
                {customFieldsGroups.map((g, i) => (
                  <li key={i}>
                    <a href={`#customfields-${i}`}>
                      {g.group === 'null' ? 'Other details' : g.group}
                    </a>
                  </li>
                ))}

                <li>
                  <a href="#professional-profile">
                    {t('Professional profile')}
                  </a>
                </li>
                <li>
                  <a href="#social-networks">{t('Social networks')}</a>
                </li>
                <li>
                  <a href="#email-password">{t('Password')}</a>
                </li>
                <li>
                  <a href="#notifications-settings">{t('Notifications')}</a>
                </li>
                <li>
                  <a href="#language">{t('Language')}</a>
                </li>
              </ul>
            </li>

            {customer?.TeamNames && (
              <li
                id={'account-nav-team'}
                className={
                  router.pathname === routes.profile_team ? 'active' : null
                }
              >
                <Link href={routes.profile_team}>
                  <a href="#">{t('My team')}</a>
                </Link>
              </li>
            )}

            {!corporateDashboard && (
              <li
                id={'account-nav-plans'}
                className={router.pathname === routes.plan ? 'active' : null}
              >
                <Link href={routes.plan}>
                  <a href="#">{t('Plans and benefits')}</a>
                </Link>
                <ul className={router.pathname === routes.plan ? null : 'hide'}>
                  <li>
                    <a href="/en/allowances/plans#your-plan">{t('My plans')}</a>
                  </li>

                  <li>
                    <a href="/en/allowances/plans#allowances">
                      {t('My benefits')}
                    </a>
                  </li>
                  <li>
                    <a href="/en/allowances/plans#credit-history">
                      {t('Credit usage')}
                    </a>
                  </li>
                  <li>
                    <a href="/en/allowances/plans#your-additional-products">
                      {t('Additional products')}
                    </a>
                  </li>
                  <li>
                    <a href="/en/allowances/plans#all-plan">{t('All plans')}</a>
                  </li>
                </ul>
              </li>
            )}

            {!corporateDashboard && configuration['Members.ViewInvoices'] && (
              <li
                id={'account-nav-billing'}
                className={
                  router.pathname === routes.invoices ? 'active' : null
                }
              >
                <Link href={routes.invoices}>
                  <a href="#">{t('Billing')}</a>
                </Link>
                <ul
                  className={
                    router.pathname === routes.invoices ? null : 'hide'
                  }
                >
                  <li>
                    <Link href={router.invoices + '#payment-history'}>
                      <a>{t('Invoices and payments')}</a>
                    </Link>
                  </li>
                  <li>
                    <Link href={router.invoices + '#next-invoice'}>
                      <a>{t('My next invoice')}</a>
                    </Link>
                  </li>
                  <li>
                    <Link href={router.invoices + '#billing-details'}>
                      <a>{t('Billing information')}</a>
                    </Link>
                  </li>
                  {customer?.EnableGoCardlessPayments && (
                    <li>
                      <Link href={router.invoices + '#gocardless'}>
                        <a>{t('Direct Debit payments')}</a>
                      </Link>
                    </li>
                  )}
                  {configuration['StripeACH.Enabled'] && (
                    <li>
                      <Link href={router.invoices + '#stripeach'}>
                        <a>{t('ACH payments')}</a>
                      </Link>
                    </li>
                  )}
                  {configuration['Spreedly.Enabled'] == 'True' && (
                    <li>
                      <Link href={router.invoices + '#payment-information'}>
                        <a>{t('Card payments')}</a>
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}

            {!corporateDashboard && (
              <li
                id={'account-nav-purchases'}
                className={router.pathname == routes.purchases ? 'active' : ''}
              >
                <Link href={routes.purchases}>
                  <a>{t('Purchases')}</a>
                </Link>
              </li>
            )}

            <li
              id={'account-nav-metrics'}
              className={router.pathname == routes.metrics ? 'active' : ''}
            >
              <Link href={routes.metrics}>
                <a>{t('Metrics')}</a>
              </Link>
            </li>

            <li
              id={'account-nav-integrations'}
              className={router.pathname == routes.integrations ? 'active' : ''}
            >
              <Link href={routes.integrations}>
                <a>{t('Integrations')}</a>
              </Link>
            </li>

            {resources.length > 0 && (
              <li
                id={'account-nav-bookings'}
                className={
                  router.pathname.indexOf(routes.my_bookings) > -1
                    ? 'active'
                    : null
                }
              >
                <Link href={routes.my_bookings}>
                  <a href="#">{t('Bookings')}</a>
                </Link>
                <ul
                  className={
                    router.pathname === routes.my_bookings ? null : 'hide'
                  }
                >
                  <li>
                    <Link href={router.my_bookings + '#my-bookings-table'}>
                      <a>{t('My Bookings')}</a>
                    </Link>
                  </li>
                  <li>
                    <Link href={router.my_bookings + '#calendar-sync-section'}>
                      <a>{t('Calendar Sync')}</a>
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {!corporateDashboard && configuration['PublicWebSite.Events'] && (
              <li
                id={'account-nav-events'}
                className={
                  router.pathname === routes.my_events ? 'active' : null
                }
              >
                <Link href={routes.my_events}>
                  <a href="#">{t('My events')}</a>
                </Link>
              </li>
            )}
            {configuration['PublicWebSite.Deliveries'] && (
              <li
                id={'account-nav-deliveries'}
                className={
                  router.pathname === routes.deliveries ? 'active' : null
                }
              >
                <Link href={routes.deliveries}>
                  <a href="#">{t('My deliveries')}</a>
                </Link>
              </li>
            )}
            <li
              id={'account-nav-files'}
              className={router.pathname === routes.files ? 'active' : null}
            >
              <Link href={routes.files}>
                <a href="#">{t('My files')}</a>
              </Link>
            </li>

            {configuration['PublicWebSite.Visitors'] && (
              <li
                id={'account-nav-visitors'}
                className={
                  router.pathname === routes.visitors ? 'active' : null
                }
              >
                <Link href={routes.visitors}>
                  <a href="#">{t('My visitors')}</a>
                </Link>
              </li>
            )}
          </ul>
        </nav>

        <nav
          data-component-name={this.props.componentName}
          className="nav-lateral--mobile js-link-select-navigation pb-24"
          role="navigation"
        >
          <select
            value={router.pathname}
            className="form-control nav-selector"
            id="nav-lateral-select"
          >
            {configuration['PublicWebSite.MyAccount'] && (
              <option id={'account-nav-account'} value={routes.profile}>
                {t('My account')}
              </option>
            )}
            {customer?.TeamNames && (
              <option id={'account-nav-team'} value={routes.profile_team}>
                {t('My team')}
              </option>
            )}
            <option id={'account-nav-metrics'} value={routes.metrics}>
              {t('Metrics')}
            </option>
            <option id={'account-nav-plans'} value={routes.plan}>
              {t('Plans and benefits')}
            </option>
            {configuration['Members.ViewInvoices'] && (
              <option id={'account-nav-billing'} value={routes.invoices}>
                {t('Billing')}
              </option>
            )}
            {resources.length > 0 && (
              <option id={'account-nav-bookings'} value={routes.my_bookings}>
                {t('My bookings')}
              </option>
            )}
            {configuration['PublicWebSite.Events'] && (
              <option id={'account-nav-events'} value={routes.my_events}>
                {t('My events')}
              </option>
            )}
            <option id={'account-nav-deliveries'} value={routes.deliveries}>
              {t('My deliveries')}
            </option>
            <option id={'account-nav-visitors'} value={routes.visitors}>
              {t('My visitors')}
            </option>
          </select>
        </nav>
      </>
    );
  }
}
export default AccountNavigationMenu;
