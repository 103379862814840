import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const CoursesPageCommentsTitle = withCustomComponent(
  'CoursesPageCommentsTitle'
)(
  inject(
    'appStore',
    'coursesStore'
  )(
    withRouter(
      observer(({ componentName, router, appStore, coursesStore }) => {
        const { t } = useTranslation();
        const course = coursesStore.coursePage.Course;

        if (!course.AllowComments) return <></>;

        return (
          <div
            data-component-name={componentName}
            className="course-page-comments-title d-flex align-items-center py-12 border-bottom text-gray-700 fs-14"
          >
            {course.Comments.length > 0 && (
              <>
                <i className="icon-replies fs-24 mr-4"></i>
                <span>
                  {t('{{count}} comment', {
                    count: course.Comments.length,
                  })}
                </span>
              </>
            )}
            {course.Comments.length == 0 && (
              <>
                {t('Be the first one to leave a comment!')}
              </>
            )}
          </div>
        );
      })
    )
  )
);
