import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

import CardDirectoryMemberTeam from 'ui/components/cards/CardDirectoryMemberTeam';

export const TeamsList = withCustomComponent('TeamsList')(
  inject(
    'appStore',
    'communityStore'
  )(
    withRouter(
      observer(({ communityStore, router }) => {
        const { t } = useTranslation();

        const { teamsDirectory } = communityStore;
        const teams = teamsDirectory.TeamsByName || [];
        return (
          <ul className="list-grid list-grid--25 list-grid--lg-33 list-grid--md-50 list-grid--sm-100">
            {teams.map((t, i) => (
              <li key={i}>
                <CardDirectoryMemberTeam team={t} />
              </li>
            ))}
          </ul>
        );
      })
    )
  )
);
