import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import AuthenticatedLink from 'ui/components/AuthenticatedLink';
import withCustomComponent from 'ui/components/withCustomComponent';
@withCustomComponent('StripeDirectDebitPaymentButton')
@withTranslation()
@inject('appStore', 'authStore')
class StripeDirectDebitPaymentButton extends Component {
  render() {
    const {
      className,
      returnUrl = '',
      failedReturnUrl = '',
      t,
      appStore,
      authStore,
      invoice,
      componentName,
    } = this.props;
    const { customer } = authStore;
    const { business } = appStore;

    return (
      <AuthenticatedLink
        data-component-name={componentName}
        className={`${className ? className : 'btn btn-icon btn-link text-gray-900'}`}
        href={`${
          business.NativeHomeUrlWithLanguage
        }/Callbacks/StripeDirectDebitPre/${
          invoice ? invoice.CoworkerId : customer.Id
        }?returnUrl=${encodeURIComponent(
          returnUrl
        )}&failedReturnUrl=${encodeURIComponent(failedReturnUrl)}`}
      >
        <i aria-hidden="true" className="icon-simple-pay"></i>
        {t('Set up Direct Debit ')}
      </AuthenticatedLink>
    );
  }
}
export default StripeDirectDebitPaymentButton;
