import React from 'react';
import {routes} from 'env/routes';
import {useTranslation} from 'react-i18next';
import {withRouter} from 'next/router';
import {inject, observer} from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import {BasketItem} from 'ui/_pages/checkout/basketItems/BasketItem';
import {LocalizedPrice} from 'env/utils/NumbersLocalization';

export const ProductBasketItem = withCustomComponent('ProductBasketItem')(
  inject(
    'appStore',
    'checkoutStore',
  )(
    withRouter(
      observer(({router, basketItem, checkoutStore, appStore}) => {
        const {t} = useTranslation();
        const {data} = basketItem;
        const {business} = appStore;

        const editProduct = () => {
          const url =
            router.pathname == routes.signup_summary
              ? routes.signup_products
              : routes.store;
          router.push(url);
        };

        return (
          <BasketItem
            basketItem={basketItem}
            onEdit={editProduct}
            id={data.IdString}
            imageUrl={`${appStore.business.NativeHomeUrlWithLanguage}/products/getImage?id=${basketItem.data.Id}&w=100&h=100&cache=${basketItem.data.UpdatedOn}`}
            header={`${data.Quantity}x ${data.Name}`}
            times={
              data.RegularCharge ? (
                <>{t('Add to my plan')}</>
              ) : (
                <>{t('One-off')}</>
              )
            }
            total={
              <>
                <LocalizedPrice
                  currency={data.Currency.Code}
                  amount={data.Price}
                />
              </>
            }
          />
        );
      }),
    ),
  ),
);
