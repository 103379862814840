import { required, pattern } from 'validx';
import { toLocalTime } from 'env/utils/dateUtil';
import global from 'global';

export const visitorValidationSchema = () => {
  const t = global.t;

  return {
    // The schema maps to the fields we are validating.
    FullName: [required({ msg: t('Full name is required') })],
    Email: [
      required({ msg: t('Email is required') }),
      pattern({
        pattern: 'email',
        msg: t('This email address does not seem to be valid.'),
      }),
    ],
    ExpectedArrival: [
      required({ msg: t('Expected arrival date and time are required') }),
      (opts) =>
        opts.value > toLocalTime(new Date()) ||
        t('Expected arrival date and time must be in the future'),
    ],
  };
};
