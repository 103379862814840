/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import LoadingLayout from 'ui/layouts/LoadingLayout';

@withCustomComponent('BookingResourceSelector')
@withTranslation()
@inject('appStore', 'bookingsStore')
@observer
class BookingResourceSelector extends Component {
  componentDidMount() {
    const { bookingsStore } = this.props;

    if (!bookingsStore.hasLoadedResources) bookingsStore.loadResources();
  }

  updateBooking = this.props.updateBooking;
  loadResourceProducts = this.props.loadResourceProducts;

  selectResource = (resource) => {
    this.updateBooking('ResourceId', resource.Id);
    this.updateBooking('ResourceName', resource.Name);
    this.updateBooking('Resource', {
      Id: resource.Id,
    });

    const { bookingsStore } = this.props;
    const { booking } = bookingsStore;
    this.loadResourceProducts(booking);
  };

  render() {
    const { t, componentName } = this.props;
    const { bookingsStore, appStore } = this.props;
    const { booking, resources } = bookingsStore;
    const { business } = appStore;

    if (!(booking)) {
      return <LoadingLayout/>;
    }

    return (
      <div
        data-component-name={componentName}
        className="dropdown booking-resource-selector"
      >
        <button
          className="btn btn-lg btn-outline dropdown-toggle w-100"
          data-toggle="dropdown"
        >
          <h4 className="m-0 w-100 text-left text-truncate">
            {booking.ResourceId ? booking.ResourceName : t('Select a resource')}
          </h4>
          <span className="caret"></span>
        </button>
        <div className="dropdown-menu">
          <ul className="dropdown-menu-scrollable-list">
            {resources.map((resource, i) => (
              <li
                key={i}
                role="menuitem"
                tabIndex="-1"
                className="dropdown-item h-auto"
              >
                <a
                  href="#"
                  onClick={() => this.selectResource(resource)}
                  className="d-flex align-items-center w-100 text-gray-900 tdn px-4 py-6 pr-24"
                >
                  <img
                    className="avatar avatar--48"
                    src={`${business.NativeHomeUrlWithLanguage}/publicresources/getimage/${resource.Id}?h=128&w=128&cache=${resource.UpdatedOn}`}
                    alt={resource.Name}
                  />
                  <div className="pl-12">
                    <h6 className="mb-0">{resource.Name}</h6>
                    <small className="text-gray-600">
                      {resource.ResourceTypeName}
                    </small>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
export default BookingResourceSelector;
