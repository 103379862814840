import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import asForm from 'ui/components/forms/asForm';
import InputField from 'ui/components/forms/InputField';
import DateInputField from 'ui/components/forms/DateInputField';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import withCustomComponent from 'ui/components/withCustomComponent';
import { TimeInputField } from 'ui/components/forms/TimeInputField';

const NewVisitorForm = withCustomComponent('NewVisitorForm')(
  inject(
    'bookingsStore',
    'appStore'
  )(
    observer(({ bookingsStore, appStore, updateProperty }) => {
      const { newVisitor, isSavingNewVisitor } = bookingsStore;
      const { t } = useTranslation();

      const saveVisitor = () => {
        bookingsStore
          .saveNewVisitor()
          .then(() =>
            appStore.setPopMessage(t('Your visitor has been registered'))
          )
          .then(() => bookingsStore.loadMyVisitors())
          .catch((err) => {
            appStore.setPopMessage(
              t('We could not register this visitor. ') + err
            );
          });
      };

      return (
        <form
          id="new-visitor-form"
          className="form your-input__form"
          novalidate
        >
          <div className="form-group required">
            <InputField
              id="visitor-fullName"
              label={t('Full name') + '*'}
              name="FullName"
              errors={bookingsStore.newVisitorValidation.errors.FullName}
              value={newVisitor.FullName}
              onChange={updateProperty}
            />
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group required">
                <InputField
                  id="visitor-phone"
                  label={t('Phone')}
                  name="PhoneNumber"
                  errors={bookingsStore.newVisitorValidation.errors.PhoneNumber}
                  value={newVisitor.PhoneNumber}
                  onChange={updateProperty}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group required">
                <InputField
                  id="visitor-email"
                  label={t('Email') + '*'}
                  name="Email"
                  errors={bookingsStore.newVisitorValidation.errors.Email}
                  value={newVisitor.Email}
                  onChange={updateProperty}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="form-group required">
                <DateInputField
                  id="visitor-ExpectedArrival"
                  label={t('Expected arrival date') + '*'}
                  name="ExpectedArrival"
                  options={{ dateFormat: 'MMMM d, yyyy' }}
                  errors={
                    bookingsStore.newVisitorValidation.errors.ExpectedArrival
                  }
                  value={newVisitor.ExpectedArrival}
                  onChange={updateProperty}
                  customProps={{
                    min: new Date(),
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group required">
                <TimeInputField
                  id="visitor-ExpectedArrival-time"
                  label={t('Expected arrival time') + '*'}
                  name="ExpectedArrival"
                  showTimeSelect={true}
                  errors={
                    bookingsStore.newVisitorValidation.errors.ExpectedArrival
                  }
                  value={newVisitor.ExpectedArrival}
                  onChange={updateProperty}
                  customProps={{
                    min: new Date(),
                  }}
                />
              </div>
            </div>
          </div>

          <div className="form-actions">
            <button
              onClick={(ev) => {
                if (isSavingNewVisitor) return;
                ev.preventDefault();
                saveVisitor();
              }}
              className="btn "
              type="submit"
            >
              {isSavingNewVisitor ? <LoadingSpinner /> : t('Register Visitor')}
            </button>
          </div>
        </form>
      );
    })
  )
);

export default asForm(NewVisitorForm, 'newVisitor');
