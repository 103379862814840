import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import moment from 'moment';
import { withRouter } from 'next/router';
import { routes } from 'env/routes';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { withCustomComponent } from 'ui/components/withCustomComponent';

import { NoAccessNotice } from 'ui/components/NoAccessNotice';
import ProfilePageHeader from 'ui/_pages/directory/members/ProfilePageHeader';
import ProfilePageBody from 'ui/_pages/directory/members/ProfilePageBody';
import ProfilePageSide from 'ui/_pages/directory/members/ProfilePageSide';

@withCustomComponent('DirectorProfileDetailsPage')
@withRouter
@withTranslation()
@inject('appStore', 'communityStore')
@observer
class DirectorProfileDetailsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    const { router, t, appStore, communityStore } = this.props;
    const $ = window.$;

    communityStore
      .loadCustomerPage(router.query)
      .then(this.initTwitter)
      .catch((err) => {
        console.log('Sorry, we could not load this page:', err);
        this.setState({
          error: t('Sorry, we could not load this page. {{err}}', { err: '' }),
        });
      });
  }

  initTwitter = () => {
    //Twitter
    !(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0],
        p = /^http:/.test(d.location) ? 'http' : 'https',
        el = d.getElementById(id);

      js = d.createElement(s);
      js.id = id;
      js.src = p + '://platform.twitter.com/widgets.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'twitter-wjs');
  };

  componentDidUpdate(prevProps) {
    const { router, communityStore } = this.props;
    const { member_id } = router.query;
    const prev_member_id = prevProps.router.query.member_id;

    if (prev_member_id != member_id)
      communityStore
        .loadCustomerPage(router.query)
        .then(this.initTwitter)
        .catch((err) => {
          console.log('Sorry, we could not load this page:', err);
          this.setState({
            error: t('Sorry, we could not load this page. {{err}}', {
              err: '',
            }),
          });
        });
  }

  render() {
    const { t, appStore, communityStore } = this.props;
    const { business } = appStore;
    const { customerPage, isLoadingCustomerPage } = communityStore;
    const member = customerPage && customerPage.Coworker;
    const customFields = customerPage && customerPage.ProfileCustomFields;
    const threads = customerPage && customerPage.Threads;
    const relatedLists = customerPage && customerPage.RelatedLists;

    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={t('{{FullName}} profile at {{Name}}', {
          Name: t(business.Name),
          FullName: !isLoadingCustomerPage && member ? member.FullName : '...',
        })}
      >
        <main className="content content--custom" role="main">
          <div className="container">
            <div className="mb-20">
              <Link href={routes.directory_members}>
                <a className="btn btn-icon btn-white">
                  <i className="icon-go-back"></i>
                  {t('Back')}
                </a>
              </Link>
            </div>
            {isLoadingCustomerPage && <LoadingSpinner />}
            <NoAccessNotice error={this.state.error} />
            {member && !isLoadingCustomerPage && (
              <div className="row">
                <div className="col-xl-9">
                  <ProfilePageHeader
                    member={member}
                    customFields={customFields}
                  />
                  <ProfilePageBody
                    member={member}
                    customFields={customFields}
                    threads={threads}
                  />
                </div>
                <div className="col-xl-3">
                  <hr className="mt-40 mb-32 d-block d-xl-none" />
                  <ProfilePageSide
                    member={member}
                    relatedLists={relatedLists}
                  />
                </div>
              </div>
            )}
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default DirectorProfileDetailsPage;
