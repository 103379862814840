import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { withCustomComponent } from 'ui/components/withCustomComponent';
import { withRouter } from 'next/router';
import moment from 'moment';
import DropDownList from 'react-widgets/DropdownList';

export const TimeInputField = withCustomComponent('TimeInputField')(
  inject('appStore')(
    withRouter(
      observer(
        ({
          componentName,
          appStore,
          value,
          name,
          onChange,
          errors,
          helpText,
          classes,
          containerClasses,
          id,
          label,
          allowPastDates,
        }) => {
          const [touched, setTouched] = useState(false);

          let startTime;

          const { configuration } = appStore;
          startTime = moment(value).startOf('day').toDate();
          // if (allowPastDates && configuration['Bookings.AcceptPastBookings']) {
          //   startTime = moment(value).startOf('day').toDate();
          // } else {
          //   const isDateToday = moment(value).isSame(new Date(), 'day');
          //   if (isDateToday) {
          //     startTime =
          //       moment(new Date()).minute() > 30
          //         ? moment(new Date()).set('minutes', 60).toDate()
          //         : moment(new Date()).set('minutes', 30).toDate();
          //   } else {
          //     startTime = moment().startOf('day').toDate();
          //   }
          // }

          const getTimeSlots = () => {
            let timeSlots = [];
            let endTime = moment(value).endOf('day');

            for (
              let tempDate = startTime;
              moment(tempDate) <= endTime;
              tempDate = moment(tempDate)
                .clone()
                .add(
                  typeof window !== 'undefined'
                    ? window.bookingTimeStep ?? 15
                    : 15,
                  'minutes'
                )
                .toDate()
            ) {
              timeSlots.push(tempDate);
            }

            return timeSlots;
          };

          const onDropdownChange = (date) => {
            setTouched(true);

            if (!value) {
              onChange(date, null);
              return;
            }

            let currentDate = value ?? new Date();
            let clone = moment(currentDate).clone().toDate();

            clone.setMinutes(date.getMinutes());
            clone.setHours(date.getHours());

            onChange(name, clone);
          };

          const onFocus = () => {
            setTouched(true);
          };

          const timeSlots = getTimeSlots();
          const hasErrors = errors && errors.length > 0;
          const timeIconElement = (
            <i className='icon-icon-clock fs-24 gray-new-primary'></i>
          );

          return (
            <div
              className={`form-group mb-0 
              ${classes ? classes : ''}
            custom-timepicker time`}
            >
              {label && (
                <label className='control-label' htmlFor={id}>
                  {label}
                </label>
              )}
              <DropDownList
                id={id}
                data={timeSlots}
                textField={(value) => moment(value).format('LT')}
                value={value}
                busy={!timeSlots.length}
                selectIcon={timeIconElement}
                containerClassName={`form-group-sm 
                ${containerClasses ? containerClasses : ''}
              mb-0`}
                onChange={(val) => onDropdownChange(val)}
                onFocus={onFocus}
              />
              {hasErrors && (
                <div className='invalid-feedback'>{errors.join(', ')}</div>
              )}
              {helpText && (
                <small class='form-text text-muted'>{helpText}</small>
              )}
            </div>
          );
        }
      )
    )
  )
);
