import React, {Component} from 'react';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';

@observer
class FileInputField extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);

    this.state = {touched: false};
  }

  onChange = (event) => {
    this.setState({touched: true});

    const files = event.target.files;
    const reader = new FileReader();
    const name = event.target.name;

    reader.onload = (r) => {
      this.props.onChange(name, files[0].name + ';' + r.target.result);
    };
    reader.readAsDataURL(files[0]);
  };

  onFocus = () => {
    this.setState({touched: true});
  };

  render() {
    const input = this.props;
    const hasErrors = input.errors && input.errors.length > 0;
    const {touched} = this.state;

    return (
      <>
        <label
          className={`control-label ${
            input.labelClassName ? input.labelClassName : ''
          }`}
          htmlFor={input.id}
        >
          {input.label}
        </label>
        <input
          className={`form-control ${hasErrors ? 'validationElement' : ''}`}
          id={input.id}
          multiple={this.props.multiple}
          name={input.name}
          onBlur={this.onFocus}
          onChange={this.onChange}
          type="file"
          value={input.value}
          {...this.props.inputProps}
        />
        {touched && hasErrors && (
          <small className="help-block validation-error">
            {input.errors.join(', ')}
          </small>
        )}
      </>
    );
  }
}

FileInputField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default FileInputField;
