import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation, Trans } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import InputField from 'ui/components/forms/InputField';
import BooleanInputField from 'ui/components/forms/BooleanInputField';
import asForm from 'ui/components/forms/asForm';
import NxMentionsInput from 'ui/components/forms/NxMentionsInput/NxMentionsInput';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import SelectField from 'ui/components/forms/SelectField';
import withCustomComponent from 'ui/components/withCustomComponent';
import TagsInputField from 'ui/components/forms/TagsInputField';

export const CommunityBoardStartForm = withCustomComponent(
  'CommunityBoardStartForm'
)(
  asForm(
    inject(
      'appStore',
      'authStore',
      'communityStore'
    )(
      withRouter(
        observer(
          ({
            updateProperty,
            newCommunityBoardMessage,
            appStore,
            authStore,
            communityStore,
            router,
          }) => {
            const { t } = useTranslation();
            const { isSignalRConnected, configuration } = appStore;
            const { customer } = authStore;
            const { isSavingNewCommunityBoardMessage, communityStartPage } =
              communityStore;

            const saveNewMessage = () => {
              communityStore
                .saveNewCommunityBoardMessage({
                  isPrivate: newCommunityBoardMessage.Private,
                })
                .then(() => {
                  appStore.setPopMessage(t('Your message has been posted'));
                  if (newCommunityBoardMessage.GroupId > 0)
                    router.push(
                      routes.forum_by_group('[group_id]'),
                      routes.forum_by_group(newCommunityBoardMessage.GroupId)
                    );
                  else router.push(routes.forum);
                })
                .catch((err) =>
                  appStore.setPopMessage(
                    t('Sorry, your message could not be sent. ' + err)
                  )
                );
            };

            return (
              <form
                className="form"
                id="new_thread_form"
                method="post"
                encType="multipart/form-data"
              >
                <div className="form-group">
                  <InputField
                    id="newCommunityBoardMessage-subject"
                    label={t('Topic')}
                    name="Subject"
                    placeholder={t('Keep it relevant, less is more...')}
                    errors={
                      communityStore.newCommunityBoardMessageValidation.errors
                        .Subject
                    }
                    value={newCommunityBoardMessage.Subject}
                    onChange={updateProperty}
                  />
                  <div className="fs-12 text-gray-600 lh-13 mt-8">
                    {t('This will serve as the title of your board.')}
                  </div>
                </div>

                <div className="form-group">
                  <NxMentionsInput
                    label={t('Message')}
                    name="Message"
                    placeholder={t(
                      "Invite other people to this conversation by mentioning them: type the '@' symbol followed by their name."
                    )}
                    errors={
                      communityStore.newCommunityBoardMessageValidation.errors
                        .Message
                    }
                    value={newCommunityBoardMessage.Message}
                    onChange={updateProperty}
                  />
                  <div className="fs-12 text-gray-600 lh-13 mt-8">
                    {t(
                      'You can use MarkDown syntax to give format to your message'
                    )}
                  </div>
                  <div className="fs-12 text-gray-600 lh-13">
                    {t(
                      "Invite other people to this conversation by mentioning them: type the '@' symbol followed by their name or by adding them below."
                    )}
                  </div>
                </div>

                <hr className="hr-1 my-24" />

                <div className="form-group">
                  <SelectField
                    id="customer-group"
                    label={t('Group')}
                    name="GroupId"
                    errors={
                      communityStore.newCommunityBoardMessageValidation.errors
                        .Group
                    }
                    value={newCommunityBoardMessage.GroupId}
                    onChange={updateProperty}
                  >
                    <option value="">{t('General Thread')}</option>
                    {communityStartPage.Groups.map((group, i) => (
                      <option key={i} value={group.Id}>
                        {group.Name}
                      </option>
                    ))}
                  </SelectField>
                </div>

                <div className="form-group">
                  <TagsInputField
                    id="newCommunityBoardMessage-tags"
                    label={t('Tags')}
                    name="Tags"
                    errors={
                      communityStore.newCommunityBoardMessageValidation.errors
                        .Tags
                    }
                    value={newCommunityBoardMessage.Tags}
                    onChange={updateProperty}
                  />
                  <div className="fs-12 text-gray-600 lh-13 mt-8">
                    {t(
                      'Help others find your posts by tagging them. Hit enter after each tag. Maximum three tags, choose wisely.'
                    )}
                  </div>
                </div>

                <hr className="hr-1 my-24" />

                <div className="form-group">
                  <InputField
                    id="newCommunityBoardMessage-files"
                    label={t('Attach Images or files')}
                    name="NewCommunityBoardMessageFiles"
                    type={'file'}
                    multiple={true}
                    errors={
                      communityStore.newCommunityBoardMessageValidation.errors
                        .Files
                    }
                    value={
                      newCommunityBoardMessage.NewCommunityBoardMessageFiles
                    }
                    onChange={updateProperty}
                  />
                  <div className="fs-12 text-gray-600 lh-13 mt-8">
                    {t(
                      'Max 10Mb. Hold Ctrl in Windows or Command in Mac to select more than one file'
                    )}
                  </div>
                </div>

                <hr className="hr-1 my-24" />

                {configuration['Zoom.Enabled'] && (
                  <>
                    <div className="form-group">
                      <div className="d-flex justify-content-between">
                        <div className="media">
                          <img
                            src="/img/zoom_logo.png"
                            className="d-none d-md-block mr-16"
                          />
                          <div className="media-body fs-14">
                            <div className="d-flex align-items-center">
                              <i className="icon-zoom fs-24 mr-8 d-block d-md-none"></i>
                              <span className="fw-500">
                                {t('Include Zoom Meeting link')}
                              </span>
                            </div>
                            <div className="text-gray-700 pt-4 lh-14">
                              {t(
                                'Include a Zoom Meeting Invite in this conversation to let others join the conversation. The link will be valid for up to 30 days.'
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="ml-20 ml-md-48">
                          <BooleanInputField
                            id="newCommunityBoardMessage-IncludeZoomInvite"
                            name="IncludeZoomInvite"
                            errors={
                              communityStore.newCommunityBoardMessageValidation
                                .errors.IncludeZoomInvite
                            }
                            value={newCommunityBoardMessage.IncludeZoomInvite}
                            onChange={updateProperty}
                            type={'switch'}
                          />
                        </div>
                      </div>
                      {!customer.HasZoom && (
                        <>
                          <div className="alert alert-warning mt-16">
                            <Trans>
                              <div className="fs-14 fw-500">Heads up! </div>
                              <div className="fs-14 lh-14">
                                <span>You must </span>
                                <a
                                  href={routes.my_bookings}
                                  target="_blank"
                                  className="text-gray-900 tdu"
                                >
                                  connect your Zoom account
                                </a>{' '}
                                <span>
                                  to be able to include Zoom links in the
                                  community board.
                                </span>
                              </div>
                            </Trans>
                          </div>
                        </>
                      )}
                    </div>
                    <hr className="hr-1 my-24" />
                  </>
                )}

                <div className="form-group">
                  <div className="d-flex justify-content-between">
                    <div className="fs-14">
                      <div className="d-flex align-items-center">
                        <i className="icon-private fs-24 mr-8"></i>
                        <span className="fw-500">
                          {t('Make this conversation private.')}
                        </span>
                      </div>
                      <div className="text-gray-700 pt-4 lh-14">
                        {t(
                          'Only you and the people you invite will see this conversation.'
                        )}
                      </div>
                    </div>
                    <div className="ml-20 ml-md-48">
                      <BooleanInputField
                        id="newCommunityBoardMessage-Private"
                        name="Private"
                        errors={
                          communityStore.newCommunityBoardMessageValidation
                            .errors.Private
                        }
                        value={newCommunityBoardMessage.Private}
                        onChange={updateProperty}
                        type={'switch'}
                      />
                    </div>
                  </div>
                </div>

                <hr className="hr-1 my-24" />

                <div className="form-group">
                  <div className="d-flex justify-content-between">
                    <div className="fs-14">
                      <div className="d-flex align-items-center">
                        <i className="icon-instant-delivery fs-24 mr-8"></i>
                        <span className="fw-500">{t('Instant delivery')}</span>
                      </div>
                      <div className="text-gray-700 pt-4 lh-14">
                        <Trans>
                          Most members choose to only receive a notification
                          about new conversations every morning. Enable this
                          option to notify everyone about this conversation
                          right away. <b>Please use responsibly</b>.
                        </Trans>
                      </div>
                    </div>
                    <div className="ml-20 ml-md-48">
                      <BooleanInputField
                        id="newCommunityBoardMessage-InstantDelivery"
                        name="InstantDelivery"
                        errors={
                          communityStore.newCommunityBoardMessageValidation
                            .errors.InstantDelivery
                        }
                        value={newCommunityBoardMessage.InstantDelivery}
                        onChange={updateProperty}
                        type={'switch'}
                      />
                    </div>
                  </div>
                </div>

                <hr className="hr-1 my-24" />

                <div className="d-flex align-item-center justify-content-end mt-32 mb-24 pb-4">
                  <Link href={routes.forum}>
                    <a className="btn btn-white mr-8">{t('Cancel')}</a>
                  </Link>
                  <button
                    className="btn btn-icon"
                    disabled={
                      !isSignalRConnected || isSavingNewCommunityBoardMessage
                    }
                    onClick={(ev) => {
                      if (!isSignalRConnected) return;
                      if (isSavingNewCommunityBoardMessage) return;
                      ev.preventDefault();
                      saveNewMessage();
                      ev.target.blur();
                    }}
                  >
                    {isSavingNewCommunityBoardMessage ? (
                      <LoadingSpinner />
                    ) : !isSignalRConnected ? (
                      t('Connecting...')
                    ) : (
                      <>
                        <i className="icon-add"></i>
                        {t('Start conversation')}
                      </>
                    )}
                  </button>
                </div>
              </form>
            );
          }
        )
      )
    ),
    'newCommunityBoardMessage'
  )
);
