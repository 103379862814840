import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

@withTranslation()
@observer
class SelectField extends Component {
  constructor(props) {
    super(props);

    this.state = { touched: false };
  }

  onChange = (event) => {
    const values = [...event.target.selectedOptions]
      .map((opt) => opt.value)
      .join(',');
    this.props.onChange(event.target.name, values);
  };

  onFocus = () => {
    this.setState({ touched: true });
  };

  render() {
    const input = this.props;
    const { t } = this.props;
    const hasErrors = input.errors && input.errors.length > 0;
    const { touched } = this.state;
    return (
      <>
        <label className="control-label" htmlFor={input.id}>
          {input.label || input.name}
        </label>
        <select
          className={`form-control ${hasErrors ? 'validationElement' : ''}`}
          id={input.id}
          name={input.name}
          onChange={this.onChange}
          onBlur={this.onFocus}
          value={
            input.value && input.inputProps && input.inputProps.multiple
              ? input.value.split(',')
              : input.value
          }
          autoComplete={input.autocomplete || ''}
          {...input.inputProps}
        >
          {this.props.children}
        </select>

        {touched && hasErrors && (
          <small className="help-block validation-error">
            {input.errors.join(', ')}
          </small>
        )}
        {input.inputProps && input.inputProps.multiple && (
          <small className="form-text text-muted">
            {t(
              "You can select more than one option holding the 'Ctrl' (Windows) or 'Command' (Mac) key."
            )}
          </small>
        )}
      </>
    );
  }
}

SelectField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default SelectField;
