import React, {Component} from 'react';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import AccountNavigationMenu from 'ui/_pages/profile/AccountNavigationMenu';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('ProfilePageLayout')
export default class ProfilePageLayout extends Component {
  render() {
    return (
      <DefaultLayout data-component-name={this.props.componentName}>
        <main className="content content--custom profile pt-0" role="main">
          <div className="portal-title-wrapper">
            <div className="container portal-title-container">
              <h1 className="portal-title">
                Settings
              </h1>
            </div>
          </div>
          <div className="container">
            <div className="settings">
              <div className="row">
                <div className="col-lg-3">
                  <div className="settings__nav">
                    <AccountNavigationMenu/>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="settings__content">
                    {this.props.children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DefaultLayout>
    );
  }
}
