import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const CommunityBoardItemUserImage = withCustomComponent(
  'CommunityBoardItemUserImage'
)(
  inject('appStore')(
    withRouter(
      observer(({ thread, componentName, appStore }) => {
        const { t } = useTranslation();
        const { business } = appStore;
        return (
          <>
            <Link
              href={{
                pathname: routes.directory_member_by_id('[member_id]'),
              }}
              as={{
                pathname: routes.directory_member_by_id({
                  Id: thread.CoworkerId,
                  FullName: thread.FullName,
                }),
              }}
            >
              <a
                data-component-name={componentName}
                className="community-board-item-user-image avatar avatar--48"
                style={{ backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${thread.CoworkerId}?h=96&w=96&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png)` }}
              >
                {thread.FollowedByUser && (
                  <span
                    className="badge thread-followed-by-user"
                    data-toggle="tooltip"
                    title={t('You are following this conversation')}
                  >
                    <i className="icon-check"></i>
                  </span>
                )}
              </a>
            </Link>
          </>
        );
      })
    )
  )
);
