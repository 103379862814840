import React, {Component} from 'react';

export default class ResponsiveTable extends Component {
  componentDidMount() {
    const $ = window.$;

    $('.table-wrap').each(function () {
      var nmtTable = $(this);
      var nmtHeadRow = nmtTable.find('thead tr');
      nmtTable.find('tbody tr').each(function () {
        var curRow = $(this);
        for (var i = 0; i < curRow.find('td').length; i++) {
          var rowSelector = 'td:eq(' + i + ')';
          var headSelector = 'th:eq(' + i + ')';
          curRow
            .find(rowSelector)
            .attr('data-title', nmtHeadRow.find(headSelector).text());
        }
      });
    });
  }

  render() {
    return (
      <div className="table-wrap table-wrap-tablet table-responsive">
        <table
          className={`table ${this.props.striped == false ? '' : 'table-striped'} table--settings ${this.props.className != undefined ? this.props.className : ''}`}>
          {this.props.children}
        </table>
      </div>
    );
  }
}
