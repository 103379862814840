import React from 'react';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { BasketItem } from 'ui/_pages/checkout/basketItems/BasketItem';
import moment from 'moment';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';
export const EventBasketItem = withCustomComponent('EventBasketItem')(
  inject(
    'appStore',
    'checkoutStore'
  )(
    withRouter(
      observer(({ router, basketItem, checkoutStore, appStore }) => {
        const { t } = useTranslation();
        const { data } = basketItem;
        const { business } = appStore;
        const { basket } = checkoutStore;
        const total = _.sumBy(data.EventProducts, (p) => p.Quantity * p.Price);
        const selectedProducts = _.filter(
          data.EventProducts,
          (p) => p.Quantity > 0
        );

        const editEvent = () => {
          router.push(
            routes.tickets_by_id('[event_id]'),
            routes.tickets_by_id(basketItem.data)
          );
        };

        return (
          <BasketItem
            basketItem={basketItem}
            onEdit={editEvent}
            id={data.Id}
            imageUrl={`${business.NativeHomeUrlWithLanguage}/Events/GetSmallImage?id=${data.Id}&w=96&h=96&cache=${data.UpdatedOn}`}
            header={data.Name}
            times={
              <>
                {`${moment(data.StartDate).format('L')}, ${moment(
                  data.StartDate
                ).format('LT')} → `}
                {moment(data.StartDate).isSame(data.EndDate, 'day')
                  ? moment(data.EndDate).format('LT')
                  : `${moment(data.EndDate).format('L')}, ${moment(
                      data.EndDate
                    ).format('LT')}`}
              </>
            }
            total={
              <LocalizedPrice
                currency={data.EventProducts[0].PriceCurrencyCode}
                amount={total}
              />
            }
            options={
              selectedProducts.length > 0 && (
                <ul class="event-options">
                  {selectedProducts.map((product, i) => (
                    <li>
                      <i className="icon-ticket"></i>
                      <span>
                        {product.Quantity}
                        {'x '}
                        {product.Name}
                        {/* {' ('}<LocalizedPrice currency={product.PriceCurrencyCode} amount={product.Price * product.Quantity} />{')'}*/}
                      </span>
                    </li>
                  ))}
                </ul>
              )
            }
          />
        );
      })
    )
  )
);
