import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import moment from 'moment';
import { withRouter } from 'next/router';
import { routes } from 'env/routes';

@withRouter
@withCustomComponent('SignupWithTeamPage')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class SignupWithTeamPage extends Component {}

SignupWithTeamPage.getInitialProps = ({ query, res }) => {
  if (res) {
    res.writeHead(302, {
      Location: routes.signup + `?team_guid=${query.team_guid}`,
    });
    res.end();
  }

  return {};
};

export default SignupWithTeamPage;
