import React, {useEffect, useRef, useState} from 'react';
import config from 'env/config';
import Cookies from 'universal-cookie';
import {inject, observer} from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import {useTranslation} from 'react-i18next';
import Link from 'next/link';
import moment from 'moment';

const oneSignalSetCookieName = 'OneSignal.Enabled';

let NotificationsMenuItem = ({appStore, authStore, componentName}) => {
  const {isLoggedIn} = authStore;
  const {notifications} = appStore;
  const {t} = useTranslation();
  const componentUnmounted = useRef(false);

  const onSignalEnabled = window.localStorage.getItem(
    oneSignalSetCookieName,
  );

  const [iframeUrl, setIframeUrl] = useState(null);
  const [notificationWarningClicked, setNotificationWarningClicked] = useState(onSignalEnabled);

  const requestPushNotifications = async () => {
    if (componentUnmounted.current) return;

    const me = await authStore.loadMe();
    const url = `${config.platformRoot}/integrations/onesignal/subscribe?t=${me.AccessToken}`;
    window.open(url, '_blank', 'height=200,width=350,menubar=0,status=0');
    window.localStorage.setItem(oneSignalSetCookieName, true);
    setNotificationWarningClicked(true);
  };

  useEffect(() => {
    let timeout;

    if (isLoggedIn) {
      appStore.loadNotifications().catch(err => console.error(`Can't load Notifications.`, err));

      timeout = setTimeout(() => {
        authStore.loadMe().then((data) => {
          const url = `${config.platformRoot}/integrations/onesignal/subscribe?t=${data.AccessToken}`;
          setIframeUrl(url);
        });
      }, 1000);
    }

    return () => {
      componentUnmounted.current = true;

      clearTimeout(timeout);
    };
  }, [isLoggedIn]);

  if (!isLoggedIn) return <></>;

  return (
    <div className="dropdown dropdown--notifications">
      {iframeUrl && (
        <iframe
          height={0}
          width={0}
          style={{display: 'none'}}
          src={iframeUrl}
        ></iframe>
      )}
      <button
        data-toggle="dropdown"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        className="dropdown-toggle btn btn-single-icon btn-white-black"
        onClick={(ev) => {
          if (!notificationWarningClicked) {
            ev.preventDefault();
            requestPushNotifications();
          }
        }}
      >
        <i className="icon-notifications"></i>
        {!notificationWarningClicked && <span className="badge">!</span>}
        {notificationWarningClicked && notifications.length > 0 && (
          <span className="badge">{notifications.length}</span>
        )}
      </button>
      <ul className="dropdown-menu notifications-menu" role="menu">
        <h5 className="p-8 m-0">{t('Notifications')}</h5>
        {notifications.map((not, i) => (
          <li
            onClick={() => {
              appStore.dismissNotification(not.id);
              return true;
            }}
            key={i}
          >
            <Link {...(not.url ?? {href: '/'})}>
              <a className="dropdown-item item d-flex">
                <i className={`${not.icon ?? 'icon-email'}`}/>
                <div>
                  {not.title}
                  <span>{moment(not.CreatedOn).fromNow()}</span>
                </div>
              </a>
            </Link>
          </li>
        ))}
        {notifications.length > 0 && (
          <>
            <li>
              <hr/>
            </li>
            <li>
              <a
                className="dropdown-item action"
                onClick={(ev) => {
                  ev.preventDefault();
                  appStore.clearNotifications();
                }}
                href="#"
              >
                <i className="icon-delete"/>
                {t('Clear notifications')}
              </a>
            </li>
          </>
        )}
        <li>
          <a
            className="dropdown-item action"
            onClick={(ev) => {
              ev.preventDefault();
              requestPushNotifications();
            }}
            href="#"
          >
            <i className="icon-notifications"/>
            {t('Manage offline notifications')}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default NotificationsMenuItem = withCustomComponent(
  'NotificationsMenuItem',
)(
  inject(
    'appStore',
    'authStore',
  )(
    observer(NotificationsMenuItem),
  ),
);
