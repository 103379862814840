import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import CustomField from '../profile/CustomField';
import { action } from 'mobx';

export const SurveyQuestionRow = withCustomComponent('SurveyQuestionRow')(
  inject(
    'appStore',
    'communityStore'
  )(
    withRouter(
      observer(({ question, communityStore, componentName }) => {
        const { t } = useTranslation();
        const field = {
          AvailableOptionArray: question.AvailableOptionsArray,
          AllowMultipleOptions: question.AllowMultipleOptions,
          CustomFieldIndex: question.Id,
          Name: question.Text,
          FieldType: question.QuestionType,
        };

        const updateQuestion = action((name, value) => {
          question[`Custom${question.Id}`] = value;
        });

        return (
          <div className="survey__row" data-component-name={componentName}>
            <CustomField
              validationSchema={
                communityStore.surveyValidation[`Question${question.Id}`]
              }
              field={field}
              updateProperty={updateQuestion}
              customer={question}
              helpText={question.Description}
            />
          </div>
        );
      })
    )
  )
);
