/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('BookingNotesForm')
@withTranslation()
@inject('appStore', 'bookingsStore')
@observer
class BookingNotesForm extends Component {
  updateBooking = this.props.updateBooking;

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t, appStore, bookingsStore, isSidePopup } = this.props;
    const { booking } = bookingsStore;
    const { business } = appStore;

    if (!booking.ResourceId) return <></>

    return (
      <>

        {!booking.Notes && !this.state.showNoteField && (
          <a
            href="#"
            className="btn btn-gray btn-icon -sm mr-8 my-4"
            onClick={() => this.setState({ showNoteField: true })}
          >
            <i className="icon-note"></i>
            {t('Add Note')}
          </a>
        )}

        {(booking.Notes || this.state.showNoteField) && (
          <div className="form-group">
            <label className="fs-18">{t('Note')}</label>
            <textarea
              rows="3"
              id={isSidePopup ? "js--input" : ""}
              className="form-control"
              value={booking.Notes}
              onChange={(ev) => this.updateBooking('Notes', ev.target.value)}
              placeholder={t('Type any notes for this booking ...')}
            ></textarea>
            <hr className="my-24" />
          </div>
        )}

      </>
    );
  }
}
export default BookingNotesForm;
