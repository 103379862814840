import React from 'react';
import Link from 'next/link';
import {routes} from 'env/routes';
import {useTranslation} from 'react-i18next';
import {withRouter} from 'next/router';
import {inject, observer} from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import moment from 'moment';
import ResponsiveTable from 'ui/components/tables/ResponsiveTable';
import {LocalizedPrice} from 'env/utils/NumbersLocalization';

export const MyBookingsTable = withCustomComponent('MyBookingsTable')(
    inject(
        'appStore',
        'bookingsStore',
    )(
        withRouter(
            observer(props => {
              const {
                router,
                appStore,
                bookingsStore,
                componentName,
                change_route,
              } = props;
              const {t} = useTranslation();
              const {upcomingBookings} = bookingsStore;
              const {business} = appStore;

              const newBooking = () => {
                bookingsStore.newBooking({});
              };

              const isCorporateDashboard = appStore.corporateDashboard;

              return (
                  <div className="card card-dashboard mb-32">
                    <fieldset
                        data-component-name={componentName}
                        id="my-bookings-table"
                    >
                      <h5 className="section__title mb-8">{t('My bookings')}</h5>
                      <p className="mxw-600px text-gray-700 fs-14 mb-20">
                        {t(
                            'This list shows bookings in the coming 90 days. Use the calendar to see all your bookings',
                        )}
                      </p>

                      {upcomingBookings.length === 0 && (
                          <div className="alert alert-outline">
                            <i className="icon-calendar-view"></i>
                            <h5>{t(`You've got no bookings.`)}</h5>
                            <p>
                              {t(
                                  'You can make a booking by checking available resources. These are some links you may find useful:',
                              )}
                            </p>
                            <div className="btn-group">
                              <Link href={routes.resources}>
                                <a className="btn btn-outline-gray btn-icon">
                                  <i className="icon-bookings"></i>
                                  {t('Make a Booking')}
                                </a>
                              </Link>
                            </div>
                          </div>
                      )}

                      {upcomingBookings.length > 0 && (
                          <>
                            <ResponsiveTable>
                              <thead>
                              <tr>
                                <th>{t('Resource')}</th>
                                <th>{t('Date')}</th>
                                <th>{t('Hours')}</th>
                                {!isCorporateDashboard && <th>{t('Amount')}</th>}
                                <th></th>
                              </tr>
                              </thead>
                              <tbody>
                              {upcomingBookings.map((booking, i) => (
                                  <tr key={i}>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <Link
                                            href={routes.booking_by_id('[booking_id]')}
                                            as={routes.booking_by_id(booking.Id)}
                                        >
                                          <a
                                              className="avatar avatar--48 rounded mr-12"
                                              style={{
                                                backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/publicResources/GetImage?id=${booking.Resource.Id}&w=96&h=96&cache=${booking.Resource.UpdatedOn})`,
                                              }}
                                              alt={booking.Resource.Name}
                                          ></a>
                                        </Link>
                                        <div className="card-bookings-available__title d-flex align-items-center">
                                          <div
                                              className={`higlight bg-${bookingsStore.getResourceColor(
                                                  booking.Resource.Id,
                                              )}`}
                                          ></div>
                                          <div className="title-wrapper">
                                            <h5 className="m-0">
                                              <Link
                                                  href={routes.booking_by_id(
                                                      '[booking_id]',
                                                  )}
                                                  as={routes.booking_by_id(booking.Id)}
                                              >
                                                <a className="text-gray-900">
                                                  {booking.Resource.Name}
                                                </a>
                                              </Link>
                                              {booking.FloorPlanDeskId &&
                                                  booking.Resource.Name !=
                                                  booking.FloorPlanDeskName && (
                                                      <>
                                                        {' '}
                                                        <Link
                                                            href={{
                                                              pathname:
                                                                  routes.floorplan_by_id(
                                                                      '[floorplan_id]',
                                                                  ),
                                                              query: {
                                                                item_id:
                                                                booking.FloorPlanDeskId,
                                                              },
                                                            }}
                                                            as={{
                                                              pathname: routes.floorplan_by_id({
                                                                Id: booking.FloorPlanDesk.FloorPlanId,
                                                                Name: booking.FloorPlanDeskName,
                                                              }),
                                                              query: {
                                                                item_id:
                                                                booking.FloorPlanDeskId,
                                                              },
                                                            }}
                                                        >
                                                          <a className="text-gray-900">
                                                            {booking.FloorPlanDeskName}
                                                          </a>
                                                        </Link>
                                                      </>
                                                  )}
                                            </h5>
                                            {/* BB-TODO : Update API to return ResourceProducts */}
                                            {booking.ResourceProducts &&
                                                booking.ResourceProducts.length > 0 && (
                                                    <div className="card-bookings-available__features mt-4">
                                                      <i className="icon-add-small"></i>
                                                      {booking.ResourceProducts.filter(
                                                          (p) => p.Quantity > 0,
                                                      ).map((product, i) => (
                                                          <span
                                                              key={i}
                                                              className="text-gray-700 fs-14"
                                                          >
                                            {product.Quantity}
                                                            {'x '}
                                                            {product.ProductName}
                                          </span>
                                                      ))}
                                                    </div>
                                                )}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td valign="middle">
                                      {moment(booking.FromTime).format('LL')}
                                      {booking.Tentative && (
                                          <small className="mt-6">
                                            {t('This booking has not been confirmed yet.')}
                                          </small>
                                      )}
                                    </td>
                                    <td valign="middle">
                                      {moment(booking.FromTime).format('LT')} -{' '}
                                      {moment(booking.ToTime).format('LT')}
                                    </td>
                                    {!isCorporateDashboard && (
                                        <td valign="middle">
                                          {booking.CoworkerExtraServicePrice != null &&
                                              booking.Invoiced && (
                                                  <LocalizedPrice
                                                      currency={business.Currency.Code}
                                                      amount={booking.CoworkerExtraServicePrice}
                                                  />
                                              )}
                                          {(!booking.Invoiced ||
                                                  booking.CoworkerExtraServicePrice == null) &&
                                              '—'}
                                        </td>
                                    )}
                                    <td
                                        valign="middle"
                                        className="text-left text-md-right"
                                    >
                                      <Link
                                          href={change_route ? change_route('[booking_id]') : routes.booking_by_id('[booking_id]')}
                                          as={change_route ? change_route(booking.Id) : routes.booking_by_id(booking.Id)}
                                      >
                                        <a className="btn btn-xs btn-link text-gray-700 mr-8">
                                          <i className="icon-edit mr-4"></i>
                                          {t('Change')}
                                        </a>
                                      </Link>
                                    </td>
                                  </tr>
                              ))}

                              {/* {% endfor %} */}
                              </tbody>
                            </ResponsiveTable>
                            <div className="btn-group mt-32">
                              <a href="#" onClick={newBooking} className="btn ">
                                {t('Make a booking')}
                              </a>
                              <Link
                                  href={{
                                    pathname: routes.calendar,
                                    query: {private: true},
                                  }}
                              >
                                <a className="btn btn-outline-gray ">
                                  {t('My bookings calendar')}
                                </a>
                              </Link>
                            </div>
                          </>
                      )}
                    </fieldset>
                  </div>
              );
            }),
        ),
    ),
);
