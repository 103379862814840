import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { action } from 'mobx';
import Link from 'next/link';
import { routes } from 'env/routes';
import ContractDetails from 'ui/_pages/plan/ContractDetails';
import SelectField from 'ui/components/forms/SelectField';
import TextAreaInputField from 'ui/components/forms/TextAreaInputField';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('CancelContractPage')
@withTranslation()
@inject('authStore', 'appStore', 'invoicingStore')
@observer
class CancelContractPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { baseUrl, invoicingStore } = this.props;
    invoicingStore
      .loadContract(this.props.match.params.contractId)
      .catch(() => this.props.history.push(baseUrl + routes.plan));
  }

  @action updateContract(name, value) {
    const { invoicingStore } = this.props;
    const { contract } = invoicingStore;
    contract[name] = value;
  }

  cancelContract = () => {
    const { baseUrl, t, invoicingStore, appStore } = this.props;
    const { contract } = invoicingStore;
    invoicingStore
      .cancelContract(contract.Id)
      .then((contract) => {
        appStore
          .setPopMessage(
            t(
              'Your plan {{Name}} will be cancelled on {{AbsoluteCancellationDate}}',
              {
                Name: contract.Tariff.Name,
                AbsoluteCancellationDate: moment(
                  contract.AbsoluteCancellationDate
                ).format('L'),
              }
            )
          )
          .then(() => this.props.history.push(baseUrl + routes.plan));
      })
      .catch(() => {
        appStore.setPopMessage(
          t('Your plan could not be cancelled. Please contact us.')
        );
      });
  };

  onPlanSelected = ({ tariff }) => {
    const { baseUrl, t, appStore, invoicingStore } = this.props;

    invoicingStore
      .saveContract({
        contractId: this.props.match.params.contractId,
        tariffId: tariff.Id,
      })
      .then((contract) => {
        appStore
          .setPopMessage(
            t(
              'Your plan {{Name}} will change to {{NextName}} on {{RenewalDate}}',
              {
                NextName: contract.NextTariff.Name,
                Name: contract.Tariff.Name,
                RenewalDate: moment(contract.RenewalDate).format('L'),
              }
            )
          )
          .then(() => this.props.history.push(baseUrl + routes.plan));
      })
      .catch(() => {
        appStore.setPopMessage(
          t('Your plan could not be changed. Please contact us.')
        );
      });
  };

  render() {
    const { componentName, t, appStore, invoicingStore } = this.props;
    const { business } = appStore;
    const { contract, isCancellingContract } = invoicingStore;
    return (
      <main
        className="content content--signup"
        role="main"
        data-component-name={componentName}
      >
        <div clasName="container">
          {/* <!-- CREDENTIALS --> */}
          <div clasName="credentials credentials--step">
            <div clasName="row">
              <div clasName="col-md-8 col-md-offset-2">
                {/* <!-- LOGO OR SPACE NAME --> */}
                <Link href={routes.home}>
                  <a clasName="navbar-brand">
                    <img
                      src={`${business.NativeHomeUrlWithLanguage}/business/getlogo?w=600&h=150&mode=pad`}
                      alt={t(business.Name)}
                    />
                  </a>
                </Link>
                {/* <!-- END LOGO OR SPACE NAME --> */}

                {/* <!-- CREDENTIALS HEADER --> */}
                <header clasName="credentials__header">
                  <h1 clasName="h2">{t('Cancel your plan')}</h1>
                </header>
                {/* <!-- END CREDENTIALS HEADER --> */}

                {/* <!-- CREDENTIALS STEPS --> */}
                <ul clasName="row steps">
                  <li clasName="col-xs-6 active">
                    <span>1</span> <b>{t('Cancellation feedback')}</b>
                  </li>
                  <li clasName="col-xs-6">
                    <span>2</span> <b>{t('Confirmation')}</b>
                  </li>
                </ul>
                {/* <!-- END CREDENTIALS STEPS --> */}

                <h4>{t('You are about to request to cancel this plan:')}</h4>
                <div clasName="credentials__box">
                  {contract ? (
                    <>
                      <ContractDetails contract={contract} basic />

                      <hr className="form--divider" />
                      <p>
                        {t(
                          'Knowing why you decided to cancel your membership can help us improve our service and the community of users at {{Name}}. We appreciate your honest feedback.',
                          { Name: t(business.Name) }
                        )}
                      </p>

                      <div clasName="form-group">
                        <SelectField
                          name="CancellationReason"
                          id="CancellationReason"
                          value={contract.CancellationReason}
                          onChange={(name, value) =>
                            this.updateContract(name, value)
                          }
                          label={t('Main reason to cancel your membership')}
                        >
                          <option></option>
                          <option value="BusinessExpansion">
                            {t('Business expansion / not enough space')}
                          </option>
                          <option value="ChangeWorkEnvironment">
                            {t('Want to change work environment')}
                          </option>
                          <option value="LackCommunityInterations">
                            {t('Lack of community interactions')}
                          </option>
                          <option value="MovedToOtherSpace">
                            {t('Moved to another workspace')}
                          </option>
                          <option value="NewJobRelocation">
                            {t('Relocation / new job')}
                          </option>
                          <option value="OtherMembers">
                            {t('Other members of the community')}
                          </option>
                          <option value="PoorSpaceCondition">
                            {t('Poor space conditions / service')}
                          </option>
                          <option value="PriceTooHigh">
                            {t('Price is too high')}
                          </option>
                          <option value="Rellocated">
                            {t('Just temporarily in this area')}
                          </option>
                          <option value="Other">{t('Other')}</option>
                        </SelectField>
                      </div>
                      <div clasName="form-group">
                        <TextAreaInputField
                          name="CancellationNotes"
                          id="CancellationNotes"
                          value={contract.CancellationNotes}
                          onChange={(name, value) =>
                            this.updateContract(name, value)
                          }
                          label={t('Any other comments or feedback')}
                        />
                      </div>

                      <button
                        onClick={(ev) => {
                          if (isCancellingContract) return;
                          ev.preventDefault();
                          this.cancelContract();
                        }}
                        type="button"
                        className="btn btn--primary"
                      >
                        {isCancellingContract ? (
                          <LoadingSpinner />
                        ) : (
                          t('End membership on {{EarliestCancellationDate}}', {
                            EarliestCancellationDate: moment(
                              contract.EarliestCancellationDate
                            ).format('L'),
                          })
                        )}
                      </button>
                    </>
                  ) : (
                    <LoadingSpinner />
                  )}
                </div>
              </div>
            </div>

            <div clasName="form-actions">
              <Link clasName="btn btn--secondary" to={routes.plan}>
                <a href="#">
                  <i clasName="fa fa-angle-left i-space--right"></i>{' '}
                  {t('go back')}
                </a>
              </Link>
            </div>
          </div>
          {/* <!-- END CREDENTIALS --> */}
        </div>
      </main>
    );
  }
}

export default CancelContractPage;
