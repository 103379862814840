import React from 'react';
import {useTranslation} from 'react-i18next';
import withCustomComponent from '../../../components/withCustomComponent';
import {inject, observer} from 'mobx-react';

let EditBasketItem = props => {
  const {
    onEdit,
  } = props;
  const {t} = useTranslation();

  return (
    <a
      href="#"
      onClick={(ev) => {
        ev.preventDefault();
        onEdit();
      }}
      className="btn btn-xs btn-icon btn-white border-0"
    >
      <i className="icon-edit"></i>
      {t('Change')}
    </a>
  );
};

export default EditBasketItem = withCustomComponent('CheckoutRemoveFromBasket')(
  observer(EditBasketItem)
);