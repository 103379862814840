import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import SignupPageLayout from 'ui/layouts/SignupPageLayout';
import { BasketItemFactory } from 'ui/_pages/checkout/basketItemFactory';
import { DiscountInfoSection } from 'ui/_pages/checkout/DiscountInfoSection';
import { CheckoutDiscountSection } from 'ui/_pages/checkout/CheckoutDiscountSection';
import { CheckoutTotalPrice } from 'ui/_pages/checkout/CheckoutTotalPrice';
import { CheckoutButton } from 'ui/_pages/checkout/CheckoutButton';
import { routes } from 'env/routes';
import { withRouter } from 'next/router';
import { privateRoute } from 'env/privateRoute';
import { SignupLoadingBanner } from 'ui/_pages/signup/summary/SignupLoadingBanner';

@privateRoute
@withRouter
@withCustomComponent('SignupSummaryPage')
@withTranslation()
@inject('appStore', 'authStore', 'checkoutStore')
@observer
class SignupSummaryPage extends Component {
  componentDidMount() {
    const { t, router, appStore, authStore, checkoutStore } = this.props;

    checkoutStore
      .loadInvoicePreview()
      .then((invoice) => {
        if (invoice?.WasSuccessful === false) {
          appStore.setPopMessage(t(invoice.Message));
        } else if (!invoice) {
          checkoutStore.loadBasketInvoice().then(() => {
            checkoutStore.clearBasket();
            router.push(routes.signup_payment);
          });
        }
      })
      .catch((err) => {
        appStore.setPopMessage(
          t('Sorry, we could not load this page. {{err}}', { err })
        );
      });
  }

  render() {
    const { t, appStore, checkoutStore } = this.props;
    const { configuration } = appStore;
    const {
      basket,
      isLoadingInvoicePreview,
      hasLoadedInvoicePreview,
      invoicePreview,
      hasPlan,
    } = checkoutStore;
    const loadingData = isLoadingInvoicePreview || !hasLoadedInvoicePreview;
    const keepOnHold = hasPlan && configuration['Signup.KeepNewAccountsOnHold'];
    const isCorporateDashboard = appStore.corporateDashboard;

    return (
      <SignupPageLayout componentName={this.props.componentName} step={4}>
        <div className="mw-820">
          {
            <div className="box checkout">
              {basket.map((item, i) => (
                <div className="mb-24">
                  <BasketItemFactory key={i} basketItem={item} />
                </div>
              ))}

              {loadingData && (
                <>
                  <SignupLoadingBanner />
                </>
              )}
              {!loadingData && (
                <>
                  {!isCorporateDashboard &&
                    invoicePreview &&
                    invoicePreview.Lines && (
                      <div className="mb-24">
                        <DiscountInfoSection />
                      </div>
                    )}

                  {/*{!isCorporateDashboard && <CheckoutDiscountSection />}*/}

                  <div>
                    <div className="text-right mb-48">
                      {!isCorporateDashboard && <CheckoutTotalPrice />}
                    </div>
                    <CheckoutButton
                      completeUrl={routes.signup_complete}
                      onlyPostItems={keepOnHold}
                      showClearBasket={false}
                      returnUrl={routes.signup_payment}
                    />
                  </div>
                </>
              )}
            </div>
          }
        </div>
      </SignupPageLayout>
    );
  }
}

export default SignupSummaryPage;
