import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import ReactMde from 'react-mde';
import 'react-mde/lib/styles/css/react-mde-all.css';
@observer
class MarkdownInputField extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(data) {
    this.props.onChange(this.props.name, data.length > 0 ? data : null);
  }

  render() {
    const input = this.props;
    const hasErrors = input.errors && input.errors.length > 0;
    return (
      <>
        <label className="control-label" htmlFor={input.id}>
          {input.label || input.name}
        </label>

        <ReactMde
          generateMarkdownPreview={false}
          id={input.id}
          name={input.name}
          value={input.value || ''}
          onChange={this.onChange}
        />

        {hasErrors && (
          <small className="help-block validation-error">
            {input.errors.join(', ')}
          </small>
        )}
      </>
    );
  }
}

MarkdownInputField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default MarkdownInputField;
