import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import { withRouter } from 'next/router';
import LoadingSpinner from 'ui/components/LoadingSpinner';

import { Pagination } from 'ui/components/listings/Pagination';
import { NoAccessNotice } from 'ui/components/NoAccessNotice';

import { EventsPageHeader } from 'ui/_pages/events/EventPageHeader';
import { EventsPageFilters } from 'ui/_pages/events/EventsPageFilters';
import { EventsPageList } from 'ui/_pages/events/EventsPageList';
import { EventsPageZeroCase } from 'ui/_pages/events/EventsPageZeroCase';

@withCustomComponent('EventsPage')
@withTranslation()
@withRouter
@inject('appStore', 'authStore', 'eventsStore')
@observer
class EventsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loaded: false,
    };
  }

  async componentDidMount() {
    const { t, router, eventsStore } = this.props;
    if (!eventsStore.hasLoadedEventsPage) {
      await eventsStore.loadEventsPage({ ...router.query }).catch((err) => {
        this.setState({
          error: t("You currently don't have access to the events calendar."),
        });
      });
    }

    this.setState({ loaded: true });
  }

  componentDidUpdate(prevProps, prevState) {
    const { t, router, eventsStore } = this.props;
    const prevQuery = prevProps.router.query;
    const currentQuery = router.query;
    if (JSON.stringify(currentQuery) != JSON.stringify(prevQuery)) {
      eventsStore.loadEventsPage({ ...router.query }).catch((err) => {
        this.setState({
          error: t("You currently don't have access to the events calendar."),
        });
      });
    }
  }

  render() {
    const { t, appStore, eventsStore } = this.props;
    const { business } = appStore;
    const { eventsPage, hasLoadedEventsPage } = eventsStore;
    const hasError = this.state.error != null;
    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={t('Events - {{Name}}', { Name: t(business.Name) })}
      >
        <main className="content content--custom" role="main">
          <div className="container">
            <EventsPageHeader />
            <EventsPageFilters />
            {!hasLoadedEventsPage && !this.state.loaded && <LoadingSpinner />}
            {hasLoadedEventsPage && this.state.loaded && (
              <>
                <NoAccessNotice error={this.state.error} />
                <EventsPageList />
                {!hasError && <EventsPageZeroCase />}
                <Pagination
                  page={eventsPage.Page}
                  records={eventsPage.CalendarEvents}
                  top={eventsPage.Top}
                />
              </>
            )}
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default EventsPage;
