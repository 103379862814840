import React, { useEffect } from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { action } from 'mobx';
import LoadingSpinner from 'ui/components/LoadingSpinner';

export const ReferralModal = withCustomComponent('ReferralModal')(
  inject('appStore')(
    withRouter(
      observer(({ router, appStore }) => {
        const { t } = useTranslation();
        const { referralDiscounts, isLoadingSendReferralInvite } = appStore;

        useEffect(() => {
          $('#modal_referral').modal({
            backdrop: 'static',
            keyboard: false,
            show: true,
          });

          return () => {};
        }, []);

        const showReferralModal = action((show) => {
          appStore.showReferralModal = show;
        });

        const sendReferral = async () => {
          for (
            let index = 0;
            index < appStore.referralDiscounts.length;
            index++
          ) {
            const discount = appStore.referralDiscounts[index];
            if (discount.email) {
              await appStore
                .loadSendReferralInvite({
                  discountCodeId: discount.Id,
                  email: discount.email,
                })
                .then(() => {
                  $('#modal_referral').modal('hide');
                  showReferralModal(false);
                })
                .catch((err) => {
                  $('#modal_referral').modal('hide');
                  if (err.ErrorMessage) {
                    appStore
                      .setPopMessage(
                        t('We could not send this discount: {{ErrorMessage}}', {
                          ErrorMessage: err.ErrorMessage,
                        })
                      )
                      .then(() => {
                        $('#modal_referral').modal('show');
                      });
                  } else {
                    appStore
                      .setPopMessage(
                        t('We could not send this discount: {{ErrorMessage}}', {
                          ErrorMessage: err,
                        })
                      )
                      .then(() => {
                        $('#modal_referral').modal('show');
                      });
                  }
                });
            }
          }
        };

        return (
          <div
            id="modal_referral"
            className="modal fade"
            tabindex="-1"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <h4 className="modal-title">{t('Refer to a friend')}</h4>
                </div>
                <div className="modal-body">
                  <p>
                    {t(
                      'Type an email address below to refer someone and get your discount:'
                    )}
                  </p>
                  {referralDiscounts.map((discount, i) => (
                    <form key={i}>
                      <div className="row">
                        <div className="form-group col-sm-12 ">
                          <strong>{discount.Description}</strong> <br />
                          <input
                            value={discount.email}
                            onChange={action((ev) => {
                              discount.email = ev.target.value;
                            })}
                            autocomplete="off"
                            type="email"
                            name="email"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </form>
                  ))}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={(ev) => {
                      ev.preventDefault();
                      $('#modal_referral').modal('hide');
                      showReferralModal(false);
                    }}
                  >
                    {t('Close')}
                  </button>{' '}
                  <button
                    disabled={isLoadingSendReferralInvite ? 'disabled' : null}
                    onClick={(ev) => {
                      if (isLoadingSendReferralInvite) return;
                      ev.preventDefault();
                      sendReferral();
                    }}
                    className="btn btn--primary"
                    type="submit"
                  >
                    {isLoadingSendReferralInvite ? (
                      <LoadingSpinner />
                    ) : (
                      t('Send invite')
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      })
    )
  )
);
