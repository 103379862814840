import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import moment from 'moment';
import { withRouter } from 'next/router';

@withRouter
@withCustomComponent('LegacyCatchAllPage')
@withTranslation()
@observer
class RootLegacyCatchAllPage extends Component {
  componentDidMount() {
    const { router } = this.props;
    router.push(router.asPath.replace(`${router.query.language}`, ''));
  }

  render() {
    const { t } = this.props;
    return <div style={{ padding: 20 }}>{t('Redirecting...')}</div>;
  }
}

RootLegacyCatchAllPage.getInitialProps = ({ asPath, query, res }) => {
  if (res) {
    if (asPath.indexOf('Login') > -1)
      res.writeHead(302, {
        Location:
          asPath.replace(`Login`, 'login') +
          (asPath.indexOf('?') == -1 ? '?server=1' : '&server=1'),
      });
    else {
      res.writeHead(302, {
        Location: asPath.replace(`${query.language}`, ''),
      });
    }
    res.end();
  }

  return {};
};

export default RootLegacyCatchAllPage;
