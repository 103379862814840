import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import moment from 'moment';
import { removeHtml } from 'env/utils/strings';
import AuthenticatedLink from 'ui/components/AuthenticatedLink';

export const PerkMetaData = withCustomComponent('PerkMetaData')(
  inject('appStore')(
    withRouter(
      observer(({ componentName, perk, appStore }) => {
        const { t } = useTranslation();
        const { business } = appStore;

        return (
          <div
            data-component-name={componentName}
            className="blog-post-meta-data p-16 p-md-24 p-lg-32 mb-8"
          >
            <div className="category-date">
              <span className="tag mr-12">{t(perk.GroupName)}</span>
              <span className="fs-12 text-gray-600">
                {t('Last updated on {{date}}', {
                  date: moment(perk.UpdatedOn).format('L'),
                })}{' '}
              </span>
            </div>
            <div className="title mt-12">
              <h2 className="mb-0">{perk.Title}</h2>
            </div>
          </div>
        );
      })
    )
  )
);
