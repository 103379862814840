import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { CoworkerAvatar } from 'ui/components/CoworkerAvatar';
import TextAreaInputField from 'ui/components/forms/TextAreaInputField';
import InputField from 'ui/components/forms/InputField';
import asForm from 'ui/components/forms/asForm';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import NxMentionsInput from 'ui/components/forms/NxMentionsInput/NxMentionsInput';

export const NewSupportMessageReplyForm = withCustomComponent(
  'NewSupportMessageReplyForm'
)(
  inject(
    'appStore',
    'authStore',
    'supportStore'
  )(
    asForm(
      observer(
        ({
          componentName,
          updateProperty,
          message,
          appStore,
          authStore,
          supportStore,
        }) => {
          const { t } = useTranslation();
          const submitReply = () => {
            supportStore
              .submitReply()
              .then(() => {
                appStore.setPopMessage(
                  t(
                    'Thank you for your message. We will get back to you as soon as possible.'
                  )
                );
                supportStore.loadMessage(message.Id);
              })
              .catch((err) => {
                appStore.setPopMessage(err.data ? err.data.ErrorMessage : err);
              });
          };

          const { business } = appStore;
          const { reply, isSubmittingReply } = supportStore;
          const { isLoggedIn, customer } = authStore;

          return (
            <div
              data-component-name={componentName}
              className="comments-form"
            >
              {!isLoggedIn && (
                <Link
                  href={{
                    pathname: routes.login,
                    query: {
                      returnurl: encodeURIComponent(
                        routes.support_message(message.Id)
                      ),
                    },
                  }}
                >
                  <a className="btn btn-xs btn-outline mt-24">
                    {t('Sign in or join to post new comments')}
                  </a>
                </Link>
              )}
              {isLoggedIn && (
                <form noValidate className="d-flex mt-24">
                  <div className="pr-8">
                    <CoworkerAvatar
                      size={80}
                      link
                      imgClassName={'avatar avatar--40'}
                      coworker_id={customer.Id}
                      title={customer.FullName ?? 'no-name'}
                    />
                  </div>
                  <div className="w-100">
                    <div className="d-flex align-items-start justify-content-between border rounded p-4">
                      <div className="nx-mentions-input-wrapper w-100 pr-16">
                        <NxMentionsInput
                          label={null}
                          name="MessageText"
                          placeholder={t('Write a comment ...')}
                          errors={supportStore.replyValidation.errors.MessageText}
                          value={reply.MessageText}
                          onChange={updateProperty}
                          onKeyDown={(ev) => {
                            if (
                              (ev.keyCode == 10 || ev.keyCode == 13) &&
                              (ev.ctrlKey || ev.metaKey)
                            ) {
                              if (isSubmittingReply) return;
                              if (message.Closed) return;
                              ev.preventDefault();
                              submitReply();
                            }
                          }}
                        />
                        {/*
                         <TextAreaInputField
                            id="message-text"
                            label={t('Message')}
                            name="MessageText"
                            errors={supportStore.replyValidation.errors.MessageText}
                            value={reply.MessageText}
                            onChange={updateProperty}
                            inputProps={{
                              placeholder: t('Type your reply here...'),
                            }}
                          />
                         */}
                      </div>
                      <button
                        className="btn "
                        disabled={message.Closed}
                        onClick={(ev) => {
                          ev.preventDefault();
                          submitReply();
                        }}
                      >
                        {isSubmittingReply ? (
                          <LoadingSpinner />
                        ) : (
                            t('Comment')
                          )}
                      </button>
                    </div>
                    <div className="text-gray-600 fs-12 lh-12 pt-4">
                      <small
                        className="d-block text-red"
                        data-bind="validationMessage: message"
                      ></small>
                      <p className="mb-0">
                        {t(
                          "Invite other people to this conversation by mentioning them: type the '@' symbol followed by their name."
                        )}
                      </p>
                    </div>
                  </div>
                </form>
              )}
            </div>
          );
        }
      ),
      'reply'
    )
  )
);
