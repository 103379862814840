import { observable, action } from 'mobx';
import { loadDataHelper } from './storeHelpers';
import StoreBase from './storeBase';

class DeliveriesStore extends StoreBase {
  constructor({ data, host } = {}) {
    super({ data, host });
  }
  @observable isLoadingMyDeliveries = false;
  @observable hasLoadedMyDeliveries = false;
  @observable myDeliveries = [];
  @action loadMyDeliveries() {
    return loadDataHelper({
      store: this,
      agentKey: 'Deliveries',
      key: 'MyDeliveries',
    });
  }
}
export default DeliveriesStore;
