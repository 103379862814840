import React, { useState } from 'react';
import { validationContext, required, pattern } from 'validx';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import Accordion from 'ui/components/Accordion';
import ActionButton from '../../../components/forms/ActionButton';
import InputField from 'ui/components/forms/InputField';
import asForm from 'ui/components/forms/asForm';

export const NewAttendeeAccordion = withCustomComponent('NewAttendeeAccordion')(
  inject('appStore', 'bookingsStore')(
    withRouter(
      observer(
        ({
          setUsers,
          users,
          addAttendee,
          isSidePopup,
          bookingsStore
        }) => {
          const { t } = useTranslation();
          const [newAttendee, setNewAttendee] = useState({
            fullName: '',
            email: '',
            phoneNumber: '',
            errors: {
              fullName: null,
              email: null,
              phoneNumber: null
            }
          });

          return (
            <Accordion id='newAttendee' heading={t('Add Attendee')} showDividerTop={false} key="accordion-attendee" className="new-attendee-accordion mb-20">
              <div>
                <AttendeeForm
                  isSidePopup={isSidePopup}
                  setNewAttendee={setNewAttendee}
                  newAttendee={newAttendee}
                  attendees={bookingsStore.booking?.BookingVisitors}
                />

                <div className="text-right mb-20">
                  <AddAttendeeButton
                    setUsers={setUsers}
                    users={users}
                    setNewAttendee={setNewAttendee}
                    addAttendee={addAttendee}
                    newAttendee={newAttendee}
                  />
                </div>
              </div>
            </Accordion>
          );
      })
    )
  )
);

const AttendeeForm = asForm(
  ({isSidePopup, setNewAttendee, newAttendee, attendees}) => {
    const validationSchema = {
      fullName: [
        required({ msg: t('Full name is required') })
      ],
      email: [
        required({ msg: t('Email is required') }),
        pattern({
          pattern: 'email',
          msg: t('This email address does not seem to be valid.')
        }),
        (opts) => !attendees.some((attendee => attendee.VisitorEmail === opts.value)) || t('An attendee with this email was already added')
      ],
      phoneNumber: [
        pattern({
          pattern: /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]{6,}$/,
          msg: t('Please enter a valid mobile number.')
        })
      ]
    };

    const validateField = (fieldName, value) => {
      const validation = validationContext();
      validation.reset();

      const field = {};
      field[fieldName] = value;

      validation.validate(field, validationSchema);

      return validation.errors[fieldName];
    }

    return (
      <>
        <div className="form-group">
          <InputField
            id={isSidePopup ? 'js--input' : ''}
            label={t('Full name') + '*'}
            name="FullName"
            errors={newAttendee.errors.fullName}
            value={newAttendee.fullName}
            onChange={(key, value) => {
              const errors = validateField('fullName', value);

              setNewAttendee({
                fullName: value,
                email: newAttendee.email,
                phoneNumber: newAttendee.phoneNumber,
                errors: {
                  ...newAttendee.errors,
                  fullName: errors
                }
              })
            }}
          />
        </div>

        <div className="form-group">
          <InputField
            id={isSidePopup ? 'js--input' : ''}
            label={t('Email') + '*'}
            name="Email"
            errors={newAttendee.errors.email}
            value={newAttendee.email}
            onChange={(key, value) => {
              const errors = validateField('email', value);

              setNewAttendee({
                fullName: newAttendee.fullName,
                email: value,
                phoneNumber: newAttendee.phoneNumber,
                errors: {
                  ...newAttendee.errors,
                  email: errors
                }
              })
            }}
          />
        </div>
        
        <div className="form-group">
          <InputField
            id={isSidePopup ? 'js--input' : ''}
            label={t('Phone')}
            name="Phone"
            errors={newAttendee.errors.phoneNumber}
            value={newAttendee.phoneNumber}
            onChange={(key, value) => {
              const errors = validateField('phoneNumber', value);

              setNewAttendee({
                fullName: newAttendee.fullName,
                email: newAttendee.email,
                phoneNumber: value,
                errors: {
                  ...newAttendee.errors,
                  phoneNumber: errors
                }
              })
            }}
          />
        </div>
      </>
    )
  }
  , 'newAttendee')

const AddAttendeeButton = ({setUsers, users, setNewAttendee, addAttendee, newAttendee}) => {
  const { fullName, email, phoneNumber, errors } = newAttendee;
  const isDisabled = !fullName || !email || errors.fullName || errors.email;

  return (
    <ActionButton
      className={`btn  ${isDisabled  ? 'disabled' : ''}`}
      label={t('Add attendee')}
      action={() => {
        if(isDisabled) return;

        addAttendee(users.length+1, fullName, email, phoneNumber);

        setUsers([
          ...users,
          {
            Id: users.length+1,
            Visitor: {
              FullName: fullName,
              Email: email
            }
          }
        ]);

        setNewAttendee({
          fullName: '',
          email: '',
          phoneNumber: '',
          errors: {
            fullName: null,
            email: null,
            phoneNumber: null
          }
        });
      }}
    />
  );
};