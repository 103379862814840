import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { routes } from 'env/routes';
import Link from 'next/link';
import { removeHtml, shorten } from 'env/utils/strings';
import withCustomComponent from 'ui/components/withCustomComponent';

import { CoworkerAvatar } from 'ui/components/CoworkerAvatar';
import { TeamAvatar } from 'ui/components/TeamAvatar';

@withCustomComponent('CardDirectoryMemberTeam')
@withTranslation()
@withRouter
@inject('appStore')
@observer
class CardDirectoryMemberTeam extends Component {
  static propTypes = {
    member: PropTypes.object,
    team: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { t, router, componentName, appStore, member, team } = this.props;
    const { business } = appStore;

    return (
      <div
        data-component-name={componentName}
        className={`card card-directory-member-team ${member ? 'member' : 'team'}`}
      >
        <Link
          as={{
            pathname: member ? routes.directory_member_by_id(member) : routes.directory_team_by_id(team),
            query: router.query,
          }}
          href={{
            pathname: member ? routes.directory_member_by_id('[member_id]') : routes.directory_team_by_id('[team_id]'),
            query: router.query,
          }}
        >
          <a className="card-directory-member-team__content">
            {member ?
              <>
                <CoworkerAvatar
                  coworker_id={member.Id}
                  size={200}
                  imgClassName="avatar avatar--100"
                />
                <h5>{member.FullName}</h5>
                <small>
                {(member.Position || member.CompanyName) && (
                  <>
                    {member.Position}
                    {member.CompanyName && ' - '}
                    {member.CompanyName}
                  </>
                )}
                </small>
                {member.IsNew && (
                  <span className="badge right new">{t('New Member')}</span>
                )}
                {member.IsAdmin && (
                  <span className="badge right admin">{t('Staff')}</span>
                )}
                {member.CheckedIn && (
                  <span className="badge left checked-in">{t('Checked In')}</span>
                )}
              </>
              :
              <>
                <TeamAvatar
                  team_id={team.Id}
                  size={200}
                  imgClassName="avatar avatar--100"
                />
                <h5>{team.Name}</h5>
                <small>
                  {shorten(team.Description, 50)}
                </small>
                {team.AllTeamMembers?.length > 0 && (
                  <span className="badge right people">
                    <i className="icon-community"></i>
                    {team.AllTeamMembers.length}
                  </span>
                )}

              </>
            }
          </a>
        </Link>
        <ul className="card-directory-member-team__social">
          {member ?
            <>
              {member.ProfileWebsite && (
                <li>
                  <a
                    target="_blank"
                    href={member.ProfileWebsite}
                  >
                    <i className="icon-external-link"></i>
                  </a>
                </li>
              )}
              {member.Twitter && (
                <li>
                  <a
                    target="_blank"
                    href={`http://twitter.com/${member.Twitter}`}
                  >
                    <i className="icon-social-twitter"></i>
                  </a>
                </li>
              )}
              {member.Linkedin && (
                <li>
                  <a
                    target="_blank"
                    href={member.Linkedin}
                  >
                    <i className="icon-social-linkedin"></i>
                  </a>
                </li>
              )}
              {member.Facebook && (
                <li>
                  <a
                    target="_blank"
                    href={member.Facebook}
                  >
                    <i className="icon-social-facebook"></i>
                  </a>
                </li>
              )}
              {member.Skype && (
                <li>
                  <a
                    target="_blank"
                    href={`callto://${member.Skype}`}
                  >
                    <i className="icon-skype"></i>
                  </a>
                </li>
              )}
              {member.Google && (
                <li>
                  <a
                    target="_blank"
                    href={member.Google}
                  >
                    <i className="icon-google-square"></i>
                  </a>
                </li>
              )}
              {member.Github && (
                <li>
                  <a
                    target="_blank"
                    href={member.Github}
                  >
                    <i className="icon-github"></i>
                  </a>
                </li>
              )}
              {member.Pinterest && (
                <li>
                  <a
                    target="_blank"
                    href={member.Pinterest}
                  >
                    <i className="icon-pinterest"></i>
                  </a>
                </li>
              )}
              {member.Flickr && (
                <li>
                  <a
                    target="_blank"
                    href={member.Flickr}
                  >
                    <i className="icon-flickr"></i>
                  </a>
                </li>
              )}
              {member.Instagram && (
                <li>
                  <a
                    target="_blank"
                    href={member.Instagram}
                  >
                    <i className="icon-social-instagram"></i>
                  </a>
                </li>
              )}
              {member.Vimeo && (
                <li>
                  <a
                    target="_blank"
                    href={member.Vimeo}
                  >
                    <i className="icon-vimeo"></i>
                  </a>
                </li>
              )}
              {member.Tumblr && (
                <li>
                  <a
                    target="_blank"
                    href={member.Tumblr}
                  >
                    <i className="icon-social-tumblr"></i>
                  </a>
                </li>
              )}
              {member.Blogger && (
                <li>
                  <a
                    target="_blank"
                    href={member.Blogger}
                  >
                    <i className="icon-blogger"></i>
                  </a>
                </li>
              )}
            </>
            :
            <>
              {team.ProfileWebsite && (
                <li>
                  <a
                    target="_blank"
                    href={team.ProfileWebsite}
                  >
                    <i className="icon-external-link"></i>
                  </a>
                </li>
              )}
              {team.Twitter && (
                <li>
                  <a
                    target="_blank"
                    href={`http://twitter.com/${team.Twitter}`}
                  >
                    <i className="icon-social-twitter"></i>
                  </a>
                </li>
              )}
              {team.Linkedin && (
                <li>
                  <a
                    target="_blank"
                    href={team.Linkedin}
                  >
                    <i className="icon-social-linkedin"></i>
                  </a>
                </li>
              )}
              {team.Facebook && (
                <li>
                  <a
                    target="_blank"
                    href={team.Facebook}
                  >
                    <i className="icon-social-facebook"></i>
                  </a>
                </li>
              )}
              {team.Skype && (
                <li>
                  <a
                    target="_blank"
                    href={`callto://${team.Skype}`}
                  >
                    <i className="icon-skype"></i>
                  </a>
                </li>
              )}
            </>
          }

        </ul>
      </div>
    );
  }
}

export default CardDirectoryMemberTeam;
