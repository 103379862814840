export default {
  height: 'auto',
  padding: 0,
  control: {
    height: 'auto',
  },

  highlighter: {
    overflow: 'hidden',
  },

  input: {
    margin: 0,
  },

  '&singleLine': {
    control: {
      display: 'inline-block',

      width: 130,
    },

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },

    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  '&multiLine': {
    control: {
      fontFamily: 'monospace',
    },

    highlighter: {
      padding: 9,
    },

    input: {
      padding: 9,
      minHeight: 63,
      outline: 0,
      border: 0,
    },
  },

  suggestions: {
    list: {
      minWidth: 200,
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
      maxHeight: 150,
      overflowY: 'auto',
      overflowX: 'hidden',
    },

    item: {
      padding: '5px 5px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      whiteSpace: 'nowrap',
      '&focused': {
        backgroundColor: '#fae9b4',
      },
    },
    img: {
      width: 16,
      height: 16,
      marginRight: 10,
      borderRadius: 5,
    },
  },
};
