import React, { useEffect } from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { BookingFeaturesList } from 'ui/_pages/home/components/BookingsWidget/BookingFeaturesList';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';
import { ResourceAvailableSlots } from './ResourceAvailableSlots';
import moment from 'moment';

export const ResourceDetails = withCustomComponent('ResourceDetails')(
  inject(
    'appStore',
    'bookingsStore'
  )(
    withRouter(
      observer(({ resource, bookingsStore, doSearch, appStore }) => {
        const { t } = useTranslation();
        const { business } = appStore;

        useEffect(() => {
          initToolTips();

          return () => {
            const $ = window.$;
            $('[data-toggle="available-tooltip"]').tooltip('dispose');
          };
        }, [resource.Message]);

        const initToolTips = () => {
          const $ = window.$;

          if ($('[data-toggle="available-tooltip"]').length > 0) {
            $('[data-toggle="available-tooltip"]').tooltip({
              html: true,
            });
          }
        };

        const selectDates = (selectingDate, date) => {
          let dates = {};
          dates[selectingDate] = moment(date).toDate();

          if (selectingDate == 'fromTime') {
            if (moment(date) >= moment(bookingsStore.toTime))
              dates.toTime = moment(date).add(60, 'minutes').toDate();
          }

          if (selectingDate == 'toTime') {
            if (moment(date) <= moment(bookingsStore.fromTime))
              dates.fromTime = moment(date).add(-60, 'minutes').toDate();
          }

          bookingsStore.selectDates(dates);
        };

        const newBooking = () => {
          bookingsStore.newBooking({
            resourceId: resource.Id,
            fromTime: bookingsStore.fromTime,
            toTime: bookingsStore.toTime,
          });
        };

        return (
          <li className="col-sm-6 resources-list__item">
            <h2 className="h3 resources-list__title">
              <a href="#">{resource.Name}</a>
            </h2>
            {resource.IsAvailable && (
              <span className="label label--success">{t('Available')}</span>
            )}
            {!resource.IsAvailable && (
              <>
                <span className="label label--danger">{t('Unavailable')}</span>
                <small
                  className="unavailable-message"
                  data-toggle="available-tooltip"
                  title={resource.Message}
                >
                  {' '}
                  {t('Why?')}
                </small>
              </>
            )}
            <a
              onClick={(ev) => {
                ev.preventDefault();
                newBooking();
              }}
              href="#"
              className="resources-list__cover"
            >
              <img
                src={`${business.NativeHomeUrlWithLanguage}/publicResources/GetImage?id=${resource.Id}&w=1110&h=822&cache=${resource.UpdatedOn}`}
                alt={resource.Name}
              />
            </a>
            <ResourceAvailableSlots
              id="booking-search"
              selectDates={selectDates}
              resource={resource}
            />

            <p className="resources-description">
              <span
                dangerouslySetInnerHTML={{
                  __html: resource.Description,
                }}
              ></span>
            </p>

            <ul className="list-inline resources-services-list">
              <BookingFeaturesList data={resource} />
            </ul>
            <ul className="clearfix resources-details-list">
              {resource.Allocation && (
                <li className="resources-details-list__item">
                  <span className="resources-details-list__title">
                    {resource.AllowMultipleBookings
                      ? t('Availability')
                      : t('Seating')}
                  </span>
                  <b className="resources-details-list__value">
                    {resource.AllowMultipleBookings
                      ? t('{{count}} unit', { count: resource.Allocation })
                      : t('{{count}} person', { count: resource.Allocation })}
                  </b>
                </li>
              )}
              {resource.Price >= 0 && (
                <li className="resources-details-list__item">
                  <span className="resources-details-list__title">Price</span>
                  <b className="resources-details-list__value">
                    <LocalizedPrice
                      currency={business.Currency.Code}
                      amount={resource.Price}
                    />
                  </b>
                </li>
              )}
            </ul>
            <div className="btn-toolbar">
              <a
                onClick={(ev) => {
                  ev.preventDefault();
                  newBooking();
                }}
                href="#"
                className="btn btn--primary btn--sm"
              >
                {t('Make booking')}
              </a>
            </div>
          </li>
        );
      })
    )
  )
);
