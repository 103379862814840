import { parseSource } from 'env/utils/babelHelpers';
import { inject } from 'mobx-react';
var cache = require('memory-cache');

const LoadableComponent = inject('appStore')(
  ({ url, appStore, loadingComponent, depth = 0, ...rest }) => {
    if (depth > 10) throw Error('Custom component depth too deep (max = 10)');

    const relativeUrl = url.toLowerCase().split('/page/')[1].split('.jsx')[0];

    const foundPage = appStore.customPages.find((p) => {
      const thisPageUrl = `${p.Name.toLowerCase().split('.jsx')[0]}`;
      return relativeUrl == thisPageUrl;
    });

    const parse = (source, key, url) => {
      console.log(`Parsing ${url}. Name: ${foundPage.Name}. Key: ${key}.`);
      return parseSource(source, depth + 1);
    };

    const source = foundPage?.source;
    if (source) {
      const key = `${foundPage.Id}${foundPage.UpdatedOn}${depth}`;
      try {
        const cached = null;
        cache.get(key);

        const WrappedComponent = cached ?? parse(source, key, url);

        if (cached == null) cache.put(key, WrappedComponent);

        return <WrappedComponent {...rest} />;
      } catch (ex) {
        return (
          <div
            style={{ fontFamily: 'monospace', padding: 5, color: 'red' }}
          >{`Error parsing ${url}: ${ex}`}</div>
        );
      }
    } else {
      const errorComponents = (
        <div
          style={{ fontFamily: 'monospace', padding: 5, color: 'red' }}
        >{`Could not find component with path ${relativeUrl}`}</div>
      );
      cache.put(key, errorComponents);
      return errorComponent;
    }
  }
);

export default LoadableComponent;
