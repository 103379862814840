import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { removeHtml } from 'env/utils/strings';
import AuthenticatedLink from 'ui/components/AuthenticatedLink';

export const PerkDetails = withCustomComponent('PerkDetails')(
  inject('appStore')(
    withRouter(
      observer(({ componentName, appStore, perk }) => {
        const { t } = useTranslation();

        const { business } = appStore;

        return (
          <div
            data-component-name={componentName}
            className="blog-post-details"
          >
            {perk.HasLargeImage && (
              <div className="p-16 p-md-24 p-lg-32 text-center">
                <img
                  className="img-fluid"
                  src={`${business.NativeHomeUrlWithLanguage}/communityperks/GetLargeImage?id=${perk.Id}&w=1145&cache=${perk.UpdatedOn}`}
                />
              </div>
            )}
            <div
              className="blog-post-details__content fr-view"
              dangerouslySetInnerHTML={{
                __html: perk.FullText,
              }}
            ></div>
            <div className="blog-post-details__content pt-0">
              <AuthenticatedLink
                className="btn px-72"
                href={`${business.NativeHomeUrlWithLanguage}/communityperks/claim/?pid=${perk.UniqueId}`}
              >
                {t('Claim')}
              </AuthenticatedLink>
            </div>
          </div>
        );
      })
    )
  )
);
