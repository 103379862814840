import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import asForm from 'ui/components/forms/asForm';
import BooleanInputField from 'ui/components/forms/BooleanInputField';
import PlanTermsModal from 'ui/_pages/plan/PlanTermsModal';

export const SignupPageTermsAndConditions = asForm(
  withCustomComponent('SignupPageTermsAndConditions')(
    inject(
      'authStore',
      'appStore'
    )(
      withRouter(
        observer(
          ({
            componentName,
            updateProperty,
            customer,
            authStore,
            appStore,
          }) => {
            const { t } = useTranslation();
            const { business } = appStore;
            const { signupCustomFields } = authStore;

            const [showTermsForService, setShowTermsForService] = useState(
              null
            );
            return (
              <div data-component-name={componentName} className="mt-24 mb-8">
                {showTermsForService && (
                  <PlanTermsModal
                    close={() => setShowTermsForService(null)}
                    terms={showTermsForService}
                  />
                )}
                {business.TermsAndConditions && (
                  <div className="mb-24">
                    <BooleanInputField
                      label={
                        <Trans>
                          <span className="fs-14 text-gray-700">
                            <span>
                              Check this box to indicate you agree with our{' '}
                            </span>
                            <a
                              href="#"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setShowTermsForService(
                                  business.TermsAndConditions
                                );
                              }}
                            >
                              terms and conditions
                            </a>
                            <span>.</span>
                          </span>
                        </Trans>
                      }
                      name="GeneralTermsAcceptedOnline"
                      value={customer.GeneralTermsAcceptedOnline}
                      onChange={updateProperty}
                      type="checkbox"
                    ></BooleanInputField>
                  </div>
                )}
              </div>
            );
          }
        )
      )
    )
  ),
  'customer'
);
