import React, {useEffect} from 'react';
import Link from 'next/link';
import {routes} from 'env/routes';
import {useTranslation} from 'react-i18next';
import {withRouter} from 'next/router';
import {inject, observer} from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import {LocalizedPrice} from 'env/utils/NumbersLocalization';
import moment from 'moment';

import {bookingsFeatures} from 'env/bookingsResources';
import {ResourceAvailableSlots} from 'ui/_pages/bookings/resources/ResourceAvailableSlots';
import {slugify} from 'env/utils/strings';

export const CardBookingsAvailable = withCustomComponent(
  'CardBookingsAvailable'
)(
  inject(
    'appStore',
    'bookingsStore'
  )(
    withRouter(
      observer(({resource, bookingsStore, showAvailability, appStore}) => {
        const {t} = useTranslation();
        const {business} = appStore;
        useEffect(() => {
          initToolTips();

          return () => {
            const $ = window.$;
            $('[data-toggle="available-tooltip"]').tooltip('dispose');
          };
        }, [resource.Message]);

        const initToolTips = () => {
          const $ = window.$;

          if ($('[data-toggle="available-tooltip"]').length > 0) {
            $('[data-toggle="available-tooltip"]').tooltip({
              html: true,
            });
          }
        };

        const selectDates = (selectingDate, date) => {
          let dates = {};
          dates[selectingDate] = moment(date).toDate();

          if (selectingDate == 'fromTime') {
            if (moment(date) >= moment(bookingsStore.toTime))
              dates.toTime = moment(date).add(60, 'minutes').toDate();
          }

          if (selectingDate == 'toTime') {
            if (moment(date) <= moment(bookingsStore.fromTime))
              dates.fromTime = moment(date).add(-60, 'minutes').toDate();
          }

          bookingsStore.selectDates(dates);
        };


        const newBooking = () => {
          bookingsStore.newBooking({
            resourceId: resource.Id,
            fromTime: bookingsStore.fromTime,
            toTime: bookingsStore.toTime,
          });
        };

        const highlightColor = bookingsStore.getResourceColor(resource.Id);

        const bookingsFeaturesArray = bookingsFeatures(t)
          .map((feature, i) => {
            if (resource[feature.key] == true) return feature.name;
          })
          .filter((f) => f != undefined);

        const hasShifts =
          resource && resource.Shifts && resource.Shifts.length > 0;

        return (
          <div
            className={`card card-bookings-available resource-id-${
              resource?.Id
            } resource-type-${slugify(
              resource?.ResourceTypeName
            )} resource-group-${slugify(resource?.GroupName)}`}
          >
            <div className="card-bookings-available__main">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  newBooking();
                }}
                className="card-bookings-available__image"
                style={{
                  backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/publicResources/GetImage?id=${resource.Id}&w=340&h=400&cache=${resource.UpdatedOn})`,
                }}
                alt={resource.Name}
              ></a>
              <div className="card-bookings-available__content">
                <div className="card-bookings-available__title">
                  <div className={`higlight bg-${highlightColor}`}></div>
                  <div className="title-wrapper">
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        newBooking();
                      }}
                      href="#"
                    >
                      <h4>{resource.Name}</h4>
                    </a>
                    <ul className="list-tags">
                      {resource.Allocation && (
                        <li
                          className="with-icon-left"
                          data-toggle="available-tooltip"
                          title={
                            resource.AllowMultipleBookings
                              ? t('Availability {{count}} unit', {
                                count: resource.Allocation,
                              })
                              : t('Seating {{count}} person', {
                                count: resource.Allocation,
                              })
                          }
                        >
                          <i
                            className={`icon-${
                              resource.AllowMultipleBookings ? 'desks' : 'user'
                            }`}
                          ></i>
                          {'x'}
                          {resource.Allocation}
                        </li>
                      )}
                      {resource.Price >= 0 && (
                        <li
                          className="with-icon-left"
                          data-toggle="available-tooltip"
                          title={t('Price')}
                        >
                          <i className="icon-money"></i>
                          <LocalizedPrice
                            currency={business.Currency.Code}
                            amount={resource.Price}
                          />
                        </li>
                      )}
                      {resource.ResourceTypeName && (
                        <li>{resource.ResourceTypeName}</li>
                      )}
                      {resource.GroupName && <li>{resource.GroupName}</li>}
                    </ul>
                  </div>
                </div>

                {showAvailability && (
                  <div className="card-bookings-available__status">
                    {resource.IsAvailable && (
                      <span className="tag available">{t('Available')}</span>
                    )}
                    {!resource.IsAvailable && (
                      <>
                        {hasShifts && (
                          <span className="tag unknown">
                            {t('Invalid start/end')}
                          </span>
                        )}
                        {!hasShifts && (
                          <span
                            className="tag unavailable"
                            data-toggle="available-tooltip"
                            title={resource.Message}
                          >
                            {t('Unavailable')}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                )}

                <div
                  className="card-bookings-available__description"
                  dangerouslySetInnerHTML={{__html: resource.Description}}
                ></div>
                <div className="card-bookings-available__spacer"></div>
                <div className="card-bookings-available__features">
                  {bookingsFeaturesArray.length > 0 && (
                    <>
                      <span>Includes </span>
                      {bookingsFeaturesArray.map((feature, i) => (
                        <span key={i}>{t(feature)}</span>
                      ))}
                    </>
                  )}
                </div>
                <div className="mt-5 book-now-button">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      newBooking();
                    }}
                    className="btn btn-icon"
                  >
                    <span className="mr-12">{t('Book now')}</span>
                    <i className="icon-i-arrow-i mr-0"></i>
                  </button>
                </div>
              </div>
            </div>
            {/*{showAvailability && (*/}
            {/*  <div className="card-bookings-available__footer">*/}
            {/*    <ResourceAvailableSlots*/}
            {/*      id="booking-search"*/}
            {/*      selectDates={selectDates}*/}
            {/*      resource={resource}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>
        );
      })
    )
  )
);
