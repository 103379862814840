/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import config from 'env/config';

@withTranslation()
@inject('appStore', 'authStore')
class PeachPaymentsPaymentButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showForm: false,
    };
  }

  showForm = () => {
    this.setState({ showForm: true });
    const { appStore } = this.props;
    window.exports = undefined;
    $('#peachPaymentsScriptTag').remove();

    // if (window.wpwl !== undefined && window.wpwl.unload !== undefined) {
    //   window.wpwl.unload();
    //   $('script').each(function () {
    //     if (this.src.indexOf('static.min.js') !== -1) {
    //       $(this).remove();
    //     }
    //   });
    // }

    const scriptTag = document.createElement('script');
    scriptTag.id = 'peachPaymentsScriptTag';
    scriptTag.src = `https://${
      appStore.configuration['PeachPayments.Sandbox'] == 'true' ? 'test.' : ''
    }oppwa.com/v1/paymentWidgets.js?checkoutId=${
      this.props.invoice.PeachPaymentsSignature
    }`;

    document.body.appendChild(scriptTag);
  };

  render() {
    const { t, invoice, componentName, appStore } = this.props;

    return (
      <>
        {this.state.showForm && (
          <div style={{ minWidth: 300 }}>
            <form
              action={`${config.platformRoot}/callbacks/PeachPaymentsResult?invoiceId=${invoice.Id}`}
              class="paymentWidgets"
              data-brands="VISA MASTER AMEX"
            ></form>
          </div>
        )}
        {!this.state.showForm && (
          <a
            href="#"
            onClick={(ev) => {
              ev.preventDefault();
              this.showForm();
            }}
            data-token={invoice.PeachPaymentsSignature}
            data-component-name={componentName}
            className="d-inline-flex align-items-center mt-16 fs-12 tdn ml-10"
          >
            <i aria-hidden="true" className="icon-credit-card"></i>
            {t('Pay by card')}
          </a>
        )}
      </>
    );
  }
}
export default PeachPaymentsPaymentButton;
