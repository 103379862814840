/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('BookingDiscountForm')
@withTranslation()
@inject('appStore', 'bookingsStore')
@observer
class BookingDiscountForm extends Component {
  updateBooking = this.props.updateBooking;

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t, bookingsStore, withBorder } = this.props;
    const { booking, bookingPrice } = bookingsStore;

    if (!booking.ResourceId) return <></>

    return (
      <div
        data-component-name={this.props.componentName}
        className="mt-24 booking-discount-form"
      >
        <h6 className="mb-0 modal-booking-details-title">{t('Discount code')}</h6>
        <div className={`d-flex justify-content-between bg-white py-8 fs-14 mb-12`}>
          <input id="BookingDiscountFormInputField" className="w-100  outline-0" defaultValue={booking.DiscountCode} placeholder={t('Enter discount code')} />
          <button
            className="btn btn-xs btn-outline text-uppercase fs-14 fw-400 px-20"
            onClick={(e) => {
              e.preventDefault();
              this.updateBooking('DiscountCode', document.getElementById('BookingDiscountFormInputField').value)
            }}>
            {t('Apply')}
          </button>
        </div>
        {bookingPrice && bookingPrice.discountCode && (
          <div>
            {bookingPrice.discountCode.discountMessage && (
              <div className="text-red fs-12 mb-12">
                {bookingPrice.discountCode.discountMessage}
              </div>
            )}
            {bookingPrice.discountCode.description && (
              <div className="text-green fs-12 mb-12">
                {bookingPrice.discountCode.description}
              </div>
            )}
          </div>
        )}
        <hr/>
      </div>
    );
  }
}
export default BookingDiscountForm;
