import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { routes } from 'env/routes';
import { linkTo } from 'env/utils/strings';
import ActiveLink from 'ui/components/ActiveLink';

export const EventsPageHeader = withCustomComponent('EventsPageHeader')(
  inject(
    'appStore',
    'eventsStore'
  )(
    withRouter(
      observer(({ router, componentName, eventsStore, appStore }) => {
        const { t } = useTranslation();
        const { eventsPage } = eventsStore;
        const { business } = appStore;

        // BB-TODO: Get upcomingEvents count, and pastEvents count
        const upcomingEventsCount = 9;
        const pastEventsCount = 12;

        return (
          <div
            data-component-name={componentName}
            className="d-block d-md-flex align-items-center justify-content-between"
          >
            <div className="d-block d-md-flex align-items-center">
              <ActiveLink
                href={linkTo(router, { page: 1, pastEvents: 'false' })}
              >
                <a
                  className={`btn btn-link btn-op-02 align-items-baseline tdn mr-24 mr-md-32 ${
                    !eventsPage.PastEvents ? 'active' : ''
                  }`}
                >
                  <h2 className="mb-0 mr-6">{t('Events')}</h2>
                  {/* <h5 className="mb-0 d-none d-md-block">{upcomingEventsCount}</h5> */}
                </a>
              </ActiveLink>

              <ActiveLink
                href={linkTo(router, { page: 1, pastEvents: 'true' })}
              >
                <a
                  className={`btn btn-link btn-op-02 align-items-baseline tdn`}
                >
                  <h2 className="mb-0 mr-6">{t('Past Events')}</h2>
                  {/* <h5 className="mb-0 d-none d-md-block">{pastEventsCount}</h5> */}
                </a>
              </ActiveLink>
            </div>
            <div className="d-flex d-md-block justify-content-between mt-20 mt-md-0">
              <ActiveLink href={routes.events} activeOnRootPath={true}>
                <a className={`btn  btn-icon btn-gray-light btn-md-xs`}>
                  <i className="icon-card-view d-none d-lg-block"></i>
                  <span className="pl-4 pl-lg-0">{t('Card view')}</span>
                </a>
              </ActiveLink>

              <ActiveLink href={routes.events_calendar} activeOnRootPath={true}>
                <a className="btn  btn-icon btn-gray-light ml-0 ml-md-8 btn-md-xs">
                  <i className="icon-calendar-view d-none d-lg-block"></i>
                  <span className="pl-4 pl-lg-0">{t('Calendar view')}</span>
                </a>
              </ActiveLink>
            </div>
          </div>
        );
      })
    )
  )
);
