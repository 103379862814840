import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import BookingDetailsModal from 'ui/_pages/bookings/BookingDetailsModal';

export const BookingDetails = withCustomComponent('BookingDetails')(
  inject(
    'appStore',
    'bookingsStore'
  )(
    withRouter(
      observer(({ close, bookingsStore, componentName }) => {
        const { t } = useTranslation();

        const { booking, isLoadingBooking } = bookingsStore;
        return (
          <div data-component-name={componentName}>
            <>
              {booking && booking.Id === 0 && (
                <BookingDetailsModal
                  close={() =>
                    (close && close()) || bookingsStore.closeNewBooking()
                  }
                />
              )}
              {(isLoadingBooking || (booking && booking.Id > 0)) && (
                <BookingDetailsModal
                  close={() =>
                    (close && close()) || bookingsStore.closeBooking()
                  }
                />
              )}
            </>
          </div>
        );
      })
    )
  )
);
