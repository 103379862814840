import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'next/router';
import { routes } from './routes';
import LoadingLayout from 'ui/layouts/LoadingLayout';

export const checkTerms = (WrappedComponent) => {
  @withRouter
  @inject('authStore', 'appStore')
  @observer
  class CheckTerms extends Component {
    constructor(props) {
      super(props);

      this.state = {
        termsChecked: false,
      };
    }

    async componentDidMount() {
      const { router, authStore, appStore } = this.props;

      const user = appStore.me ?? (await authStore.loadMe());
      if (user != null) {
        const status =
          appStore.legalStatus ?? (await appStore.loadLegalStatus());
        if (status.required)
          router.push(`${routes.legal_terms}?returnurl=${router.asPath}`);
      } else this.setState({ termsChecked: true });
    }

    render() {
      const { appStore } = this.props;
      const { me } = appStore;
      if (
        me &&
        !this.state.termsChecked &&
        (!appStore.legalStatus || appStore.legalStatus.required)
      )
        return <LoadingLayout />;
      return <WrappedComponent>{this.props.children}</WrappedComponent>;
    }
  }

  return CheckTerms;
};
