import React, {Component} from 'react';
import ContractDetails from 'ui/_pages/plan/ContractDetails';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import Link from 'next/link';
import {routes} from 'env/routes';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('YourPlanSection')
@withTranslation()
@inject('authStore', 'appStore', 'invoicingStore')
@observer
class YourPlanSection extends Component {
  componentDidMount() {
    const {invoicingStore} = this.props;
    const {hasLoadedBenefits} = invoicingStore;
    if (!hasLoadedBenefits) invoicingStore.loadBenefits();
  }

  render() {
    const {t, invoicingStore, appStore} = this.props;
    const {benefits} = invoicingStore;
    const nonCancelledContracts = benefits.NonCancelledContracts;

    console.log('nonCancelledContracts');
    console.log(nonCancelledContracts);

    if (!benefits) return <></>;

    return (
      <fieldset
        data-component-name={this.props.componentName}
        className="pb-0"
        id="your-plan"
      >
        <div className="card-item-wrapper mb-0">
          <div className="card-item-header">
            <h5 className="card-item-header-title">
              {t('Your plan')}
            </h5>
          </div>
        </div>
        {nonCancelledContracts.length === 0 && (
          <div className="alert alert-info mb-24" role="alert">
            <b>{t('You are not in a price plan.')}</b>
          </div>
        )}

        {nonCancelledContracts.map((c, i) => (
          <div className="card-item-wrapper mb-5">
            <div className="card-item-body">
              <div className="mb-24">
                <ContractDetails key={i} contract={c}/>
              </div>
            </div>
          </div>
        ))}
      </fieldset>
    );
  }
}

export default YourPlanSection;
