import React from 'react';
import Popup from 'ui/_pages/bookings/floorplan/Popup';

export const returnDomDeskIcons = (type, clickable) => {
  const isClickable = clickable ? 'clickable' : '';
  switch (type) {
    case 'office':
      return <div key='office' className={`icon office ${isClickable}`} />;
    case 'other':
      return <div className={`svg other ${isClickable}`} key='other' />;
    case 'dedicated':
      return <div key='dedicated' className={`svg dedicated ${isClickable}`} />;
    case 'hot':
      return <div key='hot' className={`svg hot ${isClickable}`} />;
    case 'popup':
      return <Popup key='popup' />;
    case 'tooltip':
      return <Tooltip key='tooltip' />;
    default:
      return <div />;
  }
};

export const returnDomDeskIconsMobile = (type, clickable) => {
  const isClickable = clickable ? 'clickable' : '';
  switch (type) {
    case 'office':
      return <div key='office' className={`icon office ${isClickable}`} />;
    case 'other':
      return <div className={`svg other ${isClickable}`} key='other' />;
    case 'dedicated':
      return <div key='dedicated' className={`svg dedicated ${isClickable}`} />;
    case 'hot':
      return <div key='hot' className={`svg hot ${isClickable}`} />;
    case 'tooltip':
      return <Tooltip key='tooltip' />;
    default:
      return <div />;
  }
};

const InvisibleDeskNodes = (props) => (
  <div style={{ display: 'none' }}>
    {['office', 'other', 'dedicated', 'hot', 'popup', 'tooltip'].map((type) => (
      <div className='js--anchor' id={type} key={type}>
        {!!props.isMobilePage
          ? returnDomDeskIconsMobile(type, props.clickable)
          : returnDomDeskIcons(type, props.clickable)}
      </div>
    ))}
  </div>
);

const Tooltip = () => (
  <div className='tooltip bs-tooltip-top js--tooltip' role='tooltip'>
    <div className='tooltip-inner js--tooltip-inner d-flex flex-column px-12 pt-12'></div>

    <div className='arrow'></div>
  </div>
);

export default InvisibleDeskNodes;
