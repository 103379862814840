import React, { useEffect } from 'react';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import { routes } from '../../env/routes';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import { useTranslation } from 'react-i18next';
import { ContactForm } from 'ui/_pages/contact/ContactForm';
import { ContactDetails } from 'ui/_pages/contact/ContactDetails';
import { LocationMap } from 'ui/_pages/contact/LocationMap';

export default withCustomComponent('ContactPage')(
  inject(
    'appStore',
    'supportStore'
  )(
    withRouter(
      observer(({ appStore, supportStore, componentName, router }) => {
        const { t } = useTranslation();
        const { business, configuration } = appStore;
        const { newContactMessage } = supportStore;

        useEffect(() => {
          if (!configuration['PublicWebSite.Contact']) {
            router.push(routes.home);
          }
        }, []);

        return (
          <DefaultLayout
            componentName={componentName}
            title={t('Contact us') + ' - ' + t(business.Name)}
          >
            <main className="content content--custom" role="main">
              <div class="container">
                <div data-component-name={componentName}>
                  <h2 className="h3 section__title">
                    {t('Get in touch with us')}
                  </h2>
                  <div className="row mb-48">
                    <div className="col-sm-6">
                      <div className="your-input" data-mh="contact-box">
                        <ContactForm newContactMessage={newContactMessage} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <LocationMap />
                    </div>
                  </div>
                  <ContactDetails />
                </div>
              </div>
            </main>
          </DefaultLayout>
        );
      })
    )
  )
);
