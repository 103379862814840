import React from 'react';
import {useTranslation} from 'react-i18next';
import Link from 'next/link';
import {routes} from 'env/routes';
import withCustomComponent from 'ui/components/withCustomComponent';

export const SupportHeader = withCustomComponent('SupportHeader')(
  ({componentName}) => {
    const {t} = useTranslation();

    return (
      <>
        {/*<div className="portal-title-wrapper" data-component-name={componentName}>
          <div className="container portal-title-container">
            <h1 className="portal-title">
              {t('Help')}
            </h1>
            <Link href={routes.support_new}>
              <a className="btn btn-icon">
                <i className="icon-add"></i>
                {t('Ask for Help')}
              </a>
            </Link>
          </div>
        </div>*/}
        <div className="container">
          <div
            data-component-name={componentName}
            className="d-block d-md-flex align-items-start justify-content-between mb-32"
          >
            <div className="">
              <div className="fs-14 fw-700 mxw-600px">
                {t('Report a problem, ask for help.')}
              </div>
              <div className="fs-14 text-gray-700 mxw-600px">
                {t('If you find or experience any problems please contact us via this wall. We will tackle the problem as soon as possible and let you know via this wall too.')}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);
