import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const FloorPlanLegend = withCustomComponent('FloorPlanLegend')(
  inject(
    'appStore',
    'floorPlanStore'
  )(
    withRouter(
      observer(({ componentName, floorPlanStore }) => {
        const { t } = useTranslation();
        const { currentFloorPlan, filteredItems } = floorPlanStore;
        const items = filteredItems;
        const floorPlanCapacity =
          currentFloorPlan?.Capacity ?? items.length ?? 0;

        const capacity =
          floorPlanCapacity > items.length ? items.length : floorPlanCapacity;

        const itemsLeft =
          items.length - items.filter((x) => x.ErrorCode != null).length;
        const available = itemsLeft > capacity ? capacity : itemsLeft;
        return (
          <div
            data-component-name={componentName}
            className="floorplans__legend mt-20"
          >
            <ul className="list-inline-32 sm-column">
              <li className="d-flex align-items-center office">
                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h15.972v16H0z" fillRule="evenodd" />
                </svg>
                <span className="pl-8 fs-14">{t('Office')}</span>
              </li>
              <li className="d-flex align-items-center dedicated">
                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                  <ellipse
                    cx="7.986"
                    cy="8"
                    rx="7.986"
                    ry="8"
                    fillRule="evenodd"
                  />
                </svg>
                <span className="pl-8 fs-14">{t('Dedicated Desk')}</span>
              </li>
              <li className="d-flex align-items-center hot">
                <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.986 0l7.986 16H0z" fillRule="evenodd" />
                </svg>
                <span className="pl-8 fs-14">{t('Hot Desk')}</span>
              </li>
              <li className="d-flex align-items-center other">
                <svg width="17" height="16" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.307 0l8.307 6.046-3.173 9.783H3.173L0 6.046z"
                    fillRule="evenodd"
                  />
                </svg>
                <span className="pl-8 fs-14">
                  {t('Meeting Rooms and Other')}
                </span>
              </li>
              <li className="ml-0 ml-md-auto w-sm-100">
                <div>
                  <div className="d-flex align-items-center justify-content-between mb-8">
                    <div className="mr-5">{t('Availability:')}</div>
                    <div>
                      {t('{{valueNow}} / {{valueMax}}', {
                        valueNow: available,
                        valueMax: capacity,
                      })}
                    </div>
                  </div>
                  <div className="progress ">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuenow={available}
                      aria-valuemin={0}
                      aria-valuemax={capacity}
                      style={{
                        width: (available / capacity) * 100.0 + '%',
                      }}
                    ></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        );
      })
    )
  )
);
