import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

import { EventTicketsPageTicketsList } from 'ui/_pages/events/tickets/EventTicketsPageTicketsList';
import { EventTicketsPageAttendeeList } from 'ui//_pages/events/tickets/EventTicketsPageAttendeeList';


export const EventTicketsPageTicketsSection = withCustomComponent(
  'EventTicketsPageTicketsSection'
)(
  inject(
    'appStore',
    'authStore',
    'eventsStore'
  )(
    withRouter(
      observer(({ authStore, componentName, appStore, eventsStore }) => {
        const { t } = useTranslation();
        const { eventTicketsPage } = eventsStore;
        const event = eventTicketsPage.Event;
        return (
          <form
            data-component-name={componentName}
            className="event-tickets-page-tickets-section"
          >
            <EventTicketsPageTicketsList />
            <EventTicketsPageAttendeeList />
          </form>
        );
      })
    )
  )
);
