import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import DateInputField from 'ui/components/forms/DateInputField';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import moment from 'moment';
export const DateFilters = withCustomComponent('DateFilters')(
  inject('appStore')(
    withRouter(
      observer(
        ({
          componentName,
          doSearch,
          selectShift,
          selectDates,
          shifts,
          isLoadingItems,
          shiftId,
          fromTime,
          toTime,
        }) => {
          const { t } = useTranslation();

          const updateByShift = (shiftId) => {
            selectShift(shiftId);
            doSearch();
          };

          const updateDates = (name, value) => {
            //If we are moving the fromTime, shift the toTime by the same amount.
            var updateObj = {};
            if (name == 'fromTime') {
              var diff = moment(value).diff(fromTime, 'minutes');
              updateObj.toTime = moment(toTime).add(diff, 'minutes').toDate();
            }

            updateObj[name] = value;
            selectDates(updateObj);
            doSearch();
          };

          const hasShifts = shifts.length > 0;
          return (
            <form
              className="form form--floorplans"
              data-component-name={componentName}
            >
              <div className="row">
                <div className="col-sm-3 col-md-2">
                  <div className="form-group">
                    <DateInputField
                      label={t('From date')}
                      icon="fa-calendar"
                      name="fromTime"
                      value={fromTime}
                      onChange={updateDates}
                    />
                  </div>
                </div>
                {!hasShifts && (
                  <div className="col-sm-3 col-md-2">
                    <div className="form-group">
                      <DateInputField
                        showTimeSelect={true}
                        label={t('From time')}
                        icon="fa-clock-o"
                        name="fromTime"
                        value={fromTime}
                        onChange={updateDates}
                      />
                    </div>
                  </div>
                )}
                <div className="col-sm-3 col-md-2">
                  <div className="form-group">
                    <DateInputField
                      label={t('Until date')}
                      icon="fa-calendar"
                      name="toTime"
                      value={toTime}
                      onChange={updateDates}
                    />
                  </div>
                </div>
                {!hasShifts && (
                  <div className="col-sm-3 col-md-2">
                    <div className="form-group">
                      <DateInputField
                        showTimeSelect={true}
                        label={t('Until time')}
                        icon="fa-clock-o"
                        name="toTime"
                        value={toTime}
                        onChange={updateDates}
                      />
                    </div>
                  </div>
                )}
                {hasShifts && (
                  <div className="col-sm-6 col-md-4">
                    <div className="form-group">
                      <label className="control-label" htmlFor="select-shift">{t('Time')}</label>
                      <select
                        value={shiftId}
                        id="select-shift"
                        onChange={(ev) => updateByShift(ev.target.value)}
                        className="form-control"
                      >
                        {shifts.map((item, i) => (
                          <option value={item.id} key={i}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
                <div className="col-md-3">
                  <div className="form-actions">
                    <button
                      onClick={(ev) => {
                        ev.preventDefault();
                        doSearch();
                        ev.target.blur();
                      }}
                      className="btn btn--default btn--sm"
                      type="button"
                    >
                      {isLoadingItems ? (
                        <LoadingSpinner />
                      ) : (
                        t('Find available spaces')
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          );
        }
      )
    )
  )
);
