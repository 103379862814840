import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import Link from 'next/link';
import { routes } from 'env/routes';
import { withRouter } from 'next/router';
import { withCustomComponent } from 'ui/components/withCustomComponent';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import Head from 'next/head';

@withCustomComponent('ResetPasswordPage')
@withRouter
@withTranslation()
@inject('authStore', 'appStore')
@observer
class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      isRedirecting: false,
      password: '',
    };
  }

  resetPassword = () => {
    const { router, t, authStore } = this.props;
    this.setState({ loginError: null });

    authStore
      .setPassword(this.state.email, router.query.token, this.state.password)
      .then((res) => {
        if (res.Status == 500) {
          this.setState({ loginError: t(res.Message) });
          return;
        }

        this.login();
      })
      .catch((err) => {
        this.setState({ loginError: t(err) });
      });
  };

  login() {
    const { authStore } = this.props;

    authStore
      .login(this.state.email, this.state.password, this.state.totp)
      .catch((err) => {
        if (err?.response?.body?.error == 'must_reset_password') {
          this.setState({
            changePassword: true,
            changePasswordToken: err?.response?.body?.error_description,
            password: null,
            loginMessage:
              t('You must change your password to continue') +
              '. ' +
              t(
                'Your password must be at least 6 characters long and include lowercase (a-z), uppercase (A-Z) and symbols (!@#$%^&*)'
              ),
          });
        } else if (err?.response?.body?.error == 'two_factor_auth_check') {
          this.setState({
            twoFactorRequired: true,
            twoFactorToken: err?.response?.body?.error_description,
            loginMessage: t('Enter your 2 factor authentication code.'),
          });
        } else if (err.response)
          this.setState({
            loginError: t(err.response.body.error_description),
          });
        else this.setState({ loginError: t(err) });
      })
      .then((data) => {
        if (data && data.refresh_token) return authStore.loadMe();
        else return data;
      })
      .then(this.afterLogin);
  }

  afterLogin = (me) => {
    const { appStore, authStore } = this.props;
    const { business } = appStore;
    const { t, router } = this.props;

    if (!me) return;
    if (!router.query.imp) authStore.setImpersonationToken(null);

    //We use window.location to force a browser reload
    //This ensures we clear all mobx states after a user logs in.
    const continueTo =
      router.query.ReturnUrl ||
      router.query.returnurl ||
      router.query.redirectUrl;

    this.setState({ isRedirecting: true });
    const isServerRedirect = router.query.server == '1';
    window.location = continueTo
      ? isServerRedirect
        ? `${business.NativeHomeUrlWithLanguage}/user/login?t=${
            me.AccessToken
          }&redirectUrl=${encodeURIComponent(continueTo)}`
        : decodeURIComponent(continueTo)
      : '/';

    return me;
  };

  parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  };

  componentDidMount() {
    const { router } = this.props;

    if (!router.query.token) {
      router.push('/');
      return;
    }

    const parsedToken = this.parseJwt(router.query.token);
    if (parsedToken.email) {
      this.setState({ email: parsedToken.email });
    }
  }

  render() {
    const { t, router, appStore, authStore } = this.props;
    const { business, configuration } = appStore;
    const { isLoggingIn, isLoadingMe } = authStore;

    if (!router.query.token) {
      return <LoadingLayout />;
    }

    return (
      <main
        componentName={this.props.componentName}
        className="content content--login login"
        role="main"
      >
        <Head>
          <title>
            {t('Sign in')} - {business?.Name}
          </title>
        </Head>

        <header className="site-header site-header--lo position-relative">
          <content className="site-header__content">
            <nav className="navbar container">
              <a className="navbar-brand" href="/">
                <img
                  src={`${business.NativeHomeUrlWithLanguage}/business/getlogo?h=96&mode=pad`}
                  alt={t(business.Name)}
                  height="48"
                />
                <div className="spacer d-none d-md-inline-block"></div>
                <span className="d-none d-md-inline">{t(business.Name)}</span>
              </a>
              <a href="/" className="btn btn-single-icon btn-white">
                <i className="icon-close-large fs-18"></i>
              </a>
            </nav>
          </content>
        </header>
        <content
          className="login__content"
          style={{
            backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/business/GetBannerFor?id=${business.Id}&w=1920&h=1080&mode=max)`,
          }}
        >
          <div className="card login__card">
            <header className="login__card__header">
              <h4>{t('Reset your password')}</h4>
            </header>
            <content className="login__card__content">
              {router.query.token && (
                <div className="alert alert-success fs-14 mt-16" role="alert">
                  <b>{t('You need to set a new password to continue.')}</b>
                </div>
              )}

              <form
                className="form"
                onSubmit={(ev) => {
                  ev.preventDefault();
                  this.resetPassword();
                }}
              >
                <div className="form-group">
                  <label htmlFor="password">{t('New Password')}</label>
                  <div className="input-group">
                    <input
                      type={this.state.showPassword ? 'text' : 'password'}
                      defaultValue={''}
                      value={this.state.password}
                      onChange={(ev) =>
                        this.setState({ password: ev.target.value })
                      }
                      className="form-control"
                      id="password"
                    />

                    <div className="input-group-append">
                      <i className="icon-lock"></i>
                    </div>
                  </div>

                  <div className="fs-14 mt-8 text-right">
                    <a
                      href="#"
                      onMouseDown={(ev) => {
                        ev.preventDefault();
                        this.setState({ showPassword: true });
                      }}
                      onTouchStart={(ev) => {
                        ev.preventDefault();
                        this.setState({ showPassword: true });
                      }}
                      onMouseUp={(ev) => {
                        ev.preventDefault();
                        this.setState({ showPassword: false });
                      }}
                      onTouchEnd={(ev) => {
                        ev.preventDefault();
                        this.setState({ showPassword: false });
                      }}
                    >
                      {t('Show password')}
                    </a>
                  </div>
                </div>

                <div className="credentials__register-access">
                  {this.state.loginError && (
                    <div
                      className="alert alert-danger fs-14 mt-16"
                      role="alert"
                    >
                      <b>{t(this.state.loginError)}</b>
                    </div>
                  )}

                  {this.state.loginMessage && (
                    <div className="alert alert-info fs-14 mt-16" role="alert">
                      <b>{t(this.state.loginMessage)}</b>
                    </div>
                  )}
                </div>

                <div className="form-actions">
                  <button className="btn btn-lg w-100 w-md-50" type="submit">
                    {this.state.isRedirecting || isLoggingIn || isLoadingMe ? (
                      <LoadingSpinner />
                    ) : (
                      t('Reset password')
                    )}
                  </button>
                </div>
              </form>
            </content>

            <footer className="login__card__footer">
              {configuration['Members.CanSignup'] && (
                <>
                  {t('New user?')}{' '}
                  <Link
                    href={{
                      pathname: routes.signup,
                      query: { returnurl: router.query.returnurl },
                    }}
                  >
                    <a>{t('Create account')}</a>
                  </Link>{' '}
                </>
              )}
              {configuration['PublicWebSite.Tour'] && (
                <>
                  {configuration['Members.CanSignup'] && <>{t('or')} </>}
                  <Link href={routes.tour}>
                    <a className="link--primary">{t('Request a tour')}</a>
                  </Link>
                </>
              )}
            </footer>
          </div>
        </content>

        <footer className="login__footer">
          <div className="container">
            <div>
              <img
                src={`${business.NativeHomeUrlWithLanguage}/business/getlogo?h=64&mode=pad`}
                alt={t(business.Name)}
                height="32"
              />
              <div className="mt-24 text-gray-600 ">
                {t('{{Name}}. Copyright © {{year}} All Rights Reserved.', {
                  Name: t(business.Name),
                  year: new Date().getFullYear(),
                })}
              </div>
            </div>
            <div className="mt-16 mt-lg-0">
              {business.TermsAndConditions && (
                <Link href={routes.legal_terms}>
                  <a className="text-gray-600 mr-12">
                    {t('Terms and conditions')}
                  </a>
                </Link>
              )}
              {configuration['Legal.Privacy'] && (
                <Link href={routes.legal_privacy}>
                  <a className="text-gray-600 mr-12">{t('Privacy policy')}</a>
                </Link>
              )}
              {configuration['Legal.Cookies'] && (
                <Link href={routes.legal_cookies}>
                  <a className="text-gray-600">{t('Cookies policy')}</a>
                </Link>
              )}
            </div>
          </div>
        </footer>
      </main>
    );
  }
}

export default ResetPasswordPage;
