import React, { useState, useEffect } from 'react';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { initYouTubeVideos, initVideoEvents } from './YouTubeLogic';

export const LessonPageBody = withCustomComponent('LessonPageBody')(
  inject(
    'appStore',
    'coursesStore'
  )(
    withRouter(
      observer(({ appStore, coursesStore, router }) => {
        const { t } = useTranslation();
        const [videosCount, setVideosCount] = useState(
          coursesStore.videos.length || -1
        );
        const [completedVideosCount, setCompletedVideosCount] = useState(0);
        const [nextLesson, setNextLesson] = useState({});
        const lesson = coursesStore.lessonPage.Lesson;
        const lessons = coursesStore.lessonsPage.Lessons;
        const course = coursesStore.coursePage.Course;
        const { COMPLETION_TYPES } = coursesStore;
        const [lessonCompleted, setLessonCompleted] = useState(
          lessons.find((l) => l.Id == lesson.Id).IsComplete
        );
        const [isCompletingLesson, setIsCompletingLesson] = useState(false);
        const [isLastLesson, setIsLastLesson] = useState(false);
        const [isLastLessonToComplete, setIsLastLessonToComplete] =
          useState(false);

        const completeLesson = async () => {
          try {
            setIsCompletingLesson(true);
            await coursesStore.completeLesson(course.Id, lesson.UniqueId);

            const lessonsPage = await coursesStore.loadLessonsPage(
              router.query.course_id
            );

            if (lesson.Id == lessonsPage.CurrentLesson?.Id) {
              appStore.setPopMessage(
                t('You completed all lessons available right now!')
              );
            }

            setNextLesson(lessonsPage.CurrentLesson);

            return lessonsPage.CurrentLesson;
          } catch {
            appStore.setPopMessage(
              t('Sorry, there was a problem completing the lesson')
            );
          } finally {
            setIsCompletingLesson(false);
          }
        };

        const onNextLessonClick = async () => {
          $('body, html, main').scrollTop(0);

          if (
            lesson.CompletionType == COMPLETION_TYPES.VIDEO &&
            !lessonCompleted
          ) {
            return;
          }

          if (lesson.Id == nextLesson?.Id) {
            appStore.setPopMessage(
              t('You completed all lessons available right now!')
            );
          }

          await coursesStore.loadLessonsPage(router.query.course_id);

          if (
            lessons.every((lesson) => lesson.IsComplete) &&
            (isLastLesson || isLastLessonToComplete)
          ) {
            coursesStore.showGraduationModal(true);
            return;
          }

          let nextLesson = lessonCompleted
            ? coursesStore.lessonsPage.CurrentLesson
            : await completeLesson();
          if (!nextLesson) {
            let currentLessonIndex = lessons.findIndex(
              (l) => l.Id == lesson.Id
            );
            nextLesson = lessons[currentLessonIndex + 1];
          }

          if (!nextLesson) {
            appStore.setPopMessage(
              t('You completed all lessons available right now!')
            );
            return;
          }

          router.push(
            `${routes.lesson_by_id(course, nextLesson.Id)}?course_id=${
              course.Id
            }&lesson_id=${nextLesson.Id}`,
            `${routes.lesson_by_id(course, nextLesson.Id)}`
          );
        };

        useEffect(() => {
          const incompleteLessons = lessons.filter((l) => !l.IsComplete);
          if (
            incompleteLessons.length == 1 &&
            incompleteLessons[0].Id == lesson.Id
          ) {
            setIsLastLessonToComplete(true);
          }

          if (lessons[lessons.length - 1].Id === lesson.Id) {
            setIsLastLesson(true);
          }

          const videos = initYouTubeVideos();
          coursesStore.setVideos(videos);
          setVideosCount(videos.length);

          if (!lessonCompleted) {
            window.onYouTubeIframeAPIReady = () => {
              initVideoEvents(
                videos,
                coursesStore.coursePage.Course.Id,
                setCompletedVideosCount,
                completedVideosCount,
                router
              );
            };

            if (window.YT && $('#yt-iframe-api').length > 0) {
              initVideoEvents(
                videos,
                coursesStore.coursePage.Course.Id,
                setCompletedVideosCount,
                completedVideosCount,
                router
              );
            }
          }

          return () => {
            window.onYouTubeIframeAPIReady = null;
          };
        }, []);

        useEffect(() => {
          if (
            lesson.CompletionType == COMPLETION_TYPES.VISITED &&
            !lessonCompleted
          ) {
            setLessonCompleted(true);
          }

          if (
            lesson.CompletionType == COMPLETION_TYPES.VIDEO &&
            !lessonCompleted
          ) {
            if (completedVideosCount === videosCount) {
              completeLesson();
              setLessonCompleted(true);
            }
          }
        }, [lessonCompleted, completedVideosCount]);

        return (
          <div className="article-content fr-view">
            <div className="lesson-details__contents">
              {lesson.SummaryText && (
                <div className="mb-24">{lesson.SummaryText}</div>
              )}

              {lesson.LessonContents && (
                <>
                  <hr />
                  <div
                    className="fr-view"
                    dangerouslySetInnerHTML={{
                      __html: lesson.LessonContents,
                    }}
                  ></div>
                </>
              )}
            </div>

            <div className="row lesson-details__actions align-items-center">
              <div className="col-md-auto text-center not-completed-message text-gray-700">
                {lesson.CompletionType == COMPLETION_TYPES.VIDEO &&
                  !lessonCompleted &&
                  t(
                    'You must watch 90% of the video(s) to complete this lesson.'
                  )}
              </div>
              <div className="col-xl-auto">
                <div
                  className={`btn btn--primary btn--sm btn-block
                                ${
                                  lesson.CompletionType ==
                                    COMPLETION_TYPES.VIDEO && !lessonCompleted
                                    ? 'disabled'
                                    : ''
                                }`}
                  onClick={onNextLessonClick}
                >
                  {isCompletingLesson && <LoadingSpinner />}
                  {!isCompletingLesson && (
                    <>
                      {lessons.every((lesson) => lesson.IsComplete) &&
                      (isLastLesson || isLastLessonToComplete)
                        ? t('Complete course')
                        : t('Next lesson')}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })
    )
  )
);
