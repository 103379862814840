import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Breadcrumbs from 'ui/components/Breadcrumbs';
import { CoursesPageHeader } from 'ui/_pages/courses/CoursesPageHeader';
import { withRouter } from 'next/router';
import { CoursesPageFilters } from 'ui/_pages/courses/CoursesPageFilters';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { CoursesPageList } from 'ui/_pages/courses/CoursesPageList';
import { CoursesPageZeroCase } from 'ui/_pages/courses/CoursesPageZeroCase';
import { Pagination } from 'ui/components/listings/Pagination';
import { NoAccessNotice } from 'ui/components/NoAccessNotice';

@withCustomComponent('MyCourses')
@withTranslation()
@withRouter
@inject('appStore', 'authStore', 'coursesStore')
@observer
class MyCourses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loaded: false,
    };
  }

  async componentDidMount() {
    const { t, router, coursesStore } = this.props;
    await coursesStore.loadMyCourses({ ...router.query }).catch((err) => {
      this.setState({
        error: t("You currently don't have access to this section."),
      });
    });

    this.setState({ loaded: true });
  }

  componentDidUpdate(prevProps, prevState) {
    const { t, router, coursesStore } = this.props;
    const prevQuery = prevProps.router.query;
    const currentQuery = router.query;
    if (JSON.stringify(currentQuery) != JSON.stringify(prevQuery)) {
      coursesStore.loadMyCourses({ ...router.query }).catch((err) => {
        this.setState({
          error: t("You currently don't have access to this section."),
        });
      });
    }
  }

  render() {
    const { t, appStore, coursesStore } = this.props;
    const { business } = appStore;
    const { myCourses, hasLoadedMyCourses } = coursesStore;
    const hasError = this.state.error != null;

    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={t('Courses - {{Name}}', { Name: t(business.Name) })}
      >
        <main className="content content--custom" role="main">
          <div className="container">
            <CoursesPageHeader />
            <CoursesPageFilters />
            {!hasLoadedMyCourses && !this.state.loaded && <LoadingSpinner />}
            {hasLoadedMyCourses && this.state.loaded && (
              <>
                <NoAccessNotice error={this.state.error} />
                <CoursesPageList courses={myCourses} isMyCourses={true} />
                {!hasError && (
                  <CoursesPageZeroCase
                    courses={myCourses}
                    message={t("You haven't enrolled in any courses yet")}
                  />
                )}
                <Pagination page={1} records={myCourses} top={12} />
              </>
            )}
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default MyCourses;
