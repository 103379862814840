import {removeNewLines} from 'env/utils/strings';
import {isServer} from 'env/ssr/ServerSideRenderManager';
import {adjustBrightness, isDarkColor} from 'env/utils/cssHelpers';

const hex2rgba = (hex) => {
  let c = hex.substring(1).split('');

  if (!/^#(([\dA-Fa-f]{3}){1,2}|([\dA-Fa-f]{4}){1,2})$/.test(hex)) {
    return `63, 150, 103`;
  }

  switch (c.length) {
    case 3:
      c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], 'ff'];
      break;
    case 4:
      c = [c[0] + c[0], c[1] + c[1], c[2] + c[2], c[3] + c[3]];
      break;
    case 6:
      c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], 'ff'];
      break;
    case 8:
      c = [c[0] + c[1], c[2] + c[3], c[4] + c[5], c[6] + c[7]];
      break;
  }

  c = c.map((char) => parseInt(char, 16).toString());
  c[3] = (Math.round((parseInt(c[3], 10) / 255) * 100) / 100).toString();
  return c[3] === '1'
    ? `${c[0]}, ${c[1]}, ${c[2]}`
    : `${c[0]}, ${c[1]}, ${c[2]}`;
}

const hex2hsl = (hex, valuesOnly = false, hslValue) => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  let r = parseInt(result[1], 16);
  let g = parseInt(result[2], 16);
  let b = parseInt(result[3], 16);
  let cssString = '';
  r /= 255, g /= 255, b /= 255;
  let max = Math.max(r, g, b), min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;
  if (max == min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  switch (hslValue) {
    case 'H':
      cssString = h;
      break;
    case 'S':
      cssString = s + '%';
      break;
    case 'L':
      cssString = l + '%';
      break;
    default:
      cssString = h + ',' + s + '%,' + l + '%';
      cssString = !valuesOnly ? 'hsl(' + cssString + ')' : cssString;
      break;
  }

  return cssString;
}


export const whiteLabelCss = ({configuration, primaryColor, appStore}) => {

  let hoverColor = '#B40028';

  //Default values (for lighter color)
  let textColor = '#23272B';
  let linkColor = '#000000';
  let defaultColor = '#3f9667';

  //Checking if the color is dark enough
  if (isDarkColor(primaryColor)) {
    textColor = '#FFFFFF'; //white
    linkColor = primaryColor;
  }
//Change color automatic
//   primaryColor = '#3f9667';


  let scssPrimaryColor = primaryColor ? hex2rgba(primaryColor) : hex2rgba(defaultColor);
  let scssPrimaryLightenDarkenColor = primaryColor ? hex2hsl(primaryColor) : hex2hsl(defaultColor);
  let scssPrimaryLightenDarkenColorH = primaryColor ? hex2hsl(primaryColor, false, 'H') : hex2hsl(defaultColor, false, 'H');
  let scssPrimaryLightenDarkenColorS = primaryColor ? hex2hsl(primaryColor, false, 'S') : hex2hsl(defaultColor, false, 'S');
  let scssPrimaryLightenDarkenColorL = primaryColor ? hex2hsl(primaryColor, false, 'L') : hex2hsl(defaultColor, false, 'L');

  let borderRadius = '0.5rem';
  if (appStore.corporateDashboard) {
    borderRadius = '0';
  }
  // --bg-scss-color: ${hex2hsl(primaryColor)};

  return removeNewLines(`
    :root {
      --bg-color: ${primaryColor};
      --bg-scss-color: ${scssPrimaryColor};
      --bg-scss-darken-lighten-color: ${scssPrimaryLightenDarkenColor};
      --bg-scss-darken-lighten-color-h: ${scssPrimaryLightenDarkenColorH};
      --bg-scss-darken-lighten-color-s: ${scssPrimaryLightenDarkenColorS};
      --bg-scss-darken-lighten-color-l: ${scssPrimaryLightenDarkenColorL};
      --hover-color: ${hoverColor};
      --text-color: ${textColor};
      --link-color: ${linkColor};
      --border-radius: ${borderRadius};
    }
    ${configuration['Website.CustomCSS']}
    ${!isServer() && '#__next { visibility: visible !important; }'}`);
};
