const shouldShowField = (configuration, fieldName, customer) => {
  if (fieldName == 'ProfileWebsite') fieldName = 'ProfileWebiste'; //account for typo in the settings

  return customer.Id > 0
  
    ? configuration[`Forms.${fieldName}`] &&
      configuration[`Forms.${fieldName}`] !== '3'

    : configuration[`Forms.${fieldName}`] !== '3' &&
      configuration[`SignupForm.${fieldName}`] !== '3';
};

export { shouldShowField };
