import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'next/router';
import { routes } from './routes';
import LoadingLayout from 'ui/layouts/LoadingLayout';

export const privateRoute = (WrappedComponent) => {
  @withRouter
  @inject('authStore')
  @observer
  class PrivateRoute extends Component {
    async componentDidMount() {
      const { router, authStore } = this.props;
      const user = await authStore.loadMe();
      if (user == null)
        router.push(`${routes.login}?returnurl=${router.asPath}`);
    }

    render() {
      const { authStore } = this.props;
      if (!authStore.me) return <LoadingLayout />;
      return <WrappedComponent>{this.props.children}</WrappedComponent>;
    }
  }

  return PrivateRoute;
};
