import React, { useState } from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation, Trans } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import asForm from 'ui/components/forms/asForm';
import NxMentionsInput from 'ui/components/forms/NxMentionsInput/NxMentionsInput';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import withCustomComponent from 'ui/components/withCustomComponent';
import { CoworkerAvatar } from 'ui/components/CoworkerAvatar';

export const CommunityBoardItemComment = withCustomComponent(
  'CommunityBoardItemComment'
)(
  asForm(
    inject(
      'appStore',
      'authStore',
      'communityStore'
    )(
      withRouter(
        observer(
          ({ thread, appStore, authStore, communityStore, componentName }) => {
            const [reply, setReply] = useState({});
            const [isLoading, setIsLoading] = useState(false);

            const { t } = useTranslation();
            const { customer, isLoggedIn } = authStore;
            const { isSavingNewCommunityBoardReply } = communityStore;
            const { isSignalRConnected } = appStore;

            // BB-TODO : The logic behind displaying and saving comment value needs to be completed

            const saveNewMessage = () => {
              if (!isSignalRConnected) return;
              setIsLoading(true);
              communityStore.setNewCommunityBoardReply(reply);
              communityStore
                .saveNewCommunityBoardReply(thread.Id)
                .then(() => {
                  setReply({});
                })
                .catch((err) =>
                  appStore.setPopMessage(
                    t('Sorry, your message could not be sent. ' + err)
                  )
                )
                .finally(() => {
                  setIsLoading(false);
                });
            };

            return (
              <div
                className="community-board-item-comment"
                data-component-name={componentName}
              >
                {!isLoggedIn ? (
                  <Link
                    href={{
                      pathname: routes.login,
                      query: {
                        returnurl: encodeURIComponent(
                          routes.thread_by_id(thread)
                        ),
                      },
                    }}
                  >
                    <a className="btn btn-xs btn-outline">
                      {t('Sign in to join this conversation')}
                    </a>
                  </Link>
                ) : (
                  <form noValidate className="d-flex">
                    <div className="pr-8">
                      <CoworkerAvatar
                        size={80}
                        link
                        imgClassName={'avatar avatar--40'}
                        coworker_id={customer.Id}
                      />
                    </div>
                    <div className="w-100">
                      <div className="d-flex justify-content-between border rounded p-4">
                        <div className="nx-mentions-input-wrapper w-100">
                          <NxMentionsInput
                            label={null}
                            name="Message"
                            placeholder={t('Write a comment ...')}
                            errors={
                              communityStore.newCommunityBoardReplyValidation
                                .errors.Message
                            }
                            value={reply.Message}
                            onChange={(id, value) => {
                              setReply({ [id]: value });
                            }}
                            onKeyDown={(ev) => {
                              if (
                                (ev.keyCode == 10 || ev.keyCode == 13) &&
                                (ev.ctrlKey || ev.metaKey)
                              ) {
                                if (isSavingNewCommunityBoardReply) return;
                                ev.preventDefault();
                                saveNewMessage();
                              }
                            }}
                          />
                        </div>
                        <button
                          style={{ alignSelf: 'flex-end' }}
                          className="btn "
                          disabled={
                            !isSignalRConnected ||
                            isSavingNewCommunityBoardReply
                          }
                          onClick={(ev) => {
                            if (!isSignalRConnected) return;
                            if (isSavingNewCommunityBoardReply) return;
                            ev.preventDefault();
                            saveNewMessage();
                          }}
                        >
                          {isLoading ? (
                            <LoadingSpinner />
                          ) : !isSignalRConnected ? (
                            t('Connecting...')
                          ) : (
                            t('Comment')
                          )}
                        </button>
                      </div>
                      <div className="text-gray-600 fs-12 lh-12 pt-4">
                        <small
                          className="d-block text-red"
                          data-bind="validationMessage: message"
                        ></small>
                        <p className="mb-0">
                          {t(
                            "Invite other people to this conversation by mentioning them: type the '@' symbol followed by their name."
                          )}
                        </p>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            );
          }
        )
      )
    ),
    'newCommunityBoardReply'
  )
);
