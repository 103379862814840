/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import {inject, observer} from 'mobx-react';
import {LocalizedPrice} from 'env/utils/NumbersLocalization';
import withCustomComponent from 'ui/components/withCustomComponent';
import {bookingsFeatures} from 'env/bookingsResources';
import {ResourceAvailableSlots} from 'ui/_pages/bookings/resources/ResourceAvailableSlots';
import BookingDateTimeForm from 'ui/_pages/bookings/details/BookingDateTimeForm';
import BookingServicesForm from './BookingServicesForm';
import Accordion from 'ui/components/Accordion';

@withCustomComponent('BookingResourceInfo')
@withTranslation()
@inject('appStore', 'bookingsStore')
@observer
class BookingResourceInfo extends Component {
  componentDidMount() {
    const {bookingsStore} = this.props;

    if (!bookingsStore.hasLoadedResources) bookingsStore.loadResources();

    this.initToolTips();
  }

  initToolTips = () => {
    const $ = window.$;

    if ($('[data-toggle="available-tooltip"]').length > 0) {
      $('[data-toggle="available-tooltip"]').tooltip({
        html: true,
      });
    }
  };

  updateBooking = this.props.updateBooking;

  getCurrentBooking = () => {
    const {bookingsStore} = this.props;
    const {booking} = bookingsStore;
    return booking;
  };

  getResource() {
    const {bookingsStore} = this.props;
    const {booking, networkResources} = bookingsStore;
    return (
      booking &&
      _(networkResources)
        .filter((r) => r.Id === booking.ResourceId)
        .first()
    );
  }

  loadBookingPrice = () => {
    const {bookingsStore} = this.props;
    bookingsStore.loadBookingPrice();
  };

  selectDates = (selectingDate, date) => {
    const booking = this.getCurrentBooking();
    let dates = {};
    dates[selectingDate] = moment(date).toDate();

    if (selectingDate == 'fromTime') {
      if (moment(date) >= moment(booking.ToTime)) {
        // the date will always be at most midnight of the next day or earlier
        const smallestDate = moment.min(
          moment(date).endOf('day'),
          moment(date).add(60, 'minutes')
        );
        dates.toTime = smallestDate.toDate();
      }
    }

    if (selectingDate == 'toTime') {
      if (moment(date) <= moment(booking.FromTime)) {
        // the date will always be at least midnight of the same day or later
        const greatestDate = moment.max(
          moment(date).startOf('day'),
          moment(date).add(-60, 'minutes')
        );
        dates.fromTime = greatestDate.toDate();
      }
    }

    if (dates.fromTime) this.updateBooking('FromTime', dates.fromTime);
    if (dates.toTime) this.updateBooking('ToTime', dates.toTime);
  };

  render() {
    const {
      t,
      componentName,
      isSidePopup,
      showResourceAvailableSlots,
    } = this.props;
    const {bookingsStore, appStore} = this.props;
    const {booking, resources} = bookingsStore;
    const {business, configuration} = appStore;

    const resource = this.getResource();

    const bookingsFeaturesObjectArray =
      resource != undefined
        ? bookingsFeatures(t)
          .map((feature, i) => {
            if (resource[feature.key] == true) return feature;
          })
          .filter((f) => f != undefined)
        : null;

    if (!resource || resource == undefined)
      return (
        <small className="fs-12 text-gray-600">
          {t('Please select resource to continue booking.')}
        </small>
      );
    if (!booking.ResourceId) return <></>;

    // will show only if time is between today at midnight and tomorrow at midnight
    const shouldShowSlots = moment(booking.ToTime).isSameOrBefore(
      moment(booking.FromTime).startOf('day').add(1, 'days')
    );

    return (
      <div
        data-component-name={componentName}
        className={`booking-resource-info ${isSidePopup ? 'mb-24' : ''}`}
      >
        {/* {booking.ResourceName && booking.DeskName && (
          <h4 className="booking-resource-info--title mt-10">
            {booking.DeskName}
          </h4>
        )} */}

        <img
          src={`${business.NativeHomeUrlWithLanguage}/publicresources/getimage/${booking.ResourceId}?h=480&w=700}`}
          alt={booking.ResourceName}
          className="img-fluid rounded d-block mt-12 ml-auto mr-auto"
        />

        {resource && (
          <div>
            {showResourceAvailableSlots !== false && shouldShowSlots && (
              <ResourceAvailableSlots
                id="booking-modal-as"
                selectDates={this.selectDates}
                fromTime={booking.FromTime}
                toTime={booking.ToTime}
                resource={resource}
                isSidePopup={isSidePopup}
              />
            )}

            <ul className="list-tags mt-8 mb-24">
              {resource.Allocation && (
                <li
                  className="with-icon-left"
                  data-toggle="available-tooltip"
                  title={
                    resource.AllowMultipleBookings
                      ? t('Availability {{count}} unit', {
                        count: resource.Allocation,
                      })
                      : t('Seating {{count}} person', {
                        count: resource.Allocation,
                      })
                  }
                >
                  <i
                    className={`icon-${
                      resource.AllowMultipleBookings ? 'desks' : 'user'
                    }`}
                  ></i>
                  {'x'}
                  {resource.Allocation}
                </li>
              )}
              {resource.Price > 0 && (
                <li
                  className="with-icon-left"
                  data-toggle="available-tooltip"
                  title={t('Price')}
                >
                  <i className="icon-money"></i>
                  <LocalizedPrice
                    currency={business.Currency.Code}
                    amount={resource.Price}
                  />
                </li>
              )}
              {resource.ResourceTypeName && (
                <li>{resource.ResourceTypeName}</li>
              )}
              {resource.GroupName && <li>{resource.GroupName}</li>}
            </ul>

            {isSidePopup && (
              <BookingDateTimeForm
                updateBooking={(prop, value) => this.updateBooking(prop, value)}
                withBorder
                isSidePopup
              />
            )}
          </div>
        )}

        {isSidePopup && (
          <BookingServicesForm
            loadBookingPrice={() => this.loadBookingPrice()}
            isSidePopup
          />
        )}

        {isSidePopup ? (
          <>
            <Accordion
              id="description-section"
              heading={t('Description')}
              showDividerTop={false}
              key="accordion-description-section"
            >
              <div
                className="booking-resource-info--description mb-20"
                dangerouslySetInnerHTML={{__html: resource.Description}}
              ></div>
            </Accordion>

            <Accordion
              id="features-section"
              heading={t('Features')}
              showDividerTop={false}
              key="accordion-features-section"
            >
              {bookingsFeaturesObjectArray.length > 0 && (
                <>
                  <div className="">
                    <ul className="list-grid list-grid--33 list-grid--sm-50 mb-20">
                      {bookingsFeaturesObjectArray.map((feature, i) => (
                        <li key={i}>
                          <i className={`${feature.icon} fs-40`}></i>
                          <div className="mt-8">{feature.name}</div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </Accordion>
          </>
        ) : (
          <>
            <div
              className="booking-resource-info--description mt-20"
              dangerouslySetInnerHTML={{__html: resource.Description}}
            ></div>

            <hr className="mt-32 mb-24"/>

            {bookingsFeaturesObjectArray.length > 0 && (
              <>
                <div className="">
                  <h5 className="mb-20 modal-booking-details-title">{t('Features')}</h5>
                  <ul className="list-grid list-grid--33 list-grid--sm-50 booking-resource-info-list">
                    {bookingsFeaturesObjectArray.map((feature, i) => (
                      <li key={i}>
                        <i className={`${feature.icon}`}></i>
                        <div className="">{feature.name}</div>
                      </li>
                    ))}
                  </ul>
                </div>
                <hr className="mt-32 mb-24"/>
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

export default BookingResourceInfo;
