import React, { Component } from 'react';

export default class SortableTableHeader extends Component {
  onClick = () => {
    const { sortField, currentSortField, currentSortDirection } = this.props;

    this.props.onClick({
      sortDirection:
        currentSortField === sortField
          ? currentSortDirection === 'asc'
            ? 'desc'
            : 'asc'
          : currentSortDirection,
      sortField: sortField,
    });
  };
  render() {
    const {
      label,
      sortField,
      currentSortField,
      currentSortDirection,
      className,
    } = this.props;

    return (
      <th
        onClick={(ev) => {
          ev.preventDefault();
          this.onClick();
        }}
        className={`${className} ${currentSortField === sortField ? 'active' : ''
          }`}
      >
        {label}{' '}
        <i
          className={`${currentSortDirection === 'asc'
              ? 'fa fa-caret-up'
              : 'fa fa-caret-down'
            }`}
        ></i>
      </th>
    );
  }
}
