import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import CardDashboardSmall from 'ui/components/cards/CardDashboardSmall';
import withCustomComponent from 'ui/components/withCustomComponent';
import { routes } from 'env/routes';

@withCustomComponent('EventsWidgetSmall')
@withTranslation()
@inject('appStore', 'eventsStore')
@observer
class EventsWidgetSmall extends Component {
  componentDidMount() {
    const { eventsStore } = this.props;
    eventsStore.loadMyEvents().catch(() => null);
  }

  render() {
		const { t, appStore, eventsStore, oneLiner } = this.props;
    const { myEvents } = eventsStore;

    const totalTickets = myEvents.UpcomingEvents.length;

    const svg = (primaryColor) => (
			<svg width="98px" height="79px" version="1.1" id="EventsWidgetSvg" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 97.8 78.2" enableBackground="new 0 0 97.8 78.2">
				<g>
					<circle fill="#F5F5F5" cx="55.9" cy="39.7" r="37.2" />
					<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M31.1,41.7c2.7,2.7,2.7,7.1,0,9.8
		c-2.7,2.7-7.1,2.7-9.8,0c-2.7-2.7-2.7-7.1,0-9.8C24,39,28.4,39,31.1,41.7"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M44.7,24.3H32.3v-6.2c0-1.7-1.4-3.1-3.1-3.1h-6.2
		c-1.7,0-3.1,1.4-3.1,3.1v6.2H7.7c-3.4,0-6.2,2.8-6.2,6.2v40.1c0,3.4,2.8,6.2,6.2,6.2h37c3.4,0,6.2-2.8,6.2-6.2V30.4
		C50.8,27,48.1,24.3,44.7,24.3z"/>
					<path fill={primaryColor} d="M26.2,55c-2.2,0-4.3-0.8-6-2.5c-1.6-1.6-2.5-3.7-2.5-6s0.9-4.4,2.5-6c3.3-3.3,8.6-3.3,11.9,0l0,0
		c3.3,3.3,3.3,8.6,0,11.9C30.5,54.2,28.3,55,26.2,55z M26.2,41.2c-1.4,0-2.8,0.5-3.8,1.6c-1,1-1.6,2.4-1.6,3.8s0.6,2.8,1.6,3.8
		c2.1,2.1,5.6,2.1,7.7,0c1-1,1.6-2.4,1.6-3.8S31,43.8,30,42.8C29,41.7,27.6,41.2,26.2,41.2z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M37.3,67.4c-0.5-1.4-1.4-2.6-2.4-3.6l0,0
		c-1.8-1.7-4.2-2.6-6.7-2.6h-4c-2.5,0-4.8,0.9-6.6,2.6l0,0c-1.1,1-1.9,2.2-2.4,3.6"/>
					<path fill={primaryColor} d="M15.1,68.9c-0.2,0-0.4,0-0.6-0.1c-0.8-0.3-1.1-1.2-0.8-1.9c0.6-1.6,1.6-3,2.8-4.1c2.1-1.9,4.8-3,7.7-3h4
		c2.9,0,5.6,1.1,7.7,3c1.2,1.1,2.2,2.5,2.8,4.1c0.3,0.8-0.1,1.6-0.8,1.9c-0.8,0.3-1.6-0.1-1.9-0.8c-0.5-1.1-1.2-2.2-2.1-3
		c-1.5-1.4-3.5-2.2-5.6-2.2h-4c-2.1,0-4.1,0.8-5.6,2.2c-0.9,0.8-1.6,1.9-2.1,3C16.2,68.6,15.7,68.9,15.1,68.9z"/>
					<path fill={primaryColor} d="M44.7,22.8H33.8v-4.7c0-2.5-2.1-4.6-4.6-4.6h-6.2c-2.5,0-4.6,2.1-4.6,4.6v4.7H7.7c-4.2,0-7.7,3.4-7.7,7.7
		v40.1c0,4.2,3.4,7.7,7.7,7.7h37c4.2,0,7.7-3.4,7.7-7.7V30.4C52.3,26.2,48.9,22.8,44.7,22.8z M21.5,18.1c0-0.9,0.7-1.6,1.6-1.6h6.2
		c0.9,0,1.6,0.7,1.6,1.6v9.3c0,0.9-0.7,1.6-1.6,1.6h-6.2c-0.9,0-1.6-0.7-1.6-1.6V18.1z M49.3,70.5c0,2.6-2.1,4.7-4.7,4.7h-37
		c-2.6,0-4.7-2.1-4.7-4.7V30.4c0-2.6,2.1-4.7,4.7-4.7h10.8v1.6c0,2.5,2.1,4.6,4.6,4.6h6.2c2.5,0,4.6-2.1,4.6-4.6v-1.6h10.8
		c2.6,0,4.7,2.1,4.7,4.7V70.5z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M75.3,20.7l-5.4,3.6v-5.4h-1.8c-2,0-3.6-1.6-3.6-3.6
		V4.6c0-2,1.6-3.6,3.6-3.6h16.1c2,0,3.6,1.6,3.6,3.6v3.6"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M93.2,8.2h-5.4V4.6c0-2-1.6-3.6-3.6-3.6H68.1
		c-2,0-3.6,1.6-3.6,3.6v10.8c0,2,1.6,3.6,3.6,3.6h1.8v5.4l5.4-3.6v1.8c0,2,1.6,3.6,3.6,3.6h5.4l7.2,5.4v-5.4h1.8
		c2,0,3.6-1.6,3.6-3.6V11.8C96.8,9.8,95.2,8.2,93.2,8.2z"/>
					<path fill={primaryColor} d="M93.2,7.2h-4.4V4.6c0-2.5-2.1-4.6-4.6-4.6H68.1c-2.5,0-4.6,2.1-4.6,4.6v10.8c0,2.5,2.1,4.6,4.6,4.6h0.8v4.4
		c0,0.4,0.2,0.7,0.5,0.9c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.4-0.1,0.6-0.2l3.8-2.6c0,2.5,2.1,4.5,4.6,4.5h5l6.9,5.2
		c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.3,0,0.4-0.1c0.3-0.2,0.6-0.5,0.6-0.9v-4.4h0.8c2.5,0,4.6-2.1,4.6-4.6V11.8
		C97.8,9.2,95.7,7.2,93.2,7.2z M69.9,17.9h-1.8c-1.4,0-2.6-1.2-2.6-2.6V4.6c0-1.4,1.2-2.6,2.6-2.6h16.1c1.4,0,2.6,1.2,2.6,2.6v2.6
		h-8c-2.5,0-4.6,2.1-4.6,4.6v8.4l-3.4,2.2v-3.5C70.9,18.4,70.5,17.9,69.9,17.9z M95.8,22.5c0,1.4-1.2,2.6-2.6,2.6h-1.8
		c-0.6,0-1,0.4-1,1v3.4l-5.6-4.2c-0.2-0.1-0.4-0.2-0.6-0.2h-5.4c-1.4,0-2.6-1.2-2.6-2.6V11.8c0-1.4,1.2-2.6,2.6-2.6h14.3
		c1.4,0,2.6,1.2,2.6,2.6V22.5z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M81.4,17.1c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0
		c0,0,0-0.1,0-0.1C81.3,17,81.4,17,81.4,17.1"/>
					<path fill={primaryColor} d="M81.4,18.2c-0.3,0-0.6-0.1-0.8-0.3c-0.4-0.4-0.4-1.1,0-1.5c0.4-0.4,1.1-0.4,1.5,0c0,0,0,0,0,0
		c0.4,0.4,0.4,1.1,0,1.5C81.9,18.1,81.7,18.2,81.4,18.2z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M86.1,17.1c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0
		c0,0,0-0.1,0-0.1C86,17,86.1,17,86.1,17.1"/>
					<path fill={primaryColor} d="M86,18.2c-0.3,0-0.6-0.1-0.8-0.3c-0.4-0.4-0.4-1.1,0-1.5c0.4-0.4,1.1-0.4,1.5,0c0,0,0,0,0,0
		c0.4,0.4,0.4,1.1,0,1.5C86.6,18.1,86.3,18.2,86,18.2z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M90.8,17.1c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0
		c0,0,0-0.1,0-0.1C90.7,17,90.7,17,90.8,17.1"/>
					<path fill={primaryColor} d="M90.7,18.2C90.7,18.2,90.7,18.2,90.7,18.2c-0.3,0-0.6-0.1-0.8-0.3c-0.4-0.4-0.4-1.1,0-1.5
		c0.4-0.4,1.1-0.4,1.5,0c0,0,0,0,0,0c0.4,0.4,0.4,1.1,0,1.5C91.3,18.1,91,18.2,90.7,18.2z"/>
					<g>
						<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M75.9,66.7c0,5.1,4.1,9.2,9.2,9.2s9.2-4.1,9.2-9.2
			s-4.1-9.2-9.2-9.2S75.9,61.6,75.9,66.7z"/>
						<path fill={primaryColor} d="M85,76.8c-5.6,0-10.2-4.6-10.2-10.2S79.4,56.5,85,56.5s10.2,4.6,10.2,10.2S90.6,76.8,85,76.8z M85,58.5
			c-4.5,0-8.2,3.7-8.2,8.2s3.7,8.2,8.2,8.2c4.5,0,8.2-3.7,8.2-8.2S89.5,58.5,85,58.5z"/>
					</g>
					<g>
						<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M64.5,54.2c0,5.1,4.1,9.2,9.2,9.2s9.2-4.1,9.2-9.2
			s-4.1-9.2-9.2-9.2S64.5,49.2,64.5,54.2z"/>
						<path fill={primaryColor} d="M73.7,64.4c-5.6,0-10.2-4.6-10.2-10.2s4.6-10.2,10.2-10.2c5.6,0,10.2,4.6,10.2,10.2S79.3,64.4,73.7,64.4z
			 M73.7,46.1c-4.5,0-8.2,3.7-8.2,8.2s3.7,8.2,8.2,8.2s8.2-3.7,8.2-8.2S78.2,46.1,73.7,46.1z"/>
					</g>
					<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M82.1,64.2V65V64.2z" />
					<path fill={primaryColor} d="M82.1,66c-0.6,0-1-0.4-1-1v-0.8c0-0.6,0.4-1,1-1s1,0.4,1,1V65C83.1,65.6,82.7,66,82.1,66z" />
					<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M70.8,51.8v0.8V51.8z" />
					<path fill={primaryColor} d="M70.8,53.6c-0.6,0-1-0.4-1-1v-0.8c0-0.6,0.4-1,1-1s1,0.4,1,1v0.8C71.8,53.1,71.4,53.6,70.8,53.6z" />
					<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M87.9,64.2V65V64.2z" />
					<path fill={primaryColor} d="M87.9,66c-0.6,0-1-0.4-1-1v-0.8c0-0.6,0.4-1,1-1s1,0.4,1,1V65C88.9,65.6,88.5,66,87.9,66z" />
					<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M76.6,51.8v0.8V51.8z" />
					<path fill={primaryColor} d="M76.6,53.6c-0.6,0-1-0.4-1-1v-0.8c0-0.6,0.4-1,1-1s1,0.4,1,1v0.8C77.6,53.1,77.1,53.6,76.6,53.6z" />
					<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M87,69.5c-0.5,0.2-1.2,0.5-2,0.5
		c-0.9,0-1.6-0.3-2.1-0.5"/>
					<path fill={primaryColor} d="M85,71c-0.9,0-1.7-0.2-2.5-0.6c-0.5-0.3-0.7-0.9-0.4-1.4c0.3-0.5,0.9-0.7,1.4-0.4c1,0.5,2.1,0.5,3.1,0
		c0.5-0.3,1.1-0.1,1.3,0.4c0.2,0.5,0.1,1.1-0.4,1.3C86.7,70.8,85.9,71,85,71z"/>
					<path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M71.6,57.5c0.5-0.2,1.2-0.5,2-0.5
		c0.9,0,1.6,0.3,2.1,0.5"/>
					<path fill={primaryColor} d="M75.7,58.5c-0.2,0-0.3,0-0.5-0.1c-1-0.5-2.1-0.5-3.1,0c-0.5,0.3-1.1,0-1.3-0.4s0-1.1,0.4-1.3
		c1.6-0.8,3.4-0.8,5,0c0.5,0.3,0.7,0.9,0.4,1.4C76.4,58.3,76,58.5,75.7,58.5z"/>
				</g>
			</svg>
    );

    return (
      <CardDashboardSmall
        title={t('Your tickets')}
        url={routes.my_events}
        mainValue={totalTickets}
        zeroMessage={t('No event tickets yet!')}
				svg={svg('var(--bg-color)')}
				oneLiner={oneLiner}
      />
    );
  }
}
export default EventsWidgetSmall;
