import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import { BasketItemFactory } from 'ui/_pages/checkout/basketItemFactory';
import { DiscountInfoSection } from 'ui/_pages/checkout/DiscountInfoSection';
import { CheckoutTotalPrice } from 'ui/_pages/checkout/CheckoutTotalPrice';
import { CheckoutButton } from 'ui/_pages/checkout/CheckoutButton';
import { routes } from 'env/routes';
import ProposalPageLayout from 'ui/layouts/ProposalPageLayout';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import { withRouter } from 'next/router';

@withCustomComponent('ProposalSummaryPage')
@withTranslation()
@inject('appStore', 'authStore', 'checkoutStore')
@withRouter
@observer
class ProposalSummaryPage extends Component {
  componentDidMount() {
    const { t, router, appStore, checkoutStore } = this.props;
    checkoutStore
      .loadInvoicePreview({
        addNewContracts: true,
        proposalId: router.query.proposal_id,
      })
      .catch((err) => {
        appStore.setPopMessage(
          t('Sorry, we could not load this page. {{err}}', { err })
        );
      });
  }

  render() {
    const { t, checkoutStore } = this.props;
    const {
      invoicePreview,
      hasLoadedInvoicePreview,
      isLoadingInvoicePreview,
    } = checkoutStore;

    if (isLoadingInvoicePreview || !hasLoadedInvoicePreview)
      return <LoadingLayout />;

    return (
      <ProposalPageLayout
        componentName={this.props.componentName}
        title={t('Proposal summary')}
      >
        <div className="container">
          <div className="mw-840 pt-48 pb-64">
            <div className="box checkout">
              {invoicePreview && (
                <>
                  {invoicePreview?.Lines?.map((item, i) => (
                    <div className="mb-24">
                      <BasketItemFactory key={i} basketItem={item} />
                    </div>
                  ))}

                  {invoicePreview && invoicePreview.Lines && (
                    <div className="mb-24">
                      <DiscountInfoSection />
                    </div>
                  )}
                </>
              )}

              <hr className="my-24" />

              <div>
                <div className="text-right mb-48">
                  <CheckoutTotalPrice />
                </div>
                <CheckoutButton
                  completeUrl={routes.proposal_payment}
                  showClearBasket={false}
                  returnUrl={routes.proposal_payment}
                />
              </div>
            </div>
          </div>
        </div>
      </ProposalPageLayout>
    );
  }
}

export default ProposalSummaryPage;
