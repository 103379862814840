import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import _ from 'lodash';

import { withTranslation } from 'react-i18next';
import DashboardBox from 'ui/components/DashboardBox';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import AuthenticatedLink from 'ui/components/AuthenticatedLink';
import { routes } from 'env/routes';
import withCustomComponent from 'ui/components/withCustomComponent';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';

@withCustomComponent('InvoicesWidget')
@withTranslation()
@inject('appStore', 'invoicingStore')
@observer
class InvoicesWidget extends Component {
  componentDidMount() {
    const { invoicingStore } = this.props;
    invoicingStore.loadInvoices().catch(() => null);
    invoicingStore.loadActiveContracts().catch(() => null);
  }

  render() {
    const { t, baseUrl, appStore, invoicingStore } = this.props;
    const { hasLoadedInvoices } = invoicingStore;

    if (!hasLoadedInvoices)
      return (
        <DashboardBox title={t('Invoices')} url={routes.invoices}>
          <div
            data-component-name={this.props.componentName}
            className="dashboard-box__body"
          >
            <p className="dashboard-box__notice">
              <LoadingSpinner />
            </p>
          </div>
        </DashboardBox>
      );

    const invoices = _(invoicingStore.invoices)
      .orderBy(['Paid', 'CreatedOn'], ['asc', 'desc'])
      .take(4)
      .value();
    const hasInvoices = invoices.length > 0;
    const nextInvoice = invoicingStore.earliestRenewingContract;
    return (
      <DashboardBox
        title={t('Invoices')}
        url={hasInvoices ? routes.invoices : null}
      >
        <div
          data-component-name={this.props.componentName}
          className="dashboard-box__body"
        >
          {nextInvoice ? (
            <p className="dashboard-box__notice">
              <i className="fa fa-calendar i-space--right"></i>
              {t('Your next invoice will be issued on {{nextInvoice}}', {
                nextInvoice: moment(nextInvoice).format('L'),
              })}
            </p>
          ) : (
            <></>
          )}
          {hasInvoices ? (
            <table className="table table--invoices">
              <thead>
                <tr>
                  <th>{t('Invoice #')}</th>
                  <th className="text-center">{t('Due date')}</th>
                  <th className="text-center">{t('Amount')}</th>
                  <th className="text-center">{t('Status')}</th>
                </tr>
              </thead>

              <tbody>
                {invoices.map((inv, i) => (
                  <tr key={i}>
                    <th>
                      <AuthenticatedLink
                        href={`${appStore.business.NativeHomeUrlWithLanguage}/invoices/print?guid=${inv.UniqueId}`}
                      >
                        {inv.InvoiceNumber}
                      </AuthenticatedLink>
                    </th>
                    <td className="text-center">
                      {moment(inv.DueDate).format('L')}
                    </td>
                    <td className="text-center">
                      <LocalizedPrice
                        amount={inv.TotalAmount}
                        currency={inv.Currency.Code}
                      />
                    </td>
                    <td className="text-center">
                      {inv.Paid ? (
                        <>
                          <i className="fa fa-check-circle i-space--right--small text-success"></i>{' '}
                          <b>{t('Paid')}</b>
                        </>
                      ) : (
                        <>
                          {moment(inv.DueDate) > moment() && (
                            <b className="text-danger">{t('Pending')}</b>
                          )}
                          {moment(inv.DueDate) <= moment() && (
                            <b className="text-danger">{t('Due')}</b>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <>
              <p className="dashboard-box__zero-case-text">
                {t('You have no invoices in your account.')}
              </p>
              <svg
                width="140"
                height="138"
                xmlns="http://www.w3.org/2000/svg"
                className="dashboard-box__zero-case-icon"
              >
                <g fill="none" fillRule="evenodd">
                  <path
                    fill="#D9D9D9"
                    d="M45.221 5.221v116.511l15.663 15.663 15.663-15.663 15.664 15.663 15.664-15.663 15.662 15.663 15.663-15.663V5.222z"
                  ></path>
                  <path
                    className="dashboard-box__zero-case-icon__accent"
                    d="M40 0v116.51l15.663 15.663 15.663-15.663 15.664 15.663 15.664-15.663 15.662 15.663 15.664-15.663V0z"
                  ></path>
                  <path
                    fill="#FFFFFF"
                    d="M52.671 104.037h68.638V12.67H52.671z"
                  ></path>
                  <path
                    fill="#343434"
                    d="M62.485 94.223h49.01v-4.967h-49.01zM84.48 63.89c-4.245-.725-7.454-3.054-9.473-6.884l5.694-4.658c1.605 2.536 3.83 4.555 6.884 4.555 2.433 0 3.52-1.398 3.52-3.106 0-1.656-1.19-2.69-4.297-4.089l-2.07-.93c-5.537-2.486-8.126-6.575-8.126-11.543 0-5.33 3.365-9.11 7.868-10.248v-4.503h6.572v4.554c2.174.57 4.037 1.812 6.108 4.193l-4.968 5.227c-1.813-2.018-2.795-2.536-4.348-2.536-2.277 0-3.364 1.346-3.364 3.002 0 1.915 1.603 2.95 4.968 4.451l1.968.88c5.072 2.278 7.556 5.952 7.556 11.025 0 5.486-3.002 9.368-7.92 10.506v4.555H84.48v-4.45zM62.485 81.282h49.01v-4.966h-49.01zM5.5 17L0 22.499 5.5 28l5.5-5.501z"
                  ></path>
                  <path
                    className="dashboard-box__zero-case-icon__accent"
                    d="M6.5 95L0 101.499 6.5 108l6.5-6.501z"
                  ></path>
                  <path
                    fill="#D9D9D9"
                    d="M28 63.268h-3.732V67h-3.536v-3.732H17v-3.536h3.732V56h3.536v3.732H28z"
                  ></path>
                </g>
              </svg>
            </>
          )}
        </div>
      </DashboardBox>
    );
  }
}
export default InvoicesWidget;
