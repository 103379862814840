import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import Link from 'next/link';
import { routes } from 'env/routes';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';
import withCustomComponent from 'ui/components/withCustomComponent';
@withCustomComponent('FeaturedBlogPosts')
@withTranslation()
@inject('appStore', 'authStore', 'eventsStore')
@observer
class FeaturedBlogPosts extends Component {
  componentDidMount() {
    const { t, eventsStore } = this.props;
    eventsStore.loadUpcomingEvents().catch(() => null);
  }

  render() {
    const { match, t, appStore, eventsStore } = this.props;
    const { business } = appStore;
    const { upcomingEvents } = eventsStore;

    const events = _.take(upcomingEvents, 6);
    if (events.length === 0) return <></>;
    return (
      <div data-component-name={this.props.componentName}>
        <h2 className="dashboard-body__title">{t('Upcoming events')}</h2>

        <ul className="row events-list">
          {events.map((event, i) => {
            return (
              <li
                key={i}
                className="col-xxs-12 col-xs-6 col-md-4 events-list__item"
              >
                <Link href={routes.event_by_id(event)}>
                  <a className="events-list__cover">
                    <img
                      src={`${business.NativeHomeUrlWithLanguage}/events/getlargeimage?id=${event.Id}&w=720&h=540&cache=${event.UpdatedOn}`}
                      alt={event.Title}
                    />
                  </a>
                </Link>
                <div className="events-list__box">
                  <time className="events-list__time">
                    {moment(event.StartDate).format('LL')}{' '}
                    {moment(event.StartDate).format('LT')}
                    {' - '}
                    {moment(event.EndDate).format('LT')}
                  </time>
                  <h3 className="h4 events-list__title">
                    <Link href={routes.event_by_id(event)}>{event.Name}</Link>
                  </h3>
                  <div className="events-list__metadata">
                    <span>
                      <span className="events-list__location">
                        {event.Location || t(business.Name)}
                      </span>
                      <b className="events-list__price">
                        {event.ChepeastPrice != event.MostExpensivePrice && (
                          <>
                            {LocalizedPrice({
                              currency: business.Currency.Code,
                              amount: event.ChepeastPrice,
                            })}
                            {' - '}
                            {LocalizedPrice({
                              currency: business.Currency.Code,
                              amount: event.MostExpensivePrice,
                            })}
                          </>
                        )}
                        {(event.ChepeastPrice === event.MostExpensivePrice) ===
                          0 && <b>{t('Free')}</b>}
                        {(event.ChepeastPrice === event.MostExpensivePrice) >
                          0 && (
                          <b>
                            {LocalizedPrice({
                              currency: business.Currency.Code,
                              amount: event.ChepeastPrice,
                            })}
                          </b>
                        )}
                      </b>
                    </span>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        <hr />
      </div>
    );
  }
}

export default FeaturedBlogPosts;
