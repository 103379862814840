import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import global from 'global';

@withTranslation()
@inject('appStore', 'authStore')
@observer
class MobxApp extends Component {
  componentDidMount() {
    const {t, appStore, authStore} = this.props;
    global.t = t;

    if (
      process.env.LOCAL_BUILD !== 'true' &&
      process.env.NODE_ENV === 'production'
    ) {
      authStore.setRegistrableDomain(
        process.env.NEXT_PUBLIC_APP_DOMAIN ? process.env.NEXT_PUBLIC_APP_DOMAIN : appStore.configuration['System.RegistrableDomain'],
      );
    }
  }

  render() {
    return (
      <div className="nx" id={`business-${this.props.appStore.business?.Id}`}>
        {this.props.children}
      </div>
    );
  }
}

export default MobxApp;
