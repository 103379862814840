import React, { Component } from 'react';
import { observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import CommunityBoardPage from 'pages/community/index';
import { privateRoute } from 'env/privateRoute';

const CommunityMePage = privateRoute(
  withCustomComponent('CommunityMePage')(
    observer(({ componentName }) => {
      return (
        <CommunityBoardPage componentName={componentName} me={true} />
      );
    })
  )
);

export default CommunityMePage;
