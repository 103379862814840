import React, { Component } from 'react';
import Link from 'next/link';
import _ from 'lodash';
import { routes } from 'env/routes';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { removeHtml } from 'env/utils/strings';

import { TeamAvatar } from 'ui/components/TeamAvatar';
import { NewPrivateMessageForm } from 'ui/_pages/directory/members/NewPrivateMessageForm';

@withCustomComponent('TeamProfilePageHeader')
@withRouter
@withTranslation()
@inject('appStore', 'communityStore')
@observer
class TeamProfilePageHeader extends Component {
  componentDidMount() {
    const { appStore } = this.props;
    appStore.loadCountries();
  }

  componentWillUnmount() {
    const { team } = this.props;
    if (team?.PayingMemberId > 0)
      this.hideNewPrivateMessageModal(team.PayingMemberId);
  }

  showNewPrivateMessageModal = (memberId) => {
    const $ = window.$;
    $(`#new-team-private-message-modal-${memberId}`).modal({
      backdrop: 'static',
      keyboard: false,
      show: true,
    });
  };

  hideNewPrivateMessageModal = (memberId) => {
    const $ = window.$;
    $(`#new-team-private-message-modal-${memberId}`).modal('hide');
  };

  render() {
    const { t, componentName, team, appStore, communityStore } = this.props;
    const { business, countries, configuration } = appStore;
    const { newCommunityBoardMessage } = communityStore;

    const teamCountry = _.first(
      countries.filter((c) => c.Id == team.CountryId)
    );

    return (
      <div
        data-component-name={componentName}
        className="directory-team-profile-page-header"
      >
        <div
          className="directory-team-profile-page-header__images"
          style={{
            backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/team/GetAvatar/${team.Id})`,
          }}
        >
          <TeamAvatar
            team_id={team.Id}
            size={320}
            title={team.FulLName}
            imgClassName="avatar avatar--160"
          />
          {team.AllTeamMembers?.length > 0 && (
            <span className="badge right people">
              <i className="icon-community"></i>
              {team.AllTeamMembers.length}
            </span>
          )}
        </div>
        <div className="directory-team-profile-page-header__content">
          <div className="directory-team-profile-page-header__content--l">
            <h2>{removeHtml(team.Name)}</h2>
            {(team.CityName || team.CountryId) && (
              <p>
                {team.CityName}
                {countries && (
                  <>
                    {team.CityName && team.CountryId && ', '}
                    {teamCountry && teamCountry.Name}
                  </>
                )}
              </p>
            )}
            {team.Description && <p>{removeHtml(team.Description)}</p>}
            {team.ProfileTagsList.length > 0 && (
              <ul className="list-tags">
                {team.ProfileTagsList.map((tag, i) => {
                  return (
                    <li key={i}>
                      <Link href={routes.directory_teams + `?tag=${tag}`}>
                        <a>{tag}</a>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <div className="directory-team-profile-page-header__content--r">
            {team.ProfileWebsite && (
              <a
                target="_blank"
                href={team.ProfileWebsite}
                className="btn btn-outline  mr-8"
              >
                {t('Visit Website')}
              </a>
            )}
            {team.PayingMemberId > 0 &&
              configuration['PublicWebSite.CommunityBoard'] && (
                <>
                  <button
                    className="btn btn-icon "
                    onClick={() =>
                      this.showNewPrivateMessageModal(team.PayingMemberId)
                    }
                  >
                    <i className="icon-send"></i>
                    {t('Send Message')}
                  </button>
                  <div
                    id={`new-team-private-message-modal-${team.PayingMemberId}`}
                    className="modal fade"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                      <div className="modal-content">
                        <button
                          className="close"
                          onClick={() =>
                            this.hideNewPrivateMessageModal(team.PayingMemberId)
                          }
                        >
                          <i className="icon-close"></i>
                        </button>
                        <div className="modal-body py-40">
                          <NewPrivateMessageForm
                            member={{
                              Id: team.PayingMemberId,
                              Salutation: team.Name,
                            }}
                            newCommunityBoardMessage={newCommunityBoardMessage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    );
  }
}
export default TeamProfilePageHeader;
