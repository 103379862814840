import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { linkTo } from 'env/utils/strings';

export const CoursesPageFilters = withCustomComponent('CoursesPageFilters')(
  inject(
    'appStore',
    'authStore',
    'coursesStore'
  )(
    withRouter(
      observer(({ router, coursesStore, authStore, componentName }) => {
        const { t } = useTranslation();
        const { coursesPage } = coursesStore;

        const doSearch = (searchTerm) => {
          router.push(linkTo(router, {...router.query, query: searchTerm}));
        };

        const handleSearchBySelectChange = (e) => {
          e.preventDefault();

          switch (e.target.name) {
            case 'category':
              router.push(linkTo(router, { page: 1, group: e.target.value == 0 ? null : e.target.value }))
              break;
            default:
              router.push(linkTo(router, { page: 1, [e.target.name]: e.target.value == 0 ? null : e.target.value }))
          }
        }

        return (
          <>
            <div
              data-component-name={componentName}
              className="list-inline-32 sm-column mt-16"
            >

              <div className="d-flex align-items-center w-sm-100">
                <label className="text-gray-700 fs-14 mr-12 mw-sm-64px" htmlFor="select-course-category">
                  {t('Categories')}{':'}
                </label>
                <select
                  name="category"
                  id="select-course-category"
                  value={router.query.categoryId ? router.query.categoryId : 0}
                  onChange={(e) => {
                    handleSearchBySelectChange(e);
                  }}
                  className="custom-select custom-select-sm w-sm-100"
                >
                  <option value="0">All</option>
                  {coursesPage.Categories.map((group, i) => (
                    <option key={i} value={group.Id}>
                      {group}
                    </option>
                  ))}
                </select>
              </div>

              <div className="ml-auto w-sm-100">
                <div className="d-flex align-items-center justify-content-between bg-white rounded p-4 pl-8 fs-14 shadow-sm">
                  <input
                    id="query"
                    className="w-100 border-0 outline-0"
                    defaultValue={router.query.query}
                    placeholder={t('Search')}
                    onKeyDown={(ev) => {
                      if (ev.keyCode == 10 || ev.keyCode == 13) {
                        ev.preventDefault();
                        doSearch(ev.target.value);
                      }
                    }}
                  />
                  <button
                    className="btn btn-xs py-0 fs-18 btn-gray"
                    onClick={(e) => {
                      e.preventDefault();
                      doSearch(document.getElementById('query').value);
                    }}>
                    <i className="icon-search"></i>
                  </button>
                </div>
              </div>

            </div>
            <hr className="mt-24 mb-40" />
          </>
        );
      })
    )
  )
);
