/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import PlanTermsModal from 'ui/_pages/plan/PlanTermsModal';
import PlanDetails from 'ui/_pages/plan/PlanDetails';
import { withRouter } from 'next/router';
import withCustomComponent from 'ui/components/withCustomComponent';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import PlanDescriptionModal from 'ui/_pages/plan/PlanDescriptionModal';
import { PriceTaxStatus } from 'ui/components/PriceTaxStatus';

@withCustomComponent('PlansTable')
@withRouter
@withTranslation()
@inject('invoicingStore', 'appStore', 'authStore')
@observer
class PlansTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(),
    };
  }

  componentDidMount() {
    const { router, invoicingStore, authStore } = this.props;

    if (!invoicingStore.hasLoadedPricePlans) {
      invoicingStore.loadPricePlans({
        customer: authStore.customer,
        tariffGuid: router.query.tariff_guid,
      });
    }
  }

  showTermsFor = (service) => {
    this.setState({
      showTermsForService: service.TermsAndConditions,
    });
  };

  showDescriptionFor = (service) => {
    this.setState({
      showDescriptionForService: service,
    });
  };

  render() {
    const {
      onPlanSelected,
      hideTitle,
      showStartDate,
      match,
      t,
      invoicingStore,
    } = this.props;
    const { hasLoadedPricePlans, pricePlans } = invoicingStore;
    if (!hasLoadedPricePlans) return <LoadingLayout />;

    const noGroupName =
      pricePlans.PricePlanGroups?.length > 1 ? t('Other') : '';

    if (pricePlans.PricePlans?.length == 0 && !pricePlans.SelectedTariff)
      return <></>;

    return (
      <fieldset data-component-name={this.props.componentName} id="all-plan">
        {hideTitle ? (
          <></>
        ) : (
          <h5 className="section__title mb-0 mt-20">{t('All plans')}</h5>
        )}

        {this.state.showTermsForService && (
          <PlanTermsModal
            close={() => {
              this.setState({ showTermsForService: null });
            }}
            terms={this.state.showTermsForService}
          />
        )}

        {this.state.showDescriptionForService && (
          <PlanDescriptionModal
            close={() => {
              this.setState({ showDescriptionForService: null });
            }}
            service={this.state.showDescriptionForService}
          />
        )}

        {pricePlans.SelectedTariff && (
          <div className="col-md-8 col-md-offset-2">
            <div className="credentials__box">
              <PlanDetails
                startDate={this.state.startDate}
                match={match}
                onPlanSelected={onPlanSelected}
                isLoading={this.props.isLoading}
                service={pricePlans.SelectedTariff}
                t={t}
                showStartDate={showStartDate}
                displayAgreements
                onShowDescriptionFor={this.showDescriptionFor}
                onShowTermsFor={this.showTermsFor}
                onChangeDate={(value) => this.setState({ startDate: value })}
                design={this.props.planDesign ? this.props.planDesign : false}
              />
            </div>
          </div>
        )}

        {!pricePlans.SelectedTariff && (
          <>
            <ul
              id="plan-tabs"
              className="nav nav-tabs d-none d-md-flex"
              role="tablist"
            >
              {pricePlans.PricePlanGroups.map((group, i) => {
                if (!group && pricePlans.PricePlanGroups.length === 1)
                  return null;

                return (
                  <li key={i} role="presentation" className="nav-item">
                    <a
                      className={`nav-link ${i === 0 ? 'active' : ''}`}
                      id={`tariffs-${i}-link`}
                      href={`#tariffs-${i}`}
                      aria-controls={`#tariffs-${i}`}
                      role="tab"
                      data-toggle="tab"
                    >
                      {group || noGroupName}
                    </a>
                  </li>
                );
              })}
            </ul>
            {/* Nav select for small devices */}
            <select
              className="form-control nav-selector d-block d-md-none"
              data-content="#tab-content-memberships"
              onChange={(ev) => {
                $(`${ev.target.value}-link`).tab('show');
              }}
            >
              {pricePlans.PricePlanGroups.map((group, i) => (
                <option key={i} value={`#tariffs-${i}`}>
                  {group}
                </option>
              ))}
            </select>
            {/* Tab panes */}
            <div className="tab-content mt-24" id="tab-content-memberships">
              {pricePlans.PricePlanGroups.map((group, i) => (
                <div
                  key={i}
                  role="tabpanel"
                  className={`tab-pane ${i === 0 ? 'active' : ''}`}
                  id={`tariffs-${i}`}
                >
                  <ul className={`list-grid ${this.props.planDesign ? `plan-design-${this.props.planDesign}` : 'list-grid--33 list-grid--lg-50 list-grid--md-50 list-grid--sm-100'}`}>
                    {pricePlans.PricePlans.map((service, j) => {
                      return (
                        (service.GroupName || noGroupName) ===
                          (group || noGroupName) && (
                          <li key={j}>
                            <PlanDetails
                              startDate={this.state.startDate}
                              match={match}
                              onPlanSelected={onPlanSelected}
                              isLoading={this.props.isLoading}
                              service={service}
                              t={t}
                              showStartDate={showStartDate}
                              displayAgreements
                              onShowTermsFor={this.showTermsFor}
                              onShowDescriptionFor={this.showDescriptionFor}
                              onChangeDate={(value) =>
                                this.setState({ startDate: value })
                              }
                              design={this.props.planDesign ? this.props.planDesign : false}
                            />
                          </li>
                        )
                      );
                    })}
                  </ul>
                </div>
              ))}
            </div>
            <div className="mt-10">
              <PriceTaxStatus />
            </div>
          </>
        )}
      </fieldset>
    );
  }
}

export default PlansTable;
