import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject } from 'mobx-react';
import { linkTo } from 'env/utils/strings';
import withCustomComponent from 'ui/components/withCustomComponent';
export const Pagination = withCustomComponent('Pagination')(
  inject('appStore')(
    withRouter(({ componentName, router, records, page = 1, top = 24 }) => {
      const { t } = useTranslation();
      page = parseInt(page);

      const morePages = records && (page > 1 || records.length == top);
      return (
        <>
          {morePages && (
            <nav data-component-name={componentName}>
              <ul className="pagination">
                {page > 1 && (
                  <>
                    <li>
                      <Link href={linkTo(router, { page: page - 1 })}>
                        <a className="btn prev" aria-label={t('Previous')}>
                          <span aria-hidden="true">
                            <i className="icon-chevron-right"></i>
                          </span>
                        </a>
                      </Link>
                    </li>

                    {page > 2 && (
                      <li>
                        <Link href={linkTo(router, { page: 1 })}>
                          <a className="btn">1</a>
                        </Link>
                      </li>
                    )}
                    {page > 3 && (
                      <li className="disabled">
                        <a className="btn dots" href="javascript:;">
                          <span>...</span>
                        </a>
                      </li>
                    )}

                    <li>
                      <Link href={linkTo(router, { page: page - 1 })}>
                        <a className="btn">{page - 1}</a>
                      </Link>
                    </li>
                  </>
                )}
                <li className="active">
                  <a className="btn" href="#">
                    {page} <span className="sr-only">({t('current')})</span>
                  </a>
                </li>
                {records.length == top && (
                  <>
                    <li>
                      <Link href={linkTo(router, { page: page + 1 })}>
                        <a className="btn">{page + 1}</a>
                      </Link>
                    </li>

                    <li className="disabled">
                      <a className="btn dots" href="javascript:;">
                        <span>...</span>
                      </a>
                    </li>
                    <li>
                      <Link href={linkTo(router, { page: page + 1 })}>
                        <a className="btn next" aria-label={t('Next')}>
                          <span aria-hidden="true">
                            <i className="icon-chevron-right"></i>
                          </span>
                        </a>
                      </Link>
                    </li>
                  </>
                )}
                {records.length < top && (
                  <li className="disabled">
                    <a className="btn next" aria-label={t('Next')}>
                      <span aria-hidden="true">
                        <i className="icon-chevron-right"></i>
                      </span>
                    </a>
                  </li>
                )}
              </ul>
            </nav>
          )}
        </>
      );
    })
  )
);
