import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import { shorten } from 'env/utils/strings';
import withCustomComponent from 'ui/components/withCustomComponent';
export const CommunityBoardListFile = withCustomComponent(
  'CommunityBoardListFile'
)(
  inject('appStore')(
    withRouter(
      observer(
        ({
          appStore,
          setLightboxOpen,
          thread,
          fileIndex,
          fileId,
          overlayValue,
          componentName,
        }) => {
          const { t } = useTranslation();
          const { business } = appStore;
          const fileName = thread.FileNames[fileIndex].toLowerCase();
          const isImage =
            fileName.indexOf('.jpg') > -1 ||
            fileName.indexOf('.png') > -1 ||
            fileName.indexOf('.gif') > -1;
          const isPDF = fileName.indexOf('pdf') > -1;
          const isArchive =
            fileName.indexOf('zip') > -1 || fileName.indexOf('rar') > -1;

          const openFile = () => {
            const url = `${business.NativeHomeUrlWithLanguage}/Content/CommunityThreadFiles?id=${fileId}`;
            window.open(url);
          };

          return (
            <>
              {isImage && (
                <div
                  data-component-name={componentName}
                  className="community-board-list-file community-board-list-file--image"
                  href={`${business.NativeHomeUrlWithLanguage}/Content/CommunityThreadFiles?id=${fileId}&w=1024`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setLightboxOpen(fileIndex);
                  }}
                  style={{
                    backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/Content/CommunityThreadFiles?id=${fileId}&w=640&h=480)`,
                  }}
                >
                  {overlayValue && (
                    <span className="overlay">
                      {'+'}
                      {overlayValue}
                    </span>
                  )}
                </div>
              )}
              {!isImage && (
                <>
                  <div
                    data-component-name={componentName}
                    onClick={(ev) => {
                      ev.preventDefault();
                      openFile();
                    }}
                    className="community-board-list-file community-board-list-file--file"
                  >
                    <>
                      {isPDF && (
                        <>
                          <h6>{t('PDF')}</h6>
                          <i className="fa fa-file-pdf"></i>
                          <small>{fileName}</small>
                        </>
                      )}
                      {isArchive && (
                        <>
                          <h6>{t('Compressed File')}</h6>
                          <i className="fa fa-file-archive-o"></i>
                          <small>{fileName}</small>
                        </>
                      )}
                      {!isArchive && !isPDF && (
                        <>
                          <h6>{t('File')}</h6>
                          <i className="fa fa-download"></i>
                          <small>{fileName}</small>
                        </>
                      )}
                      {overlayValue && (
                        <span className="overlay">
                          {'+'}
                          {overlayValue}
                        </span>
                      )}
                    </>
                  </div>
                </>
              )}
            </>
          );
        }
      )
    )
  )
);
