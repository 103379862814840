import React, { Component } from 'react';
import { observer } from 'mobx-react';
import BookingsPage from 'pages/profile/bookings';
import { withCustomComponent } from 'ui/components/withCustomComponent';
import { checkTerms } from 'env/checkTerms';

@checkTerms
@withCustomComponent('BookingsDetailsPage')
@observer
class BookingDetailsPage extends Component {
  componentWillUnmount() {
    const $ = window.$;
    $('#bookings-booking-form').modal('hide');
  }
  render() {
    return <BookingsPage componentName={this.props.componentName} />;
  }
}

export default BookingDetailsPage;
