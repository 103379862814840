import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import moment from 'moment';
import { withRouter } from 'next/router';
import { routes } from 'env/routes';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { withCustomComponent } from 'ui/components/withCustomComponent';

import { RelatedProfile } from 'ui/_pages/directory/members/RelatedProfile';

@withCustomComponent('ProfilePageSide')
@withRouter
@withTranslation()
@inject('appStore')
@observer
class ProfilePageSide extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidMount() {}

  render() {
    const { t, componentName, appStore, member, relatedLists } = this.props;

    return (
      <div
        data-component-name={componentName}
        className="directory-profile-page-side"
      >
        <div className="card location">
          <h5>{t('Location')}</h5>
          <div className="d-flex align-item-center">
            <i className="icon-business-spaces fs-24 text-gray-600 mr-8"></i>
            <span>{member.HomeSpaceName}</span>
          </div>
        </div>

        {(member.ProfileWebsite ||
          member.Twitter ||
          member.Linkedin ||
          member.Facebook ||
          member.Skype ||
          member.Google ||
          member.Github ||
          member.Pinterest ||
          member.Flickr ||
          member.Instagram ||
          member.Vimeo ||
          member.Tumblr ||
          member.Blogger) && (
          <div className="card connect">
            <h5>{t('Connect')}</h5>
            <ul>
              {member.ProfileWebsite && (
                <li>
                  <a target="_blank" href={member.ProfileWebsite}>
                    <i className="icon-external-link"></i>
                    <span>{member.ProfileWebsite}</span>
                  </a>
                </li>
              )}
              {member.Twitter && (
                <li>
                  <a
                    target="_blank"
                    href={`http://twitter.com/${member.Twitter}`}
                  >
                    <i className="icon-social-twitter"></i>
                    <span>{t('Twitter')}</span>
                  </a>
                </li>
              )}
              {member.Linkedin && (
                <li>
                  <a target="_blank" href={member.Linkedin}>
                    <i className="icon-social-linkedin"></i>
                    <span>{t('LinkedIn')}</span>
                  </a>
                </li>
              )}
              {member.Facebook && (
                <li>
                  <a target="_blank" href={member.Facebook}>
                    <i className="icon-social-facebook"></i>
                    <span>{t('Facebook')}</span>
                  </a>
                </li>
              )}
              {member.Skype && (
                <li>
                  <a target="_blank" href={`callto://${member.Skype}`}>
                    <i className="icon-skype"></i>
                    <span>{t('Skype')}</span>
                  </a>
                </li>
              )}
              {member.Google && (
                <li>
                  <a target="_blank" href={member.Google}>
                    <i className="icon-google-square"></i>
                    <span>{t('Google')}</span>
                  </a>
                </li>
              )}
              {member.Github && (
                <li>
                  <a target="_blank" href={member.Github}>
                    <i className="icon-github"></i>
                    <span>{t('GitHub')}</span>
                  </a>
                </li>
              )}
              {member.Pinterest && (
                <li>
                  <a target="_blank" href={member.Pinterest}>
                    <i className="icon-pinterest"></i>
                    <span>{t('Pinterest')}</span>
                  </a>
                </li>
              )}
              {member.Flickr && (
                <li>
                  <a target="_blank" href={member.Flickr}>
                    <i className="icon-flickr"></i>
                    <span>{t('Flickr')}</span>
                  </a>
                </li>
              )}
              {member.Instagram && (
                <li>
                  <a target="_blank" href={member.Instagram}>
                    <i className="icon-social-instagram"></i>
                    <span>{t('Instagram')}</span>
                  </a>
                </li>
              )}
              {member.Vimeo && (
                <li>
                  <a target="_blank" href={member.Vimeo}>
                    <i className="icon-vimeo"></i>
                    <span>{t('Vimeo')}</span>
                  </a>
                </li>
              )}
              {member.Tumblr && (
                <li>
                  <a target="_blank" href={member.Tumblr}>
                    <i className="icon-social-tumblr"></i>
                    <span>{t('Tumblr')}</span>
                  </a>
                </li>
              )}
              {member.Blogger && (
                <li>
                  <a target="_blank" href={member.Blogger}>
                    <i className="icon-blogger"></i>
                    <span>{t('Blogger')}</span>
                  </a>
                </li>
              )}
            </ul>
          </div>
        )}

        {relatedLists && relatedLists.length > 0 && (
          <div className="card similar-profiles">
            <h5>{t('Similar profiles')}</h5>
            {relatedLists.map((relatedMembers) =>
              relatedMembers.map((relatedMember, j) => (
                <RelatedProfile
                  key={j}
                  member={relatedMember}
                  className="mb-16"
                />
              ))
            )}
          </div>
        )}

        {member.Twitter && (
          <div className="card twitter">
            <h5>
              {t('{{Salutation}} on Twitter', {
                Salutation: member.Salutation,
              })}
            </h5>
            <a
              className="twitter-timeline"
              href={`https://twitter.com/@${member.Twitter.replace('@', '')}`}
              data-tweet-limit="2"
              data-widget-id="430399305858285569"
              data-chrome="noheader nofooter transparent noborders"
              data-screen-name={member.Twitter.replace('@', '')}
              data-show-replies="false"
            >
              Tweets by @{member.Twitter.replace('@', '')}
            </a>
          </div>
        )}
      </div>
    );
  }
}
export default ProfilePageSide;
