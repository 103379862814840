import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import { withCustomComponent } from 'ui/components/withCustomComponent';
import moment from 'moment';
import Link from 'next/link';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import TourFormStep from 'ui/_pages/tour/TourFormStep';

@withCustomComponent('TourPage')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class TourPage extends Component {
  componentDidMount() {
    let date = new Date();
    date.setMinutes(0);
    date.setHours(9);
    date = moment(date).add('day', 1).toDate();
    const { appStore, authStore } = this.props;
    if (authStore.customer == null)
      authStore.createCustomer({
        business: appStore.business,
        isTour: true,
        tourDate: date,
      });
    this.showRequestTourModal();
  }

  componentWillUnmount() {
    this.hideRequestTourModal();
  }

  showRequestTourModal = () => {
    const $ = window.$;
    $('#request-tour-modal').modal({
      backdrop: 'static',
      keyboard: false,
      show: true,
    });
  };

  hideRequestTourModal = () => {
    const $ = window.$;
    $('#request-tour-modal').modal('hide');
    window.location.hash = '';
  };

  render() {
    const { t, appStore, authStore } = this.props;
    const { business } = appStore;
    const { customer, isSavingCustomerWithUser } = authStore;

    return (
      <DefaultLayout
        title={t('Request a tour of {{Name}}', { Name: t(business.Name) })}
      >
        <main className="content content--custom" role="main">
          <div className="container">
            <div
              data-component-name={this.props.componentName}
              className="modal modal-request-tour"
              id="request-tour-modal"
            >
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <Link href="/">
                    <a className="close">
                      <i className="icon-close"></i>
                    </a>
                  </Link>
                  <div className="modal-body">
                    {!customer && <LoadingSpinner />}
                    {customer && (
                      <TourFormStep
                        customer={customer}
                        isSavingCustomerWithUser={isSavingCustomerWithUser}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default TourPage;
