import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import Link from 'next/link';
import { routes } from 'env/routes';
import withCustomComponent from 'ui/components/withCustomComponent';
import ThreadFeedCard from './components/ThreadFeedCard';
import NewMemberCard from './components/NewMemberCard';
import EventFeedCard from './components/EventFeedCard';
import DiscountFeedCard from './components/DiscountFeedCard';
import CourseFeedCard from './components/CourseFeedCard';
import BlogFeedCard from './components/BlogFeedCard';
import dynamic from 'next/dynamic';
const ScrollableSection = dynamic(() => import('react-scrollable-url'), {
  ssr: false,
});
@withCustomComponent('VirtualFeedWidget')
@withTranslation()
@inject(
  'appStore',
  'communityStore',
  'coursesStore',
  'eventsStore',
  'blogStore',
  'contentStore'
)
@observer
class VirtualFeedWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    const {
      contentStore,
      coursesStore,
      eventsStore,
      communityStore,
      blogStore,
    } = this.props;
    Promise.all([
      communityStore.loadThreads({ page: 1, size: 25 }),
      communityStore.loadMembersDirectory({ onlyNew: true }),
      eventsStore.loadUpcomingEvents(),
      contentStore.loadCommunityPerks(),
      coursesStore.loadCoursesPage({ page: 1, size: 25 }),
      blogStore.loadBlogPosts({ page: 1, size: 25 }),
    ])
      .catch((x) => null)
      .finally(() => this.setState({ isLoading: false }));
  }

  render() {
    const { t, communityStore, appStore } = this.props;
    const { configuration } = appStore;
    const { dashboardItems } = communityStore;

    const grouped = _.groupBy(dashboardItems, (t) =>
      moment(t.CreatedOn).fromNow()
    );

    return (
      <div
        data-component-name={this.props.componentName}
        className="virtual-feed-widget"
      >
        {Object.keys(grouped).map((g) => (
          <fieldset>
            <h5 className="section__title">{g}</h5>
            {grouped[g].map((item) => (
              <ScrollableSection
                keepLastAnchorHash={true}
                name={`#feed-${item.type}-${item.data.Id}`}
              >
                <div id={`feed-${item.type}-${item.data.Id}`}>
                  {item.type == 'thread' && (
                    <ThreadFeedCard thread={item.data} />
                  )}
                  {item.type == 'member' &&
                    configuration['PublicWebSite.Directory'] && (
                      <NewMemberCard member={item.data} />
                    )}
                  {item.type == 'event' &&
                    configuration['PublicWebSite.Events'] && (
                      <EventFeedCard event={item.data} />
                    )}

                  {item.type == 'post' && <BlogFeedCard post={item.data} />}
                  {item.type == 'discount' && (
                    <DiscountFeedCard perk={item.data} />
                  )}
                  {item.type == 'course' && (
                    <CourseFeedCard courseContainer={item.data} />
                  )}
                </div>
              </ScrollableSection>
            ))}
          </fieldset>
        ))}
      </div>
    );
  }
}

export default VirtualFeedWidget;
