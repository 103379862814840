import React, {useState, useEffect, useRef} from 'react';
import {inject, observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import {withRouter} from 'next/router';
import {withCustomComponent} from 'ui/components/withCustomComponent';
import {withCheckTerms} from 'env/withCheckTerms';
import {isServer} from 'env/ssr/ServerSideRenderManager';

import BookingHeader from 'ui/_pages/bookings/components/BookingHeader';
import {RotateScreenAlert} from 'ui/_pages/bookings/calendar/RotateScreenAlert';
import {BookingDetails} from 'ui/_pages/bookings/calendar/BookingDetails';

let FullCalendarWrapper = '';

let BookingCalendarPage = ({appStore, bookingsStore, componentName, router}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const componentUnmounted = useRef(false);
  const [setupFullCalendarTimeout, setSetupFullCalendarTimeout] = useState(null);
  const {t} = useTranslation();

  const {business} = appStore;
  const {hasLoadedResources} = bookingsStore;

  useEffect(() => {
    const {resource_id, desk_id} = router.query;
    bookingsStore.loadResources().then(() => {
      if (componentUnmounted.current) return;

      if (resource_id) {
        bookingsStore.newBooking({
          resourceId: resource_id ? parseInt(resource_id) : null,
          floorPlanDeskId: desk_id ? parseInt(desk_id) : null,
        });
      }
    }).catch(() => null);

    setupFullCalendar();

    return () => {
      componentUnmounted.current = true;

      clearTimeout(setupFullCalendarTimeout);
    }
  }, []);

  const setupFullCalendar = async () => {
    if (componentUnmounted.current) return;

    //FC doesn't play nicely with SSR, so we do a dynamic import
    if (!isServer()) {
      let result = await import('ui/_pages/bookings/calendar/FullCalendar');
      FullCalendarWrapper = result.FullCalendarWrapper;
    }

    //Full calendar and react... 👻
    setSetupFullCalendarTimeout(setTimeout(() => {
      if (componentUnmounted.current) return;

      setShowCalendar(true);
    }, 500));

    if (window.addEventListener) {
      window.addEventListener('orientationchange', function() {
        location.reload();
      });
    }
  };

  return (
    <DefaultLayout
      componentName={componentName}
      title={t('Booking Calendar - {{Name}}', {Name: t(business.Name)})}
    >
      <main className="content content--custom pt-0" role="main">
        <BookingHeader/>
        <div className="container">

          {/*<RotateScreenAlert/>*/}

          <BookingDetails/>

          <div className="">
            {!hasLoadedResources && <LoadingSpinner/>}
            {hasLoadedResources && (
              <div id="bookings-calendar">
                {!isServer() && showCalendar && (
                  <FullCalendarWrapper/>
                )}
              </div>
            )}
          </div>
        </div>
      </main>
    </DefaultLayout>
  );
};

export default BookingCalendarPage = withCustomComponent('BookingCalendarPage')(
  withCheckTerms(
    inject(
      'appStore',
      'authStore',
      'bookingsStore',
    )(
      withRouter(
        observer(BookingCalendarPage),
      ),
    ),
  ),
);
