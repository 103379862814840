import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import {withCustomComponent} from 'ui/components/withCustomComponent';
import CheckoutComplete from 'ui/_pages/checkout/CheckoutComplete';
import {InvoicesTable} from 'ui/_pages/invoices/InvoicesTable';
import BlankLayout from 'ui/layouts/BlankLayout';
import {toJS} from 'mobx';
import * as prices from 'env/utils/prices';
import SpreedlyPaymentForm from 'ui/_pages/invoices/paymentForms/SpreedlyPaymentForm';
import SpreedlyPaymentButton from 'ui/_pages/invoices/paymentButtons/SpreedlyPaymentButton';
import BillingDetailsSection from '../../ui/_pages/profile/BillingDetailsSection';
import DownloadInvoiceButton from '../../ui/_pages/invoices/DownloadInvoiceButton';
import HostedPaymentProviderButton from '../../ui/_pages/invoices/paymentButtons/HostedPaymentProviderButton';
import Link from 'next/link';
import {routes} from 'env/routes';
import {withRouter} from 'next/router';

const CheckoutCompletePage = withCustomComponent('CheckoutCompletePage')(
  inject(
    'appStore',
    'authStore',
    'invoicingStore',
    'filesStore',
  )(withRouter(
    observer(({appStore, authStore, invoicingStore, filesStore, componentName, router}) => {
      const {t} = useTranslation();
      const {configuration, business} = appStore;
      const {isLoadingInvoicesPage, invoicesPage, unpaidInvoices} = invoicingStore;
      const {
        SpreedlyEnabled,
      } = invoicesPage;
      const isCorporateDashboard = appStore.corporateDashboard;
      const [payByInvoice, setPayByInvoice] = useState();

      useEffect(() => {
        if (!invoicingStore.hasLoadedPricePlans) {
          invoicingStore.loadPricePlans({
            customer: authStore.customer,
            tariffGuid: null,
          });
        }

        invoicingStore.loadInvoicesPage().
          then(({UnPaidInvoices}) => {
            // Redirect on the Complete page is no unpaid invoices
            if (UnPaidInvoices.length === 0) {
              router.push(routes.checkout_complete);
            }
          }).
          catch((err) => {
            appStore.setPopMessage(
              t('Sorry, we could not load your invoices. {{err}}', {err}),
            );
          });

        invoicingStore.loadInvoices().catch(() => {
          appStore.setPopMessage(t('Could not load your invoices.'));
        });
        filesStore.loadMyFiles().catch(err => console.error(`Can't load MyFiles.`, err));
      }, []);

      const onPaymentComplete = () => {
        authStore.loadCustomer();
        invoicingStore.loadInvoicesPage().catch((err) => {
          appStore.setPopMessage(
            t('Sorry, we could not load your invoices. {{err}}', {err}),
          );
        });
      };

      const saveCustomer = () => {
        authStore.saveCustomer().catch((err) => {
          appStore.setPopMessage(err);
        });
      };

      const invoice = unpaidInvoices && unpaidInvoices.length > 0 ? unpaidInvoices[0] : null;

      if (!(invoicingStore.hasLoadedInvoicesPage && invoicingStore.hasLoadedInvoices && filesStore.hasLoadedMyFiles)) {
        return <LoadingSpinner/>;
      }

      return (
        <BlankLayout
          componentName={componentName}
          title={t('Checkout - {{Name}}', {Name: t(business.Name)})}
        >
          <main className="content content--custom pt-0" role="main">
            {isLoadingInvoicesPage && <LoadingSpinner/>}
            {!isLoadingInvoicesPage && unpaidInvoices.length > 0 && (
              <>
                <div className="portal-title-wrapper">
                  <div className="container portal-title-container">
                    <h1 className="portal-title">
                      {isCorporateDashboard ? t('Orders') : t('Payment')}
                    </h1>
                  </div>
                </div>
                <div className="container">
                  <div className="funnel-steps-wrapper">
                    <div className="funnel-steps-body">
                      <div className="row">
                        <div className="col-md-5 col-lg-5 col-xl-5 mb-20">
                          {/*<div className="mb-32 mb-md-16">
                            <img className="img-fluid"
                                 src={}
                                 alt=""/>
                          </div>*/}
                          <div className="fs-14">
                            {invoice.Lines.map((line, i) => <div key={i} className="mb-4">
                              <div className="mb-4">
                                <span dangerouslySetInnerHTML={{__html: line.Description}}></span>
                                <span>: </span>
                                <span className="text-secondary"
                                >{prices.format(line.SubTotal + line.TaxAmount)}</span>
                              </div>
                            </div>)}
                          </div>
                        </div>
                        <div className="col-md-7 col-lg-7 col-xl-7">
                          <ul className="payment-methods ml-0 ml-md-20">
                            {/*Pay by invoice*/}
                            <li className="payment-method mb-12">
                              <div className="custom-control custom-radio">
                                <input type="radio" id="customRadio1" name="customRadio" className="custom-control-input"
                                       defaultChecked/>
                                <label className="payment-method-header custom-control-label custom-control-label-darken"
                                       htmlFor="customRadio1">
                                  {t('Pay by invoice')}
                                </label>
                                <div className="payment-method-body">
                                  <div className="mb-20">
                                    {[...Array(3).keys()].map(
                                      (i) =>
                                        configuration[
                                          `HostedPayments.Provider${
                                            i + 1
                                          }.Enabled`
                                          ] && (
                                          <HostedPaymentProviderButton
                                            key={i}
                                            index={i}
                                            providerIndex={i + 1}
                                            invoice={invoice}
                                            showQrCode="image"
                                            showOnLoad
                                            showDownloadInvoiceBtn
                                            rightSideCnt={<Link href={{
                                              pathname: routes.checkout_complete,
                                              query: {
                                                invoice_id: invoice.Id,
                                              },
                                            }}>
                                            <a className="btn ml-20">
                                              {t('Complete purchase')}
                                            </a>
                                          </Link>}
                                          />
                                        ),
                                    )}
                                  </div>

                                  {/*<div className="custom-control custom-checkbox mb-4">
                                    <input type="checkbox" className="custom-control-input" id="customCheck2"
                                           onChange={e => setPayByInvoice(e.target.checked)}
                                    />
                                    <label
                                      className="custom-control-label custom-control-label-darken custom-control-label-sm"
                                      htmlFor="customCheck2">Check
                                      neue Rechungsadresse erfassen
                                    </label>
                                  </div>

                                  {payByInvoice && <>
                                    <BillingDetailsSection
                                      saveCustomer={saveCustomer}
                                      customer={authStore.customer}
                                    />
                                  </>}*/}
                                </div>
                              </div>
                            </li>
                            {/*Pay by debit/credit card*/}
                            {!isCorporateDashboard && configuration['Spreedly.Enabled'] === 'True' && SpreedlyEnabled && (
                              <li className="payment-method mb-12">
                                <div className="custom-control custom-radio">
                                  <input type="radio" id="customRadio2" name="customRadio" className="custom-control-input"/>
                                  <label className="payment-method-header custom-control-label custom-control-label-darken"
                                         htmlFor="customRadio2">
                                    {t('Pay by debit/credit card')}
                                  </label>
                                  <div className="payment-method-body">
                                    <SpreedlyPaymentForm
                                      onPaymentComplete={onPaymentComplete}
                                      btnToolbarContentBefore={<>
                                        <SpreedlyPaymentButton
                                          className="btn mr-20"
                                          label={t('Pay by card to complete purchase')}
                                          invoice={unpaidInvoices[0]}
                                          redirectWhenPaidSuccess={`${routes.checkout_complete}?invoice_id=${invoice.Id}`}
                                          redirectWhenPaidFailed={`${routes.checkout_complete}?invoice_id=${invoice.Id}`}
                                        />
                                      </>}
                                    />
                                    {/*<SpreedlyPaymentButton
                                      className="btn mt-20 mb-20"
                                      label={t('Pay by card to complete purchase')}
                                      invoice={unpaidInvoices[0]}
                                      redirectWhenPaidSuccess={`${routes.checkout_complete}?invoice_id=${invoice.Id}`}
                                      redirectWhenPaidFailed={`${routes.checkout_complete}?invoice_id=${invoice.Id}`}
                                    />*/}
                                  </div>
                                </div>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*<div className="container">
                  {unpaidInvoices.length > 0 && (
                    <div className="info-block card-item-header bg-white mb-20">
                      {t('Download your invoice down below')}
                    </div>)}
                  <InvoicesTable
                    showPaymentHistory={unpaidInvoices.length > 0}
                    showPaidInvoices={false}
                  />
                </div>*/}
              </>
            )}
            <div className="container">
              {!isLoadingInvoicesPage && unpaidInvoices.length === 0 && (
                <CheckoutComplete/>
              )}
            </div>
          </main>
          {/*The bottom navigation bar*/}
          {/*{!isLoadingInvoicesPage && unpaidInvoices.length > 0 && <div className="step-buttons">
            <div className="container">
              <div className="btn-group justify-content-end">
                <Link href={{
                  pathname: routes.checkout_complete,
                  query: {
                    invoice_id: invoice.Id,
                  },
                }}>
                  <a className="btn">
                    {t('Complete purchase')}
                  </a>
                </Link>
              </div>
            </div>
          </div>}*/}
        </BlankLayout>
      );
    })),
  ),
);

export default CheckoutCompletePage;
