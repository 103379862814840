import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import { removeNewLines, removeHtml, getQueryString } from 'env/utils/strings';
import withCustomComponent from 'ui/components/withCustomComponent';
export const CommunityBoardItemTitle = withCustomComponent(
  'CommunityBoardItemTitle'
)(
  inject('appStore')(
    withRouter(
      observer(({ router, thread, componentName }) => {
        return (
          <Link
            href={{
              pathname: routes.thread_by_id(thread),
              query: {
                _top: router.query.top,
                _page: router.query.page,
              },
            }}
          >
            <a
              data-component-name={componentName}
              className="community-board-item-title"
            >
              {removeNewLines(removeHtml(thread.Subject))}
            </a>
          </Link>
        );
      })
    )
  )
);
