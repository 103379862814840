import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import CardDashboardSmall from 'ui/components/cards/CardDashboardSmall';
import withCustomComponent from 'ui/components/withCustomComponent';
import { routes } from 'env/routes';

@withCustomComponent('SupportWidgetSmall')
@withTranslation()
@inject('appStore', 'supportStore')
@observer
class SupportWidgetSmall extends Component {
  componentDidMount() {
    const { supportStore } = this.props;
    supportStore.loadMessages().catch(() => null);
  }

  render() {
    const { t, appStore, supportStore } = this.props;
    const { messages } = supportStore;

    const totalMessages = messages.length;

    const svg = (primaryColor) => (
      <svg
        width="80px"
        height="80px"
        viewBox="0 0 80 80"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Icons/Large/My-Tickets"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Group">
            <rect id="Rectangle" x="0" y="0" width="80" height="80"></rect>
            <path
              d="M6.66666667,46.6666667 L15.1684667,32.4970333 C16.1469454,30.8662156 17.570183,29.5482381 19.2712333,28.6977 L21.2218667,27.7224 C22.6104227,27.0281295 24.1415505,26.6666667 25.694,26.6666667 L33.3333333,26.6666667"
              id="Path"
              stroke="#999999"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M33.3333333,43.3333333 L33.3333333,25 C33.3333333,20.3976271 37.0642937,16.6666667 41.6666667,16.6666667 L65,16.6666667 C69.602373,16.6666667 73.3333333,20.3976271 73.3333333,25 L73.3333333,38.3333333 C73.3333333,42.9357063 69.602373,46.6666667 65,46.6666667 L46.6666667,46.6666667"
              id="Path"
              stroke={primaryColor}
              strokeWidth="2"
              fill={primaryColor}
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M13.3333333,70 L32.5395667,63.06 C35.2202453,62.0913547 37.519963,60.2891533 39.1013667,57.9177667 L49.0776,42.9577667 C50.5285943,40.7819273 50.2418947,37.8843623 48.3926333,36.0350667 L48.3926333,36.0350667 C46.250586,33.8930413 42.7777707,33.8926983 40.6353,36.0343 L33.3333333,43.3333333 L26.6666667,43.3333333"
              id="Path"
              stroke="#999999"
              strokeWidth="3"
              fill="#FFFFFF"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <line
              x1="63.3333333"
              y1="26.6666667"
              x2="53.3333333"
              y2="26.6666667"
              id="Path"
              stroke="#FFFFFF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
            <line
              x1="63.3333333"
              y1="36.6666667"
              x2="60"
              y2="36.6666667"
              id="Path"
              stroke="#FFFFFF"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></line>
          </g>
        </g>
      </svg>
    );

    return (
      <CardDashboardSmall
        title={t('Your tickets')}
        url={routes.support}
        mainValue={totalMessages}
        zeroMessage={t('No support tickets yet!')}
        svg={svg('var(--bg-color)')}
      />
    );
  }
}
export default SupportWidgetSmall;
