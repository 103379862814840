import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { SurveyQuestionRow } from './SurveyQuestionRow';

export const SurveyQuestionList = withCustomComponent('SurveyQuestionList')(
  inject(
    'appStore',
    'communityStore'
  )(
    withRouter(
      observer(({ surveyPage, componentName }) => {
        const { t } = useTranslation();
        const survey = surveyPage.SurveyRun.SurveyDrop;
        return (
          <fieldset
            data-component-name={componentName}
            className="survey__section"
          >
            {surveyPage.Questions.map((question, i) => (
              <SurveyQuestionRow question={question} key={i} />
            ))}
          </fieldset>
        );
      })
    )
  )
);
