import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import ProfilePageLayout from 'ui/layouts/ProfilePageLayout';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { privateRoute } from 'env/privateRoute';
import { withRouter } from 'next/router';
import { withCustomComponent } from 'ui/components/withCustomComponent';
import Head from 'next/head';
import ResponsiveTable from 'ui/components/tables/ResponsiveTable';
import moment from 'moment';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';

@withCustomComponent('ProfilePurchasesPage')
@privateRoute
@withRouter
@withTranslation()
@inject('appStore', 'authStore', 'invoicingStore')
@observer
class ProfilePurchasesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { invoicingStore, t, appStore } = this.props;

    invoicingStore
      .loadPurchaseHistory()
      .catch((err) =>
        appStore.setPopMessage(
          t('Sorry, we could not load your purchase history.', { err })
        )
      );
  }

  getPurchaseType(purchaseItem) {
    if (purchaseItem.ContractDepositUniqueId) {
      return t('Deposit');
    } else if (purchaseItem.CoworkerProductName) {
      return t('Product');
    } else if (purchaseItem.CoworkerExtraServiceName) {
      return t('Booking');
    } else if (purchaseItem.CoworkerTimePassName) {
      return t('Pass');
    } else if (purchaseItem.EventAttendeeProductName) {
      return t('Event');
    } else if (purchaseItem.TariffName) {
      return t('Contract');
    } else {
      return t('Other');
    }
  }

  getPurchaseStatus(purchase, paid, pending, invoiced) {
    if (purchase.Invoiced) {
      return purchase.Paid ? paid : invoiced;
    } else {
      return pending;
    }
  }

  render() {
    const { t, appStore, invoicingStore } = this.props;
    const { hasLoadedPurchaseHistory, purchaseHistory } = invoicingStore;

    return (
      <ProfilePageLayout componentName={this.props.componentName}>
        <Head>
          <title>
            {t('Purchases')} - {t(appStore.business.Name)}
          </title>
        </Head>

        <div>
          {!hasLoadedPurchaseHistory && (
            <form id="profile-form">
              <fieldset className="section--settings">
                <legend className="h3 section__title">
                  <LoadingSpinner />
                </legend>
              </fieldset>
            </form>
          )}

          {hasLoadedPurchaseHistory && (
            <>
              <h3>{t('Purchase History')}</h3>

              <p className="mxw-600px text-gray-700 fs-14 mb-20">
                {t('Your itemized purchased history at a glance.')}
              </p>

              {purchaseHistory?.length > 0 && (
                <div>
                  <ResponsiveTable>
                    <thead>
                      <tr>
                        <th className="w-50">{t('Item')}</th>
                        <th>{t('Type')}</th>
                        <th>{t('Status')}</th>
                        <th className="text-center">{t('QTY')}</th>
                        <th className="text-right">{t('Total')}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {purchaseHistory.map((purchase, i) => {
                        const purchaseDiscount =
                          purchase.Item?.DiscountAmount ?? 0;
                        const purchaseTotal =
                          purchase.Item?.SubTotal +
                          purchase.Item?.TaxAmount -
                          purchaseDiscount;

                        return (
                          <tr>
                            <td className="align-middle">
                              <span
                                className="fs-16"
                                dangerouslySetInnerHTML={{
                                  __html: purchase.Item?.Description,
                                }}
                              ></span>
                              {purchase.Invoiced && (
                                <>
                                  <br />
                                  <span className="text-gray-700">
                                    {moment(purchase.InvoiceDateUtc).format(
                                      'L'
                                    )}
                                  </span>
                                </>
                              )}
                            </td>
                            <td className="align-middle">
                              <span>{this.getPurchaseType(purchase.Item)}</span>
                            </td>
                            <td className="align-middle">
                              <div
                                className={`tag m-0 ${this.getPurchaseStatus(
                                  purchase,
                                  'green',
                                  'yellow',
                                  'red'
                                )}`}
                              >
                                <i
                                  className={`${this.getPurchaseStatus(
                                    purchase,
                                    'icon-check',
                                    'icon-pending',
                                    'icon-alert'
                                  )} mr-4`}
                                ></i>
                                <span>
                                  {this.getPurchaseStatus(
                                    purchase,
                                    t('Paid'),
                                    t('Pending'),
                                    t('Invoiced')
                                  )}
                                </span>
                              </div>
                            </td>
                            <td className="align-middle text-center">
                              {purchase.Item?.Quantity}
                            </td>
                            <td className="align-middle text-right">
                              <LocalizedPrice
                                currency={purchase.CurrencyCode}
                                amount={purchaseTotal}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </ResponsiveTable>
                </div>
              )}
            </>
          )}
        </div>
      </ProfilePageLayout>
    );
  }
}

export default ProfilePurchasesPage;
