import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import ReactPlayer from 'react-player';
const urlRegex = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

const isSupportedVideo = (url) =>
  url?.toLowerCase().indexOf('vimeo.com/') > -1 ||
  url?.toLowerCase().indexOf('youtube.com/watch') > -1 ||
  url?.toLowerCase().indexOf('soundcloud.com') > -1 ||
  url?.toLowerCase().indexOf('facebook.com/facebook/videos') > -1 ||
  url?.toLowerCase().indexOf('twitch.tv/videos') > -1 ||
  url?.toLowerCase().indexOf('streamable.com') > -1 ||
  url?.toLowerCase().indexOf('home.wistia.com/medias') > -1 ||
  url?.toLowerCase().indexOf('dailymotion.com/video') > -1 ||
  url?.toLowerCase().indexOf('mixcloud.com/mixcloud') > -1 ||
  url?.toLowerCase().indexOf('vidyard.com/watch') > -1;

export const CommunityBoardItemVideoLinks = withCustomComponent(
  'CommunityBoardItemVideoLinks'
)(
  inject(
    'appStore',
    'communityStore'
  )(
    withRouter(
      observer(
        ({
          communityStore,
          turndownService,
          thread,
          threadDetails,
          componentName,
        }) => {
          const { t } = useTranslation();

          const urls = thread.Message.match(urlRegex);
          const uniqueUrl = _.uniq(urls);
          return (
            <div data-component-name={componentName}>
              {uniqueUrl?.map(
                (url, i) =>
                  isSupportedVideo(url) && (
                    <div key={i} className="video-preview">
                      <ReactPlayer controls width={'100%'} url={url} />
                    </div>
                  )
              )}
            </div>
          );
        }
      )
    )
  )
);
