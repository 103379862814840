/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('KlarnaWidget')
@withTranslation()
@inject('appStore', 'authStore')
class KlarnaWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      widgetShown: false,
      authorizing: false,
    };
  }

  processPayment = () => {
    //this.setState({ authorizing: true });
    Klarna.Payments.authorize({}, {}, (result) => {
      //this.setState({ authorizing: false });
      console.debug(result);
    });
  };

  render() {
    const { t, invoice, componentName } = this.props;
    return (
      <>
        <tr>
          <td colSpan={5}>
            <div id={`klarna-payments-container-${invoice.Id}`}></div>
            <a
              href="#"
              data-component-name={componentName}
              className="btn btn-icon btn-link text-gray-900"
              onClick={() => this.processPayment()}
            >
              <i aria-hidden="true" className="icon-credit-card"></i>{' '}
              {this.state.authorizing
                ? t('Completing purchase...')
                : t('Complete payment')}
            </a>
            <div className="mb-20"></div>
          </td>
        </tr>
      </>
    );
  }
}
export default KlarnaWidget;
