import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { BasketItem } from 'ui/_pages/checkout/basketItems/BasketItem';
import moment from 'moment';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';
export const BookingBasketItem = withCustomComponent('BookingBasketItem')(
  inject(
    'appStore',
    'bookingsStore'
  )(
    withRouter(
      observer(({ router, basketItem, bookingsStore, appStore }) => {
        const { t } = useTranslation();
        const { data } = basketItem;
        const { business, configuration } = appStore;

        const selectedProducts = _.filter(
          data.ResourceProducts,
          (p) => p.Quantity > 0
        );

        const editBooking = (hash) => {
          if (hash) window.location.hash = hash;
          bookingsStore.loadBasketBooking(basketItem.data);
        };

        return (
          <BasketItem
            basketItem={basketItem}
            id={data.Id}
            imageUrl={`${business.NativeHomeUrlWithLanguage}/publicresources/getimage/?id=${data.ResourceId}&w=96&h=96&cache=${data.UpdatedOn}`}
            header={data.ResourceName}
            onEdit={editBooking}
            times={
              <>
                {moment(data.FromTime).calendar()}
                {' - '}
                {moment(data.FromTime).format('LT')} →{' '}
                {moment(data.ToTime).isSame(data.FromTime, 'day') ? (
                  moment(data.ToTime).format('LT')
                ) : (
                  <>
                    {moment(data.ToTime).calendar()}{' '}
                    {moment(data.ToTime).format('LT')}
                  </>
                )}
              </>
            }
            features={
              data.BookingPriceMessage && (
                <>
                  <div className="alert alert-sm alert-inline alert-danger">
                    <div className="d-flex align-items-center">
                      <i className="icon-alert mr-4 fs-18"></i>
                      <span className="fw-500">{data.BookingPriceMessage}</span>
                    </div>
                  </div>
                </>
              )
            }
            total={
              data.BookingPrice != null ? (
                <LocalizedPrice
                  currency={data.BookingPriceCurrencyCode}
                  amount={data.BookingPrice}
                />
              ) : null
            }
            additionalActions={
              <>
                {configuration['PublicWebSite.Visitors'] && (
                  <>
                    {' '}
                    <a
                      href="#"
                      onClick={(ev) => {
                        ev.preventDefault();
                        editBooking('visitors');
                      }}
                      className="btn btn-icon btn-xs btn-white"
                    >
                      <i className="icon-attendee-add"></i>
                      {t('Add attendees')}
                    </a>{' '}
                  </>
                )}
                <a
                  href="#"
                  onClick={(ev) => {
                    ev.preventDefault();
                    editBooking('notes');
                  }}
                  className="btn btn-icon btn-xs btn-white"
                >
                  <i className="icon-note"></i>
                  {t('Add a note')}
                </a>
              </>
            }
            options={
              selectedProducts.length > 0 && (
                <div className="booking-options">
                  <i className="icon-add-small"></i>
                  {selectedProducts.map((product, i) => {
                    if (product.Quantity > 1) {
                      return (
                        <span key={i}>
                          {product.Quantity}
                          {'x '}
                          {product.ProductName}
                          {/* <LocalizedPrice currency={data.BookingPriceCurrencyCode} amount={product.Price * product.Quantity} /> */}
                        </span>
                      );
                    } else {
                      return <span key={i}>{product.ProductName}</span>;
                    }
                  })}
                </div>
              )
            }
          />
        );
      })
    )
  )
);
