import React from 'react';
import Link from 'next/link';
import {routes} from 'env/routes';
import {useTranslation} from 'react-i18next';
import {withRouter} from 'next/router';
import {inject, observer} from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const BasketItem = withCustomComponent('BasketItem')(
  inject(
    'appStore',
    'checkoutStore',
    'authStore',
    'bookingsStore'
  )(
    withRouter(
      observer(
        ({
           componentName,
           basketItem,
           onEdit,
           id,
           options,
           additionalActions,
           features,
           imageUrl,
           header,
           times,
           total,
           appStore,
           checkoutStore,
           authStore,
           bookingsStore,
           showRemoveButton = true,
         }) => {
          const {t} = useTranslation();
          const {customer} = authStore;
          const removeFromBasket = () => {
            checkoutStore.removeFromBasket({
              item: basketItem,
              previewInvoice: customer != null,
            });
          };

          const isCorporateDashboard = appStore.corporateDashboard;

          return (
            <div
              data-component-name={componentName}
              className="card card-basket-item"
            >
              <div className="card-basket-item__header">{features}</div>

              <div className="card-basket-item__content">
                <div className="card-basket-item__content-l">
                  <div className="card-basket-item__media">
                    {imageUrl && (
                      <img
                        style={{width: 48, height: 48}}
                        src={imageUrl}
                        alt=""
                        className="card-basket-item__media__image"
                      />
                    )}
                    {basketItem.type === 'booking' && (
                      <div
                        className={`card-basket-item__media__higlight bg-${bookingsStore.getResourceColor(
                          basketItem.data.ResourceId
                        )}`}
                      ></div>
                    )}
                    <div className="card-basket-item__media__text">
                      <h5
                        style={{cursor: onEdit ? 'pointer' : null}}
                        onClick={() => onEdit()}
                        className="card-basket-item__media__text__title"
                      >
                        {header}
                      </h5>
                      <div className="card-basket-item__media__text__times">
                        {times}
                      </div>
                    </div>
                  </div>
                  <div className="card-basket-item__options">{options}</div>
                </div>
                <div className="card-basket-item__content-r">
                  <div className="card-basket-item__price">
                    {!isCorporateDashboard && <>{total}</>}
                  </div>
                  <div className="card-basket-item__actions">
                    {/* additionalActions */}
                    {onEdit && (
                      <a
                        href="#"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onEdit();
                        }}
                        className="btn btn-xs btn-icon btn-white border-0"
                      >
                        <i className="icon-edit"></i>
                        {t('Change')}
                      </a>
                    )}
                    {showRemoveButton && (
                      <a
                        href="#"
                        onClick={(ev) => {
                          ev.preventDefault();
                          removeFromBasket();
                        }}
                        className="btn btn-xs btn-icon btn-white border-0" // "btn btn-xs btn-icon btn-link text-gray-600 fs-12 fw-500"
                      >
                        <i className="icon-close-large"></i>
                        {t('Remove')}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        }
      )
    )
  )
);
