/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { LocalizedNumber } from 'env/utils/NumbersLocalization';
import withCustomComponent from 'ui/components/withCustomComponent';
@withCustomComponent('BookingCreditsRow')
@withTranslation()
@inject('appStore')
@observer
class BookingCreditsRow extends Component {
  render() {
    const { t, appStore, benefit, componentName } = this.props;
    const { business } = appStore;
    const totalCreditsFormatted = LocalizedNumber({
      amount: benefit.RemainingBookingCredit,
    });
    const totalMinutesFormatted = LocalizedNumber({
      amount: benefit.RemainingTimeCreditMinutes / 60,
    });

    return (
      <tr data-component-name={componentName}>
        <td>
          <div className="d-flex align-items-center">
            <img
              className="avatar mr-8"
              src={`${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${benefit.CoworkerId}?h=64&w=64&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}
            />
            <span>{benefit.CoworkerFullName}</span>            
          </div>
        </td>
        <td className="text-right">
          {benefit.RemainingBookingCredit > 0 &&
            t('{{totalFormatted}} credit', {
              totalFormatted: totalCreditsFormatted,
              count: benefit.RemainingBookingCredit,
            })}
          {benefit.RemainingBookingCredit > 0 &&
            benefit.RemainingTimeCreditMinutes > 0 && <br />}
          {benefit.RemainingTimeCreditMinutes > 0 &&
            t('{{totalFormatted}} hr.', {
              totalFormatted: totalMinutesFormatted,
              count: benefit.RemainingTimeCreditMinutes / 60,
            })}
        </td>
        <td className="text-right">
          {t('{{totalFormatted}} hr.', {
            totalFormatted: LocalizedNumber({
              amount: benefit.BookedTimeThisMonth / 60,
            }),
            count: benefit.BookedTimeThisMonth / 60,
          })}
        </td>
      </tr>
    );
  }
}

export default BookingCreditsRow;
