import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { removeHtml, shorten } from 'env/utils/strings';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const EventPageSocialLinks = withCustomComponent('EventPageSocialLinks')(
  inject(
    'appStore',
    'eventsStore'
  )(
    withRouter(
      observer(({ router, appStore, eventsStore }) => {
        const { t } = useTranslation();
        const event = eventsStore.eventPage.Event;

        return (
          (event.WebAddress || event.FacebookPage) &&
          <div className="card event-page-social-links h-auto mb-24">
            <h5>{t('Connect')}</h5>
            <ul>
              {event.WebAddress && (
                <li>
                  <a href={event.WebAddress} target="_blank">
                    <i className="icon-external-link"></i>
                    <span>{t('Website')}</span>
                  </a>
                </li>
              )}
              {event.FacebookPage && (
                <li>
                  <a href={event.FacebookPage} target="_blank">
                    <i className="icon-social-facebook"></i>
                    <span>{t('Facebook')}</span>
                </a>
                </li>
              )}
            </ul>
          </div>
        );
      })
    )
  )
);
