import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import AuthenticatedLink from 'ui/components/AuthenticatedLink';
import withCustomComponent from 'ui/components/withCustomComponent';
@withCustomComponent('GoCardlessPaymentButton')
@withTranslation()
@inject('appStore', 'authStore', 'invoicingStore')
class GoCardlessPaymentButton extends Component {
  render() {
    const {
      className,
      returnUrl = '',
      failedReturnUrl = '',
      t,
      appStore,
      authStore,
      invoice,
      componentName,
      invoicingStore,
    } = this.props;
    const { customer } = authStore;
    const { business } = appStore;

    return (
      <AuthenticatedLink
        data-component-name={componentName}
        className={`${
          className ? className : 'btn btn-icon btn-link text-gray-900'
        }`}
        href={`${business.NativeHomeUrlWithLanguage}/Callbacks/GoCardlessPre/${
          invoice ? invoice.CoworkerId : customer.Id
        }?returnUrl=${encodeURIComponent(
          returnUrl
        )}&failedReturnUrl=${encodeURIComponent(failedReturnUrl)}`}
      >
        <i aria-hidden="true" className="icon-simple-pay"></i>
        {t('Set up Direct Debit')}
      </AuthenticatedLink>
    );
  }
}
export default GoCardlessPaymentButton;
