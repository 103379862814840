import React, { Component } from 'react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { FloorPlanBasketItem } from 'ui/_pages/bookings/floorplan/FloorPlanBasketItem';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import Link from 'next/link';
import { routes } from 'env/routes';
import _ from 'lodash';
@withCustomComponent('FloorplansCart')
@withTranslation()
@inject('appStore', 'bookingsStore', 'checkoutStore')
@observer
class FloorPlanBasket extends Component {
  initCartHeight = () => {
    if ($(window).width() > 992) {
      const floorplansMapHeight = $('.js-floorplans-map').height();
      $('.js-floorplans-cart').innerHeight(floorplansMapHeight);
    }
  };

  componentDidMount() {
    this.initCartHeight();
  }

  render() {
    const { t, appStore, bookingsStore, checkoutStore } = this.props;
    const { business } = appStore;
    const { basket } = checkoutStore;
    const { isLoadingBookingPrice } = bookingsStore;
    const bookings = basket.filter((i) => i.type === 'booking');
    const total = _.sumBy(bookings, (b) => b.data.BookingPrice);
    return (
      <div
        data-component-name={this.props.componentName}
        className="box cart js-floorplans-cart"
      >
        <div className="cart__inner">
          <h2 className="h4 cart__title">{t('Your bookings')}</h2>
          <ul className="cart__list">
            {bookings.length == 0 && (
              <li>{t('You have no bookings in your basket')}</li>
            )}
            {bookings.map((item, i) => (
              <FloorPlanBasketItem item={item} key={i} />
            ))}
          </ul>
        </div>

        <div className="cart__total">
          {total > 0 && (
            <div className="cart__total__price">
              <span className="cart__total__text">{t('Total')}</span>
              <span className="h4 cart__total__amount">
                {isLoadingBookingPrice ? (
                  <LoadingSpinner />
                ) : (
                  <LocalizedPrice
                    currency={business.Currency.Code}
                    amount={total}
                  />
                )}
              </span>
            </div>
          )}
          <Link href={routes.checkout}>
            <a href="#" className="btn btn--success btn--sm btn-block">
              {total > 0 ? t('Check out') : t('Continue')}
            </a>
          </Link>
        </div>
      </div>
    );
  }
}
export default FloorPlanBasket;
