import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import SignupNavigationMenu from 'ui/_pages/signup/SignupNavigationMenu';
import Head from 'next/head';
import { withRouter } from 'next/router';

@withCustomComponent('SignupPageLayout')
@withRouter
@withTranslation()
@inject('appStore')
@observer
class SignupPageLayout extends Component {
  render() {
    const { t, router, appStore } = this.props;
    const { business } = appStore;
    const { returnurl } = router.query;
    return (
      <div data-component-name={this.props.componentName}>
        <Head>
          <meta charSet="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
          <title>
            {t('Join')} - {t(business.Name)}
          </title>
        </Head>

        <header className="site-header site-header--lo position-relative">
          <div className="site-header__content">
            <nav className="navbar container">
              <a className="navbar-brand" href="/">
                <img
                  src={`${business.NativeHomeUrlWithLanguage}/business/getlogo?h=96&mode=pad`}
                  alt={t(business.Name)}
                />
                <div className="spacer d-none d-md-inline-block"></div>
                <span className="d-none d-md-inline">{t(business.Name)}</span>
              </a>
              <a href="/" className="btn btn-single-icon btn-white">
                <i className="icon-close-large fs-18"></i>
              </a>
            </nav>
          </div>
        </header>

        <main className="content content--signup signup" role="main">
          <div className="container">
            <div className="row">
              {!(returnurl?.length > 0) && (
                <div className="col-lg-3">
                  <div className="signup__nav">
                    <h2 className="mb-24">{t('Create account')}</h2>
                    <SignupNavigationMenu />
                  </div>
                </div>
              )}
              <div className="col-lg-9">
                {returnurl?.length > 0 && (
                  <h2 className="mb-24">{t('Create account')}</h2>
                )}
                <div className="signup__content">
                  <h2 className="mb-0 d-none d-lg-block">&nbsp;</h2>
                  {this.props.children}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default SignupPageLayout;
