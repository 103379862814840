import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import CardDashboardSmall from 'ui/components/cards/CardDashboardSmall';
import withCustomComponent from 'ui/components/withCustomComponent';
import { routes } from 'env/routes';

@withCustomComponent('MessagesWidgetSmall')
@withTranslation()
@inject('appStore', 'communityStore')
@observer
class MessagesWidgetSmall extends Component {
  componentDidMount() {
    const { appStore } = this.props;
  }

  render() {
    const { t, appStore, communityStore, oneLiner } = this.props;

    const unreadMessages = appStore.notifications.filter(
      (x) =>
        x.NotificationType == 'community-thread' ||
        x.NotificationType == 'community-message'
    ).length;

    const svg = (primaryColor) => (
      <svg
        width="94px"
        height="81px"
        version="1.1"
        id="MessagesWidgetSvg"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 93.4 81"
        enableBackground="new 0 0 93.4 81"
      >
        <g>
          <circle fill="#F5F5F5" cx="56.2" cy="43.9" r="37.2" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M70.2,71.9l2.2,5.6c0.4,1,1.4,1.7,2.4,1.7l0,0
		c1.9,0,3.1-1.9,2.4-3.6l-1.4-3.7"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M87.5,54.9l-5.6,3.9H71.6c-3.2,0-5.8,2.6-5.8,5.8V66
		c0,2.8,1.9,5.1,4.5,5.7l2.3,5.7c0.4,1.1,1.4,1.8,2.6,1.8c2,0,3.3-2,2.6-3.9l-1.4-3.4l5.5,0l5.6,3.9c1.9,1.3,4.6-0.1,4.6-2.4v-16
		C92.1,55,89.4,53.6,87.5,54.9z"
          />
          <path
            fill={primaryColor}
            d="M91,53.8c-1.3-0.7-2.8-0.6-4,0.2l-5.4,3.7h-9.9c-3.8,0-6.8,3.1-6.8,6.8V66c0,3,1.9,5.5,4.5,6.4l2.1,5.4
		c0.6,1.4,1.9,2.3,3.4,2.3c1.2,0,2.3-0.6,3-1.6c0.7-1,0.8-2.3,0.4-3.4l-0.9-2.3l4.2,0l5.4,3.7c0.7,0.5,1.4,0.7,2.2,0.7
		c0.6,0,1.3-0.2,1.8-0.5c1.3-0.7,2.1-2,2.1-3.5v-16C93.1,55.8,92.3,54.5,91,53.8z M76.2,77.5c-0.3,0.5-0.8,0.7-1.3,0.7
		c-0.7,0-1.3-0.4-1.5-1l-1.7-4.3l3.5,0l1.2,3C76.6,76.4,76.5,77,76.2,77.5z M80.8,70.9l-9.2,0c-2.7,0-4.8-2.2-4.8-4.8v-1.5
		c0-2.7,2.2-4.8,4.8-4.8h9.2V70.9z M91.1,73.3c0,0.7-0.4,1.4-1,1.7s-1.4,0.3-2-0.1l-5.2-3.6V59.3l5.2-3.6c0.6-0.4,1.3-0.5,2-0.1
		c0.6,0.3,1,1,1,1.7V73.3z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M13.2,73.9C24.9,82.1,40.8,80,50,69.1s8.4-27-1.6-37
		s-26.1-10.8-37-1.6s-13,25.1-4.8,36.7"
          />
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M13.2,73.9C24.9,82.1,40.8,80,50,69.1
			s8.4-27-1.6-37s-26.1-10.8-37-1.6s-13,25.1-4.8,36.7"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M21.9,62.2l3.5-21.3L21.9,62.2z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M32.5,62.2L36,40.9L32.5,62.2z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M40.7,46.3H19.1H40.7z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M38.8,56.8H17.2H38.8z"
          />
          <path
            fill={primaryColor}
            d="M40.7,44.8h-3.8l0.6-3.7c0.1-0.8-0.4-1.6-1.2-1.7c-0.8-0.1-1.6,0.4-1.7,1.2l-0.7,4.2h-7.6l0.6-3.7
		c0.1-0.8-0.4-1.6-1.2-1.7c-0.8-0.1-1.6,0.4-1.7,1.2l-0.7,4.2h-4.1c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h3.6l-1.2,7.5h-4.3
		c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5H21l-0.6,3.7c-0.1,0.8,0.4,1.6,1.2,1.7c0.1,0,0.2,0,0.2,0c0.7,0,1.4-0.5,1.5-1.3l0.7-4.1
		h7.6L31,61.9c-0.1,0.8,0.4,1.6,1.2,1.7c0.1,0,0.2,0,0.2,0c0.7,0,1.4-0.5,1.5-1.3l0.7-4.1h4.2c0.8,0,1.5-0.7,1.5-1.5
		s-0.7-1.5-1.5-1.5h-3.7l1.2-7.5h4.3c0.8,0,1.5-0.7,1.5-1.5S41.5,44.8,40.7,44.8z M32.1,55.3h-7.6l1.2-7.5h7.6L32.1,55.3z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="#FFFFFF"
            d="M13.2,73.9l-8.6,1.9L13.2,73.9z"
          />
          <path
            fill={primaryColor}
            d="M49.4,31C38.7,20.3,22,19.6,10.4,29.3C-1.2,39-3.4,55.7,5.3,68.1c0.5,0.7,1.4,0.8,2.1,0.4
		C8.1,68,8.2,67,7.7,66.3c-7.8-11.1-5.8-26,4.6-34.7s25.4-8.1,35,1.5c9.6,9.6,10.2,24.6,1.5,35c-8.7,10.4-23.6,12.3-34.7,4.6
		c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0
		c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0l-8.6,1.9c-0.8,0.2-1.3,1-1.1,1.8c0.2,0.7,0.8,1.2,1.5,1.2c0.1,0,0.2,0,0.3,0
		l8-1.8c4.9,3.3,10.5,4.9,16.1,4.9c8.3,0,16.4-3.6,22.1-10.4C60.9,58.5,60.1,41.7,49.4,31z"
          />
          <path
            fill={primaryColor}
            d="M92.4,16.5h-4.8c-0.6,0-1-0.4-1-1s0.4-1,1-1h4.8c0.6,0,1,0.4,1,1S93,16.5,92.4,16.5z"
          />
          <path
            fill={primaryColor}
            d="M68.3,16.5h-4.8c-0.6,0-1-0.4-1-1s0.4-1,1-1h4.8c0.6,0,1,0.4,1,1S68.8,16.5,68.3,16.5z"
          />
          <path
            fill={primaryColor}
            d="M77.9,6.8c-0.6,0-1-0.4-1-1V1c0-0.6,0.4-1,1-1s1,0.4,1,1v4.8C78.9,6.4,78.5,6.8,77.9,6.8z"
          />
          <path
            fill={primaryColor}
            d="M77.9,31c-0.6,0-1-0.4-1-1v-4.8c0-0.6,0.4-1,1-1s1,0.4,1,1V30C78.9,30.6,78.5,31,77.9,31z"
          />
          <path
            fill={primaryColor}
            d="M88.2,26.8c-0.3,0-0.5-0.1-0.7-0.3L84.1,23c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l3.4,3.4c0.4,0.4,0.4,1,0,1.4
		C88.7,26.7,88.4,26.8,88.2,26.8z"
          />
          <path
            fill={primaryColor}
            d="M71.1,9.7c-0.3,0-0.5-0.1-0.7-0.3L67,6c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0L71.8,8c0.4,0.4,0.4,1,0,1.4
		C71.6,9.6,71.3,9.7,71.1,9.7z"
          />
          <path
            fill={primaryColor}
            d="M84.8,9.7c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l3.4-3.4c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-3.4,3.4
		C85.3,9.6,85,9.7,84.8,9.7z"
          />
          <path
            fill={primaryColor}
            d="M67.7,26.8c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l3.4-3.4c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-3.4,3.4
		C68.2,26.7,67.9,26.8,67.7,26.8z"
          />
        </g>
      </svg>
    );

    return (
      <CardDashboardSmall
        title={t('Unread messages')}
        url={routes.my_threads}
        mainValue={unreadMessages}
        zeroMessage={t('No unread messages!')}
        svg={svg('var(--bg-color)')}
        oneLiner={oneLiner}
      />
    );
  }
}
export default MessagesWidgetSmall;
