import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation, Trans } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import asForm from 'ui/components/forms/asForm';
import { CoworkerAvatar } from 'ui/components/CoworkerAvatar';
import ReactMarkdown from 'react-markdown';
import moment from 'moment';
import { initTimeAgo } from 'env/utils/dateUtil';
import ScrollableAnchor from 'react-scrollable-anchor';
import withCustomComponent from 'ui/components/withCustomComponent';

export const CommunityBoardReply = withCustomComponent('CommunityBoardReply')(
  asForm(
    inject(
      'appStore',
      'authStore',
      'communityStore'
    )(
      withRouter(
        observer(
          ({
            turndownService,
            message,
            componentName,
            authStore,
            communityStore,
            router,
          }) => {
            const { t } = useTranslation();
            const { isLoggedIn, me } = authStore;

            const deleteMessage = () => {
              communityStore.deleteMessage(message);
            };

            const like = () => {
              communityStore.likeMessage(message);
            };

            return (
              <ScrollableAnchor id={`message-${message.Id}`}>
                <div
                  data-component-name={componentName}
                  className="community-board-reply"
                >
                  <div className="d-flex align-items-center">
                    <div className="d-flex">
                      <Link
                        as={routes.directory_member_by_id({
                          Id: message.CoworkerId,
                          FullName: message.CoworkerFullName,
                        })}
                        href={routes.directory_member_by_id('[member_id]')}
                      >
                        <CoworkerAvatar
                          size={80}
                          link
                          coworker_id={message.CoworkerId}
                          imgClassName="avatar avatar--40 mr-8 cp"
                          title={message.CoworkerFullName}
                        />
                      </Link>
                      <div className="d-inline-block bg-gray-100 rounded pt-8 px-12 mr-12 community-board-reply__header">
                        <div className="fs-14">
                          <Link
                            as={routes.directory_member_by_id({
                              Id: message.CoworkerId,
                              FullName: message.CoworkerFullName,
                            })}
                            href={routes.directory_member_by_id('[member_id]')}
                          >
                            <a>{message.CoworkerFullName}</a>
                          </Link>{' '}
                          <span className="d-block d-md-inline text-gray-600">
                            <time
                              className="user-badge__metadata timeago"
                              datetime={moment(message.PostedOnUtc + 'Z')
                                .locale('en')
                                .format()}
                            >
                              {moment(message.PostedOnUtc + 'Z').format('L')}{' '}
                              {moment(message.PostedOnUtc + 'Z').format('LT')}
                            </time>
                          </span>
                        </div>
                        <div className="community-board-reply__message">
                          <ReactMarkdown
                            source={turndownService.turndown(
                              message.Message.replace(
                                /(?:\r\n|\r|\n)/g,
                                '<br />'
                              )
                            )}
                          />
                        </div>
                        {message.LikedByUser && (
                          <div className="badge">
                            <i className="icon-likes"></i>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="community-board-reply__options">
                      {isLoggedIn && (
                        <>
                          <a
                            onClick={(ev) => {
                              ev.preventDefault();
                              like();
                            }}
                            href="#"
                            className="btn btn-single-icon  btn-white mr-2"
                            data-toggle="tooltip"
                            title={t(
                              `${message.LikedByUser ? 'Unlike' : 'Like'}`
                            )}
                          >
                            <i
                              className={`icon-${
                                message.LikedByUser ? 'unlike' : 'likes'
                              }`}
                            ></i>
                          </a>
                          {message.UserId == me.Id && (
                            <a
                              onClick={(ev) => {
                                ev.preventDefault();
                                deleteMessage();
                              }}
                              className="btn btn-single-icon  btn-white mr-2"
                              href="#"
                              data-toggle="tooltip"
                              title={t(`Delete`)}
                            >
                              <i className="icon-delete"></i>
                            </a>
                          )}
                          <a
                            href={`#message-${message.Id}`}
                            className="btn btn-single-icon  btn-white"
                            data-toggle="tooltip"
                            title={t(`Direct link to this message`)}
                          >
                            <i className="icon-link"></i>
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </ScrollableAnchor>
            );
          }
        )
      )
    ),
    'newCommunityBoardReply'
  )
);
