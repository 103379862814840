/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('LiquidPayPaymentButton')
@withTranslation()
@inject('appStore', 'authStore')
class LiquidPayPaymentButton extends Component {
  render() {
    const { t, invoice, componentName } = this.props;

    return (
      <form
        data-component-name={componentName}
        method="POST"
        action="https://www.liqpay.ua/api/3/checkout"
        acceptCharset="utf-8"
      >
        <input type="hidden" name="data" value={invoice.LiqdPayData} />
        <input
          type="hidden"
          name="signature"
          value={invoice.LiqdPaySignature}
        />
        <button type="submit" className="btn btn-icon btn-link text-gray-900">
          <i aria-hidden="true" className="icon-credit-card"></i>
          {t('Pay by card')}
        </button>
      </form>
    );
  }
}
export default LiquidPayPaymentButton;
