import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';

export const DiscountInfoSection = withCustomComponent('DiscountInfoSection')(
  inject(
    'appStore',
    'checkoutStore'
  )(
    withRouter(
      observer(({ componentName, checkoutStore }) => {
        const { t } = useTranslation();
        const { invoicePreview } = checkoutStore;

        return (
          <>
            {invoicePreview.Lines.filter((line) => line.DiscountCode).length > 0 &&
              <div className="alert alert-info mt-20">
                <ul className="list-group list-group-flush">
                  {invoicePreview.Lines.filter((line) => line.DiscountCode).map((line, i) => (
                    <li
                      data-component-name={componentName}
                      key={i}
                      className="list-group-item"
                    >
                      {t('Discount applied: {{Code}} (', { Code: line.DiscountCode })}
                      <b>
                        <LocalizedPrice
                          currency={invoicePreview.Currency.Code}
                          amount={line.DiscountAmount}
                        />
                      </b>
                      {')'}
                    </li>
                  )
                  )}
                </ul>
              </div>
            }
          </>
        )

      })
    )
  )
);
