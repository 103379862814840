import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { routes } from 'env/routes';
import { removeHtml } from 'env/utils/strings';
import Link from 'next/link';
import AuthenticatedLink from 'ui/components/AuthenticatedLink';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('CardPerkDiscount')
@withTranslation()
@inject('appStore', 'contentStore', 'authStore')
@observer
class CardPerkDiscount extends Component {
  static propTypes = {
    perk: PropTypes.object.isRequired,
  };

  render() {
    const { perk, t, componentName, appStore, withoutButtons } = this.props;
    const { business } = appStore;

    return (
      <div
        data-component-name={componentName}
        className={`card card-perk-discount`}
      >
        <div className="card-perk-discount__wrapper-image">
          {perk.HasImage && (
            <Link href={routes.perk_by_id()} as={routes.perk_by_id(perk)}>
              <a className="card-perk-discount__image">
                <img
                  src={`${
                    business.NativeHomeUrlWithLanguage
                  }/communityperks/GetImage?id=${
                    perk.Id
                  }&w=${200}&h=200&cache=${perk.UpdatedOn}`}
                  alt={removeHtml(perk.Title)}
                />
                <div className="badge">
                  <i className="icon-discount"></i>
                </div>
              </a>
            </Link>
          )}
        </div>
        <div className="card-perk-discount__wrapper-text">
          <div>
            <Link href={routes.perk_by_id()} as={routes.perk_by_id(perk)}>
              <a className="text-gray-900">
                <h4 className="mb-0">{perk.Title}</h4>
              </a>
            </Link>
            <p className="mt-8 mb-0 fs-14 text-gray-700">{perk.SummaryText}</p>
          </div>
          {withoutButtons == true ? (
            <></>
          ) : (
            <>
              <div className="d-flex align-items-center pt-16">
                <AuthenticatedLink
                  className="btn btn-icon"
                  href={`${business.NativeHomeUrlWithLanguage}/communityperks/claim/?pid=${perk.UniqueId}`}
                >
                  <i className="icon-external-link"></i>
                  {t('Claim')}
                </AuthenticatedLink>
                {perk.FullText && (
                  <Link href={routes.perk_by_id()} as={routes.perk_by_id(perk)}>
                    <a className="btn btn-white ml-8">{t('Read More')}</a>
                  </Link>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default CardPerkDiscount;
