import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

import CheckinWidgetSmall from 'ui/_pages/home/components/CheckinWidget/CheckinWidgetSmall';
import InvoicesWidgetSmall from 'ui/_pages/home/components/InvoicesWidget/InvoicesWidgetSmall';
import MessagesWidgetSmall from 'ui/_pages/home/components/MessagesWidget/MessagesWidgetSmall';
import EventsWidgetSmall from 'ui/_pages/home/components/EventsWidget/EventsWidgetSmall';

import BookingsWidget from 'ui/_pages/home/components/BookingsWidget/BookingsWidget';
import TeamKpiWidget from 'ui/_pages/home/components/TeamKpiWidget/TeamKpiWidget';
import TeamMetricsWidget from 'ui/_pages/home/components/TeamMetricsWidget/TeamMetricsWidget';

export const TeamDashboard = withCustomComponent('TeamDashboard')(
  inject(
    'appStore',
    'authStore'
  )(
    withRouter(
      observer(({ componentName, appStore, authStore }) => {
        const { t } = useTranslation();
        const { customer } = authStore;
        const canManageTeam = customer?.IsTeamAdministrator;

        return (
          <div data-component-name={componentName}>
            <div className="dashboard-body">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <h2 className="mb-0">{t(`Team dashboard`)}</h2>
                    <div className="text-gray-700">
                      {customer.TeamNames?.replace(',', ', ')}
                    </div>
                  </div>
                  {canManageTeam && (
                    <div className="col-lg-6 text-lg-right pt-20 pt-lg-0">
                      <Link href={routes.profile_team}>
                        <a className="btn btn-icon">
                          <i className="icon-add"></i>
                          {t(`Manage team`)}
                        </a>
                      </Link>
                    </div>
                  )}
                </div>

                {/*
                <div className="row mt-20">
                  {configuration['HomePage.ShowCheckedInMembers'] && (
                    <div className="col-md-6 col-xl-3 mt-12">
                      {<CheckinWidgetSmall />}
                    </div>
                  )}
                  {configuration['Members.ViewInvoices'] && (
                    <div className="col-md-6 col-xl-3 mt-12">
                      {<InvoicesWidgetSmall />}
                    </div>
                  )}
                  <div className="col-md-6 col-xl-3 mt-12">
                    {<MessagesWidgetSmall />}
                  </div>
                  {configuration['PublicWebSite.Events'] && (
                    <div className="col-md-6 col-xl-3 mt-12">
                      {<EventsWidgetSmall />}
                    </div>
                  )}
                </div>
                 */}

                {appStore.configuration['Members.ViewTeamBookings'] && (
                  <BookingsWidget showZeroCase={true} team={true} />
                )}

                <TeamKpiWidget />

                {!customer.TeamNames && <TeamMetricsWidget />}
              </div>
            </div>
          </div>
        );
      })
    )
  )
);
