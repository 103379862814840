import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import FullPageLayout from 'ui/layouts/FullPageLayout';
import Link from 'next/link';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import { removeHtml } from 'env/utils/strings';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { withRouter } from 'next/router';
import LoadingLayout from 'ui/layouts/LoadingLayout';

@withRouter
@withCustomComponent('VisitorTermsPage')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class VisitorTermsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasAcceptedTerms: false,
    };
  }

  async componentDidMount() {
    const { appStore, router } = this.props;
    appStore.loadVisitorTerms(router.query.guid);
  }

  acceptTerms = () => {
    const { appStore, router } = this.props;
    appStore
      .loadVisitorTermsAccept(router.query.guid)
      .then(() => {
        this.setState({ hasAcceptedTerms: true });
      })
      .catch((err) =>
        appStore.setPopMessage(
          t('Sorry, we could not load this page. {{err}}', { err })
        )
      );
  };

  render() {
    const { t, appStore } = this.props;
    const { business, configuration } = appStore;
    const {
      isLoadingAcceptTerms,
      hasLoadedVisitorTerms,
      isLoadingVisitorTermsAccept,
    } = appStore;
    const { hasAcceptedTerms } = this.state;
    return (
      <FullPageLayout
        componentName={this.props.componentName}
        title={t('Visitors Terms and Conditions')}
      >
        <div className="mw-840">
          {hasAcceptedTerms && (
            <>
              <h2 className="mt-20 mb-8">{t('Thank you!')}</h2>
              <p className="text-gray-700 mb-24">
                {t(
                  'Welcome to {{Name}}. Please follow the instructions to check in as a visitor.',
                  { Name: t(appStore.business.Name) }
                )}
              </p>
            </>
          )}

          {!hasLoadedVisitorTerms && <LoadingSpinner />}
          {!hasAcceptedTerms && hasLoadedVisitorTerms && (
            <>
              <p className="mb-20">
                {t('Please review and agree to the terms below:')}
              </p>

              <ReactMarkdown
                source={removeHtml(
                  configuration['Legal.VisitorTermsAndConditions']
                )}
              />
              <a
                disabled={isLoadingAcceptTerms}
                onClick={(ev) => {
                  if (isLoadingAcceptTerms) return;
                  ev.preventDefault();
                  this.acceptTerms();
                }}
                href="#"
                className="btn"
              >
                {isLoadingVisitorTermsAccept ? (
                  <LoadingSpinner />
                ) : (
                  t('Accept terms and conditions')
                )}
              </a>
            </>
          )}
        </div>
      </FullPageLayout>
    );
  }
}

export default VisitorTermsPage;
