import React from 'react';
import {useTranslation} from 'react-i18next';
import {withRouter} from 'next/router';
import {inject, observer} from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import PaymentHistory from 'ui/_pages/invoices/PaymentHistory';
import SpreedlyPaymentForm from 'ui/_pages/invoices/paymentForms/SpreedlyPaymentForm';
import {StripeDirectDebitPaymentForm} from 'ui/_pages/invoices/paymentForms/StripeDirectDebitPaymentForm';
import {GoCardlessPaymentForm} from 'ui/_pages/invoices/paymentForms/GoCardlessPaymentForm';
import {StripeACHPaymentForm} from 'ui/_pages/invoices/paymentForms/StripeACHPaymentForm';
import AuthenticatedLink from 'ui/components/AuthenticatedLink';
import BillingDetailsSection from 'ui/_pages/profile/BillingDetailsSection';
import StripeSvg from 'ui/_pages/invoices/paymentButtons/StripeSvg';

export const InvoicesTable = withCustomComponent('InvoicesTable')(
  inject(
    'appStore',
    'authStore',
    'invoicingStore'
  )(
    withRouter(
      observer(
        ({
           componentName,
           invoicingStore,
           appStore,
           authStore,
           showDraftInvoiceButton = false,
           showStatementButton = false,
           showPaymentHistory = true,
           showPaidInvoices = true,
           showUnpaidInvoices = true,
         }) => {
          const {t} = useTranslation();

          const {configuration, business} = appStore;
          const {customer, hasLoadedCustomer} = authStore;
          const {invoicesPage, hasLoadedInvoicesPage, unpaidInvoices} =
            invoicingStore;
          const {
            SpreedlyEnabled,
            StripeACHEnabled,
            StripeDirectDebitEnabled,
          } = invoicesPage;
          const hasLoadedData = hasLoadedCustomer && hasLoadedInvoicesPage;
          const isCorporateDashboard = appStore.corporateDashboard;

          const onPaymentComplete = () => {
            authStore.loadCustomer();
            invoicingStore.loadInvoicesPage().catch((err) => {
              appStore.setPopMessage(
                t('Sorry, we could not load your invoices. {{err}}', {err})
              );
            });
          };

          const saveCustomer = () => {
            authStore.saveCustomer().catch((err) => {
              appStore.setPopMessage(err);
            });
          };

          return (
            <div
              data-component-name={componentName}
              className="settings__content"
            >
              {!hasLoadedData && (
                <div id="profile-form">
                  <fieldset className="section--settings" id="your-plan">
                    <LoadingSpinner/>
                  </fieldset>
                </div>
              )}
              {hasLoadedData && (
                <div id="profile-form">
                  {showPaymentHistory && (
                    <PaymentHistory
                      showPaidInvoices={showPaidInvoices}
                      showUnpaidInvoices={showUnpaidInvoices}
                    />
                  )}

                  {!isCorporateDashboard && (
                    <>
                      <div id="credit-card-form">
                        <BillingDetailsSection
                          saveCustomer={saveCustomer}
                          customer={authStore.customer}
                        />

                        {configuration['Spreedly.Enabled'] == 'True' &&
                          SpreedlyEnabled && (
                            <fieldset
                              className="section--settings"
                              id="payment-information"
                            >
                              <SpreedlyPaymentForm
                                onPaymentComplete={onPaymentComplete}
                              />
                            </fieldset>
                          )}

                        {(showDraftInvoiceButton || showStatementButton) && (
                          <fieldset
                            className="section--settings"
                            id="next-invoice"
                          >
                            <div className="card-item-wrapper">
                              <div className="card-item-header">
                                <h5 className="card-item-header-title">
                                  {t('My next invoice')}
                                </h5>
                              </div>
                              <div className="card-item-body">
                                <p className="mxw-600px text-gray-700 fs-14 mb-20">
                                  {t(
                                    'Use the options below to download a document with the charges we will include in your next invoice or a summary of all your previous invoices and payments. Please note that charges for bookings may be added to your account as each booking ends, so they may not show in these documents until then.'
                                  )}
                                </p>
                                <div className="btn-group">
                                  {showDraftInvoiceButton && (
                                    <AuthenticatedLink
                                      href={`${business.NativeHomeUrlWithLanguage}/invoices/draft`}
                                      className="btn btn-icon "
                                    >
                                      <i className="icon-invoice"></i>
                                      {t('View my next invoice')}
                                    </AuthenticatedLink>
                                  )}
                                  {showStatementButton && (
                                    <AuthenticatedLink
                                      href={`${business.NativeHomeUrlWithLanguage}/invoices/statement`}
                                      className="btn btn-icon "
                                    >
                                      <i className="icon-download"></i>
                                      {t('Download my statement')}
                                    </AuthenticatedLink>
                                  )}
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        )}
                      </div>

                      {customer.EnableGoCardlessPayments && (
                        <div className="card card-dashboard mb-32">
                          <fieldset
                            className="section--settings"
                            id="gocardless"
                          >
                            <h5 className="section__title mb-16">
                              {t('GoCardless Direct Debit Payments')}
                            </h5>
                            <GoCardlessPaymentForm/>
                          </fieldset>
                        </div>
                      )}

                      {customer.StripeDirectDebitPaymentsEnabled &&
                        StripeDirectDebitEnabled && (
                          <fieldset
                            className="section--settings"
                            id="stripedirectdebit"
                          >
                            <div className="card-item-wrapper">
                              <div className="card-item-header">
                                <div>
                                  <StripeSvg/>
                                  <h5 className="card-item-header-title">
                                    {t('Stripe Direct Debit Payments')}
                                  </h5>
                                </div>
                              </div>
                              <div className="card-item-body">
                                <StripeDirectDebitPaymentForm/>
                              </div>
                            </div>
                          </fieldset>
                        )}

                      {configuration['StripeACH.Enabled'] &&
                        StripeACHEnabled && <StripeACHPaymentForm/>}

                    </>
                  )}
                </div>
              )}
            </div>
          );
        }
      )
    )
  )
);
