import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import FunnelTitle from 'ui/_pages/funnel/Title';

@withCustomComponent('FaqPage')
@withTranslation()
@inject('contentStore', 'appStore', 'authStore')
@observer
class FaqPage extends Component {
  componentDidMount() {
    const {contentStore} = this.props;
    contentStore.loadFaqArticles();
  }

  render() {
    const {t, contentStore, appStore} = this.props;
    const {business} = appStore;
    const {articlesByGroup} = contentStore;

    const excludeGroups = ['Help'];

    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={t('Frequently Asked Questions') + ' - ' + t(business.Name)}
      >
        <main className="content content--custom faq pt-0" role="main">
          <div
            className="portal-title-wrapper">
            <div className="container portal-title-container"><h1
              className="portal-title">
              {t('Frequently Asked Questions')}
            </h1>
            </div>
          </div>
          <div className="container">
            {articlesByGroup.map((group, i) => !excludeGroups.includes(group.group) && (
              <React.Fragment key={i}>
                <div className="funnel-steps-wrapper">
                  <FunnelTitle
                    title={group.group != 'null' ? t(group.group) : t('General')}
                  />
                  <div className="funnel-steps-body">
                    <div id={`accordion${group.group.split(' ').join('')}`} className="accordion">
                      {group.articles.map((article, i) => (
                        <div
                          key={i}
                          className="card"
                        >
                          <div className="card-header" id={`heading${article.Id}`}>
                            <a className="btn btn-link" data-toggle="collapse" data-target={`#collapse${article.Id}`}
                               aria-expanded="false" aria-controls={`collapse${article.Id}`}>
                              {article.Title}
                              <span className="card-header-icon"></span>
                            </a>
                          </div>
                          {article.FullText && (
                            <>
                              <div id={`collapse${article.Id}`} className="collapse"
                                   aria-labelledby="{`heading${article.Id}`}"
                                   data-parent={`#accordion${group.group}`}>
                                <div className="card-body" dangerouslySetInnerHTML={{
                                  __html: article.FullText,
                                }}>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* <hr className="mt-32 mb-24"/>
                <h5>{group.group != 'null' ? t(group.group) : t('General')}</h5>
                {group.articles.map((article) => (
                  <div className="card py-20 px-24 h-auto mb-8 mw-820">
                    <div className="d-flex">
                      {article.HasImage && (
                        <div
                          className="pr-16"
                          style={{width: 66, minWidth: 66}}
                        >
                          <img
                            className="img-fluid"
                            src={`${
                              business.NativeHomeUrlWithLanguage
                            }/faq/GetImage?id=${
                              article.Id
                            }&w=${100}&h=100&cache=${article.UpdatedOn}`}
                            alt={removeHtml(article.Title)}
                          />
                        </div>
                      )}
                      <div>
                        <div className="d-flex align-items-center">
                          <Link
                            href={routes.faq_by_id()}
                            as={routes.faq_by_id(article)}
                          >
                            <a className="mr-4 text-gray-900">
                              <strong>{article.Title}</strong>
                            </a>
                          </Link>
                          <Link
                            href={routes.faq_by_id()}
                            as={routes.faq_by_id(article)}
                          >
                            <a className="d-inline-flex align-items-center text-gray-600 tdn">
                              <i className="icon-arrow-right-small fs-24 mt-2"></i>
                            </a>
                          </Link>
                        </div>
                        <p className="fs-14 text-gray-700 pt-4 mb-0">
                          {article.SummaryText}
                        </p>
                        {article.FullText && (
                          <div className="mt-12">
                            <Link
                              href={routes.faq_by_id()}
                              as={routes.faq_by_id(article)}
                            >
                              <a className="btn btn-gray btn-sm">
                                {t('Read more')}
                              </a>
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}*/}
              </React.Fragment>
            ))}
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default FaqPage;
