import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import global from 'global';

export const CheckoutClearButton = withCustomComponent('CheckoutClearButton')(
  inject(
    'appStore',
    'checkoutStore'
  )(
    withRouter(
      observer(({ componentName, checkoutStore, appStore }) => {
        const { t } = useTranslation();
        const clearBasket = () => {
          appStore
            .setYesNotQuestion(
              appStore.corporateDashboard
                ? t('Do you want to clear all items?')
                : t('Do you want to empty your basket?'),
              appStore.corporateDashboard
                ? t(
                    'If you click Yes, all bookings and other selected items from your current session will be removed.'
                  )
                : t(
                    'If you click Yes, all items from your basket will be removed.'
                  )
            )
            .then((r) => {
              if (r) checkoutStore.clearBasket();
            });
        };

        return (
          <div data-component-name={componentName} className="mt-24">
            <a
              onClick={(ev) => {
                ev.preventDefault();
                clearBasket();
              }}
              className="btn  btn-outline btn-icon"
              href="#"
            >
              <i className="icon-close"></i>
              {appStore.corporateDashboard
                ? t('Clear all items')
                : t('Clear basket')}
            </a>
          </div>
        );
      })
    )
  )
);
