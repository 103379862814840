import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import ReactTagInput from '@pathofdev/react-tag-input';
import '@pathofdev/react-tag-input/build/index.css';
@observer
class TagsInputField extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = { touched: false };
  }

  onChange(tags) {
    this.setState({ touched: true });
    this.props.onChange(this.props.name, tags.join(','));
  }

  onFocus = () => {
    this.setState({ touched: true });
  };

  render() {
    const input = this.props;
    const hasErrors = input.errors && input.errors.length > 0;
    const { touched } = this.state;
    const tags = input.value ? input.value.split(',') : [];
    return (
      <>
        {!input.noLabel && (
          <label className="control-label" htmlFor={input.id}>
            {input.label || input.name}
          </label>
        )}

        <ReactTagInput
          className={`form-control ${hasErrors ? 'validationElement' : ''}`}
          removeOnBackspace={true}
          editable={true}
          tags={tags}
          id={input.id}
          name={input.name}
          onChange={this.onChange}
        />

        {touched && hasErrors && (
          <small className="help-block validation-error">
            {input.errors.join(', ')}
          </small>
        )}
      </>
    );
  }
}

TagsInputField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default TagsInputField;
