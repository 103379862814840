/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import withScript from 'env/utils/withScript';
import {withRouter} from 'next/router';
import {LocalizedPrice} from 'env/utils/NumbersLocalization';
import withCustomComponent from 'ui/components/withCustomComponent';
import {scrollToAnchorElement} from 'env/utils/scrolling';

@withCustomComponent('SpreedlyPaymentButton')
@withRouter
@withScript('https://core.spreedly.com/iframe/iframe-v1.min.js')
@withTranslation()
@inject('appStore', 'authStore', 'invoicingStore')
@observer
class SpreedlyPaymentButton extends Component {
  initSpreedly() {
    const {
      t,
      invoice,
      appStore,
      redirectWhenPaidSuccess,
    } = this.props;

    const {business, configuration} = appStore;
    const $ = window.$;
    const Spreedly = window.Spreedly;
    const spreedlyEnvKey = configuration['System.SpreedlyEnvironmentKey'];
    const gatewayType =
      configuration['Spreedly.DefaultPaymentGateway.PaymentGatewayType'];

    $(`#spreedlyButton-${invoice.Id}`).click(function (e) {
      var btn = $(this);
      if (btn.attr('disabled')) return;

      var description = $(this).attr('data-description');
      if (!description) {
        scrollToAnchorElement('payment-information');
        e.preventDefault();
        return;
      }

      var originalLabel = btn.html();
      var invoiceId = $(this).attr('data-invoiceid');
      window.clickedInvoice = invoiceId ?? 0;

      appStore.setYesNotQuestion(description).then(function (result) {
        if (result) {
          btn.text(t('Please wait'));
          btn.attr('disabled', 'disabled');

          var browser_size = '05';

          var acceptHeader =
            'text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8';

          let browser_info = Spreedly.ThreeDS.serialize(
            browser_size,
            acceptHeader
          );
          var use3dSecure = gatewayType != 'stripe';
          var data = use3dSecure
            ? {
              three_ds_version: 2,
              attempt_3dsecure: true,
              browser_info: browser_info,
              returnUrl: window.location.href.split('?')[0],
            }
            : {};

          function checkStatus(token) {
            fetch(
              `${business.NativeHomeUrlWithLanguage}/invoices/checkStatus?token=${token}`,
              {
                method: 'POST',
              }
            )
              .then((response) => response.json())
              .then((data) => {
                $('#challenge-modal').modal('hide');

                if (data.TransactionResult === 'Success')
                  appStore
                    .setPopMessage(t('Thank you for your payment'))
                    .then(() => {
                      if (redirectWhenPaidSuccess) {
                        location = redirectWhenPaidSuccess;
                      } else {
                        location.reload();
                      }
                    });
                else if (data.TransactionResult != 'Awaiting') {
                  appStore
                    .setPopMessage(
                      data.FailedResultReason ||
                      t('We could not process your payment')
                    )
                    .then(() => location.reload());
                }
              });
          }

          const agent = appStore.getAgent();
          agent.requests
            .post(`/invoices/trypayment/${invoiceId}`, data)
            .then((result) => {
              if (!result.WasSuccessful) {
                if (
                  result.Value &&
                  result.Value.PostParameters &&
                  result.Value.TransactionResult === 'Awaiting'
                ) {
                  //3DS1 fallback redirect URL
                  if (
                    result.Value.PostParameters.required_action == 'redirect' &&
                    result.Value.PostParameters.checkout_form
                  ) {
                    $(result.Value.PostParameters.checkout_form)
                      .appendTo('body')
                      .submit();
                    return;
                  }
                  if (
                    result.Value.PostParameters.required_action == 'redirect' &&
                    result.Value.PostParameters.checkout_url
                  ) {
                    window.location = result.Value.PostParameters.checkout_url;
                    return;
                  }

                  var token = result.Value.TransactionID;
                  var lifecycle = new Spreedly.ThreeDS.Lifecycle({
                    environmentKey: spreedlyEnvKey,
                    hiddenIframeLocation: 'device-fingerprint',
                    challengeIframeLocation: 'challenge',
                    transactionToken: token,
                  });

                  var statusUpdates = function (event) {
                    if (event.action === 'succeeded') {
                      checkStatus(token);
                    } else if (event.action === 'error') {
                      $('#challenge-modal').modal('hide');
                      checkStatus(token);
                    } else if (event.action === 'trigger-completion') {
                      var data = event.context
                        ? JSON.stringify(event.context)
                        : '';
                      fetch(
                        `${business.NativeHomeUrlWithLanguage}/invoices/complete?token=${token}&data=${data}`,
                        {
                          method: 'POST',
                        }
                      )
                        .then((response) => response.json())
                        .then((data) => {
                          data = {
                            state: data.State,
                            required_action: data.RequiredAction,
                            device_fingerprint_form: data.DeviceFingerprintForm,
                            challenge_form: data.ChallengeForm,
                            challenge_url: data.ChallengeUrl,
                            checkout_form: data.CheckoutForm,
                            checkout_url: data.CheckoutUrl,
                          };
                          if (data.state === 'succeeded') {
                            checkStatus(token);
                          }

                          if (data.state === 'gateway_processing_failed')
                            checkStatus(token);

                          if (data.state === 'pending') {
                            event.finalize(data);
                          }
                          if (
                            data.state === 'pending' &&
                            data.required_action === 'challenge'
                          ) {
                            $('#challenge-modal').modal('show');
                          }
                        });
                    }
                  };

                  Spreedly.on('3ds:status', statusUpdates);
                  lifecycle.start(result.Value.PostParameters);
                  return result;
                } else {
                  $('#challenge-modal').modal('hide');
                  btn.html(originalLabel);
                  btn.attr('disabled', null);
                  return appStore
                    .setPopMessage(result.Message)
                    .then(() => location.reload());
                }
              } else {
                return appStore
                  .setPopMessage(t('Thank you for your payment'))
                  .then(() => {
                    if (redirectWhenPaidSuccess) {
                      location = redirectWhenPaidSuccess;
                    } else {
                      location.reload();
                    }
                  });
              }
            })
            .catch((err) => {
              btn.html(originalLabel);
              btn.attr('disabled', null);
              appStore
                .setPopMessage(
                  t(
                    `Unknown error while processing your payment. Please try again.`
                  )
                )
                .then(() => location.reload());
            });
        }
      });

      return false;
    });
  }

  componentDidMount() {
    const checkSpreedly = () => {
      if (window.Spreedly) this.initSpreedly();
      else setTimeout(checkSpreedly, 500);
    };
    setTimeout(checkSpreedly, 500);
  }

  render() {
    const {t, appStore, authStore, invoice, className, label, componentName} =
      this.props;
    const {business} = appStore;
    const {customer} = authStore;
    const totalWithFees = LocalizedPrice({
      currency: invoice.TransactionCurrency.Code,
      amount:
        invoice.TransactionTotalAmount +
        (invoice.TransactionTotalAmount * business.TransactionFee) / 100,
    });

    return (
      <>
        <a
          id={`spreedlyButton-${invoice.Id}`}
          data-component-name={componentName}
          className={className ?? 'btn btn-icon btn-link text-gray-900'}
          data-description={
            customer.CardNumber != null
              ? t(
                'Pay {{totalWithFees}} for invoice #{{InvoiceNumber}} using card ending on "{{CardNumber}}"?',
                {
                  totalWithFees,
                  InvoiceNumber: invoice.InvoiceNumber,
                  CardNumber: customer.CardNumber,
                }
              )
              : ''
          }
          data-invoiceid={invoice.Id}
          href="#"
        >
          {t(label ?? 'Pay by card')}
          {business.TransactionFee > 0 && ' (' + totalWithFees + ')'}
        </a>
        <>
          {business.TransactionFee > 0 && (
            <small className="mt-0">
              {t('Card payments are subject to a {{fee}}% fee.', {
                fee: business.TransactionFee,
              })}
            </small>
          )}
        </>
      </>
    );
  }
}

export default SpreedlyPaymentButton;
