import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'next/router';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import { routes } from 'env/routes';

@withCustomComponent('GraduationModal')
@withTranslation()
@withRouter
@inject('appStore', 'coursesStore')
@observer
class GraduationModal extends Component {
  closeDialog = () => {
    this.props.coursesStore.showGraduationModal(false);
  };

  goToCourses = (e) => {
    e.preventDefault();
    this.props.coursesStore.showGraduationModal(false);
    this.props.router.push(routes.courses);
  }

  componentDidMount() {
    if (this.props.coursesStore.shouldShowGraduationModal) {
      this.showModal();
    }
  }

  showModal = () => {
    const $ = window.$;
    $('#modal-graduation').modal({
      backdrop: 'static',
      keyboard: false,
      show: true,
    });
  };

  hideModal = () => {
    const $ = window.$;
    $('#modal-graduation').modal('hide');
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.coursesStore.shouldShowGraduationModal) {
      this.showModal();
    } else {
      this.hideModal();
    }
  }

  render() {
    const { t, componentName } = this.props;

    return (
      <div
        data-component-name={componentName}
        className="modal modal-graduation"
        id="modal-graduation"
        tabIndex="-1"
        role="dialog"
      >
        <div className={`modal-dialog modal-lg modal-dialog-centered ${this.props.coursesStore.shouldShowGraduationModal}`} role="document">
          <div className="modal-content">

            <button className="close" onClick={this.closeDialog}>
              <i className="icon-close"></i>
            </button>

            <i className="icon icon-graduation text-center"></i>

            <div className="modal-header">
              <p className="text-center">{t("It's your graduation!")}</p>
            </div>

            <div className="modal-body">
              <p className="fs-16 text-center text-gray-700">
                {t("Congratulations on completing the course, if you want to keep learning check out our other courses.")}
              </p>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                className="btn btn-gray"
                data-dismiss="modal"
                onClick={this.goToCourses}
                id="close-button"
                type="button"
              >
                {t('Go to courses')}
              </button>
            </div>          

          </div>
        </div>
      </div>
    );
  }
}

export default GraduationModal;
