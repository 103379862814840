import React from 'react';
import Link from 'next/link';
import {routes} from 'env/routes';
import {useTranslation} from 'react-i18next';
import {withRouter} from 'next/router';
import {inject, observer} from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import {getErrorDescription} from '../bookings/BookingErrorCodes';
import global from 'global';

export const CheckoutButton = withCustomComponent('CheckoutButton')(
  inject(
    'appStore',
    'authStore',
    'checkoutStore',
  )(
    withRouter(
      observer(
        ({
           router,
           componentName,
           completeUrl,
           returnUrl,
           checkoutStore,
           appStore,
           authStore,
           onlyPostItems,
           onAfterCreatingInvoice,
         }) => {
          const {t} = useTranslation();
          const {
            invoicePreview,
            isLoadingInvoicePreview,
            isLoadingBasketInvoice,
          } = checkoutStore;
          const {corporateDashboard} = appStore;

          const createInvoice = () => {
            (onlyPostItems
                ? checkoutStore.loadPostItems()
                : checkoutStore.loadBasketInvoice()
            ).then((invoice) => {
              let url = returnUrl ?? completeUrl ?? routes.signup_complete;

              if (invoice?.WasSuccessful === false) {
                appStore.setPopMessage(t(invoice.Message));
              } else if (!invoice) {
                authStore.loadCustomer().then(() => {
                  checkoutStore.clearBasket();

                  if (typeof onAfterCreatingInvoice === 'function') {
                    onAfterCreatingInvoice();
                  }

                  router.push(url);
                });

                return;
              }

              if (invoice.Message) {
                appStore.setPopMessage(
                  t(
                    'Sorry, we could not process this transaction. Check the items in your basket are still available.',
                  ) +
                  '. ' +
                  invoice.Message,
                );
              } else {
                authStore.loadCustomer().then(() => {
                  checkoutStore.clearBasket();

                  if (typeof onAfterCreatingInvoice === 'function') {
                    onAfterCreatingInvoice();
                  }


                  router.push(url);
                });
              }
            }).catch((err) => {
              appStore.setPopMessage(
                t('Sorry, we could not process this transaction.'),
              );
            });
          };

          const hasError = invoicePreview && invoicePreview.Message;
          const isLoading = isLoadingInvoicePreview || isLoadingBasketInvoice;

          return (
            <div data-component-name={componentName}>
              {hasError && (
                <div className="alert alert-danger mb-24">
                  <span>
                    {getErrorDescription(invoicePreview.Message, '', global.t)}
                  </span>
                </div>
              )}

              {!hasError && (
                <div>
                  <a
                    onClick={(ev) => {
                      if (isLoading) return;
                      ev.preventDefault();
                      createInvoice();
                    }}
                    href="#"
                    className={`btn btn-lg d-flex w-100 ${
                      corporateDashboard ? '' : 'mt-8'
                    } js-checkout-purchase-button ${
                      isLoading ? 'disabled' : ''
                    }`}
                  >
                    {!hasError && isLoading ? (
                      <>
                        <LoadingSpinner/>
                      </>
                    ) : (
                      <>
                        {!onlyPostItems &&
                        invoicePreview &&
                        !invoicePreview.Draft &&
                        invoicePreview.TotalAmount > 0
                          ? !corporateDashboard
                            ? t('Continue to payment')
                            : t('Confirm')
                          : !corporateDashboard
                            ? t('Continue')
                            : t('Confirm')}
                      </>
                    )}
                  </a>
                </div>
              )}
            </div>
          );
        },
      ),
    ),
  ),
);
