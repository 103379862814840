import { observable, action, reaction, toJS, computed } from 'mobx';
import { loadDataHelper, saveDateHelper } from './storeHelpers';
import { validationContext } from 'validx';
import authStore from './authStore';
import { visitorValidationSchema } from './validationSchemas/visitorValidationSchema';
import StoreBase from './storeBase';
import { toLocalTime } from 'env/utils/dateUtil';
import moment from 'moment';
import { getShiftsFromResources } from 'env/utils/shiftsHelper';
import _ from 'lodash';
import global from 'global';
const validation = validationContext();
class BookingsStore extends StoreBase {
  constructor({ data, host } = {}) {
    super({ data, host });
    this.newVisitorValidation = validation.reset();

    this.fromTime = moment().add(1, 'hour').startOf('hour').toDate();
    this.toTime = moment(this.fromTime).clone().add(1, 'hour').toDate();

    reaction(
      () => toJS(this.newVisitor),
      (newVisitor) => {
        this.newVisitorValidation = validation
          .reset()
          .validate(newVisitor, visitorValidationSchema());
      }
    );
  }

  @observable isLoadingMyVisitors = false;
  @observable hasLoadedMyVisitors = false;
  @observable myVisitors = [];
  @action loadMyVisitors() {
    return loadDataHelper({
      store: this,
      agentKey: 'Bookings',
      key: 'MyVisitors',
      path: 'Visitors',
    });
  }

  @observable isLoadingPreviousVisitors = false;
  @observable hasLoadedPreviousVisitors = false;
  @observable previousVisitors = [];
  @action loadPreviousVisitors() {
    return loadDataHelper({
      store: this,
      agentKey: 'Bookings',
      key: 'PreviousVisitors',
      path: 'PreviousVisitors',
    });
  }

  @observable isLoadingAvailabilityAt = false;
  @observable hasLoadedAvailabilityAt = false;
  @observable availabilitiesAt = {};
  @observable availabilityAt = {};
  @action loadAvailabilityAt({
    customer,
    guid,
    startTime,
    interval = 30,
    bookingId,
  }) {
    return loadDataHelper({
      store: this,
      agentKey: 'Bookings',
      key: 'AvailabilityAt',
      params: { customer, guid, startTime, interval, bookingId },
    }).then(
      action((data) => {
        this.availabilitiesAt[guid] = data;
        return data;
      })
    );
  }

  @computed get shifts() {
    if (!this.resourceSearch) return [];
    return getShiftsFromResources(
      this.resourceSearch.Resources,
      'ShiftsExpression'
    );
  }

  @action selectDates(dates) {
    Object.keys(dates).forEach((key) => {
      this[key] = dates[key];
    });
  }

  @observable fromTime = null;
  @observable toTime = null;
  @observable shiftId;
  @action selectShift(shiftId) {
    const shift = this.shifts[shiftId];
    if (!shift) return;

    const shiftStart = shift.start.clone();
    const shiftEnd = shift.end.clone();

    const start = moment(this.fromTime).clone();
    start.set('minutes', shiftStart.get('minutes'));
    start.set('hours', shiftStart.get('hours'));

    const end = moment(this.toTime).clone();
    end.set('minutes', shiftEnd.get('minutes'));
    end.set('hours', shiftEnd.get('hours'));

    if (start > end) {
      end.add(1, 'day');
    }

    this.fromTime = start.toDate();
    this.toTime = end.toDate();
    this.shiftId = parseInt(shiftId);
  }

  getResourceColor(resourceId, hex) {
    const bookingHighlightColorArray = [
      'green',
      'yellow',
      'orange',
      'red',
      'blue',
    ];
    var colors = [
      '#28B95F',
      '#DCB40A',
      '#F0783C',
      '#14B4E6',
      '#F04B69',
      '#0064FA',
      '#5F00BA',
      '#EE589E',
    ];
    var index = this.resources.findIndex((x) => x.Id == resourceId);
    var adjustedIndex = index % bookingHighlightColorArray.length;

    return hex
      ? colors[adjustedIndex > -1 ? adjustedIndex : 0]
      : bookingHighlightColorArray[adjustedIndex > -1 ? adjustedIndex : 0];
  }

  @observable isLoadingResources = false;
  @observable hasLoadedResources = false;
  @observable resources = [];
  @observable networkResources = [];
  @action loadResources() {
    return loadDataHelper({
      store: this,
      agentKey: 'Bookings',
      key: 'Resources',
      onDataReceived: (data) => {
        this.resources = data.Resources;
        this.networkResources = data.NetworkResources;
      },
    });
  }

  @computed get resourceTypes() {
    return _(this.resources)
      .sortBy('DisplayOrder')
      .groupBy((r) => r.ResourceType.Id)
      .orderBy((resources) => resources[0].DisplayOrder)
      .map((resources) => {
        return {
          resources,
          ...resources[0].ResourceType,
        };
      })
      .value();
  }

  @computed get firstResourceType() {
    if (this.resources.length == 0) return null;
    return _(this.resources).sortBy('DisplayOrder').value()[0].ResourceType;
  }

  @computed get resourceGroups() {
    return _(this.resources)
      .groupBy((r) => r.GroupName)
      .orderBy((resources) => resources[0].GroupName)
      .map((resources, groupName) => {
        return {
          resources,
          groupName: resources[0].GroupName,
        };
      })
      .value();
  }

  @observable isLoadingResourceSearch = false;
  @observable hasLoadedResourceSearch = false;
  @observable resourceSearch = {
    Resources: [],
  };
  @action loadResourceSearch(params) {
    return loadDataHelper({
      store: this,
      agentKey: 'Bookings',
      key: 'ResourceSearch',
      params: {
        ...params,
        start: moment(this.fromTime).format('YYYY-MM-DDTHH:mm'),
        end: moment(this.toTime).format('YYYY-MM-DDTHH:mm'),
      },
    }).then(
      action((data) => {
        if (this.shifts.length > 0 && !this.shiftId)
          this.selectShift(this.shifts[0].id);
        return data;
      })
    );
  }

  @observable isLoadingBookings = false;
  @observable hasLoadedBookings = false;
  @observable bookings = [];
  @action loadBookings({
    start,
    end,
    resourceId,
    showAll,
    onlyEvents,
    resourceTypeId,
    group,
    ...rest
  }) {
    return loadDataHelper({
      store: this,
      agentKey: 'Bookings',
      key: 'Bookings',
      params: {
        start,
        end,
        resourceId,
        showAll,
        onlyEvents,
        resourceTypeId,
        group,
        ...rest,
      },
    });
  }

  @action deleteBooking(bookingId) {
    return this.getAgent().Bookings.deleteBooking(bookingId);
  }

  @action closeBooking() {
    this.booking = null;
    this.hasLoadedBooking = false;
  }

  @action closeNewBooking() {
    this.booking = null;
    this.hasLoadedBooking = false;
  }

  @action newBooking({
    fromTime,
    toTime,
    resourceId,
    floorPlanDeskId,
    deskName,
  }) {
    const now = fromTime || new Date();
    const ms = 15 * 60000;
    let roundedDate = new Date(Math.round(now.getTime() / ms) * ms);

    this.booking = {
      Id: 0,
      FromTime: roundedDate,
      ResourceId: resourceId,
      FloorPlanDeskId: floorPlanDeskId,
      DeskName: deskName,
      ResourceName:
        resourceId &&
        this.resources &&
        _.find(this.resources, (r) => r.Id == resourceId)?.Name,
      ToTime: toTime ?? new Date(roundedDate.getTime() + 60 * 60000),
      BookingVisitors: [],
      BookingProducts: [],
    };
    this.resourceProducts = [];
  }

  @observable isDeletingVisitor = false;
  @action deleteVisitor(visitor) {
    this.isDeletingVisitor = true;
    return this.getAgent()
      .Bookings.deleteVisitor(visitor)
      .finally(
        action((data) => {
          this.isDeletingVisitor = false;
          return data;
        })
      );
  }

  @observable newVisitorValidation = null;
  @observable isSavingNewVisitor = false;
  @observable newVisitor = {
    FullName: null,
    Email: null,
  };
  @action saveNewVisitor() {
    this.newVisitor = {
      ...this.newVisitor,
      ExpectedArrival: toLocalTime(this.newVisitor.ExpectedArrival),
    };
    return saveDateHelper({
      store: this,
      agentKey: 'Bookings',
      key: `NewVisitor`,
      validation: validation,
      validationSchema: visitorValidationSchema(),
    }).then(
      action((data) => {
        if (data.ErrorMessage) throw new Error(data.ErrorMessage);
        this.newVisitorValidation = validation.reset();
        return data;
      })
    );
  }

  @observable isSavingBookingToCreate = false;
  @observable isSavingBookingToUpdate = false;
  @action saveBooking() {
    const bookingData = getBookingToSubmit(this.booking, this.resourceProducts);
    const agentAction = bookingData.booking.Id > 0 ? 'Update' : 'Create';
    this[`bookingTo${agentAction}`] = bookingData;
    return saveDateHelper({
      store: this,
      agentKey: 'Bookings',
      key: `BookingTo${agentAction}`,
    });
  }

  @action loadBasketBooking(booking) {
    this.booking = booking;
    this.booking.FromTime = new Date(this.booking.FromTime);
    this.booking.ToTime = new Date(this.booking.ToTime);
    this.loadResourceProducts({
      bookingId: 0,
      resourceId: booking.ResourceId,
    }).then(action(() => this.loadBookingPrice()));
  }

  @observable isLoadingBookingPrice = false;
  @observable hasLoadedBookingPrice = false;
  @observable bookingPrice = [];
  loadBookingPrice = _.debounce(this.loadBookingPriceDebounced, 1000);

  @action loadBookingPriceDebounced(booking) {
    this.bookingPrice = null;
    const bookingToProcess = booking ?? this.booking;
    if (!bookingToProcess) return Promise.resolve();
    return loadDataHelper({
      store: this,
      agentKey: 'Bookings',
      key: 'BookingPrice',
      params: getBookingToSubmit(bookingToProcess, this.resourceProducts),
    });
  }

  @action resetBookingPrice() {
    this.hasLoadedBookingPrice = false;
  }

  @observable isLoadingBooking = false;
  @observable hasLoadedBooking = false;
  @observable booking = null;
  @action loadBooking(bookingId) {
    this.booking = null;
    return loadDataHelper({
      store: this,
      agentKey: 'Bookings',
      key: 'Booking',
      path: 'Value',
      params: bookingId,
    })
      .then(
        action((data) => {
          if (!data) return;

          const fromDate = new Date(data.FromTime);
          const toDate = new Date(data.ToTime);
          const fromHours = fromDate.getHours();
          const fromOffset = fromDate.getTimezoneOffset() / 60.0;
          const toHours = toDate.getHours();
          const toOffset = toDate.getTimezoneOffset() / 60.0;
          fromDate.setHours(fromHours + fromOffset);
          toDate.setHours(toHours + toOffset);

          data.FromTime = fromDate;
          data.ToTime = toDate;
          data.BookingProducts = data.BookingProducts || [];
          data.BookingVisitors = data.BookingVisitors || [];

          this.booking = data;
          return data;
        })
      )
      .then(
        action((data) => {
          if (!this.booking) return;
          this.loadBookingPrice(this.booking);
          return this.loadResourceProducts({
            bookingId: this.booking.Id,
            resourceId: this.booking.ResourceId,
          });
        })
      );
  }

  @observable isLoadingResourceProducts = false;
  @observable hasLoadedResourceProducts = false;
  @observable resourceProducts = [];
  @action loadResourceProducts({ bookingId, resourceId }) {
    this.resourceProducts = [];
    return loadDataHelper({
      store: this,
      agentKey: 'Bookings',
      key: 'ResourceProducts',
      params: { bookingId, resourceId },
    }).then(
      action((data) => {
        if (this.booking && this.booking.ResourceProducts) {
          for (let index = 0; index < data.length; index++) {
            const product = data[index];
            var foundInBooking = this.booking.ResourceProducts.filter(
              (bp) => bp.ProductId == product.ProductId
            );

            if (foundInBooking.length > 0) {
              product.Quantity = foundInBooking[0].Quantity;
              product.Selected = product.Quantity > 0;
            }
          }

          this.resourceProducts = data;
        }
        return data;
      })
    );
  }

  @observable isLoadingUpcomingBookings = false;
  @observable hasLoadedUpcomingBookings = false;
  @observable upcomingBookings = [];
  @action loadUpcomingBookings() {
    return loadDataHelper({
      store: this,
      agentKey: 'Bookings',
      key: 'UpcomingBookings',
      path: 'MyBookings',
    });
  }

  @observable isLoadingTeamBookings = false;
  @observable hasLoadedTeamBookings = false;
  @observable teamBookings = [];
  @action loadTeamBookings() {
    return loadDataHelper({
      store: this,
      agentKey: 'Bookings',
      key: 'TeamBookings',
      path: 'TeamBookings',
    });
  }
}

export const getBookingToSubmit = (booking, resourceProducts = []) => {
  return {
    booking: {
      ...booking,
      FromTime: toLocalTime(booking.FromTime),
      ToTime: toLocalTime(booking.ToTime),
      resource: {
        Id: booking.ResourceId,
      },
      floorPlanDesk: {
        Id: booking.FloorPlanDeskId,
      },
    },
    products: resourceProducts,
    coworker: authStore.customer,
  };
};

export default BookingsStore;
