import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import moment from 'moment';
import { PlanFrequencyLabel } from 'ui/_pages/plan/PlanFrequencyLabel';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import i18next from 'i18next';
import ResponsiveTable from 'ui/components/tables/ResponsiveTable';
import ContractDetails from 'ui/_pages/plan/ContractDetails';

export const ProposalDetailsTable = withCustomComponent('ProposalDetailsTable')(
  inject(
    'appStore',
    'invoicingStore'
  )(
    withRouter(
      observer(({ router, invoicingStore, appStore }) => {
        const { t } = useTranslation();

        const { business } = appStore;
        const { proposalView, isLoadingProposalView } = invoicingStore;
        const proposal = proposalView?.Proposal;
        if (!proposal || isLoadingProposalView) return <LoadingLayout />;
        let oneOffTotal = 0;
        let recurringTotal = 0;

        if (proposal.Products) {
          oneOffTotal = proposal.Products.reduce(
            (acc, product) =>
              (acc += product.RegularCharge ? 0 : product.TotalPrice),
            0
          );
          recurringTotal = proposal.Products.reduce(
            (acc, product) =>
              (acc += product.RegularCharge ? product.TotalPrice : 0),
            0
          );
        }

        return (
          <>
            {proposal.Contracts.map((contract, i) => {
              oneOffTotal += contract.SignupTotal;
              recurringTotal += contract.RecurringTotal;

              return (
                <>
                  <fieldset>
                    <h5 className="">{t('Your plan')}</h5>
                    <div className="card p-16 p-md-24">
                      <div className="row">
                        <div className="col-md-6">
                          <h5>{contract.Tariff.Name}</h5>
                          <h3 className="mb-0">
                            <>
                              {LocalizedPrice({
                                currency: contract.Tariff.Currency.Code,
                                amount: contract.RecurringTotal,
                              })}
                            </>
                          </h3>
                          <p className="text-gray-700">
                            {contract.Tariff.InvoiceInMonths && (
                              <>
                                {t('every {{InvoicePeriod}} month', {
                                  InvoicePeriod: contract.Tariff.InvoicePeriod,
                                  count: contract.Tariff.InvoicePeriod,
                                })}
                              </>
                            )}
                            {!contract.Tariff.InvoiceInMonths && (
                              <>
                                {t('every {{InvoicePeriod}} week', {
                                  InvoicePeriod: contract.Tariff.InvoicePeriod,
                                  count: contract.Tariff.InvoicePeriod,
                                })}
                              </>
                            )}
                          </p>

                          <ul className="mb-0 fs-12 text-gray-600 list-unstyled fw-500">
                            {contract.StartDate && (
                              <li>
                                {t('Start date:')}{' '}
                                <span className="text-gray-900">
                                  {contract.StartDate
                                    ? moment(contract.StartDate).format('LL')
                                    : ' - '}
                                </span>
                              </li>
                            )}
                            {contract.CancellationDate && (
                              <li>
                                {t('End date:')}{' '}
                                <span className="text-gray-900">
                                  {contract.CancellationDate
                                    ? moment(contract.CancellationDate).format(
                                        'LL'
                                      )
                                    : ' - '}
                                </span>
                              </li>
                            )}
                            {contract.BillingDay && (
                              <li>
                                {t('Billing day:')}{' '}
                                <span className="text-gray-900">
                                  {contract.BillingDay}
                                </span>
                              </li>
                            )}
                            {contract.Desks && (
                              <li>
                                {t('Offices / Desks:')}{' '}
                                <span className="text-gray-900">
                                  {contract.Desks ?? ' - '}
                                </span>
                              </li>
                            )}
                          </ul>
                        </div>
                        <div
                          className="col-md-6 fs-14 text-gray-700 pt-16 pt-md-0"
                          dangerouslySetInnerHTML={{
                            __html: contract.Tariff.Description,
                          }}
                        ></div>
                      </div>
                    </div>
                  </fieldset>

                  {(contract.Tariff.Products.length > 0 ||
                    contract.Tariff.SignupProducts.length > 0 ||
                    proposal.Products.length > 0) && (
                    <fieldset className="pb-0">
                      {contract.Tariff.Products.length > 0 && (
                        <>
                          <h5 className="mb-12 mb-md-0">
                            {t('Recurring products (paid with plan)')}
                          </h5>
                          <ResponsiveTable className="mb-24">
                            <thead>
                              <tr>
                                <th className="w-100 w-md-60">{t('Item')}</th>
                                <th className="w-100 w-md-20">
                                  {t('Quantity')}
                                </th>
                                <th className="w-100 w-md-20 text-left text-md-right">
                                  {t('Price')}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {contract.Tariff.Products.map((product, i) => (
                                <tr>
                                  <td>{product.Name}</td>
                                  <td>{contract.Quantity}</td>
                                  <td className="text-left text-md-right">
                                    {LocalizedPrice({
                                      lang: i18next.language,
                                      currency: product.ProductCurrencyCode,
                                      amount: product.Price,
                                    })}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </ResponsiveTable>
                        </>
                      )}
                      {contract.Tariff.SignupProducts.length > 0 && (
                        <>
                          <h5 className="mb-12 mb-md-0">
                            {t('One-off products')}
                          </h5>
                          <ResponsiveTable className="mb-24">
                            <thead>
                              <tr>
                                <th className="w-100 w-md-60">{t('Item')}</th>
                                <th className="w-100 w-md-20">
                                  {t('Quantity')}
                                </th>
                                <th className="w-100 w-md-20 text-left text-md-right">
                                  {t('Price')}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {contract.Tariff.SignupProducts.map(
                                (product, i) => (
                                  <tr>
                                    <td>{product.Name}</td>
                                    <td>{contract.Quantity}</td>
                                    <td className="text-left text-md-right">
                                      {LocalizedPrice({
                                        lang: i18next.language,
                                        currency: product.ProductCurrencyCode,
                                        amount: product.Price,
                                      })}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </ResponsiveTable>
                        </>
                      )}
                      {proposal.Products.length > 0 && (
                        <>
                          <h5 className="mb-12 mb-md-0">
                            {t('Additional products')}
                          </h5>
                          <ResponsiveTable className="mb-24">
                            <thead>
                              <tr>
                                <th className="w-100 w-md-60">{t('Item')}</th>
                                <th className="w-100 w-md-20">
                                  {t('Quantity')}
                                </th>
                                <th className="w-100 w-md-20 text-left text-md-right">
                                  {t('Price')}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {proposal.Products.map((line, i) => (
                                <tr>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <span>{line.Product.Name}</span>
                                      {line.RegularCharge ? (
                                        <small className="mt-0 pl-4">
                                          {'- '}
                                          {t('Recurrent charge')}
                                        </small>
                                      ) : (
                                        <small className="mt-0 pl-4">
                                          {'- '}
                                          {t('One-off charge')}
                                        </small>
                                      )}
                                    </div>
                                  </td>
                                  <td>{line.Quantity}</td>
                                  <td className="text-left text-md-right">
                                    {LocalizedPrice({
                                      lang: i18next.language,
                                      currency:
                                        line.Product.ProductCurrencyCode,
                                      amount: line.TotalPrice,
                                    })}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </ResponsiveTable>
                        </>
                      )}
                    </fieldset>
                  )}

                  <ResponsiveTable striped={false}>
                    <tbody>
                      <tr>
                        <td className="pl-0">
                          <h6 className="mb-0">{t('First payment total: ')}</h6>
                        </td>
                        <td className="text-left text-md-right pr-0">
                          <h4 className="mb-0">
                            <LocalizedPrice
                              amount={oneOffTotal}
                              currency={business.Currency.Code}
                            />
                          </h4>
                        </td>
                      </tr>
                      <tr>
                        <td className="pt-0 pl-0">
                          <h6 className="mb-0">
                            {t('Recurring payment total: ')}
                          </h6>
                        </td>
                        <td className="text-left text-md-right pt-0 pr-0">
                          <h4 className="mb-0">
                            <LocalizedPrice
                              amount={recurringTotal}
                              currency={business.Currency.Code}
                            />
                          </h4>
                        </td>
                      </tr>
                    </tbody>
                  </ResponsiveTable>
                </>
              );
            })}
          </>
        );
      })
    )
  )
);
