export const scrollToAnchorElement = (anchorId, noAnimation) => {
  const $ = window.$;

  const el = document.getElementById(anchorId);

  if (el) {
    el.scrollIntoView({
      behavior: noAnimation ? 'auto' : 'smooth',
      block: 'start',
    });
  }
};
