import React from 'react';
import {routes} from 'env/routes';
import {useTranslation} from 'react-i18next';
import {withRouter} from 'next/router';
import {inject, observer} from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import moment from 'moment';
import * as prices from 'env/utils/prices';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import RemoveFromBasket from 'ui/_pages/checkout/basketItems/RemoveFromBasket';
import EditBasketItem from 'ui/_pages/checkout/basketItems/EditBasketItem';

let ContractBasketItem = props => {
  const {
    router,
    basketItem,
    checkoutStore,
  } = props;
  const {t} = useTranslation();
  const {data} = basketItem;
  const {invoicePreview} = checkoutStore;

  const editContract = () => {
    /*const url =
      router.pathname == routes.signup_summary
        ? routes.signup_plan
        : routes.plan;*/
    router.push(routes.plans);
  };

 /* if (!invoicePreview) {
    return <LoadingLayout/>;
  }*/

  let contractLines = [];

  if (invoicePreview) {
    contractLines = invoicePreview.Lines ? invoicePreview.Lines.filter(i => i.Quantity > 0) : [];
  }

  return (
    <>
      <div className="card-item-wrapper card-basket-item p-0">
        <div className="card-item-header px-20">
          <h1 className="card-item-header-title">
            {data.tariff.Name}
          </h1>
          <div className="text-right">
            <RemoveFromBasket
              basketItem={basketItem}
            />
            <EditBasketItem
              onEdit={editContract}
            />
          </div>
        </div>
        <div className="card-item-body p-20">
          <div className="row">
            <div className="col-md-5 col-lg-3 col-xl-2">
              <div className="mb-20">
                <img className="img-fluid"
                     src={data.tariff.CfImage_1}
                     alt=""/>
              </div>
            </div>
            <div className="col-lg-9 col-xl-10">
              <div className="row">
                <div className="col-md-12 col-lg-6 col-xl-5 mb-16 mb-lg-0">
                  <div className="mb-4">
                    <span>{t('Rental start')}: </span>
                    <span className="text-secondary">
                      {moment(data.startDate).format('D MMMM YYYY')}
                      </span>
                  </div>
                  {contractLines && contractLines.length > 0 && (<div className="mb-4">
                    <span>{t('Minimum months for trial')}: </span>
                    <span className="text-secondary">
                        {contractLines.length} {t('Months')}
                      </span>
                  </div>)}
                  {contractLines && contractLines.length > 1 && (<div className="mb-4">
                    <span>{t('Next period')}: </span>
                    <span className="text-secondary">
                      {moment(contractLines[1].SaleDate).format('D MMMM')}
                    </span>
                  </div>)}
                </div>
                <div className="col-md-12 col-lg-6 col-xl-6 ml-auto">
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                      {t('Monthly rent')}
                    </div>
                    <div>
                      {prices.format(prices.excludeTax(data.tariff.Price, data.tariff.TaxRate))}
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                      {t('VAT')}
                    </div>
                    <div>
                      {prices.format(prices.retrieveTax(data.tariff.Price, data.tariff.TaxRate),
                        {currencyCode: false})}
                    </div>
                  </div>
                  <hr className="my-16"/>
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                      {t('Monthly rent total')}
                    </div>
                    <div className="text-right">
                      <b>{prices.format(data.tariff.Price)}</b>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mt-16 mb-20"/>
              <div className="row">
                <div className="col-lg-6 col-xl-6 ml-auto">
                  {contractLines && contractLines.length > 0 && (<div className="d-flex align-items-center justify-content-between mb-0">
                    <div className="funnel-steps-price-total-title">
                      {t('Total amount (for ')}{contractLines.length} {t('months)')}
                    </div>
                    <div className="color funnel-steps-price-total text-right">
                      <strong>
                        {prices.format(invoicePreview.TotalAmount)}
                      </strong>
                    </div>
                  </div>)}
                  {/*<div>
                      <small>
                        <PlanFrequencyLabel service={data.tariff}/>
                      </small>
                    </div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<BasketItem
        basketItem={basketItem}
        onEdit={editContract}
        id={data.tariff.Id}
        imageUrl={`${business.NativeHomeUrlWithLanguage}/business/getlogo?w=96&h=96&mode=pad`}
        header={data.tariff.Name}
        times={
          <>
            {t('Starting on: {{startDate}}', {
              startDate: moment(data.startDate).format('LL'),
            })}
          </>
        }
        options={
          data.tariff.SignupProducts.length > 0 && (
            <ul className="contract-options">
              {data.tariff.SignupProducts.map((product, i) => (
                <li>
                      <span>
                        {product.Name}
                        {' - '}
                        &nbsp;
                      </span>

                  <span>
                        (
                    {product.Recurrent && (
                      <PlanFrequencyLabel service={data.tariff}/>
                    )}
                    {!product.Recurrent && t('One off')})
                      </span>
                  {':'}
                  {
                    <span>
                          &nbsp;
                      <LocalizedPrice
                        currency={product.ProductCurrencyCode}
                        amount={product.Price}
                      />
                        </span>
                  }
                </li>
              ))}
            </ul>
          )
        }
        total={
          <>
            <LocalizedPrice
              currency={data.tariff.Currency.Code}
              amount={data.tariff.PriceDecimal}
            />
            <small>
              <PlanFrequencyLabel service={data.tariff}/>
            </small>
          </>
        }
      />*/}
    </>
  );
};

export default ContractBasketItem = withCustomComponent('ContractBasketItem')(
  inject('checkoutStore')(
    withRouter(
      observer(ContractBasketItem),
    ),
  ),
);
