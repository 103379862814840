import React from 'react';
import App from 'next/app';
import Head from 'next/head';
import {Provider} from 'mobx-react';
import initializeStore from 'env/stores/stores';
import {configure} from 'mobx';
import config from 'env/config';
import 'ui/assets/fonts/font-awesome.min.css';
import 'ui/assets/fonts/Icomoon/mobile/style.css';
import 'ui/assets/fonts/Icomoon/desktop/style.css';
import 'prismjs/themes/prism-tomorrow.css';
import 'ui/assets/scss/styles.scss';
import PopUpMessageModal from 'ui/components/PopUpMessageModal';
import YesNoModalDialog from 'ui/components/YesNoModalDialog';
import MobxApp from 'ui/_pages/MobxApp';
import {handServerRequest, isServer} from 'env/ssr/ServerSideRenderManager';
import {configureAnchors} from 'react-scrollable-anchor';
import {DefaultHead} from 'ui/components/DefaultHead';
import {CustomHtmlHead} from 'ui/components/CustomHeader';
import moment from 'moment';
import {Localization} from 'react-widgets';
import MomentLocalizer from 'react-widgets-moment';
import TurndownService from 'turndown';
import _ from 'lodash';
import {whiteLabelCss} from 'ui/assets/css/whitelabel';
import i18n from 'i18next';
import {addTagManagerEvent, initGoogleTagManager} from 'env/TagManager';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

//Disable markdown escaping in Turndown.
TurndownService.prototype.escape = (x) => x;

//Fix for full calendar ssr
if (typeof Element === 'undefined') {
  global.Element = function() {
  };
}

if (!isServer()) {
  require('env/i18n');
  window.$ = window.jQuery = require('jquery');
  require('bootstrap/dist/js/bootstrap.bundle.js');
  require('public/js/bootstrap-tagsinput-0.8.0.js')
  configureAnchors({offset: -100, scrollDuration: 200});
}

configure({
  enforceActions: 'observed', //strict mode
});

//react components localiser
const momentLocalizer = new MomentLocalizer(moment);

class CustomApp extends App {
  //This method runs on the server when loading any page
  //and on the client when navigating between pages.
  //It creates the stores and serializes its properties in the server
  //to achieve Server Side Rendering and then pass
  //pass store data on to the client so stores can be hydrated as needed.
  static async getInitialProps(appContext) {
    const host = appContext.ctx.req && appContext.ctx.req.headers.host;

    const mobxStore = initializeStore({host});

    if (!mobxStore.appStore.hasLoadedConfiguration) {
      await mobxStore.appStore.loadConfiguration();
    }

    if (!mobxStore.appStore.hasLoadedCustomPages) {
      const pages = await mobxStore.appStore.loadCustomPages();
      const jsxPages = pages.filter((p) => p.Name.indexOf('.jsx') > -1);

      const fetchFuncs = jsxPages.map((p) => async () => {
        const jsxPage = p;
        const pagesHost = config.apiRoot ?? `https://${host}`;
        const url = `${pagesHost}/en/page/${jsxPage.Name}?raw=true`;
        const res = await fetch(url);

        const source = await res.text();
        jsxPage.source = source;
      });

      while (fetchFuncs.length) {
        await Promise.all(fetchFuncs.splice(0, 25).map((f) => f()));
      }

      mobxStore.appStore.setCustomPages([...pages]);
    }

    if (!mobxStore.appStore.hasLoadedBusiness)
      await mobxStore.appStore.loadBusiness();

    if (isServer()) await handServerRequest({appContext, mobxStore});

    if (!isServer()) {
      addTagManagerEvent({
        name: 'pageview',
        route: appContext.ctx.pathname,
        customer: mobxStore.authStore.customer,
      });
    }

    const appProps = await App.getInitialProps(appContext);
    return {
      ...appProps,
      mobxStore: mobxStore,
    };
  }

  constructor(props) {
    super(props);

    this.mobxStore = isServer()
      ? props.mobxStore
      : initializeStore({stores: props.mobxStore});

    this.state = {
      babelReady: isServer(),
    };
  }

  async componentDidMount() {
    // Add Babel standalone
    // We load Babel here and not in Head so we can
    // attach the load event handler. Babel must be loaded before
    // any components are rendered in case they have been customized
    // and need to be transformed by babel

    if (!isServer()) {
      const isLoginPage =
        document.location.pathname.toLowerCase().indexOf('/login') != -1;
      if (!isLoginPage && this.mobxStore.authStore.accessToken) {
        const user = await this.mobxStore.authStore.loadMe();
        if (user) {
          //Load customer details
          await this.mobxStore.authStore.loadCustomer();

          //Set current language
          await this.mobxStore.authStore.selectLanguage(i18n.language ?? 'en');

          //SignalR scripts
          const jquerySignalR = document.createElement('script');
          jquerySignalR.src = `/js/jquery.signalR-2.4.3.min.js`;

          jquerySignalR.addEventListener('load', () => {
            const signalHubs = document.createElement('script');
            signalHubs.src = `${config.platformRoot}/signalr/hubs`;

            signalHubs.addEventListener('load', () => {
              const signalR = document.createElement('script');
              signalR.src = '/js/signalr.js?v=5';
              signalR.addEventListener('load', () => {
                const getAccessToken = () => {
                  return this.mobxStore.authStore.loadMe().then((user) => user);
                };
                var manager = new PushNotificationsManager(getAccessToken);
                manager.initPushNotifications(config.platformRoot);
              });
              document.body.appendChild(signalR);
            });

            document.body.appendChild(signalHubs);
          });

          document.body.appendChild(jquerySignalR);
        }
      }

      //Google tag manager
      initGoogleTagManager(
        this.mobxStore.appStore.configuration['Google.Analytics'],
      );

      const script = document.createElement('script');
      script.src = 'https://unpkg.com/@babel/standalone@7.10.2/babel.min.js';
      script.addEventListener('load', () => {
        this.setState({babelReady: true});
      });
      document.body.appendChild(script);

      window.nexudus = this.mobxStore;
      if (window.app) window.app();
      if (window.componentDidMount) window.componentDidMount();

      if (window.parent !== window) {
        document.documentElement.classList.add('inside-iframe');
      }

      if (
        cookies.get('in-ms-teams') == 'true' ||
        window.location.search?.indexOf('in-ms-teams=true') > -1
      ) {
        document.documentElement.classList.add('hide-main-menu');
        document.documentElement.classList.add('in-ms-teams');
        cookies.set('in-ms-teams', 'true');
      }

      if (window.location.search?.indexOf('hide-main-menu=true') > -1) {
        document.documentElement.classList.add('hide-main-menu');
      }
    }
  }

  render() {
    const {Component, pageProps} = this.props;
    const {appStore} = this.mobxStore;
    const {configuration} = appStore;
    const primaryColor = configuration['PrimaryWebColor'];

    const customHead = eval(configuration['Website.CustomHead']) ?? [];

    if (!isServer()) {
      document.documentElement.lang = i18n.language?.toLowerCase() || 'en';
      const rtl = ['ar', 'he'].indexOf(document.documentElement.lang) > -1;
      document.body.dir = rtl ? 'rtl' : 'ltr';
      if (rtl) {
        customHead.push({
          type: 'link',
          rel: 'stylesheet',
          href: '//cdn.rawgit.com/morteza/bootstrap-rtl/v3.3.4/dist/css/bootstrap-rtl.min.css',
        });
      }
    }

    const headCode = (
      <>
        <style type="text/css">
          {whiteLabelCss({configuration, primaryColor, appStore})}
        </style>
        {customHead?.filter((t) => t.type == 'script').map((s, i) => (
          <script key={i} {...{...s, type: undefined}}></script>
        ))}
        {customHead?.filter((t) => t.type == 'meta').map((m, i) => (
          <meta key={i} {...{...m, type: undefined}} />
        ))}
        {customHead?.filter((t) => t.type == 'link').map((l, i) => (
          <link key={i} {...{...l, type: undefined}} />
        ))}
        <script
          dangerouslySetInnerHTML={{
            __html: `${configuration['Website.CustomJS']}`,
          }}
        />
      </>
    );

    const head = (
      <Head>
        {headCode}
        <script defer src="https://www.google.com/recaptcha/api.js"></script>
        {/*<script
          defer
          src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-tagsinput/0.8.0/bootstrap-tagsinput.js"
        ></script>*/}
        <script
          defer
          type="text/javascript"
          src="https://js.stripe.com/v3/"
        ></script>
        <script
          defer
          src="https://code.archilogic.com/fpe-sdk/v3.1.x/fpe.js"
        ></script>
        <link
          rel="stylesheet"
          media=""
          href="https://code.archilogic.com/fpe-sdk/v3.1.x/fpe.css"
          type="text/css"
        ></link>
        <link
          rel="preload"
          href="https://code.archilogic.com/fpe-sdk/v3.1.x/fpe.css"
          as="style"
          onload="this.onload=null;this.rel='stylesheet'"
        />
        <link
          rel="preload"
          href="https://cdnjs.cloudflare.com/ajax/libs/froala-editor/3.2.6/css/froala_style.min.css"
          as="style"
          onload="this.onload=null;this.rel='stylesheet'"
        />
      </Head>
    );

    return (
      <React.StrictMode>
        <Provider {...this.mobxStore}>
          <DefaultHead appStore={this.mobxStore.appStore}/>
          {head}
          <CustomHtmlHead appStore={this.mobxStore.appStore}/>

          {this.state.babelReady && (
            <MobxApp>
              <PopUpMessageModal/>
              <YesNoModalDialog/>
              <Localization date={momentLocalizer}>
                <Component {...pageProps} />
              </Localization>
            </MobxApp>
          )}
        </Provider>
      </React.StrictMode>
    );
  }
}

CustomApp.ComponentsCache = {};

export default CustomApp;
