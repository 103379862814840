import React, { useState } from 'react';
import LoadingSpinner from './LoadingSpinner';

const Accordion = ({children, id, heading, actionButton, showDividerTop = true, showDividerBottom = true, onClick, className = ''}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`accordion ${className}`}>

      {showDividerTop && <hr/>}

      <div
        className='accordion__heading d-flex justify-content-between align-items-center'
        id={`heading-${id}`}
        onClick={() => {
          $(`#${id}`).toggle(200);
          $(`#heading-${id} .icon`).toggleClass('upside-down');
          if(onClick) {
            setIsLoading(true);
            setIsOpen(!isOpen);
            
            Promise.resolve(onClick()).then(() => {
              setIsLoading(false);
            });
          }
        }}
      >
        <div>
          <span className="mr-12" aria-expanded="true" aria-controls={id}>
            {heading}
          </span>

          {actionButton}
        </div>

        <i className="icon icon-dropdown-large"></i>
      </div>

      <div id={id} className="collapse" aria-labelledby={`heading-${id}`}>
        {!isLoading && children}
        {isLoading && <LoadingSpinner className="mb-20"/>}
      </div>

      {showDividerBottom && <hr className={isOpen ? 'mt-20' : ''}/>}

    </div>
  );
}

export default Accordion;