import React from 'react';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import Head from 'next/head';
import { removeHtml, shorten } from 'env/utils/strings';

export const LessonMetaTags = withCustomComponent('LessonMetaTags')(
  inject(
    'appStore',
    'coursesStore'
  )(
    withRouter(
      observer(({ appStore, coursesStore, componentName }) => {
        const { t } = useTranslation();
        const { business, configuration } = appStore;
        const lesson = coursesStore.lessonPage.Lesson;

        return (
          <Head>
            <meta name="twitter:card" content="summary" />
            <meta
              name="twitter:site"
              content={`@${configuration['Social.Twitter']}`}
            />
            <meta
              name="twitter:title"
              content={`${shorten(removeHtml(lesson?.Title), 70)}`}
            />
            <meta
              name="twitter:description"
              content={`${shorten(removeHtml(lesson?.SummaryText), 200)}`}
            />
            <meta
              name="twitter:creator"
              content={`@${configuration['Social.Twitter']}`}
            />

            <meta
              property="og:url"
              key="og:url"
              content={`${business.HomeUrl}/${routes.course_by_id(
                lesson
              )}?public`}
            />
            <meta
              property="og:title"
              key="og:title"
              content={removeHtml(lesson?.Title)}
            />
            {lesson?.ImageFileName && (
              <meta
                property="og:image"
                key="og:image"
                content={`${business.NativeHomeUrlWithLanguage}/learn/getcourselessonimage?id=${lesson.Id}&w=600h=314&mode=Pad`}
              />
            )}
            <meta
              property="og:image:type"
              content="image/jpeg"
              key="og:image:type"
              content="image/jpeg"
            />
            <meta
              property="og:description"
              key="og:description"
              content={removeHtml(lesson?.SummaryText)}
            />
            <meta
              property="og:site_name"
              key="og:site_name"
              content={business.Name}
            />

            {/* this is needed for every lesson page if there's a video */}
            <script
              src="https://www.youtube.com/iframe_api"
              id="yt-iframe-api"
            ></script>
          </Head>
        );
      })
    )
  )
);
