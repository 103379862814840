import React, { Component, createRef } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';

import { MentionsInput, Mention } from 'react-mentions';
import defaultStyle from 'ui/components/forms/NxMentionsInput/defaultStyle';
import defaultMentionStyle from 'ui/components/forms/NxMentionsInput/defaultMentionStyle';
import MarkdownInputField from '../MarkdownInputField';
import ReactMde from 'react-mde';

@inject('appStore', 'communityStore')
@observer
class NxMentionsInput extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.inputRef = createRef();
    this.state = {
      users: [],
    };
  }

  componentDidMount() {
    const { communityStore } = this.props;
    if (!communityStore.hasLoadedSimpleSearch) {
      communityStore
        .loadSimpleSearch()
        .then((res) =>
          res.map((user) => ({
            display: user.FullName,
            id: user.Id,
          }))
        )
        .then((res) => this.setState({ users: res }));
    } else {
      this.setState({
        users: communityStore.simpleSearch.map((user) => ({
          display: user.FullName,
          id: user.Id,
        })),
      });
    }
  }

  onChange(data) {
    const value = data.target.value;
    this.props.onChange(this.props.name, value.length > 0 ? value : null);
  }

  renderSuggestion = (entry, search, highlightedDisplay, index, focused) => {
    const { appStore } = this.props;
    const { business } = appStore;
    return (
      <div>
        <img
          style={defaultStyle.suggestions.img}
          src={`${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${entry.id}?h=32&w=32&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}
          alt=""
        />
        <span>{entry.display}</span>
      </div>
    );
  };

  addToTextArea = (text) => {
    const el = this.inputRef.current;
    const [start, end] = [el.selectionStart, el.selectionEnd];
    const finalText = text.replace('{text}', el.value.substring(start, end));
    el.setRangeText(finalText, start, end, 'end');

    this.props.onChange(this.props.name, el.value);
    el.focus();
  };

  render() {
    const input = this.props;
    const hasErrors = input.errors && input.errors.length > 0;

    const header = (
      <div className="mde-header" style={{ border: 'none', background: 'none' }}>
        <ul className="mde-header-group">
          <li className="mde-header-item">
            <button
              type="button"
              tabIndex="-1"
              aria-label="Add header"
              onClick={() => this.addToTextArea('##### {text}')}
            >
              <svg
                className="svg-icon"
                aria-hidden="true"
                data-prefix="fas"
                data-icon="heading"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M496 80V48c0-8.837-7.163-16-16-16H320c-8.837 0-16 7.163-16 16v32c0 8.837 7.163 16 16 16h37.621v128H154.379V96H192c8.837 0 16-7.163 16-16V48c0-8.837-7.163-16-16-16H32c-8.837 0-16 7.163-16 16v32c0 8.837 7.163 16 16 16h37.275v320H32c-8.837 0-16 7.163-16 16v32c0 8.837 7.163 16 16 16h160c8.837 0 16-7.163 16-16v-32c0-8.837-7.163-16-16-16h-37.621V288H357.62v128H320c-8.837 0-16 7.163-16 16v32c0 8.837 7.163 16 16 16h160c8.837 0 16-7.163 16-16v-32c0-8.837-7.163-16-16-16h-37.275V96H480c8.837 0 16-7.163 16-16z"
                ></path>
              </svg>
            </button>
          </li>
          <li className="mde-header-item">
            <button
              data-name="bold"
              tabIndex="-1"
              aria-label="Add bold text"
              type="button"
              onClick={() => this.addToTextArea('**{text}**')}
            >
              <svg
                className="svg-icon"
                aria-hidden="true"
                data-prefix="fas"
                data-icon="bold"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M304.793 243.891c33.639-18.537 53.657-54.16 53.657-95.693 0-48.236-26.25-87.626-68.626-104.179C265.138 34.01 240.849 32 209.661 32H24c-8.837 0-16 7.163-16 16v33.049c0 8.837 7.163 16 16 16h33.113v318.53H24c-8.837 0-16 7.163-16 16V464c0 8.837 7.163 16 16 16h195.69c24.203 0 44.834-1.289 66.866-7.584C337.52 457.193 376 410.647 376 350.014c0-52.168-26.573-91.684-71.207-106.123zM142.217 100.809h67.444c16.294 0 27.536 2.019 37.525 6.717 15.828 8.479 24.906 26.502 24.906 49.446 0 35.029-20.32 56.79-53.029 56.79h-76.846V100.809zm112.642 305.475c-10.14 4.056-22.677 4.907-31.409 4.907h-81.233V281.943h84.367c39.645 0 63.057 25.38 63.057 63.057.001 28.425-13.66 52.483-34.782 61.284z"
                ></path>
              </svg>
            </button>
          </li>
          <li className="mde-header-item">
            <button
              data-name="italic"
              tabIndex="-1"
              aria-label="Add italic text"
              type="button"
              onClick={() => this.addToTextArea('*{text}*')}
            >
              <svg
                className="svg-icon"
                aria-hidden="true"
                data-prefix="fas"
                data-icon="italic"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M204.758 416h-33.849l62.092-320h40.725a16 16 0 0 0 15.704-12.937l6.242-32C297.599 41.184 290.034 32 279.968 32H120.235a16 16 0 0 0-15.704 12.937l-6.242 32C96.362 86.816 103.927 96 113.993 96h33.846l-62.09 320H46.278a16 16 0 0 0-15.704 12.935l-6.245 32C22.402 470.815 29.967 480 40.034 480h158.479a16 16 0 0 0 15.704-12.935l6.245-32c1.927-9.88-5.638-19.065-15.704-19.065z"
                ></path>
              </svg>
            </button>
          </li>
          <li className="mde-header-item">
            <button
              onClick={() => this.addToTextArea('~~{text}~~')}
              data-name="strikethrough"
              tabIndex="-1"
              aria-label="Add strikethrough text"
              type="button"
            >
              <svg
                className="svg-icon"
                aria-hidden="true"
                data-prefix="fas"
                data-icon="strikethrough"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M496 288H16c-8.837 0-16-7.163-16-16v-32c0-8.837 7.163-16 16-16h480c8.837 0 16 7.163 16 16v32c0 8.837-7.163 16-16 16zm-214.666 16c27.258 12.937 46.524 28.683 46.524 56.243 0 33.108-28.977 53.676-75.621 53.676-32.325 0-76.874-12.08-76.874-44.271V368c0-8.837-7.164-16-16-16H113.75c-8.836 0-16 7.163-16 16v19.204c0 66.845 77.717 101.82 154.487 101.82 88.578 0 162.013-45.438 162.013-134.424 0-19.815-3.618-36.417-10.143-50.6H281.334zm-30.952-96c-32.422-13.505-56.836-28.946-56.836-59.683 0-33.92 30.901-47.406 64.962-47.406 42.647 0 64.962 16.593 64.962 32.985V136c0 8.837 7.164 16 16 16h45.613c8.836 0 16-7.163 16-16v-30.318c0-52.438-71.725-79.875-142.575-79.875-85.203 0-150.726 40.972-150.726 125.646 0 22.71 4.665 41.176 12.777 56.547h129.823z"
                ></path>
              </svg>
            </button>
          </li>
        </ul>
        <ul className="mde-header-group">
          <li className="mde-header-item">
            <button
              onClick={() => this.addToTextArea('[{text}](url)')}
              data-name="link"
              tabIndex="-1"
              aria-label="Add a link"
              type="button"
            >
              <svg
                className="svg-icon"
                aria-hidden="true"
                data-prefix="fas"
                data-icon="link"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"
                ></path>
              </svg>
            </button>
          </li>
          <li className="mde-header-item">
            <button
              onClick={() => this.addToTextArea('> {text}')}
              data-name="quote"
              tabIndex="-1"
              aria-label="Insert a quote"
              type="button"
            >
              <svg
                className="svg-icon"
                aria-hidden="true"
                data-prefix="fas"
                data-icon="quote-right"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M512 80v128c0 137.018-63.772 236.324-193.827 271.172-15.225 4.08-30.173-7.437-30.173-23.199v-33.895c0-10.057 6.228-19.133 15.687-22.55C369.684 375.688 408 330.054 408 256h-72c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h128c26.51 0 48 21.49 48 48zM176 32H48C21.49 32 0 53.49 0 80v128c0 26.51 21.49 48 48 48h72c0 74.054-38.316 119.688-104.313 143.528C6.228 402.945 0 412.021 0 422.078v33.895c0 15.762 14.948 27.279 30.173 23.199C160.228 444.324 224 345.018 224 208V80c0-26.51-21.49-48-48-48z"
                ></path>
              </svg>
            </button>
          </li>
          <li className="mde-header-item">
            <button
              onClick={() => this.addToTextArea('```\n{text}\n```')}
              data-name="code"
              tabIndex="-1"
              aria-label="Insert code"
              type="button"
            >
              <svg
                className="svg-icon"
                aria-hidden="true"
                data-prefix="fas"
                data-icon="code"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M278.9 511.5l-61-17.7c-6.4-1.8-10-8.5-8.2-14.9L346.2 8.7c1.8-6.4 8.5-10 14.9-8.2l61 17.7c6.4 1.8 10 8.5 8.2 14.9L293.8 503.3c-1.9 6.4-8.5 10.1-14.9 8.2zm-114-112.2l43.5-46.4c4.6-4.9 4.3-12.7-.8-17.2L117 256l90.6-79.7c5.1-4.5 5.5-12.3.8-17.2l-43.5-46.4c-4.5-4.8-12.1-5.1-17-.5L3.8 247.2c-5.1 4.7-5.1 12.8 0 17.5l144.1 135.1c4.9 4.6 12.5 4.4 17-.5zm327.2.6l144.1-135.1c5.1-4.7 5.1-12.8 0-17.5L492.1 112.1c-4.8-4.5-12.4-4.3-17 .5L431.6 159c-4.6 4.9-4.3 12.7.8 17.2L523 256l-90.6 79.7c-5.1 4.5-5.5 12.3-.8 17.2l43.5 46.4c4.5 4.9 12.1 5.1 17 .6z"
                ></path>
              </svg>
            </button>
          </li>
        </ul>
        <ul className="mde-header-group">
          <li className="mde-header-item">
            <button
              onClick={() => this.addToTextArea('- {text}')}
              data-name="unordered-list"
              tabIndex="-1"
              aria-label="Add unordered list"
              type="button"
            >
              <svg
                className="svg-icon"
                aria-hidden="true"
                data-prefix="fas"
                data-icon="list-ul"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M96 96c0 26.51-21.49 48-48 48S0 122.51 0 96s21.49-48 48-48 48 21.49 48 48zM48 208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm0 160c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm96-236h352c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H144c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h352c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H144c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h352c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H144c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                ></path>
              </svg>
            </button>
          </li>
        </ul>
      </div>
    );

    return (
      <>
        {input.label && (
          <label
            className={`control-label ${
              input.labelClassName ? input.labelClassName : ''
            }`}
            htmlFor={input.id}
          >
            {input.label}
          </label>
        )}
        {header}
        <MentionsInput
          inputRef={this.inputRef}
          className={`form-control ${hasErrors ? 'validationElement' : ''} ${
            input.mentionsClassName ? input.mentionsClassName : ''
          } mentions-input`}
          allowSpaceInQuery={true}
          placeholder={input.placeholder}
          style={defaultStyle}
          value={input.value || ''}
          onKeyDown={this.props.onKeyDown}
          onChange={this.onChange}
        >
          <Mention
            renderSuggestion={this.renderSuggestion}
            appendSpaceOnAdd
            trigger="@"
            markup="@[__display__](contact:__id__)"
            data={this.state.users}
            style={defaultMentionStyle}
          />
        </MentionsInput>

        {hasErrors && (
          <div className="text-red fs-12">{input.errors.join(', ')}</div>
        )}
      </>
    );
  }
}

MentionsInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default NxMentionsInput;
