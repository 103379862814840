import React, { Component, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import SignupPageLayout from 'ui/layouts/SignupPageLayout';
import { BasketItemFactory } from 'ui/_pages/checkout/basketItemFactory';
import { DiscountInfoSection } from 'ui/_pages/checkout/DiscountInfoSection';
import { CheckoutDiscountSection } from 'ui/_pages/checkout/CheckoutDiscountSection';
import { CheckoutTotalPrice } from 'ui/_pages/checkout/CheckoutTotalPrice';
import { CheckoutButton } from 'ui/_pages/checkout/CheckoutButton';
import { routes } from 'env/routes';
import { withRouter } from 'next/router';
import { privateRoute } from 'env/privateRoute';
import { SignupLoadingBanner } from 'ui/_pages/signup/summary/SignupLoadingBanner';
import Link from 'next/link';
import TourPageLayout from '../../ui/layouts/TourPageLayout';
import FullPageLayout from '../../ui/layouts/FullPageLayout';

@withRouter
@withCustomComponent('SignupRestorePage')
@withTranslation()
@inject('appStore', 'authStore', 'checkoutStore')
@observer
class SignupRestorePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: false,
      sent: false,
    };
  }

  sendValidationEmail = () => {
    const { authStore, router } = this.props;
    this.setState({ error: false, loading: true, sent: false });
    authStore
      .restore(router.query.token, router.query.returnUrl ?? '/en/signup')
      .then(() => this.setState({ sent: true }))
      .catch(() => this.setState({ error: true }))
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const { t } = this.props;
    return (
      <FullPageLayout componentName={this.props.componentName} step={4}>
        <div className="mw-820">
          <fieldset
            data-component-name={this.props.componentName}
            id="your-appearance"
            className="pt-10"
          >
            <h5 className="mb-20">{t('Email validation')}</h5>
            <div>
              {this.state.sent && (
                <p>{t('Please check your email inbox to continue.')}</p>
              )}

              {!this.state.sent && (
                <>
                  <p className=" mb-16">
                    {t(
                      'We need to validate your email address. Click below so we can send you an email to continue with your sign-up.'
                    )}
                  </p>
                  <Link href="#">
                    <a
                      disabled={this.state.loading}
                      onClick={() =>
                        !this.state.loading && this.sendValidationEmail()
                      }
                      className="btn btn-sm"
                    >
                      {this.state.loading
                        ? t('Please wait...')
                        : t('Validate my email')}
                    </a>
                  </Link>
                </>
              )}
            </div>
          </fieldset>
        </div>
      </FullPageLayout>
    );
  }
}

export default SignupRestorePage;
