import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { routes } from 'env/routes';
import Link from 'next/link';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import withCustomComponent from 'ui/components/withCustomComponent';

import CardDashboard from 'ui/components/cards/CardDashboard';
import { NoAccessNotice } from 'ui/components/NoAccessNotice';
import LoadingWidget from 'ui/_pages/home/components/LoadingWidget/LoadingWidget';

@withCustomComponent('VisitorsWidget')
@withTranslation()
@inject('appStore', 'bookingsStore')
@observer
class VisitorsWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    const { bookingsStore } = this.props;
    bookingsStore.loadMyVisitors().catch((ex) => {
      this.setState({
        error: t("You currently don't have access to register visitors."),
      });
    });
  }

  deleteVisitor = (visitor) => {
    const { t, bookingsStore, appStore } = this.props;
    appStore
      .setYesNotQuestion(t('Do you really want to remove this visitor?'))
      .then((response) => {
        if (response)
          bookingsStore
            .deleteVisitor(visitor)
            .then(() => bookingsStore.loadMyVisitors())
            .catch((err) => {
              appStore.setPopMessage(
                t(
                  'We could not remove this visitors. Please contact us. ' + err
                )
              );
            });
      });
  };

  render() {
    const { t, bookingsStore } = this.props;
    const { myVisitors, hasLoadedMyVisitors, isDeletingVisitor } =
      bookingsStore;

    const totalVisitors = myVisitors.length;
    const firstVisitors = _.take(myVisitors, 3);
    const hasVisitors = firstVisitors.length > 0;
    const hasError = this.state.error != null;

    if (!hasLoadedMyVisitors)
      return <LoadingWidget className="" title={t('Visitors')} />;

    return (
      <CardDashboard
        headerMainTitle={
          t('Visitors') + `${hasVisitors ? ` (${totalVisitors})` : ''}`
        }
        headerActionTitle={t('View all')}
        headerActionUrl={routes.visitors}
        footerActionTitle={t('Add Visitor')}
        footerActionIcon="icon-add"
        footerActionUrl={routes.visitors}
        componentName={this.props.componentName}
      >
        {hasVisitors ? (
          <ul className="list-unstyled mb-24">
            {firstVisitors.map((visitor, i) => {
              return (
                <li
                  className="d-flex justify-content-between align-items-center mt-16"
                  key={i}
                >
                  <div className="d-flex align-items-center">
                    <Link href={routes.visitors}>
                      <a>
                        <img
                          className="avatar"
                          src={`//www.gravatar.com/avatar/${visitor.GravatarHashedEmail}?size=32&d=${visitor.DefaultAvatarUrl}`}
                        />
                      </a>
                    </Link>
                    <div className="pl-12">
                      <Link href={routes.visitors}>
                        <a className="btn btn-link d-flex text-gray-900 fs-14 fw-500">
                          {visitor.FullName}
                        </a>
                      </Link>
                      <time className="d-flex text-gray-700 fs-14">
                        {moment(visitor.ExpectedArrival).format('LLL')}
                      </time>
                    </div>
                  </div>
                  <div className="dropdown">
                    <button
                      className="btn btn-single-icon btn-white dropdown-toggle"
                      data-toggle="dropdown"
                    >
                      <i className="icon-more"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                      <button
                        className="dropdown-item"
                        onClick={(ev) => {
                          this.setState({ ['clicked' + visitor.Id]: true });
                          ev.preventDefault();
                          this.deleteVisitor(visitor);
                        }}
                      >
                        {this.state['clicked' + visitor.Id] &&
                        isDeletingVisitor ? (
                          <LoadingSpinner />
                        ) : (
                          <>
                            <i aria-hidden="true" className="icon-delete"></i>
                            {t('Remove visitor')}
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <>
            <NoAccessNotice error={this.state.error} />
            {!hasError && (
              <p className="text-gray-700 mt-16">
                {t('You have no expected visitors.')}
              </p>
            )}
          </>
        )}
      </CardDashboard>
    );
  }
}
export default VisitorsWidget;
