import React, { useState, useEffect, useRef } from 'react';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'next/router';
import withCustomComponent from 'ui/components/withCustomComponent';
import { ResourceCard } from 'ui/_pages/bookings/map/ResourceCard';

const ResourcesScrollView = withCustomComponent('ResourcesScrollView')(
  inject(
    'appStore',
    'authStore'
  )(
    withRouter(
      observer(({ resourcesToShow, hoveringOn }) => {
        const [scrollValue, setScrollValue] = useState(0);
        const [hasReachedStart, setHasReachedStart] = useState(true);
        const [hasReachedEnd, setHasReachedEnd] = useState(false);

        const scrollAmount = 479; //defaults to ResourceCard width (with margins)
        const scrollViewRef = useRef();
        const bgColors = ['green', 'yellow', 'orange', 'red', 'blue'];

        useEffect(() => {
          if (!scrollViewRef.current) return;

          setScrollValue(0);

          //note: subtract 50 to cover cases when scroll view is slightly larger than the map
          const isScrollViewSmallerThanMap =
            scrollViewRef.current.scrollWidth -
              scrollViewRef.current.offsetWidth -
              50 <=
            0;
          if (scrollViewRef.current.scrollLeft === 0) {
            if (isScrollViewSmallerThanMap) {
              setHasReachedStart(true);
              setHasReachedEnd(true);
            } else {
              setHasReachedEnd(false);
            }
          }
        }, [resourcesToShow]);

        useEffect(() => {
          if (hoveringOn >= 0) {
            scrollViewRef.current.scroll({
              left: scrollAmount * hoveringOn,
              behavior: 'smooth',
            });

            setArrowsVisibility(scrollAmount * hoveringOn, scrollAmount);
            setScrollValue(scrollAmount * hoveringOn);
          }
        }, [hoveringOn]);

        const scroll = (amount) => {
          scrollViewRef.current.scroll({
            left: scrollValue + amount,
            behavior: 'smooth',
          });

          setArrowsVisibility(scrollValue + amount, amount);
          setScrollValue(scrollValue + amount);
        };

        const setArrowsVisibility = (currScrollValue, amount) => {
          if (currScrollValue + amount > 0) {
            setHasReachedStart(false);
            setHasReachedEnd(false);
          }

          const scrollableWidth = scrollAmount * resourcesToShow.length;
          const widthLimit =
            scrollableWidth - scrollViewRef.current.offsetWidth;

          if (currScrollValue >= widthLimit) {
            setHasReachedEnd(true);
          }

          if (currScrollValue < scrollAmount) {
            setHasReachedStart(true);
            setHasReachedEnd(false);
          }
        };

        if (!resourcesToShow.length) {
          return null;
        }

        let resources = resourcesToShow.sort(sortByName);
        return (
          <div className="resources-cards" ref={scrollViewRef}>
            {resources.map((r, i) => {
              if (i === hoveringOn) {
                return <ResourceCard key={i} resource={r} active />;
              }

              return <ResourceCard key={i} resource={r} />;
            })}

            {!hasReachedStart && (
              <div
                className="see-more left btn btn-primary btn-lg d-none d-lg-inline-flex"
                onClick={() => scroll(-scrollAmount)}
              >
                <i className="icon-chevron-right fs-24"></i>
              </div>
            )}

            {!hasReachedEnd && (
              <div
                className="see-more right btn btn-primary btn-lg d-none d-lg-inline-flex"
                onClick={() => scroll(scrollAmount)}
              >
                <i className="icon-chevron-right fs-24"></i>
              </div>
            )}
          </div>
        );
      })
    )
  )
);

export default ResourcesScrollView;

const sortByName = (a, b) => {
  if (a.Name < b.Name) {
    return -1;
  }
  if (a.Name > b.Name) {
    return 1;
  }

  return 0;
};
