/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import withCustomComponent from 'ui/components/withCustomComponent';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import moment from 'moment';
import { withRouter } from 'next/router';

import BookingResourceInfo from 'ui/_pages/bookings/details/BookingResourceInfo';
import BookingVisitorsForm from 'ui/_pages/bookings/details/BookingVisitorsForm';
import BookingNotesForm from 'ui/_pages/bookings/details/BookingNotesForm';
import BookingZoomForm from 'ui/_pages/bookings/details/BookingZoomForm';

import BookingDiscountForm from 'ui/_pages/bookings/details/BookingDiscountForm';
import BookingStatus from 'ui/_pages/bookings/details/BookingStatus';

@withRouter
@withCustomComponent('Popup')
@withTranslation()
@inject('appStore', 'bookingsStore')
@observer
class Popup extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { bookingsStore } = this.props;
    const { booking } = bookingsStore;

    if (booking?.ResourceId) {
      this.loadResourceProducts(booking);
    }

    this.loadBookingPrice();
  }

  componentDidUpdate() {
    const { bookingsStore } = this.props;
    const { booking, resourceProducts } = bookingsStore;

    if (booking?.ResourceId && resourceProducts.length <= 0) {
      this.loadResourceProducts(booking);
    }
  }

  loadResourceProducts = (booking) => {
    const { bookingsStore } = this.props;
    bookingsStore.loadResourceProducts({
      bookingId: booking.Id,
      resourceId: booking.ResourceId,
    });
  };

  loadBookingPrice = () => {
    const { bookingsStore } = this.props;
    bookingsStore.loadBookingPrice();
  };

  @action updateBooking(prop, value) {
    const { bookingsStore } = this.props;
    const { booking } = bookingsStore;

    const prevToTime = moment(booking.ToTime);
    const prevFromTime = moment(booking.FromTime);
    const prevMinutesLength = Math.abs(
      prevToTime.diff(prevFromTime, 'minutes')
    );

    booking[prop] = value;

    const toTime = moment(booking.ToTime);
    const fromTime = moment(booking.FromTime);

    //Make sure "to date" is always after "from date"
    if (prop === 'FromTime') {
      if (new Date(booking.ToTime) < value) {
        let newToTime = fromTime.add(prevMinutesLength, 'minutes').toDate();
        booking.ToTime = newToTime;
      }
    }

    //Make sure "from date" is always before "to date"
    if (prop === 'ToTime') {
      if (new Date(booking.FromTime) > value) {
        let newFromTime = toTime
          .subtract(prevMinutesLength, 'minutes')
          .toDate();
        booking.FromTime = newFromTime;
      }
    }

    this.loadBookingPrice();
  }

  render() {
    const { t, appStore, bookingsStore, componentName, router } = this.props;
    const { booking, isLoadingBooking, bookingPrice } = bookingsStore;
    const hasLoadedData = booking;
    const isCorporateDashboard = appStore.corporateDashboard;
    const isAddToBasketEnabled =
      bookingPrice && bookingPrice.IsAvailable && !bookingPrice.Message;

    return (
      <div data-component-name={componentName} className="popover-floorplan">
        <div id="js--close-bg" className="popover-floorplan__background"></div>

        {isLoadingBooking && <LoadingSpinner />}

        {hasLoadedData && (
          <>
            <div
              id="js--close-icon"
              className="popover-floorplan__close-icon icon-close"
            ></div>
            <form className="popover-floorplan__content">
              <BookingResourceInfo
                updateBooking={(prop, value) => this.updateBooking(prop, value)}
                isSidePopup
                showResourceAvailableSlots={false}
              />

              <BookingVisitorsForm
                updateBooking={(prop, value) => this.updateBooking(prop, value)}
                isSidePopup
              />

              <BookingNotesForm
                updateBooking={(prop, value) => this.updateBooking(prop, value)}
                isSidePopup
              />

              <BookingZoomForm
                updateBooking={(prop, value) => this.updateBooking(prop, value)}
              />

              <div className="popover-floorplan__actions">
                {/*{!isCorporateDashboard && (
                  <BookingDiscountForm
                    updateBooking={(prop, value) =>
                      this.updateBooking(prop, value)
                    }
                    withBorder
                  />
                )}*/}

                {booking.ResourceId > 0 && <BookingStatus />}

                <a
                  id="js--basket"
                  className={`d-flex btn w-100 js-add-to-basket mt-12 btn-outline ${
                    isAddToBasketEnabled ? '' : 'disabled'
                  }`}
                  href="#"
                >
                  {router.pathname.includes('bookings/floorPlanMobile')
                    ? t('Book resource')
                    : t('Add to basket')}
                </a>

                {!router.pathname.includes('bookings/floorPlanMobile') && (
                  <a
                    id="js--checkout"
                    className={`d-flex btn w-100 js-add-to-basket mt-12 ${
                      isAddToBasketEnabled ? '' : 'disabled'
                    }`}
                    href="#"
                  >
                    {t('Checkout')}
                  </a>
                )}
              </div>
            </form>
          </>
        )}
      </div>
    );
  }
}
export default Popup;
