import React, {Component, useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import {withCustomComponent} from 'ui/components/withCustomComponent';
import HomeDashboard from 'ui/_pages/home/HomeDashboard';
import {VirtualDashboard} from 'ui/_pages/home/VirtualDashboard';
import {GuestHomePage} from 'ui/_pages/home/GuestHomePage';
import {VirtualHomePage} from 'ui/_pages/home/VirtualHomePage';
import {checkTerms} from 'env/checkTerms';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import config from 'env/config';

@checkTerms
@withCustomComponent('HomePage')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class HomePage extends Component {
  componentDidMount() {
    const {appStore, authStore} = this.props;
    const {isLoggedIn} = authStore;

    // Redirect to the desired destination
    if (!isLoggedIn && process.env.NEXT_PUBLIC_REDIRECT_HOMEPAGE) {
      window.location = process.env.NEXT_PUBLIC_REDIRECT_HOMEPAGE;
    }

    try {
      console.log('apiRoot', config.apiRoot);
      console.log('PUBLIC_URL', process.env.PUBLIC_URL);
    } catch (ex) {
      console.info('config log error');
    }

    appStore.loadHomePage().catch(() => null);
  }

  render() {
    const {t, appStore, authStore, componentName} = this.props;
    const {virtualDashboard, business, configuration} = appStore;
    const {isLoggedIn} = authStore;

    const isVirtualDashboard =
      configuration['PublicWebSite.Home.UseCommunityDashboard'] == 'true' ||
      virtualDashboard;

    //This just checks we have actually received data from the home page.
    if (!appStore?.homePage?.Gallery) return <LoadingLayout/>;

    // Waiting for the redirection
    if (!isLoggedIn && process.env.NEXT_PUBLIC_REDIRECT_HOMEPAGE) {
      return <LoadingLayout/>;
    }

    return (
      <DefaultLayout title={t('Home - {{Name}}', {Name: t(business.Name)})}>
        <main
          data-component-name={componentName}
          className={`content ${
            isLoggedIn ? 'content--dashboard' : 'content--home'
          }`}
          role="main"
        >
          {isLoggedIn && (
            <>{isVirtualDashboard ? <VirtualDashboard/> : <HomeDashboard/>}</>
          )}
          {!isLoggedIn && (
            <>{isVirtualDashboard ? <VirtualHomePage/> : <GuestHomePage/>}</>
          )}
        </main>
      </DefaultLayout>
    );
  }
}

export default HomePage;
