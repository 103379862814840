import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import { withRouter } from 'next/router';
import { routes } from 'env/routes';
import { removeHtml } from 'env/utils/strings';
import LoadingSpinner from 'ui/components/LoadingSpinner';

import CardEvent from 'ui/components/cards/CardEvent';
import { EventTicketsPageSignupSection } from 'ui/_pages/events/tickets/EventTicketsPageSignupSection';
import { EventTicketsPageTicketsSection } from 'ui/_pages/events/tickets/EventTicketsPageTicketsSection';

@withCustomComponent('EventTicketsPage')
@withRouter
@withTranslation()
@inject('appStore', 'authStore', 'eventsStore')
@observer
class EventTicketsPage extends Component {
  componentDidMount() {
    const { authStore, t, appStore, router, eventsStore } = this.props;
    const { eventTicketsPage } = eventsStore;
    const eventId = router.query.event_id;
    if (eventTicketsPage.Event.Id != eventId) {
      eventsStore.loadEventTicketsPage(eventId).catch((err) => {
        appStore.setPopMessage(
          t('Sorry, we could not load this page. {{err}}', { err })
        );
      });
    }
    this.showTicketsModal();
  }

  componentWillUnmount() {
    this.hideTicketsModal();
  }

  showTicketsModal = () => {
    const $ = window.$;
    $('#tickets-modal').modal({
      backdrop: 'static',
      keyboard: false,
      show: true,
    });
  };

  hideTicketsModal = () => {
    const $ = window.$;
    $('#tickets-modal').modal('hide');
    window.location.hash = '';
  };

  render() {
    const { router, t, eventsStore, authStore, appStore } = this.props;
    const { business } = appStore;
    const { isLoggedIn } = authStore;
    const event = eventsStore.eventTicketsPage.Event;
    const isLoading = !event || event.Id != router.query.event_id;

    return (
      <DefaultLayout
        title={t('{{EventName}} - {{Name}}', {
          EventName: !isLoading && event ? removeHtml(event.Name) : '...',
          Name: t(business.Name),
        })}
      >
        <main className="content content--custom" role="main">
          <div className="container">
            <div
              data-component-name={this.props.componentName}
              className="modal modal-tickets"
              id="tickets-modal"
            >
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <Link href={routes.event_by_id(event)}>
                    <a className="close">
                      <i className="icon-close"></i>
                    </a>
                  </Link>
                  <div className="modal-body">
                    {isLoading && <LoadingSpinner />}
                    {!isLoading && (
                      <>
                        <CardEvent event={event} sizeLarge={true} />
                        {!isLoggedIn && <EventTicketsPageSignupSection />}
                        <div
                          style={{
                            cursor: isLoggedIn ? null : 'not-allowed',
                            opacity: isLoggedIn ? '100%' : '33%',
                          }}
                        >
                          <EventTicketsPageTicketsSection />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default EventTicketsPage;
