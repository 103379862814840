    import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import Link from 'next/link';
import { routes } from 'env/routes';
import { withRouter } from 'next/router';
import { withCustomComponent } from 'ui/components/withCustomComponent';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import Head from 'next/head';
@withCustomComponent('LoginPage')
@withRouter
@withTranslation()
@inject('authStore', 'appStore')
@observer
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      changePassword: false,
      newPassword: true,
      exchangedFailed: false,
      isRedirecting: false,
    };
  }

  sendMagicLink = () => {
    const { t, appStore, authStore } = this.props;
    this.setState({ loginError: null });

    authStore
      .sendMagicLink(this.state.email)
      .then((res) => {
        appStore.setPopMessage(
          t('Please check your email to access your account.')
        );
      })
      .catch((err) => {
        this.setState({ loginError: t(err) });
      });
  };

  login = () => {
    const { router, t, authStore } = this.props;
    this.setState({ loginError: null, passwordResetSent: false });

    if (this.state.changePassword) {
      authStore
        .setPassword(
          this.state.email,
          this.state.changePasswordToken,
          this.state.password
        )
        .then((res) => {
          if (res.Status == 500) {
            this.setState({ loginError: t(res.Message) });
          } else {
            this.setState({ changePassword: false });
            this.login();
          }
        })
        .catch((err) => {
          this.setState({ loginError: t(err) });
        });
    } else {
      authStore
        .login(
          this.state.email,
          this.state.password,
          this.state.totp,
          this.state.keepLoggedIn
        )
        .catch((err) => {
          if (err?.response?.body?.error === 'must_reset_password') {
            this.setState({
              changePassword: true,
              changePasswordToken: err?.response?.body?.error_description,
              password: '',
              loginMessage:
                t('You must change your password to continue') +
                '. ' +
                t(
                  'Your password must be at least 6 characters long and include lowercase (a-z), uppercase (A-Z) and symbols (!@#$%^&*)'
                ),
            });
          } else if (err?.response?.body?.error === 'two_factor_auth_check') {
            this.setState({
              twoFactorRequired: true,
              twoFactorToken: err?.response?.body?.error_description,
              loginMessage: t('Enter your 2 factor authentication code.'),
            });
          } else if (err.response)
            this.setState({
              loginError: t(err.response.body.error_description),
            });
          else this.setState({ loginError: t(err) });
        })
        .then((data) => {
          if (data && data.refresh_token) return authStore.loadMe();
          else return data;
        })
        .then(this.afterLogin);
    }
  };

  changePassword = () => {
    authStore.login(this.state.email, this.state.password);
  };

  getSsoUrl = () => {
    const { appStore } = this.props;
    const { business } = appStore;
    return `${business.NativeHomeUrlWithLanguage}/login/sso`;
  };

  getContinueToUrl = () => {
    const { router } = this.props;
    return (
      router.query.ReturnUrl ||
      router.query.returnurl ||
      router.query.redirectUrl
    );
  };

  afterLogin = (me) => {
    const { appStore, authStore } = this.props;
    const { business } = appStore;
    const { router } = this.props;

    if (!me) return;
    if (!router.query.imp) authStore.setImpersonationToken(null);

    //We use window.location to force a browser reload
    //This ensures we clear all mobx states after a user logs in.
    const continueTo = this.getContinueToUrl();

    this.setState({ isRedirecting: true });
    const isServerRedirect = router.query.server == '1';
    window.location = continueTo
      ? isServerRedirect
        ? `${business.NativeHomeUrlWithLanguage}/user/login?t=${
            me.AccessToken
          }&redirectUrl=${encodeURIComponent(continueTo)}`
        : decodeURIComponent(continueTo)
      : '/';

    return me;
  };

  getExclusiveSso() {
    const { appStore } = this.props;
    const { configuration } = appStore;
    return (
      (configuration['Okta.Enabled'] && configuration['Okta.Exclusive']) ||
      (configuration['ActiveDirectory.Enabled'] &&
        configuration['ActiveDirectory.Exclusive']) ||
      (configuration['OpenId.Enabled'] && configuration['OpenId.Exclusive'])
    );
  }

  componentDidMount() {
    const { router, authStore, appStore, t } = this.props;
    const { me } = authStore;
    const exclusiveSso = this.getExclusiveSso();

    if (!router.query.t && exclusiveSso) {
      if (window.parent !== window) {
        let ssoWindow = window.open(this.getSsoUrl());
        let timer = setInterval(function () {
          if (ssoWindow.closed) {
            clearInterval(timer);
            this.afterLogin();
          }
        }, 1000);
      } else window.location = this.getSsoUrl();
      return;
    }

    if (router.query.t) {
      authStore
        .exchange(router.query.t)
        .then((tokenResponse) => {
          if (tokenResponse?.token) return authStore.loadMe();
          else {
            this.setState({ exchangedFailed: true });
            return tokenResponse?.token;
          }
        })
        .then(this.afterLogin);
    } else if (me && me.Id > 0 && this.getContinueToUrl()) {
      this.afterLogin(me);
    }
  }

  render() {
    const { t, router, appStore, authStore } = this.props;
    const { business, configuration } = appStore;
    const { isLoggingIn, isLoadingMe } = authStore;
    const continueTo = router.query.returnurl || router.query.redirectUrl;
    const exclusiveSso = this.getExclusiveSso();
    const hasExternalLogin =
      configuration['Signup.GooglePlusSignIn'] ||
      configuration['Signup.FacebookSignIn'] ||
      configuration['ActiveDirectory.Enabled'] ||
      configuration['OpenId.Enabled'] ||
      configuration['Okta.Enabled'];

    if (router.query.t && !this.state.exchangedFailed) return <LoadingLayout />;
    return (
      <main
        data-component-name={this.props.componentName}
        className="content content--login login"
        role="main"
      >
        <Head>
          <title>
            {t('Sign in')} - {t(business?.Name)}
          </title>
        </Head>

        <header className="site-header site-header--lo">
          <div className="site-header__content">
            <nav className="navbar container">
              <a className="navbar-brand" href="/">
                <img
                  src={`${business.NativeHomeUrlWithLanguage}/business/getlogo?h=96&mode=pad`}
                  alt={t(business.Name)}
                  height="48"
                />
                <div className="spacer d-none d-md-inline-block"></div>
                <span className="d-none d-md-inline">{t(business.Name)}</span>
              </a>
              <a href="/" className="btn btn-single-icon btn-white">
                <i className="icon-close-large fs-18"></i>
              </a>
            </nav>
          </div>
        </header>
        <div
          className="login__content"
          style={{
            backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/business/GetBannerFor?id=${business.Id}&w=1920&h=1080&mode=max)`,
          }}
        >
          <div className="card login__card">
            <header className="login__card__header">
              <h4>{t('Sign in to {{Name}}', { Name: t(business.Name) })}</h4>
            </header>
            <div className="login__card__content">
              <div className="credentials__social-access">
                {!this.state.changePassword && (
                  <div className="btn-toolbar">
                    {configuration['Signup.GooglePlusSignIn'] && (
                      <a
                        href={`${
                          business.NativeHomeUrlWithLanguage
                        }/login/ExternalLogin?returnUrl=${
                          continueTo ? continueTo : ''
                        }&provider=GooglePlus&bid=${business.Id}`}
                        className="btn btn--google"
                      >
                        <i className="fa fa-google i-space--right--large"></i>{' '}
                        <span>{t('Sign in with Google')}</span>
                      </a>
                    )}
                    {configuration['Signup.FacebookSignIn'] && (
                      <a
                        href={`${
                          business.NativeHomeUrlWithLanguage
                        }/login/ExternalLogin?returnUrl=${
                          continueTo ? continueTo : ''
                        }&provider=Facebook&bid=${business.Id}`}
                        className="btn btn--facebook"
                      >
                        <i className="fa fa-facebook i-space--right--large"></i>{' '}
                        <span>{t('Sign in with Facebook')}</span>
                      </a>
                    )}
                    {configuration['Okta.Enabled'] && (
                      <a
                        target={
                          typeof window !== 'undefined' &&
                          window.parent !== window
                            ? '_blank'
                            : null
                        }
                        href={this.getSsoUrl()}
                        className="btn btn--okta"
                      >
                        <i className="fa fa-lock i-space--right--large"></i>{' '}
                        <span>
                          {configuration['Okta.Label']
                            ? configuration['Okta.Label']
                            : t('Sign in with Corporate Account')}
                        </span>
                      </a>
                    )}
                    {configuration['ActiveDirectory.Enabled'] && (
                      <a
                        target={
                          typeof window !== 'undefined' &&
                          window.parent !== window
                            ? '_blank'
                            : null
                        }
                        href={this.getSsoUrl()}
                        className="btn btn--okta"
                      >
                        <i className="fa fa-lock i-space--right--large"></i>{' '}
                        <span>
                          {configuration['ActiveDirectory.Label']
                            ? configuration['ActiveDirectory.Label']
                            : t('Sign in with Corporate Account')}
                        </span>
                      </a>
                    )}
                    {configuration['OpenId.Enabled'] && (
                      <a
                        target={
                          typeof window !== 'undefined' &&
                          window.parent !== window
                            ? '_blank'
                            : null
                        }
                        href={this.getSsoUrl()}
                        className="btn btn--okta"
                      >
                        <i className="fa fa-lock i-space--right--large"></i>{' '}
                        <span>
                          {configuration['OpenId.Label']
                            ? configuration['OpenId.Label']
                            : t('Sign in with Corporate Account')}
                        </span>
                      </a>
                    )}
                  </div>
                )}
              </div>

              {!this.state.twoFactorRequired && !this.state.changePassword && (
                <h5 className="credentials__register-access__intro text-center fs-16">
                  {hasExternalLogin
                    ? t('or sign in with your email address')
                    : t('Enter your email and password')}
                </h5>
              )}
              {!exclusiveSso && (
                <form
                  className="form"
                  onSubmit={(ev) => {
                    ev.preventDefault();
                    this.login();
                  }}
                >
                  {!this.state.twoFactorRequired && !this.state.changePassword && (
                    <div className="form-group">
                      <label htmlFor="email">{t('Email')}</label>
                      <div className="input-group">
                        <input
                          disabled={this.state.changePassword}
                          type="email"
                          value={this.state.email}
                          onChange={(ev) =>
                            this.setState({ email: ev.target.value })
                          }
                          className="form-control"
                          id="email"
                        />
                        <div className="input-group-append">
                          <i className="icon-email"></i>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="form-group">
                    {!this.state.twoFactorRequired &&
                      !this.state.changePassword && (
                        <label htmlFor="password">
                          {!this.state.twoFactorRequired &&
                            !this.state.changePassword &&
                            t('Password')}
                          {this.state.changePassword && t('New Password')}
                          {this.state.twoFactorRequired &&
                            t('Your Authentication Code')}
                        </label>
                      )}

                    <div className="input-group">
                      {this.state.twoFactorRequired && (
                        <input
                          type="text"
                          id="totp"
                          name="totp"
                          value={this.state.totp}
                          onChange={(ev) =>
                            this.setState({ totp: ev.target.value })
                          }
                          className="form-control"
                        />
                      )}
                      {!this.state.twoFactorRequired && (
                        <input
                          placeholder={
                            this.state.changePassword ? t('New Password') : ''
                          }
                          type={
                            this.state.changePassword && this.state.showPassword
                              ? 'text'
                              : 'password'
                          }
                          value={this.state.password}
                          onChange={(ev) =>
                            this.setState({ password: ev.target.value })
                          }
                          className="form-control"
                          id="password"
                        />
                      )}
                      <div className="input-group-append">
                        <i className="icon-lock"></i>
                      </div>
                    </div>

                    {!this.state.passwordResetSent && !this.state.changePassword && this.state.email && (
                      <p className="text-gray-700 fs-14 my-12">
                        <Trans>
                          <span>
                            If you don't remember your current password:{' '}
                          </span>
                          <a
                            disabled={this.state.passwordResetSent}
                            onClick={(ev) => {
                              if(this.state.passwordResetSent) return;

                              ev.preventDefault();
                              this.setState({passwordResetSent: true})
                              authStore
                                .loadResetPassword(
                                  this.state.email,
                                  business.Id
                                )
                                .then((data) => {
                                  appStore.setPopMessage(
                                    t(
                                      'We have sent you an email to {{email}} to reset your password',
                                      { email: this.state.email }
                                    )
                                  );
                                });
                            }}
                            href="#"
                            className="text-gray-700 tdu"
                          >
                            request a password reset email
                          </a>
                          <span>.</span>
                        </Trans>
                      </p>
                    )}

                    {this.state.changePassword && (
                      <div className="fs-14 mt-8 text-right">
                        <a
                          href="#"
                          onMouseDown={(ev) => {
                            ev.preventDefault();
                            this.setState({ showPassword: true });
                          }}
                          onTouchStart={(ev) => {
                            ev.preventDefault();
                            this.setState({ showPassword: true });
                          }}
                          onMouseUp={(ev) => {
                            ev.preventDefault();
                            this.setState({ showPassword: false });
                          }}
                          onTouchEnd={(ev) => {
                            ev.preventDefault();
                            this.setState({ showPassword: false });
                          }}
                        >
                          {t('Show password')}
                        </a>
                      </div>
                    )}
                  </div>

                  <div className="credentials__register-access">
                    {this.state.loginError && (
                      <div
                        className="alert alert-danger fs-14 mt-16"
                        role="alert"
                      >
                        <b>{t(this.state.loginError)}</b>
                      </div>
                    )}

                    {this.state.loginMessage && (
                      <div
                        className="alert alert-info fs-14 mt-16"
                        role="alert"
                      >
                        <b>{t(this.state.loginMessage)}</b>
                      </div>
                    )}
                  </div>

                  <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input
                        checked={this.state.keepLoggedIn}
                        onChange={(ev) =>
                          this.setState({
                            keepLoggedIn: ev.target.checked,
                          })
                        }
                        type="checkbox"
                        className="custom-control-input"
                        id="keepLoggedIn"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="keepLoggedIn"
                      >
                        {t('Keep me logged in')}
                      </label>
                    </div>
                  </div>

                  <div className="form-actions text-center">
                    <button
                      disabled={!this.state.email || isLoggingIn}
                      className="btn btn-lg w-100 mb-10"
                      type="submit"
                    >
                      {this.state.isRedirecting ||
                      isLoggingIn ||
                      isLoadingMe ? (
                        <LoadingSpinner />
                      ) : (
                        t('Sign in')
                      )}
                    </button>

                    <button
                      className="btn btn-lg btn-link"
                      type="button"
                      disabled={!this.state.email || isLoggingIn}
                      onClick={() => this.state.email && this.sendMagicLink()}
                    >
                      <i className="fa fa-envelope i-space--right--large mr-5"></i>{' '}
                      <span>{t('Log in using email link')}</span>
                    </button>
                  </div>
                </form>
              )}
            </div>
            <footer className="login__card__footer">
              {configuration['Members.CanSignup'] && (
                <>
                  {t('New user?')}{' '}
                  <Link
                    href={{
                      pathname: routes.signup,
                      query: {
                        returnurl:
                          router.query.returnurl ??
                          router.query.Returnurl ??
                          router.query.ReturnUrl,
                      },
                    }}
                  >
                    <a>{t('Create account')}</a>
                  </Link>{' '}
                </>
              )}
              {configuration['PublicWebSite.Tour'] && (
                <>
                  {configuration['Members.CanSignup'] && <>{t('or')} </>}
                  <Link href={routes.tour}>
                    <a className="link--primary">{t('Request a tour')}</a>
                  </Link>
                </>
              )}
            </footer>
          </div>
        </div>
        {/*<footer className="login__footer">*/}
        {/*  <div className="container">*/}
        {/*    <div>*/}
        {/*      <img*/}
        {/*        src={`${business.NativeHomeUrlWithLanguage}/business/getlogo?h=64&mode=pad`}*/}
        {/*        alt={t(business.Name)}*/}
        {/*        height="32"*/}
        {/*      />*/}
        {/*      <div className="mt-24 text-gray-600 ">*/}
        {/*        {t('{{Name}}. Copyright © {{year}} All Rights Reserved.', {*/}
        {/*          Name: t(business.Name),*/}
        {/*          year: new Date().getFullYear(),*/}
        {/*        })}*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="mt-16 mt-lg-0">*/}
        {/*      {business.TermsAndConditions && (*/}
        {/*        <Link href={routes.legal_terms}>*/}
        {/*          <a className="text-gray-600 mr-12">*/}
        {/*            {t('Terms and conditions')}*/}
        {/*          </a>*/}
        {/*        </Link>*/}
        {/*      )}*/}
        {/*      {configuration['Legal.Privacy'] && (*/}
        {/*        <Link href={routes.legal_privacy}>*/}
        {/*          <a className="text-gray-600 mr-12">{t('Privacy policy')}</a>*/}
        {/*        </Link>*/}
        {/*      )}*/}
        {/*      {configuration['Legal.Cookies'] && (*/}
        {/*        <Link href={routes.legal_cookies}>*/}
        {/*          <a className="text-gray-600">{t('Cookies policy')}</a>*/}
        {/*        </Link>*/}
        {/*      )}*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</footer>*/}
      </main>
    );
  }
}

export default LoginPage;
