import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import moment from 'moment';
import { CoworkerAvatar } from 'ui/components/CoworkerAvatar';
import { removeHtml } from 'env/utils/strings';
import ScrollableAnchor from 'react-scrollable-anchor';

export const CoursesPageCommentsListItem = withCustomComponent(
  'CoursesPageCommentsListItem'
)(
  inject(
    'appStore',
    'authStore',
    'coursesStore'
  )(
    withRouter(
      observer(({ comment, authStore, appStore, coursesStore }) => {
        const { t } = useTranslation();
        const course = coursesStore.coursePage.Course;
        const { business } = appStore;
        const { me, isLoggedIn } = authStore;

        const deleteComment = (id) => {
          coursesStore
            .deleteComment(id)
            .then(() => coursesStore.loadCoursePage(course.Id))
            .catch((err) => {
              appStore.setPopMessage(
                t('Sorry, we could not delete this comment. {{err}}', { err })
              );
            });
        };

        return (
          <>
            <ScrollableAnchor id={`comment-${comment.Id}`}>
              <div className="d-flex">
                <Link
                  href={{
                    pathname: routes.directory_member_by_id('[member_id]'),
                  }}
                  as={{
                    pathname: routes.directory_member_by_id({
                      Id: comment.PostedBy.CoworkerId,
                      FullName: comment.PostedBy.FullName,
                    }),
                  }}
                >
                  <img
                    className="avatar avatar--40 mr-8 cp"
                    src={`${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${comment.PostedBy.CoworkerId}?h=80&w=80&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}
                    alt={comment.PostedBy.FullName}
                  />
                </Link>
                <div className="d-inline-block bg-gray-100 rounded py-8 px-12">
                  <div className="fs-14">
                    <Link
                      href={{
                        pathname: routes.directory_member_by_id('[member_id]'),
                      }}
                      as={{
                        pathname: routes.directory_member_by_id({
                          Id: comment.PostedBy.CoworkerId,
                          FullName: comment.PostedBy.FullName,
                        }),
                      }}
                    >
                      <a>{comment.PostedBy.FullName}</a>
                    </Link>{' '}
                    <span className="text-gray-600">
                      <time
                        dateTime={moment(comment.CreatedOnUtc)
                          .locale('en')
                          .format()}
                        className="timeago"
                      >
                        {moment(comment.CreatedOnUtc + 'Z').format('LL')}
                      </time>
                    </span>
                    {isLoggedIn && comment.PostedBy.Id == me.Id && (
                      <a
                        onClick={(ev) => {
                          ev.preventDefault();
                          deleteComment(comment.Id);
                        }}
                        href="#"
                        className="btn btn-link text-gray-600 ml-8 pb-3 tdn"
                        title={t('Delete comment')}
                      >
                        <i className="icon-delete"></i>
                      </a>
                    )}
                  </div>
                  {comment.Title && (
                    <div className="fs-14 fw-500">
                      {removeHtml(comment.Title)}
                    </div>
                  )}
                  <div className="fs-14">{removeHtml(comment.Text)}</div>
                </div>
              </div>
            </ScrollableAnchor>
          </>
        );
      })
    )
  )
);
