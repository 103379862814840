import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import ProfilePageLayout from 'ui/layouts/ProfilePageLayout';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { privateRoute } from 'env/privateRoute';
import { withRouter } from 'next/router';
import { routes } from 'env/routes';
import { BookingDetails } from 'ui/_pages/bookings/calendar/BookingDetails';
import { withCustomComponent } from 'ui/components/withCustomComponent';

import { CalendarSynSection } from 'ui/_pages/profile/myBookings/CalendarSyncSection';
import { MyBookingsTable } from 'ui/_pages/profile/myBookings/MyBookingsTable';
import Head from 'next/head';

@withCustomComponent('BookingsPage')
@privateRoute
@withRouter
@withTranslation()
@inject('appStore', 'authStore', 'bookingsStore')
@observer
class BookingsPage extends Component {
  componentDidMount() {
    const { router, t, appStore, bookingsStore } = this.props;
    bookingsStore.loadUpcomingBookings().catch(() => {
      appStore.setPopMessage(t('Could not load your bookings.'));
    });

    const $ = window.$;
    $('#bookings-booking-form').modal('hide');
  }

  componentDidUpdate(prevProps, prevState) {
    const { router, bookingsStore } = this.props;
    const { booking_id } = router.query;
    const { isLoadingBooking, booking } = bookingsStore;
    if (booking_id > 0) {
      if (!isLoadingBooking && (booking == null || booking.Id != booking_id)) {
        bookingsStore.loadBooking(booking_id);
      }
    } else if (booking && booking.Id > 0) {
      bookingsStore.closeBooking();
    }
  }

  closeBooking = () => {
    const { router } = this.props;

    router.query = '0';
    router.push(routes.my_bookings);
  };

  render() {
    const { router, t, authStore, appStore, bookingsStore } = this.props;
    const { booking, upcomingBookings, hasLoadedUpcomingBookings } =
      bookingsStore;
    const { business, configuration } = appStore;
    const { customer } = authStore;
    const hasLoadedData = hasLoadedUpcomingBookings;

    return (
      <ProfilePageLayout componentName={this.props.componentName}>
        <Head>
          <title>
            {t('My bookings')} - {t(appStore.business.Name)}
          </title>
        </Head>

        <BookingDetails close={() => this.closeBooking()} />

        {!hasLoadedData && (
          <form id="profile-form">
            <fieldset className="section--settings" id="your-plan">
              <legend className="h3 section__title">
                <LoadingSpinner />
              </legend>
            </fieldset>
          </form>
        )}

        {hasLoadedData && (
          <form className="profile-form">
            <MyBookingsTable />
            <CalendarSynSection />
          </form>
        )}
      </ProfilePageLayout>
    );
  }
}

export default BookingsPage;
