import StoreBase from './storeBase';
import {action, observable, observe, toJS} from 'mobx';
import {isServer} from 'env/ssr/ServerSideRenderManager';
import {getStores} from './stores';
import i18n from '../i18n';

const crypto = require('crypto');

class QrPaymentStore extends StoreBase {

  constructor({data, host} = {}) {
    super({data, host});

    if (!isServer() && window.localStorage) {
    }
  }

  customerLang() {
    let customerLanguage = i18n.language.toLowerCase() ?? 'de';
    return customerLanguage.split('-').shift();
  }

  /**
   * Get an invoice file
   *
   * @param {object} invoice - Invoice object
   * @param {object} args
   * @param {string|number} args.providerIndex
   * @param {string} args.type - "qri" - Invoice; "qrc" - QR code image; "qrb" - ??? WHF, I do not know!
   * @returns {*|boolean}
   */
  getInvoiceFile(invoice, args = {}) {
    let {
      providerIndex = false,
      type = 'qri',
    } = args;
    let file = this.getFiles({
      providerIndex,
    }).find(file => {
      return file.invoice_id === invoice.Id && file.metadata.type === type;
    });

    return file ? file : false;
  };

  /**
   * Return invoice files
   *
   * @param {object} args
   * @param {string|number} args.providerIndex
   * @param {string[]} args.type
   * @param {boolean} args.paid
   * @returns {*[]}
   */
  getFiles(args = {}) {
    let {
      providerIndex = false,
      type = args.type ?? ['qrc', 'qri', 'qrb'],
      paid = null,
    } = args;
    const invoices = toJS(getStores().invoicingStore?.invoices) ?? [];
    const files = toJS(getStores().filesStore?.myFiles?.Files) ?? [];
    let invoiceQRFilesArray = [];
    const customerLang = this.customerLang();

    invoices.map(invoice => {
      if (paid !== null && !(invoice.Paid === paid)) {
        return;
      }

      files.map(file => {
        if (
          'string' === typeof file.Description
          && file.Description.indexOf(';') > 0
        ) {

          const metaDataQR = file.Description.split(';');

          // Check the "providerIndex"
          if (providerIndex && !(providerIndex === parseInt(metaDataQR[0]))) {
            return;
          }

          if (
            Array.isArray(metaDataQR)
            && metaDataQR.length >= 3
            && type.includes(metaDataQR[1])
            && invoice.IdString === metaDataQR[2]
            && customerLang === metaDataQR[4]
          ) {
            invoiceQRFilesArray.push({
              invoice_id: invoice.Id,
              file_id: file.IdString,
              file: file,
              metadata: {
                type: metaDataQR[1],
                hash: metaDataQR[3],
                customerLang: metaDataQR[4],
              },
            });
          }
        }
      });
    });

    return invoiceQRFilesArray;
  }

  /**
   * @todo Allow Oleg to check it do we need it?
   *
   * Return QR files
   *
   * Requires:
   *  - Invoices
   *  - Files
   *
   * @returns {*[]}
   */
  _getFiles(args = {}) {
    let type = args.type ?? ['qrc', 'qri', 'qrb'];
    const invoices = getStores().invoicingStore.invoices ?? [];
    const files = getStores().filesStore.myFiles.Files ?? [];
    const {business} = getStores().appStore;
    let invoiceQRFilesArray = [];
    const customerLang = this.customerLang();

    invoices.map((invoice) => {
      if (!invoice.Paid) {
        let validQRInvoice;

        const signatureStr = _.join([
          invoice.CoworkerId,
          invoice.BillToCountry.Id,
          invoice.BillToPostCode,
          invoice.BillToCity,
          invoice.BillToAddress,
          invoice.BillToName,
          invoice.Id,
          invoice.InvoiceNumber,
          invoice.TotalAmount,
          invoice.DueAmount,
          invoice.Currency.Code,
          business.BillingAccountCode,
        ], '//');

        const signature = crypto.createHash('sha256').update(signatureStr).digest('hex');

        let validFiles = [];

        files.map((file) => {
          if (
            'string' === typeof file.Description
            && file.Description.indexOf(';') > 0
          ) {

            const metaDataQR = file.Description.split(';');

            if (
              Array.isArray(metaDataQR)
              && metaDataQR.length >= 3
              && type.includes(metaDataQR[1])
              && invoice.IdString === metaDataQR[2]
              && customerLang === metaDataQR[4]
            ) {
              if (metaDataQR[3] === signature) {
                validFiles.push(file);
              }
            }
          }
        });

        if (validFiles.length >= 1) {
          if (validFiles.length > 1) {
            validFiles.sort(function(a, b) {
              return new Date(b.CreatedOnUtc) - new Date(a.CreatedOnUtc);
            });
          }

          validQRInvoice = validFiles[0];

          invoiceQRFilesArray.push({
            invoice_id: invoice.Id,
            file_id: validQRInvoice.IdString,
            file: validQRInvoice,
          });
        }
      }
    });

    return invoiceQRFilesArray;
  }

}

export default QrPaymentStore;