import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import {privateRoute} from 'env/privateRoute';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import Link from 'next/link';
import {routes} from 'env/routes';
import {withCustomComponent} from 'ui/components/withCustomComponent';

import NewSupportMessageForm from 'ui/_pages/support/NewSupportMessageForm';

@withCustomComponent('NewSupportMessagePage')
@privateRoute
@withTranslation()
@inject('appStore', 'authStore', 'supportStore')
@observer
class NewSupportMessagePage extends Component {
  componentDidMount() {
    const {supportStore} = this.props;
    const $ = window.$;

    supportStore.createNewMessage();
    supportStore.loadDepartments().catch((err) => {
      appStore.setPopMessage(
        t('Sorry, we could not load this page. {{err}}', {err}),
      );
    });

    $('#support-message-modal').modal({
      backdrop: 'static',
      keyboard: false,
      show: true,
    });
  }

  componentWillUnmount() {
    const $ = window.$;
    $('#support-message-modal').modal('hide');
  }

  render() {
    const {baseUrl, t, appStore, supportStore, authStore} = this.props;
    const {business} = appStore;
    const {customer} = authStore;
    const {newMessage, hasLoadedDepartments, departments} = supportStore;

    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={t('New help request - {{Name}}', {Name: t(business.Name)})}
      >
        <main className="content content--custom">
          <div className="container">
            <div
              data-component-name={this.props.componentName}
              className="modal modal-support-message"
              id="support-message-modal"
            >
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <Link href={routes.support}>
                    <a className="close">
                      <i className="icon-close"></i>
                    </a>
                  </Link>
                  <div className="modal-body">
                    {!hasLoadedDepartments && <LoadingSpinner/>}
                    {hasLoadedDepartments && (
                      <NewSupportMessageForm
                        departments={departments}
                        newMessage={newMessage}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default NewSupportMessagePage;
