import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const ContactDetails = withCustomComponent('ContactDetails')(
  inject('appStore')(
    withRouter(
      observer(({ router, appStore, componentName }) => {
        const { t } = useTranslation();
        const { business } = appStore;
        return (
          <div data-component-name={componentName}>
            <h3 className="h4">{t('Contact details')}</h3>
            <div className="contact-details">
              <div className="row">
                <div className="col-sm-4 contact-details__item">
                  <p>
                    <b>{t(business.Name)}</b>
                    <br />
                    {business.Address?.split('\n').map((str) => (
                      <span>
                        {str}
                        <br />
                      </span>
                    ))}
                  </p>
                </div>
                <div className="col-sm-8 contact-details__item">
                  {business.Phone && (
                    <p>
                      {t('Tel.')}{' '}
                      <a href={`tel:${business.Phone}`}>{business.Phone}</a>
                    </p>
                  )}
                  {business.Fax && (
                    <p>
                      {t('Fax.')} {business.Fax}
                    </p>
                  )}
                  {business.EmailContact && (
                    <p>
                      <a
                        className="link--secondary contact-details__url"
                        href={`mailto:${business.EmailContact}`}
                      >
                        {business.EmailContact}
                      </a>
                    </p>
                  )}
                  {business.WebContact && (
                    <p>
                      <a
                        className="link--secondary contact-details__url"
                        target="_blank"
                        href={`http://${business.WebContact?.replace(
                          'http://',
                          ''
                        ).replace('https://', '')}`}
                      >
                        {business.WebContact}
                      </a>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })
    )
  )
);
