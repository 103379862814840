/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
@withCustomComponent('PlanDescriptionModal')
@withTranslation()
@observer
class PlanDescriptionModal extends Component {
  componentDidMount() {
    window.$('#service-description-form').modal({
      backdrop: 'static',
      keyboard: false,
      show: true,
    });
  }

  componentWillUnmount() {
    window.$('#service-description-form').modal('hide');
  }

  render() {
    const { t } = this.props;
    return (
      <div
        data-component-name={this.props.componentName}
        className="modal plan-description-modal"
        id="service-description-form"
        role="dialog"
        aria-labelledby="modal-bookLabel"
      >
        <div className="modal-dialog modal-md modal-dialog-centered" role="document">
          <div className="modal-content">
            {/*
             <button
              type="button"
              className="close"
              aria-label={t('Close')}
              onClick={this.props.close}
            >
              <span aria-hidden="true">&times;</span>
            </button>
             */}
            <div className="modal-header">
              <h3 className="modal-title" id="modal-bookLabel">
                {this.props.service.Name}
              </h3>
            </div>
            <div className="modal-body fr-view">
              <span
                dangerouslySetInnerHTML={{
                  __html: this.props.service.Description,
                }}
              ></span>
            </div>
            <div className="modal-footer">
              <a
                onClick={(ev) => {
                  ev.preventDefault();
                  this.props.close();
                }}
                href="#"
                className="btn"
              >
                {t('Close')}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PlanDescriptionModal;
