import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import Link from 'next/link';
import { withCustomComponent } from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';

@withCustomComponent('TourCompletePage')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class TourCompletePage extends Component {
  componentDidMount() {
    const $ = window.$;
    $('#request-tour-complete-modal').modal({
      backdrop: 'static',
      keyboard: false,
      show: true,
    });
  }

  componentWillUnmount() {
    const $ = window.$;
    $('#request-tour-complete-modal').modal('hide');
  }

  render() {
    const { t, appStore } = this.props;
    const { business } = appStore;
    return (
      <DefaultLayout
        title={t('Request a tour of {{Name}}', { Name: t(business.Name) })}
      >
        <main className="content content--custom" role="main">
          <div className="container">
            <div
              data-component-name={this.props.componentName}
              className="modal modal-request-tour-complete"
              id="request-tour-complete-modal"
            >
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <a
                    className="close"
                    href={
                      business.WebContact
                        ? 'http://' +
                          business.WebContact.replace('http://', '').replace(
                            'https://',
                            ''
                          )
                        : '/'
                    }
                  >
                    <i className="icon-close"></i>
                  </a>
                  <div className="modal-body text-center p-16 p-md-60">
                    <svg
                      width="80px"
                      height="80px"
                      viewBox="0 0 80 80"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        id="Icons/Large/Request-Sent"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <g
                          id="Group"
                          transform="translate(10.000000, 6.000000)"
                          strokeWidth="3"
                        >
                          <g id="Path">
                            <path
                              d="M46.67,17.3333333 L52.1533333,17.3333333 C56.4866667,17.3333333 60,20.8466667 60,25.1833333 L60,25.1833333 C60,27.7866667 58.71,30.22 56.5566667,31.68 C52.0933333,34.7066667 44.0966667,40.1266667 37.4766667,44.6133333 C32.96,47.6766667 27.0366667,47.6733333 22.52,44.6133333 C16.1,40.2633333 7.96,34.7433333 3.43333333,31.6766667 C1.28,30.2133333 0,27.7866667 0,25.1833333 L0,25.1833333 C0,20.8466667 3.51333333,17.3333333 7.85,17.3333333 L13.3333333,17.3333333"
                              stroke="#28B95F"
                            ></path>
                            <path
                              d="M0,25.1833333 L0,54 C0,59.5233333 4.47666667,64 10,64 L50,64 C55.5233333,64 60,59.5233333 60,54 L60,25.1833333"
                              stroke="#28B95F"
                            ></path>
                            <path
                              d="M14.2466667,20.0433333 L16.46,22.94 L16.94,26.5433333 C17.2066667,28.5433333 18.7766667,30.1166667 20.7766667,30.3833333 L24.39,30.8666667 L27.2833333,33.08 C28.8833333,34.3033333 31.1066667,34.3033333 32.7066667,33.08 L35.6033333,30.8666667 L35.6,30.8666667 L39.2066667,30.3866667 C41.2066667,30.12 42.78,28.55 43.0466667,26.55 L43.53,22.9366667 C43.53,22.94 44.65,21.4733333 45.7433333,20.0433333 C46.9666667,18.4433333 46.9666667,16.22 45.7433333,14.62 L43.53,11.7233333 L43.05,8.12 C42.7833333,6.12 41.2133333,4.54666667 39.2133333,4.28 L35.6,3.79666667 L32.7066667,1.58333333 C31.1066667,0.36 28.8833333,0.36 27.2833333,1.58333333 L24.3866667,3.79666667 L24.39,3.79666667 L20.78,4.27666667 C18.78,4.54333333 17.2066667,6.11333333 16.94,8.11333333 L16.46,11.7266667 C16.46,11.7233333 15.34,13.19 14.2466667,14.62 C13.0233333,16.2233333 13.0233333,18.4433333 14.2466667,20.0433333 Z"
                              stroke="#28B95F"
                              fill="#28B95F"
                            ></path>
                            <polyline
                              stroke="#FFFFFF"
                              points="35.1833333 14.74 28.7 21.2233333 24.81 17.3333333"
                            ></polyline>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <h2 className="mt-20 mb-8">
                      {t('Thank you for your request!')}
                    </h2>
                    <p className="text-gray-700 mb-24">
                      {t(
                        'We will get back to you as soon as possible. Usually takes up to 24 hours for us to reply to tour requests.'
                      )}
                    </p>
                    <a
                      href={
                        business.WebContact
                          ? 'http://' +
                            business.WebContact.replace('http://', '').replace(
                              'https://',
                              ''
                            )
                          : '/'
                      }
                      className="btn btn-gray"
                    >
                      {t('Okay, Got it!')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default TourCompletePage;
