import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { routes } from 'env/routes';
import { withTranslation } from 'react-i18next';
import { slugify, removeHtml } from 'env/utils/strings';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('CardProduct')
@withTranslation()
@inject('appStore')
@observer
class CardProduct extends Component {
  static propTypes = {
    room: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const $ = window.$;
    if ($('[data-toggle="tooltip"]').length > 0) {
      $('[data-toggle="tooltip"]').tooltip({
        html: true,
      });
    }
  }

  render() {
    const { room, t, componentName, appStore } = this.props;
    const { business } = appStore;

    const displayParticipantsNo = 5;

    return (
      <div
        data-component-name={componentName}
        className={`card card-video-room`}
      >
        {room.IsTeamRoom && (
          <Link
            as={routes.virtual_rooms_team_stream('team', room.Id)}
            href={routes.virtual_rooms_team_stream('[room_id]', room.Id)}
          >
            <a
              className="card-video-room__image"
              style={{
                backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/Team/GetAvatar?id=${room.Id}&w=480&h=260)`,
              }}
            >
              <span
                className="tag with-icon-left"
                data-toggle="tooltip"
                title={t(
                  'This room can be accessed just by your team or yourself unless you share the meeting link with other participants.'
                )}
              >
                <i className="icon-lock"></i>
                {t('Private')}
              </span>
            </a>
          </Link>
        )}
        {room.IsPersonalRoom && (
          <Link
            as={routes.virtual_rooms_by_id('me')}
            href={routes.virtual_rooms_by_id('[room_id]')}
          >
            <a
              className="card-video-room__image"
              style={{
                backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/Coworker/GetAvatar?id=${room.Id}&w=480&h=260)`,
              }}
            >
              <span
                className="tag with-icon-left"
                data-toggle="tooltip"
                title={t(
                  'This room can be accessed just by your team or yourself unless you share the meeting link with other participants.'
                )}
              >
                <i className="icon-lock"></i>
                {t('Private')}
              </span>
            </a>
          </Link>
        )}
        {!room.IsTeamRoom && !room.IsPersonalRoom && (
          <Link
            as={routes.virtual_rooms_by_id(room.Id)}
            href={routes.virtual_rooms_by_id('[room_id]')}
          >
            <a
              className="card-video-room__image"
              style={{
                backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/VideoRooms/GetImage?id=${room.Id}&w=480&h=260&cache=${room.UpdatedOn})`,
              }}
            ></a>
          </Link>
        )}
        <div className="card-video-room__content">
          <div>
            {room.IsTeamRoom && (
              <>
                <Link
                  as={routes.virtual_rooms_team_stream('team', room.Id)}
                  href={routes.virtual_rooms_team_stream('[room_id]', room.Id)}
                >
                  <a className="text-gray-900">
                    <h4 className="mb-0">{room.Name}</h4>
                  </a>
                </Link>
                <p className="mt-8 mb-0">
                  {t('Private Team Virtual Room for {{Name}} team members.', {
                    Name: room.Name,
                  })}
                </p>
              </>
            )}
            {room.IsPersonalRoom && (
              <>
                <Link
                  as={routes.virtual_rooms_by_id('me')}
                  href={routes.virtual_rooms_by_id('[room_id]')}
                >
                  <a className="text-gray-900">
                    <h4 className="mb-0">{room.Name}</h4>
                  </a>
                </Link>
                <p className="mt-8 mb-0">
                  {t('Private Personal Virtual Room for {{Name}}.', {
                    Name: room.Name,
                  })}
                </p>
              </>
            )}
            {!room.IsTeamRoom && !room.IsPersonalRoom && (
              <>
                <Link
                  as={routes.virtual_rooms_by_id(room.Id)}
                  href={routes.virtual_rooms_by_id('[room_id]')}
                >
                  <a className="text-gray-900">
                    <h4 className="mb-0">{room.Name}</h4>
                  </a>
                </Link>
                {room.Description && (
                  <p className="mt-8 mb-0">{room.Description}</p>
                )}
              </>
            )}
          </div>
          <div className="card-video-room__footer">
            <div>
              {room.Participants && room.NumberOfParticipants > 0 && (
                <ul className="list-avatars mt-8">
                  {/* BB-TODO: We dont have room.Participants array inside room object */}
                  {_.take(room.Participants, displayParticipantsNo).map(
                    (participantId, i) => (
                      <li key={i}>
                        <Link
                          href={{
                            pathname:
                              routes.directory_member_by_id('[member_id]'),
                          }}
                          as={{
                            pathname: routes.directory_member_by_id({
                              Id: participantId,
                            }),
                          }}
                        >
                          <a
                            className="avatar avatar--40 avatar--border-2-s-white"
                            style={{
                              backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${participantId}?h=80&w=80&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png)`,
                            }}
                          ></a>
                        </Link>
                      </li>
                    )
                  )}
                  {room.Participants.length > displayParticipantsNo && (
                    <li className="rest">
                      {'+'}
                      {room.Participants.length - displayParticipantsNo}
                    </li>
                  )}
                </ul>
              )}

              <div className="d-flex align-items-center mt-8">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 44 44"
                  width="40"
                  height="40"
                >
                  <g>
                    <circle fill="#CCCCCC" cx="22" cy="19.3" r="8.7" />
                    <path
                      fill="#CCCCCC"
                      d="M22,0C9.9,0,0,9.9,0,22s9.9,22,22,22s22-9.9,22-22S34.1,0,22,0z M36.8,35.4c-0.4-0.8-1-1.5-1.7-2.1c-1.5-1.3-3.4-2-5.4-2H14.3c-2,0-4,0.7-5.4,2c-0.7,0.6-1.2,1.3-1.7,2.1C4,31.8,2,27.1,2,22C2,11,11,2,22,2c11,0,20,9,20,20C42,27.1,40,31.8,36.8,35.4z"
                    />
                  </g>
                </svg>
                <span className="ml-12 fs-18 fw-500">
                  {room.NumberOfParticipants}
                </span>
              </div>
            </div>
            <div>
              {room.IsTeamRoom && (
                <Link
                  as={routes.virtual_rooms_team_stream('team', room.Id)}
                  href={routes.virtual_rooms_team_stream('[room_id]', room.Id)}
                >
                  <a className="btn btn-icon">
                    <i className="icon-video-call"></i>
                    {t('Join in')}
                  </a>
                </Link>
              )}
              {room.IsPersonalRoom && (
                <Link
                  as={routes.virtual_rooms_by_id('me')}
                  href={routes.virtual_rooms_by_id('[room_id]')}
                >
                  <a className="btn btn-icon">
                    <i className="icon-video-call"></i>
                    {t('Join in')}
                  </a>
                </Link>
              )}
              {!room.IsTeamRoom && !room.IsPersonalRoom && (
                <Link
                  as={routes.virtual_rooms_by_id(room.Id)}
                  href={routes.virtual_rooms_by_id('[room_id]')}
                >
                  <a className="btn btn-icon">
                    <i className="icon-video-call"></i>
                    {t('Join in')}
                  </a>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardProduct;
