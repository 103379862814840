import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import Link from 'next/link';
import { routes } from 'env/routes';
import withCustomComponent from 'ui/components/withCustomComponent';

import CardEvent from 'ui/components/cards/CardEvent';
import CardPerkDiscount from 'ui/components/cards/CardPerkDiscount';

@withCustomComponent('VirtualNotificationCard')
@withTranslation()
@inject('appStore')
@observer
class VirtualNotificationCard extends Component {
  componentDidMount() {}

  render() {
    const { t, appStore, dummyNo } = this.props;
    const { business } = appStore;

    const contentData = [
      {
        Business: { Name: 'Kalkio Network' },
        HasTickets: true,
        ChepeastPrice: 50,
        ChepeastProduct: {
          PriceCurrencyCode: 'USD',
          Price: 50,
        },
        MostExpensivePrice: 50,
        MostExpensiveProduct: {
          PriceCurrencyCode: 'USD',
          Price: 50,
        },
        AttendeeCount: 12,
        EventCategories: [
          { Id: 1, Title: 'Yoga' },
          { Id: 2, Title: 'Workshop' },
        ],
        HasTickets: true,
        Id: 1006033031,
        IdString: '1006033031',
        Location: null,
        LongDescription:
          'Hey everyone, to celebrate the anniversary of the space opening next year we will be pooling our resident musicians together and having a music extravaganza! Make sure to book your ticket early to avoid disappointment!<br><br>See you all there!',
        Name:
          'Learn a Proper Mandala Meditation with Laruga Glaser and John Adams',
        ShortDescription:
          'Take a 101 course with award winning paintor Antonio Giuliani on how to use acrylics to transform your drawings into world class art.',
        StartDate: '2025-01-16T21:20:00',
        EndDate: '2025-01-16T22:20:00',
      },
      {
        Active: true,
        DisplayOrder: 1,
        FullText: '',
        GroupName: 'Computing',
        HasImage: true,
        HasLargeImage: true,
        Id: 1392930539,
        SummaryText:
          'Try Any Azure Services & Keep Going For Free, Regardless Of Subscription Type. 25+ Products Always Free. Learn by Doing. Start with a Free Credit. Build Your Next Idea. Try Popular Products Free.',
        Title: '12 months free Azure Computing',
        UniqueId: '91e61cfa-745c-48ad-8e0a-33b642204a56',
      },
      {
        Business: { Name: 'Kalkio Network' },
        HasTickets: true,
        ChepeastPrice: 40,
        ChepeastProduct: {
          PriceCurrencyCode: 'USD',
          Price: 40,
        },
        MostExpensivePrice: 40,
        MostExpensiveProduct: {
          PriceCurrencyCode: 'USD',
          Price: 40,
        },
        AttendeeCount: 12,
        EventCategories: [{ Id: 1, Title: 'Workout' }],
        HasTickets: true,
        Id: 1006033032,
        IdString: '1006033032',
        Location: null,
        LongDescription:
          'Hey everyone, to celebrate the anniversary of the space opening next year we will be pooling our resident musicians together and having a music extravaganza! Make sure to book your ticket early to avoid disappointment!<br><br>See you all there!',
        Name: 'Cross-fit Session',
        ShortDescription:
          'Take a 101 course with award winning paintor Antonio Giuliani on how to use acrylics to transform your drawings into world class art.',
        StartDate: '2025-01-18T21:20:00',
        EndDate: '2025-01-18T22:20:00',
      },
    ];

    const dummyThreadArray = [
      {
        CoworkerId: 1159872173,
        FullName: 'Roger Davis',
        eventAction:
          'has joined the Lifeboost community! Wish him warm welcome.',
        eventTime: '5 hours ago',
        actionButton1: 'Send message',
        actionButton2: 'See profile',
        content: null,
      },
      {
        eventAction:
          'New event added, check it out and buy tickets while they are still available.',
        eventTime: '6 hours ago',
        actionButton1: 'Buy Tickets',
        actionButton2: 'See details',
        content: <CardEvent event={contentData[0]} sizeMedium={true} />,
      },
      {
        eventAction:
          'New discount added, check it out and save 10% for online orders.',
        eventTime: '1 day ago',
        actionButton1: 'Claim',
        actionButton2: 'See details',
        content: (
          <CardPerkDiscount perk={contentData[1]} withoutButtons={true} />
        ),
      },
      {
        eventAction: 'You have a new invoice INV - 12/2020 for 200.00$.',
        eventTime: '1 day ago',
        actionButton1: 'Pay now',
        actionButton2: 'Download',
        content: null,
      },
      {
        eventAction: 'New virtual room added, join the conversation.',
        eventTime: '2 days ago',
        actionButton1: 'Join in',
        actionButton2: 'See other room',
        content: <CardEvent event={contentData[2]} sizeMedium={true} />,
      },
    ];
    const thread = dummyThreadArray[dummyNo];

    return (
      <div
        data-component-name={this.props.componentName}
        className="virtual-notification-card"
      >
        <div className="card h-auto p-16 p-md-24 mb-24">
          <header className="media mb-32">
            {thread?.CoworkerId ? (
              <Link
                href={{
                  pathname: routes.directory_member_by_id('[member_id]'),
                }}
                as={{
                  pathname: routes.directory_member_by_id({
                    Id: thread.CoworkerId,
                    FullName: thread.FullName,
                  }),
                }}
              >
                <a
                  className="avatar avatar--48"
                  style={{
                    backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${thread.CoworkerId}?h=96&w=96&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png)`,
                  }}
                ></a>
              </Link>
            ) : (
              <div
                className="avatar avatar--48"
                style={{
                  backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/business/getlogo?h=144&mode=pad)`,
                }}
              ></div>
            )}
            <div className="media-body d-flex justify-content-between pl-16">
              {thread?.CoworkerId ? (
                <div className="text-gray-600">
                  <div className="fw-500">
                    <a href="#">{thread.FullName}</a> {thread.eventAction}
                  </div>
                  <div className="fs-14 lh-14">{thread.eventTime}</div>
                </div>
              ) : (
                <div>
                  <div className="fw-500 text-gray-900">
                    {thread?.eventAction}
                  </div>
                  <div className="fs-14 lh-14 text-gray-600">
                    {thread?.eventTime}
                  </div>
                </div>
              )}
              <div className="dropdown">
                <button
                  className="btn btn-single-icon btn-white dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <i className="icon-more"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button className="dropdown-item">Mute message</button>
                  <button className="dropdown-item">Delete message</button>
                </div>
              </div>
            </div>
          </header>
          {thread?.content && (
            <div className="d-block mb-32">{thread.content}</div>
          )}
          <footer>
            <div className="btn-group">
              <a className="btn ">{thread?.actionButton1}</a>
              <a className="btn  btn-white">{thread?.actionButton2}</a>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default VirtualNotificationCard;
