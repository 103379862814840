import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import withCustomComponent from 'ui/components/withCustomComponent';

import DefaultLayout from 'ui/layouts/DefaultLayout';
import DirectoryHeader from 'ui/_pages/directory/DirectoryHeader';

@withCustomComponent('DirectoryLayout')
@withTranslation()
@inject('appStore', 'authStore', 'communityStore')
@observer
class DirectoryLayout extends Component {
  render() {
    const {
      isLoading,
      customFields,
      topTags,
      title,
      componentName,
      showType = true,
    } = this.props;

    return (
      <DefaultLayout title={title} componentName={this.props.componentName}>
        <main
          data-component-name={componentName}
          className="content content--custom"
        >
          <div className="container">
            <DirectoryHeader
              topTags={topTags}
              customFields={customFields}
              showType={showType}
            />

            {isLoading && <LoadingSpinner />}
            {!isLoading && this.props.children}
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default DirectoryLayout;
