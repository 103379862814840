import Agent from 'env/agent';

class StoreBase {
  constructor({ data = {}, host }) {
    this.host = host;
    Object.keys(data).forEach((key, i) => {
      this[key] = data[key];
    });

    this.getAgent = () => new Agent(host);
  }
}

export default StoreBase;
