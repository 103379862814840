import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { routes } from 'env/routes';
import { withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'next/router';
import withCustomComponent from 'ui/components/withCustomComponent';
import Head from 'next/head';
import { removeHtml, shorten } from 'env/utils/strings';

@withCustomComponent('CourseMetaTags')
@withRouter
@withTranslation()
@inject('appStore', 'coursesStore')
@observer
class CourseMetaTags extends Component {
  render() {
    const { business, configuration } = this.props.appStore;
    const course = this.props.coursesStore.coursePage.Course;

    return (
      <Head>
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:site"
          content={`@${configuration['Social.Twitter']}`}
        />
        <meta
          name="twitter:title"
          content={`${shorten(removeHtml(course?.Title), 70)}`}
        />
        <meta
          name="twitter:description"
          content={`${shorten(removeHtml(course?.SummaryText), 200)}`}
        />
        <meta
          name="twitter:creator"
          content={`@${configuration['Social.Twitter']}`}
        />
        {course?.HasLargeImage && (
          <meta
            name="twitter:image:src"
            content={`${business.NativeHomeUrlWithLanguage}/learn/getCourseimage?id=${course.Id}&w=800`}
          />
        )}

        <meta
          property="og:url"
          key="og:url"
          content={`${business.HomeUrl}/${routes.course_by_id(course)}?public`}
        />
        <meta
          property="og:title"
          key="og:title"
          content={removeHtml(course?.Title)}
        />
        {course?.LargeImageFileName && (
          <meta
            property="og:image"
            key="og:image"
            content={`${business.NativeHomeUrlWithLanguage}/learn/getCourseimage?id=${course.Id}&w=600h=314&mode=Pad`}
          />
        )}
        <meta
          property="og:image:type"
          content="image/jpeg"
          key="og:image:type"
          content="image/jpeg"
        />
        <meta
          property="og:description"
          key="og:description"
          content={removeHtml(course?.SummaryText)}
        />
        <meta
          property="og:site_name"
          key="og:site_name"
          content={business.Name}
        />
      </Head>
    );
  }
}

export default CourseMetaTags;
