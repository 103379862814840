import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import { routes } from 'env/routes';
import { privateRoute } from 'env/privateRoute';
import { withRouter } from 'next/router';
import LoadingSpinner from 'ui/components/LoadingSpinner';

import { PerkMetaData } from 'ui/_pages/perks/PerkMetaData';
import { PerkDetails } from 'ui/_pages/perks/PerkDetails';

@privateRoute
@withRouter
@withCustomComponent('FaqArticlePage')
@withTranslation()
@inject('appStore', 'contentStore', 'authStore')
@observer
class FaqArticlePage extends Component {
  componentDidMount() {
    const { contentStore } = this.props;

    if (!contentStore.hasLoadedCommunityPerks)
      contentStore.loadCommunityPerks();
  }

  render() {
    const { t, router, appStore, contentStore } = this.props;
    const { business } = appStore;
    const { communityPerks } = contentStore;

    const perk = communityPerks.find((a) => a.Id == router.query.perk_id);

    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={t('Perks and benefits') + ' - ' + t(business.Name)}
      >
        <main className="content content--custom" role="main">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-1 d-none d-xl-block">
                <Link href={routes.perks}>
                  <a className="btn btn-single-icon btn-white">
                    <i className="icon-go-back"></i>
                  </a>
                </Link>
              </div>
              <div className="col-12 col-xl-10">
                {!perk && <LoadingSpinner />}
                {perk && (
                  <div className="card h-auto">
                    <PerkMetaData perk={perk} />
                    <PerkDetails perk={perk} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default FaqArticlePage;
