import { inject, observer } from 'mobx-react';
import { withRouter } from 'next/router';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { CoworkerAvatar } from 'ui/components/CoworkerAvatar';
import withCustomComponent from 'ui/components/withCustomComponent';

@withRouter
@withCustomComponent('FloorPlanSideBar')
@withTranslation()
@inject(
  'appStore',
  'authStore',
  'bookingsStore',
  'checkoutStore',
  'floorPlanStore'
)
@observer
export default class FloorPlanSideBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
    };
  }

  render() {
    const { highlightDesk, floorPlanStore, componentName, t } = this.props;
    const { groupedFilteredItems } = floorPlanStore;
    const hasCoworkers =
      groupedFilteredItems.find((i) => i.Coworkers.length > 0) != null;

    return (
      <>
        {hasCoworkers && (
          <div
            data-component-name={componentName}
            className="d-none d-lg-block floorplans__sidebar box col floorplans__map mt-20 p-2 mr-10"
          >
            <div class="floorplans__sidebar__title">
              {t('Here at this time')}
            </div>
            <div>
              <input
                value={this.state.searchTerm}
                onChange={(ev) =>
                  this.setState({ searchTerm: ev.target.value })
                }
                class="form-control"
                name="Search"
                placeholder={t('Search')}
                type="text"
              />
            </div>
            <div className="who-is-in-wrapper">
              {groupedFilteredItems.map((i) =>
                i.Coworkers.filter(
                  (c) =>
                    c.CoworkerFullName &&
                    c.CoworkerFullName.toLowerCase().includes(
                      this.state.searchTerm.toLowerCase()
                    )
                )
                  .sort((a, b) =>
                    a.CoworkerFullName.localeCompare(b.CoworkerFullName)
                  )
                  .map((c) => {
                    return (
                      <div
                        title={c.CoworkerFullName + ` (${i.Name})`}
                        onClick={() => highlightDesk(i, true)}
                        onMouseOver={() => highlightDesk(i, false)}
                        onMouseLeave={() => highlightDesk(null)}
                        className="floorplans__sidebar__coworker"
                      >
                        <CoworkerAvatar
                          coworker_id={c.CoworkerId}
                          size={100}
                          title={c.CoworkerFullName}
                          imgClassName="avatar avatar--100"
                        />
                        <div>
                          <span>{c.CoworkerFullName}</span>
                          <br />
                          <small>{i.Name}</small>
                        </div>
                      </div>
                    );
                  })
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}
