import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Link from 'next/link';
import _ from 'lodash';
import { routes } from 'env/routes';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import CardEvent from 'ui/components/cards/CardEvent';
import LoadingWidget from 'ui/_pages/home/components/LoadingWidget/LoadingWidget';

@withCustomComponent('EventsWidget')
@withTranslation()
@inject('appStore', 'eventsStore')
@observer
class EventsWidget extends Component {
  componentDidMount() {
    const { eventsStore } = this.props;
    eventsStore.loadUpcomingEvents().catch(() => null);
    eventsStore.loadMyEvents().catch(() => null);
  }

  render() {
    const { t, appStore, eventsStore } = this.props;
    const { hasLoadedUpcomingEvents, upcomingEvents, myEvents } = eventsStore;

    if (!hasLoadedUpcomingEvents)
      return <LoadingWidget title={t('Upcoming events')} />;

    const events = _.take(upcomingEvents, 2);

    if (events.length === 0) return <></>;

    return (
      <div className="col mxw-xl-50 mt-40">
        <div
          data-component-name={this.props.componentName}
          className="dashboard-events-widget"
        >
          <h6>{t('Upcoming events')}</h6>
          {events.map((event, i) => (
            <CardEvent
              myEvents={myEvents}
              event={event}
              sizeSmall={true}
              key={i}
            />
          ))}
          <div className="mt-16">
            <Link href={routes.events}>
              <a className="text-gray-900">{t('See all events')}</a>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default EventsWidget;
