import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import _ from 'lodash';
import {routes} from 'env/routes';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import {NoAccessNotice} from 'ui/components/NoAccessNotice';
import withCustomComponent from 'ui/components/withCustomComponent';
import CardDashboard from 'ui/components/cards/CardDashboard';
import BenefitsRow from 'ui/_pages/home/components/BenefitsWidget/BenefitsRow';
import LoadingWidget from 'ui/_pages/home/components/LoadingWidget/LoadingWidget';

@withCustomComponent('BenefitsWidget')
@withTranslation()
@inject('appStore', 'invoicingStore')
@observer
class BenefitsWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    const {t, invoicingStore} = this.props;
    if (!invoicingStore.hasLoadedBenefits) {
      invoicingStore.loadBenefits().catch((ex) => {
        this.setState({error: t('Could not load your benefits.')});
      });
    }
  }

  render() {
    const {t, invoicingStore} = this.props;
    const {benefits, hasLoadedBenefits, hasBenefits} = invoicingStore;
    const hasError = this.state.error != null;

    if (!hasLoadedBenefits)
      return <LoadingWidget className="" title={t('Benefits')}/>;

    return (
      <CardDashboard
        headerMainTitle={t('Benefits')}
        headerActionTitle={t('View all')}
        headerActionUrl={this.props.headerActionUrl ?? routes.plan}
        footerActionTitle={t('Join a Plan')}
        footerActionUrl={this.props.footerActionUrl ?? routes.plan}
        componentName={this.props.componentName}
      >
        <NoAccessNotice error={this.state.error}/>
        {hasBenefits ? (
          <div className="">
            {_.take(benefits.TimePasses, 1).map((b, i) => {
              const timeMax = b.TotalMinutes > 0 ? b.TotalMinutes : b.Count;
              const timeNow = b.TotalMinutes > 0 ? b.MinutesLeft : b.PassesLeft;
              const timeUnit = b.TotalMinutes > 0 ? t('mins.') : t('passes');
              return (
                <BenefitsRow
                  name={b.CreditName}
                  valueMin={0}
                  valueMax={timeMax}
                  valueNow={timeNow}
                  unit={timeUnit}
                  key={i}
                />
              );
            })}
            {_.take(benefits.BookingCredits, 1).map((b, i) => (
              <BenefitsRow
                name={b.CreditName}
                valueMin={0}
                valueMax={b.TotalCredit}
                valueNow={b.RemainingCredit}
                unit={t('Credits')}
                key={i}
              />
            ))}
            {_.take(benefits.ExtraServices, 1).map((b, i) => {
              const total = b.TotalMinutes
                ? b.TotalMinutes / 60.0
                : b.TotalDays || b.TotalMonths || b.TotalWeeks || b.TotalUses;
              const remaining = b.MinutesLeft
                ? b.MinutesLeft / 60.0
                : b.DaysLeft || b.MonthsLeft || b.WeeksLeft || b.UsesLeft;

              let unit = '';
              if (b.TotalMinutes > 0)
                unit = t('hr.', {count: b.TotalMinutes / 60.0});
              if (b.TotalDays > 0) unit = t('day', {count: b.TotalDays});
              if (b.TotalMonths > 0)
                unit = t('month', {count: b.TotalMonths});
              if (b.TotalWeeks > 0) unit = t('week', {count: b.TotalWeeks});
              if (b.TotalUses > 0) unit = t('use', {count: b.TotalUses});
              return (
                <BenefitsRow
                  name={b.CreditName}
                  valueMin={0}
                  valueMax={total}
                  valueNow={remaining}
                  unit={unit}
                  key={i}
                />
              );
            })}
          </div>
        ) : (
          <>
            {!hasError && (
              <div className="alert" role="alert">
                <span className="icon-alert-2"></span>
                {t('You have no benefits currently.')}
              </div>
            )}
          </>
        )}
      </CardDashboard>
    );
  }
}

export default BenefitsWidget;
