import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { MarkersContainer } from './MarkersContainer';
import Geosuggest from './GeoSuggest';

const LIBRARIES = ['geometry', 'places'];

export const ResourcesMap = withCustomComponent('ResourcesMap')(
  inject(
    'appStore',
    'bookingsStore'
  )(
    withRouter(
      observer(
        ({
          center,
          setCenter,
          resourcesToShow,
          appStore,
          setDistance,
          setHoveringOn,
        }) => {
          const { t } = useTranslation();
          const { business, configuration } = appStore;

          const {
            isLoaded: isGoogleMapsLoaded,
            loadError: googleMapsLoadError,
          } = useJsApiLoader({
            googleMapsApiKey: configuration['Google.ApiKey'],
            libraries: LIBRARIES,
          });

          const [map, setMap] = useState(null);

          const [businessPosition, setBusinessPosition] = useState();

          useEffect(() => {
            if (isGoogleMapsLoaded) {
              setBusinessPositionAndCenter();
            }
          }, [isGoogleMapsLoaded]);

          useEffect(() => {
            if (businessPosition) {
              updateResourcesOnMap();
            }
          }, [center]);

          const setBusinessPositionAndCenter = () => {
            const geocoder = new google.maps.Geocoder();
            var address = business.Address?.replace('\n', ' ').replace(
              '\r',
              ''
            );

            geocoder.geocode({ address }, function (results, status) {
              if (status == google.maps.GeocoderStatus.OK) {
                setBusinessPosition(results[0].geometry.location);
                setCenter(results[0].geometry.location);
              }
            });
          };

          const updateResourcesOnMap = () => {
            if (map?.getBounds()) {
              const mapSWboundLatLng = map.getBounds().getSouthWest();

              const distanceToSWbound =
                google.maps.geometry.spherical.computeDistanceBetween(
                  center,
                  mapSWboundLatLng
                );
              setDistance(distanceToSWbound);
            }
          };

          const onLoad = React.useCallback(function callback(map) {
            setMap(map);
          }, []);

          const onUnmount = React.useCallback(function callback(map) {
            setMap(null);
          }, []);

          const onIdle = React.useCallback(
            function callback() {
              if (map) {
                setCenter(map.getCenter());
              }
            },
            [map]
          );

          return isGoogleMapsLoaded ? (
            <>
              <Geosuggest
                setMapCenter={(location) =>
                  setCenter(new google.maps.LatLng(location))
                }
              />

              <GoogleMap
                mapContainerStyle={{
                  color: 'rgb(196, 196, 196)',
                  margin: '0 0 25px 0px',
                  paddingTop: 180,
                  textAlign: 'center',
                  height: window.innerHeight - 370,
                  backgroundColor: 'rgb(244, 244, 244)',
                }}
                id="google-resources-map"
                center={center}
                zoom={13}
                options={{
                  disableDefaultUI: true,
                  gestureHandling: 'greedy',
                }}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onIdle={onIdle}
              >
                <MarkersContainer
                  resourcesToShow={resourcesToShow}
                  businessPosition={businessPosition}
                  setHoveringOn={setHoveringOn}
                />
              </GoogleMap>
              {resourcesToShow.length == 0 && (
                <div className="map-no-results">
                  <div>
                    {t('We found no results in this area.')}
                    <br />
                    <a
                      href="#"
                      onClick={(ev) => {
                        map.setZoom(map.getZoom() - 2);
                        updateResourcesOnMap();
                        ev.preventDefault();
                      }}
                    >
                      {t('Try a larger search area')}
                    </a>
                  </div>
                </div>
              )}
            </>
          ) : (
            <LoadingSpinner />
          );
        }
      )
    )
  )
);
