import React from 'react';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { CoursesPageCommentsTitle } from './comments/CoursesPageCommentsTitle';
import { CoursesPageCommentsList } from './comments/CoursesPageCommentsList';
import { CoursesPageCommentsForm } from './comments/CoursesPageCommentsForm';

export const CoursePageComments = withCustomComponent('CoursePageComments')(
  inject(
    'appStore',
    'coursesStore'
  )(
    withRouter(
      observer(({ componentName, router, appStore, coursesStore }) => {
        const course = coursesStore.coursePage.Course;

        if (!course.AllowComments) return <></>;

        return (
          course.Comments ? (
            <div
              data-component-name={componentName}
              className="course-page-comments card h-auto pl-16 pl-md-24 pr-16 pr-md-24 pb-24 pt-4 mt-24">
              <CoursesPageCommentsTitle />
              <CoursesPageCommentsList />
              <CoursesPageCommentsForm />
            </div>
          ) : null
        );
      })
    )
  )
);
