import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation, Trans} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import {privateRoute} from 'env/privateRoute';
import {action} from 'mobx';
import {withRouter} from 'next/router';
import {routes} from 'env/routes';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import DefaultLayout from 'ui/layouts/DefaultLayout';

import ProductsHeader from 'ui/_pages/store/ProductsHeader';
import {ProductsList} from 'ui/_pages/store/ProductsList';
import {ProductsTotal} from 'ui/_pages/store/ProductsTotal';

@privateRoute
@withRouter
@withCustomComponent('ProductsPage')
@withTranslation()
@inject('appStore', 'authStore', 'invoicingStore')
@observer
class ProductsPage extends Component {
  componentDidMount() {
    const {t, router, appStore, invoicingStore} = this.props;
    invoicingStore
      .loadStoreProducts()
      .then(
        //Preselect a product if found in the URL
        action((data) => {
          var pid = router.query.product_id;
          if (pid) {
            var foundProduct = data.products.find((p) => p.Id == pid);

            if (foundProduct) {
              foundProduct.Quantity = 1;
              invoicingStore.storeProducts = data;
            }
          }

          return data;
        })
      )
      .catch((err) => {
        appStore.setPopMessage(
          t('Sorry, we could not load this page. {{err}}', {err})
        );
      });
  }

  render() {
    const {t, appStore, invoicingStore} = this.props;
    const {hasLoadedStoreProducts, productsByGroup} = invoicingStore;

    return (
      <DefaultLayout componentName={this.props.componentName}>
        <main className="content content--custom pt-0" role="main">

          {!hasLoadedStoreProducts && <LoadingSpinner/>}
          {hasLoadedStoreProducts && (
            <div>
              <ProductsHeader/>
              <div className="container">
                <ProductsList showZeroCase={true}/>

                {productsByGroup.length > 0 && (
                  <ProductsTotal
                    continueUrl={routes.checkout}
                    canContinueIfNoSelection={false}
                  />
                )}
              </div>
            </div>
          )}
        </main>
      </DefaultLayout>
    );
  }
}

export default ProductsPage;
