import React from 'react';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { BasketItem } from 'ui/_pages/checkout/basketItems/BasketItem';
import moment from 'moment';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';

export const CourseBasketItem = withCustomComponent('CourseBasketItem')(
  inject(
    'appStore',
    'checkoutStore'
  )(
    withRouter(
      observer(({ router, basketItem, checkoutStore, appStore }) => {
        const { t } = useTranslation();
        const { data } = basketItem;
        const { business } = appStore;
        const { basket } = checkoutStore;

        return (
          <BasketItem
            basketItem={basketItem}
            id={data.Id}
            imageUrl={`${business.NativeHomeUrlWithLanguage}/learn/getCourseimage?id=${data.Id}&w=${96}&h=${96}`}
            header={data.Title}
            total={
              <LocalizedPrice
                currency={data.TariffCurrencyCode}
                amount={data.TariffPrice}
              />
            }
          />
        );
      })
    )
  )
);
