import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import SignupPageLayout from 'ui/layouts/SignupPageLayout';
import { InvoicesTable } from 'ui/_pages/invoices/InvoicesTable';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import CheckoutComplete from 'ui/_pages/checkout/CheckoutComplete';

@withCustomComponent('SignupPaymentPage')
@withTranslation()
@inject('appStore', 'authStore', 'invoicingStore')
@observer
class SignupPaymentPage extends Component {
  componentDidMount() {
    const { t, appStore, authStore, invoicingStore } = this.props;
    authStore.loadCustomer().catch((err) => {
      appStore.setPopMessage(
        t('Sorry, we could not load your details. {{err}}', { err })
      );
    });

    invoicingStore.loadInvoicesPage().catch((err) => {
      appStore.setPopMessage(
        t('Sorry, we could not load your invoices. {{err}}', { err })
      );
    });
  }
  render() {
    const { t, invoicingStore, authStore, appStore } = this.props;
    const { invoicesPage, isLoadingInvoicesPage, unpaidInvoices } =
      invoicingStore;
    const { SpreedlyEnabled, StripeACHEnabled, StripeDirectDebitEnabled } =
      invoicesPage;
    const { configuration } = appStore;
    const { customer } = authStore;
    const keepOnHold = configuration['Signup.KeepNewAccountsOnHold'];

    const hasPaymentSystem =
      customer?.EnableGoCardlessPayments ||
      (customer?.StripeDirectDebitPaymentsEnabled &&
        StripeDirectDebitEnabled) ||
      (configuration['StripeACH.Enabled'] && StripeACHEnabled) ||
      (configuration['Spreedly.Enabled'] == 'True' && SpreedlyEnabled) ||
      configuration['HostedPayments.Provider1.Enabled'] ||
      configuration['HostedPayments.Provider2.Enabled'] ||
      configuration['HostedPayments.Provider3.Enabled'];

    // Show payment details forms if the customer has invoices or
    // if they have selected a plan but they are not paid by another team
    // member
    const needsPaymentDetails =
      hasPaymentSystem &&
      customer &&
      ((!customer.IsPaidForCustomer &&
        customer.TariffIds &&
        !customer?.HasSetupRegularPayments) ||
        // User is either already active or KOH is off and has invoices
        ((!keepOnHold || customer.Active) && unpaidInvoices.length > 0));

    return (
      <SignupPageLayout componentName={this.props.componentName} step={4}>
        <div className="mw-820">
          <div className="">
            {isLoadingInvoicesPage && <LoadingSpinner />}
            {!isLoadingInvoicesPage && (
              <>
                {needsPaymentDetails && (
                  <>
                    {!customer.Active && (
                      <>
                        <p>
                          {keepOnHold
                            ? t(
                                "We need your payment details to complete your application. We won't use these details until your account is reviewed and approved."
                              )
                            : t(
                                'We need your payment details to complete your application.'
                              )}
                        </p>
                        <hr className="form--divider" />
                      </>
                    )}

                    <InvoicesTable
                      showPaidInvoices={false}
                      showPaymentHistory={unpaidInvoices.length > 0}
                    />
                  </>
                )}

                {!needsPaymentDetails && (
                  <>
                    <CheckoutComplete />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </SignupPageLayout>
    );
  }
}

export default SignupPaymentPage;
