import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';

@withTranslation()
@inject('authStore', 'appStore')
class AuthenticatedLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      originalChildren: props.children,
    };
  }

  loadMeAfterClick = () => {
    setTimeout(this.refreshToken, 3000);
  };

  refreshToken = () => {
    const { authStore } = this.props;
    return authStore.loadMe();
  };

  openLink = async (ev) => {
    ev.preventDefault();
    this.setState({ loading: true });

    const { href, appStore } = this.props;
    const { business } = appStore;
    const me = await this.refreshToken();
    const wrappedUrl = `${
      business.NativeHomeUrlWithLanguage
    }/user/login?server=true&t=${
      me.AccessToken
    }&redirectUrl=${encodeURIComponent(href)}`;

    this.loadMeAfterClick();

    if (this.props.target === '_blank') {
      window.open(wrappedUrl);
    } else {
      window.location = wrappedUrl;
    }

    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);
  };

  componentDidMount() {}

  render() {
    const { t, componentName, href } = this.props;

    return (
      <a
        className={this.props.className}
        href={href}
        onClick={this.openLink}
        data-component-name={componentName}
      >
        {this.state.loading ? t('Loading...') : this.state.originalChildren}
      </a>
    );
  }
}

export default AuthenticatedLink;
