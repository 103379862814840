import React, { Component } from 'react';
import config from 'env/config';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { toJS, when, reaction } from 'mobx';
import { isWeekend } from 'env/utils/dateUtil';
import { isServer } from 'env/ssr/ServerSideRenderManager';
import { withRouter } from 'next/router';
import { routes } from 'env/routes';
import withCustomComponent from 'ui/components/withCustomComponent';
import LoadingSpinner from 'ui/components/LoadingSpinner';

import InvisibleDeskNodes from 'ui/_pages/bookings/floorplan/InvisibleDeskNodes';
import { FloorPlanLegend } from 'ui/_pages/bookings/floorplan/FloorPlanLegend';
import FloorPlanSideBar from './FloorPlanSideBar';

let _seadragon;
const returnApiUrl = (id) =>
  `${config.storageUrl}/api/Sys/FloorPlans/${id}/tiles.dzi`;

@withRouter
@withCustomComponent('FloorplansMap')
@withTranslation()
@inject(
  'appStore',
  'authStore',
  'bookingsStore',
  'checkoutStore',
  'floorPlanStore'
)
@observer
class FloorPlanCanvas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initd: false,
      isLoadingFloorPlan: false,
    };
  }

  async componentDidMount() {
    const seadragon = await import(
      'ui/_pages/bookings/floorplan/seaDragonModule'
    );
    _seadragon = seadragon.default();

    const { floorPlanStore } = this.props;
    const { currentFloorPlan } = floorPlanStore;
    if (!this.state.initd && currentFloorPlan) {
      this.init();
    }

    this.currentFloorPlanReaction = reaction(
      () => this.props.floorPlanStore.currentFloorPlan,
      (currentFloorPlan) => {
        if (!this.state.initd) {
          this.init();
        } else {
          _seadragon.loadNewFloorPlan({
            tileSources: returnApiUrl(currentFloorPlan.Id),
            floorPlanId: currentFloorPlan.Id,
            getDeskRecordsCallback: () => {
              this.loadOverlays();
            },
          });
        }
      }
    );

    this.itemsReaction = reaction(
      () => this.props.floorPlanStore.filteredItems,
      (items) => {
        this.loadOverlays();
      }
    );
  }

  componentWillUnmount() {
    if (this.itemsReaction) this.itemsReaction();
    if (this.currentFloorPlanReaction) this.currentFloorPlanReaction();
    _seadragon?.destroy();
  }

  loadOverlays = () => {
    const { floorPlanStore, appStore, router } = this.props;
    const { groupedFilteredItems } = floorPlanStore;

    const groupedFilteredItemsWithRouter = groupedFilteredItems.filter(
      (item) => !router.query.item_id || item?.Id == router.query.item_id
    );

    _seadragon.clearOverlay();

    _seadragon.addDesks({
      points: groupedFilteredItemsWithRouter,
      scale: groupedFilteredItemsWithRouter[0]?.FloorPlanBackgroundScale || 20,
      shouldShowTooltip:
        appStore.configuration['Calendars.ShowMemberDetailsInFeed'],
      businessUrl: appStore.business.NativeHomeUrlWithLanguage,
      t: this.props.t,
    });
  };

  createNewBooking = async (data) => {
    const { bookingsStore, floorPlanStore } = this.props;
    bookingsStore.newBooking({
      resourceId: data.ResourceId,
      floorPlanDeskId: data.Id,
      deskName: data.Name,
      fromTime: floorPlanStore.fromTime,
      toTime: floorPlanStore.toTime,
      IncludeZoomInvite: false,
    });
    return Promise.resolve();
  };

  loadBookingPrice = () => {
    const { bookingsStore } = this.props;
    bookingsStore.loadBookingPrice();
  };

  resetBookingPrice = () => {
    const { bookingsStore } = this.props;
    bookingsStore.resetBookingPrice();
  };

  closeNewBooking = async (data) => {
    const { bookingsStore } = this.props;
    bookingsStore.closeNewBooking();
    return Promise.resolve();
  };

  getResource() {
    const { bookingsStore } = this.props;
    const { booking, resources } = bookingsStore;
    return (
      booking &&
      _(resources)
        .filter((r) => r.Id === booking.ResourceId)
        .first()
    );
  }

  addBookingToBasket = async (data, fromTime, toTime) => {
    const {
      t,
      appStore,
      authStore,
      bookingsStore,
      checkoutStore,
      isMobilePage,
    } = this.props;
    const { customer } = authStore;
    const { basket } = checkoutStore;

    var hasShifts = this.getResource()?.Shifts?.length > 0;
    if (hasShifts) {
      // Check we are not booking more than 30 bookings at a time
      var items = basket.length + moment(toTime).diff(moment(fromTime), 'days');
      if (items > 30) {
        appStore.setPopMessage(
          t('You can request a maximum of 30 bookings in a single transaction')
        );
        return Promise.reject();
      }

      for (
        let date = fromTime;
        moment(date).startOf('day') <= moment(toTime).startOf('day');
        date = moment(date).clone().add(1, 'day').toDate()
      ) {
        const thisFromTime = moment(date).clone().toDate();
        const thisToTime = moment(date).clone().toDate();

        thisFromTime.setHours(fromTime.getHours());
        thisFromTime.setMinutes(fromTime.getMinutes());
        thisToTime.setHours(toTime.getHours());
        thisToTime.setMinutes(toTime.getMinutes());

        const includeWeekends = isWeekend(fromTime) || isWeekend(toTime);

        if (!includeWeekends && isWeekend(date)) continue;

        const booking = {
          ...data,
          Id: null,
          FromTime: thisFromTime,
          ToTime: thisToTime,
          ResourceName: data.Name,
          ResourceId: data.ResourceId,
          FloorPlanDeskId: data.Id,
          Notes: bookingsStore.booking?.Notes,
          BookingVisitors: bookingsStore.booking?.BookingVisitors,
          SelectedShift: bookingsStore.booking?.SelectedShift,
          IncludeZoomInvite: bookingsStore.booking?.IncludeZoomInvite,
        };

        const price = await bookingsStore.loadBookingPriceDebounced(booking);

        checkoutStore.addToBasket({
          type: 'booking',
          data: booking,
          resourceProducts: bookingsStore.resourceProducts,
          bookingPrice: price,
          previewInvoice: false,
        });
        bookingsStore.closeNewBooking();
      }
    } else {
      const booking = {
        ...data,
        Id: null,
        FromTime: fromTime,
        ToTime: toTime,
        ResourceName: data.Name,
        ResourceId: data.ResourceId,
        FloorPlanDeskId: data.Id,
        Notes: bookingsStore.booking?.Notes,
        BookingVisitors: bookingsStore.booking?.BookingVisitors,
        SelectedShift: bookingsStore.booking?.SelectedShift,
        IncludeZoomInvite: bookingsStore.booking?.IncludeZoomInvite,
      };

      const price = await bookingsStore.loadBookingPriceDebounced(booking);

      checkoutStore.addToBasket({
        type: 'booking',
        data: booking,
        resourceProducts: bookingsStore.resourceProducts,
        bookingPrice: price,
        previewInvoice: false,
      });
      bookingsStore.closeNewBooking();
    }

    if (isMobilePage) _seadragon.enableMouseNav();

    if (customer != null) return checkoutStore.loadInvoicePreview();

    return Promise.resolve();
  };

  handlePopupClick = ({ type, data }) => {
    const { router, floorPlanStore, bookingsStore } = this.props;
    if (this.props.instantAddToBasket) {
      return this.addBookingToBasket(
        data,
        floorPlanStore.fromTime,
        floorPlanStore.toTime
      );
    }
    if (type === 'popup-open') {
      this.createNewBooking(data);
      this.resetBookingPrice();
      this.loadBookingPrice();
    }
    if (type === 'popup-close') {
      this.closeNewBooking(data);
    }
    if (type === 'checkout') {
      return this.addBookingToBasket(
        data,
        bookingsStore.booking.FromTime,
        bookingsStore.booking.ToTime
      ).then(() => router.push(routes.checkout));
    }
    if (type === 'basket') {
      this.addBookingToBasket(
        data,
        bookingsStore.booking.FromTime,
        bookingsStore.booking.ToTime
      );
    }
  };

  init() {
    if (!isServer()) {
      this.setState({ initd: true, isLoadingFloorPlan: false });
      const { appStore, floorPlanStore } = this.props;
      const { currentFloorPlan } = floorPlanStore;

      _seadragon.init({
        options: {
          id: 'openseadragon',
          tileSources: returnApiUrl(currentFloorPlan.Id),
          maxZoomPixelRatio: 5,
          defaultZoomLevel: 1,
          minZoomLevel: -0.5,
          animationTime: 1.2,
          showNavigationControl: false,
        },
        getDeskRecordsCallback: () => {
          this.loadOverlays();
        },
        popupClick: this.handlePopupClick,
        loadFailed: () => {
          appStore.setPopMessage('Could not load this page');
        },
      });
    }
  }

  highlightDesk(desk, zoomTo) {
    if (zoomTo) _seadragon.goToDesk(desk.Id.toString());
  }

  render() {
    const {
      router,
      selectFloorPlan,
      t,
      componentName,
      floorPlanStore,
      isMobilePage,
    } = this.props;
    const { floorPlans, showTypeFilters, currentFloorPlan, isLoadingItems } =
      floorPlanStore;

    if (
      !currentFloorPlan &&
      !this.state.isLoadingFloorPlan &&
      floorPlans.length === 1
    ) {
      selectFloorPlan(floorPlans[0].floorPlan);
      this.setState({ isLoadingFloorPlan: true });
    }

    return (
      <div data-component-name={componentName} className="floor-plan-canvas">
        <InvisibleDeskNodes isMobilePage={isMobilePage} />

        <div class="container">
          <div class="row">
            {!isMobilePage && (
              <FloorPlanSideBar highlightDesk={this.highlightDesk} />
            )}
            <div
              data-component-name={this.props.componentName}
              id="openseadragon"
              className={`box col floorplans__map js-floorplans-map mt-20`}
            >
              {isLoadingItems && !this.state.initd && <LoadingSpinner />}
            </div>
          </div>
        </div>

        {currentFloorPlan && !isMobilePage && <FloorPlanLegend />}

        {/* If you want to use centered modal uncoment BookingDetails component and set showSidePopup: false in seaDragonModule.js:142  */}
        {/* <BookingDetails /> */}
      </div>
    );
  }
}
export default FloorPlanCanvas;
