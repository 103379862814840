const StripeSvg = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 77 32"
    width="77"
    height="32"
  >
    <g>
      <path
        fill="#6772E5"
        d="M76.4,16.6c0-5.4-2.6-9.7-7.6-9.7c-5,0-8.1,4.3-8.1,9.7c0,6.4,3.6,9.6,8.8,9.6c2.5,0,4.4-0.6,5.9-1.4v-4.2c-1.4,0.7-3.1,1.2-5.2,1.2c-2.1,0-3.9-0.7-4.1-3.2h10.4C76.4,18.2,76.4,17.1,76.4,16.6z M66,14.6c0-2.4,1.5-3.4,2.8-3.4c1.3,0,2.7,1,2.7,3.4H66z"
      />
      <path
        fill="#6772E5"
        d="M52.5,6.9c-2.1,0-3.4,1-4.1,1.7l-0.3-1.3h-4.7v24.7l5.3-1.1l0-6c0.8,0.6,1.9,1.3,3.7,1.3c3.8,0,7.2-3,7.2-9.8C59.7,10.2,56.2,6.9,52.5,6.9z M51.2,21.5c-1.2,0-2-0.4-2.5-1l0-7.9c0.6-0.6,1.3-1,2.5-1c1.9,0,3.3,2.2,3.3,4.9C54.5,19.4,53.2,21.5,51.2,21.5z"
      />
      <polygon fill="#6772E5" points="36.2,5.7 41.5,4.5 41.5,0.2 36.2,1.3 	" />
      <rect x="36.2" y="7.3" fill="#6772E5" width="5.3" height="18.5" />
      <path
        fill="#6772E5"
        d="M30.5,8.8l-0.3-1.6h-4.6v18.5h5.3V13.2c1.2-1.6,3.4-1.3,4-1.1V7.3C34.2,7,31.7,6.5,30.5,8.8z"
      />
      <path
        fill="#6772E5"
        d="M19.9,2.7l-5.2,1.1l0,17c0,3.1,2.3,5.4,5.5,5.4c1.7,0,3-0.3,3.7-0.7v-4.3c-0.7,0.3-4,1.2-4-1.9v-7.5h4V7.3h-4L19.9,2.7z"
      />
      <path
        fill="#6772E5"
        d="M5.6,12.6c0-0.8,0.7-1.1,1.8-1.1c1.6,0,3.6,0.5,5.2,1.4v-5c-1.8-0.7-3.5-1-5.2-1c-4.3,0-7.2,2.2-7.2,6c0,5.8,8,4.9,8,7.4c0,1-0.8,1.3-2,1.3c-1.8,0-4-0.7-5.8-1.7v5c2,0.8,4,1.2,5.8,1.2c4.4,0,7.4-2.2,7.4-6C13.6,13.9,5.6,15,5.6,12.6z"
      />
    </g>
  </svg>
);

export default StripeSvg;
