import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { removeNewLines, replaceNewLines } from 'env/utils/strings';

import CardLocation from 'ui/components/cards/CardLocation';

export const HomeLocationsSection = withCustomComponent('HomeLocationsSection')(
  inject('appStore')(
    observer(({ appStore, componentName, onSelectBusiness, title }) => {
      const { t } = useTranslation();
      const { businesses } = appStore;
      return (
        <div
          data-component-name={componentName}
          className="home-locations-section"
        >
          <div className="container">
            {businesses.length > 2 && (
              <h3>{title ? title : t('Our Locations')}</h3>
            )}
            <ul className="list-grid list-grid--33 list-grid--md-100">
              {businesses.length <= 2 && (
                <li>
                  <h3>{title ? title : t('Our Locations')}</h3>
                </li>
              )}
              {businesses.map((business, i) => (
                <li key={i}>
                  <CardLocation business={business} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      );
    })
  )
);
