import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import {withRouter} from 'next/router';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import {routes} from 'env/routes';
import ReactPaginate from 'react-paginate';
import PlanDetails from 'ui/_pages/plan/PlanDetails';
import {toJS} from 'mobx';

const PlansTab = props => {
  const {
    plans,
    itemsPerPage = 5,
  } = props;
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(null);
  const [itemOffset, setItemOffset] = useState(null);

  useEffect(() => {
    setItemOffset(0);
  }, []);

  // Pagination handlers
  useEffect(() => {
    setCurrentItems(plans.slice(itemOffset, itemOffset + itemsPerPage));
    setPageCount(Math.ceil(plans.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  const handlePageClick = e => {
    setItemOffset(e.selected * itemsPerPage);
  };

  return <>
    {currentItems.map((pricePlan, i) => (
      <PlanDetails
        key={i}
        service={pricePlan}
        isLoading={false}
        showStartDate={false}
        design="modern"
        buttonLabel={t('Book it')}
        buttonAsLink
        buttonLink={routes.plans_plan(pricePlan.UniqueId)}
        displayAgreements
      />
    ))}

    <nav>
      <ReactPaginate
        breakLabel="..."
        nextLabel={(
          <span aria-hidden="true">
                <i className="icon-chevron-right"></i>
              </span>
        )}
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel={(
          <span aria-hidden="true">
                <i className="icon-chevron-right"></i>
              </span>
        )}
        renderOnZeroPageCount={null}
        className="pagination justify-content-center"
        previousLinkClassName="btn prev"
        nextLinkClassName="btn next"
        pageLinkClassName="btn"
        activeClassName="active"
        breakLinkClassName="dots"
      />
    </nav>
  </>;
};

let PlanSearch = props => {
  const {
    t,
    appStore,
    authStore,
    invoicingStore,
  } = props;

  useEffect(() => {
    let cancel = false;

    // Load Price plan
    if (!invoicingStore.hasLoadedPricePlans) {
      invoicingStore.loadPricePlans({
        customer: authStore.customer,
        tariffGuid: null,
      });
    }

    return () => {
      cancel = true;
    };
  }, []);

  if (!(invoicingStore.hasLoadedPricePlans)) {
    return <LoadingLayout/>;
  }

  console.log(toJS(invoicingStore.pricePlans.PricePlanGroups));
  console.log(toJS(invoicingStore.pricePlans.PricePlans));
  const groups = invoicingStore.pricePlans.PricePlanGroups.map(group => {
    return {
      name: group ? group : t('Others'),
      slug: group ? group.trim().toLowerCase().replaceAll(' ', '-') : 'others',
    };
  });

  return (
    <DefaultLayout>
      <main className="content content--custom pt-0" role="main">
        <div className="portal-title-wrapper">
          <div className="container portal-title-container">
            <h1 className="portal-title">
              {t('Available plans')}
            </h1>
          </div>
        </div>
        <div className="container">
          <ul className="nav nav-tabs d-none d-md-flex" id="plan-tabs" role="tablist">
            {groups.map((group, i) => <li
            key={i}
              className="nav-item"
            >
              <a className={`nav-link ${i === 0 ? 'active' : ''}`} id={`${group.slug}-tab`} data-toggle="tab" href={`#${group.slug}`}
                 role="tab"
                 aria-controls={group.slug}
                 aria-selected="true">{group.name}</a>
            </li>)}
          </ul>

          <select className="form-control nav-selector d-block d-md-none" data-content="#tab-content-memberships">
            {groups.map((group, i) => <option
              key={i}
              value={`#${group.slug}`}
            >{group.name}</option>)}
          </select>

          <div id="tab-content-memberships" className="tab-content">
            {groups.map((group, i) => <div
              key={i}
              id={group.slug}
              className={`tab-pane ${i === 0 ? 'active' : ''}`}
              role="tabpanel"
              aria-labelledby={`${group.slug}-tab`}
            >
              <PlansTab
                plans={invoicingStore.pricePlans.PricePlans.filter(pricePlan => {
                  if (pricePlan.GroupName) {
                    return pricePlan.GroupName === group.name;
                  }

                  return group.slug === 'others';
                })}
              />
            </div>)}
          </div>
        </div>
      </main>
    </DefaultLayout>
  );
};

export default PlanSearch = withCustomComponent('PlanSearch')(
  withRouter(
    withTranslation()(
      inject('appStore', 'authStore', 'invoicingStore', 'checkoutStore')(
        observer(PlanSearch),
      ),
    ),
  ),
);
