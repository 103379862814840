import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import { CoursesPageHeader } from 'ui/_pages/courses/CoursesPageHeader';
import { withRouter } from 'next/router';
import { CoursesPageFilters } from 'ui/_pages/courses/CoursesPageFilters';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { CoursesPageList } from 'ui/_pages/courses/CoursesPageList';
import { CoursesPageZeroCase } from 'ui/_pages/courses/CoursesPageZeroCase';
import { Pagination } from 'ui/components/listings/Pagination';
import { NoAccessNotice } from 'ui/components/NoAccessNotice';

@withCustomComponent('CoursesPage')
@withTranslation()
@withRouter
@inject('appStore', 'authStore', 'coursesStore')
@observer
class CoursesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loaded: false,
    };
  }

  async componentDidMount() {
    const { t, router, coursesStore } = this.props;
    await coursesStore.loadCoursesPage({ ...router.query }).catch((err) => {
      this.setState({
        error: t("You currently don't have access to the courses section."),
      });
    });

    this.setState({ loaded: true });
  }

  componentDidUpdate(prevProps, prevState) {
    const { t, router, coursesStore } = this.props;
    const prevQuery = prevProps.router.query;
    const currentQuery = router.query;
    if (JSON.stringify(currentQuery) != JSON.stringify(prevQuery)) {
      coursesStore.loadCoursesPage({ ...router.query }).catch((err) => {
        this.setState({
          error: t("You currently don't have access to the courses section."),
        });
      });
    }
  }

  render() {
    const { t, appStore, coursesStore } = this.props;
    const { business } = appStore;
    const { coursesPage, hasLoadedCoursesPage } = coursesStore;
    const hasError = this.state.error != null;

    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={t('Courses - {{Name}}', { Name: t(business.Name) })}
      >
        <main className="content content--custom" role="main">
          <div className="container">
            <CoursesPageHeader />
            <CoursesPageFilters />
            {!hasLoadedCoursesPage && !this.state.loaded && <LoadingSpinner />}
            {hasLoadedCoursesPage && this.state.loaded && (
              <>
                <NoAccessNotice error={this.state.error} />
                <CoursesPageList />
                {!hasError && (
                  <CoursesPageZeroCase
                    courses={coursesPage.Courses}
                    message={t('There are no courses to display')}
                  />
                )}
                <Pagination
                  page={coursesPage.Page}
                  records={coursesPage.Courses}
                  top={coursesPage.Top}
                />
              </>
            )}
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default CoursesPage;
