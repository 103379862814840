import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';

export const CoursePageAttendees = withCustomComponent('CoursePageAttendees')(
  inject(
    'appStore',
    'coursesStore',
    'authStore'
  )(
    withRouter(
      observer(({ router, appStore, coursesStore, authStore }) => {
        const { business } = appStore;
        const { t } = useTranslation();
        const { Attendees } = coursesStore.coursePage;

        if(!Attendees) {
          return null;
        }

        return (
          <div className="card course-page-attendees p-16 p-md-24 h-auto mb-24">
            <div className="d-block d-flex mb-24 justify-content-between align-items-center">
              <div>
                <span className="attendees-number mr-12">{Attendees.length}</span>
                <span className="students">{t('Student(s)')}</span>
              </div>

              <a href="#" className="view-all text-gray-700 d-none">View all</a>
            </div>

            <div>
              {Attendees.map(attendee => {
                return <div className="d-block d-flex align-items-center mb-16">
                  <Link href={routes.directory_member_by_id(attendee)}>
                    <a className="user">
                      <img
                        className="avatar mr-8"
                        src={`${appStore.business.NativeHomeUrlWithLanguage}/coworker/getavatar/${attendee.IdString}?h=40&w=40&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}
                        alt={attendee.FullName}
                      />
                    </a>
                  </Link>

                  <div>
                    <Link href={routes.directory_member_by_id(attendee)}>
                      <a className="user text-gray-900">
                        <span className="attendee-name text-gray-900">{attendee.FullName}</span>
                      </a>
                    </Link>

                    <div className="attendee-company text-gray-600">{attendee.CompanyName || ' '}</div>
                  </div>
                </div>;
              })}
            </div>
          </div>
        );
      })
    )
  )
);