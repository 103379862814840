import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { removeHtml } from 'env/utils/strings';
@observer
class InputField extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = { touched: false };
  }

  componentDidMount() {
    if (!window.uploadStaging) window.uploadStaging = {};
    window.uploadStaging[this.props.name] = {};
  }

  onChange(event) {
    this.setState({ touched: true });

    //If the input is a file input, add FormData to the state
    var files = this.inputElement.files;
    if (files) {
      var formData = new FormData();

      for (var key in files) {
        // is the item a File?
        if (files.hasOwnProperty(key) && files[key] instanceof File) {
          formData.append(key, files[key]);
        }
      }
      if (!window.uploadStaging) window.uploadStaging = {};
      window.uploadStaging[event.target.name] = formData;
    }

    this.props.onChange(
      event.target.name,
      event.target.value.length > 0 ? removeHtml(event.target.value) : null
    );
  }

  onFocus = () => {
    this.setState({ touched: true });
  };

  render() {
    const input = this.props;
    const hasErrors = input.errors && input.errors.length > 0;
    const { touched } = this.state;

    const inputField = <input
      ref={(el) => (this.inputElement = el)}
      className={`form-control ${hasErrors ? 'validationElement' : ''}`}
      id={input.id}
      multiple={this.props.multiple}
      onBlur={this.onFocus}
      name={input.name}
      onChange={this.onChange}
      type={input.type}
      value={input.value || ''}
      placeholder={input.placeholder}
      autoComplete={input.autocomplete || ''}
      {...this.props.inputProps}
    />

    return (
      <>
        <label className="control-label" htmlFor={input.id}>
          {input.label || input.name}
        </label>

        {input.icon ?
          <div className="input-group">
            {inputField}
            <div className="input-group-append">
              <i className={input.icon}></i>
            </div>
          </div>
          :
          <>
            {inputField}
          </>
        }

        {touched && hasErrors && (
          <small className="help-block validation-error">
            {input.errors.join(', ')}
          </small>
        )}
      </>
    );
  }
}

InputField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

InputField.defaultProps = {
  type: 'text',
};

export default InputField;
