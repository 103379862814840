/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import withCustomComponent from 'ui/components/withCustomComponent';
export const HelpDeskMessageDetails = withCustomComponent(
  'HelpDeskMessageDetails'
)(
  inject(
    'supportStore',
    'appStore',
    'authStore'
  )(
    observer(
      ({
        componentName,
        messageContainer,
        supportStore,
        appStore,
        authStore,
      }) => {
        const { t } = useTranslation();

        const { business } = appStore;
        const { isClosingRequest } = supportStore;
        const { Message, ImageFileName } = messageContainer;

        const closeRequest = () => {
          var question = t(
            'Do you want to close and marked as resolved this support request?'
          );

          appStore.setYesNotQuestion(question).then((response) => {
            if (response) {
              supportStore
                .closeRequest(Message.Id)
                .then(() => supportStore.loadMessage(Message.Id))
                .catch((err) => {
                  appStore.setPopMessage(
                    err.data ? err.data.ErrorMessage : err
                  );
                });
            }
          });
        };

        return (
          <div
            data-component-name={componentName}
            className="p-16 p-md-24 p-lg-32 mb-8"
          >
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                {Message.Department && (
                  <>
                    <span className="d-inline-flex tag m-0 mr-16">
                      {Message.Department.Name}
                    </span>
                  </>
                )}
                <span className="mr-12">
                  <time>
                    {moment(Message.CreatedOnUtc + 'Z').format('LLL')} by
                  </time>
                </span>
                <div className="d-flex align-items-center">
                  <img
                    className="avatar mr-8"
                    src={`${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${Message.Coworker.Id}?h=128&w=128&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}
                    alt={Message.Coworker.FullName}
                  />
                  <span className="">{Message.Coworker.FullName}</span>
                </div>
              </div>
              <div>
                {Message.Closed ? (
                  <span className="d-inline-flex tag m-0 with-icon-left bg-red-02">
                    <i className="icon-remove"></i>
                    {t('Closed')}
                  </span>
                ) : (
                  <>
                    {Message.Comments.filter(
                      (c) => c.CoworkerId != authStore.customer.Id
                    ).length > 0 ? (
                      <span className="d-inline-flex tag m-0 with-icon-left bg-green-02">
                        <i className="icon-reply-received"></i>
                        {t('Replied')}
                      </span>
                    ) : (
                      <span className="d-inline-flex tag m-0 with-icon-left bg-orange-02">
                        <i className="icon-alert"></i>
                        {t('Open')}
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="mt-24">
              <h2 className="mb-0">{Message.Subject}</h2>
            </div>

            <div className="my-40">{Message.MessageText}</div>

            <div>
              {ImageFileName && (
                <img
                  src={`${business.NativeHomeUrlWithLanguage}en/content/helpdeskfile/?id=${Message.Id}&w=256`}
                  alt={t('Attachment')}
                  className="img-fluid mb-40"
                />
              )}
            </div>

            {!Message.Closed && (
              <div className="d-flex align-items-center justify-content-end">
                <a
                  href="#"
                  onClick={(ev) => {
                    ev.preventDefault();
                    closeRequest();
                  }}
                  className="btn"
                >
                  {isClosingRequest ? (
                    <LoadingSpinner />
                  ) : (
                    t('Close help request')
                  )}
                </a>
              </div>
            )}
          </div>
        );
      }
    )
  )
);
