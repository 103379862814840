import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import { CommunityBoardItem } from 'ui/_pages/community/list/CommunityBoardItem';
import TurndownService from 'turndown';
import withCustomComponent from 'ui/components/withCustomComponent';
import { CommunityBoardReply } from '../thread/CommunityBoardReply';
import { scrollToAnchorElement } from 'env/utils/scrolling';

export const CommunityBoardList = withCustomComponent('CommunityBoardList')(
  inject('appStore')(
    withRouter(
      observer(({ componentName, threads = [] }) => {
        const { t } = useTranslation();
        const turndownService = new TurndownService();

        useEffect(() => {
          if (window.location.hash) {
            setTimeout(
              () =>
                scrollToAnchorElement(
                  window.location.hash.replace('#', ''),
                  true
                ),
              100
            );
          }
        }, [threads]);

        return (
          <div
            data-component-name={componentName}
            className="community-board-list"
          >
            {threads.map((thread, i) => (
              <React.Fragment key={i}>
                {!thread.Messages && (
                  <CommunityBoardItem
                    key={i}
                    turndownService={turndownService}
                    thread={thread}
                    threadDetails={false}
                  />
                )}
                {thread.Messages && (
                  <CommunityBoardItem
                    key={i}
                    turndownService={turndownService}
                    thread={thread}
                    threadDetails={false}
                  >
                    <ul className="list-unstyled mb-0 mt-12">
                      {thread.Messages.map((message, i) => (
                        <div className="mb-6">
                          <CommunityBoardReply
                            turndownService={turndownService}
                            message={message}
                          />
                        </div>
                      ))}
                    </ul>
                  </CommunityBoardItem>
                )}
              </React.Fragment>
            ))}
          </div>
        );
      })
    )
  )
);
