import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Trans, withTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import Link from 'next/link';
import { routes } from 'env/routes';
import withCustomComponent from 'ui/components/withCustomComponent';
import { CommunityBoardItemReputation } from 'ui/_pages/community/list/item/CommunityBoardItemReputation';
import { shorten } from 'env/utils/strings';

@withCustomComponent('VirtualMessageCard')
@withTranslation()
@inject('appStore')
@observer
class ThreadFeedCard extends Component {
  render() {
    const { t, appStore, thread } = this.props;
    const { business } = appStore;

    return (
      <div
        data-component-name={this.props.componentName}
        className="virtual-message-card"
      >
        <div className="card h-auto p-16 p-md-24 mb-24">
          <header className="media">
            <Link
              href={{ pathname: routes.directory_member_by_id('[member_id]') }}
              as={{
                pathname: routes.directory_member_by_id({
                  Id: thread.CoworkerId,
                  FullName: thread.FullName,
                }),
              }}
            >
              <a
                className="avatar avatar--48"
                style={{
                  backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${thread.CoworkerId}?h=96&w=96&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png)`,
                }}
              ></a>
            </Link>
            <div className="media-body d-flex justify-content-between pl-16">
              <div className="text-gray-600">
                <div className="fw-500">
                  <Trans>
                    <a
                      href={routes.directory_member_by_id({
                        Id: thread.CoworkerId,
                        FullName: thread.FullName,
                      })}
                    >
                      {thread.FullName}
                    </a>

                    <span> started a discussion </span>
                    <a href={routes.thread_by_id(thread)}>{thread.Subject}</a>
                  </Trans>
                </div>
                <div className="fs-14 lh-14">
                  {moment(thread.CreatedOnUtc).fromNow()}
                </div>
              </div>
              {/* <div className="dropdown">
                <button
                  className="btn btn-single-icon btn-white dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <i className="icon-more"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button className="dropdown-item">Mute message</button>
                  <button className="dropdown-item">Delete message</button>
                </div>
              </div> */}
            </div>
          </header>
          <div className="d-block pt-24 pb-20">
            <h4 className="mb-20">"{shorten(thread.Message, 50)}"</h4>
            <div className="pb-8 border-bottom">
              <CommunityBoardItemReputation thread={thread} />
            </div>
          </div>
          <footer>
            <Link href={routes.thread_by_id(thread)}>
              <a className="btn  -xs">
                {t('Join the conversation')}
              </a>
            </Link>
          </footer>
        </div>
      </div>
    );
  }
}

export default ThreadFeedCard;
