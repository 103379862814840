import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
export const CommunityBoardZeroCase = withCustomComponent(
  'CommunityBoardZeroCase'
)(
  inject('appStore')(
    withRouter(
      observer(
        ({
          componentName,
          group,
          threads = [],
          privateThreads,
          canStartConversation,
          router,
        }) => {
          const { t } = useTranslation();

          return (
            <>
              {!(threads?.length > 0) && (
                <div
                  data-component-name={componentName}
                  className="community-board-zero-case mt-32"
                >
                  <div className="border-dashed rounded p-24 p-lg-48 pb-32 pb-lg-64 text-center">
                    {router.query.query && (
                      <>
                        <h3 className="my-32">
                          {t('We found no conversations')}
                        </h3>
                      </>
                    )}
                    {!router.query.query && (
                      <>
                        {/*
                        {router.pathname === routes.folowing && (
                          <>
                            <svg width="64px" height="64px" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Discussion-Boards" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                              <g id="Discussion-Boards---Following---Empty1" transform="translate(-725.000000, -312.000000)" stroke="#999999" strokeWidth="3">
                                <g id="Box" transform="translate(132.000000, 256.000000)">
                                  <g id="Group" transform="translate(585.000000, 48.000000)">
                                    <g id="Path" transform="translate(10.000000, 10.000000)">
                                      <path d="M11.7200001,10 L11.7200001,6.66666674 C11.7200001,2.98333339 14.7033334,4.16666666e-08 18.3866668,4.16666666e-08 L41.6166669,4.16666666e-08 C45.3000002,4.16666666e-08 48.2833336,2.98333339 48.2833336,6.66666674 L48.2833336,10" fill="#CCCCCC"></path>
                                      <path d="M5.94000007,20.0000001 L5.94000007,16.6666668 C5.94000007,12.9833334 8.92333341,10.0000001 12.6066668,10.0000001 L47.3900002,10.0000001 C51.0733336,10.0000001 54.0566669,12.9833334 54.0566669,16.6666668 L54.0566669,20.0000001" fill="#CCCCCC"></path>
                                      <path d="M53.1800003,60.0000001 L6.82000007,60.0000001 C3.13666672,60.0000001 0.153333376,57.0166669 0.153333376,53.3333336 L0.153333376,26.6666668 C0.153333376,22.9833335 3.13666672,20.0000001 6.82000007,20.0000001 L53.1800003,20.0000001 C56.8633336,20.0000001 59.846667,22.9833335 59.846667,26.6666668 L59.846667,53.3333336 C59.846667,57.0166669 56.8600003,60.0000001 53.1800003,60.0000001 Z" fill="#FFFFFF"></path>
                                      <line x1="29.9822224" y1="31.057778" x2="29.9822224" y2="48.8355558" transform="translate(29.982222, 39.946667) rotate(-90.000000) translate(-29.982222, -39.946667) "></line>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                            <h5 className="mt-16 mb-8">{t("You haven't followed any conversations.")}</h5>
                            <p className="fs-14 text-gray-700 mxw-400px mx-auto mb-24">
                              {t("Follow conversations you would like to keep a close eye on, even if you aren't participating.")}
                            </p>
                          </>
                        )}
                        */}
                        {router.pathname === routes.my_threads && (
                          <>
                            <svg
                              width="64px"
                              height="64px"
                              viewBox="0 0 64 64"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g
                                id="Discussion-Boards"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <g
                                  id="Discussion-Boards---My-Conversations---Empty2"
                                  transform="translate(-725.000000, -312.000000)"
                                  stroke="#999999"
                                  strokeWidth="3"
                                >
                                  <g
                                    id="Box"
                                    transform="translate(132.000000, 256.000000)"
                                  >
                                    <g
                                      id="Group"
                                      transform="translate(585.000000, 48.000000)"
                                    >
                                      <g
                                        id="Path"
                                        transform="translate(10.000000, 10.000000)"
                                      >
                                        <line
                                          x1="29.9822224"
                                          y1="16.0577781"
                                          x2="29.9822224"
                                          y2="33.8355559"
                                          transform="translate(29.982222, 24.946667) rotate(-90.000000) translate(-29.982222, -24.946667) "
                                        ></line>
                                        <path d="M30,60 L16.6666667,46.67 L16.6666667,46.6666667 L6.66666667,46.6666667 C2.98333333,46.6666667 -2.27373675e-13,43.6833333 -2.27373675e-13,40 L-2.27373675e-13,6.66666667 C-2.27373675e-13,2.98333333 2.98333333,0 6.66666667,0 L53.3333333,0 C57.0166667,0 60,2.98333333 60,6.66666667 L60,40 C60,43.6833333 57.0166667,46.6666667 53.3333333,46.6666667 L43.3333333,46.6666667 L30,59.9966667"></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                            <h5 className="mt-16 mb-8">
                              {t("You haven't started any conversations.")}
                            </h5>
                            <p className="fs-14 text-gray-700 mxw-400px mx-auto mb-24">
                              {t(
                                'Start a conversation with anyone on the network, invite multiple people to join in.'
                              )}
                            </p>
                          </>
                        )}
                        {router.pathname === routes.private_threads && (
                          <>
                            <svg
                              width="64px"
                              height="61px"
                              viewBox="0 0 64 61"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g
                                id="Discussion-Boards"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <g
                                  id="Discussion-Boards---My-Inbox---Empty3"
                                  transform="translate(-725.000000, -315.000000)"
                                  stroke="#999999"
                                  strokeWidth="3"
                                >
                                  <g
                                    id="Box"
                                    transform="translate(132.000000, 256.000000)"
                                  >
                                    <g
                                      id="Group"
                                      transform="translate(585.000000, 48.000000)"
                                    >
                                      <g
                                        id="Path"
                                        transform="translate(7.000000, 11.000000)"
                                      >
                                        <g transform="translate(0.666667, 0.000000)">
                                          <path
                                            d="M41,11.3333333 L41,11.3333333 C42.3333333,12.6666667 42.6666667,14.3333333 42.3333333,15.6666667 L38.6666667,35 C37.6666667,39.6666667 34,43.3333333 29.3333333,44.3333333 L10,48 C8.33333333,48.3333333 6.66666667,47.6666667 5.66666667,46.6666667 L5.66666667,46.6666667 C3.66666667,44.6666667 3.66666667,41.6666667 5.66666667,39.6666667 L34,11.3333333 C35.6666667,9.33333333 39,9.33333333 41,11.3333333 L41,11.3333333 Z"
                                            fill="#CCCCCC"
                                          ></path>
                                          <path d="M22.3333333,2.66666667 C22.24,2.66666667 22.1666667,2.59333333 22.1666667,2.5 C22.1666667,2.40666667 22.24,2.33333333 22.3333333,2.33333333 C22.4266667,2.33333333 22.5,2.40666667 22.5,2.5 C22.5,2.59333333 22.4266667,2.66666667 22.3333333,2.66666667"></path>
                                          <line
                                            x1="2.33333333"
                                            y1="59"
                                            x2="62.3333333"
                                            y2="59"
                                          ></line>
                                          <line
                                            x1="2.33333333"
                                            y1="12.3333333"
                                            x2="2.33333333"
                                            y2="2.33333333"
                                          ></line>
                                          <line
                                            x1="12.3333333"
                                            y1="5.66666667"
                                            x2="12.3333333"
                                            y2="2.33333333"
                                          ></line>
                                          <path d="M41,11.3333333 L58.3333333,28.6666667 C61,31.3333333 61,35.3333333 58.3333333,38 L37.3333333,59"></path>
                                          <line
                                            x1="5.33333333"
                                            y1="47"
                                            x2="17.6666667"
                                            y2="59"
                                          ></line>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                            <h5 className="mt-16 mb-8">
                              {t(
                                'You have no private conversations in your Inbox.'
                              )}
                            </h5>
                            <p className="fs-14 text-gray-700 mxw-400px mx-auto mb-24">
                              {t(
                                'Start a private conversation with anyone on the network, and it will be stored here in your Inbox.'
                              )}
                            </p>
                          </>
                        )}
                        {router.pathname === routes.forum && (
                          <>
                            <svg
                              width="64px"
                              height="64px"
                              viewBox="0 0 64 64"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g
                                id="Discussion-Boards"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <g
                                  id="Discussion-Boards---My-Conversations---Empty2"
                                  transform="translate(-725.000000, -312.000000)"
                                  stroke="#999999"
                                  strokeWidth="3"
                                >
                                  <g
                                    id="Box"
                                    transform="translate(132.000000, 256.000000)"
                                  >
                                    <g
                                      id="Group"
                                      transform="translate(585.000000, 48.000000)"
                                    >
                                      <g
                                        id="Path"
                                        transform="translate(10.000000, 10.000000)"
                                      >
                                        <line
                                          x1="29.9822224"
                                          y1="16.0577781"
                                          x2="29.9822224"
                                          y2="33.8355559"
                                          transform="translate(29.982222, 24.946667) rotate(-90.000000) translate(-29.982222, -24.946667) "
                                        ></line>
                                        <path d="M30,60 L16.6666667,46.67 L16.6666667,46.6666667 L6.66666667,46.6666667 C2.98333333,46.6666667 -2.27373675e-13,43.6833333 -2.27373675e-13,40 L-2.27373675e-13,6.66666667 C-2.27373675e-13,2.98333333 2.98333333,0 6.66666667,0 L53.3333333,0 C57.0166667,0 60,2.98333333 60,6.66666667 L60,40 C60,43.6833333 57.0166667,46.6666667 53.3333333,46.6666667 L43.3333333,46.6666667 L30,59.9966667"></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                            <h5 className="mt-16 mb-8">
                              {t("You haven't started any conversations.")}
                            </h5>
                            <p className="fs-14 text-gray-700 mxw-400px mx-auto mb-24">
                              {t(
                                'Start a conversation with anyone on the network, invite multiple people to join in.'
                              )}
                            </p>
                          </>
                        )}
                        {router.pathname ===
                          routes.forum_by_group('[group_id]') && (
                          <>
                            <svg
                              width="64px"
                              height="64px"
                              viewBox="0 0 64 64"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g
                                id="Discussion-Boards"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <g
                                  id="Discussion-Boards---My-Conversations---Empty2"
                                  transform="translate(-725.000000, -312.000000)"
                                  stroke="#999999"
                                  strokeWidth="3"
                                >
                                  <g
                                    id="Box"
                                    transform="translate(132.000000, 256.000000)"
                                  >
                                    <g
                                      id="Group"
                                      transform="translate(585.000000, 48.000000)"
                                    >
                                      <g
                                        id="Path"
                                        transform="translate(10.000000, 10.000000)"
                                      >
                                        <line
                                          x1="29.9822224"
                                          y1="16.0577781"
                                          x2="29.9822224"
                                          y2="33.8355559"
                                          transform="translate(29.982222, 24.946667) rotate(-90.000000) translate(-29.982222, -24.946667) "
                                        ></line>
                                        <path d="M30,60 L16.6666667,46.67 L16.6666667,46.6666667 L6.66666667,46.6666667 C2.98333333,46.6666667 -2.27373675e-13,43.6833333 -2.27373675e-13,40 L-2.27373675e-13,6.66666667 C-2.27373675e-13,2.98333333 2.98333333,0 6.66666667,0 L53.3333333,0 C57.0166667,0 60,2.98333333 60,6.66666667 L60,40 C60,43.6833333 57.0166667,46.6666667 53.3333333,46.6666667 L43.3333333,46.6666667 L30,59.9966667"></path>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                            <h5 className="mt-16 mb-8">
                              {t(
                                "You haven't started any conversation in this group."
                              )}
                            </h5>
                            <p className="fs-14 text-gray-700 mxw-400px mx-auto mb-24">
                              {t(
                                'Start a conversation with anyone in this group, invite people to join in and participate in group conversations.'
                              )}
                            </p>
                          </>
                        )}
                      </>
                    )}

                    {canStartConversation && (
                      <div className="mt-24">
                        <Link
                          href={{
                            pathname: routes.forum_start,
                            query: {
                              privateThread: privateThreads,
                              group_id: group && group.Id,
                            },
                          }}
                        >
                          <a className="btn btn-icon">
                            <i className="icon-add"></i>
                            {t('Start Conversation')}
                          </a>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          );
        }
      )
    )
  )
);
