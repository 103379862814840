import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation, Trans} from 'react-i18next';
import Link from 'next/link';
import moment from 'moment';
import {withRouter} from 'next/router';
import FullPageLayout from 'ui/layouts/FullPageLayout';
import {routes} from 'env/routes';
import {ChangeContractHeader} from 'ui/_pages/plan/change/ChangeContractHeader';
import {ChangeContractSteps} from 'ui/_pages/plan/change/ChangeContractSteps';
import PlansTable from 'ui/_pages/plan/PlansTable';
import {privateRoute} from 'env/privateRoute';
import {withCustomComponent} from 'ui/components/withCustomComponent';

@withCustomComponent('PlanChangePage')
@privateRoute
@withTranslation()
@inject('appStore', 'authStore', 'invoicingStore')
@withRouter
@observer
class PlanChangePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const {router, invoicingStore} = this.props;
    invoicingStore.loadContract(router.query.contract_id).catch(() => router.push(routes.plan));
  }

  onPlanSelected = ({tariff}) => {
    const {router, t, appStore, invoicingStore} = this.props;

    invoicingStore.saveContract({
      contractId: router.query.contract_id,
      tariffId: tariff.Id,
    }).then((contract) => {
      appStore.setPopMessage(
        t(
          'Your plan {{Name}} will change to {{NextName}} on {{RenewalDate}}',
          {
            NextName: contract.NextTariff.Name,
            Name: contract.Tariff.Name,
            RenewalDate: moment(contract.RenewalDate).format('L'),
          },
        ),
      ).then(() => {
        router.push(routes.plan);
      });
    }).catch(() => {
      appStore.setPopMessage(
        t('Your plan could not be changed. Please contact us.'),
      );
    });
  };

  render() {
    const {t, invoicingStore} = this.props;
    const {contract} = invoicingStore;

    return (
      <FullPageLayout title={t('Change contract')}>
        {contract && (
          <>
            <ChangeContractHeader contract={contract}/>
            <PlansTable
              buttonLabel={t('Change to this plan')}
              isLoading={this.props.invoicingStore.isSavingContract}
              onPlanSelected={this.onPlanSelected}
              showStartDate={false}
            />
            <div className="mt-24 btn-group justify-content-end">
              <Link href={routes.plan}>
                <a className="btn btn-outline">{t('Cancel')}</a>
              </Link>
            </div>
          </>
        )}
      </FullPageLayout>
    );
  }
}

export default PlanChangePage;
