import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { routes } from 'env/routes';
import Link from 'next/link';
import { removeHtml, shorten } from 'env/utils/strings';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';
import withCustomComponent from 'ui/components/withCustomComponent';
import AddToCalendar from '@culturehq/add-to-calendar';
import ReactMarkdown from 'react-markdown';

@withCustomComponent('CardEvent')
@withTranslation()
@inject('appStore', 'eventsStore')
@observer
class CardEvent extends Component {
  static propTypes = {
    event: PropTypes.object.isRequired,
    myEvents: PropTypes.object,
    sizeExtraSmall: PropTypes.bool,
    sizeSmall: PropTypes.bool,
    sizeMedium: PropTypes.bool,
    sizeLarge: PropTypes.bool,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const {
      t,
      componentName,
      appStore,
      eventsStore,
      event,
      sizeExtraSmall,
      sizeSmall,
      sizeMedium,
      sizeLarge,
      myEvents,
    } = this.props;

    const ticketBought =
      myEvents != undefined
        ? myEvents.UpcomingEvents.find((ev) => ev.Id == event.Id) != null
        : false;

    const addToCalendarData = {
      name: event.Name,
      details: event.ShortDescription,
      location: event.Location || (event.Business && t(event.Business.Name)),
      startsAt: moment(event.StartDate).locale('en').format(),
      endsAt: moment(event.EndDate).locale('en').format(),
    };

    const isCorporateDashboard = appStore.corporateDashboard;

    return (
      <div
        className={`card card-event ${sizeExtraSmall ? 'card-event--xs' : ''} ${
          sizeSmall ? 'card-event--sm' : ''
        } ${sizeMedium ? 'card-event--md' : ''} ${
          sizeLarge ? 'card-event--lg' : ''
        }`}
        data-component-name={componentName}
      >
        {!sizeLarge ? (
          <Link href={routes.event_by_id(event)}>
            <a
              className="card-event__image"
              style={{
                backgroundImage: `url(${appStore.business.NativeHomeUrlWithLanguage}/events/getlargeimage?id=${event.Id}&w=320&h=380&cache=${event.UpdatedOn})`,
              }}
              alt={event.Title}
            ></a>
          </Link>
        ) : (
          <div
            className="card-event__image"
            style={{
              backgroundImage: `url(${appStore.business.NativeHomeUrlWithLanguage}/events/getlargeimage?id=${event.Id}&w=320&h=380&cache=${event.UpdatedOn})`,
            }}
          ></div>
        )}
        <div className="card-event__main">
          <div className="card-event__content">
            {!sizeLarge ? (
              <Link href={routes.event_by_id(event)}>
                <a className="card-event__title">{event.Name}</a>
              </Link>
            ) : (
              <div className="card-event__title">{event.Name}</div>
            )}
            <ul className="list-tags card-event__tags">
              {!sizeLarge && (
                <li className="with-icon-left">
                  <i className="icon-marker-pin"></i>
                  {event.Location && removeHtml(event.Location)}
                  {!event.Location && t(event.Business.Name)}
                </li>
              )}
              {event.EventCategories?.map((category, i) => (
                <li key={i}>
                  <Link href={routes.event_by_category(category.Id)}>
                    <a href="#">{category.Title}</a>
                  </Link>
                </li>
              ))}
            </ul>
            {sizeLarge && event.LongDescription && (
              <p className="card-event__description">
                {removeHtml(event.LongDescription)}
              </p>
            )}
            {sizeMedium && event.ShortDescription && (
              <p className="card-event__description">
                <ReactMarkdown source={removeHtml(event.ShortDescription)} />
              </p>
            )}
            <div className="card-event__time">
              <time>
                <span>{t('Date')}</span>
                {moment(event.StartDate).format('lll')}
              </time>
              {sizeLarge && (
                <>
                  <i className="icon-next-date"></i>
                  <time>
                    <span>{t('End Time')}</span>
                    {moment(event.EndDate).format('lll')}
                  </time>
                </>
              )}
            </div>
            {sizeLarge && (
              <>
                <div className="card-event__location">
                  <div className="d-flex align-items-center">
                    <i className="icon-location fs-24 mr-4"></i>
                    {event.Location && removeHtml(event.Location)}
                    {!event.Location && t(event.Business.Name)}
                  </div>
                  {event.VenueAddress && (
                    <div className="ml-24 pl-4">
                      {event.VenueAddress}
                      {' ('}
                      <a
                        target="_blank"
                        href={`https://www.google.co.uk/maps/place/${encodeURIComponent(
                          event.VenueAddress
                        )}`}
                      >
                        {t('Map')}
                      </a>
                      {')'}
                    </div>
                  )}
                </div>
                <div className="card-event__calendar">
                  <AddToCalendar
                    children={t('Add to Calendar')}
                    event={addToCalendarData}
                    filename={'event'}
                  />
                </div>
              </>
            )}
          </div>
          <div className="card-event__footer">
            <div className="card-event__attendees">
              {event?.AttendeeCount > 0 && (
                <>
                  <i className="icon-community"></i>
                  <span>{event.AttendeeCount}</span>
                </>
              )}
            </div>
            <div className="card-event__price">
              {event.HasTickets && (
                <>
                  {ticketBought ? (
                    <span className="tag m-0 with-icon-left bought">
                      <i className="icon-check"></i>
                      {isCorporateDashboard && t('Ticket reserved')}
                      {!isCorporateDashboard && t('Ticket bought')}
                    </span>
                  ) : (
                    <>
                      {!isCorporateDashboard && (
                        <>
                          {event.ChepeastPrice == 0 &&
                            event.MostExpensivePrice == 0 && (
                              <span className="tag m-0 free">{t('Free')}</span>
                            )}
                          {event.ChepeastPrice > 0 &&
                            event.ChepeastPrice ===
                              event.MostExpensivePrice && (
                              <span>
                                {LocalizedPrice({
                                  currency:
                                    event.ChepeastProduct.PriceCurrencyCode,
                                  amount: event.ChepeastProduct.Price,
                                })}
                              </span>
                            )}
                          {event.ChepeastPrice != event.MostExpensivePrice && (
                            <div className="d-flex align-items-center">
                              {event.ChepeastPrice == 0 ? (
                                <span className="tag m-0 free mr-4">
                                  {t('Free')}
                                </span>
                              ) : (
                                <>
                                  {LocalizedPrice({
                                    currency:
                                      event.ChepeastProduct.PriceCurrencyCode,
                                    amount: event.ChepeastProduct.Price,
                                  })}
                                </>
                              )}{' '}
                              ~{' '}
                              {LocalizedPrice({
                                currency:
                                  event.MostExpensiveProduct.PriceCurrencyCode,
                                amount: event.MostExpensiveProduct.Price,
                              })}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardEvent;
