import { observable, action } from 'mobx';
import { loadDataHelper } from './storeHelpers';
import StoreBase from './storeBase';

class FilesStore extends StoreBase {
  constructor({ data, host } = {}) {
    super({ data, host });
  }

  @observable isLoadingMyFiles = false;
  @observable hasLoadedMyFiles = false;
  @observable myFiles = [];
  @action loadMyFiles() {
    return loadDataHelper({
      store: this,
      agentKey: 'Files',
      key: 'MyFiles',
    });
  }

  @action loadFile(file_id) {
    return this.getAgent()
    .Files
    .getFile(file_id);
  }
}
export default FilesStore;
