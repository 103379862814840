import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { routes } from 'env/routes';
import Link from 'next/link';
import { withRouter } from 'next/router';
import withCustomComponent from 'ui/components/withCustomComponent';

@withRouter
@withCustomComponent('SignupNavigationMenu')
@withTranslation()
@inject('appStore')
@observer
class SignupNavigationMenu extends Component {
  componentDidMount() {}

  render() {
    const { t, router } = this.props;
    const { configuration } = this.props.appStore;

    return (
      <>
        <nav
          data-component-name={this.props.componentName}
          className="nav-lateral"
          id="js-nav-smooth"
          role="navigation"
        >
          <ul>
            <li className={router.pathname === routes.signup ? 'active' : ''}>
              {router.pathname === routes.signup_plan ||
              router.pathname === routes.signup_products ||
              router.pathname === routes.signup_summary ? (
                <Link href={routes.signup}>
                  <a>{t('Your details')}</a>
                </Link>
              ) : (
                <>{t('Your details')}</>
              )}
            </li>
            <li className={router.pathname === routes.signup_plan ? 'active' : ''}>
              {router.pathname === routes.signup_products ||
              router.pathname === routes.signup_summary ? (
                <Link href={routes.signup_plan}>
                  <a>{t('Choose plan')}</a>
                </Link>
              ) : (
                <>{t('Choose plan')}</>
              )}
            </li>

            {configuration['Signup.ShowStoreOnSignUp'] && (
              <li
                className={router.pathname === routes.signup_products ? 'active' : ''}
              >
                {router.pathname === routes.signup_summary ? (
                  <Link href={routes.signup_products}>
                    <a>{t('Add-ons')}</a>
                  </Link>
                ) : (
                  <>{t('Add-ons')}</>
                )}
              </li>
            )}

            <li
              className={router.pathname === routes.signup_summary ? 'active' : ''}
            >
              {t('Summary')}
            </li>
          </ul>
        </nav>
      </>
    );
  }
}
export default SignupNavigationMenu;
