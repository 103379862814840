import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import ProfilePageLayout from 'ui/layouts/ProfilePageLayout';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { privateRoute } from 'env/privateRoute';
import { withRouter } from 'next/router';
import ResponsiveTable from 'ui/components/tables/ResponsiveTable';
import moment from 'moment';
import { withCustomComponent } from 'ui/components/withCustomComponent';
import Head from 'next/head';

@withCustomComponent('FilesPage')
@privateRoute
@withRouter
@withTranslation()
@inject('appStore', 'authStore', 'filesStore')
@observer
class FilesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedDescriptions: [],
    };
  }

  componentDidMount() {
    const { t, appStore, filesStore } = this.props;
    filesStore
      .loadMyFiles()
      .then(() => {
        this.setState({
          expandedDescriptions: new Array(filesStore.myFiles.Files.length).fill(
            false
          ),
        });
      })
      .catch(() => {
        appStore.setPopMessage(t('Could not load your files.'));
      });
  }

  toggleExpandedDescription = (index) => {
    const expandedDescriptions = [...this.state.expandedDescriptions];
    expandedDescriptions[index] = !expandedDescriptions[index];
    this.setState({ expandedDescriptions });
  };

  render() {
    const { t, appStore, filesStore } = this.props;
    const { myFiles, hasLoadedMyFiles } = filesStore;
    const files =
      hasLoadedMyFiles && _.orderBy(myFiles.Files, ['CreatedOn'], ['desc']);

    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={`${t('My files')} - ${t(this.props.appStore.business.Name)}`}
      >
        <main className="content content--custom pt-0" role="main">
          <div className="portal-title-wrapper">
            <div className="container portal-title-container"><h1 className="portal-title">
              {t('My files')}
            </h1>
            </div>
          </div>
          <div className="container">
            {!hasLoadedMyFiles && (
              <form id="profile-form">
                <fieldset className="section--settings" id="your-plan">
                  <legend className="h3 section__title">
                    <LoadingSpinner />
                  </legend>
                </fieldset>
              </form>
            )}
            {hasLoadedMyFiles && (
              <form className="profile-form">
                <div className="card card-dashboard mb-32">
                  <fieldset>
                    <h5 className="section__title mb-8">{t('My files')}</h5>

                    {!(files?.length > 0) && (
                      <p className="alert alert-info">
                        <b>{t('You have no files.')}</b>
                      </p>
                    )}

                    {files?.length > 0 && (
                      <ResponsiveTable>
                        <thead>
                        <tr>
                          <th>{t('Reference')}</th>
                          <th>{t('Location')}</th>
                          <th>{t('Date')}</th>
                          <th>{t('Status')}</th>
                          <th></th>
                        </tr>
                        </thead>

                        <tbody>
                        <>
                          {files.map((file, i) => {
                            let description = file.Description ?? '';
                            if (
                              description.length > 82 &&
                              !this.state.expandedDescriptions[i]
                            ) {
                              description = description.slice(0, 82) + '...';
                            }

                            let toggleExpand = (
                              <a
                                href="#"
                                onClick={() =>
                                  this.toggleExpandedDescription(i)
                                }
                              >
                                (
                                {this.state.expandedDescriptions[i]
                                  ? t('collapse')
                                  : t('expand')}
                                )
                              </a>
                            );

                            return (
                              <tr key={i}>
                                <td className="align-middle">
                                  <span className="fs-16 font-weight-bold">
                                    {file.Name}
                                  </span>
                                  <br />
                                  <span className="text-gray-600">
                                    {description}{' '}
                                    {file.Description?.length > 82 &&
                                      toggleExpand}
                                  </span>
                                </td>

                                <td className="align-middle">
                                  {file.BusinessName}
                                </td>

                                <td className="align-middle">
                                  {t('{{CreatedOn}}', {
                                    CreatedOn: moment(file.CreatedOnUtc).format(
                                      'll'
                                    ),
                                  })}
                                </td>

                                <td className="align-middle">
                                  <div
                                    className={`tag m-0 ${
                                      file.Signed ? 'green' : 'red'
                                    }`}
                                  >
                                    <i
                                      className={`${
                                        file.Signed
                                          ? 'icon-check'
                                          : 'icon-alert'
                                      } mr-4`}
                                    ></i>
                                    <span>
                                      {file.Signed
                                        ? t('Signed')
                                        : t('Not Signed')}
                                    </span>
                                  </div>
                                </td>

                                <td className="align-middle">
                                  <a
                                    href="#"
                                    className=""
                                    onClick={async (e) => {
                                      e.preventDefault();

                                      let blob;
                                      try {
                                        blob = await filesStore.loadFile(
                                          file.IdString
                                        );
                                      } catch(err) {
                                        return appStore.setPopMessage(
                                          t('Sorry, we could not download this file. {{err}}', { err })
                                        );
                                      }

                                      if (!blob) {
                                        return appStore.setPopMessage(
                                          t('Sorry, we could not download this file.')
                                        );
                                      }

                                      let a = document.createElement('a');
                                      a.href = window.URL.createObjectURL(
                                        new Blob([blob], { type: blob.type })
                                      );
                                      a.download = file.Name;
                                      a.style.display = 'none';
                                      document.body.appendChild(a);
                                      a.click();
                                    }}
                                  >
                                    <i className="icon-download fs-14 mr-4 tdn"></i>
                                    <span>{t('Download')}</span>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                        </tbody>
                      </ResponsiveTable>
                    )}
                  </fieldset>
                </div>
              </form>
            )}
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default FilesPage;
