import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import ProfilePageLayout from 'ui/layouts/ProfilePageLayout';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import YourPlanSection from 'ui/_pages/plan/YourPlanSection';
import PlansTable from 'ui/_pages/plan/PlansTable';
import AllowancesSection from 'ui/_pages/plan/AllowancesSection';
import ProductsSection from 'ui/_pages/plan/ProductsSection';
import { privateRoute } from 'env/privateRoute';
import { withCustomComponent } from 'ui/components/withCustomComponent';
import { withRouter } from 'next/router';
import { routes } from 'env/routes';
import CreditUsageSection from 'ui/_pages/plan/CreditUsageSection';
import Head from 'next/head';

@withCustomComponent('PlanPage')
@privateRoute
@withTranslation()
@inject('appStore', 'authStore', 'checkoutStore', 'invoicingStore')
@withRouter
@observer
class PlanPage extends Component {
  componentDidMount() {
    const { t, appStore, invoicingStore } = this.props;

    invoicingStore.loadBenefits().catch((err) => {
      appStore.setPopMessage(
        t('Sorry, we could not load your benefits.', { err })
      );
    });
  }

  onPlanSelected = (plan) => {
    const { router, checkoutStore } = this.props;

    checkoutStore.addToBasket({
      type: 'contract',
      data: plan,
    });

    router.push(routes.signup_summary);
  };

  render() {
    const { t, invoicingStore, appStore } = this.props;
    const { hasLoadedBenefits } = invoicingStore;
    const hasLoadedData = hasLoadedBenefits;
    return (
      <ProfilePageLayout componentName={this.props.componentName}>
        <Head>
          <title>
            {t('My Plan')} - {t(this.props.appStore.business.Name)}
          </title>
        </Head>
        <div>
          {!hasLoadedData && (
            <form id="profile-form">
              <fieldset className="section--settings" id="your-plan">
                <legend className="h3 section__title">
                  <LoadingSpinner />
                </legend>
              </fieldset>
            </form>
          )}
          {hasLoadedData && (
            <form id="profile-form" className="mb-84">
              <YourPlanSection />
              <AllowancesSection />
              <CreditUsageSection />
              <ProductsSection />
              <PlansTable onPlanSelected={this.onPlanSelected} />
            </form>
          )}
        </div>
      </ProfilePageLayout>
    );
  }
}

export default PlanPage;
