import React from 'react';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { EventTicketsPageAttendeeListItem } from 'ui/_pages/events/tickets/EventTicketsPageAttendeeListItem';
export const EventTicketsPageAttendeeList = withCustomComponent(
  'EventTicketsPageAttendeeList'
)(
  inject(
    'appStore',
    'authStore',
    'eventsStore',
    'checkoutStore'
  )(
    withRouter(
      observer(
        ({ checkoutStore, authStore, router, appStore, eventsStore }) => {
          const { t } = useTranslation();
          const { eventTicketsPage } = eventsStore;
          const event = eventTicketsPage.Event;
          const { business } = appStore;
          const { customer } = authStore;

          const addToBasket = () => {
            checkoutStore.addToBasket({
              type: 'event',
              data: event,
            });
            router.push(routes.checkout);
          };

          var totalSelected = _.sumBy(event.EventProducts, 'Quantity');
          if (totalSelected == 0) return <></>;

          return (
            <div className="mt-32">

              <h5 className="mb-6">{t('Attendees')}</h5>
              <p className="fs-12 text-gray-700 mb-24">
                {t(
                  'Type the name and email address of each person attending this event. They will receive a notification and their ticket by email.'
                )}
              </p>              
              
              <div className="form" data-bind="foreach: attendees">
                {event.EventProducts.map((product, i) =>
                  product.Attendees.map((attendee, j) => (
                    <EventTicketsPageAttendeeListItem
                      key={i + '-' + j}
                      product={product}
                      attendee={attendee}
                    />
                  ))
                )}
              </div>

              <div className="text-center mt-40 mb-16">
                <button
                  onClick={(ev) => {
                    ev.preventDefault();
                    addToBasket();
                  }}
                  className="btn px-24 px-md-72"
                  type="button"
                >
                  {t('Check Out')}
                </button>
              </div>

            </div>
          );
        }
      )
    )
  )
);
