import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import moment from 'moment';
import { routes } from 'env/routes';
import { removeHtml } from 'env/utils/strings';
import { privateRoute } from 'env/privateRoute';
import AuthenticatedLink from 'ui/components/AuthenticatedLink';

import CardPerkDiscount from 'ui/components/cards/CardPerkDiscount';

@privateRoute
@withCustomComponent('PerksPage')
@withTranslation()
@inject('contentStore', 'appStore', 'authStore')
@observer
class PerksPage extends Component {
  componentDidMount() {
    const { contentStore } = this.props;
    contentStore.loadCommunityPerks();
  }

  render() {
    const { t, contentStore, appStore } = this.props;
    const { business } = appStore;
    const { communityPerksByGroup } = contentStore;
    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={t('Perks and benefits') + ' - ' + t(business.Name)}
      >
        <main className="content content--custom faq" role="main">
          <div className="container">
            <h2 className="mb-24">{t('Perks and benefits')}</h2>
            {communityPerksByGroup.map((group, gi) => (
              <div
                className={`pb-40 ${gi > 0 ? 'border-top pt-40' : ''}`}
                key={gi}
              >
                <h6>{group.group != 'null' ? t(group.group) : t('General')}</h6>
                <ul className="list-grid list-grid--50 list-grid--md-100">
                  {group.perks.map((perk, pi) => (
                    <li key={pi}>
                      <CardPerkDiscount perk={perk} />
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default PerksPage;
