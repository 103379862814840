import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import {withRouter} from 'next/router';
import ContractDetails from 'ui/_pages/plan/ContractDetails';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import ReactPaginate from 'react-paginate';

let Index = props => {
  const {
    t,
    appStore,
    authStore,
    invoicingStore,
    itemsPerPage = 5,
  } = props;
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(null);
  const [itemOffset, setItemOffset] = useState(null);

  useEffect(() => {
    let cancel = false;

    if (!invoicingStore.hasLoadedBenefits) {
      invoicingStore.loadBenefits().then(data => {
        if (cancel) return;

        setItemOffset(0);
      });
    }

    return () => {
      cancel = true;
    }
  }, []);

  let nonCancelledContracts = invoicingStore.benefits.NonCancelledContracts ?? [];
  nonCancelledContracts = nonCancelledContracts.reverse();

  // Pagination handlers
  useEffect(() => {
    if (!invoicingStore.hasLoadedBenefits)
      return;

    setCurrentItems(nonCancelledContracts.slice(itemOffset, itemOffset + itemsPerPage));
    setPageCount(Math.ceil(nonCancelledContracts.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  const handlePageClick = e => {
    setItemOffset(e.selected * itemsPerPage);
  };

  if (!(invoicingStore.hasLoadedBenefits)) {
    return <LoadingLayout/>;
  }

  return (
    <DefaultLayout>
      <main className="content content--custom pt-0" role="main">
        <div className="portal-title-wrapper">
          <div className="container portal-title-container">
            <h1 className="portal-title">
              {t('My current plans')}
            </h1>
          </div>
        </div>
        <div className="container">
          {currentItems.length === 0 && (
            <div className="alert alert-info mb-24" role="alert">
              <b>{t('You are not in a price plan.')}</b>
            </div>
          )}

          {currentItems.map((c, i) => (
            <div key={i} className="card-item-wrapper">
              <ContractDetails key={i} contract={c}/>
            </div>
          ))}
        </div>
        <nav>
          <ReactPaginate
            breakLabel="..."
            nextLabel={(
              <span aria-hidden="true">
                <i className="icon-chevron-right"></i>
              </span>
            )}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={(
              <span aria-hidden="true">
                <i className="icon-chevron-right"></i>
              </span>
            )}
            renderOnZeroPageCount={null}
            className="pagination justify-content-center"
            previousLinkClassName="btn prev"
            nextLinkClassName="btn next"
            pageLinkClassName="btn"
            activeClassName="active"
            breakLinkClassName="dots"
          />
        </nav>
      </main>
    </DefaultLayout>
  );
};

export default Index = withCustomComponent('MyPlans')(
  withRouter(
    withTranslation()(
      inject('appStore', 'authStore', 'invoicingStore', 'checkoutStore')(
        observer(Index),
      ),
    ),
  ),
);
