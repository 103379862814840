import React, {useEffect, useRef} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import {routeInfo, routes} from 'env/routes';
import Breadcrumbs from 'ui/components/Breadcrumbs';
import {SignupForm} from 'ui/_pages/signup/SignupForm';
import qs from 'query-string';
import {withRouter} from 'next/router';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import {toJS} from 'mobx';

let CreateAccount = (props) => {
  const {
    router,
    t,
    authStore,
    appStore,
    planStore,
    invoicingStore,
    checkoutStore,
  } = props;
  const {
    customer,
    hasLoadedSignupPage,
    isLoggedIn,
    isSavingCustomerWithUser,
  } = authStore;
  const {selectedPlans} = checkoutStore;
  const signupRef = useRef({});

  // Component mount
  useEffect(() => {
    const {configuration} = appStore;
    const {isLoggedIn} = authStore;
    const query = qs.parse(location.search);
    const signupToken = query.t;
    const {tariff_guid, team_guid} = query;

    if (isLoggedIn) {
      authStore.loadCustomFields();
    } else {
      authStore.loadSignupCustomFields({tariff_guid, team_guid, signupToken});
      if (!configuration['Members.CanSignup']) {
        if (!router.query.returnurl) {
          router.push(routes.contact);
          return;
        }
      }

      authStore.createCustomer({
        tourDate: null,
        business: appStore.business,
        newsletter: configuration['Newsletter.DefaultOptIn'] == 'true',
        createPassword: !configuration['Signup.AskForPassword'],
      });
    }

    // Load Price plan
    if (!invoicingStore.hasLoadedPricePlans) {
      invoicingStore.loadPricePlans({
        customer: null,
        tariffGuid: null,
      });
    }

    // Load products
    invoicingStore.loadStoreProducts({selectedPlans});

    authStore.loadSignupPage({
      t: signupToken,
      tariff_guid,
      team_guid,
    }).then((data) => {
      if (!isLoggedIn && !router.query.returnurl) {
        if (data.RedirectTo?.indexOf('missingInvite') > -1)
          router.push(routes.contact);
      }
    }).catch((err) => {
      appStore.setPopMessage(
        t('Sorry, we could not load this page. {{err}}', {err}),
      );
    });
  }, []);

  if (!customer || !hasLoadedSignupPage || !planStore.plan) {
    return <LoadingLayout/>;
  }

  // Skip account creation
  if (isLoggedIn) {
    router.push(routes.store_products({from: 'plans'}));

    return <LoadingLayout/>;
  }

  if (!(invoicingStore.hasLoadedPricePlans && invoicingStore.hasLoadedStoreProducts)) {
    return <LoadingLayout/>;
  }

  const products = invoicingStore.pricePlans.Products ?? [];
  let backUrl = planStore.plan.UniqueId ? routes.plans_plan(planStore.plan.UniqueId) : routes.home;
  let returnUrl = products.length > 0 ? routes.store_products({from: 'plans'}) : routes.checkout;

  console.log('returnUrl', returnUrl);
  console.log('backUrl', backUrl);

  return (
    <DefaultLayout>
      <main className="content content--custom pt-0" role="main">
        <div className="our-breadcrumbs mb-16 mb-md-48">
          <div className="container">
            <nav aria-label="breadcrumb">
              <Breadcrumbs
                steps={[
                  {
                    ...routeInfo.plans_plan,
                    route: routeInfo.plans_plan.route(planStore.plan.UniqueId),
                    class: 'done',
                  },
                  ...!isLoggedIn ? [routeInfo.plans_create_account] : [],
                  ...products.length > 0 ? [{name: routeInfo.store_products.name}] : [],
                  {name: routeInfo.checkout.name},
                ]}
              />
            </nav>
          </div>
        </div>
        <div className="container">
          <div className="funnel-steps-wrapper">
            <div className="funnel-steps-header">
              <h1 className="funnel-steps-header-title">
                {t('Customer info')}
              </h1>
              <Link
                href={{
                  pathname: routes.login,
                  query: {
                    returnurl: routes.checkout,
                  },
                }}
              >
                <a className="btn funnel-steps-header-login">
                  {t('Sign in')}
                </a>
              </Link>
            </div>
            <div className="funnel-steps-body">
              <SignupForm
                ref={signupRef}
                returnUrl={returnUrl}
                viewShowCreateAccountButton={false}
              />
            </div>
          </div>
        </div>
      </main>
      <div className="step-buttons">
        <div className="container">
          <div className="btn-group justify-content-end">
            <Link href={backUrl}>
              <a className="btn btn-gray">
                {t('Back')}
              </a>
            </Link>
            <Link href="#">
              <a
                className="btn"
                disabled={isSavingCustomerWithUser ? 'disabled' : null}
                onClick={e => {
                  e.preventDefault();
                  signupRef.current.saveForm();
                }}
              >
                {isSavingCustomerWithUser ? (
                  <LoadingSpinner/>
                ) : (
                  <>{t('Create and continue')}</>
                )}
              </a>
            </Link>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

CreateAccount = withRouter(
  withCustomComponent('CreateAccount')(
    withTranslation()(
      inject('appStore', 'authStore', 'planStore', 'invoicingStore', 'checkoutStore')(
        observer(CreateAccount),
      ),
    ),
  ),
);

export default CreateAccount;