/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('RazorPayPaymentButton')
@withTranslation()
@inject('appStore', 'authStore')
class RazorPayPaymentButton extends Component {
  render() {
    const { t, appStore, authStore, invoice, componentName } = this.props;
    const { business } = appStore;
    const { customer } = authStore;

    return (
      <a
        data-component-name={componentName}
        className="btn btn-icon btn-link text-gray-900"
        data-email={customer.Email}
        data-description={invoice.InvoiceNumber}
        data-payurl={`${business.NativeHomeUrlWithLanguage}/callbacks/paycoworkerinvoice?result=OK&provider=razorPay&invoiceId=${invoice.Id}&invoiceownerid=${invoice.CoworkerId}`}
        data-amount={(invoice.TransactionTotalAmount * 100).toFixed(0)}
        data-currency={invoice.TransactionCurrency.Code}
        href="#"
      >
        <i aria-hidden="true" className="icon-credit-card"></i>
        {t('Pay by card')}
      </a>
    );
  }
}
export default RazorPayPaymentButton;
