import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import { BannerCarousel } from 'ui/_pages/about/BannerCarousel';
import OurTeam from 'ui/_pages/about/OurTeam';
import OpenPositions from 'ui/_pages/about/OpenPositions';
import { HomeLocationsSection } from 'ui/_pages/home/components/HomeLocationsSection';
import { ContactForm } from 'ui/_pages/contact/ContactForm';
import ReactMarkdown from 'react-markdown';
import { removeHtml } from 'env/utils/strings';

const AboutPage = withCustomComponent('AboutPage')(
  inject(
    'appStore',
    'supportStore'
  )(
    observer(
      ({ appStore, supportStore, componentName }) => {
        const { t } = useTranslation();

        const { business, configuration } = appStore;
        const { newContactMessage } = supportStore;

        useEffect(() => {
          if (!appStore.hasLoadedHomePage)
            appStore.loadHomePage().catch((ex) => null);
        }, []);

        return (
          <DefaultLayout
            componentName={componentName}
            title={`${t('About us')} - ${t(business.Name)}`}
          >
            <main className="content content--about" role="main">
              <div className="about-page-content">
                <div className="container">
                  {business.ShortIntroduction && (
                    <div className="intro">
                      {business.ShortIntroduction.length < 100 ? (
                        <h1>
                          <ReactMarkdown
                            source={t(removeHtml(business.ShortIntroduction))}
                          />
                        </h1>
                      ) : (
                        <h2>
                          <ReactMarkdown
                            source={t(removeHtml(business.ShortIntroduction))}
                          />
                        </h2>
                      )}
                      <h4>{t(`About {{Name}}`, { Name: t(business.Name) })}</h4>
                      {business.HasBanner && (
                        <div
                          className="img"
                          style={{
                            backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/business/GetBannerFor?id=${business.Id}&w=1150&h=440&mode=max)`,
                          }}
                        ></div>
                      )}
                    </div>
                  )}
                  {business.AboutUs && (
                    <div
                      className="content fr-view"
                      dangerouslySetInnerHTML={{
                        __html: business.AboutUs,
                      }}
                    ></div>
                  )}
                </div>
              </div>
    
              <BannerCarousel />
    
              {appStore.businesses.length > 1 && (
                <HomeLocationsSection title={t('Our locations')} />
              )}
    
              <OurTeam />
    
              {/* <OpenPositions /> */}
    
              {configuration['PublicWebSite.Contact'] && (
                <div className="about-page-contact-form">
                  <div className="container">
                    <h2 className="title">{t('Get in touch with us')}</h2>
                    <ContactForm newContactMessage={newContactMessage} />
                  </div>
                </div>
              )}
            </main>
          </DefaultLayout>
        );
      }
    )
  )
)

export default AboutPage;
