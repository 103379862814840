/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import {action} from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import withCustomComponent from 'ui/components/withCustomComponent';

import BookingResourceSelector from 'ui/_pages/bookings/details/BookingResourceSelector';
import BookingResourceInfo from 'ui/_pages/bookings/details/BookingResourceInfo';
import BookingVisitorsForm from 'ui/_pages/bookings/details/BookingVisitorsForm';
import BookingNotesForm from 'ui/_pages/bookings/details/BookingNotesForm';
import BookingZoomForm from 'ui/_pages/bookings/details/BookingZoomForm';

import BookingDateTimeForm from 'ui/_pages/bookings/details/BookingDateTimeForm';
import BookingServicesForm from 'ui/_pages/bookings/details/BookingServicesForm';
import BookingDiscountForm from 'ui/_pages/bookings/details/BookingDiscountForm';
import BookingCancellationStatus from 'ui/_pages/bookings/details/BookingCancellationStatus';
import BookingStatus from 'ui/_pages/bookings/details/BookingStatus';
import BookingFormToolBar from 'ui/_pages/bookings/details/BookingFormToolBar';
import {slugify} from 'env/utils/strings';

@withCustomComponent('BookingDetailsModal')
@withTranslation()
@inject('appStore', 'bookingsStore')
@observer
class BookingDetailsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNoteField: false,
    };
  }

  componentDidMount() {
    this.showBookingModal();

    const {bookingsStore} = this.props;
    const {booking} = bookingsStore;
    if (booking?.ResourceId) {
      this.resetBookingPrice();
      this.loadResourceProducts(booking);
      this.loadBookingPrice();
    }
  }

  componentWillUnmount() {
    this.hideBookingModal();
  }

  resetBookingPrice = () => {
    const {bookingsStore} = this.props;
    bookingsStore.resetBookingPrice();
  };

  getResource() {
    const {bookingsStore} = this.props;
    const {booking, resources} = bookingsStore;
    return (
      booking &&
      _(resources)
        .filter((r) => r.Id === booking.ResourceId)
        .first()
    );
  }

  loadResourceProducts = (booking) => {
    const {bookingsStore} = this.props;
    bookingsStore.loadResourceProducts({
      bookingId: booking.Id,
      resourceId: booking.ResourceId,
    });
  };

  loadBookingPrice = () => {
    const {bookingsStore} = this.props;
    bookingsStore.loadBookingPrice();
  };

  @action updateBooking(prop, value) {
    const {bookingsStore} = this.props;
    const {booking} = bookingsStore;

    const prevToTime = moment(booking.ToTime);
    const prevFromTime = moment(booking.FromTime);
    const prevMinutesLength = Math.abs(
      prevToTime.diff(prevFromTime, 'minutes')
    );

    booking[prop] = value;

    const toTime = moment(booking.ToTime);
    const fromTime = moment(booking.FromTime);

    //Make sure "to date" is always after "from date"
    if (prop === 'FromTime') {
      if (new Date(booking.ToTime) < value) {
        let newToTime = fromTime.add(prevMinutesLength, 'minutes').toDate();
        booking.ToTime = newToTime;
      }
    }

    //Make sure "from date" is always before "to date"
    if (prop === 'ToTime') {
      if (new Date(booking.FromTime) > value) {
        let newFromTime = toTime
          .subtract(prevMinutesLength, 'minutes')
          .toDate();
        booking.FromTime = newFromTime;
      }
    }

    this.loadBookingPrice();
  }

  showBookingModal = () => {
    const $ = window.$;
    $('#bookings-booking-form').modal({
      backdrop: 'static',
      keyboard: false,
      show: true,
    });
  };

  hideBookingModal = () => {
    const {bookingsStore} = this.props;
    bookingsStore.closeNewBooking();
    const $ = window.$;
    $('#bookings-booking-form').modal('hide');
    window.location.hash = '';
  };

  getCurrentBooking = () => {
    const {bookingsStore} = this.props;
    const {booking} = bookingsStore;
    return booking;
  };

  render() {
    const {t, componentName, appStore, bookingsStore} = this.props;
    const {booking, isLoadingBooking} = bookingsStore;
    const hasLoadedData = booking;
    const resource = this.getResource();
    const hash =
      window.location.hash && window.location.hash.length > 0
        ? window.location.hash
        : null;

    const isCorporateDashboard = appStore.corporateDashboard;

    return (
      <div
        data-component-name={componentName}
        id="bookings-booking-form"
        className={'modal modal-booking-details'}
        aria-labelledby="modal-bookLabel"
        aria-hidden="true"
      >
        <div
          className={`modal-dialog modal-xl modal-dialog-centered resource-id-${
            resource?.Id
          } resource-type-${slugify(
            resource?.ResourceTypeName
          )} resource-group-${slugify(resource?.GroupName)}`}
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              {!hash && (
                <BookingResourceSelector
                  updateBooking={(prop, value) =>
                    this.updateBooking(prop, value)
                  }
                  loadResourceProducts={(booking) =>
                    this.loadResourceProducts(booking)
                  }
                />
              )}

              <button className="close" onClick={this.props.close}>
                <i className="icon-close-large"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                {isLoadingBooking && <LoadingSpinner/>}
                {hasLoadedData && (
                  <form>
                    <div className="row mb-8">
                      <div className="col-lg-7">

                        {!hash && (
                          <BookingResourceInfo
                            updateBooking={(prop, value) =>
                              this.updateBooking(prop, value)
                            }
                            showResourceAvailableSlots={false}
                          />
                        )}

                        {(!hash || hash == '#visitors') && (
                          <BookingVisitorsForm
                            updateBooking={(prop, value) =>
                              this.updateBooking(prop, value)
                            }
                          />
                        )}

                        {(!hash || hash == '#notes') && (
                          <BookingNotesForm
                            updateBooking={(prop, value) =>
                              this.updateBooking(prop, value)
                            }
                          />
                        )}

                        {(!hash || hash == '#zoom') && (
                          <BookingZoomForm
                            updateBooking={(prop, value) =>
                              this.updateBooking(prop, value)
                            }
                          />
                        )}
                      </div>
                      <div className="col-lg-5 mt-24 mt-lg-0">
                        {!hash && (
                          <div className="pl-md-24">
                            <BookingDateTimeForm
                              updateBooking={(prop, value) =>
                                this.updateBooking(prop, value)
                              }
                            />
                            <BookingServicesForm
                              loadBookingPrice={() => this.loadBookingPrice()}
                            />
                            {/*{!isCorporateDashboard && (
                              <BookingDiscountForm
                                updateBooking={(prop, value) =>
                                  this.updateBooking(prop, value)
                                }
                              />
                            )}*/}
                            <BookingCancellationStatus/>
                            {booking.ResourceId > 0 && (
                              <>
                                <BookingStatus/>
                                <BookingFormToolBar
                                  showBookingModal={this.showBookingModal}
                                  hideBookingModal={this.hideBookingModal}
                                  updateBooking={(prop, value) =>
                                    this.updateBooking(prop, value)
                                  }
                                  close={this.props.close}
                                />
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BookingDetailsModal;
