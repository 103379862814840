import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import {withRouter} from 'next/router';

@withRouter
@withCustomComponent('LegacyCatchAllPage')
@withTranslation()
@observer
class LegacyCatchAllPage extends Component {
}

LegacyCatchAllPage.getInitialProps = ({asPath, query, res}) => {
  if (res) {
    //  Precedence
    if (asPath.indexOf(`/${query.language ? `${query.language}/` : ''}Login/ResetPassword`) > -1) {
      res.writeHead(302, {
        Location: asPath.
          replace(`/${query.language ? `${query.language}/` : ''}Login/ResetPassword`, '/resetPassword'),
      });
    } else if (asPath.indexOf('Login') > -1) {
      res.writeHead(302, {
        Location: asPath.replace(`Login`, 'login'),
      });
    } else if (asPath.indexOf('Products') > -1) {
      res.writeHead(302, {
        Location: asPath.replace(`Products`, 'products'),
      });
    } else if (asPath.toLowerCase().indexOf('termsandconditions') > -1) {
      res.writeHead(302, {
        Location: '/legal/terms',
      });
    } else if (asPath.toLowerCase().indexOf('deliveries') > -1) {
      res.writeHead(302, {
        Location: '/profile/deliveries?public',
      });
    } /*else if (asPath.toLowerCase().indexOf('invoices') > -1) {
      res.writeHead(302, {
        Location: '/profile/invoices?public',
      });
    }*/ else {
      res.writeHead(302, {
        Location: asPath.replace(`${query.language}/`, ''),
      });
    }
    res.end();
  }

  return {};
};

export default LegacyCatchAllPage;
