import React, { useEffect } from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';
import { CoursePageDiscountSection } from './CoursePageDiscountSection';

export const CoursePageAccess = withCustomComponent('CoursePageAccess')(
  inject(
    'appStore',
    'coursesStore',
    'authStore',
    'checkoutStore'
  )(
    withRouter(
      observer(({ router, appStore, coursesStore, authStore, checkoutStore }) => {
        const { t } = useTranslation();
        const { isLoggedIn } = authStore;
        const { Course, Enrolled } = coursesStore.coursePage;
        const { invoicePreview, isLoadingInvoicePreview } = checkoutStore;
        const isLoadingNextPage = coursesStore.isLoadingSignUp || coursesStore.isLoadingCoursePage || coursesStore.isLoadingLessonsPage;
        const buttonText = Enrolled ? t('Access course') : t('Enrol');
        const isFree = !Course.TariffPrice;

        const onEnrolClick = async () => {
          if(Enrolled) {
            accessCourse();
            return;
          }

          if(isFree) {
            await coursesStore.loadSignUp(Course.Id);
            accessCourse();
          } else {
            addCourseToBasket();
            router.push(routes.checkout);
          }
        };

        const addCourseToBasket = () => {
          checkoutStore.addToBasket({
            type: 'course',
            data: Course,
            previewInvoice: false
          });
        }

        const accessCourse = async () => {
          try {
            const {CanAccessCourse} = await coursesStore.loadCoursePage(Course.Id).catch((err) => {
              appStore.setPopMessage(
                t('Sorry, we could not load this page. {{err}}', { err })
              );
            });

            if(CanAccessCourse) {
              const lessonsPage = await coursesStore.loadLessonsPage(Course.Id);
              let currentLesson = lessonsPage.Lessons[lessonsPage.Lessons.length-1];
              if(lessonsPage.CurrentLesson) {
                currentLesson = lessonsPage.CurrentLesson;
              }

              router.push(`${routes.lesson_by_id(Course, currentLesson.Id)}?course_id=${Course.Id}&lesson_id=${currentLesson.Id}`, `${routes.lesson_by_id(Course, currentLesson.Id)}`);
              return;
            }

            router.push(routes.invoices);
          } catch(err) {
            console.log(err);
          }
        }

        useEffect(() => {
          if(!Enrolled) {
            checkoutStore.loadPartialInvoicePreview({basket: [
              {
                type: 'course',
                Course: {
                  Id: Course.Id
                },
              }
            ]});
          }
        }, []);

        return (
          <div className="card course-page-access p-16 p-md-24 h-auto mb-24">
            {(!isFree && !Enrolled && !isLoadingInvoicePreview) && (
              <>
                {/*{isLoggedIn && <CoursePageDiscountSection/>}*/}

                <div className="d-block d-flex justify-content-between align-items-start mb-24">
                  <div className="text-gray-700">{t('Total')}</div>
                  <div className="price">{invoicePreview?.TotalFormated || LocalizedPrice({currency: Course.TariffCurrencyCode, amount: Course.TariffPrice})}</div>
                </div>
              </>
            )}
            
            {isLoadingInvoicePreview && <LoadingSpinner/>}

            {!isLoadingInvoicePreview && (
              isLoggedIn ?

                <>
                  <div className="btn btn--primary btn-block" onClick={onEnrolClick}>
                    {isLoadingNextPage && <LoadingSpinner/>}
                    {!isLoadingNextPage && buttonText}
                  </div>

                  {(!Enrolled && !isFree) && (
                    <div className="btn btn-outline btn-block border mt-8" onClick={addCourseToBasket}>
                      {isLoadingNextPage && <LoadingSpinner/>}
                      {!isLoadingNextPage && t('Add to Cart')}
                    </div>
                  )}
                </> :

                <>
                  <Link href={`${routes.login}?returnurl=${router.asPath}`}>
                    <a className="btn btn--primary btn-block">
                      {isLoadingNextPage && <LoadingSpinner/>}
                      {!isLoadingNextPage && buttonText}
                    </a>
                  </Link>

                  {!isFree && (
                    <Link href={`${routes.login}?returnurl=${router.asPath}`}>
                      <a className="btn btn-outline btn-block mt-8">
                        {isLoadingNextPage && <LoadingSpinner/>}
                        {!isLoadingNextPage && t('Add to Cart')}
                      </a>
                    </Link>
                  )}
                </>
              )
            }
          </div>
        );
      })
    )
  )
);