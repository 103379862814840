import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation, Trans} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import {routes} from 'env/routes';
import moment from 'moment';
import {withRouter} from 'next/router';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import {FaqArticleMetaData} from 'ui/_pages/faq/FaqArticleMetaData';
import {FaqArticleDetails} from 'ui/_pages/faq/FaqArticleDetails';

@withRouter
@withCustomComponent('FaqArticlePage')
@withTranslation()
@inject('appStore', 'contentStore', 'authStore')
@observer
class FaqArticlePage extends Component {
  componentDidMount() {
    const {contentStore} = this.props;

    if (!contentStore.hasLoadedFaqArticles) contentStore.loadFaqArticles();
  }

  render() {
    const {t, router, appStore, contentStore} = this.props;
    const {business} = appStore;
    const {faqArticles} = contentStore;
    const article = faqArticles.find((a) => a.Id == router.query.article_id);

    if (!article) return <LoadingLayout/>;

    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={t('Frequently Asked Questions') + ' - ' + t(business.Name)}
      >
        <main className="content content--custom pt-0" role="main">
          <div className="portal-title-wrapper">
            <div className="container portal-title-container justify-content-start flex-nowrap">
              <Link href={routes.help}>
                <a className="single-page-back">
                  <i className="icon-arrow-left"></i>
                </a>
              </Link>
              <h1 className="portal-title">
                {article.Title}
              </h1>
            </div>
          </div>
          <div className="container">
            <div className="card h-auto">
              <FaqArticleDetails article={article}/>
            </div>
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default FaqArticlePage;
