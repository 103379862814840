import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import withCustomComponent from 'ui/components/withCustomComponent';
import { removeHtml } from 'env/utils/strings';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';

export const CardCourse = withCustomComponent('CardCourse')(
  inject('appStore')(
    withRouter(
      observer(
        ({
          componentName,
          appStore,
          courseContainer,
          sizeExtraSmall,
          sizeSmall,
          sizeMedium,
          sizeLarge,
          isMyCourse,
        }) => {
          const { t } = useTranslation();
          const { business } = appStore;
          const { Course, Host, AttendeeCount, LessonsCount } = courseContainer;

          let imageUrl = 'https://placehold.it/720x540?text=?';
          let price = 0;

          if (Course.LargeImageFileName) {
            imageUrl = `${
              business.NativeHomeUrlWithLanguage
            }/learn/getCourselargeimage?id=${Course.Id}&w=${320}&h=${380}`;
          } else if (Course.ImageFileName) {
            imageUrl = `${
              business.NativeHomeUrlWithLanguage
            }/learn/getCourseimage?id=${Course.Id}&w=${320}&h=${380}`;
          }

          if (Course.TariffPrice) {
            price = LocalizedPrice({
              currency: Course.TariffCurrencyCode,
              amount: Course.TariffPrice,
            });
          }

          return (
            <div
              className={`card card-course ${
                sizeExtraSmall ? 'card-course--xs' : ''
              } ${sizeSmall ? 'card-course--sm' : ''} ${
                sizeMedium ? 'card-course--md' : ''
              } ${sizeLarge ? 'card-course--lg' : ''}`}
              data-component-name={componentName}
            >
              {!sizeLarge ? (
                <Link
                  href={routes.course_by_id('[course_id]')}
                  as={routes.course_by_id(Course)}
                >
                  <a
                    className="card-course__image"
                    style={{
                      backgroundImage: `url(${imageUrl})`,
                    }}
                    alt={removeHtml(Course.Title)}
                  ></a>
                </Link>
              ) : (
                <div
                  className="card-course__image"
                  style={{
                    backgroundImage: `url(${imageUrl})`,
                  }}
                ></div>
              )}

              <div className="card-course__main">
                <div className="card-course__content">
                  {!sizeLarge ? (
                    <Link
                      href={routes.course_by_id('[course_id]')}
                      as={routes.course_by_id(Course)}
                    >
                      <a className="card-course__title">
                        {removeHtml(Course.Title)}
                      </a>
                    </Link>
                  ) : (
                    <div className="card-course__title">
                      {removeHtml(Course.Title)}
                    </div>
                  )}

                  <ul className="list-tags card-course__tags">
                    <li className="with-icon-left">
                      <i className="icon-marker-pin"></i>
                      {Course.Location && removeHtml(Course.Location)}
                      {!Course.Location && Course.BusinessName}
                    </li>

                    {Course.GroupName && (
                      <li>
                        <Link href={routes.course_by_group(Course.GroupName)}>
                          <a href="#">{Course.GroupName}</a>
                        </Link>
                      </li>
                    )}
                  </ul>

                  <p className="card-course__description">
                    {Course.SummaryText && removeHtml(Course.SummaryText)}
                  </p>

                  {!sizeExtraSmall && (
                    <>
                      <div className="card-course__lessons-count">
                        <i className="icon icon-whiteboard-2 align-middle"></i>
                        <span className="align-middle text-gray-700">
                          {t('Lessons:')}{' '}
                        </span>
                        <span className="align-middle">{LessonsCount}</span>
                      </div>

                      <div className="card-course__skill-level">
                        <i className="icon icon-privacy-shield align-middle"></i>
                        <span className="align-middle text-gray-700">
                          {t('Skill Level:')}{' '}
                        </span>
                        <span className="align-middle">----</span>
                      </div>
                    </>
                  )}
                </div>

                <div className="card-course__footer align-items-center">
                  {sizeLarge && Host.FullName && (
                    <div className="card-course__author">
                      <h5>
                        <b>{t('Course by:')}</b>
                      </h5>
                      <Link
                        href={`${business.NativeHomeUrl}${Host.ProfileUrl}`}
                      >
                        <a className="user">
                          <img
                            className="avatar"
                            src={`${appStore.business.NativeHomeUrlWithLanguage}/coworker/getavatar/${Host.IdString}?h=32&w=32&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}
                            alt={Host.FullName}
                          />
                          <span>{Host.FullName}</span>
                        </a>
                      </Link>
                    </div>
                  )}

                  {!sizeLarge && (
                    <>
                      {AttendeeCount > 0 && (
                        <div className="card-course__attendees-count">
                          <i className="icon icon-community align-middle"></i>
                          <span className="align-middle text-gray-700">
                            {t('{{count}} member', { count: AttendeeCount })}
                          </span>
                        </div>
                      )}

                      {isMyCourse ? (
                        <div className="card-course__status">
                          <span
                            className={`tag m-0 ${
                              courseContainer.Completed ? 'green' : 'yellow'
                            }`}
                          >
                            <i
                              className={
                                courseContainer.Completed
                                  ? 'icon-confimred mr-4'
                                  : 'icon-pending mr-4'
                              }
                            ></i>
                            {courseContainer.Completed
                              ? t('Completed')
                              : t('In Progress')}
                          </span>
                        </div>
                      ) : (
                        <div className="card-course__price">
                          <span className={!price && 'tag m-0 free'}>
                            {price || t('Free')}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        }
      )
    )
  )
);
