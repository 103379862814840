export const bookingsFloorPlanTypes = (t) => [
  { id: 1, key: 'Offices', name: t('Offices') },
  { id: 2, key: 'DedicatedDesks', name: t('Dedicated desks') },
  { id: 3, key: 'HotDesks', name: t('Hot-desks') },
  { id: 4, key: 'Others', name: t('Other') },
];

export const bookingsFeatures = (t) => [
  {
    key: 'AirConditioning',
    name: t('Air conditioning'),
    icon: 'icon-climate'
  },
  {
    key: 'Projector',
    name: t('Projector'),
    icon: 'icon-projector'
  },
  {
    key: 'Internet',
    name: t('Internet'),
    icon: 'icon-icon-internet'
  },
  {
    key: 'ConferencePhone',
    name: t('Conference phone'),
    icon: 'icon-conference-phone'
  },
  {
    key: 'StandardPhone',
    name: t('Standard phone'),
    icon: 'icon-phone'
  },
  {
    key: 'WhiteBoard',
    name: t('Whiteboard'),
    icon: 'icon-whiteboard'
  },
  {
    key: 'LargeDisplay',
    name: t('Large display'),
    icon: 'icon-icon-large-display'
  },
  {
    key: 'Catering',
    name: t('Catering'),
    icon: 'icon-catering'
  },
  {
    key: 'TeaAndCoffee',
    name: t('Tea & Coffee'),
    icon: 'icon-icon-tea-coffee'
  },
  {
    key: 'Drinks',
    name: t('Drinks'),
    icon: 'icon-drinks'
  },
  {
    key: 'SecurityLock',
    name: t('Security lock'),
    icon: 'icon-icon-security-lock'
  },
  {
    key: 'CCTV',
    name: t('CCTV'),
    icon: 'icon-cctv'
  },
  {
    key: 'VoiceRecorder',
    name: t('Voice recorder'),
    icon: 'icon-voice-recorder'
  },
  {
    key: 'Heating',
    name: t('Heating'),
    icon: 'icon-heating'
  },
  {
    key: 'NaturalLight',
    name: t('Natural light'),
    icon: 'icon-icon-natura-light'
  },
  {
    key: 'StandingDesk',
    name: t('Standing desk'),
    icon: 'icon-standing-desk'
  },
  {
    key: 'QuietZone',
    name: t('Quiet zone'),
    icon: 'icon-quiet-zone'
  },
  {
    key: 'WirelessCharger',
    name: t('Wireless charger'),
    icon: 'icon-wireless-charger'
  },
  {
    key: 'PrivacyScreen',
    name: t('Privacy Screen'),
    icon: 'icon-icon-privacy-screen'
  },
  {
    key: 'Soundproof',
    name: t('Soundproof'),
    icon: 'icon-soundproof'
  },
];