import React, {Component} from 'react';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import {removeHtml} from 'env/utils/strings';

@observer
class TextAreaInputField extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);

    this.state = {touched: false};
  }

  onChange(event) {
    this.setState({touched: true});

    this.props.onChange(
      event.target.name,
      event.target.value.length > 0 ? removeHtml(event.target.value) : null,
    );
  }

  onFocus = () => {
    this.setState({touched: true});
  };

  render() {
    const input = this.props;
    const hasErrors = input.errors && input.errors.length > 0;
    const {touched} = this.state;

    return (
      <>
        <label className="control-label" htmlFor={input.id}>
          {input.label || input.name}
        </label>
        <textarea
          className={`form-control ${hasErrors ? 'validationElement' : ''}`}
          rows="1"
          id={input.id}
          name={input.name}
          onBlur={this.onFocus}
          onChange={this.onChange}
          value={input.value || ''}
          autoComplete={input.autocomplete || ''}
          {...this.props.inputProps}
        ></textarea>

        {touched && hasErrors && (
          <small className="help-block validation-error">
            {input.errors.join(', ')}
          </small>
        )}
      </>
    );
  }
}

TextAreaInputField.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default TextAreaInputField;
