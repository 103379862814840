import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import {withCustomComponent} from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import {privateRoute} from 'env/privateRoute';

import CheckoutComplete from 'ui/_pages/checkout/CheckoutComplete';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import {toJS} from 'mobx';
import Link from 'next/link';
import {routes} from 'env/routes';
import BlankLayout from 'ui/layouts/BlankLayout';

let CheckoutCompletePage = props => {
  const {
    appStore,
    invoicingStore,
    contentStore,
    componentName,
  } = props;
  const {t} = useTranslation();
  const {business} = appStore;

  useEffect(() => {
    if (!invoicingStore.isLoadingInvoices && !invoicingStore.hasLoadedInvoices) {
      invoicingStore.loadInvoices();
    }

    if (!contentStore.isLoadingFaqArticles && !contentStore.hasLoadedFaqArticles) {
      contentStore.loadFaqArticles();
    }
  }, []);

  if (!(invoicingStore.hasLoadedInvoices && contentStore.hasLoadedFaqArticles)) {
    return <LoadingLayout/>;
  }

  return (
    <BlankLayout
      componentName={componentName}
      title={t('Checkout - {{Name}}', {Name: t(business.Name)})}
    >
      <main className="content content--custom pt-0" role="main">
        <div className="container">
          <CheckoutComplete/>
        </div>
      </main>
      <div className="step-buttons">
        <div className="container">
          <div className="btn-group justify-content-end">
            <Link href={routes.home}>
              <a className="btn">
                {t('Back to Dashboard')}
              </a>
            </Link>
          </div>
        </div>
      </div>
    </BlankLayout>
  );
};

export default CheckoutCompletePage = privateRoute(
  withCustomComponent('CheckoutCompletePage')(
    inject('appStore', 'invoicingStore', 'contentStore')(
      observer(CheckoutCompletePage),
    ),
  ),
);
