import React from 'react';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { LessonPageCommentsTitle } from './comments/LessonPageCommentsTitle';
import { LessonPageCommentsList } from './comments/LessonPageCommentsList';
import { LessonPageCommentsForm } from './comments/LessonPageCommentsForm';

export const LessonPageComments = withCustomComponent('LessonPageComments')(
  inject(
    'appStore',
    'coursesStore'
  )(
    withRouter(
      observer(({ componentName, router, appStore, coursesStore }) => {
        const { newComment } = coursesStore;
        const lesson = coursesStore.lessonPage.Lesson;

        if (!lesson.AllowComments) return <></>;

        return (
          <div
            data-component-name={componentName}
            className="lesson-page-comments card h-auto pl-16 pl-md-24 pr-16 pr-md-24 pb-24 pt-4 mt-24">
            <LessonPageCommentsTitle />
            <LessonPageCommentsList />
            <LessonPageCommentsForm newComment={newComment} />
          </div>
        );
      })
    )
  )
);
