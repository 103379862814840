import { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'next/router';
import { routes } from './routes';
import LoadingLayout from 'ui/layouts/LoadingLayout';

export const withCheckTerms = (WrappedComponent) => {
  return inject('appStore', 'authStore')(
      withRouter(
        observer(({ appStore, authStore, router, children }) => {
          const [termsChecked, setTermsChecked] = useState(false);

          const { me } = appStore;

          useEffect(() => {
            checkLegalStatus();
          }, [])

          const checkLegalStatus = async () => {
            const user = appStore.me ?? (await authStore.loadMe());
            if (user != null) {
              const status =
                appStore.legalStatus ?? (await appStore.loadLegalStatus());
              if (status.required)
                router.push(`${routes.legal_terms}?returnurl=${router.asPath}`);
            } else setTermsChecked(true);
          }

          if (
            me &&
            !termsChecked &&
            (!appStore.legalStatus || appStore.legalStatus.required)
          )
            return <LoadingLayout />;
            
          return <WrappedComponent>{children}</WrappedComponent>;
        })
      )
  )
};
