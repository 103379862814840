import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';
import moment from 'moment';
import { BookingFeaturesList } from 'ui/_pages/home/components/BookingsWidget/BookingFeaturesList';

export const FloorPlanBasketItem = withCustomComponent('FloorPlanBasketItem')(
  inject(
    'checkoutStore',
    'appStore',
    'authStore'
  )(
    withRouter(
      observer(
        ({ item, componentName, authStore, appStore, checkoutStore }) => {
          const { t } = useTranslation();
          const { business } = appStore;
          const { data } = item;
          const { customer } = authStore;

          useEffect(() => {
            const $ = window.$;
            if ($('[data-toggle="tooltip"]').length > 0) {
              $('[data-toggle="tooltip"]').tooltip('dispose');
              $('[data-toggle="tooltip"]').tooltip({
                html: true,
              });
            }
            return () => {};
          });

          return (
            <li
              data-component-name={componentName}
              className="cart__list__item"
            >
              <div className="media">
                <img
                  style={{ height: 48 }}
                  src={`${business.NativeHomeUrlWithLanguage}/publicresources/getimage/?id=${data.ResourceId}&w=96&h=96&cache=${data.UpdatedOn}`}
                  alt=""
                  className="cart__list__image"
                />

                <div className="media-body">
                  <header className="cart__list__header">
                    <svg
                      width="16"
                      height="16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0h15.972v16H0z" fillRule="evenodd" />
                    </svg>
                    <h3 className="cart__list__title">{data.ResourceName}</h3>
                    <a
                      href="#"
                      onClick={(ev) => {
                        ev.preventDefault();
                        checkoutStore.removeFromBasket({
                          item,
                          previewInvoice: customer != null,
                        });
                      }}
                      className="close cart__list__close"
                    >
                      &times;
                    </a>
                  </header>

                  <ul className="list-inline resources-services-list resources-services-list--xs">
                    <BookingFeaturesList data={data} />
                  </ul>

                  <div className="cart__list__dates">
                    <>
                      {moment(data.FromTime).format('ddd Do, LT')} → <br />
                      {moment(data.ToTime).format('ddd Do, LT')}
                    </>
                  </div>
                  {data.BookingPriceCurrencyCode && data.BookingPrice > 0 && (
                    <div className="cart__list__price">
                      <LocalizedPrice
                        currency={data.BookingPriceCurrencyCode}
                        amount={data.BookingPrice}
                      />
                    </div>
                  )}
                  {data.BookingPriceMessage && (
                    <>
                      <div className={'cart__list__info'}>
                        {data.BookingPriceMessage}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </li>
          );
        }
      )
    )
  )
);

export default FloorPlanBasketItem;
