import React, { useEffect, useState, useCallback } from "react";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import withCustomComponent from "ui/components/withCustomComponent";
import FloorPlanCanvas from "ui/_pages/bookings/floorplan/FloorPlanCanvas";
import { FloorPlanItemFilters } from "ui/_pages/bookings/floorplan/FloorPlanItemFilters";
import { NoAccessNotice } from "ui/components/NoAccessNotice";
import { reaction } from "mobx";
import moment from "moment";
import ArchilogicFloorPlanCanvas from "ui/_pages/bookings/floorplan/ArchilogicFloorPlanCanvas";
import { FloorPlanBackdrop } from "ui/_pages/bookings/floorplan/FloorPlanBackdrop";
import { FloorPlanTypeFilters } from "ui/_pages/bookings/floorplan/FloorPlanTypeFilters";

const PassportFloorPlans = withCustomComponent("PassportFloorPlans")(
  inject(
    "floorPlanStore",
    "checkoutStore"
  )(
    observer(({ floorPlanStore, checkoutStore }) => {
      const [error, setError] = useState(null);
      const { showTypeFilters, currentFloorPlan } = floorPlanStore;
      const { t } = useTranslation();
      const listenerCallback = useCallback((ev) => {
        try {
          let action = ev.data;
          /*document.getElementById(
                'newStuff'
              ).innerHTML += `</br>${JSON.stringify(ev.data)}`;*/
          if (action.type) {
            switch (action.type) {
              case INITIAL_DATA:
                loadFloorPlan(action.data.selectedFloorPlan.id);
                selectDates(action.data);
                break;
              case SELECT_DATES:
                selectDates(action.data);
                break;
              default:
                return;
            }
          }
        } catch (err) {
          document.getElementById("newStuff").innerHTML += `</br>${err}`;
          sendToPassport(ERROR, err);
        }
      }, []);

      useEffect(() => {
        window.addEventListener("message", listenerCallback);
        floorPlanStore
          .loadItems()
          .catch((err) => {
            setError(t("We could not load the floor plans at this time."));
            sendToPassport(
              ERROR,
              t("We could not load the floor plans at this time.")
            );
          })
          .then(() => {
            sendToPassport(READY_TO_RECEIVE);
          });

        reaction(
          () => checkoutStore.basket.length,
          () => {
            if (checkoutStore.basket[0]) {
              sendToPassport(
                FLOOR_PLAN_ITEM_SELECTED,
                checkoutStore.basket[0].data
              );
              checkoutStore.clearBasket();
              document
                .querySelector("#openseadragon")
                .classList.remove("popup-open");
              document
                .querySelector("body")
                .classList.remove("seadragon-popup-open");
            }
          }
        );

        return () => {
          window.removeEventListener("message", listenerCallback);
        };
      }, []);

      useEffect(() => {
        resizeCanvas();
      }, [currentFloorPlan]);

      /*const selectShift = (shiftId) => {
            floorPlanStore.selectShift(shiftId);
          };*/

      const selectDates = (data) => {
        let dates = {
          fromTime: moment(data.start).toDate(),
          toTime: moment(data.end).toDate(),
        };
        floorPlanStore.selectDates(dates);
        floorPlanStore.loadItems().catch((err) => {
          setError(t("We could not load the floor plans at this time."));
        });
      };

      // WebView to Passport
      const selectFloorPlan = (floorPlan) => {
        sendToPassport(FLOOR_PLAN_SELECTED, {
          id: floorPlan.Id,
          name: floorPlan.Name,
        });
        floorPlanStore.selectFloorPlan(floorPlan);
      };

      // Passport to WebView
      const loadFloorPlan = (id) => {
        var foundPlan = floorPlanStore.floorPlans.find(
          (fp) => fp.floorPlan.Id == id
        );
        if (foundPlan) floorPlanStore.selectFloorPlan(foundPlan?.floorPlan);
        else floorPlanStore.selectFloorPlan(null);
      };

      const resizeCanvas = () => {
        let canvas = document.querySelector(".floorplans__map");
        if (canvas) {
          canvas.style.height = "90vh";
          canvas.style.width = "100vw";
        }
      };

      return (
        <div className="container">
          <div id="newStuff"></div>
          <div>
            {/*<FloorPlanItemFilters selectFloorPlan={selectFloorPlan} />*/}
            {showTypeFilters && (
              <FloorPlanBackdrop>
                <FloorPlanTypeFilters />
              </FloorPlanBackdrop>
            )}
            {currentFloorPlan && (
              <div className="col-xs-12">
                <NoAccessNotice error={error} />
                {!currentFloorPlan.ArchilogicUniqueId && (
                  <div className="floorplans">
                    <FloorPlanCanvas
                      instantAddToBasket
                      isMobilePage
                      selectFloorPlan={selectFloorPlan}
                    />
                  </div>
                )}
                {currentFloorPlan.ArchilogicUniqueId && (
                  <ArchilogicFloorPlanCanvas
                    instantAddToBasket
                    isMobilePage
                    selectFloorPlan={selectFloorPlan}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      );
    })
  )
);

export default PassportFloorPlans;

const sendToPassport = (type, data) => {
  const message = JSON.stringify({
    type,
    data,
  });
  window.ReactNativeWebView?.postMessage(message);
};

const ERROR = "error";
const READY_TO_RECEIVE = "ready_to_receive";
const INITIAL_DATA = "initial_data";
const SELECT_DATES = "select_dates";
const FLOOR_PLAN_SELECTED = "floor_plan_selected";
const FLOOR_PLAN_ITEM_SELECTED = "floor_plan_item_selected";
