import React from 'react';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

import { BlogPostCommentsTitle } from 'ui/_pages/blog/comments/BlogPostCommentsTitle';
import { BlogPostCommentsList } from 'ui/_pages/blog/comments/BlogPostCommentsList';
import { BlogPostCommentsForm } from 'ui/_pages/blog/comments/BlogPostCommentsForm';

export const BlogPostComments = withCustomComponent('BlogPostComments')(
  inject(
    'appStore',
    'blogStore'
  )(
    withRouter(
      observer(({ componentName, router, appStore, blogStore }) => {
        const { newComment } = blogStore;
        const blogPost = blogStore.blogPostPage.BlogPost;

        if (!blogPost.AllowComments) return <></>;

        return (
          blogPost.Comments && (
            <div
              data-component-name={componentName}
              className="blog-post-comments card h-auto pl-16 pl-md-24 pr-16 pr-md-24 pb-24 pt-4 mt-24">
              <BlogPostCommentsTitle />
              <BlogPostCommentsList />
              <BlogPostCommentsForm newComment={newComment} />
            </div>
          )
        );
      })
    )
  )
);
