/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import AuthenticatedLink from 'ui/components/AuthenticatedLink';
import withCustomComponent from 'ui/components/withCustomComponent';
import {withRouter} from 'next/router';
import i18n from '../../../env/i18n';

let DownloadInvoiceButton = props => {
  const {
    t,
    componentName,
    appStore,
    filesStore,
    qrPaymentStore,
    invoice,
    invoiceFileId,
  } = props;
  let {
    text,
    customerLanguage = i18n.language.toLowerCase() ?? 'de',
  } = props;
  const {business, configuration} = appStore;
  let invoiceQRFile = qrPaymentStore.getInvoiceFile(invoice);
  let fileId = invoiceFileId ? invoiceFileId : invoiceQRFile.file_id;
  customerLanguage = customerLanguage.split('-').shift();

  return <span data-component-name={componentName}>
    {configuration['Members.PrintInvoices'] && filesStore.hasLoadedMyFiles && <>
      {fileId ? <a
          href="#"
          className="btn btn-gray-light"
          onClick={async (e) => {
            e.preventDefault();

            let blob;
            try {
              blob = await filesStore.loadFile(fileId);
            } catch (err) {
              return appStore.setPopMessage(
                t('Sorry, we could not download this file. {{err}}', {err}),
              );
            }

            if (!blob) {
              return appStore.setPopMessage(
                t('Sorry, we could not download this file.'),
              );
            }

            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(
              new Blob([blob], {type: blob.type}),
            );
            a.download = invoice.InvoiceNumber;
            a.target = '_blank';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
          }}
        >
          <i className="icon-downloads fs-20 mr-4 tdn"></i>
          <span>{text}</span>
        </a>
        : <AuthenticatedLink
          className="btn btn-gray-light"
          href={`${business.NativeHomeUrl}/${customerLanguage}/invoices/print?guid=${invoice.UniqueId}`}
          target="_blank"
        >
          <i className="icon-downloads fs-20 mr-4 tdn"></i>
          <span>{text}</span>
        </AuthenticatedLink>
      }
    </>}
  </span>;
};

export default DownloadInvoiceButton = withCustomComponent('DownloadInvoiceButton')(
  withRouter(
    withTranslation()(
      inject('authStore', 'appStore', 'invoicingStore', 'filesStore', 'qrPaymentStore')(
        observer(DownloadInvoiceButton),
      ),
    ),
  ),
);
