import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withTranslation, Trans } from "react-i18next";
import { withCustomComponent } from "ui/components/withCustomComponent";
import SignupPageLayout from "ui/layouts/SignupPageLayout";
import { SignupForm } from "ui/_pages/signup/SignupForm";
import { withRouter } from "next/router";
import LoadingLayout from "ui/layouts/LoadingLayout";
import { routes } from "env/routes";
import qs from "query-string";
@withRouter
@withCustomComponent("SignupPageOld")
@withTranslation()
@inject("appStore", "authStore")
@observer
class SignupPageOld extends Component {
  componentDidMount() {
    const { router, t, authStore, appStore } = this.props;
    const { configuration } = appStore;
    const { isLoggedIn } = authStore;
    const query = qs.parse(location.search);
    const signupToken = query.t;
    const { tariff_guid, team_guid } = query;

    if (isLoggedIn) {
      authStore.loadCustomFields();
    } else {
      authStore.loadSignupCustomFields({ tariff_guid, team_guid, signupToken });
      if (!configuration["Members.CanSignup"]) {
        if (!router.query.returnurl) {
          router.push(routes.contact);
          return;
        }
      }

      authStore.createCustomer({
        tourDate: null,
        business: appStore.business,
        newsletter: configuration["Newsletter.DefaultOptIn"] == "true",
        createPassword: !configuration["Signup.AskForPassword"],
      });
    }

    authStore
    .loadSignupPage({
      t: signupToken,
      tariff_guid,
      team_guid,
    })
    .then((data) => {
      if (!isLoggedIn && !router.query.returnurl) {
        if (data.RedirectTo?.indexOf("missingInvite") > -1)
          router.push(routes.contact);
      }
    })
    .catch((err) => {
      appStore.setPopMessage(
          t("Sorry, we could not load this page. {{err}}", { err })
      );
    });
  }

  render() {
    const { router, appStore, authStore } = this.props;
    const { customer, hasLoadedSignupPage } = authStore;
    if (!customer || !hasLoadedSignupPage) return <LoadingLayout />;
    return (
        <SignupPageLayout componentName={this.props.componentName}>
          <SignupForm />
        </SignupPageLayout>
    );
  }
}

export default SignupPageOld;