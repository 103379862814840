import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import CreditDetails from 'ui/_pages/plan/CreditDetails';
import {withTranslation} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import {LocalizedPrice} from 'env/utils/NumbersLocalization';

@withCustomComponent('AllowancesSection')
@withTranslation()
@inject('authStore', 'appStore', 'invoicingStore')
@observer
class AllowancesSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    const {t, invoicingStore} = this.props;
    if (!invoicingStore.hasLoadedBenefits) {
      invoicingStore.loadBenefits().catch((ex) => {
        this.setState({
          error: t('Could not load your benefits.'),
        });
      });
    }
  }

  render() {
    const {t, invoicingStore, appStore, componentName} = this.props;
    const {configuration, business} = appStore;
    const {benefits, hasBenefits} = invoicingStore;

    if (!benefits) return <></>;

    const balance = benefits.CurrentBalance;
    return (
      <fieldset data-component-name={componentName} id="allowances">
        <div className="card-item-wrapper">
          <div className="card-item-header">
            <h5 className="card-item-header-title">
              {t('Benefits')}
            </h5>
          </div>
          <div className="card-item-body">
            {configuration['Bookings.ChargeBookingsImmediately'] && (
              <p className="mxw-600px fs-14 text-gray-700 lh-14 mb-20">
                {t(
                  'Bookings will be charged to your account as you request them. Any credit available at the time of requesting the booking will be used to pay for it.'
                )}
              </p>
            )}
            {!configuration['Bookings.ChargeBookingsImmediately'] && (
              <p className="mxw-600px fs-14 text-gray-700 lh-14 mb-20">
                {t(
                  'Bookings will be charged to your account as they end. Any credit available at the time the booking ends will be used to pay for it.'
                )}
              </p>
            )}

            {(hasBenefits || balance > 0) && (
              <div className="table-responsive">
                <table className="table table--settings js-table-settings">
                  <thead>
                  <tr>
                    <th>{t('Item')}</th>
                    <th>{t('Quantity')}</th>
                    <th>{t('Remaining')}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {balance > 0 && (
                    <tr className="tr-parent js-table-settings-tr-parent 123">
                      <th>
                        <div className="table--settings__handler">
                          <a className="table--settings__title">
                            <span className="icon-add caret"></span>
                            {t('Credit on account')}
                          </a>
                          <small>
                            {t(
                              'This credit will automatically be applied to your next invoice at checkout.'
                            )}
                          </small>
                        </div>
                      </th>
                      <td>-</td>
                      <td>
                        <LocalizedPrice
                          currency={business.Currency.Code}
                          amount={balance}
                        />
                      </td>
                    </tr>
                  )}
                  {benefits.TimePasses.map((b, i) => (
                    <CreditDetails key={i} group={b}/>
                  ))}
                  {benefits.BookingCredits.map((b, i) => (
                    <CreditDetails key={i} group={b}/>
                  ))}
                  {benefits.ExtraServices.map((b, i) => (
                    <CreditDetails key={i} group={b}/>
                  ))}
                  </tbody>
                </table>
              </div>
            )}

            {!hasBenefits && (
              <div className="alert alert-info fs-14" role="alert">
                <b>
                  {t(
                    'You are not signed up to a membership that includes benefits.'
                  )}
                </b>
              </div>
            )}
          </div>
        </div>
      </fieldset>
    );
  }
}

export default AllowancesSection;
