import React from 'react';
import moment from 'moment';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import ContractDetails from 'ui/_pages/plan/ContractDetails';
import {useTranslation} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';

export const ChangeContractHeader = withCustomComponent('ChangeContractHeader')(
  ({componentName, contract}) => {
    const {t} = useTranslation();
    return (
      <>
        <h4>{t('You are about to change this plan:')}</h4>
        <div data-component-name={componentName}>
          {contract ? (
            <>
              <div className="card-item-wrapper">
                <ContractDetails contract={contract} basic/>
              </div>
              <p className="mt-12 fs-14 text-gray-700">
                {t(
                  'If you change your plan now, the new plan will take effect on {{RenewalDate}}',
                  {
                    RenewalDate: moment(contract.RenewalDate).format('L'),
                  }
                )}
              </p>
            </>
          ) : (
            <LoadingSpinner/>
          )}
        </div>
      </>
    );
  }
);
