import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import DefaultLayout from 'ui/layouts/DefaultLayout';

import { RotateScreenAlert } from 'ui/_pages/bookings/calendar/RotateScreenAlert';
import { CalendarHeader } from 'ui/_pages/events/calendar/CalendarHeader';
import LocationsMenu from 'ui/components/LocationsMenu/LocationsMenu';
import { withRouter } from 'next/router';
import { withCustomComponent } from 'ui/components/withCustomComponent';
import { checkTerms } from 'env/checkTerms';
import { isServer } from 'env/ssr/ServerSideRenderManager';

let FullCalendarWrapper = '';

@checkTerms
@withCustomComponent('BookingCalendarPage')
@withRouter
@withTranslation()
@inject('appStore', 'authStore', 'eventsStore')
@observer
class EventsCalendarPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCalendar: false,
    };
  }

  async componentDidMount() {
    //FC doesn't play nicely with SSR so we do a dynamic import
    if (!isServer()) {
      let result = await import('ui/_pages/events/calendar/FullCalendar');
      FullCalendarWrapper = result.FullCalendarWrapperEvents;
    }

    //Full calendar and react... 👻
    setTimeout(() => {
      this.setState({ showCalendar: true });
    }, 500);

    if (window.addEventListener) {
      window.addEventListener('orientationchange', function () {
        location.reload();
      });
    }
  }

  render() {
    const { t, appStore, bookingsStore } = this.props;
    const { business } = appStore;

    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={t('Booking Calendar - {{Name}}', { Name: t(business.Name) })}
      >
        <main className="content content--custom" role="main">
          <div className="container">
            <RotateScreenAlert />
            <CalendarHeader newBooking={this.newBooking} />
            <div id="bookings-calendar">
              {!isServer() && this.state.showCalendar && (
                <FullCalendarWrapper />
              )}
            </div>
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default EventsCalendarPage;
