import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import usePrevious from 'env/usePrevious';
import withCustomComponent from 'ui/components/withCustomComponent';
import { withCheckTerms } from 'env/withCheckTerms';
import FloorPlanCanvas from 'ui/_pages/bookings/floorplan/FloorPlanCanvas';
import { FloorPlanItemFilters } from 'ui/_pages/bookings/floorplan/FloorPlanItemFilters';
import { NoAccessNotice } from 'ui/components/NoAccessNotice';
import { routes } from 'env/routes';
import { withRouter } from 'next/router';
import { toJS, reaction } from 'mobx';
import moment from 'moment';
import ArchilogicFloorPlanCanvas from 'ui/_pages/bookings/floorplan/ArchilogicFloorPlanCanvas';
import { FloorPlanBackdrop } from 'ui/_pages/bookings/floorplan/FloorPlanBackdrop';
import { FloorPlanTypeFilters } from 'ui/_pages/bookings/floorplan/FloorPlanTypeFilters';

const FloorPlanMobile = withCustomComponent('FloorPlanMobile')(
  withCheckTerms(
    inject(
      'appStore',
      'authStore',
      'floorPlanStore',
      'checkoutStore'
    )(
      withRouter(
        observer(
          ({
            appStore,
            floorPlanStore,
            checkoutStore,
            router
          }) => {
            const [error, setError] = useState(null);
            const prevQuery = usePrevious(router.query);
            const { t } = useTranslation();

            const { showTypeFilters, currentFloorPlan } = floorPlanStore;

            useEffect(() => {
              selectDates();
              floorPlanStore
                .loadItems()
                .catch((err) => {
                  setError(t('We could not load the floor plans at this time.'));
                })
                .then(() => {
                  loadFloorPlan();
                  resizeCanvas();
                });
          
              reaction(
                () => checkoutStore.basket.length,
                () => {
                  if (checkoutStore.basket[0]) {
                    window.ReactNativeWebView?.postMessage(
                      JSON.stringify(toJS(checkoutStore.basket[0]))
                    );
                    checkoutStore.clearBasket();
                  }
                }
              );

              return () => floorPlanStore.selectFloorPlan(null);
            }, []);

            useEffect(() => {
              const currentQuery = router.query;
              if (JSON.stringify(currentQuery) != JSON.stringify(prevQuery)) {
                loadFloorPlan();
              }
            }, [router.query]);

            const selectShift = (shiftId) => {
              floorPlanStore.selectShift(shiftId);
            };
          
            const selectDates = () => {
              let dates = {
                fromTime: moment(router.query.fromTime).toDate(),
                toTime: moment(router.query.toTime).toDate(),
              };
              floorPlanStore.selectDates(dates);
            };
          
            const selectFloorPlan = (floorPlan) => {
              window.ReactNativeWebView?.postMessage(
                JSON.stringify({ type: 'SELECT_FLOOR_PLAN', floorPlan })
              );
              router.push(routes.floorplan_mobile(floorPlan, router.query));
            };
          
            const loadFloorPlan = () => {
              var foundPlan = floorPlanStore.floorPlans.find(
                (fp) => fp.floorPlan.Id == router.query.floorplan_id
              );
              if (foundPlan) floorPlanStore.selectFloorPlan(foundPlan?.floorPlan);
              else floorPlanStore.selectFloorPlan(null);
            }
          
            const resizeCanvas = () => {
              let canvas = document.querySelector('.floorplans__map');
              if (canvas) {
                canvas.style.height = '80vh';
                canvas.style.width = '100vw';
              }
            }

            return (
              <div className='container'>
                {
                  <div>
                    <FloorPlanItemFilters selectFloorPlan={selectFloorPlan} />
                    {showTypeFilters && (
                      <FloorPlanBackdrop>
                        <FloorPlanTypeFilters />
                      </FloorPlanBackdrop>
                    )}
                    {currentFloorPlan && (
                      <div className='row'>
                        <div className='col-xs-12'>
                          <NoAccessNotice error={error} />
                          {!currentFloorPlan.ArchilogicUniqueId && (
                            <div className='floorplans'>
                              <FloorPlanCanvas
                                instantAddToBasket
                                isMobilePage
                                selectFloorPlan={selectFloorPlan}
                              />
                            </div>
                          )}
                          {currentFloorPlan.ArchilogicUniqueId && (
                            <ArchilogicFloorPlanCanvas
                              instantAddToBasket
                              isMobilePage
                              selectFloorPlan={selectFloorPlan}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                }
              </div>
            );

          })
      )
    )
  )
);

export default FloorPlanMobile;
