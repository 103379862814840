import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import moment from 'moment';

import ResponsiveTable from 'ui/components/tables/ResponsiveTable';
import { privateRoute } from 'env/privateRoute';

@privateRoute
@withCustomComponent('CapacityPage')
@withTranslation()
@inject('appStore', 'communityStore')
@observer
class CapacityPage extends Component {
  componentDidMount() {
    const { communityStore } = this.props;
    communityStore.loadCheckedInMetrics().catch(() => null);
  }

  render() {
    const { t, appStore, communityStore } = this.props;
    const { business } = appStore;
    const { checkedInMetrics } = communityStore;
    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={t('Available space') + ' - ' + t(business.Name)}
      >
        <main className="content content--custom capacity" role="main">
          <div className="container">
            <ResponsiveTable>
              <thead>
                <tr>
                  <th>{t('Location')}</th>
                  <th>{t('Availability')}</th>
                  <th>{t('From your team')}</th>
                  <th>{t('Status')}</th>
                </tr>
              </thead>
              <tbody>
                {checkedInMetrics.network
                  ?.filter((b) => b.capacity > 0)
                  .map((metric, i) => {
                    let availableCount =
                      metric.capacity -
                      (metric.counted > 0 ? metric.counted : metric.checkedIn);
                    if (availableCount <= 0) availableCount = 0;

                    const status =
                      availableCount == 0
                        ? 'full'
                        : availableCount < 5
                        ? 'busy'
                        : 'available';
                    const color =
                      status == 'full'
                        ? '#fccccc'
                        : status == 'busy'
                        ? '#fce3cc'
                        : '#F0FBF5';

                    const teamCount = _.sumBy(
                      Object.keys(metric.byTeam),
                      (m) => metric.byTeam[m]
                    );
                    return (
                      <tr
                        key={i}
                        style={{
                          backgroundColor: color,
                        }}
                      >
                        <td>
                          <strong>{t(metric.business.Name)}</strong>
                          <br />
                          {metric.business.Address}
                        </td>
                        <td>
                          <strong>
                            {t('{{count}} space', { count: availableCount })}
                          </strong>
                        </td>
                        <td>
                          <strong>
                            {t('{{count}} colleague', { count: teamCount })}
                          </strong>
                        </td>
                        <td>
                          <strong>
                            {status == 'available' && t('Plenty of space')}
                            {status == 'busy' && t('A bit busy')}
                            {status == 'full' && t('Full')}
                          </strong>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </ResponsiveTable>
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default CapacityPage;
