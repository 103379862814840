import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import AuthenticatedLink from 'ui/components/AuthenticatedLink';

export const AppleCalendarIntegrationSection = withCustomComponent(
  'AppleCalendarIntegrationSection'
)(
  inject(
    'appStore',
    'authStore'
  )(
    withRouter(
      observer(({ authStore, appStore, componentName }) => {
        const { t } = useTranslation();
        const { business } = appStore;
        const { customer } = authStore;
        return (
          <tr>
            <td className="py-16" valign="middle">
              <img src="/img/apple_calendar.png" />
            </td>
            <td className="w-100 pl-0 py-16" valign="middle">
              <h6 className="mb-4">{t('Apple Calendar')}</h6>
              <p className="text-gray-700 fs-14 lh-14 mxw-500px mb-0">
                {t('Manage bookings directly from your Apple Calendar.')}
              </p>
            </td>
            <td className="text-left text-md-right py-16" valign="middle">
              {customer?.HasAppleCalendar && (
                <AuthenticatedLink
                  href={`${business.NativeHomeUrlWithLanguage}/integrations/apple/signout`}
                  className="btn btn-icon btn-gray-light "
                >
                  <i className="icon-disconnect"></i>
                  {t('Disconnect')}
                </AuthenticatedLink>
              )}
              {customer?.HasAppleCalendar === false && (
                <AuthenticatedLink
                  href={`${business.NativeHomeUrlWithLanguage}/integrations/apple/signin`}
                  className="btn  mw-250px"
                >
                  {t('Connect to Apple Calendar')}
                </AuthenticatedLink>
              )}
            </td>
          </tr>
        );
      })
    )
  )
);
