import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('SuccessActionMark')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class SuccessActionMark extends Component {
  render() {
    const { t, appStore } = this.props;
    const { business } = appStore;
    return (
      <div className="mx-auto text-center">
        <svg
          width="131"
          height="131"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 130.2 130.2"
        >
          <circle
            class="path circle"
            fill="none"
            stroke="#73AF55"
            stroke-width="6"
            stroke-miterlimit="10"
            cx="65.1"
            cy="65.1"
            r="62.1"
          />
          <polyline
            class="path check"
            fill="none"
            stroke="#73AF55"
            stroke-width="6"
            stroke-linecap="round"
            stroke-miterlimit="10"
            points="100.2,40.2 51.5,88.8 29.8,67.5 "
          />
        </svg>
        <h3 class="mt-32">{this.props.title}</h3>
      </div>
    );
  }
}

export default SuccessActionMark;
