import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { getUrlDomain } from 'env/utils/strings';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import ResponsiveTable from 'ui/components/tables/ResponsiveTable';

export const CalendarSynSection = withCustomComponent('CalendarSynSection')(
  inject(
    'appStore',
    'bookingsStore'
  )(
    withRouter(
      observer(({ router, appStore, bookingsStore, componentName }) => {
        const { t } = useTranslation();
        const { business } = appStore;

        const allBookingsIcalFeed = `webcal://${getUrlDomain(
          business.NativeHomeUrlWithLanguage
        )}/en/feeds/bookings`;

        const allBookingsMacInstruction = (url) => `
            <h4 class="text-left mb-32">${t('Apple Calendar')}</h4>
            <ol class="fs-18 text-left">
              <li>
                ${t('Copy the link below.')}<br/>
                <input onClick="this.setSelectionRange(0, this.value.length)"  style="width: 100%; font-size: 0.8em" value="${url}"/>
              </li>
              <li class="pt-16">
              ${t('In Apple Calendar, go to File > New Calendar Subscription')}
              </li>
              <li class="pt-16">
              ${t(
                'Paste the link above into the Calendar URL field and select Subscribe.'
              )}
              </li>
            </ol>
          `;

        const allBookingsOutlookInstruction = (url) => `
            <h4 class="text-left mb-32">${t('Outlook')}</h4>
            <ol class="fs-18 text-left">
              <li>${t('Copy the link below.')}<br/>
                <input onClick="this.setSelectionRange(0, this.value.length)"  style="width: 100%; font-size: 0.8em" value="${url}"/>
              </li>
              <li class="pt-16">
              ${t(
                'In Outlook, right click on the My Calendars dropdown in the calendar view. Select Add Calendar > From Internet.'
              )}
              </li>
              <li class="pt-16">
              ${t(
                'Paste the link above into the Calendar URL field and select OK.'
              )}
              </li>
            </ol>
          `;

        return (
          <div className="card card-dashboard mb-32" id="calendar-sync-section">
            <fieldset data-component-name={componentName}>
              <h5 className="section__title mb-8">{t('Calendar Sync')}</h5>
              <p className="mxw-600px text-gray-700 fs-14 mb-20">
                {t(
                  'Calendar Sync lets you see bookings for the different rooms in your calendar of choice. If you would also like to place new bookings directly in Outlook or Google Calendar, you can use the integration options below.'
                )}
              </p>

              <ResponsiveTable className="table--sm--no-thead">
                <tbody>
                  <tr>
                    <td className="py-16" valign="middle">
                      <img src="/img/google_calendar.png" />
                    </td>
                    <td className="w-100 pl-0 py-16" valign="middle">
                      <h6 className="mb-4">{t('Google Calendar')}</h6>
                      <p className="text-gray-700 fs-14 lh-14 mxw-400px mb-0">
                        {t(
                          'Connecting to your Google account will enable you to make and manage bookings from your own Google Calendar.'
                        )}
                      </p>
                    </td>
                    <td
                      className="text-left text-md-right py-16"
                      valign="middle"
                    >
                      <a
                        className="btn  mw-250px"
                        target="_blank"
                        href={`https://calendar.google.com/calendar/u/0/r?cid=${allBookingsIcalFeed}`}
                      >
                        {t('Connect to Google Calendar')}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="py-16" valign="middle">
                      <img src="/img/apple_calendar.png" />
                    </td>
                    <td className="w-100 pl-0 py-16" valign="middle">
                      <h6 className="mb-4">{t('Apple Calendar')}</h6>
                      <p className="text-gray-700 fs-14 lh-14 mxw-400px mb-0">
                        {t(
                          'Manage bookings directly from your Apple Calendar.'
                        )}
                      </p>
                    </td>
                    <td
                      className="text-left text-md-right py-16"
                      valign="middle"
                    >
                      <a
                        className="btn  mw-250px"
                        onClick={(ev) => {
                          ev.preventDefault();
                          appStore.setPopMessage(
                            allBookingsMacInstruction(allBookingsIcalFeed)
                          );
                        }}
                        href="#"
                      >
                        {t('Connect to Apple Calendar')}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="py-16" valign="middle">
                      <img src="/img/outlook.png" />
                    </td>
                    <td className="w-100 pl-0 py-16" valign="middle">
                      <h6 className="mb-4">{t('Microsoft Outlook')}</h6>
                      <p className="text-gray-700 fs-14 lh-14 mxw-400px mb-0">
                        {t(
                          'Manage bookings directly from your Outlook Calendar.'
                        )}
                      </p>
                    </td>
                    <td
                      className="text-left text-md-right py-16"
                      valign="middle"
                    >
                      <a
                        className="btn  mw-250px"
                        onClick={(ev) => {
                          ev.preventDefault();
                          appStore.setPopMessage(
                            allBookingsOutlookInstruction(allBookingsIcalFeed)
                          );
                        }}
                        href="#"
                      >
                        {t('Connect to Outlook 365')}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </ResponsiveTable>

              <ResponsiveTable className="table--sm--no-thead">
                <thead>
                  <tr>
                    <th>{t('Resource')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="nowrap">
                  {bookingsStore.resources
                    .sort((a, b) => a.Name.localeCompare(b.Name))
                    .map((resource, i) => {
                      const feedUrl = `webcal://${getUrlDomain(
                        business.NativeHomeUrlWithLanguage
                      )}/en/feeds/resourceBookings?guid=${resource.UniqueId}`;
                      return (
                        <tr key={i}>
                          <td valign="middle">{resource.Name}</td>
                          <td
                            valign="middle"
                            className="text-left text-md-right"
                          >
                            <div className="btn-group d-inline-flex">
                              <a
                                className="btn btn-icon btn-outline-gray "
                                target="_blank"
                                href={`https://calendar.google.com/calendar/u/0/r?cid=${feedUrl}`}
                              >
                                <i className="fa fa-google fs-18"></i>
                                {t('Google')}
                              </a>
                              <a
                                className="btn btn-icon btn-outline-gray "
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  appStore.setPopMessage(
                                    allBookingsMacInstruction(feedUrl),
                                    'modal-xl'
                                  );
                                }}
                                href="#"
                              >
                                <i className="fa fa-apple fs-18"></i>
                                {t('Apple')}
                              </a>
                              <a
                                className="btn btn-icon btn-outline-gray "
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  appStore.setPopMessage(
                                    allBookingsOutlookInstruction(feedUrl),
                                    'modal-xl'
                                  );
                                }}
                                href="#"
                              >
                                <i className="fa fa-windows fs-18"></i>
                                {t('Outlook')}
                              </a>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </ResponsiveTable>
            </fieldset>
          </div>
        );
      })
    )
  )
);
