import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import { NewPrivateMessageForm } from './NewPrivateMessageForm';

export const showNewPrivateMessageModal = (memberId) => {
  const $ = window.$;
  $(`#new-private-message-modal-for-${memberId}`).modal({
    backdrop: 'static',
    keyboard: false,
    show: true,
  });
};

export const hideNewPrivateMessageModal = (memberId) => {
  const $ = window.$;
  $(`#new-private-message-modal-for-${memberId}`).modal('hide');
};

@withCustomComponent('NewPrivateMessage')
@withTranslation()
@inject('communityStore')
@observer
class NewPrivateMessage extends Component {
  render() {
    const { t, member, communityStore } = this.props;
    const { newCommunityBoardMessage } = communityStore;
    return (
      <div
        id={`new-private-message-modal-for-${member.Id}`}
        className="modal fade"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <button
              className="close"
              onClick={() => hideNewPrivateMessageModal(member.Id)}
            >
              <i className="icon-close"></i>
            </button>
            <div className="modal-body py-40">
              <NewPrivateMessageForm
                member={member}
                newCommunityBoardMessage={newCommunityBoardMessage}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewPrivateMessage;
