import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import CustomerProfileForm from 'ui/_pages/profile/CustomerProfileForm';
import {privateRoute} from 'env/privateRoute';
import {withCustomComponent} from 'ui/components/withCustomComponent';
import {checkTerms} from 'env/checkTerms';
import Head from 'next/head';

@checkTerms
@withCustomComponent('ProfilePage')
@privateRoute
@withTranslation()
@inject('authStore', 'appStore')
@observer
class ProfilePage extends Component {
  render() {
    const {t} = this.props;
    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={`${t('My Account')} - ${t(this.props.appStore.business.Name)}`}
      >
        <main className="content content--custom pt-0" role="main">
          <div className="portal-title-wrapper">
            <div className="container portal-title-container">
              <h1 className="portal-title">
                {t('Team')}
              </h1>
            </div>
          </div>
          <div className="container">
            <div className="settings__content">
              <CustomerProfileForm/>
            </div>
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default ProfilePage;
