import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import FullPageLayout from 'ui/layouts/FullPageLayout';
import SuccessActionMark from 'ui/components/actionMarks/SuccessActionMark';
import ErrorActionMark from 'ui/components/actionMarks/ErrorActionMark';
import { withRouter } from 'next/router';
import { privateRoute } from 'env/privateRoute';
import LoadingSpinner from 'ui/components/LoadingSpinner';

@privateRoute
@withCustomComponent('TilePage')
@withTranslation()
@withRouter
@inject('appStore', 'authStore')
@observer
class TilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      success: false,
      title: null,
    };
  }

  componentDidMount() {
    const { appStore, router } = this.props;
    this.setState({ error: false, success: false });
    appStore
      .loadTileAction(router.query.tile_id)
      .then((data) => {
        const res = data.result;
        if (res.RedirectURL) {
          window.location = res.RedirectURL;
        }

        this.setState({
          title: data.tileName,
          error: !res.WasSuccessful,
          success: res.WasSuccessful,
          message: res.Message,
        });
      })
      .catch(() => {
        this.setState({ error: true });
      });
  }

  render() {
    const { t, appStore } = this.props;
    const { business, isLoadingTileAction } = appStore;
    return (
      <FullPageLayout
        componentName={this.props.componentName}
        title={
          this.state.title ??
          (isLoadingTileAction ? t('Please wait...') : t(business.Name))
        }
      >
        {isLoadingTileAction && <LoadingSpinner />}
        {this.state.success && <SuccessActionMark title={t('Success!')} />}
        {this.state.error && (
          <ErrorActionMark
            message={
              this.state.message
                ? t(this.state.message)
                : t('Sorry, this tile does not seem to work')
            }
          />
        )}
      </FullPageLayout>
    );
  }
}

export default TilePage;
