/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import { platformRoot } from 'env/config';
@withCustomComponent('MidtransPaymentButton')
@withTranslation()
@inject('appStore', 'authStore')
class MidtransPaymentButton extends Component {
  processPayment = () => {
    const { appStore, invoice } = this.props;
    const { business } = appStore;
    var token = invoice.CustomData.replace('vt_', '');
    var invoiceId = invoice.Id;
    snap.pay(token, {
      onSuccess: function () {
        window.location = `${platformRoot}/callbacks/paycoworkerinvoice?result=OK&provider=MidTrans&invoiceownerid=${invoice.CoworkerId}&invoiceId=${invoice.Id}`;
      },
      onClose: function () {
        window.location = `${platformRoot}/callbacks/paycoworkerinvoice?result=FAIL&provider=MidTrans&invoiceownerid=${invoice.CoworkerId}&invoiceId=${invoice.Id}`;
        invoiceId;
      },
    });
  };
  render() {
    const { t, appStore, componentName } = this.props;

    return (
      <a
        href="#"
        data-component-name={componentName}
        className="btn btn-icon btn-link text-gray-900"
        onClick={() => this.processPayment()}
      >
        <i aria-hidden="true" className="icon-credit-card"></i> {t('Pay')}
      </a>
    );
  }
}
export default MidtransPaymentButton;
