/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component, forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import AuthenticatedLink from 'ui/components/AuthenticatedLink';
import withCustomComponent from 'ui/components/withCustomComponent';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import _ from 'lodash';
import filesStore from 'env/stores/filesStore';
import {toJS} from 'mobx';
import moment from 'moment';
import crypto from 'crypto';
import i18n from '../../../../env/i18n';
import LoadingLayout from '../../../layouts/LoadingLayout';
import DownloadInvoiceButton from '../DownloadInvoiceButton';

let moduleI = 0;

let HostedPaymentProviderButtonComponent = props => {
  const {
    providerIndex,
    invoice,
    appStore,
    filesStore,
    authStore,
    componentName,
    qrPaymentStore,
    showQrCode = 'modal',
    showOnLoad,
    showDownloadInvoiceBtn = false,
  } = props;
  let {
    customerLanguage = i18n.language.toLowerCase() ?? 'de',
  } = props;
  const {business, configuration} = appStore;
  const [swissQRCode, setSwissQRCode] = useState({
    is_loading: false,
    is_loaded: false,
    is_image: false,
    is_pdf: false,
    url: '',
    file_name: '',
    file_id: '',
  });
  const [swissQRCodeFailed, setSwissQRCodeFailed] = useState(false);
  const [invoiceQRFiles, setInvoiceQRFiles] = useState([]);
  const [firstTimeFilesLoaded, setFirstTimeFilesLoaded] = useState(false);
  customerLanguage = customerLanguage.split('-').shift();

  useEffect(() => {
    moduleI++;
  }, []);

  useEffect(() => {
    if (!firstTimeFilesLoaded && !filesStore.hasLoadedMyFiles) {
      return;
    }

    setFirstTimeFilesLoaded(true);

    // Create a QR invoice when files are downloaded
    if (showOnLoad) {
      createQRInvoice();
    }
  }, [filesStore.hasLoadedMyFiles]);

  const getInvoiceIsBeingProcessed = (invoice) => {
    return (
      (invoice.CustomData || invoice.GoCardlessReference) &&
      (invoice.CustomData ?? '').indexOf('tok_') === -1 &&
      (invoice.CustomData ?? '').indexOf('vt_') === -1
    );
  };

  const getValidQRCodeFile = (providerIndex) => {
    const file = qrPaymentStore.getInvoiceFile(invoice, {
      providerIndex,
      paid: false,
      type: 'qrc',
    });

    return file ? file.file : false;

    // Rudiment
    /*const files = qrPaymentStore.getFiles({paid: false});
    const signature = createFileSignature();
    let validFiles = [];

    files.map(file => {
      file = file.file;
      if (
        'string' === typeof file.Description
        && file.Description.indexOf(';') > 0
      ) {
        const metaDataQR = file.Description.split(';');

        if (
          Array.isArray(metaDataQR)
          && metaDataQR.length >= 3
          && providerIndex === parseInt(metaDataQR[0])
          && ['qrc', 'qrb'].includes(metaDataQR[1])
          && invoice.IdString === metaDataQR[2]
          && customerLanguage === metaDataQR[4]
        ) {
          //if (metaDataQR[3] === signature) {
          validFiles.push(file);
          //}
        }
      }
    });

    if (validFiles.length === 1) {
      return validFiles[0];
    }

    if (validFiles.length > 1) {
      validFiles.sort(function(a, b) {
        return new Date(b.CreatedOnUtc) - new Date(a.CreatedOnUtc);
      });

      return validFiles[0];
    }

    return null;*/
  };

  const getIsEnabledQRInvoiceService = () => {
    if (
      !configuration[`HostedPayments.Provider${providerIndex}.Secret`]
      || !configuration[`HostedPayments.Provider${providerIndex}.Url`]
    ) {
      return false;
    }

    return 0 === configuration[`HostedPayments.Provider${providerIndex}.Secret`].indexOf('qr-invoice.ch;', 0);
  };

  const getTaxesArrays = () => {
    const qrSettings = configuration[`HostedPayments.Provider${providerIndex}.Secret`].split(';');

    const invoiceTaxes = toJS(invoice.TaxCategories);

    const importTaxesNames = qrSettings[2].split(',');

    let taxesVAT = [];

    let taxesVATImport = [];

    invoiceTaxes.map((taxCategory) => {
      if (importTaxesNames.includes(taxCategory.Name)) {
        taxesVATImport.push({
          'taxPercentage': taxCategory.Rate,
          'taxAmount': taxCategory.TaxSubTotal,
        });
      } else {
        taxesVAT.push({
          'taxPercentage': taxCategory.Rate,
          'taxedNetAmount': taxCategory.SubTotal,
        });
      }
    });

    return {
      'taxesVAT': taxesVAT,
      'taxesVATImport': taxesVATImport,
    };
  };

  const onQRInvoiceCreateBtnClick = e => {
    e.preventDefault();

    createQRInvoice();
  };

  const createQRInvoice = async () => {
    // Check if the file just has been created
    // Page was not reloaded after first click of the button
    if ((swissQRCode.is_loading && !swissQRCode.is_loaded) || swissQRCode.is_loaded) {
      return null;
    }

    setSwissQRCode({
      is_loading: true,
      is_loaded: false,
      is_image: false,
      is_pdf: false,
      url: '',
      file_name: '',
      file_id: '',
    });

    // Check valid QR file in the customer's files
    const validQRCodeFile = getValidQRCodeFile(providerIndex);

    if (validQRCodeFile) {
      await loadFileForPreview(validQRCodeFile.Id, validQRCodeFile.Name);
      return null;
    }

    // No QR file found, prepare data and make request for QR creation
    const taxesVATDetails = getTaxesArrays();

    const dayDifference = moment(invoice.DueDate).diff(moment(invoice.CreatedOn), 'days');

    let paymentConditions = [];

    if (dayDifference >= 0) {
      paymentConditions.push({
        'eligiblePaymentPeriodDays': dayDifference,
        'cashDiscountPercentage': 0,
      });
    }

    const data = {
      invoice_id: invoice.Id,
      customer_id: invoice.CoworkerId,
      taxes_vat: JSON.stringify(taxesVATDetails.taxesVAT),
      taxes_vat_import: JSON.stringify(taxesVATDetails.taxesVATImport),
      payment_conditions: JSON.stringify(paymentConditions),
      locale: customerLanguage,
      timezone_iana: business.SimpleTimeZone.Iana ?? null,
      signature: createSignature(),
      provider_index: providerIndex,
    };

    let response = null;

    const formData = new FormData();

    for (const name in data) {
      formData.append(name, data[name]);
    }

    if (configuration[`HostedPayments.Provider${providerIndex}.Url`].indexOf('file_type=qrci')) {
      try {
        response = await appStore.getAgent().requests.getBlob(`/invoices/print?guid=${invoice.UniqueId}`, `/${customerLanguage}`);
        const content = response;
        formData.append('invoice_pdf', content, `${invoice.InvoiceNumber}.pdf`);

      } catch (err) {
        setSwissQRCodeFailed(true);

        return appStore.setPopMessage(
          t('Sorry, something went wrong during QR file creation. Failed to load invoice. Please contact us to fix this error.'),
        );
      }
    }

    await fetch(configuration[`HostedPayments.Provider${providerIndex}.Url`], {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: formData,
    }).then((response) => response.json()).then((data) => {
      if (!data) {
        setSwissQRCodeFailed(true);

        return appStore.setPopMessage(
          t('Sorry, something went wrong during QR file creation. Please contact us to fix this error.'),
        );
      }

      // Update the file store
      filesStore.loadMyFiles().catch(err => console.error(`Can't load MyFiles.`, err));

      if (data.file_qr_invoice_id && parseInt(data.file_qr_invoice_id) > 0) {
        const qrInvoiceArrayItem = [
          {
            'invoice_id': data.invoice_id,
            'file_id': data.file_qr_invoice_id,
          }];

        setInvoiceQRFiles([...invoiceQRFiles, ...qrInvoiceArrayItem]);
      }

      loadFileForPreview(data.file_id, data.file_name);
    }).catch((err) => {
      setSwissQRCode({
        is_loading: false,
        is_loaded: false,
        is_image: false,
        is_pdf: false,
        url: '',
        file_name: '',
        file_id: '',
      });
      setSwissQRCodeFailed(true);

      appStore.setPopMessage(
        t('Sorry, something went wrong during QR file creation. Please contact us to fix this error.'),
      );
    });
  };

  const loadFileForPreview = async (fileId, fileName) => {
    let blob;
    try {
      blob = await filesStore.loadFile(fileId);
    } catch (err) {
      setSwissQRCode({
        is_loading: false,
        is_loaded: true,
        is_image: false,
        is_pdf: false,
        url: '',
        file_name: '',
        file_id: '',
      });
      setSwissQRCodeFailed(true);

      return appStore.setPopMessage(
        t('Sorry, we could not download QR file.'),
      );
    }

    if (!blob) {
      setSwissQRCode({
        is_loading: false,
        is_loaded: true,
        is_image: false,
        is_pdf: false,
        url: '',
        file_name: '',
        file_id: '',
      });
      setSwissQRCodeFailed(true);

      return appStore.setPopMessage(
        t('Sorry, we could not download QR file.'),
      );
    }

    const fileURL = window.URL.createObjectURL(
      new Blob([blob], {type: blob.type}),
    );

    const fileExt = fileName.split('.').pop();

    const imageExts = ['png', 'gif', 'jpeg', 'tiff', 'bmp'];

    setSwissQRCode({
      is_loading: false,
      is_loaded: true,
      is_image: imageExts.includes(fileExt),
      is_pdf: 'pdf' === fileExt,
      url: fileURL,
      file_name: fileName,
      file_id: fileId,
    });
  };

  const createSignature = () => {
    const signature = _.join([
      invoice.CoworkerId,
      configuration[`HostedPayments.Provider${providerIndex}.Secret`],
      invoice.Id,
    ], '|');

    return crypto.createHash('sha256').update(signature).digest('hex');
  };

  const createFileSignature = () => {
    const taxesArrays = getTaxesArrays();
    const signature = _.join([
      invoice.CoworkerId,
      invoice.BillToCountry.Id,
      invoice.BillToPostCode,
      invoice.BillToCity,
      invoice.BillToAddress,
      invoice.BillToName,
      invoice.Id,
      invoice.InvoiceNumber,
      invoice.TotalAmount,
      invoice.DueAmount,
      invoice.Currency.Code,
      JSON.stringify(taxesArrays.taxesVAT),
      JSON.stringify(taxesArrays.taxesVATImport),
      business.BillingAccountCode,
    ], '//');

    return crypto.createHash('sha256').update(signature).digest('hex');
  };

  useImperativeHandle(props.forwardedRef, () => ({
    getInvoiceIsBeingProcessed() {
      return getInvoiceIsBeingProcessed(invoice);
    },
  }));

  const isEnabledQRInvoiceService = getIsEnabledQRInvoiceService();
  const qrSettings = isEnabledQRInvoiceService
    ? configuration[`HostedPayments.Provider${providerIndex}.Secret`].split(';')
    : false;
  const isQRSettingsOk = Array.isArray(qrSettings) && qrSettings.length >= 2;
  const invoiceFileId = Array.isArray(invoiceQRFiles) && invoiceQRFiles.length > 0 ?
    invoiceQRFiles[invoiceQRFiles.length - 1].file_id : false;

  if (!filesStore.hasLoadedMyFiles) {
    return <LoadingLayout/>;
  }

  return <div data-component-name={componentName}>
    {!isEnabledQRInvoiceService && (
      <AuthenticatedLink
        data-component-name={componentName}
        className="btn btn-icon btn-link text-gray-900"
        href={`${business.NativeHomeUrlWithLanguage}/callbacks/HostedPagePaymentsStart?providerKey=${providerIndex}&invoiceId=${invoice.Id}`}
      >
        <i aria-hidden="true" className="icon-credit-card"></i>
        {configuration[`HostedPayments.Provider${providerIndex}.Name`]}
      </AuthenticatedLink>
    )}
    {isEnabledQRInvoiceService && !isQRSettingsOk && !filesStore.hasLoadedMyFiles && (
      <LoadingSpinner/>
    )}
    {isEnabledQRInvoiceService && isQRSettingsOk && filesStore.hasLoadedMyFiles ?
      <>
        {showQrCode === 'modal' && <>
          <a
            id={`qrinvoiceButton-${providerIndex}-${invoice.Id}-${moduleI}`}
            data-invoice-id={invoice.Id}
            data-component-name={componentName}
            data-toggle="modal"
            data-target={`#qrinvoice-modal-${providerIndex}-${invoice.Id}-${moduleI}`}
            className="btn btn-icon btn-link text-gray-900"
            onClick={onQRInvoiceCreateBtnClick}
            href="#"
          >
            <i aria-hidden="true" className="icon-invoice"></i>
            {configuration[`HostedPayments.Provider${providerIndex}.Name`]}
          </a>
          <div
            id={`qrinvoice-modal-${providerIndex}-${invoice.Id}-${moduleI}`}
            className="modal custom-modal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby={`${invoice.InvoiceNumber} - ${t('Swiss QR Code')}`}
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog_qr" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    {`${invoice.InvoiceNumber} - ${t('Swiss QR Code')}`}
                  </h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <i className="icon-close-large"></i>
                  </button>
                </div>
                <div className="modal-body modal-body_qr">
                  <div className="modal-body-qr__previewer">
                    {swissQRCode.is_loading && (
                      <div className="modal-body-qr__previewer-loader">
                        <LoadingSpinner/>
                      </div>
                    )}
                    {swissQRCode.is_loaded && swissQRCode.url && (
                      <>
                        {swissQRCode.is_image && (
                          <img
                            src={swissQRCode.url}
                            className="img-fluid modal-body-qr__previewer-image"
                            alt=""
                          />
                        )}
                        {swissQRCode.is_pdf && (
                          <a
                            href={swissQRCode.url}
                            className="btn text-dark"
                            download={swissQRCode.file_name}
                          >
                            <i className="icon-downloads fs-20 mr-4 tdn text-dark"></i> {t('Download')} {t('PDF')}
                          </a>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  {swissQRCode.is_loaded && swissQRCode.url && swissQRCode.is_image && (
                    /*<a
                      href={swissQRCode.url}
                      className="btn btn-white mr-4"
                      download={swissQRCode.file_name}
                    >
                      <i className="icon-downloads fs-20 mr-4 tdn"></i> {t('Download')}
                    </a>*/
                    <DownloadInvoiceButton
                      invoice={invoice}
                      invoiceFileId={invoiceFileId}
                      text={t('Download')}
                    />
                  )}
                  <button type="button" className="btn" data-dismiss="modal" aria-label="Close">
                    {t('Close')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>}
        {showQrCode === 'image' && <>
          <div className="qr-code-generate-btn">
            <span onClick={onQRInvoiceCreateBtnClick}>
              <i aria-hidden="true" className="icon-invoice"></i>
              {configuration[`HostedPayments.Provider${providerIndex}.Name`]}
            </span>
          </div>
          {swissQRCode.is_loading && <div className="qr-code-img-placeholder">
            <LoadingLayout/>
          </div>}
          {swissQRCode.url && <div className="qr-code-img">
            <img className="img-fluid" src={swissQRCode.url} alt=""/>
            {showDownloadInvoiceBtn && <>
              <div className="mt-20">
                <DownloadInvoiceButton
                  invoice={invoice}
                  invoiceFileId={invoiceFileId}
                  text={t('Download')}
                />
                {props.rightSideCnt && props.rightSideCnt}
              </div>
            </>}
          </div>}
          {/*Download a standard invoice if the QR failed*/}
          {swissQRCodeFailed && <>
            <DownloadInvoiceButton
              invoice={invoice}
              text={t('Download')}
            />
            {props.rightSideCnt && props.rightSideCnt}
          </>}
        </>}
      </>
      : ''}
    {getInvoiceIsBeingProcessed(invoice) && (
      <div className="d-flex align-items-center processing-payment">
        <i className="icon-pending mr-8 fs-24"></i>
        <span className="">
            {t('Processing your payment...')}
          </span>
      </div>
    )}
  </div>;
};

const HostedPaymentProviderButton = forwardRef((props, ref) => {
  const Component = withCustomComponent('HostedPaymentProviderButton')(
    withTranslation()(
      inject('appStore', 'authStore', 'filesStore', 'qrPaymentStore')(
        observer(HostedPaymentProviderButtonComponent),
      ),
    ),
  );

  return <Component forwardedRef={ref} {...props} />;
});

export default HostedPaymentProviderButton;
