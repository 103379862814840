import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
export const CommunityBoardHeader = withCustomComponent('CommunityBoardHeader')(
  inject('appStore')(
    withRouter(
      observer(({
        componentName,
        privateThreads,
        group,
        canStartConversation,
        router
      }) => {
        const { t } = useTranslation();
        const doSearch = (searchTerm) => {
          router.push(
            `${router.pathname}?query=${encodeURIComponent(searchTerm)}`
          );
        };
        const tag = router.query.tag;
        return (
          <div
            data-component-name={componentName}
            className="community-board-header"
          >
            <div className="d-block d-lg-flex align-items-center justify-content-between">
              <div className="">
                <h2 className="mb-0">
                  {!group && t('Discussion boards')}
                  {group && group.Name}
                  {tag && ' / '} {tag}
                </h2>
                {group && group.Description && <p className="mb-0 text-gray-600 fs-14">{group.Description}</p>}
              </div>
              <div className="d-block d-md-flex flex-wrap justify-content-between mt-16 mt-lg-0">

                <div className="d-flex align-items-center justify-content-between bg-white rounded p-4 fs-14 shadow-lg mr-12">
                  <input
                    id="query"
                    className="w-100 border-0 outline-0 pl-8"
                    defaultValue={router.query.query}
                    placeholder={t('Search...')}
                    onKeyDown={(ev) => {
                      if (ev.keyCode == 10 || ev.keyCode == 13) {
                        ev.preventDefault();
                        doSearch(ev.target.value);
                      }
                    }}
                  />
                  <button
                    className="btn  py-0 fs-18 btn-gray"
                    onClick={(e) => {
                      e.preventDefault();
                      doSearch(document.getElementById('query').value);
                    }}>
                    <i className="icon-search"></i>
                  </button>
                </div>

                {canStartConversation && (
                  <Link
                    href={{
                      pathname: routes.forum_start,
                      query: {
                        privateThread: privateThreads,
                        groupId: group && group.Id,
                      },
                    }}
                  >
                    <a className="btn btn-icon mt-8 mt-md-0 w-sm-100">
                      <i className="icon-add"></i>
                      {t('Start Conversation')}
                    </a>
                  </Link>
                )}
              </div>
            </div>
          </div>
        );
      })
    )
  )
);
