import React, { useState } from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { bookingsFeatures } from 'env/bookingsResources';

export const ResourceCard = withCustomComponent('ResourcesCard')(
  inject(
    'appStore',
    'bookingsStore'
  )(
    withRouter(
      observer(({ appStore, bookingsStore, resource, active }) => {
        const { t } = useTranslation();
        const { business, configuration } = appStore;
        const [showAvailability, setShowAvailability] = useState(false);
        const color = bookingsStore.getResourceColor(resource.Id);
        const bookingsFeaturesArray = bookingsFeatures(t)
          .map((feature, i) => {
            if (resource[feature.key] == true) return feature.name;
          })
          .filter((f) => f != undefined);

        const hasShifts =
          resource && resource.Shifts && resource.Shifts.length > 0;

        const newBooking = () => {
          const business = appStore.businesses.find(
            (b) => b.Id == resource.BusinessId
          );
          if (!business) return;

          // If this resource is not for this location, open the booking form
          // in the other site.
          var foundResource = bookingsStore.resources.find(
            (r) => r.Id == resource.Id
          );
          if (!foundResource) {
            window.open(
              business.HomeUrl + `/bookings/search?resource_id=${resource.Id}`
            );
            return;
          }

          bookingsStore.newBooking({
            resourceId: resource.Id,
            fromTime: bookingsStore.fromTime,
            toTime: bookingsStore.toTime,
          });
        };

        return (
          <div
            className={`card card-bookings-available card-bookings-map ${
              active ? 'active' : ''
            }`}
          >
            <div className="card-bookings-available__main">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  newBooking();
                }}
                className="card-bookings-map__image"
                style={{
                  backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/publicResources/GetImage?id=${resource.Id}&w=340&h=400&cache=${resource.UpdatedOn})`,
                }}
                alt={resource.Name}
              ></a>
              <div className="card-bookings-map__content">
                <div className="card-bookings-available__title">
                  <div className={`higlight bg-${color}`}></div>
                  <div className="title-wrapper">
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        newBooking();
                      }}
                      href="#"
                    >
                      <h4>{resource.Name}</h4>
                    </a>
                    <ul className="list-tags">
                      {resource.Allocation && (
                        <li
                          className="with-icon-left"
                          data-toggle="available-tooltip"
                          title={
                            resource.AllowMultipleBookings
                              ? t('Availability {{count}} unit', {
                                  count: resource.Allocation,
                                })
                              : t('Seating {{count}} person', {
                                  count: resource.Allocation,
                                })
                          }
                        >
                          <i
                            className={`icon-${
                              resource.AllowMultipleBookings ? 'desks' : 'user'
                            }`}
                          ></i>
                          {'x'}
                          {resource.Allocation}
                        </li>
                      )}
                      {resource.Price >= 0 && (
                        <li
                          className="with-icon-left"
                          data-toggle="available-tooltip"
                          title={t('Price')}
                        >
                          <i className="icon-money"></i>
                          <LocalizedPrice
                            currency={business.Currency.Code}
                            amount={resource.Price}
                          />
                        </li>
                      )}
                      {resource.ResourceTypeName && (
                        <li>{resource.ResourceTypeName}</li>
                      )}
                      {resource.GroupName && <li>{resource.GroupName}</li>}
                    </ul>
                  </div>
                </div>

                {showAvailability && (
                  <div className="card-bookings-available__status">
                    {resource.IsAvailable && (
                      <span className="tag available">{t('Available')}</span>
                    )}
                    {!resource.IsAvailable && (
                      <>
                        {hasShifts && (
                          <span className="tag unknown">{t('Unknown')}</span>
                        )}
                        {!hasShifts && (
                          <span
                            className="tag unavailable"
                            data-toggle="available-tooltip"
                            title={resource.Message}
                          >
                            {t('Unavailable')}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                )}

                <div className="card-bookings-available__features d-md-block d-none">
                  {bookingsFeaturesArray.length > 0 &&
                    bookingsFeaturesArray.length < 3 && (
                      <>
                        {bookingsFeaturesArray.map((feature, i) => (
                          <span key={i}>{t(feature)}</span>
                        ))}
                      </>
                    )}

                  {bookingsFeaturesArray.length > 2 && (
                    <>
                      <span key={0}>{t(bookingsFeaturesArray[0])}</span>
                      <span key={1}>{t(bookingsFeaturesArray[1])}</span>
                      <span key={2}>
                        {t('+{{count}} more', {
                          count: bookingsFeaturesArray.length - 2,
                        })}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })
    )
  )
);
