import React, { Component } from 'react';
import config from 'env/config';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { toJS, when, reaction } from 'mobx';
import { isWeekend } from 'env/utils/dateUtil';
import { isServer } from 'env/ssr/ServerSideRenderManager';
import { withRouter } from 'next/router';
import { routes } from 'env/routes';
import withCustomComponent from 'ui/components/withCustomComponent';
import { FloorPlanLegend } from 'ui/_pages/bookings/floorplan/FloorPlanLegend';
import { BookingDetails } from '../calendar/BookingDetails';
import { CoworkerAvatar } from 'ui/components/CoworkerAvatar';
import FloorPlanSideBar from './FloorPlanSideBar';

let engine;
let active = {};

@withRouter
@withCustomComponent('ArchilogicFloorPlanCanvas')
@withTranslation()
@inject(
  'appStore',
  'authStore',
  'bookingsStore',
  'checkoutStore',
  'floorPlanStore'
)
@observer
class ArchilogicFloorPlanCanvas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingFloorPlan: false,
      searchTerm: '',
    };
  }

  loadScene = () => {
    const { floorPlanStore, appStore } = this.props;
    if (engine) engine.destroy();
    const publishableToken = appStore.configuration['Archilogic.PublicToken'];
    const floorPlan = floorPlanStore.currentFloorPlan;
    if (!floorPlan) return;

    const container = document.getElementById('plan-canvas');
    engine = new window['FloorPlanEngine'](container, {
      ui: { menu: false },
      theme: {
        background: { color: 'transparent' },
      },
    });

    engine
      .loadScene(floorPlan.ArchilogicUniqueId, { publishableToken })
      .then(() => {
        this.setAvailabilityHighlight();
        this.updateCanvas(this.props.floorPlanStore.filteredItems);
        engine.on('mousemove', (evt) => this.highlightResources(evt), engine);
        engine.on('click', (evt) => this.openFeature(evt), engine);
      });
  };

  componentDidMount() {
    const { floorPlanStore } = this.props;

    this.loadScene();

    this.floorPlanReaction = reaction(
      () => floorPlanStore.currentFloorPlan,
      (fp) => {
        if (fp?.ArchilogicUniqueId) {
          this.loadScene();
        }
      }
    );

    this.itemsReaction = reaction(
      () => this.props.floorPlanStore.filteredItems,
      (items) => {
        if (items) this.updateCanvas(items);
      }
    );
  }

  componentWillUnmount() {
    if (this.itemsReaction) this.itemsReaction();
  }

  setAvailabilityHighlight() {
    const { floorPlanStore, checkoutStore } = this.props;
    const { groupedFilteredItems } = floorPlanStore;
    const { basket } = checkoutStore;

    const findDeskByFeature = (feature) =>
      groupedFilteredItems.find((d) => d.ArchilogicUniqueId == feature.id);

    const highlightFeature = (feature) => {
      const desk = findDeskByFeature(feature);

      if (!desk) {
        feature.node.setHighlight();
        return;
      }
      if (desk.ErrorCode) {
        //Booked
        feature.node.setHighlight({ fill: [227, 108, 100] });
      } else {
        const inBasket = basket.find((b) => b.data.FloorPlanDeskId == desk.Id);
        if (inBasket) feature.node.setHighlight({ fill: [250, 150, 50] });
        else feature.node.setHighlight({ fill: [100, 179, 121] });
      }
    };

    engine.resources.spaces.forEach((element) => highlightFeature(element));
    engine.resources.assets.forEach((element) => highlightFeature(element));
  }

  openFeature = (evt) => {
    const { floorPlanStore } = this.props;
    const { groupedFilteredItems } = floorPlanStore;
    const pos = evt.pos;
    let { spaces, assets } = engine.getResourcesFromPosition(pos);

    const id = assets[0]?.id ?? spaces[0]?.id;
    const desk = groupedFilteredItems.find((d) => d.ArchilogicUniqueId == id);

    if (this.props.instantAddToBasket) {
      return this.addBookingToBasket(
        desk,
        floorPlanStore.fromTime,
        floorPlanStore.toTime
      );
    }

    if (desk) {
      this.createNewBooking(desk);
    }
  };

  updateCanvas(items) {
    this.setAvailabilityHighlight();
    const nameMap = items.reduce(function (map, obj) {
      map[obj.ArchilogicUniqueId] = obj.Name;
      return map;
    }, {});
    engine.set({
      spaceLabelMapping: nameMap,
    });
  }

  highlight = (items, type, color) => {
    if (!items.length) {
      if (active[type]) {
        active[type].node.setHighlight();
        this.setAvailabilityHighlight();
      }
      delete active[type];
      return;
    }
    let item = items[0];
    if (active[type]?.id === item.id) return;
    else if (active[type]) {
      active[type].node.setHighlight();
      this.setAvailabilityHighlight();
    }
    item.node.setHighlight({ fill: color });
    active[type] = item;
  };

  setInfoWindow = (infoPos) => {
    const { floorPlanStore, appStore } = this.props;
    const { groupedFilteredItems } = floorPlanStore;
    const { business } = appStore;
    if (active.asset || active.space) {
      const id = active.asset?.id ?? active.space?.id;
      const desk = groupedFilteredItems.find((d) => d.ArchilogicUniqueId == id);

      active.infoWindow?.remove();
      delete active.infoWindow;

      if (!desk) {
        return;
      }
      const html = `<div style="font-weight: bold">${desk.Name}</div>
      ${desk.Coworkers.map(
        (c) => `<img style="height: 12px" src="${`${
          business.NativeHomeUrlWithLanguage
        }/coworker/getavatar/${
          c.CoworkerId
        }?&h=${50}&w=${50}&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}" />
      ${c.CoworkerFullName}<br/>`
      )}
      `;

      if (active.infoWindow) active.infoWindow.set({ pos: infoPos, html });
      else
        active.infoWindow = engine.addInfoWindow({
          pos: infoPos,
          html,
          height: 50 + 20 * desk.Coworkers.length,
          width: 'auto',
          closeButton: false,
        });
    } else if (active.infoWindow) {
      active.infoWindow.remove();
      delete active.infoWindow;
    }
  };

  highlightResources = (evt) => {
    const { floorPlanStore } = this.props;
    const { groupedFilteredItems } = floorPlanStore;
    const pos = evt.pos;
    const infoPos = [pos[0], pos[1] - 0.5];
    let { spaces, assets } = engine.getResourcesFromPosition(pos);

    spaces = spaces.filter((s) =>
      groupedFilteredItems.find((i) => i.ArchilogicUniqueId == s.id)
    );
    assets = assets.filter((s) =>
      groupedFilteredItems.find((i) => i.ArchilogicUniqueId == s.id)
    );

    if (assets.length > 0 || spaces.length > 0)
      document.body.childNodes[0].style.cursor = 'pointer';
    else document.body.childNodes[0].style = null;

    this.highlight(spaces, 'space', [150, 200, 250]);
    this.highlight(assets, 'asset', [250, 150, 50]);
    this.setInfoWindow(infoPos);
  };

  createNewBooking = async (data) => {
    const { bookingsStore, floorPlanStore } = this.props;
    bookingsStore.newBooking({
      resourceId: data.ResourceId,
      floorPlanDeskId: data.Id,
      deskName: data.Name,
      fromTime: floorPlanStore.fromTime,
      toTime: floorPlanStore.toTime,
      IncludeZoomInvite: false,
    });
    return Promise.resolve();
  };

  loadBookingPrice = () => {
    const { bookingsStore } = this.props;
    bookingsStore.loadBookingPrice();
  };

  closeNewBooking = async (data) => {
    const { bookingsStore } = this.props;
    bookingsStore.closeNewBooking();
    return Promise.resolve();
  };

  getResource() {
    const { bookingsStore } = this.props;
    const { booking, resources } = bookingsStore;
    return (
      booking &&
      _(resources)
        .filter((r) => r.Id === booking.ResourceId)
        .first()
    );
  }

  addBookingToBasket = async (data, fromTime, toTime) => {
    const { t, appStore, authStore, bookingsStore, checkoutStore } = this.props;
    const { customer } = authStore;
    const { basket } = checkoutStore;

    var hasShifts = this.getResource()?.Shifts?.length > 0;
    if (hasShifts) {
      // Check we are not booking more than 30 bookings at a time
      var items = basket.length + moment(toTime).diff(moment(fromTime), 'days');
      if (items > 30) {
        appStore.setPopMessage(
          t('You can request a maximum of 30 bookings in a single transaction')
        );
        return Promise.reject();
      }

      for (
        let date = fromTime;
        moment(date).startOf('day') <= moment(toTime).startOf('day');
        date = moment(date).clone().add(1, 'day').toDate()
      ) {
        const thisFromTime = moment(date).clone().toDate();
        const thisToTime = moment(date).clone().toDate();

        thisFromTime.setHours(fromTime.getHours());
        thisFromTime.setMinutes(fromTime.getMinutes());
        thisToTime.setHours(toTime.getHours());
        thisToTime.setMinutes(toTime.getMinutes());

        const includeWeekends = isWeekend(fromTime) || isWeekend(toTime);

        if (!includeWeekends && isWeekend(date)) continue;

        const booking = {
          ...data,
          Id: null,
          FromTime: thisFromTime,
          ToTime: thisToTime,
          ResourceName: data.Name,
          ResourceId: data.ResourceId,
          FloorPlanDeskId: data.Id,
          Notes: bookingsStore.booking?.Notes,
          BookingVisitors: bookingsStore.booking?.BookingVisitors,
          SelectedShift: bookingsStore.booking?.SelectedShift,
          IncludeZoomInvite: bookingsStore.booking?.IncludeZoomInvite,
        };

        const price = await bookingsStore.loadBookingPriceDebounced(booking);

        checkoutStore.addToBasket({
          type: 'booking',
          data: booking,
          resourceProducts: bookingsStore.resourceProducts,
          bookingPrice: price,
          previewInvoice: false,
        });
        bookingsStore.closeNewBooking();
      }
    } else {
      const booking = {
        ...data,
        Id: null,
        FromTime: fromTime,
        ToTime: toTime,
        ResourceName: data.Name,
        ResourceId: data.ResourceId,
        FloorPlanDeskId: data.Id,
        Notes: bookingsStore.booking?.Notes,
        BookingVisitors: bookingsStore.booking?.BookingVisitors,
        SelectedShift: bookingsStore.booking?.SelectedShift,
        IncludeZoomInvite: bookingsStore.booking?.IncludeZoomInvite,
      };

      const price = await bookingsStore.loadBookingPriceDebounced(booking);

      checkoutStore.addToBasket({
        type: 'booking',
        data: booking,
        resourceProducts: bookingsStore.resourceProducts,
        bookingPrice: price,
        previewInvoice: false,
      });
      bookingsStore.closeNewBooking();
    }

    if (customer != null) return checkoutStore.loadInvoicePreview();

    return Promise.resolve();
  };

  highlightDesk = (item, zoomTo) => {
    this.setAvailabilityHighlight();
    if (!item) return;

    const spaceNode = engine.resources.spaces.find(
      (s) => s.id == item.ArchilogicUniqueId
    )?.node;

    const assetNode = engine.resources.assets.find(
      (s) => s.id == item.ArchilogicUniqueId
    )?.node;
    const node = assetNode ?? spaceNode;
    if (node) {
      node.setHighlight({
        fill: [250, 150, 50],
        outline: [250, 150, 50],
        outlineWidth: 2,
      });
      if (zoomTo) engine.zoomToElement(node, 2, 1000);
    }
  };

  render() {
    const { router, selectFloorPlan, t, componentName, floorPlanStore } =
      this.props;
    const { floorPlans, groupedFilteredItems, currentFloorPlan } =
      floorPlanStore;

    if (
      !currentFloorPlan &&
      !this.state.isLoadingFloorPlan &&
      floorPlans.length === 1
    ) {
      selectFloorPlan(floorPlans[0].floorPlan);
      this.setState({ isLoadingFloorPlan: true });
    }

    return (
      <div data-component-name={componentName}>
        <div class="container">
          <div class="row">
            <FloorPlanSideBar highlightDesk={this.highlightDesk} />
            <div className="box col floorplans__map js-floorplans-map mt-20 p-2">
              <div id="plan-canvas"></div>
            </div>
          </div>
        </div>
        <BookingDetails />
      </div>
    );
  }
}
export default ArchilogicFloorPlanCanvas;
