import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { removeHtml } from 'env/utils/strings';

export const FaqArticleDetails = withCustomComponent('FaqArticleDetails')(
  inject('appStore')(
    withRouter(
      observer(({ componentName, appStore, article }) => {
        const { t } = useTranslation();

        const { business } = appStore;

        return (
          <div
            data-component-name={componentName}
            className="blog-post-details__content"
          >
            {article.HasLargeImage && (
              <img
                className="d-block img-fluid mx-auto mb-24"
                src={`${
                  business.NativeHomeUrlWithLanguage
                }/faq/GetLargeImage?id=${article.Id}&w=${1680}&cache=${
                  article.UpdatedOn
                }`}
                alt={removeHtml(article.Title)}
              />
            )}

            {article.FullText && (
              <div
                className="fr-view"
                dangerouslySetInnerHTML={{
                  __html: article.FullText,
                }}
              ></div>
            )}

            {!article.FullText && (
              <div
                className="fr-view"
                dangerouslySetInnerHTML={{
                  __html: article.SummaryText,
                }}
              ></div>
            )}
          </div>
        );
      })
    )
  )
);
