import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';

import {withRouter} from 'next/router';
import {routeInfo} from 'env/routes';
import Link from 'next/link';
import {isString} from 'env/utils/strings';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('Breadcrumbs')
@withTranslation()
@withRouter
@observer
class Breadcrumbs extends Component {
  render() {
    const {router, t, parent, last, steps} = this.props;

    let template;

    if (steps) {
      template = (
        <ol data-component-name={this.props.componentName} className="breadcrumb py-0 px-0 mb-0">
          {steps.map((s, i) => {
            let route = s.route;
            let className = s.class || '';
            route = typeof route === 'function' ? route() : route;
            className = `breadcrumb-item ${(!last && router.pathname === route ? ' active ' : '')} ${className}`;

            return <li
              key={i}
              className={className}
            >
              {!route || router.pathname === route ? (
                <span>{t(s.name)}</span>
              ) : (
                <Link href={route}>
                  <a>{t(s.name)}</a>
                </Link>
              )}
            </li>;
          })}
        </ol>
      );
    } else {
      template = (
        <ol data-component-name={this.props.componentName} className="breadcrumb">
          {Object.keys(routeInfo).reverse().map((x, i) => {
            let route = routeInfo[x].route;
            route = typeof route === 'function' ? route() : route;

            return this.navigationStep(
              router,
              t,
              routeInfo[x].route,
              routeInfo[x].name,
              last,
              i,
            );
          })}
          {parent && (
            <li>
              <Link href={parent.href}>{parent.label}</Link>
            </li>
          )}
          {last && <li className={'active'}>{last}</li>}
        </ol>
      );
    }

    return template;
  }

  navigationStep(router, t, route, name, last, i) {
    return (
      isString(route) &&
      router.pathname.indexOf(route) > -1 && (
        <li
          key={i}
          className={!last && router.pathname === route ? 'active' : ''}
        >
          {router.pathname === route ? (
            t(name)
          ) : (
            <Link href={route}>
              <a>{t(name)}</a>
            </Link>
          )}
        </li>
      )
    );
  }
}

export default Breadcrumbs;
