import React from 'react';
import dummyHocComponent from 'env/LoadableComponent/DummyHocComponent';
import LoadableComponent from 'env/LoadableComponent/LoadableComponent';
const babel = require('@babel/standalone');
let window = require('global/window');
const r = require;

export const parseSource = (source, depth = 0) => {
  window.exports = {};
  window.require = (name) => {
    if (name == 'react') return React;
    //To avoid recursinve calls to withCustomComponent
    else if (name == 'ui/components/withCustomComponent')
      return dummyHocComponent;
    else if (name == 'env/LoadableComponent/LoadableComponent')
      return (props) => {
        return <LoadableComponent {...props} depth={depth + 1} />;
      };
    else if (name == 'react-player') return r('react-player');
    else if (name == 'mobx-react') return r('mobx-react');
    else if (name == 'global') return r('global');
    else if (name == 'react-image-lightbox') return r('react-image-lightbox');
    else if (name == 'react-image-lightbox/style.css')
      return r('react-image-lightbox/style.css');
    else if (name == '@fullcalendar/common/main.css')
      return r('@fullcalendar/common/main.css');
    else if (name == '@fullcalendar/list/main.css')
      return r('@fullcalendar/list/main.css');
    else if (name == '@fullcalendar/daygrid/main.css')
      return r('@fullcalendar/daygrid/main.css');
    else if (name == '@fullcalendar/timegrid/main.css')
      return r('@fullcalendar/timegrid/main.css');
    else if (name == '@fullcalendar/timeline/main.css')
      return r('@fullcalendar/timeline/main.css');
    else if (name == '@fullcalendar/resource-timeline/main.css')
      return r('@fullcalendar/resource-timeline/main.css');
    else if (name == '@fullcalendar/core/locales/en-gb')
      return r('@fullcalendar/core/locales/en-gb');
    else if (name == '@fullcalendar/core/locales/it')
      return r('@fullcalendar/core/locales/it');
    else if (name == '@fullcalendar/core/locales/fr')
      return r('@fullcalendar/core/locales/fr');
    else if (name == '@fullcalendar/core/locales/de')
      return r('@fullcalendar/core/locales/de');
    else if (name == '@fullcalendar/core/locales/pt')
      return r('@fullcalendar/core/locales/pt');
    else if (name == '@fullcalendar/core/locales/es')
      return r('@fullcalendar/core/locales/es');
    else if (name == '@fullcalendar/core/locales/ar')
      return r('@fullcalendar/core/locales/ar');
    else if (name == '@fullcalendar/core/locales/he')
      return r('@fullcalendar/core/locales/he');
    else if (name == '@fullcalendar/core/locales/ja')
      return r('@fullcalendar/core/locales/ja');
    else if (name == '@fullcalendar/core/locales/nn')
      return r('@fullcalendar/core/locales/nn');
    else if (name == '@fullcalendar/core/locales/pl')
      return r('@fullcalendar/core/locales/pl');
    else if (name == '@fullcalendar/core/locales/sv')
      return r('@fullcalendar/core/locales/sv');
    else if (name == '@fullcalendar/react') return r('@fullcalendar/react');
    else if (name == '@fullcalendar/daygrid') return r('@fullcalendar/daygrid');
    else if (name == '@fullcalendar/timegrid')
      return r('@fullcalendar/timegrid');
    else if (name == '@fullcalendar/interaction')
      return r('@fullcalendar/interaction');
    else if (name == '@fullcalendar/resource-timeline')
      return r('@fullcalendar/resource-timeline');
    else if (name == '@culturehq/add-to-calendar')
      return r('@culturehq/add-to-calendar');
    else if (name == 'react-add-to-calendar') return r('react-add-to-calendar');
    else if (name == '@fullcalendar/list') return r('@fullcalendar/list');
    else if (name == 'mobx') return r('mobx');
    else if (name == 'next/link') return r('next/link');
    else if (name == 'react-dom') return r('react-dom');
    else if (name == 'react-i18next') return r('react-i18next');
    else if (name == 'i18next') return r('i18next');
    else if (name == 'text-clipper') return r('text-clipper');
    else if (name == 'prop-types') return r('prop-types');
    else if (name == 'next/router') return r('next/router');
    else if (name == 'next/head') return r('next/head');
    else if (name == 'react-helmet') return r('react-helmet');
    else if (name == 'query-string') return r('query-string');
    else if (name == 'react-recaptcha') return r('react-recaptcha');
    else if (name == 'reaptcha') return r('reaptcha');
    else if (name == 'react-markdown') return r('react-markdown');
    else if (name == 'react-scrollable-anchor')
      return r('react-scrollable-anchor');
    else if (name == 'react-widgets/DatePicker')
      return r('react-widgets/DatePicker');
    else if (name == 'turndown') return r('turndown');
    else if (name == '@fullcalendar/react') return r('@fullcalendar/react');
    else if (name == '@fullcalendar/daygrid') return r('@fullcalendar/daygrid');
    else if (name == '@fullcalendar/timegrid')
      return r('@fullcalendar/timegrid');
    else if (name == '@fullcalendar/interaction')
      return r('@fullcalendar/interaction');
    else if (name == '@fullcalendar/resource-timeline')
      return r('@fullcalendar/resource-timeline');
    else if (name == '@fullcalendar/list') return r('@fullcalendar/list');
    else if (name == '@react-google-maps/api')
      return r('@react-google-maps/api');
    else if (name == 'lodash') return r('lodash');
    else if (name == 'moment') return r('moment');
    else if (name == 'react-geosuggest') return r('react-geosuggest');
    else if (name == 'dequal') return r('dequal');
    else if (name == 'tiny-warning') return r('tiny-warning');
    else if (name == 'dom-helpers') return r('dom-helpers');
    else if (name == 'react-widgets') return r('react-widgets');
    else if (name == 'react-widgets/DropdownList')
      return r('react-widgets/DropdownList');
    else if (name == 'react-widgets/DatePicker')
      return r('react-widgets/DatePicker');
    else if (name == 'react-widgets/cjs') return r('react-widgets/cjs');
    else if (name == 'universal-cookie') return r('universal-cookie');
    else if (name == 'react-widgets-moment') return r('react-widgets-moment');
    else {
      // Keep the 'require' below here and not outside this function scope to avoid
      // recursive calls to the component finder
      const findComponent =
        require('env/LoadableComponent/componentFinder').findComponent;
      var found = findComponent(name);

      if (!found) throw Error(`Could not find component ${name}`);
      return found;
    }
  };
  const transformedSource = (window.Babel ?? babel).transform(source, {
    presets: ['react', 'es2015', 'stage-3'],
    plugins: [
      ['proposal-decorators', { legacy: true }],
      ['proposal-class-properties', { loose: true }],
      ['proposal-private-methods', { loose: true }],
      ['proposal-private-property-in-object', { loose: true }],
    ],
  }).code;

  return window.eval(transformedSource);
};
