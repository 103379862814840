import React, { Component } from 'react';
import Head from 'next/head';
import Link from 'next/link';
import { CardCourse } from 'ui/_pages/courses/CardCourse';
import { routes } from 'env/routes';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import { withRouter } from 'next/router';
import { removeHtml } from 'env/utils/strings';
import { CoursePageDetails } from 'ui/_pages/courses/course/CoursePageDetails';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import CourseMetaTags from 'ui/_pages/courses/course/CourseMetaTags';
import { CoursePageRelatedCourses } from 'ui/_pages/courses/course/CoursePageRelatedCourses';
import { CoursePageAccess } from 'ui/_pages/courses/course/CoursePageAccess';
import { CoursePageAttendees } from 'ui/_pages/courses/course/CoursePageAttendees';
import { CoursePageComments } from 'ui/_pages/courses/CoursePageComments';

@withCustomComponent('CoursesDetailsPage')
@withRouter
@withTranslation()
@inject('appStore', 'authStore', 'coursesStore', 'communityStore')
@observer
class CoursesDetailsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      group: '',
      error: null,
    };
  }

  async componentDidMount() {
    await this.loadCourse();
    this.loadCourses();
  }

  loadCourse = async () => {
    const { t, appStore, router, coursesStore } = this.props;
    const { coursePage } = coursesStore;

    const courseId = router.query.course_id;
    if (coursePage.Course.Id != courseId) {
      await coursesStore.loadCoursePage(courseId).catch((err) => {
        if (err == 'Error: Not Found') {
          router.push(routes.courses);
          return;
        }

        appStore.setPopMessage(
          t('Sorry, we could not load this page. {{err}}', { err })
        );
      });
    }
  };

  loadCourses = async () => {
    const { t, coursesStore } = this.props;

    if (this.state.group !== coursesStore.coursePage.Course.GroupName) {
      await coursesStore
        .loadCoursesPage({ group: coursesStore.coursePage.Course.GroupName })
        .catch((err) => {
          console.log(err);
          this.setState({
            error: t("You currently don't have access to the courses section."),
          });
        });

      this.setState({
        group: coursesStore.coursePage.Course.GroupName,
      });
    }
  };

  async componentDidUpdate() {
    await this.loadCourse();
    this.loadCourses();
  }

  render() {
    const { router, t, coursesStore, appStore } = this.props;
    const { business } = appStore;
    const course = coursesStore.coursePage.Course;
    const courses = coursesStore.coursesPage.Courses;
    const isLoading =
      !course ||
      !courses ||
      course.Id != router.query.course_id ||
      this.state.group !== coursesStore.coursePage.Course.GroupName;

    return (
      <>
        <CourseMetaTags />

        <DefaultLayout
          componentName={this.props.componentName}
          title={t('{{CourseTitle}} - {{Name}}', {
            CourseTitle:
              !isLoading && course ? removeHtml(course.Title) : '...',
            Name: t(business.Name),
          })}
        >
          <main className="content content--custom" role="main">
            <div className="container course">
              {isLoading && <LoadingSpinner />}
              {!isLoading && (
                <>
                  <div className="mb-20">
                    <Link href={routes.courses}>
                      <a className="btn btn-icon btn-white">
                        <i className="icon-go-back"></i>
                        {t('Back')}
                      </a>
                    </Link>
                  </div>

                  <div className="row mb-32">
                    <div className="col-xl-8 mb-24">
                      <CardCourse
                        courseContainer={coursesStore.coursePage}
                        sizeLarge={true}
                      />
                      <CoursePageDetails />
                      <CoursePageComments />
                    </div>

                    <div className="col-xl-4">
                      <CoursePageAccess />
                      <CoursePageAttendees />
                      <CoursePageRelatedCourses />
                    </div>
                  </div>
                </>
              )}
            </div>
          </main>
        </DefaultLayout>
      </>
    );
  }
}

export default CoursesDetailsPage;
