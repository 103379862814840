import React, {Component} from 'react';
import {observer, inject} from 'mobx-react';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import asForm from 'ui/components/forms/asForm';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('RegisteredDevicesSection')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class RegisteredDevicesSection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {t, authStore} = this.props;

    const registeredDevicesArray = authStore.me.Devices?.split(',');

    return (
      <>
        {registeredDevicesArray && registeredDevicesArray.length > 0 && (
          <fieldset
            componentName={this.props.componentName}
            id="registered-devices"
          >
            <div className="card-item-wrapper">
              <div className="card-item-header">
                <h5 className="card-item-header-title">
                  {t('Registered Devices')}
                </h5>
              </div>
              <div className="card-item-body">
                <p className="fs-14 text-gray-700 mb-24">
                  {t(
                    'These devices are currently registered to access our network.'
                  )}
                </p>

                <div className="fs-14 fw-500 pl-16 mb-6">{t('Type')}</div>
                <ul className="list-unstyled-striped">
                  {registeredDevicesArray.map((device, i) => (
                    <li
                      key={i}
                      className="d-flex align-items-center justify-content-between p-16 rounded"
                    >
                      <div className="">
                        <h6 className="mb-2">{t('Mac Address')}</h6>
                        <div className="fs-14 text-gray-700">{device}</div>
                      </div>
                      {/* <div className="">
                      <button
                        className="btn btn-link text-gray-700 fs-12"
                        onClick={(e) => {
                          this.setState({
                            registeredDevicesArray: this.state.registeredDevicesArray.filter(
                              function (mac) {
                                return mac != device;
                              }
                            ),
                          });
                          e.preventDefault();
                        }}
                      >
                        <i className="icon-close fs-18 mr-4"></i>
                        {t('Deregister')}
                      </button>
                    </div> */}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </fieldset>
        )}
      </>
    );
  }
}

export default asForm(RegisteredDevicesSection, 'customer');
