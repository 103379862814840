import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import { HomeBanner } from 'ui/_pages/home/components/HomeBanner';
import { HomeTopClaimFeaturesSection } from 'ui/_pages/home/components/HomeTopClaimFeaturesSection';
import { HomeServicesSection } from 'ui/_pages/home/components/HomeServicesSection';
import { HomeProductsSection } from 'ui/_pages/home/components/HomeProductsSection';
import { HomeLocationsSection } from 'ui/_pages/home/components/HomeLocationsSection';
import HomePlansSection from 'ui/_pages/home/components/HomePlansSection';
import HomeEventsArticlesSection from './components/HomeEventsArticlesSection';

export const GuestHomePage = withCustomComponent('GuestHomePage')(
  inject('appStore')(
    withRouter(
      observer(({ componentName, appStore }) => {
        const { t } = useTranslation();
        const {
          hasLoadedHomePage,
          isLoadingHomePage,
          configuration,
          homePage,
        } = appStore;

        if (!hasLoadedHomePage) return <></>;
        return (
          <div data-component-name={componentName}>
            {configuration['HomePage.Banner'] && <HomeBanner />}
            <HomeTopClaimFeaturesSection />
            {!appStore.corporateDashboard &&
              configuration['HomePage.ShowServicesBar'] && (
                <HomeServicesSection />
              )}
            {!appStore.corporateDashboard && <HomePlansSection />}
            {!appStore.corporateDashboard && homePage.Products?.length > 0 && (
              <HomeProductsSection />
            )}
            <HomeEventsArticlesSection />
            {appStore.businesses.length > 1 && (
              <HomeLocationsSection title={t('Our locations')} />
            )}
          </div>
        );
      })
    )
  )
);
