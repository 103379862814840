import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import InputField from 'ui/components/forms/InputField';
import DateInputField from 'ui/components/forms/DateInputField';
import TextAreaInputField from 'ui/components/forms/TextAreaInputField';
import BooleanInputField from 'ui/components/forms/BooleanInputField';
import SelectField from 'ui/components/forms/SelectField';
import withCustomComponent from 'ui/components/withCustomComponent';
import FileInputField from 'ui/components/forms/FileInputField';

@withCustomComponent('CustomField')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class CustomField extends Component {
  render() {
    const {
      field,
      updateProperty,
      customer,
      helpText,
      authStore,
      validationSchema,
    } = this.props;
    const schema = validationSchema || authStore.customerValidation;

    return (
      <div data-component-name={this.props.componentName}>
        {field.FieldType === 'Text' && (
          <>
            <div className="form-group col-12">
              <InputField
                id={`customer-custom-${field.CustomFieldIndex}`}
                label={t(field.Name) + (field.Required ? '*' : '')}
                name={`Custom${field.CustomFieldIndex}`}
                errors={schema.errors[`Custom${field.CustomFieldIndex}`]}
                value={customer[`Custom${field.CustomFieldIndex}`]}
                onChange={updateProperty}
              />
              {helpText && (
                <small class="form-text text-muted">{helpText}</small>
              )}
            </div>
          </>
        )}
        {field.FieldType === 'Binary' && (
          <>
            <div className="form-group col-12">
              <FileInputField
                id={`customer-custom-${field.CustomFieldIndex}`}
                label={t(field.Name) + (field.Required ? '*' : '')}
                name={`Custom${field.CustomFieldIndex}`}
                errors={schema.errors[`Custom${field.CustomFieldIndex}`]}
                onChange={updateProperty}
              />

              {helpText && (
                <small class="form-text text-muted">{helpText}</small>
              )}
            </div>
          </>
        )}
        {field.FieldType === 'Date' && (
          <>
            <div className="form-group col-12">
              <DateInputField
                id={`customer-custom-${field.CustomFieldIndex}`}
                label={t(field.Name) + (field.Required ? '*' : '')}
                name={`Custom${field.CustomFieldIndex}`}
                errors={schema.errors[`Custom${field.CustomFieldIndex}`]}
                value={customer[`Custom${field.CustomFieldIndex}`]}
                onChange={updateProperty}
                helpText={helpText}
              />
            </div>
          </>
        )}
        {field.FieldType === 'LongText' && (
          <>
            <div className="form-group col-12">
              <TextAreaInputField
                id={`customer-custom-${field.CustomFieldIndex}`}
                label={t(field.Name) + (field.Required ? '*' : '')}
                name={`Custom${field.CustomFieldIndex}`}
                errors={schema.errors[`Custom${field.CustomFieldIndex}`]}
                value={customer[`Custom${field.CustomFieldIndex}`]}
                onChange={updateProperty}
              />
              {helpText && (
                <small class="form-text text-muted">{helpText}</small>
              )}
            </div>
          </>
        )}
        {field.FieldType === 'Boolean' && (
          <>
            <div className="form-group col-12">
              <SelectField
                inputProps={{ style: { maxWidth: 100 } }}
                id={`customer-custom-${field.CustomFieldIndex}`}
                label={t(field.Name) + (field.Required ? '*' : '')}
                type="number"
                name={`Custom${field.CustomFieldIndex}`}
                errors={schema.errors[`Custom${field.CustomFieldIndex}`]}
                value={customer[`Custom${field.CustomFieldIndex}`]}
                onChange={updateProperty}
              >
                <>
                  <option value="">-</option>
                  <option value={true}>{t('Yes')}</option>
                  <option value={false}>{t('No')}</option>
                </>
              </SelectField>
              {helpText && (
                <small class="form-text text-muted">{helpText}</small>
              )}
            </div>
          </>
        )}

        {field.FieldType === 'Dropdown' && (
          <>
            <div className="form-group col-12">
              <SelectField
                inputProps={{
                  multiple: field.AllowMultipleOptions,
                }}
                id={`customer-custom-${field.CustomFieldIndex}`}
                label={t(field.Name) + (field.Required ? '*' : '')}
                type="number"
                name={`Custom${field.CustomFieldIndex}`}
                errors={schema.errors[`Custom${field.CustomFieldIndex}`]}
                value={customer[`Custom${field.CustomFieldIndex}`]}
                onChange={updateProperty}
              >
                <>
                  <option value="">-</option>
                  {field.AvailableOptionArray.map((opt, i) => (
                    <option key={i} value={opt}>
                      {opt}
                    </option>
                  ))}
                </>
              </SelectField>
              {helpText && (
                <small class="form-text text-muted">{helpText}</small>
              )}
            </div>
          </>
        )}

        {(field.FieldType === 'Integer' || field.FieldType === 'Decimal') && (
          <>
            <div className="form-group col-12">
              <InputField
                id={`customer-custom-${field.CustomFieldIndex}`}
                label={t(field.Name) + (field.Required ? '*' : '')}
                type="number"
                name={`Custom${field.CustomFieldIndex}`}
                errors={schema.errors[`Custom${field.CustomFieldIndex}`]}
                value={customer[`Custom${field.CustomFieldIndex}`]}
                onChange={updateProperty}
              />
              {helpText && (
                <small class="form-text text-muted">{helpText}</small>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
}

export default CustomField;
