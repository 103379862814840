import React from 'react';
import {useTranslation} from 'react-i18next';
import withCustomComponent from '../../../components/withCustomComponent';
import {inject, observer} from 'mobx-react';

let RemoveFromBasket = props => {
  const {
    authStore,
    checkoutStore,
    basketItem,
  } = props;
  const {t} = useTranslation();
  const {customer} = authStore;

  const removeFromBasket = () => {
    checkoutStore.removeFromBasket({
      item: basketItem,
      previewInvoice: customer != null,
    });
  };

  return (
    <a
      href="#"
      onClick={(ev) => {
        ev.preventDefault();
        removeFromBasket();
      }}
      className="btn btn-xs btn-icon btn-white border-0" // "btn btn-xs btn-icon btn-link text-gray-600 fs-12 fw-500"
    >
      <i className="icon-close-large"></i>
      {t('Remove')}
    </a>
  );
};

export default RemoveFromBasket = withCustomComponent('CheckoutRemoveFromBasket')(
  inject('authStore', 'checkoutStore')(
    observer(RemoveFromBasket),
  ),
);