import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { FormPageQuestionList } from './FormPageQuestionList';
import InputField from 'ui/components/forms/InputField';
import { action } from 'mobx';

export const FormPageDetails = withCustomComponent('FormPageDetails')(
  inject(
    'authStore',
    'appStore',
    'communityStore'
  )(
    withRouter(
      observer(
        ({
          authStore,
          formPagePage,
          saveFormPage,
          communityStore,
          componentName,
        }) => {
          const { t } = useTranslation();
          const { isSavingFormPagePage } = communityStore;
          const canBeSubmitted = saveFormPage && !isSavingFormPagePage;
          const formRequest = formPagePage.FormPageRequest;
          const updateProperty = action((name, value) => {
            formRequest[name] = value;
          });

          const isNewRequest =
            formPagePage.FormPageRequest.UniqueId ==
            formPagePage.FormPageRequest.FormPageDrop.UniqueId;

          return (
            <div className="container">
              <div className="mw-640 mx-auto pt-48 pb-64">
                <form id="formPage-form" data-component-name={componentName}>
                  {isNewRequest && (
                    <div className="border-bottom mb-20">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group ">
                            <InputField
                              id="fullname"
                              label={t('Full name') + '*'}
                              name="FullName"
                              errors={
                                communityStore.formPageValidation.Customer
                                  ?.errors.FullName
                              }
                              value={formRequest.FullName}
                              onChange={updateProperty}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group ">
                            <InputField
                              id="email"
                              label={t('Email') + '*'}
                              name="Email"
                              errors={
                                communityStore.formPageValidation.Customer
                                  ?.errors.Email
                              }
                              value={formRequest.Email}
                              onChange={updateProperty}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <FormPageQuestionList formPagePage={formPagePage} />

                  {!saveFormPage && (
                    <div className="fs-12 text-red mt-16 text-center">
                      {t(
                        'This is a preview of what a customer receiving this form would see. You cannot submit this form.'
                      )}
                    </div>
                  )}

                  <div className="btn-group mt-24 justify-content-end">
                    <button
                      disabled={!canBeSubmitted}
                      onClick={(ev) => {
                        if (!canBeSubmitted) return;
                        ev.preventDefault();
                        saveFormPage();
                      }}
                      className="btn"
                    >
                      {isSavingFormPagePage ? (
                        <LoadingSpinner />
                      ) : (
                        t('Submit responses')
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          );
        }
      )
    )
  )
);
