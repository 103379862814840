import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import {SupportHeader} from 'ui/_pages/support/SupportHeader';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import {withCustomComponent} from 'ui/components/withCustomComponent';
import CardSupport from 'ui/components/cards/CardSupport';
import NewSupportMessageForm from 'ui/_pages/support/NewSupportMessageForm';
import {withRouter} from 'next/router';

let SupportPage = props => {
  const {t, supportStore, appStore} = props;
  const {messages, hasLoadedMessages, newMessage, hasLoadedDepartments, departments} = supportStore;
  const {business} = appStore;

  useEffect(() => {
    supportStore.loadMessages().catch((err) => {
      appStore.setPopMessage(
        t('Sorry, we could not load this page. {{err}}', {err}),
      );
    });
    supportStore.createNewMessage();
    supportStore.loadDepartments().catch((err) => {
      appStore.setPopMessage(
        t('Sorry, we could not load this page. {{err}}', {err}),
      );
    });
  }, []);

  return (
    <DefaultLayout
      componentName={props.componentName}
      title={t('Request help - {{Name}}', {Name: t(business.Name)})}
    >
      <main className="content content--custom pt-0">
        <div className="portal-title-wrapper">
          <div className="container portal-title-container">
            <h1 className="portal-title">
              {t('Contact us')}
            </h1>
          </div>
        </div>
        <SupportHeader/>
        <div className="container">
          {!hasLoadedDepartments && <LoadingSpinner/>}
          {hasLoadedDepartments && (
            <NewSupportMessageForm
              departments={departments}
              newMessage={newMessage}
            />
          )}
        </div>
        <div className="container">
          {!hasLoadedMessages && <LoadingSpinner/>}
          {messages.length > 0 && (
            <>
              <div className="card-item-wrapper">
                <div className="card-item-header">
                  <h6 className="card-item-header-title d-flex align-items-center">
                    <i className="icon-marker-pin-circle text-gray-600 mr-8"></i>
                    {t(business.Name)}
                  </h6>
                </div>
                <div className="table-wrap table-wrap-tablet table-responsive">
                  <table className="table table-custom mb-0">
                    <thead>
                    <tr>
                      <th>{t('Issue')}</th>
                      <th>{t('Department')}</th>
                      <th>{t('Date created')}</th>
                      <th>{t('Created by')}</th>
                      <th>{t('Replies')}</th>
                      <th>{t('Status')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {messages.map((message, i) => (
                      <CardSupport message={message} key={i}/>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          <div className="card-item-wrapper">
            <div className="card-item-header">
              <h5 className="card-item-header-title">
                Contact information
              </h5>
            </div>
            <div className="card-item-body">
              <div className="contact-information-items">
                <div className="contact-information-item">
                  <i className=" icon-icon-map"></i>
                  <p>
                    4140 Parker Rd. Allentown,
                    New Mexico 31134
                  </p>
                </div>
                <div className="contact-information-item">
                  <i className="icon-phone"></i>
                  <p>
                    (480) 555-0103
                  </p>
                </div>
                <div className="contact-information-item">
                  <i className="icon-phone"></i>
                  <p>
                    (480) 555-0103
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </DefaultLayout>
  );
};

export default SupportPage = withCustomComponent('SupportPage')(
  withRouter(
    withTranslation()(
      inject('appStore', 'supportStore')(
        observer(SupportPage),
      ),
    ),
  ),
);
