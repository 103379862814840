import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { CardCourse } from 'ui/_pages/courses/CardCourse';

export const CoursePageRelatedCourses = withCustomComponent('CoursePageRelatedCourses')(
  inject(
    'appStore',
    'coursesStore'
  )(
    withRouter(
      observer(({ componentName, appStore, coursesStore }) => {
        const { t } = useTranslation();
        const { business } = appStore;
        const { Courses } = coursesStore.coursesPage;
        const { Course } = coursesStore.coursePage;
        const relatedCourses = Courses.filter((c) => c.Course.Id !== Course.Id);

        return (
          relatedCourses && relatedCourses.length > 0 && (
            <div className="card course-page-related-courses h-auto p-16 p-md-24 mb-24">
              <h5>
                {t('Related courses')}
              </h5>

              {_.take(relatedCourses, 3).map((c, i) => (
                <div className="mt-12" key={i}>
                  <CardCourse courseContainer={c} sizeExtraSmall={true} />
                </div>
              ))}
            </div>
          ));
        }
      )
    )
  )
);
