/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import AuthenticatedLink from 'ui/components/AuthenticatedLink';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('PayPalPaymentButton')
@withTranslation()
@inject('appStore', 'authStore')
class PayPalPaymentButton extends Component {
  render() {
    const { t, appStore, invoice, componentName } = this.props;
    const { business } = appStore;
    return (
      <AuthenticatedLink
        data-component-name={componentName}
        className="btn btn-icon btn-link text-gray-900"
        href={`${business.NativeHomeUrlWithLanguage}/invoices/pay?id=${invoice.Id}&provider=PayPal`}
      >
        <i aria-hidden="true" className="fa fa-cc-paypal text-gray-800 fs-18"></i>
        {t('Pay by PayPal')}
      </AuthenticatedLink>
    );
  }
}
export default PayPalPaymentButton;
