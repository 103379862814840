import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import {withRouter} from 'next/router';
import PortalPageTitle from 'ui/components/Header/PortalPageTitle';
import PaymentHistory from 'ui/_pages/invoices/PaymentHistory';

let Invoices = props => {
  const {
    t,
    appStore,
    authStore,
    invoicingStore,
  } = props;

  useEffect(() => {
    if (!authStore.hasLoadedCustomer)
      authStore.loadCustomer().catch(() => null);

    invoicingStore.loadInvoicesPage().catch((err) => {
      appStore.setPopMessage(
          t('Sorry, we could not load your invoices. {{err}}', {err}),
      );
    });
  }, []);

  return (
      <DefaultLayout>
        <main className="content content--custom pt-0" role="main">
          <PortalPageTitle
              title={t('Invoices')}
          />
          <div className="container">
            <PaymentHistory
                showPaidInvoices={true}
                showUnpaidInvoices={true}
            />
          </div>
        </main>
      </DefaultLayout>
  );
};

export default Invoices = withCustomComponent('Invoices')(
    withRouter(
        withTranslation()(
            inject('appStore', 'authStore', 'invoicingStore')(
                observer(Invoices),
            ),
        ),
    ),
);
