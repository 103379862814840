import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import Link from 'next/link';
import {routes} from 'env/routes';
import {LocalizedPrice} from 'env/utils/NumbersLocalization';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('ProductsSection')
@withTranslation()
@inject('appStore', 'authStore', 'invoicingStore')
@observer
class ProductsSection extends Component {
  componentDidMount() {
    const {invoicingStore} = this.props;
    const {hasLoadedBenefits} = invoicingStore;
    if (!hasLoadedBenefits) invoicingStore.loadBenefits();
  }

  render() {
    const {t, appStore, invoicingStore} = this.props;
    const {benefits} = invoicingStore;
    const products = benefits.Products;
    const isCorporateDashboard = appStore.corporateDashboard;

    return (
      <fieldset
        data-component-name={this.props.componentName}
        className="section section--settings"
        id="your-additional-products"
      >
        <div className="card-item-wrapper">
          <div className="card-item-header">
            <h5 className="card-item-header-title">
              {t('Your additional products')}
            </h5>
            <Link href={routes.store}>
              <a className="btn btn-gray">{t('See additional products')}</a>
            </Link>
          </div>
          <div className="card-item-body">

            {products.length > 0 && (
              <div className="table-wrap table-responsive">
                <table className="table table-striped table--settings">
                  <thead>
                  <tr>
                    <th>{t('Product')}</th>
                    {!isCorporateDashboard && (
                      <>
                        <th>{t('Payment frequency')}</th>
                        <th>{t('Price')}</th>
                      </>
                    )}
                  </tr>
                  </thead>
                  <tbody>
                  {products.map((product, i) => (
                    <tr key={i}>
                      <th data-title={t('Product')}>{product.Product.Name}</th>
                      {!isCorporateDashboard && (
                        <>
                          <td data-title={t('Payment frequency')}>
                            {product.RepeatCycle === 'PricePlan' && (
                              <span>{t('With your membership')}</span>
                            )}
                            {product.RepeatCycle === 'Day' && (
                              <span>
                            {t('Every {{count}} day', {
                              count: product.RepeatUnit,
                            })}
                          </span>
                            )}
                            {product.RepeatCycle === 'Week' && (
                              <span>
                            {t('Every {{count}} week', {
                              count: product.RepeatUnit,
                            })}
                          </span>
                            )}
                            {product.RepeatCycle === 'Month' && (
                              <span>
                            {t('Every {{count}} month', {
                              count: product.RepeatUnit,
                            })}
                          </span>
                            )}
                            {product.RepeatCycle === 'Year' && (
                              <span>
                            {t('Every {{count}} year', {
                              count: product.RepeatUnit,
                            })}
                          </span>
                            )}
                            {product.RepeatCycle === 'LastDayOfMonth' && (
                              <span>{t('Last day of the month')}</span>
                            )}

                            {product.InvoiceOn != null && (
                              <span className="help-block">
                            {' - '}
                                {t(
                                  'We will invoice this product again on {{ InvoiceOn, L }}',
                                  {InvoiceOn: new Date(product.InvoiceOn)}
                                )}
                          </span>
                            )}
                          </td>
                          <td data-title={t('Price')}>
                            {LocalizedPrice({
                              currency: product.Product.Currency.Code,
                              amount: product.Price,
                            })}
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            )}

            {products.length === 0 && (
              <div className="alert" role="alert">
                <span className="icon-alert-2"></span>
                {t('You have no products added to your account.')}
              </div>
            )}
          </div>
        </div>
      </fieldset>
    );
  }
}

export default ProductsSection;
