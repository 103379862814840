import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import moment from 'moment';
import { shorten, removeNewLines } from 'env/utils/strings';
import { CommunityBoardItemSeeRepliesButton } from './CommunityBoardItemSeeRepliesButton';

//import { CommunityBoardReply } from 'ui/_pages/community/thread/CommunityBoardReply';

export const CommunityBoardItemLastMessage = withCustomComponent(
  'CommunityBoardItemLastMessage'
)(
  inject('appStore')(
    withRouter(
      observer(({ thread, turndownService, componentName, appStore }) => {
        const { t } = useTranslation();
        const { business } = appStore;

        return (
          <>
            {thread.LastMessageText && (
              <div
                data-component-name={componentName}
                className="community-board-item-last-message"
              >
                <ul className="list-unstyled mb-0">
                  <li className="mb-8">
                    {/*<CommunityBoardReply />*/}

                    <div className="d-flex">
                      <Link
                        href={{
                          pathname:
                            routes.directory_member_by_id('[member_id]'),
                        }}
                        as={{
                          pathname: routes.directory_member_by_id({
                            Id: thread.LastMessageCoworkerId,
                            FullName: thread.LastMessageUserFullName,
                          }),
                        }}
                      >
                        <img
                          className="avatar avatar--40 mr-8 cp"
                          src={`${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${thread.LastMessageCoworkerId}?h=48&w=48&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}
                          alt={thread.LastMessageUserFullName}
                        />
                      </Link>
                      <div className="d-inline-block bg-gray-100 rounded py-8 px-12">
                        <div className="fs-14">
                          <Link
                            href={{
                              pathname:
                                routes.directory_member_by_id('[member_id]'),
                            }}
                            as={{
                              pathname: routes.directory_member_by_id({
                                Id: thread.LastMessageCoworkerId,
                                FullName: thread.LastMessageUserFullName,
                              }),
                            }}
                          >
                            <a>{thread.LastMessageUserFullName}</a>
                          </Link>{' '}
                          <span className="text-gray-600">
                            {t('replied')}{' '}
                            <time
                              dateTime={moment(thread.LastMessage)
                                .locale('en')
                                .format()}
                              className="timeago"
                            >
                              {moment(thread.LastMessage).format('L')}
                            </time>
                          </span>
                        </div>
                        <div className="">
                          {shorten(removeNewLines(thread.LastMessageText), 50)}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>

                {thread.MessageCount > 1 && (
                  <CommunityBoardItemSeeRepliesButton thread={thread} />
                )}
              </div>
            )}
          </>
        );
      })
    )
  )
);
