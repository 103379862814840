import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import ProfilePageLayout from 'ui/layouts/ProfilePageLayout';
import { privateRoute } from 'env/privateRoute';
import { withCustomComponent } from 'ui/components/withCustomComponent';
import MetricsWidget from 'ui/_pages/profile/metrics/MetricsWidget';
import moment from 'moment';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';
import Head from 'next/head';

@withCustomComponent('MetricsPage')
@privateRoute
@withTranslation()
@inject('appStore', 'authStore', 'invoicingStore')
@observer
class MetricsPage extends Component {
  componentDidMount() {
    const { invoicingStore, authStore, appStore } = this.props;

    if (!authStore.hasLoadedCustomer)
      authStore.loadCustomer().catch(() => null);

    invoicingStore.loadMetrics().catch((err) => {
      appStore.setPopMessage(
        t('Sorry, we could not load your metrics. {{err}}', { err })
      );
    });
  }

  render() {
    const { t, invoicingStore, appStore } = this.props;
    const { configuration, corporateDashboard } = appStore;
    const { metrics } = invoicingStore;
    const bookingData = _.map(metrics, (d) => {
      return {
        date: moment(d.MonthDate).format('MMM YYYY'),
        team:
          Math.round((d.TeamBookingMinutes / 60 + Number.EPSILON) * 100) / 100,
        customer:
          Math.round((d.BookingMinutes / 60 + Number.EPSILON) * 100) / 100,
      };
    });

    const revenueData = _.map(metrics, (d) => {
      return {
        date: moment(d.MonthDate).format('MMM YYYY'),
        team: d.TeamInvoicedAmount,
        customer: d.InvoicedAmount,
      };
    });

    const accessData = _.map(metrics, (d) => {
      return {
        date: moment(d.MonthDate).format('MMM YYYY'),
        team:
          Math.round((d.TeamCheckingMinutes / 60 + Number.EPSILON) * 100) / 100,
        customer:
          Math.round((d.CheckingMinutes / 60 + Number.EPSILON) * 100) / 100,
      };
    });

    return (
      <ProfilePageLayout componentName={this.props.componentName}>
        <Head>
          <title>
            {t('My metrics')} - {t(this.props.appStore.business.Name)}
          </title>
        </Head>
        <div className="container">
          <div className="mw-720">
            {!corporateDashboard && configuration['Members.ViewInvoices'] && (
              <fieldset>
                <div className="card p-16 p-md-24">
                  <h5 className="section__title">
                    {t('Payments in the last 12 months')}
                  </h5>
                  <MetricsWidget
                    formatter={(value) =>
                      LocalizedPrice({
                        currency: metrics[0].CurrencyCode,
                        amount: value,
                      })
                    }
                    metrics={revenueData}
                  />
                </div>
              </fieldset>
            )}

            <fieldset>
              <div className="card p-16 p-md-24">
                <h5 className="section__title">
                  {t('Booked time in the last 12 months')}
                </h5>
                <MetricsWidget
                  formatter={(value) => t('{{count}} hour', { count: value })}
                  metrics={bookingData}
                />
              </div>
            </fieldset>

            <fieldset>
              <div className="card p-16 p-md-24">
                <h5 className="section__title">
                  {t('Access time in the last 12 months')}
                </h5>
                <MetricsWidget
                  formatter={(value) => t('{{count}} hour', { count: value })}
                  metrics={accessData}
                />
              </div>
            </fieldset>
          </div>
        </div>
      </ProfilePageLayout>
    );
  }
}

export default MetricsPage;
