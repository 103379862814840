/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('BookingCancellationStatus')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class BookingCancellationStatus extends Component {

  render() {
    const { t, componentName, appStore, authStore } = this.props;
    const { configuration } = appStore;
    const { customer } = authStore;
    if (
      (customer === null && (
        configuration['Bookings.CancelUnPaidBookingsForNonSubscribers'] === 'true' ||
        configuration['Bookings.CancelUnPaidBookingsForSubscribers'] === 'true'
      )) ||
      (
        customer?.IsMember === true &&
        configuration['Bookings.CancelUnPaidBookingsForSubscribers'] === 'true'
      ) ||
      (
        customer?.IsMember === false &&
        configuration['Bookings.CancelUnPaidBookingsForNonSubscribers'] === 'true'
      )
    ) {
      return (
        <div
          data-component-name={componentName}
          className="alert alert-info mt-24 mb-0 booking-cancellation-status"
        >
          <p className="m-0 fw-500 lh-13 fs-14">{t(`This booking will be cancelled if it is not paid within {{CancelUnPaidBookingsAfter}} minutes of purchase.`, {
            CancelUnPaidBookingsAfter: configuration['Bookings.CancelUnPaidBookingsAfter'],
          })}</p>
        </div>
      );
    }
    return null;
  }
}
export default BookingCancellationStatus;
