import React, {useEffect, useState} from 'react';
import {observer, inject} from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import {useTranslation} from 'react-i18next';
import {toJS} from 'mobx';
import usePrevious from 'env/usePrevious';
import i18n from 'env/i18n';

const LanguageBar = withCustomComponent('LanguageBar')(
  inject('appStore')(
    observer(({router, appStore, classes, componentName}) => {
      const {t} = useTranslation();

      const getLanguageShortCode = () => {
        return i18n.language?.toLowerCase();
      };
      const [currentLanguage, setCurrentLanguage] = useState(
        getLanguageShortCode()
      );
      const prevLanguage = usePrevious(currentLanguage);

      const {languages} = appStore;
      const languagesArray = toJS(languages);

      useEffect(() => {
        if (!appStore.hasLoadedLanguages) appStore.loadLanguages();
      }, []);

      useEffect(() => {
        setCurrentLanguage(getLanguageShortCode());
      }, [getLanguageShortCode()]);

      useEffect(() => {
        if (prevLanguage && currentLanguage != prevLanguage) {
          window.location.href = `?lng=${currentLanguage}`;
        }
      }, [currentLanguage]);

      return (
        <select
          className={`w-100 custom-select ${classes ?? ''}`}
          id="language"
          value={currentLanguage}
          aria-label={t('Select a language')}
          onChange={(ev) => setCurrentLanguage(ev.target.value)}
        >
          {languagesArray.sort(sortAlphabetically).map((l, i) => {
            if (l.Culture == 'en') {
              return (
                <React.Fragment key={i}>
                  <option key={'en-us'} value={`${l.Culture.toLowerCase()}-us`}>
                    {l.Name} (US)
                  </option>
                  <option key={'en-gb'} value={`${l.Culture.toLowerCase()}-gb`}>
                    {l.Name} (GB)
                  </option>
                </React.Fragment>
              );
            }
            return (
              <option
                key={l.Culture.toLowerCase()}
                value={l.Culture.toLowerCase()}
              >
                {l.Name}
              </option>
            );
          })}
        </select>
      );
    })
  )
);

export default LanguageBar;

const sortAlphabetically = (a, b) => {
  if (a.Name < b.Name) {
    return -1;
  }
  if (a.Name > b.Name) {
    return 1;
  }

  return 0;
};
