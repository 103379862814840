import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import ProfilePageLayout from 'ui/layouts/ProfilePageLayout';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { privateRoute } from 'env/privateRoute';
import { withRouter } from 'next/router';
import ResponsiveTable from 'ui/components/tables/ResponsiveTable';
import moment from 'moment';
import NewVisitorForm from 'ui/_pages/visitors/NewVisitorForm';
import { withCustomComponent } from 'ui/components/withCustomComponent';
import Head from 'next/head';

@withCustomComponent('VisitorsPage')
@privateRoute
@withRouter
@withTranslation()
@inject('appStore', 'authStore', 'bookingsStore')
@observer
class VisitorsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { t, appStore, bookingsStore } = this.props;
    bookingsStore.loadMyVisitors().catch(() => {
      appStore.setPopMessage(t('Could not load your visitors.'));
    });
  }

  deleteVisitor = (visitor) => {
    const { t, bookingsStore, appStore } = this.props;

    appStore
      .setYesNotQuestion(t('Do you really want to remove this visitor?'))
      .then((response) => {
        if (response)
          bookingsStore
            .deleteVisitor(visitor)
            .then(() => bookingsStore.loadMyVisitors())
            .catch((err) => {
              appStore.setPopMessage(
                t(
                  'We could not remove this visitors. Please contact us. ' + err
                )
              );
            });
      });
  };

  render() {
    const { routes, baseUrl, t, appStore, bookingsStore } = this.props;
    const { business } = appStore;
    const {
      myVisitors,
      hasLoadedMyVisitors,
      newVisitor,
      isDeletingVisitor,
    } = bookingsStore;

    const hasLoadedData = hasLoadedMyVisitors;
    return (
      <ProfilePageLayout componentName={this.props.componentName}>
        <Head>
          <title>
            {t('My visitors')} - {t(appStore.business.Name)}
          </title>
        </Head>

        <div>
          {!hasLoadedData && (
            <form id="profile-form">
              <fieldset className="section--settings" id="your-plan">
                <legend className="h3 section__title">
                  <LoadingSpinner />
                </legend>
              </fieldset>
            </form>
          )}
          {hasLoadedData && (
            <form className="profile-form ">
              <div className="card card-dashboard mb-32">
                <fieldset className="section--settings">
                  <h5 className="section__title">
                    {t('My upcoming visitors')}
                  </h5>
                  {myVisitors.length == 0 && (
                    <p className="alert alert-info">
                      <b>{t('You have no expected visitors')}</b>
                    </p>
                  )}
                  {myVisitors.length > 0 && (
                    <ResponsiveTable>
                      <thead>
                        <tr>
                          <th>{t('Full name')}</th>
                          <th>{t('Expected at')}</th>
                          <th>{t('Email')}</th>
                          <th>{t('Phone number')}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {myVisitors.map((visitor, i) => {
                          return (
                            <tr key={i}>
                              <td valign="middle">
                                <h6 className="m-0 py-0 py-md-4">
                                  {visitor.FullName}
                                </h6>
                              </td>
                              <td valign="middle">
                                {moment(visitor.ExpectedArrival).format('LLL')}
                              </td>
                              <td valign="middle">
                                <a
                                  className="text-gray-900"
                                  href={`mailto:${visitor.Email}`}
                                >
                                  {visitor.Email}
                                </a>
                              </td>
                              <td valign="middle">
                                {visitor.PhoneNumber && (
                                  <a
                                    className="text-gray-900"
                                    href={`tel:${visitor.PhoneNumber}`}
                                  >
                                    {visitor.PhoneNumber}
                                  </a>
                                )}
                              </td>
                              <td
                                valign="middle"
                                className="text-left text-md-right"
                              >
                                <a
                                  onClick={(ev) => {
                                    this.setState({
                                      ['clicked' + visitor.Id]: true,
                                    });
                                    ev.preventDefault();
                                    this.deleteVisitor(visitor);
                                  }}
                                  href="#"
                                  type="button"
                                  className="btn btn-link btn-xs text-gray-900 mb-2"
                                >
                                  {this.state['clicked' + visitor.Id] &&
                                  isDeletingVisitor ? (
                                    <LoadingSpinner />
                                  ) : (
                                    <>
                                      <i className="icon-close mr-4 fs-18"></i>
                                      {t('Remove')}
                                    </>
                                  )}
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </ResponsiveTable>
                  )}
                </fieldset>
              </div>
              <div className="card card-dashboard mb-32">
                <fieldset className="section--settings">
                  <h5 className="section__title">{t('Register a visitor')}</h5>
                  <div className="mw-640">
                    <NewVisitorForm newVisitor={newVisitor}/>
                  </div>
                </fieldset>
              </div>
            </form>
          )}
        </div>
      </ProfilePageLayout>
    );
  }
}

export default VisitorsPage;
