import React from 'react';
import { inject } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { HelpDeskComment } from 'ui/_pages/support/HelpDeskComment';

export const HelpDeskCommentsList = withCustomComponent('HelpDeskCommentsList')(
  inject('appStore')(({ componentName, message, appStore }) => {
    return (
      <>
        {message.Comments.length > 0 &&
          <ul className="list-unstyled mb-0">
            <TransitionGroup className="comments-list">
              {message.Comments.map((comment, i) => (
                <CSSTransition
                  key={comment.Id}
                  timeout={200}
                  classNames="comment"
                >
                  <li className="mb-8">
                    <HelpDeskComment comment={comment} />
                  </li>
                </CSSTransition>
              ))}
            </TransitionGroup>
          </ul>
        }
      </>
    );
  })
);
