import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';
import LoadingSpinner from 'ui/components/LoadingSpinner';

export const CheckoutTotalPrice = withCustomComponent('CheckoutTotalPrice')(
  inject(
    'appStore',
    'checkoutStore'
  )(
    withRouter(
      observer(({ componentName, checkoutStore, appStore }) => {
        const { t } = useTranslation();
        const { business } = appStore;
        const { invoicePreview, isLoadingInvoicePreview } = checkoutStore;
        const hasErrors = invoicePreview && invoicePreview.Message;
        let totalCredits = 0;

        if (invoicePreview && invoicePreview.Lines) {
          invoicePreview.Lines.forEach(line => {
            if (line.SubTotal < 0) {
              totalCredits += line.SubTotal + line.TaxAmount;
            }
          })
        }

        if (hasErrors) return <></>;

        return (
          invoicePreview && (
            <div data-component-name={componentName}>
              {invoicePreview.TaxAmount > 0 && (
                <>
                  <div className="mb-12">
                    <h6 className="mb-4">{t('Sub total')}</h6>
                    <h4 className="m-0">
                      <LocalizedPrice
                        currency={invoicePreview.Currency.Code}
                        amount={
                          invoicePreview.TotalAmount - invoicePreview.TaxAmount
                        }
                      />
                    </h4>
                  </div>
                  <div className="mb-12">
                    <h6 className="mb-4">{t('Tax')}</h6>
                    <h4 className="m-0">
                      <LocalizedPrice
                        currency={invoicePreview.Currency.Code}
                        amount={invoicePreview.TaxAmount}
                      />
                    </h4>
                  </div>
                  {totalCredits < 0 && <div className="mb-12">
                    <h6 className="mb-4">{t('Credits')}</h6>
                    <h4 className="m-0">
                      <LocalizedPrice
                        currency={invoicePreview.Currency.Code}
                        amount={totalCredits}
                      />
                    </h4>
                  </div>}
                </>
              )}
              {invoicePreview.TotalAmount - invoicePreview.DueAmount > 0 && (
                <>
                  <div className="mb-12">
                    <h6 className="mb-4">{t('Credit on account')}</h6>
                    <h4 className="m-0">
                      <LocalizedPrice
                        currency={invoicePreview.Currency.Code}
                        amount={
                          invoicePreview.DueAmount - invoicePreview.TotalAmount
                        }
                      />
                    </h4>
                  </div>
                </>
              )}

              <div className="mb-24">
                {invoicePreview && (
                  <h6 className="mb-4">{t('Total to pay')}</h6>
                )}
                <h3 className="m-0">
                  {isLoadingInvoicePreview ? (
                    <LoadingSpinner />
                  ) : (
                    invoicePreview && (
                      <LocalizedPrice
                        currency={invoicePreview.Currency.Code}
                        amount={invoicePreview.DueAmount}
                      />
                    )
                  )}
                </h3>
              </div>
            </div>
          )
        );
      })
    )
  )
);
