import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const LessonPageImage = withCustomComponent('LessonPageImage')(
  inject(
    'appStore',
    'coursesStore'
  )(
    withRouter(
      observer(({ router, appStore, coursesStore }) => {
        const lesson = coursesStore.lessonPage.Lesson;
        const { business } = appStore;
        
        return (
          <div className="article-content fr-view">
            {lesson.ImageFileName && (
              <div
                className="lesson-details__image"
                style={{ backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/learn/getcourselessonimage?id=${lesson.Id}&w=1500` }}
              >
              </div>
            )}
          </div>
        );
      })
    )
  )
);
