import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import GoCardlessPaymentButton from 'ui/_pages/invoices/paymentButtons/GoCardlessPaymentButton';

export const GoCardlessPaymentForm = withCustomComponent(
  'GoCardlessPaymentForm'
)(
  inject('invoicingStore')(
    withRouter(
      observer(({ invoicingStore }) => {
        const { t } = useTranslation();
        const { invoicesPage } = invoicingStore;
        return (
          <>
            <p className="mxw-600px text-gray-700 fs-14 mb-20">
              {t(
                'Direct Debit is an instruction from you to your bank or building society. It authorises the organisation you want to pay to collect varying amounts from your account – but only if you’ve been given advanced notice of the amounts and dates of collection.'
              )}
            </p>
            {invoicesPage.HasGoCardlessMandateForLocation && (
              <>
                <p className="mxw-600px text-gray-700 fs-14 mb-20">
                  <strong>
                    {t(
                      'You already have a Direct Debit agreement with us. Please get in touch with us if you would like to cancel or change it.'
                    )}
                  </strong>
                </p>
                <Link href={routes.support_new}>
                  <a href="#" className="btn  mr-8">
                    {t('Contact us')}
                  </a>
                </Link>
              </>
            )}
            {!invoicesPage.HasGoCardlessMandateForLocation && (
              <GoCardlessPaymentButton
                returnUrl={window.location.pathname + '?gc_result=ok'}
                failedReturnUrl={window.location.pathname + '?gc_result=fail'}
                className="btn btn-outline-gray btn-icon "
              />
            )}
          </>
        );
      })
    )
  )
);
