import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { FormPageQuestionRow } from './FormPageQuestionRow';

export const FormPageQuestionList = withCustomComponent('FormPageQuestionList')(
  inject(
    'appStore',
    'communityStore'
  )(
    withRouter(
      observer(({ formPagePage, componentName }) => {
        const { t } = useTranslation();
        const formPage = formPagePage.FormPageRequest.FormPageDrop;
        return (
          <fieldset
            data-component-name={componentName}
            className="formPage__section"
          >
            {formPagePage.Questions.map((question, i) => (
              <FormPageQuestionRow question={question} key={i} />
            ))}
          </fieldset>
        );
      })
    )
  )
);
