import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const FormPageSubmitted = withCustomComponent('FormPageSubmitted')(
  inject('appStore')(
    withRouter(
      observer(({ router, appStore, componentName }) => {
        const { t } = useTranslation();

        return (
          <div data-component-name={componentName} className="container">
            <div className="mw-640 mx-auto pt-48 pb-64 text-center">
              <i className="icon-email-delivery fs-64"></i>

              <h5 className="text-center mt-24 mb-48">
                {t('Your answers were successfully submitted.')}
                <br />
                {t('Thanks for your time!')}
              </h5>

              <Link href={routes.home}>
                <a className="btn" href="/">
                  {t('Continue')}
                </a>
              </Link>
            </div>
          </div>
        );
      })
    )
  )
);
