import _ from 'lodash';
export const getColorForResource = (resources, id) => {
  //Custom color support
  if (window.nxPortal?.fullCalendar?.getColorForResource) {
    const color = window.nxPortal.fullCalendar.getColorForResource(
      resources,
      id
    );
    if (color) return color;
  }

  var colors = [
    '#28B95F',
    '#DCB40A',
    '#F0783C',
    '#14B4E6',
    '#F04B69',
    '#0064FA',
    '#5F00BA',
    '#EE589E',
  ];

  var index = _.findIndex(resources, { Id: id }) % colors.length;
  return colors[index];
};
