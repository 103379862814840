import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const BannerCarousel = withCustomComponent('BannerCarousel')(
  inject('appStore')(
    withRouter(
      observer(({ router, appStore, componentName }) => {
        const { t } = useTranslation();
        const { business, homePage } = appStore;
        return (
          <>
            {homePage?.Gallery?.length > 0 && 
              <div
                data-component-name={componentName}
                className="banner-carousel"
              >
                <div className="container">
                  <div
                    id="carousel-about"
                    class="carousel carousel--about slide"
                  data-ride="carousel"
                >

                    <div class="carousel-inner">
                      {homePage?.Gallery?.map((item, i) => (
                        <div
                          key={i}
                          class={`carousel-item ${i == 0 ? 'active' : ''}`}
                        >
                          <div
                            className="img"
                            style={{ backgroundImage: `url(${business.NativeHomeUrl}${item.Url}?w=1200)` }}
                          ></div>
                        </div>
                      ))}
                    </div>

                    {homePage?.Gallery?.length > 1 && (
                      <>
                        <a
                        className="carousel-control-prev"
                          href="#carousel-about"
                          role="button"
                          data-slide="prev"
                        >
                          <span className="carousel-control__inner">
                            <i className="fa fa-chevron-left" aria-hidden="true"></i>
                          </span>
                          <span className="sr-only">{t('Previous')}</span>
                        </a>
                        <a
                        className="carousel-control-next"
                          href="#carousel-about"
                          role="button"
                          data-slide="next"
                        >
                          <span className="carousel-control__inner">
                            <i className="fa fa-chevron-right" aria-hidden="true"></i>
                          </span>
                          <span className="sr-only">{t('Next')}</span>
                        </a>
                      </>
                  )}

                  </div>
                </div>
              </div>
            }
          </>
        );
      })
    )
  )
);
