import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import { removeNewLines, replaceNewLines } from 'env/utils/strings';

@withCustomComponent('CardLocation')
@withTranslation()
@inject('appStore')
@observer
class CardLocation extends Component {
  static propTypes = {
    business: PropTypes.object.isRequired,
  };

  render() {
    const { t, componentName, appStore, business } = this.props;

    return (
      <div data-component-name={componentName} className={`card card-location`}>
        <a
          href={business.HomeUrl}
          className="card-location__image"
          style={{
            backgroundImage: `url('${business.NativeHomeUrlWithLanguage}/business/GetBannerFor?id=${business.Id}&w=700&h=400')`,
          }}
        ></a>
        <div className="card-location__content">
          <div className="card-location__content__header">
            <a href={business.HomeUrl} className="text-gray-900">
              <h5 className="mb-8">{t(business.Name)}</h5>
            </a>
            <p
              className="mb-24 lh-14 text-gray-700"
              dangerouslySetInnerHTML={{
                __html: replaceNewLines(business.Address, '<br />'),
              }}
            ></p>
          </div>
          <div className="card-location__content__footer">
            <div className="text-right">
              <a
                target="_blank"
                className="text-gray-700"
                href={`https://www.google.com/maps/place/${encodeURI(
                  replaceNewLines(business.Address, ', ')
                )}`}
              >
                {t('Map')}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardLocation;
