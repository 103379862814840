import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import { routes } from 'env/routes';
import moment from 'moment';
import { withRouter } from 'next/router';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import { FaqArticleMetaData } from 'ui/_pages/faq/FaqArticleMetaData';
import { FaqArticleDetails } from 'ui/_pages/faq/FaqArticleDetails';

@withRouter
@withCustomComponent('FaqArticlePage')
@withTranslation()
@inject('appStore', 'contentStore', 'authStore')
@observer
class FaqArticlePage extends Component {
  componentDidMount() {
    const { contentStore } = this.props;

    if (!contentStore.hasLoadedFaqArticles) contentStore.loadFaqArticles();
  }

  render() {
    const { t, router, appStore, contentStore } = this.props;
    const { business } = appStore;
    const { faqArticles } = contentStore;
    const article = faqArticles.find((a) => a.Id == router.query.article_id);

    if (!article) return <LoadingLayout />;

    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={t('Frequently Asked Questions') + ' - ' + t(business.Name)}
      >
        <main className="content content--custom" role="main">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-1 d-none d-xl-block">
                <Link href={routes.faq}>
                  <a className="btn btn-single-icon btn-white">
                    <i className="icon-go-back"></i>
                  </a>
                </Link>
              </div>
              <div className="col-12 col-xl-10">
                <div className="card p-16 p-md-24 p-lg-32 h-auto">
                  <FaqArticleMetaData article={article} />
                  <FaqArticleDetails article={article} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default FaqArticlePage;
