import React from 'react';
import {useTranslation} from 'react-i18next';
import {withRouter} from 'next/router';
import {inject, observer} from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import LoadingLayout from 'ui/layouts/LoadingLayout';

import {HomeBanner} from 'ui/_pages/home/components/HomeBanner';
import HomeEventsArticlesSection from 'ui/_pages/home/components/HomeEventsArticlesSection';
import HomePlansSection from 'ui/_pages/home/components/HomePlansSection';

export const VirtualHomePage = withCustomComponent('VirtualHomePage')(
    inject('appStore')(
        withRouter(
            observer(({componentName, appStore}) => {
              const {t} = useTranslation();
              const {
                hasLoadedHomePage,
                isLoadingHomePage,
                configuration,
                homePage,
              } = appStore;

              if (!hasLoadedHomePage || isLoadingHomePage)
                return (
                    <div className="pt-40 pb-40">
                      <LoadingLayout/>
                    </div>
                );
              return (
                  <div data-component-name={componentName}>
                    <HomeBanner/>
                    <HomeEventsArticlesSection/>
                    <HomePlansSection/>
                    {/* <HomeCtaSection /> */}
                  </div>
              );
            }),
        ),
    ),
);
