import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import {privateRoute} from 'env/privateRoute';
import {withCustomComponent} from 'ui/components/withCustomComponent';
import {InvoicesTable} from 'ui/_pages/invoices/InvoicesTable';
import Head from 'next/head';

@withCustomComponent('InvoicesPage')
@privateRoute
@withTranslation()
@inject('appStore', 'authStore', 'invoicingStore')
@observer
class InvoicesPage extends Component {
  componentDidMount() {
    const {invoicingStore, authStore, appStore} = this.props;

    if (!authStore.hasLoadedCustomer)
      authStore.loadCustomer().catch(() => null);

    invoicingStore.loadInvoicesPage().catch((err) => {
      appStore.setPopMessage(
        t('Sorry, we could not load your invoices. {{err}}', {err})
      );
    });
  }

  render() {
    const {t} = this.props;
    return (
      <DefaultLayout
        componentName={this.props.componentName}
        title={`${t('My invoices')} - ${t(this.props.appStore.business.Name)}`}
      >
        <main className="content content--custom pt-0" role="main">
          <div className="portal-title-wrapper">
            <div className="container portal-title-container"><h1 className="portal-title">
              {t('Billing/bank information')}
            </h1>
            </div>
          </div>
          <div className="container">
            <InvoicesTable
              showDraftInvoiceButton={true}
              showStatementButton={true}
            />
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default InvoicesPage;
