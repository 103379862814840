import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const FloorPlanItemFilters = withCustomComponent('FloorPlanItemFilters')(
  inject(
    'appStore',
    'floorPlanStore'
  )(
    withRouter(
      observer(({ floorPlanStore, selectFloorPlan, componentName }) => {
        const { t } = useTranslation();
        const {
          areas,
          currentArea,
          currentFloorPlan,
          floorPlans,
          showTypeFilters,
        } = floorPlanStore;

        return (
          <div data-component-name={componentName}>
            {currentFloorPlan && (
              <div className="my-20 d-flex align-items-center justify-content-between">
                <div className="">
                  <ul className="nav nav-s4 nav-active-black">
                    {floorPlans
                      .sort((a, b) =>
                        a.floorPlan.Name.localeCompare(b.floorPlan.Name)
                      )
                      .map((item, i) => (
                        <li className="nav-item" key={i}>
                          <a
                            onClick={(ev) => {
                              ev.preventDefault();
                              selectFloorPlan(item.floorPlan);
                            }}
                            href="#"
                            className={`btn btn-gray-light btn-md-sm -xs ${
                              currentFloorPlan?.Id == item.floorPlan.Id
                                ? 'active'
                                : ''
                            }`}
                          >
                            {item.floorPlan.Name}
                          </a>
                        </li>
                      ))}

                    {/* {areas.length > 0 && (
                      <>
                        <li className="nav-spacer"></li>
                        {areas.map((item, i) => (
                          <li className="nav-item" key={i}>
                            <a
                              href="#"
                              onClick={(ev) => { ev.preventDefault(); }}
                              className="btn btn-gray-light btn-md-sm -xs"
                            >
                              {item.area.Name}
                            </a>
                          </li>
                        ))}
                      </>
                    )} */}
                  </ul>
                </div>

                <div className="pl-12">
                  <a
                    onClick={(ev) => {
                      ev.preventDefault();
                      floorPlanStore.setShowTypeFilters(!showTypeFilters);
                    }}
                    href="#"
                    className={`btn btn-icon btn-gray-light btn-md-sm -xs ${
                      showTypeFilters ? 'active' : ''
                    }`}
                  >
                    <i
                      className={`icon-${
                        !showTypeFilters ? 'filter' : 'close'
                      }`}
                    ></i>
                    {t('Filters')}
                  </a>
                </div>
              </div>
            )}
          </div>
        );
      })
    )
  )
);
