import React, { useState } from 'react';
import Link from 'next/link';
import _ from 'lodash';
import { withRouter } from 'next/router';
import { routes } from 'env/routes';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import { RelatedProfile } from 'ui/_pages/directory/members/RelatedProfile';

export const EventPageAttendees = withCustomComponent('EventPageAttendees')(
  inject(
    'appStore',
    'eventsStore'
  )(
    withRouter(
      observer(({ router, appStore, eventsStore }) => {
        const { t } = useTranslation();
        const [showAll, setShowAll] = useState(false);
        const { eventPage } = eventsStore;
        const event = eventsStore.eventPage.Event;

        return (
          eventPage.AttendeeCount > 0 && (
            <div className="card event-page-attendees p-16 p-md-24 h-auto mb-24">
              <div className="d-flex align-items-center justify-content-between mb-20">
                <div className="d-flex align-items-end">
                  <h3 className="mb-0 mr-12">{eventPage.AttendeeCount}</h3>
                  <h6 className="mb-0 pb-2 pb-md-6">{t('Attendees')}</h6>
                </div>
                {/* BB-TODO: Link this button to all event atendees page */}

                {!showAll && (
                  <a
                    className="text-gray-700 fs-14"
                    href="#"
                    onClick={() => setShowAll(true)}
                  >
                    {t('View all')}
                  </a>
                )}
              </div>
              {eventPage.Attendees &&
                _.take(
                  eventPage.Attendees,
                  showAll ? 1000 : 5
                ).map((member, i) => (
                  <RelatedProfile key={i} member={member} className="mb-16" />
                ))}
            </div>
          )
        );
      })
    )
  )
);
