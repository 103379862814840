import { observable, action, reaction, toJS, computed } from 'mobx';
import { loadDataHelper, saveDateHelper } from './storeHelpers';
import StoreBase from './storeBase';
import { communityMessageValidationSchema } from './validationSchemas/communityMessageValidationSchema';
import { communityMessageReplyValidationSchema } from './validationSchemas/communityMessageReplyValidationSchema';
import { surveyValidationSchema } from './validationSchemas/surveyValidationSchema';
import {
  formPageCustomerValidationSchema,
  formPageValidationSchema,
} from './validationSchemas/formPageValidationSchema';
import { validationContext } from 'validx';
import { isServer } from 'env/ssr/ServerSideRenderManager';
import { getStores } from './stores';
import { slugify } from 'env/utils/strings';
import global from 'global';
import _ from 'lodash';
const validation = validationContext();

class CommunityStore extends StoreBase {
  constructor({ data, host } = {}) {
    super({ data, host });

    if (!isServer()) {
      //These handlers are triggered by the signalR hub (signalr.js)
      window.newThreadNotification = async (data) => {
        const threadPage = await this.loadThreadPage({ thread_id: data.Id });
        const thread = threadPage.Thread;
        this.showBrowserNotificationForThread(thread);

        if (this.communityPage == null) return;
        if (this.communityPage.Threads == null) return;
        if (!this.communityPage.Threads.find((x) => x.Id == data.Id)) {
          action(() => {
            this.communityPage.Threads.unshift(thread);
          })();
        }
      };

      window.newMessageNotification = (data) => {
        //Send browser notification if the browser is not on focus
        //or the thread is not on screen
        if (
          !document.hasFocus() ||
          !window.location.pathname.indexOf(data.ThreadId.toString()) > -1
        ) {
          this.showBrowserNotificationForMessage(data);
        }

        //If the thread is on the screen, show the new message.
        if (window.location.pathname.indexOf(data.ThreadId.toString()) > -1) {
          action(() => {
            this.threadPage.Messages.push(data);
            window.location = '#message-' + data.Id;
          })();
        }
        const foundInThreadsPageIndex = this.communityPage?.Threads?.findIndex(
          (x) => x.Id == data.ThreadId
        );
        if (foundInThreadsPageIndex > -1) {
          action(() => {
            var thread = this.communityPage.Threads[foundInThreadsPageIndex];
            if (!thread.Messages) {
              thread.Messages = thread.LastMessageText
                ? [
                    {
                      Message: thread.LastMessageText,
                      CreatedOn: thread.LastMessage,
                      FullName: thread.LastMessageUserFullName,
                      CoworkerId: thread.LastMessageCoworkerId,
                      Id: thread.LastMessageId,
                    },
                  ]
                : [];
            }
            thread.Messages.push(data);
            thread.MessageCount++;

            this.communityPage = this.communityPage;
          })();
        }
      };
    }

    reaction(
      () => toJS(this.newCommunityBoardMessage),
      (newCommunityBoardMessage) => {
        this.newCommunityBoardMessageValidation = validation
          .reset()
          .validate(
            newCommunityBoardMessage,
            communityMessageValidationSchema(this, newCommunityBoardMessage)
          );
      }
    );

    reaction(
      () => toJS(this.newCommunityBoardReply),
      (newCommunityBoardReply) => {
        this.newCommunityBoardReplyValidation = validation
          .reset()
          .validate(
            newCommunityBoardReply,
            communityMessageReplyValidationSchema(this, newCommunityBoardReply)
          );
      }
    );

    reaction(
      () => toJS(this.surveyPage),
      (surveyPage) => {
        validation.reset();
        for (let index = 0; index < surveyPage.Questions.length; index++) {
          const question = surveyPage.Questions[index];
          this.surveyValidation[`Question${question.Id}`] = validation.validate(
            question,
            surveyValidationSchema(question)
          );
        }
      }
    );

    reaction(
      () => toJS(this.formPagePage),
      (formPagePage) => {
        validation.reset();
        for (let index = 0; index < formPagePage.Questions.length; index++) {
          const question = formPagePage.Questions[index];
          this.formPageValidation[`Question${question.Id}`] =
            validation.validate(question, formPageValidationSchema(question));
        }

        this.formPageValidation.Customer = validation.validate(
          formPagePage.FormPageRequest,
          formPageCustomerValidationSchema(this, formPagePage.FormPageRequest)
        );
      }
    );
  }

  @observable isLoadingCheckedInMetrics = false;
  @observable hasLoadedCheckedInMetrics = false;
  @observable checkedInMetrics = { checkedIn: 0, counted: 0 };
  @action loadCheckedInMetrics() {
    return loadDataHelper({
      store: this,
      agentKey: 'Directory',
      key: 'CheckedInMetrics',
    });
  }

  @observable surveyValidation = {};
  @observable formPageValidation = {};

  @action setNewCommunityBoardReply(reply) {
    this.newCommunityBoardReply = reply;
  }

  @observable newCommunityBoardReply = {};
  @observable newCommunityBoardReplyValidation = { errors: {} };
  @action saveNewCommunityBoardReply(thread_id) {
    return saveDateHelper({
      store: this,
      agentKey: 'Community',
      key: 'NewCommunityBoardReply',
      validation: validation,
      validationSchema: communityMessageReplyValidationSchema(
        this,
        this.newCommunityBoardReply
      ),
      modelToValidate: this.newCommunityBoardReply,
      data: { thread_id: thread_id, message: this.newCommunityBoardReply },
    })
      .then((data) => {
        const isInGroup = data.SuccessMessage && !data.Guests;
        if (!isInGroup) {
          const errorMessage = `- ${global.t(
            'You must be part of this group to comment'
          )}`;
          return Promise.reject(errorMessage);
        }

        action(() => {
          this.newCommunityBoardReply = {};
        });
      })
      .then(
        action(() => {
          this.newCommunityBoardReplyValidation = { errors: {} };
        })
      );
  }

  @observable newCommunityBoardMessage = {};

  @action createNewCommunityBoardMessage = ({ groupId, privateThread }) => {
    this.newCommunityBoardMessage = {
      GroupId: groupId,
      Private: !!privateThread,
      Subject: null,
      Message: null,
    };
  };

  @observable newCommunityBoardMessageValidation = { errors: {} };
  @action saveNewCommunityBoardMessage({ isPrivate, messageToCoworkerId }) {
    this.newCommunityBoardMessage.Private = isPrivate;
    this.newCommunityBoardMessage.MessageToCoworkerId = messageToCoworkerId;
    this.newCommunityBoardMessage.Group = this.newCommunityBoardMessage
      .GroupId && {
      Id: this.newCommunityBoardMessage.GroupId,
    };

    return saveDateHelper({
      store: this,
      agentKey: 'Community',
      key: 'NewCommunityBoardMessage',
      validation: validation,
      validationSchema: communityMessageValidationSchema(
        this,
        this.newCommunityBoardMessage
      ),
      modelToValidate: this.newCommunityBoardMessage,
      data: this.newCommunityBoardMessage,
    })
      .then(
        action(() => {
          this.newCommunityBoardMessage = {};
        })
      )
      .then(
        action(() => {
          this.newCommunityBoardMessageValidation = { errors: {} };
        })
      );
  }

  @observable isLoadingCustomerPage = false;
  @observable hasLoadedCustomerPage = false;
  @observable customerPage = null;
  @action loadCustomerPage({ member_id, ...rest }) {
    return loadDataHelper({
      store: this,
      agentKey: 'Directory',
      key: 'CustomerPage',
      params: { member_id, ...rest },
    });
  }

  @observable isLoadingTeamPage = false;
  @observable hasLoadedTeamPage = false;
  @observable teamPage = null;
  @action loadTeamPage({ team_id, ...rest }) {
    return loadDataHelper({
      store: this,
      agentKey: 'Directory',
      key: 'TeamPage',
      params: { team_id, ...rest },
    });
  }

  @observable isLoadingSimpleSearch = false;
  @observable hasLoadedSimpleSearch = false;
  @observable simpleSearch = [];
  @action loadSimpleSearch() {
    return loadDataHelper({
      store: this,
      agentKey: 'Directory',
      key: 'SimpleSearch',
    });
  }

  @observable isLoadingThreadPage = false;
  @observable hasLoadedThreadPage = false;
  @observable threadPage = {};
  @action loadThreadPage({ thread_id, top, page }) {
    return loadDataHelper({
      store: this,
      agentKey: 'Community',
      key: 'ThreadPage',
      params: { thread_id, top, page },
    });
  }

  @observable isLoadingTeamsDirectory = false;
  @observable hasLoadedTeamsDirectory = false;
  @observable teamsDirectory = {};
  @action loadTeamsDirectory({ tag, query, top = 24, page = 1, ...rest }) {
    return loadDataHelper({
      store: this,
      agentKey: 'Directory',
      key: 'TeamsDirectory',
      params: {
        tag,
        query,
        top,
        page,
        ...rest,
      },
    });
  }

  @observable isLoadingMembersDirectory = false;
  @observable hasLoadedMembersDirectory = false;
  @observable membersDirectory = {};
  @action loadMembersDirectory({
    tag,
    query,
    top = 24,
    page = 1,
    onlyCheckedIn = false,
    onlyAdmins = false,
    onlyNew = false,
    ...rest
  }) {
    return loadDataHelper({
      store: this,
      agentKey: 'Directory',
      key: 'MembersDirectory',
      params: {
        tag,
        query,
        top,
        page,
        onlyCheckedIn,
        onlyAdmins,
        onlyNew,
        ...rest,
      },
    });
  }

  @action showFullMessage(thread) {
    const threads = this.communityPage.Threads;
    if (threads) {
      const index = threads.indexOf(thread);
      if (index > -1)
        threads[index].ShowFullMessage = !threads[index].ShowFullMessage;
    }
  }

  @action followThread(thread) {
    const isCurrentlyMuted = thread.MutedForUser;
    const isCurrentlyFollowed = thread.FollowedByUser;
    thread.FollowedByUser = !thread.FollowedByUser;

    if (isCurrentlyMuted && !isCurrentlyFollowed) this.muteThread(thread);

    return isCurrentlyFollowed
      ? this.getAgent().Community.unFollowThread(thread.Id)
      : this.getAgent().Community.followThread(thread.Id);
  }

  @action likeMessage(message) {
    const isCurrentlyLiked = message.LikedByUser;
    message.LikeCount = message.LikeCount + (isCurrentlyLiked ? -1 : 1);
    message.LikedByUser = !message.LikedByUser;

    return isCurrentlyLiked
      ? this.getAgent().Community.unLikeMessage(message.Id)
      : this.getAgent().Community.likeMessage(message.Id);
  }

  @action deleteThread(thread) {
    return this.getAgent().Community.deleteThread(thread.Id);
  }

  @action deleteMessage(message) {
    return this.getAgent()
      .Community.deleteMessage(message.Id)
      .then(
        action(() => {
          //Delete comment from current thread
          if (this.threadPage.Messages) {
            this.threadPage.Messages = this.threadPage.Messages.filter(
              (m) => m.Id != message.Id
            );
            this.threadPage = this.threadPage;
          }

          //Delete comment if found in current page
          const foundInThreadsPageIndex =
            this.communityPage?.Threads?.findIndex(
              (t) => t.Id == message.ThreadId
            );

          if (foundInThreadsPageIndex > -1) {
            //Remove the message
            const thread = this.communityPage.Threads[foundInThreadsPageIndex];
            if (thread.Messages) {
              thread.MessageCount--;
              thread.Messages = thread.Messages.filter(
                (m) => m.Id != message.Id
              );
              this.communityPage = this.communityPage;
            }
          }
        })
      );
  }

  @action likeThread(thread) {
    const isCurrentlyLiked = thread.LikedByUser;
    thread.LikeCount = thread.LikeCount + (isCurrentlyLiked ? -1 : 1);
    thread.LikedByUser = !thread.LikedByUser;

    const result = isCurrentlyLiked
      ? this.getAgent().Community.unLikeThread(thread.Id)
      : this.getAgent().Community.likeThread(thread.Id);

    return result;
  }

  @action muteThread(thread) {
    const isCurrentlyMuted = thread.MutedForUser;
    const isCurrentlyFollowed = thread.FollowedByUser;
    thread.MutedForUser = !thread.MutedForUser;

    if (!isCurrentlyMuted && isCurrentlyFollowed) this.followThread(thread);
    return isCurrentlyMuted
      ? this.getAgent().Community.unMuteThread(thread.Id)
      : this.getAgent().Community.muteThread(thread.Id);
  }

  @observable isLoadingCommunityStartPage = false;
  @observable hasLoadedCommunityStartPage = false;
  @observable communityStartPage = {
    Groups: [],
  };
  @action loadCommunityStartPage({ ...rest }) {
    return loadDataHelper({
      store: this,
      agentKey: 'Community',
      key: 'CommunityStartPage',
      params: rest,
    }).then(
      action(() => {
        this.newCommunityBoardMessageValidation = { errors: {} };
      })
    );
  }

  @observable isLoadingCommunityPage = false;
  @observable hasLoadedCommunityPage = false;
  @observable communityPage = {};
  @action loadCommunityPage({
    group_id,
    tag,
    query,
    top = 24,
    page = 1,
    ...rest
  }) {
    return loadDataHelper({
      store: this,
      agentKey: 'Community',
      key: 'CommunityPage',
      params: { group_id, tag, query, top, page, ...rest },
    });
  }

  @observable isLoadingThreads = false;
  @observable hasLoadedThreads = false;
  @observable threads = [];
  @action loadThreads({ page, size }) {
    return loadDataHelper({
      store: this,
      agentKey: 'Community',
      key: 'Threads',
      path: 'Threads',
      params: { page, size },
    });
  }

  @computed get dashboardItems() {
    const datedThreads =
      this.threads?.map((t) => ({
        CreatedOn: t.CreatedOnUtc,
        type: 'thread',
        data: t,
      })) ?? [];

    const newMembers =
      this.membersDirectory.AllMembersByName?.map((t) => ({
        CreatedOn: t.RegistrationDate ?? t.CreatedOnUtc,
        type: 'member',
        data: t,
      })) ?? [];

    const events =
      getStores().eventsStore.upcomingEvents?.map((t) => ({
        CreatedOn: t.PublishDate ?? t.CreatedOnUtc,
        type: 'event',
        data: t,
      })) ?? [];

    const articles =
      getStores().blogStore.blogPosts?.BlogPosts?.map((t) => ({
        CreatedOn: t.CreatedOnUtc,
        type: 'post',
        data: t,
      })) ?? [];

    const perks =
      getStores().contentStore.communityPerks?.map((t) => ({
        CreatedOn: t.CreatedOnUtc,
        type: 'discount',
        data: t,
      })) ?? [];

    const courses =
      getStores().coursesStore.coursesPage?.Courses?.map((t) => ({
        CreatedOn: t.Course.CreatedOn,
        type: 'course',
        data: t,
      })) ?? [];

    const orderedItems = _.orderBy(
      [
        ...datedThreads,
        ...newMembers,
        ...events,
        ...perks,
        ...courses,
        ...articles,
      ],
      'CreatedOn',
      'desc'
    );
    return orderedItems;
  }

  @observable isLoadingVideoRooms = false;
  @observable hasLoadedVideoRooms = false;
  @observable videoRooms = {
    Rooms: [],
  };
  @action loadVideoRooms() {
    return loadDataHelper({
      store: this,
      agentKey: 'Community',
      key: 'VideoRooms',
    });
  }

  @observable isLoadingAccessRoom = false;
  @observable hasLoadedAccessRoom = false;
  @observable accessRoom = null;
  @action loadAccessRoom(room) {
    this.accessRoom = null;
    return loadDataHelper({
      store: this,
      agentKey: 'Community',
      key: 'AccessRoom',
      params: room,
    });
  }

  @observable isLoadingAccessTeamRoom = false;
  @observable hasLoadedAccessTeamRoom = false;
  @observable accessTeamRoom = null;
  @action loadAccessTeamRoom(room) {
    this.accessTeamRoom = null;
    return loadDataHelper({
      store: this,
      agentKey: 'Community',
      key: 'AccessTeamRoom',
      params: room,
    });
  }

  @observable isLoadingAccessMyRoom = false;
  @observable hasLoadedAccessMyRoom = false;
  @observable accessMyRoom = null;
  @action loadAccessMyRoom(room) {
    this.accessMyRoom = null;
    return loadDataHelper({
      store: this,
      agentKey: 'Community',
      key: 'AccessMyRoom',
      params: room,
    });
  }

  @observable isLoadingSurveyPreviewPage = false;
  @observable hasLoadedSurveyPreviewPage = false;
  @observable surveyPreviewPage = null;
  @action loadSurveyPreviewPage(survey_guid) {
    return loadDataHelper({
      store: this,
      agentKey: 'Community',
      key: 'SurveyPreviewPage',
      params: survey_guid,
    });
  }

  @observable isLoadingSurveyPage = false;
  @observable hasLoadedSurveyPage = false;
  @observable surveyPage = null;
  @action loadSurveyPage(survey_guid) {
    return loadDataHelper({
      store: this,
      agentKey: 'Community',
      key: 'SurveyPage',
      params: survey_guid,
    });
  }

  @observable isSavingSurveyPage = false;
  @action saveSurveyPage() {
    this.surveyPage.Questions.map((question) => {
      question.SurveyAnswer.Value = question[`Custom${question.Id}`];
      if (question.SurveyAnswer.Value !== null) {
        if (Array.isArray(question.SurveyAnswer.Value)) {
          question.SurveyAnswer.Value = question.SurveyAnswer.Value.join();
        }
      }
      return question;
    });

    for (var key in this.surveyValidation) {
      if (this.surveyValidation.hasOwnProperty(key)) {
        const element = this.surveyValidation[key];
        if (!element.isValid) {
          const allErrors = Object.values(element.errors).flat(3).join(', ');

          return Promise.reject({
            validationErrors: allErrors,
          });
        }
      }
    }

    return saveDateHelper({
      store: this,
      agentKey: 'Community',
      key: 'SurveyPage',
      data: {
        returnUrl: '/',
        surveyRun: {
          Id: this.surveyPage.SurveyRun.Id,
        },
        questions: this.surveyPage.Questions,
      },
    });
  }

  @observable isLoadingFormPagePreviewPage = false;
  @observable hasLoadedFormPagePreviewPage = false;
  @observable formPagePreviewPage = null;
  @action loadFormPagePreviewPage(formPage_guid) {
    return loadDataHelper({
      store: this,
      agentKey: 'Community',
      key: 'FormPagePreviewPage',
      params: formPage_guid,
    });
  }

  @observable isLoadingFormPagePage = false;
  @observable hasLoadedFormPagePage = false;
  @observable formPagePage = null;
  @action loadFormPagePage(formPage_guid) {
    return loadDataHelper({
      store: this,
      agentKey: 'Community',
      key: 'FormPagePage',
      params: formPage_guid,
    });
  }

  @observable isSavingFormPagePage = false;
  @action saveFormPagePage() {
    this.formPagePage.Questions.map((question) => {
      question.FormPageAnswer.Value = question[`Custom${question.Id}`];
      if (question.FormPageAnswer.Value !== null) {
        if (Array.isArray(question.FormPageAnswer.Value)) {
          question.FormPageAnswer.Value = question.FormPageAnswer.Value.join();
        }
      }
      return question;
    });

    for (var key in this.formPageValidation) {
      if (this.formPageValidation.hasOwnProperty(key)) {
        const element = this.formPageValidation[key];
        if (!element.isValid) {
          const allErrors = Object.values(element.errors).flat(3).join(', ');

          return Promise.reject({
            validationErrors: allErrors,
          });
        }
      }
    }

    return saveDateHelper({
      store: this,
      agentKey: 'Community',
      key: 'FormPagePage',
      data: {
        returnUrl: '/',
        formPageRequest: { ...this.formPagePage.FormPageRequest },
        questions: this.formPagePage.Questions,
      },
    });
  }

  showBrowserNotificationForMessage = (message) => {
    const { appStore, authStore } = getStores();
    const { business } = appStore;
    const { customer } = authStore;
    const icon = `${business.NativeHomeUrlWithLanguage}/coworker/getAvatar/${message.CoworkerId}?w=192&h=192&mode=pad`;

    //Do not show notification if message is form logged in user
    if (message.CoworkerId == customer?.Id) return;

    const title = global.t(`New reply from {{FullName}}`, {
      FullName: message.CoworkerFullName,
    });
    const pathname = `/community/thread/${message.ThreadId}/${slugify(
      message.ThreadSubject
    )}#message-${message.Id}`;
    const url = `${business.HomeUrlWithLanguage}${pathname}`;

    appStore.loadNotifications().catch(err => console.error(`Can't load Notifications.`, err));

    let notification =
      window.Notification ||
      window.mozNotification ||
      window.webkitNotification;
    if (notification == null) return;

    let noty = new notification(business.Name, {
      body: title,
      dir: 'auto',
      lang: 'EN',
      tag: message.Id,
      requireInteraction: false,
      icon: icon,
    });
    noty.onclick = function () {
      window.open(url, '_blank').focus();
    };
  };

  showBrowserNotificationForThread = (thread) => {
    const { appStore, authStore } = getStores();
    const { business } = appStore;
    const { customer } = authStore;
    if (thread.CoworkerId == customer.Id) return;

    const icon = `${business.NativeHomeUrlWithLanguage}/coworker/getAvatar/${thread.CoworkerId}?w=192&h=192&mode=pad`;
    const title = global.t(`New conversation from {{FullName}}`, {
      FullName: thread.FullName,
    });
    const pathname = `/community/thread/${thread.Id}/${slugify(
      thread.Subject
    )}`;
    const url = `${business.HomeUrlWithLanguage}${pathname}`;

    appStore.loadNotifications().catch(err => console.error(`Can't load Notifications.`, err));

    let notification =
      window.Notification ||
      window.mozNotification ||
      window.webkitNotification;
    if (notification == null) return;

    let noty = new notification(business.Name, {
      body: title,
      dir: 'auto',
      lang: 'EN',
      tag: thread.Id,
      requireInteraction: false,
      icon: icon,
    });
    noty.onclick = function () {
      window.open(url, '_blank').focus();
    };
  };
}

export default CommunityStore;
