import React from 'react';
import { observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import CommunityBoardPage from 'pages/community/index';

const CommunityGroupPage = withCustomComponent('CommunityGroupPage')(
  observer(({ componentName }) => {
    return <CommunityBoardPage componentName={componentName} />;
  })
);

export default CommunityGroupPage;
