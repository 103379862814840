import { observable, action, computed, reaction, toJS } from 'mobx';
import { loadDataHelper } from './storeHelpers';
import moment from 'moment';
import StoreBase from './storeBase';
import _ from 'lodash';
import { getShiftsFromResources } from 'env/utils/shiftsHelper';
import { getStores } from './stores';
import {
  bookingsFeatures,
  bookingsFloorPlanTypes,
} from 'env/bookingsResources';

export const types = [
  { id: 1, name: 'Offices' },
  { id: 2, name: 'DedicatedDesks' },
  { id: 3, name: 'HotDesks' },
  { id: 4, name: 'Others' },
];

export const featureNames = [
  'Projector',
  'Internet',
  'ConferencePhone',
  'StandardPhone',
  'WhiteBoard',
  'LargeDisplay',
  'Catering',
  'TeaAndCoffee',
  'Drinks',
  'SecurityLock',
  'CCTV',
  'VoiceRecorder',
  'AirConditioning',
  'Heating',
  'NaturalLight',
  'StandingDesk',
  'QuietZone',
  'WirelessCharger',
  'PrivacyScreen',
  'Soundproof',
];

class FloorPlanStore extends StoreBase {
  constructor({ data, host } = {}) {
    super({ data, host });

    this.fromTime = moment().add(1, 'hour').startOf('hour').toDate();
    this.toTime = moment(this.fromTime).clone().add(1, 'hour').toDate();
  }

  @observable showTypeFilters = false;
  @action setShowTypeFilters(showTypeFilters) {
    this.showTypeFilters = showTypeFilters;
  }

  @action clearTypeFilters() {
    types.forEach((type) => {
      this[`show${type.name}`] = true;
    });
  }

  @action clearFeatureFilters() {
    featureNames.forEach((feature) => {
      this[`show${feature}`] = false;
    });
  }

  @observable showProjector = false;
  @observable showInternet = false;
  @observable showConferencePhone = false;
  @observable showStandardPhone = false;
  @observable showWhiteBoard = false;
  @observable showLargeDisplay = false;
  @observable showCatering = false;
  @observable showTeaAndCoffee = false;
  @observable showDrinks = false;
  @observable showSecurityLock = false;
  @observable showCCTV = false;
  @observable showVoiceRecorder = false;
  @observable showAirConditioning = false;
  @observable showHeating = false;
  @observable showNaturalLight = false;
  @observable showStandingDesk = false;
  @observable showQuietZone = false;
  @observable showWirelessCharger = false;
  @observable showPrivacyScreen = false;
  @observable showSoundproof = false;

  @observable showOffices = true;
  @observable showHotDesks = true;
  @observable showDedicatedDesks = true;
  @observable showOthers = true;

  @action updateTypeFilter(name, value) {
    this[`show${name}`] = value;
  }

  @observable fromTime = null;
  @observable toTime = null;
  @observable currentFloorPlan = null;
  @observable currentArea = null;

  @computed get types() {
    let groupedTypes = _(this.items)
      .filter('ItemType')
      .groupBy('ItemType')
      .map((items, itemType) => {
        return {
          itemType: {
            Name: itemType,
          },
          items,
        };
      })
      .value();

    return groupedTypes;
  }

  @computed get areas() {
    let groupedPlans = _(this.items)
      .filter('Area')
      .groupBy('Area')
      .map((items, area) => {
        return {
          area: {
            Name: area,
          },
          items,
        };
      })
      .value();

    return groupedPlans;
  }

  @computed get shifts() {
    return getShiftsFromResources(this.filteredItems, 'ResourceShifts');
  }

  @computed get filteredItems() {
    const stores = getStores();
    if (!stores) return [];
    const isFiltered = (item) => {
      for (let index = 0; index < featureNames.length; index++) {
        const feature = featureNames[index];
        if (this[`show${feature}`] && !item[`Resource${feature}`]) {
          return false;
        }
      }

      for (let index = 0; index < types.length; index++) {
        const type = types[index];
        if (!this[`show${type.name}`] && item.ItemType == type.id) {
          return false;
        }
      }
      return true;
    };

    const filteredRecords = this.items.filter((item) => {
      const r =
        isFiltered(item) && item.FloorPlanId == this.currentFloorPlan?.Id;

      return r;
    });

    const jsRecords = toJS(filteredRecords);
    // const basket = stores.checkoutStore.basket;
    // for (let index = 0; index < jsRecords.length; index++) {
    //   const item = jsRecords[index];

    //   const foundInBasket = _.find(
    //     basket,
    //     (bi) => bi.data.FloorPlanDeskId == item.Id
    //   );
    //   item.isInBasket = foundInBasket != null;
    // }

    return jsRecords;
  }

  // group coworkers that have a booking in the same resource
  @computed get groupedFilteredItems() {
    const grouped = [];
    const filteredItems = this.filteredItems;

    for (let i = 0; i < filteredItems.length; i++) {
      const curr = filteredItems[i];

      if (i > 0 && curr.Id === filteredItems[i - 1].Id) {
        continue;
      }

      let tempItem = {
        Coworkers: [],
        ...curr,
      };

      for (let j = i; j < filteredItems.length; j++) {
        const next = filteredItems[j];

        // if CoworkerFullName is not null and current item id is equal to the next one
        if (next && next.CoworkerFullName && curr.Id === next.Id) {
          tempItem.Coworkers.push({
            CoworkerFullName: next.CoworkerFullName,
            CoworkerTeamNames: next.CoworkerTeamNames,
            CoworkerId: next.CoworkerId,
          });
        }
      }

      grouped.push(tempItem);
    }

    return grouped;
  }

  @computed get floorPlans() {
    let groupedPlans = _(this.items)
      .groupBy('FloorPlanName')
      .map((items, floorPlan) => {
        return {
          floorPlan: {
            ArchilogicUniqueId: items[0].FloorPlanArchilogicUniqueId,
            Capacity: items[0].FloorPlanCapacity,
            Name: items[0].FloorPlanName,
            Id: items[0].FloorPlanId,
            Items: items,
            FilteredItems: this.filteredItems,
          },
          items,
        };
      })
      .value();

    return groupedPlans;
  }

  @action selectFloorPlan(floorPlan) {
    this.currentFloorPlan = floorPlan;
  }

  @action selectDates(dates) {
    Object.keys(dates).forEach((key) => {
      this[key] = dates[key];
    });
  }

  @observable shiftId;
  @action selectShift(shiftId) {
    const shift = this.shifts[shiftId];
    if (!shift) return;

    const shiftStart = shift.start.clone();
    const shiftEnd = shift.end.clone();

    const start = moment(this.fromTime).clone();
    start.set('minutes', shiftStart.get('minutes'));
    start.set('hours', shiftStart.get('hours'));

    const end = moment(this.toTime).clone();
    end.set('minutes', shiftEnd.get('minutes'));
    end.set('hours', shiftEnd.get('hours'));

    this.fromTime = start.toDate();
    this.toTime = end.toDate();

    this.shiftId = parseInt(shiftId);

    return Promise.resolve();
  }

  @observable itemsFetchedAt = null;
  @observable isLoadingItems = false;
  @observable hasLoadedItems = false;
  @observable items = [];
  @action loadItems() {
    return loadDataHelper({
      store: this,
      agentKey: 'FloorPlans',
      key: 'Items',
      params: {
        fromTime: moment(this.fromTime).format('YYYY-MM-DDTHH:mm'),
        toTime: moment(this.toTime).format('YYYY-MM-DDTHH:mm'),
      },
    }).then(
      action((items) => {
        if (this.shifts.length > 0 && this.shiftId == null) {
          this.selectShift(this.shifts[0].id).then(() => this.loadItems());
        }
      })
    );
  }
}
export default FloorPlanStore;
