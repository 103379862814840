import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import Link from 'next/link';
import { routes } from 'env/routes';
import withCustomComponent from 'ui/components/withCustomComponent';

import { CommunityBoardItemReputation } from 'ui/_pages/community/list/item/CommunityBoardItemReputation';
import { CommunityBoardItemComment } from 'ui/_pages/community/list/item/CommunityBoardItemComment';

@withCustomComponent('VirtualMessageCard')
@withTranslation()
@inject('appStore')
@observer
class VirtualMessageCard extends Component {
  componentDidMount() {}

  render() {
    const { t, appStore, dummyNo } = this.props;
    const { business } = appStore;

    const dummyThreadArray = [
      {
        CoworkerId: 1004169224,
        FullName: 'Mark Smith',
        eventTime: '12 minutes ago',
        eventAction: 'replied to your thread in',
        eventTitle: 'How do you start your morning routine.',
        eventMessage: '“That sound like a good way to kickoff the day!”',
        MessageCount: 7,
        LikeCount: 0,
      },
      {
        CoworkerId: 1004169223,
        FullName: 'Susan Sarandon',
        eventTime: '3 days ago',
        eventAction: 'commented in article',
        eventTitle: '7 lessons I’ve learned from practicing yoga.',
        eventMessage:
          '“Thanks for the great insights. I’m off to buy yoga pants!”',
        MessageCount: 4,
        LikeCount: 1,
      },
      {
        CoworkerId: 1004222264,
        FullName: 'Alan Lively',
        eventTime: '4 days ago',
        eventAction: 'replied to your thread in',
        eventTitle: 'How do you start your morning routine.',
        eventMessage: '“That sound like a good way to kickoff the day!”',
        MessageCount: 8,
        LikeCount: 3,
      },
    ];
    const thread = dummyThreadArray[dummyNo];

    return (
      <div
        data-component-name={this.props.componentName}
        className="virtual-message-card"
      >
        <div className="card h-auto p-16 p-md-24 mb-24">
          <header className="media">
            <Link
              href={{ pathname: routes.directory_member_by_id('[member_id]') }}
              as={{
                pathname: routes.directory_member_by_id({
                  Id: thread.CoworkerId,
                  FullName: thread.FullName,
                }),
              }}
            >
              <a
                className="avatar avatar--48"
                style={{
                  backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${thread.CoworkerId}?h=96&w=96&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png)`,
                }}
              ></a>
            </Link>
            <div className="media-body d-flex justify-content-between pl-16">
              <div className="text-gray-600">
                <div className="fw-500">
                  <a href="#">{thread.FullName}</a> {thread.eventAction}{' '}
                  <a href="#">{thread.eventTitle}</a>
                </div>
                <div className="fs-14 lh-14">{thread.eventTime}</div>
              </div>
              <div className="dropdown">
                <button
                  className="btn btn-single-icon btn-white dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <i className="icon-more"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <button className="dropdown-item">Mute message</button>
                  <button className="dropdown-item">Delete message</button>
                </div>
              </div>
            </div>
          </header>
          <div className="d-block pt-24 pb-20">
            <h4 className="mb-20">{thread.eventMessage}</h4>
            <div className="pb-8 border-bottom">
              <CommunityBoardItemReputation thread={thread} />
            </div>
          </div>
          <footer>
            <CommunityBoardItemComment thread={thread} />
          </footer>
        </div>
      </div>
    );
  }
}

export default VirtualMessageCard;
