import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const CustomHtmlHead = withCustomComponent('CustomHtmlHead')(
  withRouter(
    observer(({}) => {
      const { t } = useTranslation();

      return <></>;
    })
  )
);
