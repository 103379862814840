import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import config from './config';
import moment from 'moment';
import { getQueryString } from './utils/strings';
import { routes } from './routes';
import { TOKEN_NAME } from './stores/authStore';
import Cookies from 'universal-cookie';
import { isServer } from './ssr/ServerSideRenderManager';
import packageInfo  from 'package.json';
import i18n from './i18n';
const cookies = new Cookies();
const superagent = superagentPromise(_superagent, global.Promise);
const PLATFORM_BASE_URL = config.platformRoot;
const CLIENT_ID_PREFIX = 'nexudus.portal.';

class Agent {
  constructor(host) {
    this.host = host;

    const apiUrl =
      config.apiRoot ??
      (this.host ? `https://${this.host.split(':')[0]}:443` : '');

    this.requests = {
      del: (url, prefix = '/en') =>
        superagent
          .del(`${apiUrl}${prefix}${url}`)
          .use((req) => globalInterceptorPre('delete', req, url))
          .use(tokenPlugin)
          .use(contentTypePlugin)
          .end(handleErrors)
          .then((data) => globalInterceptorPost('delete', data, url))
          .then(responseBody)
          .catch(catchErrors),
      get: (url, prefix = '/en') =>
        superagent
          .get(`${apiUrl}${prefix}${url}`)
          .type('application/json')
          .use((req) => globalInterceptorPre('get', req, url))
          .use(contentTypePlugin)
          .use(tokenPlugin)
          .end(handleErrors)
          .then((data) => globalInterceptorPost('get', data, url))
          .then(responseBody)
          .catch(catchErrors),
      getBlob: (url, prefix = '/en') =>
        superagent
          .get(`${apiUrl}${prefix}${url}`)
          .responseType('blob')
          .type('application/json')
          .use((req) => globalInterceptorPre('get', req, url))
          .use(contentTypePlugin)
          .use(tokenPlugin)
          .end(handleErrors)
          .then((data) => globalInterceptorPost('get', data, url))
          .then(responseBody)
          .catch(catchErrors),
      put: (url, body, prefix = '/en') =>
        superagent
          .put(`${apiUrl}${prefix}${url}`, body)
          .use((req) => globalInterceptorPre('put', req, url))
          .use(contentTypePlugin)
          .use(tokenPlugin)
          .end(handleErrors)
          .then((data) => globalInterceptorPost('put', data, url))
          .then(responseBody)
          .catch(catchErrors),
      send: (url, data, prefix = '/en') =>
        superagent
          .post(`${apiUrl}${prefix}${url}`)
          .send(data)
          .use((req) => globalInterceptorPre('post', req, url))
          .use(acceptPlugin)
          .use(tokenPlugin)
          .end(handleErrors)
          .then((data) => globalInterceptorPost('post', data, url))
          .catch(catchErrors),
      post: (url, body, prefix = '/en') =>
        superagent
          .post(`${apiUrl}${prefix}${url}`, body)
          .use((req) => globalInterceptorPre('post', req, url))
          .use(contentTypePlugin)
          .use(tokenPlugin)
          .end(handleErrors)
          .then((data) => globalInterceptorPost('post', data, url))
          .then(responseBody)
          .catch(catchErrors),
    };
    const requests = this.requests;
    /* helpers */

    const getPublic = (url) => requests.get(url, '/api/public');
    const putPublic = (url, body) => requests.put(url, body, '/api/public');

    const handleErrors = (err) => {
      return err;
    };

    const globalInterceptorPre = (method, req, url) => {
      if (!isServer()) {
        if (window?.requestInterceptors?.pre) {
          window.requestInterceptors.pre({ method, request: req, url });
        }
      }
    };

    const globalInterceptorPost = (method, data, url) => {
      if (!isServer()) {
        if (window?.requestInterceptors?.post) {
          window.requestInterceptors.post({ method, data, url });
        }
      }

      return data;
    };

    const catchErrors = (err) => {
      const res = err.response;
      if (err.status == 401) {
        // These urls should not trigger a redirection to the login page as they are
        // used in the navigation bar to show/hide different elements
        var url = res.error.url?.toLowerCase();
        var token = cookies.get(TOKEN_NAME);
        if (
          url.indexOf('dismissnotifications') == -1 &&
          url.indexOf('communityperks') == -1 &&
          url.indexOf('blog?') == -1 &&
          url.indexOf('faq') == -1 &&
          url.indexOf('events/my') == -1 &&
          url.indexOf('events?') == -1 &&
          url.indexOf('team/profiles') == -1 &&
          url.indexOf('coworker/profiles') == -1 &&
          url.indexOf('profile/visitors') == -1 &&
          url.indexOf('store/productsjson') == -1 &&
          url.indexOf('floorplans/items') == -1 &&
          url.indexOf('_resource=user') == -1 &&
          url.indexOf('_resource=coworker') == -1 &&
          url.indexOf('_resource=resources') == -1 &&
          url.indexOf('publicresources') == -1 &&
          url.indexOf('_resource=resourcegroups') == -1 &&
          url.indexOf('directory/members') == -1 &&
          url.indexOf('directory/teams') == -1 &&
          url.indexOf('_resource=referaldiscounts') == -1
        ) {
          //Redirect to logging page if not logged in
          var token = cookies.get(TOKEN_NAME);
          var isHome = url.indexOf('publichome/home') > -1;
          if (isHome || !token) {
            if (window.location.pathname.indexOf('/login') == -1) {
              window.location.pathname.length > 1
                ? (window.location = `${
                    routes.login
                  }?returnurl=${encodeURIComponent(window.location.pathname)}`)
                : (window.location = `${routes.login}`);
              return Promise.reject();
            }
          }
        }
      }
      throw err;
    };

    const responseBody = (res) => res.body;

    const tokenPlugin = (req) => {
      var token = cookies.get(TOKEN_NAME);
      req.set('authorization', `Bearer ${token}`);
    };

    const contentTypePlugin = (req) => {
      req.set('nx-app-version', packageInfo.version);
      req.set('accept-language', i18n.language ?? 'en');
      req.set('accept', `application/json`);
      req.set('content-type', `application/json`);
    };

    const acceptPlugin = (req) => {
      req.set('accept', `application/json`);
      req.set('accept-language', i18n.language ?? 'en');
    };

    const clientIdPlugin = (req, email) => {
      req.set('client_id', `${CLIENT_ID_PREFIX}${email}`);
    };

    const getFormData = (object) => {
      const formData = new FormData();
      Object.keys(object).forEach((key) => formData.append(key, object[key]));
      return formData;
    };

    this.Auth = {
      exchange: (token) =>
        superagent
          .post(`${PLATFORM_BASE_URL}/api/sys/users/exchange?token=${token}`)
          .end(handleErrors)
          .then(responseBody),
      exists: (email) =>
        requests.get(`/user/exists?email=${encodeURIComponent(email)}`),
      getMe: () => requests.get('/profile?_resource=User'),
      getNotifications: () => requests.get('/coworker/notifications'),
      clearNotifications: () => requests.get('/coworker/clearNotifications'),
      DismissNotifications: (entityId) =>
        requests.get(`/coworker/dismissNotifications/?entityId=${entityId}`),
      getSignupPage: (params) =>
        requests.get(`/signup?${getQueryString(params)}`),
      tour: () => requests.get(`/tour`),
      getProfilePage: () => requests.get('/profile'),
      getCustomer: () => requests.get('/profile?_resource=Coworker'),
      getProfiles: () => requests.get('/coworker/profiles'),
      selectProfile: (coworkerId) =>
        requests.post('/coworker/setCurrentProfile?', {
          coworkerId,
        }),
      selectLanguage: (culture) =>
        requests.post(`/coworker/setCurrentLanguage?culture=${culture}`),
      getTeams: () => requests.get('/profile?_resource=Coworker.TeamsList'),

      getTeamProfiles: () =>
        requests.get('/team/profiles').then((data) =>
          data.map((profile) => ({
            Team: { ...profile.Team, AllTeamMembers: profile.AllTeamMembers },
          }))
        ),
      saveTeamProfile: (team) =>
        requests.post(`/team/profile/${team.Id}`, {
          team,
          base64teamLogo: team.base64teamLogo,
          base64TeamImage1: team.base64TeamImage1,
          base64TeamImage2: team.base64TeamImage2,
          base64TeamImage3: team.base64TeamImage3,
        }),
      restore: (userGuid) => requests.get(`/signup/restore?token=${userGuid}`),
      getPayingMember: () =>
        requests.get('/profile?_resource=Coworker.PayingMember'),
      saveCustomer: (customer) =>
        customer && customer.Id > 0
          ? requests.post('/profile', customer)
          : requests.post('/signup?_resource=.', customer),
      saveCustomerWithUser: (customerWithUser) =>
        customerWithUser.Coworker && customerWithUser.Coworker.Id > 0
          ? requests.post('/profile', customerWithUser)
          : requests.post('/signup?_resource=.', customerWithUser),
      getResetPassword: ({ email, businessId }) => {
        const url = `${PLATFORM_BASE_URL}/api/sys/users/requestPasswordReset`;
        return superagent
          .post(url, { email, businessId })
          .end(handleErrors)
          .then(responseBody);
      },
      setPassword: (token, newPassword) => {
        const body = {
          token,
          newPassword,
        };
        const url = `${PLATFORM_BASE_URL}/api/sys/users/setPassword`;
        return superagent.put(url, body).end(handleErrors).then(responseBody);
      },
      login: (email, password, totp = '') => {
        const body = `grant_type=password&username=${encodeURIComponent(
          email
        )}&password=${encodeURIComponent(password)}&totp=${totp}`;
        const url = `${apiUrl}/api/token`;
        return superagent
          .post(url, body)
          .use((req) => clientIdPlugin(req, email))
          .end(handleErrors)
          .then(responseBody);
      },

      sendMagicLink: (email) => {
        const url = `${PLATFORM_BASE_URL}/api/sys/users/SendMagicLink?email=${encodeURIComponent(
          email
        )}`;
        return superagent.get(url).end(handleErrors).then(responseBody);
      },

      refreshToken: (email) => {
        const body = `grant_type=refresh_token&refresh_token=${authStore.refreshToken}`;
        const url = `${PLATFORM_BASE_URL}/api/token`;
        return superagent
          .post(url, body)
          .use((req) => clientIdPlugin(req, email))
          .end(handleErrors)
          .then(responseBody);
      },
      getTeamMemberAccessToken: (coworkerId) =>
        requests.post('/team/getTeamMemberAccessToken', { coworkerId }),
    };

    this.Business = {
      getHomePage: () => requests.get('/publichome/home?_depth=3'),
      getLanguages: () => requests.get('/legal/privacy?_resource=Languages'),
      getLegalStatus: () => requests.get('/legal/status'),
      getVisitorTerms: (visitorGuid) =>
        requests.get(`/legal/visitor?guid=${visitorGuid}`),
      getVisitorTermsAccept: (visitorGuid) =>
        requests.get(`/legal/visitorAccept?guid=${visitorGuid}`),
      getAcceptTerms: () => requests.get('/termsAndConditions/accept'),
      getConfiguration: () => requests.post('/mobileapp/Configuration'),
      getReferralDiscounts: () => requests.get('/?_resource=ReferalDiscounts'),
      getCustomPages: () => requests.post('/mobileapp/CustomPages'),
      getSendReferralInvite: (params) =>
        requests.post(`/referrals/refer?`, params),
      getCustomPage: ({ password, ...rest }) => {
        rest.raw = true;
        return requests.post(`/page?${getQueryString(rest)}`, { password });
      },
      getTimezones: () => requests.post('/mobileapp/timezones'),
      getCountries: () => requests.post('/mobileapp/countries'),
      getBusiness: () => requests.get('/business'),
      getMessages: () => requests.get('/user/messages'),
      getBusinesses: ({ includeRoot = false }) =>
        requests.get(`/business/all?_depth=1&includeRoot=${includeRoot}`),
      getNetworks: () => requests.get('/business/networks?_depth=3'),
      getCustomFields: () => requests.get('/profile'),
      getSignupCustomFields: (params) =>
        requests.get(`/signup?${getQueryString(params)}`),
      getTourCustomFields: () => requests.get('/tour'),
      getProfileTags: () => requests.get('/signup'),
      getTileAction: (tileId) =>
        requests.get(`/api/tiles/execute/${tileId}`, ''),
    };

    this.Support = {
      getDepartments: () => requests.get('/support/newmessage?_depth=3'),
      getMessages: () => requests.get('/support?_depth=3'),
      getMessage: (id) => requests.get(`/support/message/${id}?_depth=3`),
      submitReply: (reply) => requests.post('/support/newcomment', reply),
      submitNewContactMessage: (newMessage) =>
        requests.post('/contact/NewUserMessage', newMessage),
      submitNewMessage: (newMessage) =>
        requests.post('/support/newMessage', newMessage),
      closeRequest: (id) => requests.post(`/support/closerequest/${id}`),
    };

    this.Billing = {
      getPricePlanGroups: () => requests.get('/?_resource=PricePlanGroups'),
      getPricePlans: ({ tariffGuid, customer }) =>
        customer
          ? requests.get(
              `/profile/tariff?_depth=3&tariffGuid=${
                tariffGuid ? tariffGuid : ''
              }`
            )
          : requests.get(`/publichome/home?_depth=3`),
      getInvoices: () => requests.get('/invoices?_depth=3'),
      getInvoicesPage: () => requests.get('/invoices?_depth=3'),
      getPurchaseHistory: () =>
        requests.get('/invoices/purchaseHistory?_depth=3'),
      getBenefits: () => requests.get('/allowances/plans?_depth=4'),
      getTeamBenefits: () => requests.get('/team/kpi'),
      getProducts: () => requests.get('/allowances/plans?_depth=4'),
      getStoreProducts: ({ selectedPlans, discountCode, productId } = {}) =>
        requests.post(
          `/store/productsJson?&productId=${
            productId ? productId : ''
          }&discountCode=${discountCode ? discountCode : ''}`,
          { selectedPlans }
        ),
      purchase: ({ products, discountCode, businessGuid }) =>
        requests.post(`/store/purchase`, {
          products,
          discountCode,
          businessGuid,
        }),
      useExistingSpreedlyToken: () =>
        requests.get('/payments/useExistingSpreedlyToken'),
      getNonCancelledContracts: () =>
        requests.get('/allowances/plans?_depth=4'),
      getActiveContracts: () => requests.get('/allowances/plans?_depth=3'),
      getProposalView: (proposal_guid) =>
        requests.get(`/proposal/view/${proposal_guid}?_depth=5`),
      getSignaturePage: (document_id) =>
        requests.get(`/legal/esignFrame/${document_id}`),
      getAcceptProposal: (proposal_guid) =>
        requests.get(`/proposal/accept/${proposal_guid}`),
      getContract: (contractId) =>
        requests.get(`/profile/changecontract/${contractId}?_depth=4`),
      saveNewContract: ({ tariffGuid, startDate, businessGuid }) =>
        requests.post(
          `/profile/newContract/?tariffGuid=${tariffGuid}&startDate=${moment(
            startDate
          ).format('YYYY-MM-DD')}&businessGuid=${businessGuid}`
        ),
      saveContract: ({ contractId, tariffId }) =>
        requests.get(
          `/profile/ChangeContractDo/?tariffId=${tariffId}&id=${contractId}`
        ),
      cancelContract: (contract) =>
        requests.post(`/profile/CancelContractDo/`, {
          contract,
        }),
      getMetrics: (teamId) =>
        requests.get(`/coworker/metrics?teamId=${teamId}`),
    };

    this.Bookings = {
      getUpcomingBookings: () => requests.get('/bookings/my?_depth=3'),
      getTeamBookings: () => requests.get('/bookings/my?_depth=3'),
      getBooking: (bookingId) =>
        requests.get(`/bookings/BookingJson/${bookingId}`),
      getBookingPrice: (booking) =>
        requests.post('/bookings/getbookingprice', booking).catch(() => {
          return {
            IsAvailable: false,
            Message:
              'Could not get a price for this booking. Please try again later.',
          };
        }),
      saveBookingToUpdate: (booking) =>
        requests.post('/bookings/bookingJson', booking),
      saveBookingToCreate: (booking) =>
        requests.post('/bookings/newBookingJson', booking),
      deleteBooking: (bookingId) =>
        requests.post(`/bookings/deletejson/${bookingId}`),
      getBookings: (params) =>
        requests.get(
          `/bookings/fullCalendarBookings?${getQueryString(params)}`
        ),
      getResourceSearch: (params) =>
        requests.get(`/bookings/search?${getQueryString(params)}`),
      getResources: () => requests.get(`/publicresources?_depth=3`),
      getResourceGroups: () =>
        requests.get(`/bookings/search?_resource=ResourceGroups`),
      getMyVisitors: () => requests.get(`/profile/visitors`),
      getPreviousVisitors: () => requests.get(`/profile/visitors`),
      saveNewVisitor: (visitor) =>
        requests.post('/profile/newVisitor', visitor),
      deleteVisitor: (visitor) =>
        requests.get(`/profile/DeleteVisitor?id=${visitor.Id}`),
      getResourceProducts: ({ bookingId, resourceId }) =>
        requests.get(
          `/bookings/getResourceProducts?resourceId=${resourceId}&bookingId=${bookingId}`
        ),
      getAvailabilityAt: ({ customer, ...rest }) =>
        customer
          ? requests.get(
              `/bookings/GetAvailabilityAtWithUser?${getQueryString(rest)}`
            )
          : requests.get(`/bookings/GetAvailabilityAt?${getQueryString(rest)}`),
    };

    this.Directory = {
      getMembersDirectory: (params) =>
        requests.get(`/directory/members?${getQueryString(params)}`),
      getTeamsDirectory: (params) =>
        requests.get(`/directory/teams?${getQueryString(params)}`),
      getSimpleSearch: () => requests.get(`/directory/simplesearch`),
      getCustomerPage: ({ member_id, slug, ...rest }) =>
        requests.get(
          `/directory/profile/${member_id}/${slug}?${getQueryString(rest)}`
        ),
      getTeamPage: ({ team_id, slug, ...rest }) =>
        requests.get(
          `/directory/team/${team_id}/${slug}?${getQueryString({
            ...rest,
            _depth: 3,
          })}`
        ),
      getCheckedInMetrics: () => requests.get(`/directory/checkedInMetrics`),
    };

    this.Deliveries = {
      getMyDeliveries: () => requests.get('/deliveries'),
    };

    this.Files = {
      getMyFiles: () => requests.get('/datafiles'),
      getFile: (file_id) => requests.getBlob(`/datafiles/GetFile/${file_id}`),
    };

    this.Blog = {
      deleteComment: (commentId) =>
        requests.post(`/blog/deleteComment?id=${commentId}`),
      saveNewComment: (data) => requests.post(`/blog/newcomment`, data),
      getBannerBlogPosts: () => requests.get(`/?_resource=BannerBlogPosts`),
      getBlogPostPage: (post_id) =>
        requests.get(`/blog/read/${post_id}/title?_depth=4`),
      getBlogPosts: ({ query, size = 12, page = 1, categoryId }) =>
        !categoryId
          ? requests.get(
              `/blog?size=${size}&page=${page}&_depth=4${
                query ? `&query=${query}` : ''
              }`
            )
          : requests.get(
              `/blog/index/${categoryId}/category?size=${size}&page=${page}&_depth=4${
                query ? `&query=${query}` : ''
              }`
            ),
    };

    this.Courses = {
      getCoursesPage: (params) =>
        getPublic(`/courses?${getQueryString(params)}`),
      getCoursePage: (course_id) => getPublic(`/courses/${course_id}/summary`),
      getMyCourses: (params) =>
        getPublic(`/courses/my?${getQueryString(params)}`),
      getSignUp: (course_id) => getPublic(`/courses/${course_id}/signup`),
      getLessonsPage: (course_id) => getPublic(`/courses/${course_id}/lessons`),
      getLessonPage: ({ course_id, lesson_id }) => {
        return getPublic(`/courses/${course_id}/lessons/${lesson_id}`);
      },
      putCompleteLesson: (course_id, lesson_guid) => {
        return putPublic(
          `/courses/${course_id}/lessons/${lesson_guid}/complete`
        );
      },
      saveNewComment: (data) => requests.post(`/courses/newcomment`, data),
      deleteComment: (commentId) =>
        requests.post(`/courses/deleteComment?id=${commentId}`),
      saveNewLessonComment: (data) =>
        requests.post(`/courses/lessons/newcomment`, data),
      deleteLessonComment: (commentId) =>
        requests.post(`/courses/lessons/deleteComment?id=${commentId}`),
    };

    this.Community = {
      getVideoRooms: () => requests.get(`/videoRooms`),
      getAccessRoom: (room) =>
        requests.get(`/VideoRooms/Stream/${room.Id}/access`),
      getAccessTeamRoom: (room) =>
        requests.get(`/VideoRooms/TeamStream/${room.Id}/access`),
      getAccessMyRoom: (room) => requests.get(`/VideoRooms/MyStream/access`),
      followThread: (thread_id) =>
        requests.post(`/community/FollowThread?id=${thread_id}`),
      unFollowThread: (thread_id) =>
        requests.post(`/community/UnFollowThread?id=${thread_id}`),
      muteThread: (thread_id) =>
        requests.post(`/community/MuteThread?id=${thread_id}`),
      unMuteThread: (thread_id) =>
        requests.post(`/community/UnMuteThread?id=${thread_id}`),
      likeThread: (thread_id) =>
        requests.post(`/community/LikeThread?id=${thread_id}`),
      unLikeThread: (thread_id) =>
        requests.post(`/community/UnLikeThread?id=${thread_id}`),
      likeMessage: (message_id) =>
        requests.post(`/community/LikeMessage?id=${message_id}`),
      unLikeMessage: (message_id) =>
        requests.post(`/community/UnLikeMessage?id=${message_id}`),
      deleteMessage: (message_id) =>
        requests.post(`/community/DeleteMessage?id=${message_id}`),
      deleteThread: (thread_id) =>
        requests.post(`/community/DeleteThread?id=${thread_id}`),
      getCommunityStartPage: ({ ...rest }) =>
        requests.get(`/community/start?${getQueryString(rest)}`),
      getCommunityPage: ({ group_id, me, privateThreads, ...rest }) =>
        privateThreads
          ? requests.get(`/community/private?_depth=3&${getQueryString(rest)}`)
          : me
          ? requests.get(`/community/me?_depth=3&${getQueryString(rest)}`)
          : !group_id
          ? requests.get(`/community?_depth=3&${getQueryString(rest)}`)
          : requests.get(
              `/community/group/${group_id}?_depth=3&${getQueryString(rest)}`
            ),
      getThreadPage: ({ thread_id, top = 50, page = 1 }) =>
        requests.get(
          `/community/thread/${thread_id}/title?top=${top}&page=${page}`
        ),
      getThreads: ({ page = 1, size = 10 }) =>
        requests.get(`/community?top=${size}&page=${page}`),
      saveNewCommunityBoardReply: ({ thread_id, message }) =>
        requests.post(`/conversations/postmessage/${thread_id}`, message),
      saveSurveyPage: (surveyPage) =>
        requests.post(`/survey/submit`, surveyPage),
      saveFormPagePage: (formPage) =>
        requests.post(`/formPage/submit`, formPage),
      saveNewCommunityBoardMessage: (data) => {
        const object = getFormData(data);
        const formData = new FormData();

        formData.append(`Group.Id`, data.GroupId);
        formData.append('MessageToCoworkerId', data.MessageToCoworkerId);
        for (var pair of object.entries()) {
          formData.append(`CommunityThread.${pair[0]}`, pair[1]);
        }
        const files = window.uploadStaging.NewCommunityBoardMessageFiles;
        if (files && files.entries) {
          for (var pair of files.entries()) {
            formData.append('files', pair[1]);
          }
        }
        return requests.send(`/conversations/start`, formData);
      },
      getSurveyPage: (survey_guid) =>
        requests.get(`/survey/questionnaire/${survey_guid}?_depth=3`),
      getSurveyPreviewPage: (survey_guid) =>
        requests.get(`/survey/preview/${survey_guid}?_depth=3`),

      getFormPagePage: (formPage_guid) =>
        requests.get(`/formPage/questionnaire/${formPage_guid}?_depth=3`),
      getFormPagePreviewPage: (formPage_guid) =>
        requests.get(`/formPage/preview/${formPage_guid}?_depth=3`),
    };

    this.Events = {
      getEventsPage: (params) =>
        params.categoryId
          ? requests.get(
              `/events/index/${
                params.categoryId
              }/category?_depth=3&${getQueryString(params)}`
            )
          : requests.get(`/events?_depth=3&${getQueryString(params)}`),
      getEventPage: (event_id) =>
        requests.get(`/events/view/${event_id}/title?_depth=4`),
      getEventsCalendar: (params) =>
        requests.get(`/bookings/fullCalendarEvents?${getQueryString(params)}`),
      getEventTicketsPage: (event_id) =>
        requests.get(`/events/tickets/${event_id}/title?_depth=3`),
      getBannerEvents: () => requests.get(`/?_resource=BannerEvents`),
      getUpcomingEvents: () => requests.get('/events?_depth=3'),
      getMyEvents: () => requests.get('/events/my?_depth=3'),
      deleteComment: (commentId) =>
        requests.post(`/events/deleteComment?id=${commentId}`),
      cancelTicket: (ticketId) =>
        requests.get(`/events/cancelTicket?id=${ticketId}`),
      sendTicket: (ticketId) =>
        requests.get(`/events/sendTicket?id=${ticketId}`),
      saveNewComment: (data) => requests.post(`/events/newcomment`, data),
    };

    this.Content = {
      getFaqArticles: () => requests.get(`/faq`),
      getCommunityPerks: () => requests.get(`/communityperks`),
    };

    this.Basket = {
      getItems: ({ fromTime, toTime }) =>
        requests.get(
          `/floorplans/items?fromTime=${fromTime}&toTime=${toTime}&includeWeekends=true`
        ),
      getInvoicePreview: (basket) =>
        requests.post('/basket/PreviewInvoice', basket),
      getBasketInvoice: (basket) =>
        requests.post('/basket/CreateInvoice', basket),
      getPostItems: (basket) => requests.post('/basket/PostItems', basket),
    };

    this.FloorPlans = {
      getItems: ({ fromTime, toTime }) =>
        requests.get(
          `/floorplans/items?fromTime=${fromTime}&toTime=${toTime}&includeWeekends=true`
        ),
    };
  }
}

export default Agent;
