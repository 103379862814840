import React, { Component } from 'react';
import LoadingSpinner from 'ui/components/LoadingSpinner';

export default class LoadingLayout extends Component {
  render() {
    return (
      <div style={{ marginTop: 20 }}>
        <LoadingSpinner />
      </div>
    );
  }
}
