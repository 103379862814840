import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import Link from 'next/link';
import { routes } from 'env/routes';
import withCustomComponent from 'ui/components/withCustomComponent';
import TurndownService from 'turndown';
import ReactMarkdown from 'react-markdown';
const turndownService = new TurndownService();
@withCustomComponent('LatestConversations')
@withTranslation()
@inject('appStore', 'authStore', 'communityStore')
@observer
class LatestConversations extends Component {
  componentDidMount() {
    const { communityStore } = this.props;
    communityStore.loadThreads({ page: 1, size: 4 }).catch((ex) => null);
  }

  render() {
    const { t, appStore, communityStore } = this.props;
    const { business } = appStore;
    const { threads } = communityStore;

    if (!(threads?.length === 0)) return <></>;
    return (
      <div data-component-name={this.props.componentName}>
        <h2 className="dashboard-body__title">{t('Latest conversations')}</h2>

        <div className="wall-list__wrapper">
          <ul className="row wall-list">
            {threads.map((thread, i) => (
              <li
                key={i}
                className="col-xxs-12 col-xs-6 col-sm-6 col-md-3 wall-list__item"
              >
                <div className="wall-list__head">
                  <span
                    className="avatar avatar--rounded wall-list__avatar"
                    href="#"
                  >
                    <img
                      src={`${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${thread.CoworkerId}?h=48&w=48&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}
                      alt={thread.FullName}
                    />
                  </span>
                  <time className="wall-list__time">
                    {moment(thread.PostedOnUtc).format('LL')}
                    <br />
                    {moment(thread.PostedOnUtc).format('LT')}
                  </time>
                </div>
                <div className="wall-list__body">
                  <span className="wall-list__name">{thread.FullName}</span>
                  <h3 className="h6 wall-list__title">
                    <Link
                      as={routes.thread_by_id(thread)}
                      href={routes.thread_by_id('[thread_id]')}
                    >
                      <a>{thread.Subject}</a>
                    </Link>
                  </h3>
                  <div className="wall-list__excerpt">
                    <ReactMarkdown
                      source={turndownService.turndown(
                        thread.Message.replace(/(?:\r\n|\r|\n)/g, '<br />')
                      )}
                    />
                  </div>
                </div>
                <div className="wall-list__foot">
                  <div className="wall-list__comments">
                    <i className="fa fa-comments i-space--right--small"></i>
                    {thread.MessageCount}
                  </div>
                  <Link
                    as={routes.thread_by_id(thread)}
                    href={routes.thread_by_id('[thread_id]')}
                  >
                    <a className="btn btn--primary  btn--xs">{t('Reply')}</a>
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <hr />
      </div>
    );
  }
}

export default LatestConversations;
