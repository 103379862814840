import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { SurveyQuestionList } from './SurveyQuestionList';

export const SurveyDetails = withCustomComponent('SurveyDetails')(
  inject(
    'appStore',
    'communityStore'
  )(
    withRouter(
      observer(({ surveyPage, saveSurvey, communityStore, componentName }) => {
        const { t } = useTranslation();
        const { isSavingSurveyPage } = communityStore;
        const canBeSubmitted = saveSurvey && !isSavingSurveyPage;
        const survey = surveyPage.SurveyRun.SurveyDrop;
        return (
          <div className="container">
            <div className="mw-640 mx-auto pt-48 pb-64">

              <form id="survey-form" data-component-name={componentName}>

                <SurveyQuestionList surveyPage={surveyPage} />     

                {!saveSurvey && (
                  <div className="fs-12 text-red mt-16 text-center">
                    {t(
                      'This is a preview of what a customer receiving this survey would see. You cannot submit this form.'
                    )}
                  </div>
                )}

                <div className="btn-group mt-24 justify-content-end">
                  <button
                    disabled={!canBeSubmitted}
                    onClick={(ev) => {
                      if (!canBeSubmitted) return;
                      ev.preventDefault();
                      saveSurvey();
                    }}
                    className="btn"
                  >
                    {isSavingSurveyPage ? (
                      <LoadingSpinner />
                    ) : (
                        t('Submit responses')
                      )}
                  </button>
                </div>                 

              </form>

            </div>
          </div>
        );
      })
    )
  )
);
