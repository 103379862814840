import React, { useEffect } from 'react';
import { withRouter } from 'next/router';
import { inject } from 'mobx-react';

export const ProgressCircle = inject('appStore')(
  withRouter(
    ({ appStore, percentage }) => {

      const updateProgressCircle = () => {
        const width = 24
        const height = 24;
        const radius = 12;
  
        const canvas = document.getElementById('progress-circle');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        const x = canvas.width / 2;
        const y = canvas.height / 2;
        const startRadianAngle = -90 * Math.PI / 180;
  
        // draw background circle
        ctx.beginPath();
        ctx.fillStyle = '#eeeeee';
        ctx.moveTo(x, y);
        ctx.arc(x, y, radius, 0, Math.PI * 2);
        ctx.fill();
  
        // draw progress circle
        ctx.beginPath();
        ctx.fillStyle = appStore.configuration['PrimaryWebColor'];
        ctx.moveTo(x, y);
        ctx.arc(x, y, radius, startRadianAngle, calculateEndAngle(percentage));
        ctx.fill();
      }
     
      const calculateEndAngle = (percentage) => {
        var angle = percentage / 100 * 360;
    
        const radianAngle = (angle - 90) * Math.PI / 180;
        return radianAngle;
      }

      useEffect(() => {
        updateProgressCircle();
      });

      return <canvas className="align-middle" id="progress-circle"></canvas>;
    }
  )
);
