import React, { useState } from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import moment from 'moment';
import { EventTicketsPageSignupForm } from 'ui/_pages/events/tickets/EventTicketsPageSignupForm';
export const EventTicketsPageSignupSection = withCustomComponent(
  'EventTicketsPageSignupSection'
)(
  inject(
    'appStore',
    'authStore',
    'eventsStore'
  )(
    withRouter(
      observer(({ authStore, componentName, appStore, eventsStore }) => {
        const { t } = useTranslation();
        const [showSignupForm, setShowSignupForm] = useState(false);
        const { eventTicketsPage } = eventsStore;
        const event = eventTicketsPage.Event;
        const { business } = appStore;
        const { customer } = authStore;
        return (
          <div data-component-name={componentName} className="my-24">

            <h5 className="">{t('Join or sign in to get tickets')}</h5>

            {!showSignupForm && (
              <div className="">
                <a
                  onClick={(ev) => {
                    ev.preventDefault();
                    authStore.createCustomer({
                      business,
                      createPassword: true,
                    });
                    setShowSignupForm(true);
                  }}
                  className="btn btn-outline px-24 mr-16"
                >
                  {t('Join')}
                </a>
                <Link
                  href={{
                    pathname: routes.login,
                    query: {
                      returnurl: routes.tickets_by_id(event),
                    },
                  }}
                >
                  <a className="btn">
                    {t('Sign in')}
                  </a>
                </Link>
              </div>
            )}

            {showSignupForm && (
              <div className="">
                <EventTicketsPageSignupForm customer={customer} />
              </div>
            )}

          </div>
        );
      })
    )
  )
);
