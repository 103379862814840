import React from 'react';
import GoogleButton from 'react-google-button';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const GoogleCalendarIntegrationSection = withCustomComponent(
  'GoogleCalendarIntegrationSection'
)(
  inject(
    'appStore',
    'authStore'
  )(
    withRouter(
      observer(({ authStore, appStore, componentName }) => {
        const { t } = useTranslation();
        const { business, configuration } = appStore;
        const { customer } = authStore;
        return (
          <tr>
              <td className="py-16" valign="middle">
                <img src="/img/google_calendar.png" />
              </td>
              <td className="w-100 pl-0 py-16" valign="middle">
                <h6 className="mb-4">{t('Google Calendar')}</h6>
                <p className="text-gray-700 fs-14 lh-14 mxw-500px mb-0">
                  {t('Connecting to your Google account will enable you to make and manage bookings from your own Google Calendar.')}
                </p>
              </td>
            <td className="text-left text-md-right py-16" valign="middle">
              {customer.HasGoogle && (
                <a
                  href={`${business.NativeHomeUrlWithLanguage}/integrations/google/signout`}
                  className="btn btn-icon btn-gray-light "
                >
                  <i className="icon-disconnect"></i>
                  {t('Disconnect')}
                </a>
              )}
              {!customer.HasGoogle && (
                <a
                  href={`${business.NativeHomeUrlWithLanguage}/integrations/google/signin`}
                  className="btn  mw-250px"
                >
                  {t('Connect to Google Calendar')}
                </a>
              )}                
              </td>
            </tr>
        );
      })
    )
  )
);
