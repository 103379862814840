import React, { Component } from 'react';
import Link from 'next/link';
import _ from 'lodash';
import { routes } from 'env/routes';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import moment from 'moment';

import { CoworkerAvatar } from 'ui/components/CoworkerAvatar';
import { NewPrivateMessageForm } from 'ui/_pages/directory/members/NewPrivateMessageForm';
import NewPrivateMessage, {
  hideNewPrivateMessageModal,
  showNewPrivateMessageModal,
} from './NewPrivateMessage';

@withCustomComponent('ProfilePageHeader')
@withRouter
@withTranslation()
@inject('appStore', 'communityStore')
@observer
class ProfilePageHeader extends Component {
  componentDidMount() {
    const { appStore } = this.props;
    appStore.loadCountries();
  }

  componentWillUnmount() {
    const { member } = this.props;
    hideNewPrivateMessageModal(member.Id);
  }

  render() {
    const { t, componentName, member, customFields, appStore, communityStore } =
      this.props;
    const { business, countries, configuration } = appStore;

    const memberCountry = _.first(
      countries.filter((c) => c.Id == member.CountryId)
    );

    return (
      <div
        data-component-name={componentName}
        className="directory-profile-page-header"
      >
        <div
          className="directory-profile-page-header__images"
          style={{
            backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/coworker/getbanner/${member.Id}?cache=${member.UpdatedOn})`,
          }}
        >
          <CoworkerAvatar
            coworker_id={member.Id}
            size={320}
            title={member.FulLName}
            imgClassName="avatar avatar--160"
          />
          {member.IsNew && (
            <span className="badge right new">{t('New Member')}</span>
          )}
          {member.IsAdmin && (
            <span className="badge right admin">{t('Staff')}</span>
          )}
          {member.CheckedIn && (
            <span className="badge left checked-in">{t('Checked In')}</span>
          )}
        </div>
        <div className="directory-profile-page-header__content">
          <div className="directory-profile-page-header__content--l">
            <h2>{member.FullName}</h2>
            <p>
              {member.Position}
              {member.Position && member.CompanyName && <> {t('at')} </>}
              {member.CompanyName}
            </p>
            {customFields
              .filter(
                (cf) =>
                  cf.FieldType == 'Boolean' ||
                  cf.FieldType == 'Date' ||
                  cf.FieldType == 'Integer' ||
                  cf.FieldType == 'Decimal' ||
                  cf.FieldType == 'Dropdown'
              )
              .map((cf, index) => (
                <div className="customfields" key={index}>
                  {member[cf.FieldName] && cf.FieldType === 'Date' && (
                    <>
                      <p>
                        {cf.NameInSearch}:{' '}
                        <a>{moment(member[cf.FieldName]).format('ll')}</a>
                      </p>
                    </>
                  )}
                  {member[cf.FieldName] && cf.FieldType === 'Boolean' && (
                    <>
                      <p>
                        {cf.NameInSearch}:{' '}
                        <Link
                          href={
                            routes.directory_members +
                            `?${cf.FieldName}=${
                              member[cf.FieldName] === 'true' ? 'true' : 'false'
                            }`
                          }
                        >
                          <a>
                            {member[cf.FieldName] === 'true'
                              ? t('yes')
                              : t('no')}
                          </a>
                        </Link>
                      </p>
                    </>
                  )}
                  {member[cf.FieldName] &&
                    cf.FieldType !== 'Boolean' &&
                    cf.FieldType !== 'Date' && (
                      <p>
                        {cf.NameInSearch}:{' '}
                        <Link
                          href={
                            routes.directory_members +
                            `?${cf.FieldName}=${member[cf.FieldName]}`
                          }
                        >
                          <a>{member[cf.FieldName]}</a>
                        </Link>
                      </p>
                    )}
                </div>
              ))}
            {(member.CityName || member.CountryId) && (
              <p>
                {member.CityName}
                {countries && (
                  <>
                    {member.CityName && member.CountryId && ', '}
                    {memberCountry && memberCountry.Name}
                  </>
                )}
              </p>
            )}
            {member.ProfileTagsList.length > 0 && (
              <ul className="list-tags">
                {member.ProfileTagsList.map((tag, i) => {
                  return (
                    <li key={i}>
                      <Link href={routes.directory_members + `?tag=${tag}`}>
                        <a>{tag}</a>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <div className="directory-profile-page-header__content--r">
            {configuration['PublicWebSite.CommunityBoard'] && (
              <>
                <button
                  className="btn btn-icon "
                  onClick={() => showNewPrivateMessageModal(member.Id)}
                >
                  <i className="icon-send"></i>
                  {t('Send Message')}
                </button>
                <NewPrivateMessage member={member} />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ProfilePageHeader;
