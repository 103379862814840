import React, { useState } from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

export const LocationMap = withCustomComponent('LocationMap')(
  inject('appStore')(
    withRouter(
      observer(({ appStore, componentName }) => {
        const { t } = useTranslation();
        const { business, configuration } = appStore;
        const [map, setMap] = React.useState(null);
        const [center, setCenter] = useState({
          lat: 0,
          lng: 0,
        });

        const onLoad = React.useCallback(function callback(map) {
          setMap(map);

          const geocoder = new google.maps.Geocoder();
          var address = business.Address?.replace('\n', ' ').replace('\r', '');
          geocoder.geocode({ address: address }, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
              map.setCenter(results[0].geometry.location);
              new google.maps.Marker({
                map: map,
                position: results[0].geometry.location,
                markers: [{ address: results[0].geometry.location }],
              });
            } else {
              appStore.setPopMessage(
                "We couldn't map your address. Try changing the format and order of each element of your address. Error: " +
                status
              );
            }
          });
        }, []);

        const onUnmount = React.useCallback(function callback(map) {
          setMap(null);
        }, []);
        return (
          <div data-component-name={componentName}>
            <LoadScript googleMapsApiKey={configuration['Google.ApiKey']}>
              <GoogleMap
                mapContainerStyle={{
                  color: 'rgb(196, 196, 196)',
                  margin: '25px 0px',
                  paddingTop: 180,
                  textAlign: 'center',
                  height: 350,
                  backgroundColor: 'rgb(244, 244, 244)',
                }}
                center={center}
                zoom={15}
                onLoad={onLoad}
                onUnmount={onUnmount}
              ></GoogleMap>
            </LoadScript>
          </div>
        );
      })
    )
  )
);
