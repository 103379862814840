import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import TimePassesRow from 'ui/_pages/home/components/TeamKpiWidget/TimePassesRow';
import SortableTableHeader from 'ui/components/tables/SortableTableHeader';
import Link from 'next/link';
import { routes } from 'env/routes';
import i18next from 'i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import CardDashboard from 'ui/components/cards/CardDashboard';

@withCustomComponent('TimePassesColumn')
@withTranslation()
@inject('appStore', 'invoicingStore')
@observer
class TimePassesColumn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortField: 'CoworkerFullName',
      sortDirection: 'asc',
    };
  }

  render() {
    const { componentName, metrics, t, appStore } = this.props;

    const totalFormatted = new Intl.NumberFormat(i18next.language, {}).format(
      metrics.CheckedTimeThisMonth / 60
    );

    const sortedKpi = _.orderBy(
      metrics.KPI,
      this.state.sortField,
      this.state.sortDirection
    );

    return (
      <CardDashboard
        headerMainTitle={t('Passes')}
        headerActionTitle={t('View all')}
        headerActionUrl={routes.profile_team}
        componentName={this.props.componentName}
      >
        <div className="d-flex flex-column justify-content-between h-100">
          <table className="table table--sortable table--kpi mt-16">
            <thead>
              <tr>
                <SortableTableHeader
                  onClick={({ sortField, sortDirection }) =>
                    this.setState({ sortField, sortDirection })
                  }
                  label={t('Name')}
                  sortField="CoworkerFullName"
                  currentSortField={this.state.sortField}
                  currentSortDirection={this.state.sortDirection}
                />
                <SortableTableHeader
                  onClick={({ sortField, sortDirection }) =>
                    this.setState({ sortField, sortDirection })
                  }
                  className={'text-right'}
                  label={t('Credits')}
                  sortField="TotalRemainingTimePassesMinutes"
                  currentSortField={this.state.sortField}
                  currentSortDirection={this.state.sortDirection}
                />
                <SortableTableHeader
                  onClick={({ sortField, sortDirection }) =>
                    this.setState({ sortField, sortDirection })
                  }
                  className={'text-right'}
                  label={t('Usage')}
                  sortField="CheckedTimeThisMonth"
                  currentSortField={this.state.sortField}
                  currentSortDirection={this.state.sortDirection}
                />
              </tr>
            </thead>
            <tbody>
              {sortedKpi.map(
                (benefit, i) =>
                  (benefit.RemainingTimePassesMinutes > 0 ||
                    benefit.RemainingTimePassesCount > 0) && (
                    <TimePassesRow key={i} benefit={benefit} />
                  )
              )}
            </tbody>
          </table>

          <div>
            <div className="text-gray-700 fs-14 border-top pt-16 mb-4">
              {t('Total checked-in hours this month')}
            </div>
            <h4 className="mb-0">
              {t('{{totalFormatted}} hour', {
                totalFormatted,
                count: metrics.CheckedTimeThisMonth,
              })}
            </h4>
          </div>
        </div>
      </CardDashboard>
    );
  }
}
export default TimePassesColumn;
