import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import { withCustomComponent } from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Breadcrumbs from 'ui/components/Breadcrumbs';
import CheckoutComplete from 'ui/_pages/checkout/CheckoutComplete';
import { privateRoute } from 'env/privateRoute';
import SignupPageLayout from 'ui/layouts/SignupPageLayout';

@privateRoute
@withCustomComponent('CheckoutCompletePage')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class SignupCompletePage extends Component {
  render() {
    const { t } = this.props;
    const { appStore } = this.props;
    const { business } = appStore;
    return (
      <SignupPageLayout
        componentName={this.props.componentName}
        title={t('Checkout - {{Name}}', { Name: t(business.Name) })}
      >
        <div className="mw-820">
          <CheckoutComplete />
        </div>
      </SignupPageLayout>
    );
  }
}

export default SignupCompletePage;
