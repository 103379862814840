import React, { useEffect } from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';

import CardProduct from 'ui/components/cards/CardProduct';
import { PriceTaxStatus } from 'ui/components/PriceTaxStatus';

export const HomeProductsSection = withCustomComponent('HomeProductsSection')(
  inject('appStore')(
    withRouter(
      observer(({ router, appStore, componentName }) => {
        const { t } = useTranslation();
        const { homePage } = appStore;

        return (
          <div
            data-component-name={componentName}
            className="home-products-section"
          >
            <div className="container">
              <h3 className="mb-24">
                {t('Our additional products and services')}
              </h3>
              <ul className="list-unstyled m-0">
                {homePage.ProductGroups.map((group, gi) => (
                  <li key={gi}>
                    {homePage.ProductGroups.length > 1 ? (
                      <div className="row align-items-center mb-24">
                        <div className="col-12">
                          <h6 className="mb-0">{t(group)}</h6>
                        </div>
                      </div>
                    ) : (
                      <div className="mt-40"></div>
                    )}
                    <ul className="list-grid list-grid--25 list-grid--lg-33 list-grid--md-50 list-grid--sm-100">
                      {homePage.Products.map(
                        (product, pi) =>
                          product.Tags &&
                          product.Tags.indexOf(group) > -1 && (
                            <li key={pi}>
                              <CardProduct product={product} addToCart={true} />
                            </li>
                          )
                      )}
                    </ul>
                    {homePage.ProductGroups.length != gi + 1 && (
                      <hr className="mt-24 mb-32" />
                    )}
                  </li>
                ))}
                {homePage.Products.filter((p, i) => !p.Tags).length > 0 && (
                  <li>
                    <div className="mt-40"></div>
                    <ul className="list-grid list-grid--25 list-grid--lg-33 list-grid--md-50 list-grid--sm-100">
                      {homePage.Products.map(
                        (product, pi) =>
                          !product.Tags && (
                            <li key={pi}>
                              <CardProduct product={product} addToCart={true} />
                            </li>
                          )
                      )}
                    </ul>
                  </li>
                )}
              </ul>
              <div className="mt-10">
                <PriceTaxStatus />
              </div>
            </div>
          </div>
        );
      })
    )
  )
);
