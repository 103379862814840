import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'next/router';
import DirectoryLayout from 'ui/_pages/directory/DirectoryLayout';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { routes } from 'env/routes';
import { DirectoryZeroCase } from 'ui/_pages/directory/DirectoryZeroCase';
import { MembersList } from 'ui/_pages/directory/MembersList';
import { Pagination } from 'ui/components/listings/Pagination';
import { withCustomComponent } from 'ui/components/withCustomComponent';
import { NoAccessNotice } from 'ui/components/NoAccessNotice';
import TextAreaInputField from 'ui/components/forms/TextAreaInputField';

@withCustomComponent('MembersDirectoryPage')
@withTranslation()
@withRouter
@inject('appStore', 'authStore', 'communityStore')
@observer
class MembersDirectoryPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    const { t, router, communityStore } = this.props;
    communityStore
      .loadMembersDirectory({ ...router.query })
      .then((data) => {
        if (data.RedirectTo?.indexOf('Teams'))
          router.push(routes.directory_teams);

        return data;
      })
      .catch((err) => {
        this.setState({
          error: t("You currently don't have access to the members directory."),
        });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { router, communityStore } = this.props;
    const prevQuery = prevProps.router.query;
    const currentQuery = router.query;
    if (JSON.stringify(currentQuery) != JSON.stringify(prevQuery))
      communityStore.loadMembersDirectory({ ...router.query }).catch((err) => {
        this.setState({
          error: t("You currently don't have access to the members directory."),
        });
      });
  }

  render() {
    const { communityStore, t, appStore, router } = this.props;
    const { business } = appStore;
    const {
      membersDirectory,
      hasLoadedMembersDirectory,
      isLoadingMembersDirectory,
    } = communityStore;

    const members = membersDirectory.AllMembersByName;
    const hasLoadedData =
      hasLoadedMembersDirectory && !isLoadingMembersDirectory;
    return (
      <DirectoryLayout
        componentName={this.props.componentName}
        loading={!hasLoadedData}
        topTags={membersDirectory.TopTags}
        customFields={membersDirectory.CustomFields}
        showType={TextAreaInputField}
        title={t('Members Directory - {{Name}}', { Name: t(business.Name) })}
      >
        <NoAccessNotice error={this.state.error} />
        {isLoadingMembersDirectory && <LoadingSpinner />}
        {!isLoadingMembersDirectory && (
          <>
            <DirectoryZeroCase
              members={members}
              showAllLink={routes.directory_members}
              text={t('We found no members')}
              showAllText={t('Show all members')}
            />
            <MembersList />
            <Pagination
              page={router.query.page}
              records={members}
              top={router.query.top}
            />
          </>
        )}
      </DirectoryLayout>
    );
  }
}

export default MembersDirectoryPage;
