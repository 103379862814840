import React, { Component } from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import InputField from 'ui/components/forms/InputField';
import DateInputField from 'ui/components/forms/DateInputField';
import CustomField from 'ui/_pages/profile/CustomField';
import asForm from 'ui/components/forms/asForm';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import withCustomComponent from 'ui/components/withCustomComponent';
import moment from 'moment';
import { InputRadioBox } from 'ui/components/forms/InputRadioBox';
import { TimeInputField } from 'ui/components/forms/TimeInputField';

@withCustomComponent('PersonalDetailSection')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class PersonalDetailSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      availableHours: [],
    };

    this.setAvailableHours = this.setAvailableHours.bind(this);
    this.updateTourDate = this.updateTourDateHour.bind(this);
  }

  @action updateTourDateHour(hour) {
    let newTourDate = this.props.customer.TourDate;

    // need to create a date to account for various hour formats
    let hourDate = moment('1971/08/25 ' + hour);

    newTourDate.setHours(hourDate.get('hour'));
    newTourDate.setMinutes(hourDate.get('minute'));

    this.props.customer.TourDate = newTourDate;
  }

  componentDidMount() {
    const { authStore, appStore, customer } = this.props;

    authStore
      .loadTourCustomFields()
      .then(() => authStore.validateCustomer())
      .catch((err) => {
        appStore.setPopMessage('Error: ' + err);
      });

    const availableHours = this.setAvailableHours();
    if (availableHours.length) {
      this.updateTourDateHour(availableHours[0]);
      this.setState({ selectedHour: availableHours[0] });
    }
  }

  setAvailableHours() {
    const { configuration } = this.props.appStore;
    const { customer, setIsValidDate } = this.props;
    let tourTimeSlots = [],
      dayTimeSlots;

    if (configuration['PublicWebSite.Tour.TimeSlots.Enabled']) {
      try {
        tourTimeSlots = JSON.parse(
          configuration['PublicWebSite.Tour.TimeSlots']
        );
      } catch {}

      // time slots for selected day
      dayTimeSlots = tourTimeSlots.filter(
        (t) => t.DayOfWeek == customer.TourDate?.getDay()
      );

      // sort chronologically
      dayTimeSlots.sort((a, b) => new Date(a.FromTime) - new Date(b.FromTime));
    } else {
      dayTimeSlots = [
        {
          FromTime: new Date('1976-01-01T00:00'),
          ToTime: new Date('1976-01-01T23:30'),
        },
      ];
    }

    const availableHours = [];

    if (dayTimeSlots.length) {
      setIsValidDate(true);

      for (let i = 0; i < dayTimeSlots.length; i++) {
        const dayTimeSlot = dayTimeSlots[i];
        let fromTime = moment.utc(dayTimeSlot.FromTime).local();
        let toTime = moment.utc(dayTimeSlot.ToTime).local();

        fromTime = moment.utc(fromTime).local();
        toTime = moment.utc(toTime).local();

        if (i > 0) {
          const prevDayTimeSlots = dayTimeSlots[i - 1];
          const prevDayTimeSlotsToTime = moment
            .utc(prevDayTimeSlots.ToTime)
            .local();
          // if two time slots overlap, skip to next time slot
          if (fromTime.isSame(prevDayTimeSlotsToTime)) {
            fromTime.add(30, 'minutes');
          }
        }

        while (fromTime.isSameOrBefore(toTime)) {
          availableHours.push(fromTime.format('LT'));
          fromTime.add(30, 'minutes');
        }
      }
    } else {
      setIsValidDate(false);
    }

    this.setState({ availableHours });
    return availableHours;
  }

  render() {
    const { componentName, customer, updateProperty, t, authStore, appStore } =
      this.props;
    const { business, configuration } = appStore;
    const { tourCustomFields, hasLoadedTourCustomFields } = authStore;
    if (!hasLoadedTourCustomFields) return <LoadingLayout />;

    const timeSlotsEnabled =
      configuration['PublicWebSite.Tour.TimeSlots.Enabled'];

    const customFields = _(tourCustomFields)
      .groupBy('GroupName')
      .map(function (fields, name) {
        return {
          name,
          fields,
        };
      })
      .value();

    return (
      <>
        <form
          className="form personal-details"
          data-component-name={componentName}
        >
          <div className="border-bottom">
            <div className="form-group">
              <InputField
                id="fullname"
                label={t('Full name') + '*'}
                name="FullName"
                errors={authStore.customerValidation.errors.FullName}
                value={customer.FullName}
                onChange={updateProperty}
              />
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <InputField
                  id="email"
                  label={t('Email') + '*'}
                  name="Email"
                  errors={authStore.customerValidation.errors.Email}
                  value={customer.Email}
                  onChange={updateProperty}
                />
              </div>
              <div className="form-group col-md-6 mb-24">
                <InputField
                  id="mobilePhone"
                  label={t('Phone number')}
                  name="MobilePhone"
                  errors={authStore.customerValidation.errors.MobilePhone}
                  value={customer.MobilePhone}
                  onChange={updateProperty}
                />
              </div>
            </div>
          </div>

          <div className="border-bottom">
            <h5 className="mt-24 mb-8">{t('Tour date and time')}</h5>
            <p className="fs-14 text-gray-700 mb-16">
              {t(
                'We would love to show you {{Name}}. Please let us know when you are available and we will do our best to receive you on that date and time.',
                { Name: t(business.Name) }
              )}
            </p>

            {!timeSlotsEnabled && (
              <div className="row align-items-end">
                <div className="col-md-6">
                  <div className="form-group required">
                    <DateInputField
                      id="tourDate"
                      label={t('Tour date')}
                      name="TourDate"
                      errors={authStore.customerValidation.errors.TourDate}
                      value={customer.TourDate}
                      onChange={updateProperty}
                      customProps={{
                        min: new Date()
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group required">
                    <TimeInputField
                      id="tourDate"
                      label={t('Tour time')}
                      name="TourDate"
                      errors={authStore.customerValidation.errors.TourDate}
                      value={customer.TourDate}
                      onChange={updateProperty}
                    />
                  </div>
                </div>
              </div>
            )}

            {timeSlotsEnabled && (
              <>
                {' '}
                <div className="row pb-4 ml-0">
                  <DateInputField
                    id="tourDate"
                    label={t('Date')}
                    name="TourDate"
                    errors={authStore.customerValidation.errors.TourDate}
                    value={customer.TourDate}
                    onChange={(key, value) => {
                      updateProperty(key, value);

                      const availableHours = this.setAvailableHours(value);
                      if (availableHours.length) {
                        this.updateTourDateHour(availableHours[0]);
                        this.setState({ selectedHour: availableHours[0] });
                      }
                    }}
                    customProps={{
                      min: new Date()
                    }}
                  />
                </div>
                <div
                  className="row pb-4 ml-0 mb-24 btn-group btn-group-toggle"
                  errors={authStore.customerValidation.errors.TourDate}
                >
                  {!this.state.availableHours.length && (
                    <span className="fs-14 text-gray-700">
                      {t(
                        'Sorry, there are no available times on the day you selected.'
                      )}
                    </span>
                  )}
                  {this.state.availableHours.map((hour, i) => (
                    <label
                      class={`btn btn-outline time-box ${
                        this.state.selectedHour === hour ? 'active' : ''
                      }`}
                    >
                      <input
                        type="radio"
                        key={`hour-${i}`}
                        name="TourDate"
                        value={hour}
                        onChange={(e) => {
                          this.setState({ selectedHour: hour });
                          this.updateTourDateHour(e.target.value);
                        }}
                        autocomplete="off"
                      />
                      {hour}
                    </label>
                  ))}
                </div>
              </>
            )}
          </div>

          {customFields.map((group, i) => (
            <div className="border-bottom">
              <>
                <h5 className="mt-24 mb-8">
                  {group.name !== 'null' ? group.name : t('Other Details')}
                </h5>
                {group.fields.map((field, i) => (
                  <div className="row pb-4">
                    <CustomField
                      key={i}
                      field={field}
                      customer={customer}
                      updateProperty={updateProperty}
                    />
                  </div>
                ))}
              </>
            </div>
          ))}
        </form>
      </>
    );
  }
}

export default asForm(PersonalDetailSection, 'customer');
