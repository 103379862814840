import React, { useState } from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import ReactMarkdown from 'react-markdown';
import withCustomComponent from 'ui/components/withCustomComponent';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { shorten, removeHtml } from 'env/utils/strings';
import CardDirectoryMemberTeam from 'ui/components/cards/CardDirectoryMemberTeam';

export const TeamProfilePageBody = withCustomComponent('TeamProfilePageBody')(
  inject(
    'appStore',
    'authStore'
  )(
    withRouter(
      observer(({ componentName, router, team, appStore, authStore }) => {
        const page = parseInt(router.query.page ?? '1');
        const { t } = useTranslation();
        const [lightboxOpen, setLightboxOpen] = useState(false);
        const [photoIndex, setPhotoIndex] = useState(0);
        const { business, configuration } = appStore;
        const { customer } = authStore;
        var images = [...Array(4).keys()].reduce((acc, cur) => {
          if (team[`HasImage${cur}`])
            acc.push({
              url: `${business.NativeHomeUrlWithLanguage}/team/getimage${cur}?id=${team.Id}&h=340&w=340&cache=${team.UpdatedOn}`,
              urlFull: `${business.NativeHomeUrlWithLanguage}/team/getimage${cur}?id=${team.Id}&w=1024&cache=${team.UpdatedOn}`,
            });
          return acc;
        }, []);
        const customModalStyle = {
          overlay: {
            zIndex: '100000',
          },
          content: {
            zIndex: '100000',
          },
        };

        return (
          <div
            data-component-name={componentName}
            className="directory-team-profile-page-body"
          >
            {team.ProfileSummary && (
              <div className="card about">
                <h5>{t('About {{Name}}', { Name: team.Name })}</h5>
                <ReactMarkdown source={removeHtml(team.ProfileSummary)} />
              </div>
            )}

            {team.TeamMembers.length > 0 && (
              <>
                <hr className="mt-40 mb-32" />
                <div className="row align-items-center mb-20">
                  <div className="col-8">
                    <h6 className="mb-0">
                      {t('Members at {{Name}}', { Name: team.Name })}
                    </h6>
                  </div>
                  <div className="col-4 text-right lh-1">
                    <Link href={routes.directory_members}>
                      <a className="btn btn-link text-gray-900 fw-500">
                        {t('See all')}
                        {`${
                          team.TeamMembers.length > 6
                            ? ` (${team.TeamMembers.length})`
                            : ''
                        }`}
                      </a>
                    </Link>
                  </div>
                </div>
                <ul className="list-grid list-grid--33 list-grid--md-50 list-grid--sm-100">
                  {team.TeamMembers.map((teamMember, i) => (
                    <li key={i}>
                      <CardDirectoryMemberTeam member={teamMember} />
                    </li>
                  ))}
                </ul>
              </>
            )}

            {images.length > 0 && (
              <>
                <hr className="mt-40 mb-32" />
                <div className="row align-items-center mb-20">
                  <div className="col-8">
                    <h6 className="mb-0">
                      {t('{{TeamName}} in images', { TeamName: team.Name })}
                    </h6>
                  </div>
                  <div className="col-4 text-right lh-1">
                    {team.Instagram && (
                      <a
                        target="_blank"
                        href={member.Instagram}
                        className="btn btn-link text-gray-900 fw-500"
                      >
                        <i className="icon-social-instagram"></i>
                        <span>
                          {t('{{TeamName}} Instagram', { TeamName: team.Name })}
                        </span>
                      </a>
                    )}
                  </div>
                </div>
                {lightboxOpen && (
                  <Lightbox
                    imageTitle={shorten(team.Name, 50)}
                    imageCaption={shorten(team.ProfileSummary, 500)}
                    mainSrc={images[photoIndex].urlFull}
                    onCloseRequest={() => {
                      setLightboxOpen(false);
                    }}
                    nextSrc={
                      images.length > 1 &&
                      images[(photoIndex + 1) % images.length].urlFull
                    }
                    prevSrc={
                      images.length > 1 &&
                      images[(photoIndex + images.length - 1) % images.length]
                        .urlFull
                    }
                    onCloseRequest={() => {
                      setPhotoIndex(0);
                      setLightboxOpen(false);
                    }}
                    onMovePrevRequest={() =>
                      setPhotoIndex(
                        (photoIndex + images.length - 1) % images.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setPhotoIndex((photoIndex + 1) % images.length)
                    }
                    reactModalStyle={customModalStyle}
                  />
                )}
                <ul className="list-grid list-grid--25 list-grid--lg-33 list-grid--md-50">
                  {images.map((image, i) => (
                    <li key={i}>
                      <a
                        onClick={(ev) => {
                          ev.preventDefault();
                          setPhotoIndex(i);
                          setLightboxOpen(true);
                        }}
                        href="#"
                      >
                        <img
                          className="img-fluid rounded"
                          src={image.url}
                          alt={team.Name}
                        />
                      </a>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        );
      })
    )
  )
);
