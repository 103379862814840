import React, { useState } from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation, Trans } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import moment from 'moment';
import asForm from 'ui/components/forms/asForm';
import TextAreaInputField from 'ui/components/forms/TextAreaInputField';
import BooleanInputField from 'ui/components/forms/BooleanInputField';
import InputField from 'ui/components/forms/InputField';
import RecaptchaSection from 'ui/_pages/signup/RecaptchaSection';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { SignupPageTermsAndConditions } from 'ui/_pages/signup/SignupPageTermsAndConditions';
import { SignupPagePassword } from 'ui/_pages/signup/SignupPagePassword';
import BillingDetailsSection from 'ui/_pages/profile/BillingDetailsSection';

export const EventTicketsPageSignupForm = asForm(
  withCustomComponent('EventTicketsPageSignupForm')(
    inject(
      'appStore',
      'authStore',
      'eventsStore'
    )(
      withRouter(
        observer(
          ({
            componentName,
            customer,
            updateProperty,
            authStore,
            router,
            appStore,
            eventsStore,
          }) => {
            const { t } = useTranslation();
            const { eventTicketsPage } = eventsStore;
            const event = eventTicketsPage.Event;
            const { isSavingCustomerWithUser } = authStore;
            const { configuration, business } = appStore;
            const [recaptcha, setRecaptcha] = useState(null);

            const showStarIfRequired = (group, fieldName) => {
              const { configuration } = appStore;
              return configuration[`${group}.${fieldName}`] === '2' ? '*' : '';
            };

            const saveCustomer = () => {
              authStore
                .saveCustomer({
                  router: router,
                  quickForm: true,
                  askBuyerAddress: event.AskBuyerAddress,
                  isEvent: true,
                  recaptcha: recaptcha,
                  returnUrl: routes.events,
                })
                .catch((err) => {
                  if (
                    err.data &&
                    err.data.RedirectTo.indexOf('/signup/restore') > -1
                  ) {
                    appStore.setPopMessage(
                      t(
                        'You seem to be already registered using this email, please sign in.'
                      )
                    );
                    router.push(
                      `${routes.login}?returnurl=${routes.tickets_by_id(event)}`
                    );
                  } else {
                    appStore.setPopMessage(err);
                  }
                });
            };

            return (
              <form data-component-name={componentName}>
                <div className="form-group">
                  <InputField
                    id="event-fullname"
                    label={t('Full name') + '*'}
                    name="FullName"
                    errors={authStore.customerValidation.errors.FullName}
                    value={customer.FullName}
                    onChange={updateProperty}
                  />
                </div>
                <div className="form-group">
                  <InputField
                    id="event-email"
                    label={t('Your email') + '*'}
                    name="Email"
                    errors={authStore.customerValidation.errors.Email}
                    value={customer.Email}
                    onChange={updateProperty}
                  />
                </div>
                <div className="form-group">
                  <InputField
                    id="event-phone"
                    label={
                      t('Phone') + showStarIfRequired('Forms', 'MobilePhone')
                    }
                    name="MobilePhone"
                    errors={authStore.customerValidation.errors.MobilePhone}
                    value={customer.MobilePhone}
                    onChange={updateProperty}
                  />
                </div>

                {event.AskBuyerAddress && (
                  <>
                    <div className="form-group">
                      <TextAreaInputField
                        id="event-address"
                        label={
                          t('Full address') +
                          showStarIfRequired('Forms', 'Address')
                        }
                        name="Address"
                        errors={authStore.customerValidation.errors.Address}
                        value={customer.Address}
                        onChange={updateProperty}
                      />
                    </div>

                    <div className="form-group">
                      <InputField
                        id="event-city"
                        label={
                          t('City / Town') +
                          showStarIfRequired('Forms', 'CityName')
                        }
                        name="CityName"
                        errors={authStore.customerValidation.errors.CityName}
                        value={customer.CityName}
                        onChange={updateProperty}
                      />
                    </div>

                    <div className="form-group">
                      <InputField
                        id="event-city"
                        label={
                          t('Postcode / Zip code') +
                          showStarIfRequired('Forms', 'PostCode')
                        }
                        name="PostCode"
                        errors={authStore.customerValidation.errors.PostCode}
                        value={customer.PostCode}
                        onChange={updateProperty}
                      />
                    </div>

                    <div className="form-group">
                      <InputField
                        id="event-state"
                        label={
                          t('State / Province') +
                          showStarIfRequired('Forms', 'State')
                        }
                        name="State"
                        errors={authStore.customerValidation.errors.State}
                        value={customer.State}
                        onChange={updateProperty}
                      />
                    </div>

                    <div className="form-group">
                      <InputField
                        id="event-city"
                        label={
                          t('TAX/VAT Number') +
                          showStarIfRequired('Forms', 'TaxIDNumber')
                        }
                        name="TaxIDNumber"
                        errors={authStore.customerValidation.errors.TaxIDNumber}
                        value={customer.TaxIDNumber}
                        onChange={updateProperty}
                      />
                    </div>
                  </>
                )}
                {configuration['SignupForm.ShowBillingSection'] == 'true' && (
                  <BillingDetailsSection
                    className={'mt-40'}
                    customer={customer}
                  />
                )}

                {business.TermsAndConditions && (
                  <div className="form-group">
                    <SignupPageTermsAndConditions customer={customer} />
                  </div>
                )}

                {configuration['Signup.UseCaptcha'] && (
                  <RecaptchaSection
                    customer={customer}
                    verifyCallback={(value) => setRecaptcha(value)}
                  />
                )}

                <div className="form-actions text-right">
                  <button
                    className="btn px-48"
                    disabled={isSavingCustomerWithUser}
                    onClick={(ev) => {
                      if (isSavingCustomerWithUser) return;
                      ev.preventDefault();
                      saveCustomer();
                      ev.target.blur();
                    }}
                  >
                    {isSavingCustomerWithUser ? <LoadingSpinner /> : t('Join')}
                  </button>
                </div>
              </form>
            );
          }
        )
      )
    )
  ),
  'customer'
);
