import React, { useState } from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import Reaptcha from 'reaptcha';
import asForm from 'ui/components/forms/asForm';
import InputField from 'ui/components/forms/InputField';
import TextAreaInputField from 'ui/components/forms/TextAreaInputField';
import LoadingSpinner from 'ui/components/LoadingSpinner';

export const ContactForm = asForm(
  withCustomComponent('ContactForm')(
    inject(
      'appStore',
      'supportStore'
    )(
      withRouter(
        observer(
          ({
            updateProperty,
            supportStore,
            newContactMessage,
            appStore,
            componentName,
            router,
          }) => {
            const { t } = useTranslation();
            const { configuration } = appStore;
            const { isSubmittingNewContactMessage } = supportStore;
            const [recaptcha, setRecaptcha] = useState(null);
            const utm_source = router.query.utm_source;
            const utm_medium = router.query.utm_medium;
            const utm_campaign = router.query.utm_campaign;
            const utm_content = router.query.utm_content;
            const utm_term = router.query.utm_term;
            const submitNewMessage = () => {
              supportStore
                .submitNewContactMessage({
                  recaptcha,
                  utm_source,
                  utm_medium,
                  utm_campaign,
                  utm_content,
                  utm_term,
                })
                .then(() => {
                  appStore.setPopMessage(
                    t(
                      'Thank you for your message. We will get back to you as soon as possible.'
                    )
                  );
                })
                .catch((err) => {
                  appStore.setPopMessage(
                    'Sorry, we could not process this request. Please try again'
                  );
                });
            };

            const verifyCallback = (value) => {
              setRecaptcha(value);
            };

            return (
              <form className="form your-input__form" noValidate>
                <div className="form-group">
                  <InputField
                    id="FromName"
                    label={t('Your email address')}
                    name="FromName"
                    errors={
                      supportStore.newContactMessageValidation.errors.FromName
                    }
                    value={newContactMessage.FromName}
                    onChange={updateProperty}
                  />
                </div>
                <div className="form-group">
                  <InputField
                    id="Header"
                    label={t('Full name')}
                    name="Header"
                    errors={
                      supportStore.newContactMessageValidation.errors.Header
                    }
                    value={newContactMessage.Header}
                    onChange={updateProperty}
                  />
                </div>
                <div className="form-group">
                  <TextAreaInputField
                    id="Body"
                    label={t('Message')}
                    name="Body"
                    errors={
                      supportStore.newContactMessageValidation.errors.Body
                    }
                    value={newContactMessage.Body}
                    onChange={updateProperty}
                  />
                </div>
                <div className="form-group">
                  <Reaptcha
                    onVerify={(response) => {
                      verifyCallback(response);
                    }}
                    sitekey={
                      configuration['Recaptcha.PublicKey']
                        ? configuration['Recaptcha.PublicKey']
                        : ''
                    }
                  />
                </div>
                <div className="form-actions">
                  <button
                    onClick={(ev) => {
                      ev.preventDefault();
                      submitNewMessage();
                    }}
                    className="btn px-32"
                    type="submit"
                  >
                    {isSubmittingNewContactMessage ? (
                      <LoadingSpinner />
                    ) : (
                      t('Send')
                    )}
                  </button>
                </div>
              </form>
            );
          }
        )
      )
    )
  ),
  'newContactMessage'
);
