import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const FeaturedBlogBanner = withCustomComponent('FeaturedBlogBanner')(
  inject(
    'appStore',
    'blogStore'
  )(
    withRouter(
      observer(({ router, blogStore, appStore, componentName }) => {
        const { t } = useTranslation();
        const { hasLoadedBannerBlogPosts, bannerBlogPosts } = blogStore;
        const { business } = appStore;

        const firstBannerBlogPosts = _.first(bannerBlogPosts);
        return (
          <>
            {firstBannerBlogPosts && (
              <div
                data-component-name={componentName}
                className="dashboard-event__cover"
              >
                <img
                  src={`${business.NativeHomeUrlWithLanguage}/blog/getlargeimage?id=${firstBannerBlogPosts.Id}&h=1280&w=500&cache=${firstBannerBlogPosts.UpdatedOn}`}
                  srcset={`${business.NativeHomeUrlWithLanguage}/blog/getlargeimage?id=${firstBannerBlogPosts.Id}&h=1280&w=500&cache=${firstBannerBlogPosts.UpdatedOn} 1x, ${business.NativeHomeUrlWithLanguage}/blog/getlargeimage?id=${firstBannerBlogPosts.Id}&h=2560&w=1000&cache=${firstBannerBlogPosts.UpdatedOn} 2x`}
                  alt={firstBannerBlogPosts.Title}
                />
              </div>
            )}
          </>
        );
      })
    )
  )
);
