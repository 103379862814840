import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import moment from 'moment';
import { CoworkerAvatar } from 'ui/components/CoworkerAvatar';
import { removeHtml } from 'env/utils/strings';
import ScrollableAnchor from 'react-scrollable-anchor';

export const HelpDeskComment = withCustomComponent('HelpDeskComment')(
  inject(
    'appStore',
    'authStore',
    'supportStore'
  )(
    withRouter(
      observer(
        ({ componentName, comment, authStore, appStore, supportStore }) => {
          const { t } = useTranslation();
          const { business } = appStore;
          const { me, isLoggedIn } = authStore;

          const deleteComment = (id) => {
            supportStore
              .deleteComment(id)
              .then(() => supportStore.loadMessages())
              .catch((err) => {
                appStore.setPopMessage(
                  t('Sorry, we could not delete this comment. {{err}}', { err })
                );
              });
          };

          return (
            <>
              {/*<ScrollableAnchor id={`comment-${comment.Id}`}>*/}
                <div className="d-flex">
                  <Link
                    href={routes.directory_member_by_id('[member_id]')}
                    as={routes.directory_member_by_id({
                        Id: comment.CoworkerId,
                        FullName: comment.Coworker.FullName ?? 'no-name',
                      })}
                  >
                    <a>
                      <img
                        className="avatar avatar--40 mr-8 cp"
                        src={`${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${comment.CoworkerId}?h=80&w=80&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}
                      />
                    </a>
                  </Link>
                  <div className="d-inline-block bg-gray-100 rounded py-8 px-12">
                    <div className="fs-14">
                      <Link
                        href={routes.directory_member_by_id('[member_id]')}
                        as={routes.directory_member_by_id({
                            Id: comment.CoworkerId,
                            FullName: comment.Coworker.FullName ?? 'no-name',
                          })}
                      >
                        {/* BB-TODO: comment.Coworker don't return FullName */}
                        <a>{comment.Coworker.FullName}</a>
                      </Link>{' '}
                      <span className="text-gray-600">
                        <time
                          dateTime={moment(comment.CreatedOnUtc)
                            .locale('en')
                            .format()}
                          className="timeago"
                        >
                          {moment(comment.CreatedOnUtc + 'Z').format('LL')}
                        </time>
                      </span>
                      {isLoggedIn && comment.CoworkerId == me.Id && (
                        <a
                          onClick={(ev) => {
                            ev.preventDefault();
                            deleteComment(comment.Id);
                          }}
                          href="#"
                          className="btn btn-link text-gray-600 ml-8 pb-3 tdn"
                          title={t('Delete comment')}
                        >
                          <i className="icon-delete"></i>
                        </a>
                      )}
                    </div>
                    <div className="fs-14">
                      {removeHtml(comment.MessageText)}
                    </div>
                  </div>
                </div>
              {/*</ScrollableAnchor>*/}
            </>
          );
        }
      )
    )
  )
);
