import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const BlogPageHeader = withCustomComponent('BlogPageHeader')(
  inject('appStore')(
    withRouter(
      observer(({ componentName, appStore }) => {
        const { t } = useTranslation();
        const { business } = appStore;
        return (
          <div
            data-component-name={componentName}
            className="blog-page-header">
            <h2 className="mb-0">{t('Articles')}</h2>
          </div>
        );
      })
    )
  )
);
