import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import moment from 'moment';
import { removeHtml } from 'env/utils/strings';

export const BlogPostRelatedPosts = withCustomComponent('BlogPostRelatedPosts')(
  inject(
    'appStore',
    'blogStore'
  )(
    withRouter(
      observer(({ componentName, blogStore }) => {
        const { t } = useTranslation();
        const blogPost = blogStore.blogPostPage;

        return (
          <>
            {blogPost.RelatedPosts.length > 0 && (
              <div data-component-name={componentName} class="section">
                <h2 class="h4 section__title">{t('Related articles')}</h2>

                <ul class="blog-list-horizontal">
                  {blogPost.RelatedPosts.map((post, i) => (
                    <li class="blog-list-horizontal__item">
                      <h3 class="h6 blog-list-horizontal__title">
                        <Link
                          href={routes.post_by_id('[post_id]')}
                          as={routes.post_by_id(post)}
                        >
                          <a>{post.Title}</a>
                        </Link>
                      </h3>
                      <div class="blog-list-horizontal__metadata">
                        {post.PostedBy && (
                          <span class="blog-list-horizontal__author">
                            {post.PostedBy.FullName}
                            {' | '}
                          </span>
                        )}
                        <span class="blog-list-horizontal__date">
                          {moment(post.PublishDate).format('L')}
                        </span>
                        <span class="blog-list-horizontal__comments-count">
                          <i class="fa fa-comment-o"></i>{' '}
                          {t('{{count}} comment', {
                            count: post.Comments.length,
                          })}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </>
        );
      })
    )
  )
);
