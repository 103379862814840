import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import Link from 'next/link';
import moment from 'moment';
import FullPageLayout from 'ui/layouts/FullPageLayout';
import { HomeLocationsSection } from 'ui/_pages/home/components/HomeLocationsSection';
import LoadingSpinner from 'ui/components/LoadingSpinner';

@withCustomComponent('LoginHomePage')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class LoginToHomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    const { router, appStore, authStore } = this.props;

    const customer = await authStore.loadCustomer().catch(() => null);
    const businesses = await appStore.loadBusinesses(true);

    if (customer) {
      var foundBusiness = businesses.find(
        (b) => b.Id == customer.InvoicingSpaceId
      );
      if (foundBusiness) {
        window.location = foundBusiness.HomeUrl;
      }
    }
    this.setState({ isLoading: false });
  }

  loginAtLocation = (business) => {
    window.location = `${business.HomeUrl}/login`;
  };

  render() {
    const { t, appStore } = this.props;

    const isLoadingData = this.state.isLoading || !appStore.hasLoadedBusinesses;
    return (
      <FullPageLayout
        componentName={this.props.componentName}
        title={t('Select your location')}
      >
        {isLoadingData && <LoadingSpinner />}
        {!isLoadingData && (
          <HomeLocationsSection onSelectBusiness={this.loginAtLocation} />
        )}
      </FullPageLayout>
    );
  }
}

export default LoginToHomePage;
