import React from 'react';
import _ from 'lodash';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';

import CardEvent from 'ui/components/cards/CardEvent';

export const EventPageRelatedEvents = withCustomComponent('EventPageRelatedEvents')(
  inject(
    'appStore',
    'eventsStore'
  )(
    withRouter(
      observer(({ router, appStore, eventsStore }) => {
        const { t } = useTranslation();
        const { eventPage } = eventsStore;
        const event = eventsStore.eventPage.Event;
        const { business } = appStore;

        return (
          eventPage.RelatedEvents && eventPage.RelatedEvents.length > 0 && (
            <div className="card event-page-related-events h-auto p-16 p-md-24 mb-24">
              <h5>{t('Related events')}</h5>
              {_.take(eventPage.RelatedEvents, 3).map((rEvent, i) => (
                <div className="mt-12" key={i}>
                  <CardEvent event={rEvent} sizeExtraSmall={true} />
                </div>
              ))}
            </div>
          )
        );
      })
    )
  )
);
