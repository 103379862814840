import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import withCustomComponent from 'ui/components/withCustomComponent';
import Link from 'next/link';
import moment from 'moment';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { withRouter } from 'next/router';
import { routes } from 'env/routes';
import { privateRoute } from 'env/privateRoute';
import Breadcrumbs from 'ui/components/Breadcrumbs';
import MarkdownInputField from 'ui/components/forms/MarkdownInputField';
import InputField from 'ui/components/forms/InputField';
import BooleanInputField from 'ui/components/forms/BooleanInputField';
import { CommunityBoardStartForm } from 'ui/_pages/community/start/CommunityBoardStartForm';
import { NoAccessNotice } from 'ui/components/NoAccessNotice';

@privateRoute
@withCustomComponent('CommunityBoardStartPage')
@withTranslation()
@inject('appStore', 'authStore', 'communityStore')
@withRouter
@observer
class CommunityBoardStartPage extends Component {
  componentDidMount() {
    const { t, router, appStore, communityStore } = this.props;
    communityStore.createNewCommunityBoardMessage({
      groupId: router.query.group_id || router.query.groupId,
      privateThread: router.query.privateThread == 'true',
    });
    communityStore.loadCommunityStartPage({ ...router.query }).catch((err) => {
      appStore.setPopMessage(
        t('Sorry, we could not load this page. {{err}}', { err })
      );
    });
    this.showCommunityStartModal();
  }

  componentWillUnmount() {
    this.hideCommunityStartModal();
  }

  showCommunityStartModal = () => {
    const $ = window.$;
    $('#community-start-modal').modal({
      backdrop: 'static',
      keyboard: false,
      show: true,
    });
  };

  hideCommunityStartModal = () => {
    const $ = window.$;
    $('#community-start-modal').modal('hide');
    window.location.hash = '';
  };

  render() {
    const { t, router, appStore, authStore, communityStore } = this.props;
    const { business } = appStore;
    const { customer } = authStore;
    const { communityStartPage, newCommunityBoardMessage } = communityStore;
    return (
      <DefaultLayout
        title={t('New conversation - {{Name}}', { Name: t(business.Name) })}
      >
        <main className="content content--custom" role="main">
          <div className="container">
            <div
              className="modal modal-community-start"
              id="community-start-modal"
            >
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <Link
                    href={
                      router.query.group_id > 0
                        ? routes.forum_by_group('[group_id]')
                        : routes.forum
                    }
                    as={
                      router.query.group_id > 0
                        ? routes.forum_by_group(router.query.group_id)
                        : routes.forum
                    }
                  >
                    <a className="close">
                      <i className="icon-close"></i>
                    </a>
                  </Link>
                  <div className="modal-header">
                    <h4 className="d-flex align-items-center mb-0">
                      <i className="icon-start-conversation mr-8"></i>
                      {t('Start a new conversation')}
                    </h4>
                  </div>
                  <div className="modal-body">
                    {communityStartPage?.ErrorMessage && (
                      <NoAccessNotice
                        error={t(
                          "You currently don't have access to the community board."
                        )}
                      />
                    )}
                    {!communityStartPage?.ErrorMessage && (
                      <CommunityBoardStartForm
                        newCommunityBoardMessage={newCommunityBoardMessage}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </DefaultLayout>
    );
  }
}

export default CommunityBoardStartPage;
