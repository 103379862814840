import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { removeHtml } from 'env/utils/strings';

export const LessonPageMetaData = withCustomComponent('LessonPageMetaData')(
  inject(
    'appStore',
    'coursesStore',
    'appStore'
  )(
    withRouter(
      observer(({ coursesStore, appStore, componentName }) => {
        const { t } = useTranslation();
        const { business } = appStore;
        const { Lesson } = coursesStore.lessonPage;
        const { Host } = coursesStore.lessonPage.Course;
        const { COMPLETION_TYPES } = coursesStore;
        const group = coursesStore.lessonPage.Course.Course ? coursesStore.lessonPage.Course.Course.GroupName : null;
        const videosCount = coursesStore.videos.length;
        let readTime = 1;
        if(removeHtml(Lesson.LessonContents)) {
          readTime = Math.round(removeHtml(Lesson.LessonContents).split(' ').length / 250) || 1;
        }

        return (
          <div
            data-component-name={componentName}
            className="p-16 p-md-24 p-lg-32 mb-8"
          >
            <div className="page-header page-header--lesson">
              <div className="notifications-placeholder"></div>
              <h2>{removeHtml(Lesson.Title)}</h2>

              <div className="lesson-details__metadata fs-14 text-gray-700">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="mb-12">
                      {Lesson.InstructorFullName && (
                        <>
                          <img
                            className="avatar mr-8"
                            src={`${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${Lesson.InstructorId}?h=40&w=40&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}
                            alt={Lesson.InstructorFullName}
                          />

                          <span className="instructor-name text-gray-900">
                          {Lesson.InstructorFullName}{Lesson.InstructorCompanyName ? `, ${Lesson.InstructorCompanyName}` : ''}
                          </span>
                        </>
                      )}

                      {!Lesson.InstructorFullName && Host?.FullName && (
                        <>
                          <img
                            className="avatar mr-8"
                            src={`${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${Host.IdString}?h=40&w=40&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}
                            alt={Host.FullName}
                          />

                          <span className="host-name text-gray-900">
                          {Host.FullName}{Host.CompanyName ? `, ${Host.CompanyName}` : ''}
                          </span>
                        </>
                      )}
                    </div>

                    <div className="mb-12">
                      <i className="fa fa-clock-o mr-6"></i>
                      <span>
                        {t('Length') + ': '}
                      </span>
                      <span className="text-gray-900">
                        {readTime + ' ' + t(`${readTime > 1 ? 'minutes' : 'minute'}`)}
                      </span>
                    </div>             
                  </div>
                </div>
                
                {Lesson.CompletionType === COMPLETION_TYPES.VIDEO && videosCount > 0 &&
                  <div className="d-inline align-items-center requirement">
                    <i className="icon-alert text-gray-900 mr-6"></i>
                    <span className="text-gray-900">
                      {t(`To move to the next lesson you will need to watch 90% of the {{count}} videos in this lesson.`, { count: videosCount })}
                    </span>
                  </div>
                }

                <ul className="list-tags card-course__tags">
                  {group &&
                    <li>
                      <Link
                        href={routes.courses + `?group=${group}&page=1`}
                      >
                        <a class="label label--default">{group}</a>
                      </Link>
                    </li>
                  }
                </ul>
              </div>
            </div>
          </div>
        );
      })
    )
  )
);
