import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import Link from 'next/link';
import moment from 'moment';
import { withRouter } from 'next/router';
import FullPageLayout from 'ui/layouts/FullPageLayout';
import { routes } from 'env/routes';
import { CancelContractHeader } from 'ui/_pages/plan/cancel/CancelContractHeader';
import { CancelContractSteps } from 'ui/_pages/plan/cancel/CancelContractSteps';
import { action } from 'mobx';
import { privateRoute } from 'env/privateRoute';
import { withCustomComponent } from 'ui/components/withCustomComponent';

@withCustomComponent('PlanChangePage')
@privateRoute
@withTranslation()
@inject('appStore', 'authStore', 'invoicingStore')
@withRouter
@observer
class PlanChangePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { router, invoicingStore } = this.props;
    invoicingStore
      .loadContract(router.query.contract_id)
      .catch(() => router.push(routes.plan));
  }

  @action updateContract(name, value) {
    const { invoicingStore } = this.props;
    const { contract } = invoicingStore;
    contract[name] = value;
  }

  cancelContract = () => {
    const { router, t, invoicingStore, appStore } = this.props;
    const { contract } = invoicingStore;
    invoicingStore
      .cancelContract(contract.Id)
      .then((contract) => {
        appStore
          .setPopMessage(
            t(
              'Your plan {{Name}} will be cancelled on {{AbsoluteCancellationDate}}',
              {
                Name: contract.Tariff.Name,
                AbsoluteCancellationDate: moment(
                  contract.AbsoluteCancellationDate
                ).format('L'),
              }
            )
          )
          .then(() => router.push(routes.plan));
      })
      .catch(() => {
        appStore.setPopMessage(
          t('Your plan could not be cancelled. Please contact us.')
        );
      });
  };

  render() {
    const { t, invoicingStore } = this.props;
    const { contract } = invoicingStore;

    return (
      <FullPageLayout title={t('Cancel contract')}>
        <CancelContractHeader
          updateContract={(name, value) => this.updateContract(name, value)}
          cancelContract={() => this.cancelContract()}
        />
      </FullPageLayout>
    );
  }
}

export default PlanChangePage;
