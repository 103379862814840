/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import Reaptcha from 'reaptcha';
import withCustomComponent from 'ui/components/withCustomComponent';
import LoadingSpinner from 'ui/components/LoadingSpinner';

@withCustomComponent('RecaptchaSection')
@withTranslation()
@inject('appStore', 'authStore')
@observer
class RecaptchaSection extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { appStore, customer } = this.props;
    const { configuration } = appStore;

    if (!customer) return <></>;
    if (!(configuration['Recaptcha.PublicKey']?.length > 0))
      return <LoadingSpinner />;

    return (
      <div
        data-component-name={this.props.componentName}
        className="form-group"
      >
        <Reaptcha
          onVerify={(response) => {
            this.props.verifyCallback(response);
          }}
          sitekey={configuration['Recaptcha.PublicKey']}
        />
      </div>
    );
  }
}

export default RecaptchaSection;
