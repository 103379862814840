import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
export const CommunityBoardItemDropdown = withCustomComponent(
  'CommunityBoardItemDropdown'
)(
  inject(
    'appStore',
    'authStore',
    'communityStore'
  )(
    withRouter(
      observer(
        ({
          authStore,
          appStore,
          router,
          communityStore,
          thread,
          componentName,
        }) => {
          const { isLoggedIn, me } = authStore;

          const mute = () => {
            communityStore.muteThread(thread);
          };

          const deleteThread = () => {
            appStore
              .setYesNotQuestion(
                t('Do you really want to delete this conversation?')
              )
              .then(
                (res) =>
                  res &&
                  communityStore
                    .deleteThread(thread)
                    .then((res) => {
                      if (res.ErrorMessage)
                        appStore.setPopMessage(
                          'Could not delete this conversation: ' +
                            res.ErrorMessage
                        );
                      else router.push(routes.forum);
                    })
                    .catch((ex) =>
                      appStore.setPopMessage(
                        'Could not delete this conversation: ' + ex
                      )
                    )
              );
          };

          return (
            <div
              data-component-name={componentName}
              className="community-board-item-dropdown dropdown"
            >
              <button
                className="btn btn-single-icon btn-white dropdown-toggle"
                data-toggle="dropdown"
              >
                <i className="icon-more"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                {isLoggedIn && (
                  <>
                    <button
                      onClick={(ev) => {
                        ev.preventDefault();
                        mute();
                      }}
                      className="dropdown-item"
                    >
                      <i className="fa fa-bell-slash-o fs-16"></i>
                      {thread.MutedForUser && t('Unmute')}
                      {!thread.MutedForUser && t('Mute')}
                    </button>
                  </>
                )}
                {thread.UserId == me?.Id && (
                  <button
                    onClick={(ev) => {
                      ev.preventDefault();
                      deleteThread();
                    }}
                    className="dropdown-item"
                  >
                    <i className="icon-delete"></i>
                    {t('Delete')}
                  </button>
                )}
              </div>
            </div>
          );
        }
      )
    )
  )
);
