import React, {useEffect} from 'react';
import Link from 'next/link';
import {routes} from 'env/routes';
import {useTranslation} from 'react-i18next';
import {withRouter} from 'next/router';
import {inject, observer} from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import {TeamMemberPermissionsRow} from 'ui/_pages/profile/TeamMemberPermissionsRow';
import ActionButton from 'ui/components/forms/ActionButton';
import ResponsiveTable from 'ui/components/tables/ResponsiveTable';

export const TeamMemberPermissionsList = withCustomComponent(
  'TeamMemberPermissionsList'
)(
  inject(
    'appStore',
    'authStore'
  )(
    withRouter(
      observer(({appStore, authStore, team, saveTeam}) => {
        const {t} = useTranslation();
        const {business, configuration} = appStore;
        let allMembers = team.AllTeamMembers.sort((a, b) =>
          a.FullName.localeCompare(b.FullName)
        );

        return (
          <>
            <fieldset
              className="team-members-permissions mb-28"
              data-component-name={'TeamMemberPermissionsList'}
            >
              <div className="card-item-wrapper">
                <div className="card-item-header">
                  <h5 className="card-item-header-title">
                    {t('Team members at')} {team.Name}
                  </h5>
                </div>
                {allMembers.length > 0 && (
                  <>
                    <ResponsiveTable>
                      <thead>
                      <tr>
                        <th className="name-col">{t('Full name')}</th>
                        <th className="rotate">{t('Team admin')}</th>
                        <th className="rotate">{t('Can make bookings')}</th>
                        <th className="rotate">{t('Can buy products')}</th>
                        {/*<th className="rotate">{t('Can buy tickets')}</th>*/}
                        {/*<th className="rotate">{t('Can join community')}</th>*/}
                        {/*<th className="rotate">{t('Can join network')}</th>*/}

                        {configuration['Forms.TeamAdminsCanUpdateCards'] && (
                          <th className="access-card-col ">
                            {t('Access Card')}
                          </th>
                        )}

                        <th className="access-col"></th>
                        <th className="access-col"></th>
                      </tr>
                      </thead>

                      <tbody>
                      {allMembers.map((member, key) => (
                        <TeamMemberPermissionsRow
                          key={key}
                          team={team}
                          saveTeam={saveTeam}
                          member={member}
                        />
                      ))}
                      </tbody>
                    </ResponsiveTable>
                  </>
                )}
              </div>
              {allMembers.length > 0 && (
                <ActionButton
                  className="btn "
                  action={() => saveTeam()}
                  busy={authStore.isSavingTeamProfile}
                />
              )}
            </fieldset>
          </>
        );
      })
    )
  )
);
