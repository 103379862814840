import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const StoreZeroCase = withCustomComponent('StoreZeroCase')(
  inject(
    'appStore',
    'authStore'
  )(
    withRouter(
      observer(({ componentName, authStore, appStore }) => {
        const { t } = useTranslation();
        const { configuration } = appStore;
        const { isLoggedIn } = authStore;
        return (
          <div data-component-name={componentName} className="mt-32">
            <div className="border-dashed rounded p-24 p-lg-48 pb-32 pb-lg-64 text-center">
              <svg
                width="68px"
                height="68px"
                viewBox="0 0 68 68"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="Cart"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <g
                    id="Basket---Empty"
                    transform="translate(-723.000000, -246.000000)"
                    stroke="#999999"
                    strokeWidth="3"
                  >
                    <g id="Box" transform="translate(132.000000, 192.000000)">
                      <g
                        id="Group"
                        transform="translate(585.000000, 48.000000)"
                      >
                        <g id="Path" transform="translate(8.000000, 8.000000)">
                          <g transform="translate(0.000000, 0.000000)">
                            <line
                              x1="31.9822223"
                              y1="39.057778"
                              x2="31.9822223"
                              y2="56.8355558"
                              transform="translate(31.982222, 47.946667) rotate(-90.000000) translate(-31.982222, -47.946667) "
                            ></line>
                            <path
                              d="M60.4444447,32.0000001 L3.55555557,32.0000001 C1.5928889,32.0000001 0,30.4071112 0,28.4444446 L0,21.3333334 C0,19.3706667 1.5928889,17.7777779 3.55555557,17.7777779 L60.4444447,17.7777779 C62.4071114,17.7777779 64,19.3706667 64,21.3333334 L64,28.4444446 C64,30.4071112 62.4071114,32.0000001 60.4444447,32.0000001 Z"
                              fill="#CCCCCC"
                            ></path>
                            <line
                              x1="39.2533335"
                              y1="0.106666667"
                              x2="49.777778"
                              y2="17.6711112"
                            ></line>
                            <line
                              x1="24.7466668"
                              y1="0.106666667"
                              x2="14.2222223"
                              y2="17.6711112"
                            ></line>
                            <path d="M59.5840002,32.0000001 L53.585778,59.6942225 C53.0560002,62.1475558 50.8835558,63.8968892 48.3733335,63.8968892 L15.6266667,63.8968892 C13.1164445,63.8968892 10.9475556,62.1475558 10.4142223,59.6942225 L4.41600002,32.0000001"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>

              <h5 className="my-8">{t('There are no products available.')}</h5>

              <p className="fs-14 text-gray-700 mxw-400px mx-auto mb-24">
                {t(
                  'You can make a booking or maybe book an event. These are some links you may find useful.'
                )}
              </p>

              <div className="list-inline-8 d-inline-flex justify-content-center">
                <Link href={routes.calendar}>
                  <a className="btn btn-icon btn-outline">
                    <i className="icon-bookings"></i>
                    {t('Make a Booking')}
                  </a>
                </Link>

                {configuration['PublicWebSite.Events'] && (
                  <Link href={routes.events}>
                    <a className="btn btn-icon btn-outline">
                      <i className="icon-calendar-view"></i>
                      {t('Book an event')}
                    </a>
                  </Link>
                )}
                {isLoggedIn && (
                  <Link href={routes.profile}>
                    <a className="btn btn-icon btn-outline">
                      <i className="icon-user-circle"></i>
                      {t('Access your account')}
                    </a>
                  </Link>
                )}
              </div>
            </div>
          </div>
        );
      })
    )
  )
);
