import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import AdditionalChargesColumn from 'ui/_pages/home/components/TeamKpiWidget/AdditionalChargesColumn';
import BookingCreditsColumn from 'ui/_pages/home/components/TeamKpiWidget/BookingCreditsColumn';
import TimePassesColumn from 'ui/_pages/home/components/TeamKpiWidget/TimePassesColumn';
import { routes } from 'env/routes';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import DashboardBox from 'ui/components/DashboardBox';
import withCustomComponent from 'ui/components/withCustomComponent';
import TeamMetricsWidget from '../TeamMetricsWidget/TeamMetricsWidget';

@withCustomComponent('TeamKpiWidget')
@withTranslation()
@inject('appStore', 'authStore', 'invoicingStore')
@observer
class TeamKpiWidget extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    const { t, authStore, invoicingStore } = this.props;
    if (!authStore.hasLoadedCustomer) authStore.loadCustomer();

    invoicingStore.loadTeamBenefits().catch((ex) => {
      this.setState({
        error: t('Could not load your team metrics.'),
      });
    });
  }

  render() {
    const { t, appStore, authStore, invoicingStore } = this.props;
    const {
      teamBenefits,

      hasLoadedTeamBenefits,
    } = invoicingStore;
    const { hasLoadedCustomer } = authStore;
    const { customer } = authStore;

    if (!hasLoadedTeamBenefits || !hasLoadedCustomer) return <LoadingSpinner />;

    const isCorporateDashboard = appStore.corporateDashboard;

    return teamBenefits.map((metrics, i) => {
      return (
        <div data-component-name={this.props.componentName}>
          {this.state.error && (
            <p className="text-red fs-14">{this.state.error}</p>
          )}

          <h6 className="mt-40">
            {t('Team metrics for {{Name}}', { Name: metrics.TeamName })}
          </h6>

          <div className="row">
            {!isCorporateDashboard && (
              <div className="col-lg-12 col-xl-4 mt-12">
                <AdditionalChargesColumn metrics={metrics} />
              </div>
            )}
            {metrics.RemainingBookingCredit > 0 && (
              <div
                className={`col-lg-12 ${
                  isCorporateDashboard ? 'col-xl-6' : 'col-xl-4'
                } mt-12`}
              >
                <BookingCreditsColumn metrics={metrics} />
              </div>
            )}
            {metrics.TotalRemainingTimePassesMinutes > 0 && (
              <div
                className={`col-lg-12 ${
                  isCorporateDashboard ? 'col-xl-6' : 'col-xl-4'
                } mt-12`}
              >
                <TimePassesColumn metrics={metrics} />
              </div>
            )}
          </div>

          <hr className="mb-28" />

          <div>
            {
              <TeamMetricsWidget
                teamName={metrics.TeamName}
                teamId={metrics.TeamId}
              />
            }
          </div>
        </div>
      );
    });
  }
}
export default TeamKpiWidget;
