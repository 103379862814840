import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import CheckinWidgetSmall from 'ui/_pages/home/components/CheckinWidget/CheckinWidgetSmall';
import InvoicesWidgetSmall from 'ui/_pages/home/components/InvoicesWidget/InvoicesWidgetSmall';
import MessagesWidgetSmall from 'ui/_pages/home/components/MessagesWidget/MessagesWidgetSmall';
import EventsWidgetSmall from 'ui/_pages/home/components/EventsWidget/EventsWidgetSmall';
import VirtualFeedWidget from 'ui/_pages/home/components/VirtualFeedWidget/VirtualFeedWidget';
import BenefitsWidget from 'ui/_pages/home/components/BenefitsWidget/BenefitsWidget';

export const VirtualDashboard = withCustomComponent('VirtualDashboard')(
  inject(
    'appStore',
    'authStore'
  )(
    withRouter(
      observer(({ componentName, appStore, authStore }) => {
        const { t } = useTranslation();
        const { configuration, corporateDashboard, business } = appStore;
        const { customer } = authStore;

        return (
          <div data-component-name={componentName}>
            <div className="dashboard-body">
              <div className="container">
                <div className="row align-items-center mb-32">
                  <div className="col-sm-12">
                    <h2 className="mb-0">
                      {t('Hello')} {customer.GuessedFirstName},
                    </h2>
                    <div className="text-gray-700">
                      {t(
                        `This is what's latest in the {{Name}} community hub.`,
                        { Name: t(business.Name) }
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-8" id="home-feed-widget">
                    <VirtualFeedWidget />
                  </div>
                  <div className="col-xl-4">
                    <h5 className="fw-700 lh-13 mb-20">&nbsp;</h5>

                    {configuration['HomePage.ShowCheckedInMembers'] && (
                      <div className="mb-12" id="home-checkin-widget">
                        {<CheckinWidgetSmall oneLiner={true} />}
                      </div>
                    )}

                    {!corporateDashboard &&
                      configuration['Members.ViewInvoices'] && (
                        <div className="mb-12" id="home-invoices-widget">
                          {<InvoicesWidgetSmall oneLiner={true} />}
                        </div>
                      )}

                    {configuration['PublicWebSite.CommunityBoard'] && (
                      <div className="mb-12" id="home-messages-widget">
                        {<MessagesWidgetSmall oneLiner={true} />}
                      </div>
                    )}

                    {configuration['PublicWebSite.Events'] && (
                      <div className="mb-12" id="home-events-widget">
                        {<EventsWidgetSmall oneLiner={true} />}
                      </div>
                    )}

                    <div className="mb-12" id="home-benefits-widget">
                      <BenefitsWidget />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })
    )
  )
);
