import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import GoCardlessPaymentButton from 'ui/_pages/invoices/paymentButtons/GoCardlessPaymentButton';
import i18next from 'i18next';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import StripeSvg from '../paymentButtons/StripeSvg';

export const StripeACHPaymentForm = withCustomComponent('StripeACHPaymentForm')(
  inject(
    'appStore',
    'authStore'
  )(
    withRouter(
      observer(({ router, appStore, authStore }) => {
        const { business, configuration } = appStore;
        const { t } = useTranslation();
        const { customer } = authStore;
        const $ = window.$;

        let stripe;
        try {
          stripe = window.Stripe(configuration['StripeACH.PublicAPIKey']);
        } catch (err) {
          console.error(err);
          return null;
        }

        const [loading, setLoading] = useState(false);

        const setStripeACHOutcome = async (result) => {
          if (result.token) {
            await authStore.loadMe();

            var data = {
              accessToken: authStore.me.AccessToken,
              bankToken: result.token.id,
            };

            $.ajax({
              type: 'POST',
              url: `${business.NativeHomeUrlWithLanguage}/coworker/StripeACHSetBankTokenAndCreateCustomerWithAccessToken/${customer.Id}`,
              contentType: 'application/json',
              dataType: 'json',
              data: JSON.stringify(data),
              success: async (data) => {
                await authStore.loadCustomer();
                setLoading(false);
              },
              error: (data) => {
                appStore.setPopMessage(t('Sorry, we could not load this page'));

                setLoading(false);
              },
            });
          } else if (result.error) {
            setLoading(false);
            appStore.setPopMessage(
              t('Invalid API key. Please contact the administrator.')
            );
          }
        };

        const submitBankDetailsForm = (e) => {
          e.preventDefault();
          setLoading(true);
          var bankAccountData = {
            country: 'us',
            currency: 'usd',
            routing_number: document.getElementById('routing-number').value,
            account_number: document.getElementById('account-number').value,
            account_holder_name: document.getElementById('name').value,
            account_holder_type: document.getElementById('type').value,
          };
          stripe
            .createToken('bank_account', bankAccountData)
            .then(setStripeACHOutcome);
        };

        const resetBankDetails = async (ev) => {
          ev.preventDefault();
          setLoading(true);
          await authStore.loadMe();

          $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: `https://${configuration['System.RootDomain']}/Callbacks/StripeACHResetByToken/${customer.Id}?accessToken=${authStore.me.AccessToken}`,
            success: async (data) => {
              await authStore.loadCustomer();
              setLoading(false);
            },
            error: (data) => {
              appStore.setPopMessage(t('Sorry, we could not load this page'));
              setLoading(false);
            },
          });
        };

        const submitVerificationForm = async (event) => {
          event.preventDefault();
          setLoading(true);

          await authStore.loadMe();

          var form = $('#stripe-amounts-verification-form');

          $.ajax({
            type: form.attr('method'),
            url: `https://${configuration['System.RootDomain']}/Callbacks/StripeACHVerifyAmountsByToken/${customer.Id}?accessToken=${authStore.me.AccessToken}&doNotUseLegacyErrorHandling=true`,
            data: form.serialize(),
            success: async (data) => {
              if (data.WasSuccessful) {
                await authStore.loadCustomer();
              } else {
                appStore.setPopMessage(t(data.Message));
              }

              setLoading(false);
            },
            error: (data) => {
              appStore.setPopMessage(
                t('Sorry, we could not process your request.')
              );
              setLoading(false);
            },
          });
        };

        const acceptTerms = async (ev) => {
          ev.preventDefault();
          setLoading(true);
          await authStore.loadMe();

          $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: `https://${configuration['System.RootDomain']}/Callbacks/StripeACHAgreeToTermsByToken/${customer.Id}?accessToken=${authStore.me.AccessToken}`,
            success: async (data) => {
              authStore.loadCustomer();
              setLoading(false);
            },
            error: (data) => {
              appStore.setPopMessage(t('Sorry, we could not load this page'));
              setLoading(false);
            },
          });
        };

        return (
          <div className="card card-dashboard mb-32">
            <fieldset className="section--settings" id="stripeach">
              <StripeSvg />
              <h5 className="section__title mb-16 mt-16">
                {t('Stripe ACH Payments')}
              </h5>

              <p className="mxw-600px text-gray-700 fs-14 mb-20">
                {t(
                  'Stripe Automated Clearing House (ACH) allows your bank account to be debited directly. By following this process, you authorize "{{Name}}" to automatically debit your bank account for any due invoices and, if necessary, credit your account to correct any erroneous debits. You will be asked for your bank account details after you agree with this.',
                  { Name: t(business.Name) }
                )}
              </p>
              {customer.HasAcceptedStripeACHAgreement &&
                customer.HasVerifiedStripeACHDeposits && (
                  <div className="alert alert-success">
                    <strong>
                      {t(
                        'Your bank account has been verified and is set up for payments. We will use these details to process any due invoices.'
                      )}
                    </strong>
                  </div>
                )}

              {!customer.HasStripeACHBankToken && (
                <>
                  <p className="mxw-600px text-gray-700 fs-14 mb-20">
                    {t(
                      'Enter your bank account details to complete your ACH agreement.'
                    )}
                  </p>
                  <form
                    id="stripeACHform"
                    method="POST"
                    data-coworkerid={customer.Id}
                    data-sitelang={i18next.language}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label for="">
                            {t('Individual or Company Name')}
                          </label>
                          <input
                            className="form-control"
                            id="name"
                            placeholder={customer.BillingName}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label for="type">{t('Type')}</label>
                          <div className="form-group--date-wrapper">
                            <select
                              type="text"
                              id="type"
                              className="form-control"
                              name="credit_card[month]"
                              placeholder="MM"
                            >
                              <option></option>
                              <option value="individual">
                                {t('Individual')}
                              </option>
                              <option value="company">{t('Company')}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label for="routing-number">
                            {t('Routing Number')}
                          </label>
                          <input
                            className="form-control"
                            id="routing-number"
                            placeholder="110000000"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label for="account-number">
                            {t('Account Number')}
                          </label>
                          <input
                            className="form-control"
                            id="account-number"
                            placeholder="000123456789"
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={submitBankDetailsForm}
                      className="btn "
                    >
                      {loading ? <LoadingSpinner /> : t('Submit')}
                    </button>
                  </form>
                </>
              )}

              {customer.HasStripeACHBankToken &&
                !customer.HasVerifiedStripeACHDeposits && (
                  <>
                    <p className="mxw-600px text-gray-700 fs-14 mt-24 mb-24">
                      {t(
                        'In order to verify your bank account, Stripe has deposited 2 small amounts into your balance.  These deposits should take 1 to 2 days to appear.  When these deposits show in your statement, type the values found after the decimal point into the 2 boxes below to verify your account. For example: for a deposit of $0.62, you would type in 62.'
                      )}
                    </p>
                    <form
                      method="POST"
                      class="payment-form form"
                      id="stripe-amounts-verification-form"
                    >
                      <div class="row">
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="">{t('Amount 1')}</label>
                            <input
                              type="number"
                              class="form-control"
                              id="stripeACHAmt1"
                              name="amount[0]"
                            />
                          </div>
                        </div>
                        <div class="col-sm-2">
                          <div class="form-group">
                            <label for="">{t('Amount 2')}</label>
                            <input
                              type="number"
                              class="form-control"
                              id="stripeACHAmt2"
                              name="amount[1]"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="f btn-group">
                        <button
                          class="btn "
                          id="stripe-ach-verify-amounts"
                          onClick={submitVerificationForm}
                        >
                          {loading ? <LoadingSpinner /> : t('Verify amounts')}
                        </button>
                        <a
                          id="stripe-ach-reset"
                          class="btn btn-outline-gray "
                          onClick={resetBankDetails}
                          href="#"
                        >
                          <span aria-hidden="true" class="fa"></span>{' '}
                          {t('Reset Account Details')}
                        </a>
                      </div>
                    </form>
                  </>
                )}
              {customer.HasVerifiedStripeACHDeposits &&
                !customer.HasAcceptedStripeACHAgreement && (
                  <>
                    <p className="mxw-600px text-gray-700 fs-14 mt-24 mb-24">
                      {t(
                        'By clicking "Accept agreement", you authorize "{{Name}}" to automatically debit your bank account for any due invoices and, if necessary, credit your account to correct any erroneous debits.',
                        { Name: t(business.Name) }
                      )}
                    </p>
                    <div class="btn-group">
                      <a
                        id="stripe-ach-accept"
                        onClick={acceptTerms}
                        class="btn "
                        href="#"
                      >
                        {loading ? <LoadingSpinner /> : t('Accept agreement')}
                      </a>
                    </div>
                  </>
                )}
              {customer.HasAcceptedStripeACHAgreement && (
                <div className="mt-24">
                  <a
                    id="stripe-ach-reset"
                    onClick={resetBankDetails}
                    class="btn "
                    href="#"
                  >
                    {loading ? <LoadingSpinner /> : t('Reset Account Details')}
                  </a>
                </div>
              )}
            </fieldset>
          </div>
        );
      })
    )
  )
);
