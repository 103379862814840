import React, {forwardRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {withRouter} from 'next/router';
import {inject, observer} from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import LoadingLayout from 'ui/layouts/LoadingLayout';

import CardProduct from 'ui/components/cards/CardProduct';
import {StoreZeroCase} from './StoreZeroCase';

let ProductsListComponent = props => {
  const {
    componentName,
    invoicingStore,
    gridClass,
    showZeroCase,
    forwardedRef,
    onCountProducts,
  } = props;
  const {t} = useTranslation();
  const {productsByGroup, isLoadingStoreProducts} = invoicingStore;
  const [once, setOnce] = useState({
    onCountProducts: false,
  });

  if (isLoadingStoreProducts) {
    return <LoadingLayout/>;
  }

  if (
    showZeroCase &&
    !isLoadingStoreProducts &&
    productsByGroup.length == 0
  ) {
    return <StoreZeroCase/>;
  }

  // One-time run events
  if (!once.onCountProducts) {
    setOnce({
      ...once,
      onCountProducts: !once.onCountProducts,
    });

    if (typeof onCountProducts === 'function') {
      onCountProducts(productsByGroup.flatMap(i => i.products).length);
    }
  }

  return (
    <div className="meetings-list">
      <ul data-component-name={componentName} className="list-unstyled m-0">
        {productsByGroup.map((group, gi) => (
          <li key={gi}>
            <div className="row align-items-center mb-24">
              <div className="col-12">
                <h6 className="mb-0">{group.name === 'no-tag' ? t('Other') : t(group.name)}</h6>
              </div>
            </div>
            <ul
              className={`${
                gridClass
                  ? gridClass
                  : 'list-grid list-grid--25 list-grid--lg-33 list-grid--md-50 list-grid--sm-100'
              }`}
            >
              {group.products.map((product, pi) => (
                <li key={pi}>
                  <CardProduct product={product}/>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

ProductsListComponent = withCustomComponent('ProductsList')(
  inject('invoicingStore', 'appStore')(
    withRouter(
      observer(
        ProductsListComponent,
      ),
    ),
  ),
);

export const ProductsList = forwardRef((props, ref) => {
  return <ProductsListComponent forwardedRef={ref} {...props} />;
});
