import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import _ from 'lodash';
import asForm from 'ui/components/forms/asForm';
import { action } from 'mobx';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';
export const EventTicketsPageTicketsListItem = asForm(
  withCustomComponent('EventTicketsPageTicketsListItem')(
    inject(
      'appStore',
      'authStore',
      'eventsStore'
    )(
      withRouter(
        observer(
          ({
            updateProperty,
            product,
            authStore,
            router,
            appStore,
            eventsStore,
          }) => {
            const { t } = useTranslation();
            const { eventTicketsPage } = eventsStore;
            const event = eventTicketsPage.Event;

            const { customer, isLoggedIn } = authStore;

            const updateQuantity = action((quantity) => {
              {
                product.Quantity = quantity;
                while (product.Attendees.length < quantity)
                  product.Attendees.push({
                    FullName: customer.FullName,
                    Email: customer.Email,
                  });
                while (product.Attendees.length > quantity)
                  product.Attendees.pop();
              }
            });

            return (
              <div className="d-block d-md-flex align-items-start justify-content-between mt-16">
                <div className="pt-0 pt-md-10">
                  <h6 className="mb-4">
                    {product.Name}
                    {product.Price > 0 &&
                      <>
                        {' ('}
                        <LocalizedPrice
                          currency={product.PriceCurrencyCode}
                          amount={product.Price}
                        />
                        {')'}
                      </>}
                  </h6>
                  {product.SoldOut && (
                    <p className="fs-14 text-red mb-4">
                      {t('Sold out!')}
                    </p>
                  )}
                  {!product.SoldOut && product.LastFew && (
                    <p className="fs-14 text-green mb-4">
                      {t('Last few tickets!')}
                    </p>
                  )}
                  {product.Description && (
                    <p className="fs-12 text-gray-600 mb-0">
                      {product.Description}
                    </p>
                  )}
                </div>
                <div className="pl-0 pl-md-16 pt-8 pt-md-0">
                  <div className="form-inline">
                    <div className="form-group">
                      <label className="text-gray-700 fw-400 mr-10">{t('Quantity:')}</label>
                      <>
                        {product.IsAvailableNow && !product.SoldOut && (
                          <select
                            name="Quantity"
                            disabled={!isLoggedIn}
                            onChange={(ev) => updateQuantity(ev.target.value)}
                            value={product.Quantity}
                            className="form-control"
                            style={{ width: 72 }}
                          >
                            {_.range(
                              (product.MaxTicketsPerAttendee || 25) + 1
                            ).map((i) => (
                              <option key={i} value={i}>
                                {i}
                              </option>
                            ))}
                          </select>
                        )}
                        {(!product.IsAvailableNow || product.SoldOut) && (
                          <select
                            className="form-control"
                            data-bind="visible: !IsAvailableNow() || SoldOut()"
                            disabled="disabled"
                          >
                            <option>0</option>
                          </select>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        )
      )
    )
  ),
  'product'
);
