import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('BenefitsRow')
@withTranslation()
class BenefitsRow extends Component {
  static propTypes = {
    name: PropTypes.string,
    valueMin: PropTypes.number,
    valueMax: PropTypes.number,
    valueNow: PropTypes.number,
    unit: PropTypes.string,
  };

  render() {
    const {t, name, valueMin, valueMax, valueNow, unit} = this.props;

    return (
      <div className="benefit-item">
        <div className="benefit-item-title">{name}</div>
        <div className="progress-item">
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow={valueNow}
              aria-valuemin={valueMin}
              aria-valuemax={valueMax}
              style={{
                width: (valueNow / valueMax) * 100.0 + '%',
              }}
            ></div>
          </div>

          <div>
            {t('{{valueNow}} / {{valueMax}}', {
              valueNow: valueNow,
              valueMax: valueMax,
            })}
            {unit && <span> {unit}</span>}
          </div>
        </div>
      </div>
    );
  }
}

export default BenefitsRow;
