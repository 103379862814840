import React from 'react';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { LessonPageImage } from 'ui/_pages/courses/lesson/LessonPageImage';
import { LessonPageBody } from 'ui/_pages/courses/lesson/LessonPageBody';

export const LessonPageDetails = withCustomComponent('LessonPageDetails')(
  inject(
    'appStore',
    'coursesStore'
  )(
    withRouter(
      observer(({ componentName }) => {
        return (
          <div data-component-name={componentName}>
            <LessonPageImage />
            <LessonPageBody />
          </div>
        );
      })
    )
  )
);
