import i18next from 'i18next';

export const LocalizedPrice = ({ currency, amount }) =>
  new Intl.NumberFormat(i18next.language, {
    style: 'currency',
    currency: currency,
  }).format(amount);

export const LocalizedNumber = ({ amount }) =>
  new Intl.NumberFormat(i18next.language, {}).format(amount);
