import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
export const DirectoryZeroCase = withCustomComponent('DirectoryZeroCase')(
  inject(
    'appStore',
    'communityStore'
  )(
    withRouter(
      observer(
        ({
          members,
          text,
          showAllText,
          showAllLink,
          componentName,
          router,
        }) => {
          const { t } = useTranslation();

          return (
            <>
              {members == null || (members.length > 0 && <></>)}
              {members && members.length == 0 && (
                <div
                  data-component-name={componentName}
                  className="text-center pt-32"
                >
                  <i className="icon-community fs-64 text-gray-600"></i>
                  <h3 className="mt-12 mb-24">{text}</h3>
                  {router.query.query && (
                    <Link href={showAllLink}>
                      <a className="btn">{showAllText}</a>
                    </Link>
                  )}
                </div>
              )}
            </>
          );
        }
      )
    )
  )
);
