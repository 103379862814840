import React, {useState} from 'react';
import {useTranslation, Trans} from 'react-i18next';
import {withRouter} from 'next/router';
import {inject, observer} from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import asForm from 'ui/components/forms/asForm';
import InputField from 'ui/components/forms/InputField';

export const SignupPagePassword = asForm(
  withCustomComponent('SignupPagePassword')(
    inject(
      'authStore',
      'appStore'
    )(
      withRouter(
        observer(
          ({
             componentName,
             updateProperty,
             customer,
             authStore,
             appStore,
           }) => {
            const {t} = useTranslation();
            const {business} = appStore;
            const {signupCustomFields} = authStore;
            return (
              <fieldset data-component-name={componentName}>
                <div className="card-item-wrapper">
                  <div className="card-item-header">
                    <h5 className="card-item-header-title">
                      {t('Password')}
                    </h5>
                  </div>
                  <div className="card-item-body">
                    <p className="mxw-540px fw-500 fs-14 lh-14">
                      {t(
                        'To keep your account secure your password must have at least one uppercase letter (A-Z), one lowercase letter (a-z), a number (0-9) and a symbol (!@#$%^&*).'
                      )}
                    </p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group required">
                          <InputField
                            type="password"
                            id="customer-password"
                            label={t('Password')}
                            name="Password"
                            errors={authStore.customerValidation.errors.Password}
                            value={customer.Password}
                            onChange={updateProperty}
                            icon="icon-lock"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group required">
                          <InputField
                            type="password"
                            id="customer-password-confirm"
                            label={t('Password confirmation')}
                            name="PasswordConfirm"
                            errors={
                              authStore.customerValidation.errors.PasswordConfirm
                            }
                            value={customer.PasswordConfirm}
                            onChange={updateProperty}
                            icon="icon-lock"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            );
          }
        )
      )
    )
  ),
  'customer'
);
