import React, {useState, useEffect, useRef} from 'react';
import {inject, observer} from 'mobx-react';
import {withRouter} from 'next/router';
import {useTranslation} from 'react-i18next';
import {withCheckTerms} from 'env/withCheckTerms';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import BookingHeader from 'ui/_pages/bookings/components/BookingHeader';
import {NoAccessNotice} from 'ui/components/NoAccessNotice';
import {BookingDetails} from 'ui/_pages/bookings/calendar/BookingDetails';
import {ResourcesMap} from 'ui/_pages/bookings/map/ResourcesMap';
import ResourcesScrollView from 'ui/_pages/bookings/map/ResourcesScrollView';

const ResourcesMapPage = withCustomComponent('ResourcesMapPage')(
  withCheckTerms(
    inject(
      'appStore',
      'authStore',
      'bookingsStore'
    )(
      withRouter(
        observer(({appStore, bookingsStore, componentName, router}) => {
          const {business} = appStore;

          const [error, setError] = useState(null);
          const [center, setCenter] = useState();
          const [showAvailability, setShowAvailability] = useState(false);
          const [hoveringOn, setHoveringOn] = useState(-1);
          const [distance, setDistance] = useState(6000);
          const {t} = useTranslation();

          useEffect(() => {
            doSearch();
          }, [router.query]);

          const doSearch = () => {
            bookingsStore
              .loadResourceSearch({
                ...router.query,
                latitude: 0,
                longitude: 0,
              })
              .catch((err) => {
                appStore.setPopMessage(
                  t('Sorry, we could not load this page. {{err}}', {err})
                );
              });
          };

          const selectShift = (shiftId) => {
            bookingsStore.selectShift(shiftId);
          };

          const selectDates = (dates) => {
            bookingsStore.selectDates(dates);
          };

          const resourcesToShow = bookingsStore.resourceSearch.Resources.filter(
            (r) =>
              center &&
              google.maps.geometry.spherical.computeDistanceBetween(
                center,
                new google.maps.LatLng(r.Latitude ?? 0, r.Longitude ?? 0)
              ) <= distance
          );

          return (
            <DefaultLayout
              data-component-name={componentName}
              title={t('Find available spaces- {{Name}}', {
                Name: business.Name,
              })}
            >
              <main
                className="content content--custom bookings-map-view pt-0"
                id="bookings-map-view"
                role="main"
              >
                <BookingHeader
                  availabilityToggle={(e) => setShowAvailability(e)}
                  doSearch={doSearch}
                  selectShift={selectShift}
                  selectDates={selectDates}
                  shifts={bookingsStore.shifts}
                  isLoadingItems={false}
                  shiftId={bookingsStore.shiftId}
                  fromTime={bookingsStore.fromTime}
                  toTime={bookingsStore.toTime}
                />
                <div className="container">
                  <div className="">
                    <NoAccessNotice error={error}/>

                    <div className="map-container">
                      {bookingsStore.isLoadingResourceSearch && (
                        <LoadingSpinner/>
                      )}
                      {!bookingsStore.isLoadingResourceSearch && (
                        <>
                          <ResourcesMap
                            center={center}
                            setCenter={setCenter}
                            setDistance={setDistance}
                            resourcesToShow={resourcesToShow}
                            setHoveringOn={setHoveringOn}
                            searchResources={doSearch}
                          />
                          {resourcesToShow.length > 0 && (
                            <ResourcesScrollView
                              resourcesToShow={resourcesToShow}
                              hoveringOn={hoveringOn}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <BookingDetails/>
                </div>
              </main>
            </DefaultLayout>
          );
        })
      )
    )
  )
);

export default ResourcesMapPage;
