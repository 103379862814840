import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import asForm from 'ui/components/forms/asForm';
import { CoworkerAvatar } from 'ui/components/CoworkerAvatar';
import ActionButton from 'ui/components/forms/ActionButton';
import TagsInputField from 'ui/components/forms/TagsInputField';

export const TeamMemberPermissionsItem = asForm(
  withCustomComponent('TeamMemberPermissionsItem')(
    inject(
      'appStore',
      'authStore'
    )(
      withRouter(
        observer(
          ({
            appStore,
            authStore,
            saveTeam,
            updateProperty,
            member,
            componentName,
            team,
          }) => {
            const { t } = useTranslation();
            const { configuration } = appStore;
            const fields = [
              {
                name: 'IsTeamAdministrator',
                title: t('Team administrator'),
              },
              {
                name: 'CanMakeBookings',
                title: t('Can make bookings'),
              },
              {
                name: 'CanPurchaseProducts',
                title: t('Can purchase products'),
              },
              {
                name: 'CanPurchaseEvents',
                title: t('Can purchase events'),
              },
              {
                name: 'CanAccessCommunity',
                title: t('Can access community'),
              },
              {
                name: 'AllowNetworkCheckin',
                title: t('Can access network'),
              },
            ];

            const manageAccount = (member) => {
              appStore
                .setYesNotQuestion(
                  t(
                    'This will log you in as {{FullName}}. Do you want to continue?',
                    { FullName: member.FullName }
                  )
                )
                .then((res) => {
                  if (res) {
                    authStore
                      .getTeamMemberAccessToken(member.Id)
                      .then((response) => {
                        authStore.setImpersonationToken(
                          authStore.me.AccessToken
                        );
                        if (response.token) {
                          window.location = `/login?imp=1&t=${
                            response.token
                          }&returnurl=${encodeURIComponent('/profile')}`;
                          return;
                        } else {
                          appStore.setPopMessage(
                            t('Sorry, we could not access this account.')
                          );
                        }
                      })
                      .catch(() =>
                        appStore.setPopMessage(
                          t('Sorry, we could not access this account.')
                        )
                      );
                  }
                });
            };

            return (
              <div
                data-component-name={componentName}
                className="card p-16 p-md-24"
              >
                <div className="d-flex align-items-center mb-24 mb-md-32">
                  <CoworkerAvatar
                    size={200}
                    coworker_id={member.Id}
                    title={member.FullName}
                    imgClassName={'avatar avatar--100'}
                  />
                  <div className="pl-20">
                    <h5 className="mb-4">{member.FullName}</h5>
                    <p className="fs-14 text-gray-600 mb-0">
                      {member.Position}
                      {member.Position && member.CompanyName && (
                        <> {t('at')} </>
                      )}
                      {member.CompanyName}
                    </p>
                  </div>
                </div>

                {fields.map((field, i) => (
                  <div key={i} className="custom-control custom-checkbox mb-6">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={member.Id + '-' + field.name + '-' + team.Id}
                      onChange={(ev) =>
                        updateProperty(ev.target.name, ev.target.checked)
                      }
                      name={field.name}
                      checked={member[field.name]}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={member.Id + '-' + field.name + '-' + team.Id}
                    >
                      {field.title}
                    </label>
                  </div>
                ))}

                {configuration['Forms.TeamAdminsCanUpdateCards'] && (
                  <div className="form-group mt-24">
                    <TagsInputField
                      name="AccessCardId"
                      label={t('Access Cards')}
                      value={member.AccessCardId}
                      onChange={updateProperty}
                    />
                  </div>
                )}

                <div className="d-flex align-items-center justify-content-end">
                  <a
                    onClick={(ev) => {
                      ev.preventDefault();
                      manageAccount(member);
                    }}
                    className="btn btn-outline-gray  mr-8"
                  >
                    {t('Manage Account')}
                  </a>
                  <ActionButton
                    className="btn "
                    action={() => saveTeam()}
                    busy={authStore.isSavingTeamProfile}
                  />
                </div>
              </div>
            );
          }
        )
      )
    )
  ),
  'member'
);
