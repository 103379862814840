import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import SignupPageLayout from 'ui/layouts/SignupPageLayout';
import { privateRoute } from 'env/privateRoute';
import { action } from 'mobx';
import { withRouter } from 'next/router';
import { routes } from 'env/routes';
import LoadingSpinner from 'ui/components/LoadingSpinner';

import ProductsHeader from 'ui/_pages/store/ProductsHeader';
import { ProductsList } from 'ui/_pages/store/ProductsList';
import { ProductsTotal } from 'ui/_pages/store/ProductsTotal';

@privateRoute
@withRouter
@withCustomComponent('SignupProductsPage')
@withTranslation()
@inject('appStore', 'authStore', 'checkoutStore', 'invoicingStore')
@observer
class SignupProductsPage extends Component {
  componentDidMount() {
    const { t, router, appStore, checkoutStore, invoicingStore } = this.props;

    const { selectedPlans } = checkoutStore;
    if (
      !router.query.product_id &&
      !appStore.configuration['Signup.ShowStoreOnSignUp']
    )
      return router.push(routes.signup_summary);

    invoicingStore
      .loadStoreProducts({ selectedPlans })
      .then(
        //Preselect a product if found in the URL
        action((data) => {
          if (data.products.length == 0) {
            router.push(routes.signup_summary);
            return;
          }

          var pid = router.query.product_id;
          if (pid) {
            var foundProduct = data.products.find((p) => p.Id == pid);

            if (foundProduct) {
              foundProduct.Quantity = 1;
              invoicingStore.storeProducts = data;
            }
          }

          return data;
        })
      )
      .catch((err) => {
        appStore.setPopMessage(
          t('Sorry, we could not load this page. {{err}}', { err })
        );
      });
  }

  render() {
    const { t, appStore, invoicingStore } = this.props;
    const { business } = appStore;

    return (
      <SignupPageLayout componentName={this.props.componentName} step={3}>
        {!invoicingStore.hasLoadedStoreProducts && <LoadingSpinner />}
        {invoicingStore.hasLoadedStoreProducts &&
          invoicingStore.storeProducts.products.length > 0 && (
            <>
              {/*<ProductsHeader />*/}
              <ProductsList
                showZeroCase={false}
                gridClass="list-grid list-grid--33 list-grid--lg-50 list-grid--md-100"
              />
              <ProductsTotal
                canContinueIfNoSelection={true}
                continueUrl={routes.signup_summary}
              />
            </>
          )}
      </SignupPageLayout>
    );
  }
}

export default SignupProductsPage;
