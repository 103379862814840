import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import ReactMarkdown from 'react-markdown';
import clip from 'text-clipper';
import { CommunityBoardItemVideoLinks } from './CommunityBoardItemVideoLinks';

export const CommunityBoardItemMessage = withCustomComponent(
  'CommunityBoardItemMessage'
)(
  inject(
    'appStore',
    'communityStore'
  )(
    withRouter(
      observer(
        ({
          communityStore,
          turndownService,
          thread,
          threadDetails,
          componentName,
        }) => {
          const { t } = useTranslation();
          const showFullMessage = () => {
            communityStore.showFullMessage(thread);
          };
          const saveClip = (html) => {
            try {
              return clip(message, 500, {
                html: true,
              });
            } catch {
              return html;
            }
          };
          const message = thread.Message.replace(/(?:\r\n|\r|\n)/g, '<br />');
          const clippedHtml = threadDetails ? message : saveClip(message);

          return (
            <div
              data-component-name={componentName}
              className="community-board-item-message"
            >
              {!thread.ShowFullMessage && (
                <div>
                  <ReactMarkdown
                    source={turndownService.turndown(clippedHtml)}
                  />
                </div>
              )}

              <>
                {clippedHtml != message && (
                  <>
                    {thread.ShowFullMessage && (
                      <div>
                        <ReactMarkdown
                          source={turndownService.turndown(message)}
                        />
                      </div>
                    )}

                    {!thread.ShowFullMessage && (
                      <a
                        href="#"
                        onClick={(ev) => {
                          ev.preventDefault();
                          showFullMessage();
                        }}
                        className="btn btn-xs btn-gray mb-16"
                      >
                        {t('Read more ...')}
                      </a>
                    )}
                  </>
                )}
                {thread.HasZoom && (
                  <a
                    className="btn btn-icon btn-xs mb-16"
                    target="_blank"
                    href={thread.ZoomUrl}
                  >
                    <i className="icon-zoom"></i>
                    {t('Join via Zoom')}
                  </a>
                )}
              </>
            </div>
          );
        }
      )
    )
  )
);
