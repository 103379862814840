import { observer } from 'mobx-react';
import React from 'react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const InputRadioBox = withCustomComponent('InputRadioBox')(
  observer(({isActive, key, name, value, label, onClick, onLabelClick, className}) => {
    return (
      <label
        id='js--radio-label'
        className={`input-radio-box btn btn-outline ${
          isActive ? 'active' : ''
        }`}
        onClick={onLabelClick}
        data-value={value}
      >
        <input
          type="radio"
          key={key}
          name={name}
          value={value}
          onClick={onClick}
          autocomplete="off"
          className={className ? className : ''}
        />
        {label}
      </label>
    );
}))