import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import ProposalPageLayout from 'ui/layouts/ProposalPageLayout';
import { withRouter } from 'next/router';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import { FormPageDetails } from 'ui/_pages/forms/FormPageDetails';
import { FormPageSubmitted } from 'ui/_pages/forms/FormPageSubmitted';

@withRouter
@withCustomComponent('FormPagePage')
@withTranslation()
@inject('appStore', 'communityStore')
@observer
class FormPagePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
    };
  }

  componentDidMount() {
    const { t, router, appStore, communityStore } = this.props;
    communityStore
      .loadFormPagePage(router.query.formPage_guid)
      .then((data) => {
        if (!data.FormPageRequest)
          appStore.setPopMessage(t('We could not load this form.'));
      })
      .catch((ex) => {
        appStore.setPopMessage(t('We could not load this form.'));
      });
  }

  saveFormPage() {
    const { t, appStore, communityStore } = this.props;
    communityStore
      .saveFormPagePage()
      .then(() => {
        this.setState({ submitted: true });
      })
      .catch((ex) => {
        if (ex.validationErrors) appStore.setPopMessage(ex.validationErrors);
        else appStore.setPopMessage(t('We could not save this form.'));
      });
  }
  render() {
    const { t, appStore, communityStore } = this.props;
    const { business } = appStore;
    const { formPagePage, isLoadingFormPagePage } = communityStore;
    if (!formPagePage || isLoadingFormPagePage) return <LoadingLayout />;

    if (!formPagePage.FormPageRequest) return <></>;

    const formPage = formPagePage.FormPageRequest.FormPageDrop;
    const submitted =
      this.state.submitted || formPagePage.FormPageRequest.Submitted;

    return (
      <ProposalPageLayout
        componentName={this.props.componentName}
        title={formPage.Name}
        description={formPage.Description}
      >
        {submitted && <FormPageSubmitted />}
        {!submitted && (
          <>
            <FormPageDetails
              formPagePage={formPagePage}
              saveFormPage={() => this.saveFormPage()}
            />
          </>
        )}
      </ProposalPageLayout>
    );
  }
}

export default FormPagePage;
