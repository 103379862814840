import React from 'react';

import { withRouter } from 'next/router';
import { inject } from 'mobx-react';
import Link from 'next/link';
import { routes } from 'env/routes';
export const TeamAvatar = inject('appStore')(
  withRouter(
    ({ appStore, router, link, linkClassName, imgClassName, team_id, title, size }) => {
      const { business } = appStore;
      const Img = () => (
        <img
          onError={(el) => {
            el.target.style.display = 'none';
          }}
          src={`${business.NativeHomeUrlWithLanguage}/team/getavatar/${team_id}?&h=${size}&w=${size}&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}
          alt={title}
          className={imgClassName}
        />
      );
      return link ? (
        <Link
          as={routes.directory_team_by_id({
            Id: team_id,
            Name: title,
          })}
          href={routes.directory_team_by_id('[team_id]')}
        >
          <a className={linkClassName}>
            <Img />
          </a>
        </Link>
      ) : (
        <Img />
      );
    }
  )
);
