/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import Link from 'next/link';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import moment from 'moment';
import GoCardlessPaymentButton from 'ui/_pages/invoices/paymentButtons/GoCardlessPaymentButton';
import SpreedlyPaymentButton from 'ui/_pages/invoices/paymentButtons/SpreedlyPaymentButton';
import ForteAchPaymentButton from 'ui/_pages/invoices/paymentButtons/ForteAchPaymentButton';
import MidtransPaymentButton from 'ui/_pages/invoices/paymentButtons/MidtransPaymentButton';
import PeachPaymentsPaymentButton from 'ui/_pages/invoices/paymentButtons/PeachPaymentsPaymentButton';
import PayPalPaymentButton from 'ui/_pages/invoices/paymentButtons/PayPalPaymentButton';
import RazorPayPaymentButton from 'ui/_pages/invoices/paymentButtons/RazorPayPaymentButton';
import EpayPaymentButton from 'ui/_pages/invoices/paymentButtons/EpayPaymentButton';
import LiquidPayPaymentButton from 'ui/_pages/invoices/paymentButtons/LiquidPayPaymentButton';
import HostedPaymentProviderButton from 'ui/_pages/invoices/paymentButtons/HostedPaymentProviderButton';
import ResponsiveTable from 'ui/components/tables/ResponsiveTable';
import AuthenticatedLink from 'ui/components/AuthenticatedLink';
import withCustomComponent from 'ui/components/withCustomComponent';
import {withRouter} from 'next/router';
import {routes} from 'env/routes';
import {LocalizedPrice} from 'env/utils/NumbersLocalization';
import {isServer} from 'env/ssr/ServerSideRenderManager';
import KlarnaPaymentButton from './paymentButtons/KlarnaPaymentButton';
import withScript from 'env/utils/withScript';
import ReactPaginate from 'react-paginate';
import i18n from 'env/i18n';
import LoadingLayout from '../../layouts/LoadingLayout';
import DownloadInvoiceButton from './DownloadInvoiceButton';

let PaymentHistory = props => {
  const {
    t,
    componentName,
    appStore,
    authStore,
    invoicingStore,
    filesStore,
    qrPaymentStore,
    router,
    showPaidInvoices = true,
    showUnpaidInvoices = true,
    itemsPerPage = 5,
  } = props;
  const {customer, hasLoadedCustomer} = authStore;
  const {business, configuration, corporateDashboard} = appStore;
  const {
    paidInvoices,
    unpaidInvoices,
    invoicesPage,
    hasLoadedInvoicesPage,
  } = invoicingStore;
  const {StripeACHEnabled} = invoicesPage;
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(null);
  const [itemOffset, setItemOffset] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [myFiles, setMyFiles] = useState([]);
  const [invoiceQRFiles, setInvoiceQRFiles] = useState([]);
  const spreedlyGatewayIsAvailable =
    (customer?.IsMember && configuration['Spreedly.EnabledForMembers']) ||
    (!customer?.IsMember && configuration['Spreedly.EnabledForContacts']);
  let customerLanguage = i18n.language.toLowerCase() ?? 'de';
  customerLanguage = customerLanguage.split('-').shift();

  useEffect(() => {
    invoicingStore.loadInvoices();
    filesStore.loadMyFiles().catch(err => console.error(`Can't load MyFiles.`, err));
  }, []);

  useEffect(() => {
    const {ForteECheckEnabled, EpayEnabled, MidtransEnabled} = invoicesPage;

    if (configuration['ePay.Enabled'] && EpayEnabled) {
      $('.epay').click(payWithEPay);

      function payWithEPay() {
        var invoiceNumber = $(this).attr('data-description');
        var url = $(this).attr('data-payurl');
        var currency = $(this).attr('data-currency');
        var amount = $(this).attr('data-amount');
        var hash = $(this).attr('data-hash');
        var email = $(this).attr('data-email');
        var merchantId = $(this).attr('data-merchant-id');

        const paymentWindow = new PaymentWindow({
          merchantnumber: merchantId,
          amount: amount,
          currency: currency,
          orderid: invoiceNumber,
          instantcapture: 1,
          subscription: 1,
          instantcallback: 1,
          callbackurl: url,
          subscriptionname: email,
          accepturl: `${appStore.business.HomeUrl}/profile/invoices?payment_result=ok`,
          cancelurl: `${appStore.business.HomeUrl}/profile/invoices?payment_result=fail`,
          hash: hash,
        });
        paymentWindow.open();
      }

      if (!isServer()) {
        const script = document.createElement('script');
        script.src =
          'https://ssl.ditonlinebetalingssystem.dk/integration/ewindow/paymentwindow.js';
        document.body.appendChild(script);
      }
    }

    if (configuration['Forte.eCheckPayments'] && ForteECheckEnabled) {
      const forteCallback = (e) => {
        var response = JSON.parse(e.data);
        switch (response.event) {
          case 'begin':
            const message = t(
              'By setting up the following eCheck/ACH agreement, you authorize "{{Name}}" to automatically debit your bank account for any due invoices and, if necessary, credit your account to correct any erroneous debits. You will be asked for your bank account details after you agree with this. Do you want to continue?',
              {Name: t(business.Name)},
            );
            $('.fco2-iframe-container').hide();
            appStore.setYesNotQuestion(message).then((yes) => {
              if (yes) $('.fco2-iframe-container').show();
              else $('.fco2-iframe-container').remove();
            });
            break;
          case 'success':
            const agent = appStore.getAgent();
            agent.requests.post(`/invoices/ForteResult`, response).then(() => {
              appStore.setPopMessage(
                t('Thank you, we\'ll process your payment shortly.'),
              );
              setTimeout(function() {
                window.location.reload(1);
              }, 5000);
            }).catch((xhr) => {
              appStore.setPopMessage(
                t(
                  'Sorry, transaction failed. failed reason is {{responseText}}',
                  {responseText: xhr.responseText},
                ),
              );
            });
            break;
          case 'failure':
            appStore.setPopMessage(
              t(
                'Sorry, transaction failed. failed reason is {{response_description}}',
                {response_description: response.response_description},
              ),
            );
        }
      };

      if (!isServer()) {
        const script = document.createElement('script');
        script.src = 'https://checkout.forte.net/v1/js';
        document.body.appendChild(script);
        window.forteCallback = window.forteCallback ?? forteCallback;
      }
    }

    if (configuration['Midtrans.Enabled'] && MidtransEnabled) {
      //Midtrans scripts
      const midtransScripts = document.createElement('script');
      midtransScripts.src = 'https://app.midtrans.com/snap/snap.js';
      midtransScripts['data-client-key'] =
        appStore.configuration['Midtrans.ClientKey'];
      document.body.appendChild(midtransScripts);
    }

    if (router.query.gc_result == 'ok') {
      //Send to complete if in sign-up flow
      if (router.pathname == routes.signup_payment)
        router.push(routes.signup_complete);
      else router.push(routes.invoices);

      appStore.setPopMessage(
        t('Thank you, your direct debit agreement has been set correctly.'),
      );
    }

    if (router.query.gc_result == 'fail') {
      if (router.pathname != routes.signup_payment)
        router.push(routes.invoices);
      appStore.setPopMessage(
        t('Sorry, your direct debit agreement could not be set.'),
      );
    }

    if (router.query.payment_result == 'fail') {
      //Send to invoices list if not in sign-up flow
      if (router.pathname != routes.signup_payment)
        router.push(routes.invoices);

      appStore.setPopMessage(t('Sorry, we could not process your payment'));
    }

    if (router.query.payment_result == 'awaiting') {
      //Send to complete if in sign-up flow
      if (router.pathname == routes.signup_payment)
        router.push(routes.signup_complete);
      else router.push(routes.invoices);

      appStore.setPopMessage(
        t('Thank you. We will check your payment shortly.'),
      );
    }

    if (router.query.payment_result == 'ok') {
      //Send to complete if in sign-up flow
      if (router.pathname == routes.signup_payment)
        router.push(routes.signup_complete);
      else router.push(routes.invoices);

      appStore.setPopMessage(t('Thank you. We have received your payment.'));
    }
  }, []);

  // Pagination handlers
  useEffect(() => {
    if (!hasLoadedInvoicesPage)
      return;

    setCurrentItems(invoices.slice(itemOffset, itemOffset + itemsPerPage));
    setPageCount(Math.ceil(invoices.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  const getInvoiceIsBeingProcessed = (invoice) => {
    return (
      (invoice.CustomData || invoice.GoCardlessReference) &&
      (invoice.CustomData ?? '').indexOf('tok_') === -1 &&
      (invoice.CustomData ?? '').indexOf('vt_') === -1
    );
  };

  const handlePageClick = e => {
    setItemOffset(e.selected * itemsPerPage);
  };

  if (!hasLoadedCustomer || !hasLoadedInvoicesPage) return <div></div>;

  if (itemOffset === null) {
    let invoices = [];

    if (showUnpaidInvoices) {
      invoices = invoices.concat(unpaidInvoices);
    }

    if (showPaidInvoices) {
      invoices = invoices.concat(paidInvoices);
    }

    setInvoices(invoices);
    setItemOffset(0);
  }

  if (!(invoicingStore.hasLoadedInvoices && filesStore.hasLoadedMyFiles)) {
    return <LoadingLayout/>;
  }

  return (<>
    <fieldset
      className="section--settings p-0 m-0 border-0"
      id="payment-history"
      data-component-name={componentName}
    >
      {customer.HasAcceptedStripeACHAgreement &&
        customer.HasVerifiedStripeACHDeposits &&
        StripeACHEnabled && (
          <div className="alert alert-success mb-32">
            <strong>
              {t(
                'Your bank account has been verified and is set up for payments. We will use these details to process any due invoices.',
              )}
            </strong>
          </div>
        )}

      {invoicesPage.Invoices.length === 0 && (
        <div className="alert alert-outline">
          <i className="icon-invoice"></i>
          <h5>{t('You have no invoices or payments.')}</h5>
          <p>
            {t(
              'You can make a booking, book an event, or buy a product. These are some links you may find useful:',
            )}
          </p>
          <div className="btn-group">
            <Link href={routes.resources}>
              <a className="btn btn-outline-gray btn-icon">
                <i className="icon-bookings"></i>
                {t('Make a Booking')}
              </a>
            </Link>
            <Link href={routes.events}>
              <a className="btn btn-outline-gray btn-icon">
                <i className="icon-calendar-view"></i>
                {t('Book an event')}
              </a>
            </Link>
            <Link href={routes.store}>
              <a
                className="btn btn-outline-gray btn-icon"
                aria-label={t('Cart')}
              >
                <i className="icon-cart"></i>
                {t('Buy a product')}
              </a>
            </Link>
          </div>
        </div>
      )}

      {invoicesPage.Invoices.length > 0 && (
        <ResponsiveTable>
          <thead>
          <tr>
            <th>{t('Invoice')}</th>
            {!corporateDashboard && <th>{t('Date')}</th>}
            {!corporateDashboard && <th>{t('Amount')}</th>}
            {!corporateDashboard && (
              <th>{t('Due')}</th>
            )}
            {!corporateDashboard && <th>{t('Status')}</th>}
            {!corporateDashboard && <th>{t('Actions')}</th>}
            {!corporateDashboard && <th></th>}
          </tr>
          </thead>
          <tbody>
          {currentItems.map((invoice, i) => {
            let Row, SpreedlyGatewayRow;

            if (!invoice.Paid) {
              const invoiceIsBeingProcessed = getInvoiceIsBeingProcessed(invoice);

              if (spreedlyGatewayIsAvailable
                && showUnpaidInvoices
                && !customer.EnableGoCardlessPayments
                && configuration['Spreedly.Enabled'] === 'True'
                && unpaidInvoices.length === 1
                && !getInvoiceIsBeingProcessed(unpaidInvoices[0])
              ) {
                SpreedlyGatewayRow =
                  <SpreedlyPaymentButton
                    className="btn mt-20 mb-20"
                    label={t('Pay by card to complete purchase')}
                    invoice={unpaidInvoices[0]}
                  />;
              }

              Row = (<>
                <tr key={i}>
                  <td data-title={t('Invoice')}>
                    <div>
                      <b>
                        {'#'}
                        {corporateDashboard
                          ? invoice.InvoiceNumber.replace('INV-', '')
                          : invoice.InvoiceNumber}
                      </b>
                    </div>
                    {corporateDashboard && (
                      <small
                        dangerouslySetInnerHTML={{
                          __html: invoice.Description,
                        }}
                      ></small>
                    )}
                    {!corporateDashboard && invoice.CustomData == null && (
                      <small className="mt-0">
                        {customer.HasGoCardlessContractNumber &&
                        customer.EnableGoCardlessPayments
                          ? t(
                            'To be paid by Direct Debit by {{DueDate, L}}',
                            {
                              DueDate: new Date(invoice.DueDate),
                            },
                          )
                          : customer.RegularPaymentProvider ===
                          'StripeACH'
                            ? t(
                              'To be paid by Stripe ACH by {{DueDate, L}}',
                              {
                                DueDate: new Date(invoice.DueDate),
                              },
                            )
                            : customer.RegularPaymentProvider ===
                            'MultiGateway' && customer.CardNumber
                              ? t(
                                'To be paid using card ending in {{CardNumber}} on {{DueDate, L}}',
                                {
                                  CardNumber: customer.CardNumber,
                                  DueDate: new Date(invoice.DueDate),
                                },
                              )
                              : t('To be paid by {{DueDate, L}}', {
                                DueDate: new Date(invoice.DueDate),
                              })}
                      </small>
                    )}
                    {configuration['PeachPayments.Enabled'] && (
                      <PeachPaymentsPaymentButton invoice={invoice}/>
                    )}
                  </td>
                  <td data-title={t('Date')}>
                    <div>
                      {moment(invoice.CreatedOnUtc + 'Z').format('L')}
                    </div>
                    <div>

                    </div>
                  </td>
                  {!corporateDashboard && (
                    <>
                      <td data-title={t('Amount')}>
                        <LocalizedPrice
                          amount={invoice.TotalAmount}
                          currency={invoice.Currency.Code}
                        />
                      </td>

                      <td data-title={t('Due')}>
                        {invoice.DueAmount <= 0 && (
                          <span className="text-green">
                                    <LocalizedPrice
                                      amount={invoice.DueAmount}
                                      currency={invoice.Currency.Code}
                                    />
                                  </span>
                        )}
                        {invoice.DueAmount > 0 && (
                          <span className="text-red">
                                    <LocalizedPrice
                                      amount={invoice.DueAmount}
                                      currency={invoice.Currency.Code}
                                    />
                                  </span>
                        )}
                      </td>

                      {!invoiceIsBeingProcessed && (
                        <>
                          <td data-title={t('Status')}>
                            {moment(invoice.DueDate) > moment() && (
                              <div className="d-inline-flex table-item-status table-item-status-cancelled">
                                {t('Unpaid')}
                              </div>
                            )}
                            {moment(invoice.DueDate) <= moment() && (
                              <div className="d-inline-flex table-item-status table-item-status-cancelled">
                                {t('Due')}
                              </div>
                            )}
                          </td>
                        </>
                      )}

                      {invoiceIsBeingProcessed && (
                        <td data-title={t('Status')}>
                          <div className="d-inline-flex table-item-status">
                            {t('Processing')}
                          </div>
                        </td>
                      )}
                    </>
                  )}
                  <td data-title={t('Actions')}>
                    <DownloadInvoiceButton
                      invoice={invoice}
                    />
                  </td>
                  {!corporateDashboard && <>
                    <td>
                      {!invoiceIsBeingProcessed && <>
                        <div className="table--settings__payment-options">
                          {customer.EnableGoCardlessPayments &&
                            !customer.HasGoCardlessContractNumber && (
                              <GoCardlessPaymentButton
                                returnUrl={
                                  window.location.pathname +
                                  '?gc_result=ok'
                                }
                                failedReturnUrl={
                                  window.location.pathname +
                                  '?gc_result=fail'
                                }
                                invoice={invoice}
                              />
                            )}

                          {spreedlyGatewayIsAvailable &&
                            (customer.EnableGoCardlessPayments || unpaidInvoices.length > 1) &&
                            configuration['Spreedly.Enabled'] === 'True' && (
                              <SpreedlyPaymentButton
                                invoice={invoice}
                              />
                            )}

                          {/* {% if data.Coworker.Id == invoice.Coworker.Id %} */}

                          {configuration[
                            'Forte.eCheckPayments'
                            ] && (
                            <ForteAchPaymentButton
                              invoice={invoice}
                            />
                          )}

                          {configuration['Midtrans.Enabled'] && (
                            <MidtransPaymentButton
                              invoice={invoice}
                            />
                          )}

                          {configuration['Paypal.Enabled'] && (
                            <PayPalPaymentButton
                              invoice={invoice}
                            />
                          )}

                          {configuration['RazorPay.Enabled'] && (
                            <RazorPayPaymentButton
                              invoice={invoice}
                            />
                          )}

                          {configuration['ePay.Enabled'] && (
                            <EpayPaymentButton invoice={invoice}/>
                          )}

                          {configuration['LiqdPay.Enabled'] && (
                            <LiquidPayPaymentButton
                              invoice={invoice}
                            />
                          )}
                          {[...Array(3).keys()].map(
                            (i) =>
                              configuration[
                                `HostedPayments.Provider${
                                  i + 1
                                }.Enabled`
                                ] && (
                                <HostedPaymentProviderButton
                                  key={i}
                                  index={i}
                                  providerIndex={i + 1}
                                  invoice={invoice}
                                />
                              ),
                          )}
                        </div>
                      </>}
                      {SpreedlyGatewayRow}
                    </td>
                  </>}
                </tr>
                {configuration['Klarna.Enabled'] && (
                  <KlarnaPaymentButton invoice={invoice}/>
                )}
              </>);
            } else {
              Row = (<tr key={i}>
                <td data-title={t('Invoice')}>
                  <div>
                    <b>
                      {'#'}
                      {invoice.InvoiceNumber}
                    </b>
                  </div>
                </td>
                <td data-title="Date">{moment(invoice.CreatedOnUtc + 'Z').format('L')}</td>
                {!corporateDashboard && (
                  <>
                    <td data-title="Amount">
                      <LocalizedPrice
                        amount={invoice.TotalAmount}
                        currency={invoice.Currency.Code}
                      />
                    </td>
                    <td data-title="Due">
                      {invoice.DueAmount <= 0 && (
                        <span className="text-green">
                                <LocalizedPrice
                                  amount={invoice.DueAmount}
                                  currency={invoice.Currency.Code}
                                />
                              </span>
                      )}
                      {invoice.DueAmount > 0 && (
                        <span className="text-red">
                                <LocalizedPrice
                                  amount={invoice.DueAmount}
                                  currency={invoice.Currency.Code}
                                />
                              </span>
                      )}
                    </td>
                    <td data-title="Status">
                      {invoice.CreditNote && (
                        <div className="d-inline-flex tag table-item-status">
                          <span>{t('Credit Note')}</span>
                        </div>
                      )}
                      {!invoice.CreditNote && invoice.PaidOn && (
                        <div className="d-flex align-items-center">
                          <div className="d-inline-flex table-item-status">
                            {t('Paid on {{PaidOn, L}}', {
                              PaidOn: new Date(invoice.PaidOn),
                            })}
                          </div>
                        </div>
                      )}
                    </td>
                  </>
                )}
                <td data-title="Actions">
                  {configuration['Members.PrintInvoices'] && (
                    <DownloadInvoiceButton
                      invoice={invoice}
                    />
                  )}
                </td>
                <td></td>
              </tr>);
            }

            return <React.Fragment key={i}>
              {Row}
            </React.Fragment>;
          })}
          </tbody>
          {customer.CurrentBalance > 0 && (
            <tfoot>
            <tr>
              <td colSpan="5"></td>
            </tr>
            <tr>
              <td colSpan="3">
                {t('You account has a current balance of:')}
              </td>
              <td colSpan="2">
                <strong>{customer.CurrentBalance}</strong>
              </td>
            </tr>
            </tfoot>
          )}
        </ResponsiveTable>
      )}
    </fieldset>
    <nav className="bg-white">
      <ReactPaginate
        breakLabel="..."
        nextLabel={(
          <span aria-hidden="true">
                <i className="icon-chevron-right"></i>
              </span>
        )}
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel={(
          <span aria-hidden="true">
                <i className="icon-chevron-right"></i>
              </span>
        )}
        renderOnZeroPageCount={null}
        className="pagination justify-content-center"
        previousLinkClassName="btn prev"
        nextLinkClassName="btn next"
        pageLinkClassName="btn"
        activeClassName="active"
        breakLinkClassName="dots"
      />
    </nav>
  </>);
};

export default PaymentHistory = withCustomComponent('PaymentHistory')(
  withScript('https://x.klarnacdn.net/kp/lib/v1/api.js')(
    withRouter(
      withTranslation()(
        inject('authStore', 'appStore', 'invoicingStore', 'filesStore', 'qrPaymentStore')(
          observer(PaymentHistory),
        ),
      ),
    ),
  ),
);
