import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import usePrevious from 'env/usePrevious';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import { withRouter } from 'next/router';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { Pagination } from 'ui/components/listings/Pagination';
import { NoAccessNotice } from 'ui/components/NoAccessNotice';

import { BlogPageHeader } from 'ui/_pages/blog/BlogPageHeader';
import { BlogPageFilters } from 'ui/_pages/blog/BlogPageFilters';
import { BlogPageList } from 'ui/_pages/blog/BlogPageList';
import { BlogPageZeroCase } from 'ui/_pages/blog/BlogPageZeroCase';

const BlogPage = withCustomComponent('BlogPage')(
  inject(
    'appStore',
    'authStore',
    'blogStore'
  )(
    withRouter(
      observer(
        ({router, appStore, blogStore, componentName}) => {
          const [error, setError] = useState(null);
          const prevQuery = usePrevious(router.query);

          const { t } = useTranslation();
          const { business } = appStore;
          const { blogPosts, hasLoadedBlogPosts } = blogStore;
          const hasError = error != null;

          useEffect(() => {
            if (!blogStore.hasLoadedBlogPosts) {
              blogStore.loadBlogPosts({ ...router.query }).catch((err) => {
                setError(t("You currently don't have access to the articles list."));
              });
            }
          }, []);

          useEffect(() => {
            const currentQuery = router.query;
            if (JSON.stringify(currentQuery) != JSON.stringify(prevQuery)) {
              blogStore.loadBlogPosts({ ...router.query }).catch((err) => {
                setError(t("You currently don't have access to the articles list."));
              });
            }
          });

          return (
            <DefaultLayout
              componentName={componentName}
              title={t('Articles - {{Name}}', { Name: t(business.Name) })}
            >
              <main className="content content--custom" role="main">
                <div className="container">
                  <BlogPageHeader />
                  <BlogPageFilters />
                  {!hasLoadedBlogPosts && <LoadingSpinner />}
                  {hasLoadedBlogPosts && (
                    <>
                      <NoAccessNotice error={error} />
                      <BlogPageList />
                      {!hasError && <BlogPageZeroCase />}
                      <Pagination
                        page={blogPosts.Page}
                        records={blogPosts.BlogPosts}
                        top={blogPosts.Top}
                      />
                    </>
                  )}
                </div>
              </main>
            </DefaultLayout>
          );
        }
      )
    )
  )
);

export default BlogPage;
