import React from 'react';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { EventPageCommentsTitle } from 'ui/_pages/events/event/comments/EventPageCommentsTitle';
import { EventsPageCommentsForm } from 'ui/_pages/events/event/comments/EventsPageCommentsForm';
import { EventsPageCommentsList } from 'ui/_pages/events/event/comments/EventsPageCommentsList';

export const EventPageComments = withCustomComponent('EventPageComments')(
  inject(
    'appStore',
    'eventsStore'
  )(
    withRouter(
      observer(({ componentName, router, appStore, eventsStore }) => {
        const event = eventsStore.eventPage.Event;

        if (!event.AllowComments) return <></>;

        return (
          event.Comments && (
            <div
              data-component-name={componentName}
              className="event-page-comments card h-auto pl-16 pl-md-24 pr-16 pr-md-24 pb-24 pt-4 mt-24">
              <EventPageCommentsTitle />
              <EventsPageCommentsList />
              <EventsPageCommentsForm />
            </div>
          )
        );
      })
    )
  )
);
