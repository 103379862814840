import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation, Trans } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';

import { InvoicesTable } from 'ui/_pages/invoices/InvoicesTable';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import CheckoutComplete from 'ui/_pages/checkout/CheckoutComplete';
import ProposalPageLayout from 'ui/layouts/ProposalPageLayout';

@withCustomComponent('ProposalPaymentPage')
@withTranslation()
@inject('appStore', 'authStore', 'invoicingStore')
@observer
class ProposalPaymentPage extends Component {
  componentDidMount() {
    const { t, appStore, invoicingStore } = this.props;
    invoicingStore.loadInvoicesPage().catch((err) => {
      appStore.setPopMessage(
        t('Sorry, we could not load your invoices. {{err}}', { err })
      );
    });
  }
  render() {
    const { t, invoicingStore, authStore, appStore } = this.props;
    const { isLoadingInvoicesPage, unpaidInvoices } = invoicingStore;
    const { business, configuration } = appStore;
    const { customer } = authStore;
    const keepOnHold = configuration['Signup.KeepNewAccountsOnHold'];
    const needsPaymentDetails =
      customer &&
      ((customer.TariffIds && !customer?.HasSetupRegularPayments) ||
        unpaidInvoices.length > 0);

    return (
      <ProposalPageLayout
        componentName={this.props.componentName}
        componentName={this.props.componentName}
        title={
          needsPaymentDetails
            ? t('Update payment details')
            : t('Join {{Name}}', { Name: t(business.Name) })
        }
      >
        <div className="container">
          <div className="mw-840 pt-48 pb-64">
            <div className="box checkout">
              {isLoadingInvoicesPage && <LoadingSpinner />}
              {!isLoadingInvoicesPage && (
                <>
                  {needsPaymentDetails && (
                    <div className="settings__content">
                      <p>
                        {keepOnHold
                          ? t(
                              "We need your payment details to complete your application. We won't use these details until your account is reviewed and approved."
                            )
                          : t(
                              'We need your payment details to complete your application.'
                            )}
                      </p>
                      <hr className="my-24" />
                      <InvoicesTable
                        showPaidInvoices={false}
                        showPaymentHistory={unpaidInvoices.length > 0}
                      />
                    </div>
                  )}
                  {!needsPaymentDetails && (
                    <>
                      <CheckoutComplete />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </ProposalPageLayout>
    );
  }
}

export default ProposalPaymentPage;
