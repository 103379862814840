import React, { Component } from 'react';
import SelectField from 'ui/components/forms/SelectField';
import { observer, inject } from 'mobx-react';

@inject('appStore')
@observer
class CountrySelectField extends Component {
  componentDidMount() {
    const { appStore } = this.props;
    appStore.loadCountries();
  }

  render() {
    const { appStore } = this.props;
    const { countries, hasLoadedCountries } = appStore;
    return (
      <SelectField {...this.props}>
        {hasLoadedCountries &&
          countries.map((c, i) => (
            <option key={i} value={c.Id}>
              {c.Name}
            </option>
          ))}
      </SelectField>
    );
  }
}

export default CountrySelectField;
