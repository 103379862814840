/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { LocalizedNumber } from 'env/utils/NumbersLocalization';
import withCustomComponent from 'ui/components/withCustomComponent';

@withCustomComponent('TimePassesRow')
@withTranslation()
@inject('appStore')
@observer
class TimePassesRow extends Component {
  render() {
    const { t, appStore, benefit, componentName } = this.props;
    const { business } = appStore;

    const totalFormatted = LocalizedNumber({
      amount: benefit.RemainingTimePassesMinutes / 60,
    });

    return (
      <tr data-component-name={componentName}>
        <td>
          <div className="d-flex align-items-center">
            <img
              className="avatar mr-8"
              src={`${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${benefit.CoworkerId}?h=64&w=64&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}
            />
            <span>{benefit.CoworkerFullName}</span>
          </div>
        </td>
        <td className="text-right">
          {benefit.RemainingTimePassesMinutes > 0 &&
            t('{{totalFormatted}} hr.', {
              totalFormatted: totalFormatted,
              count: benefit.RemainingTimePassesMinutes / 60,
            })}
          {benefit.RemainingTimePassesCount > 0 &&
            benefit.RemainingTimePassesMinutes > 0 && <br />}
          {benefit.RemainingTimePassesCount > 0 &&
            t('{{totalFormatted}} day', {
              totalFormatted: benefit.RemainingTimePassesCount,
              count: benefit.RemainingTimePassesCount / 60,
            })}
        </td>
        <td className="text-right">
          {t('{{totalFormatted}} hr.', {
            totalFormatted: LocalizedNumber({
              amount: benefit.CheckedTimeThisMonth / 60,
            }),
            count: benefit.CheckedTimeThisMonth / 60,
          })}
        </td>
      </tr>
    );
  }
}

export default TimePassesRow;
