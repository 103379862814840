import React, {useEffect, useState} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
import DefaultLayout from 'ui/layouts/DefaultLayout';
import {routeInfo, routes} from 'env/routes';
import Breadcrumbs from 'ui/components/Breadcrumbs';
import {action} from 'mobx';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import {ProductsList} from 'ui/_pages/store/ProductsList';
import {ProductsTotal} from 'ui/_pages/store/ProductsTotal';
import {withRouter} from 'next/router';
import FunnelTitle from 'ui/_pages/funnel/Title';

let FunnelProducts = props => {
  const {
    t,
    router,
    appStore,
    authStore,
    invoicingStore,
    planStore,
    checkoutStore,
  } = props;
  const {business} = appStore;
  const {isLoggedIn} = authStore;
  const {productsByGroup, isLoadingStoreProducts} = invoicingStore;
  const {selectedPlans, invoicePreview} = checkoutStore;
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    // Force redirection if a user isn't logged in
    if (!isLoggedIn) {
      window.location = routes.home;

      return;
    }

    invoicingStore.loadStoreProducts({selectedPlans}).then(
      //Preselect a product if found in the URL
      action((data) => {
        if (data.products.length === 0) {
          router.push(routes.checkout);
          return;
        }

        var pid = router.query.product_id;
        if (pid) {
          var foundProduct = data.products.find((p) => p.Id === pid);

          if (foundProduct) {
            foundProduct.Quantity = 1;
            invoicingStore.storeProducts = data;
          }
        }

        return data;
      }),
    ).catch((err) => {
      appStore.setPopMessage(
        t('Sorry, we could not load this page. {{err}}', {err}),
      );
    });

    // Define breadcrumbs
    if (router.query.from === 'plans') {
      setBreadcrumbs([
        {
          ...routeInfo.plans_plan,
          route: routeInfo.plans_plan.route(planStore.plan.UniqueId),
          class: 'done',
        },
        routeInfo.store_products,
        {name: routeInfo.checkout.name},
      ]);
    }
  }, []);

  if (!invoicingStore.hasLoadedStoreProducts) {
    return <LoadingSpinner/>;
  }

  // Skip if no products in the store
  let productsCount = productsByGroup.flatMap(i => i.products).length;

  if (productsCount === 0) {
    router.push(routes.checkout);

    return;
  }

  return (
    <DefaultLayout>
      <main className="content content--custom pt-0" role="main">
        {breadcrumbs && breadcrumbs.length > 0 && (<div className="our-breadcrumbs mb-16 mb-md-48">
          <div className="container">
            <nav aria-label="breadcrumb">
              <Breadcrumbs
                steps={breadcrumbs}
              />
            </nav>
          </div>
        </div>)}
        <div className="container">
          <div className="funnel-steps-wrapper">
            <FunnelTitle
              title={t('Products')}
            />
            <div className="funnel-steps-body">
              <div className="row">
                <div className="col-lg-12 col-xl-12">
                  {invoicingStore.hasLoadedStoreProducts &&
                    invoicingStore.storeProducts.products.length > 0 && (
                      <>
                        {/*<ProductsHeader />*/}
                        <ProductsList
                          showZeroCase={false}
                          gridClass="list-grid list-grid--33 list-grid--lg-50 list-grid--md-100"
                          onCountProducts={count => {
                            if (count === 0) {

                            }
                          }}
                        />
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <div className="step-buttons">
        <div className="container">
          <div className="btn-group justify-content-end">
            {invoicingStore.hasLoadedStoreProducts &&
              <ProductsTotal
                canContinueIfNoSelection={true}
                continueUrl={routes.checkout}
                cancelUrl={routes.plans_plan(planStore.plan.UniqueId)}
                cancelClass="btn-gray"
              />}
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default FunnelProducts = withCustomComponent('FunnelProducts')(
  withRouter(
    withTranslation()(
      inject('appStore', 'authStore', 'checkoutStore', 'invoicingStore', 'planStore', 'validationStore')(
        observer(FunnelProducts),
      ),
    ),
  ),
);
