import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const FloorPlanBackdrop = withCustomComponent('FloorPlanBackdrop')(
  inject(
    'appStore',
    'floorPlanStore'
  )(
    withRouter(
      observer(({ floorPlanStore, children, componentName }) => {
        const { t } = useTranslation();

        return (
          <div
            data-component-name={componentName}
            className={`box floorplans__map js-floorplans-map mt-20`}
          >
            {children}
            <div
              onClick={(ev) => {
                ev.preventDefault();
                floorPlanStore.setShowTypeFilters(false);
              }}
              className="floorplans__backdrop js-floorplans-backdrop "
            ></div>
          </div>
        );
      })
    )
  )
);
