import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { LocalizedPrice } from 'env/utils/NumbersLocalization';
import { getErrorDescription } from '../../bookings/BookingErrorCodes';

export const CoursePageDiscountSection = withCustomComponent(
  'CoursePageDiscountSection'
)(
  inject(
    'appStore',
    'checkoutStore',
    'coursesStore'
  )(
    withRouter(
      observer(({ componentName, checkoutStore, coursesStore }) => {
        const { t } = useTranslation();
        const { invoicePreview } = checkoutStore;
        const hasInvoiceError = invoicePreview && invoicePreview.Message;
        const { Course } = coursesStore.coursePage;

        const applyDiscount = (discountCode) => {
          checkoutStore.applyDiscount(discountCode);
          checkoutStore.loadPartialInvoicePreview({
            basket: [
              {
                type: 'course',
                Course: {
                  Id: Course.Id,
                },
              },
            ],
          });
        };

        if (!invoicePreview) return <></>;

        return (
          <div>
            <form>
              <h6 className="mb-8">{t('Discount code')}</h6>
              <div className="d-flex align-items-center justify-content-between bg-white rounded border p-8 fs-14 mb-24">
                <input
                  className="w-100 border-0 outline-0"
                  placeholder={t('Enter discount code')}
                  id="discount"
                />
                <button
                  className="btn btn-xs btn-gray"
                  onClick={(ev) => {
                    ev.preventDefault();
                    applyDiscount(document.getElementById('discount').value);
                  }}
                >
                  {t('Apply')}
                </button>
              </div>
            </form>

            {hasInvoiceError && (
              <div className="alert alert-danger mb-24">
                <span>
                  {getErrorDescription(invoicePreview.Message, '', t)}
                </span>
              </div>
            )}

            {invoicePreview?.Lines?.filter((line) => line.DiscountCode).length >
              0 && (
              <div className="alert alert-info mt-20">
                <ul className="list-group list-group-flush">
                  {invoicePreview?.Lines?.filter(
                    (line) => line.DiscountCode
                  ).map((line, i) => (
                    <li
                      data-component-name={componentName}
                      key={i}
                      className="list-group-item"
                    >
                      {t('Discount applied: {{Code}} (', {
                        Code: line.DiscountCode,
                      })}
                      <b>
                        <LocalizedPrice
                          currency={invoicePreview.Currency.Code}
                          amount={line.DiscountAmount}
                        />
                      </b>
                      {')'}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        );
      })
    )
  )
);
