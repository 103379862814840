import React from 'react';
import { useTranslation } from 'react-i18next';
import withCustomComponent from 'ui/components/withCustomComponent';
export const ReplyCount = withCustomComponent('ReplyCount')(
  ({ componentName, message }) => {
    const { t } = useTranslation();
    return (
      <div
        data-component-name={componentName}
        className="event-page-comments-title d-flex align-items-center py-12 mb-16 border-bottom text-gray-700 fs-14"
      >
        {message.Comments.length > 0 && (
          <>
            <i className="icon-replies fs-24 mr-4"></i>
            <span>
              {t('{{count}} comment', {
                count: message.Comments.length,
              })}
            </span>
          </>
        )}
        {message.Comments.length == 0 && !message.Closed && (
          <>
            {t('Be the first one to leave a comment!')}
          </>
        )}
        {message.Comments.length == 0 && message.Closed && (
          <>
            {t('Comments are disabled after the support ticket has been closed.')}
          </>
        )}
      </div>
    );
  }
);
