import React, { useEffect } from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import _ from 'lodash';
import { getColorForResource } from 'ui/_pages/bookings/calendar/ResourceColorHelper';
import { linkTo } from 'env/utils/strings';
import withCustomComponent from 'ui/components/withCustomComponent';
export const CalendarResourceFilter = withCustomComponent(
  'CalendarResourceFilter'
)(
  withRouter(
    inject(
      'appStore',
      'bookingsStore'
    )(
      observer(({ appStore, componentName, bookingsStore, router }) => {
        const { t } = useTranslation();
        const { business } = appStore;

        const { resource_id, resource_type_id, type } = router.query;
        const { resources, resourceGroups, resourceTypes } = bookingsStore;

        const resourceType =
          (resource_type_id || type) &&
          _.find(resourceTypes, { Id: parseFloat(resource_type_id ?? type) });

        return (
          <>
            <li
              data-component-name={componentName}
              className={resourceType ? 'active' : ''}
            >
              <a
                href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {!resourceType && t('Types')}{' '}
                {resourceType && <>{resourceType.Name} </>}
                <span className="caret"></span>
              </a>
              <ul className="dropdown-menu " role="menu">
                <li role="menuitem" tabIndex="-1">
                  <Link
                    href={linkTo(router, {
                      group: null,
                      resource_id: null,
                      type: null,
                    })}
                  >
                    <a>
                      <span className="dropdown-menu--images__title">
                        {t('All resources')}
                      </span>
                      <span className="dropdown-menu--images__subtitle">
                        {t('{{count}} resource', { count: resources.length })}
                      </span>
                    </a>
                  </Link>
                </li>
                {resourceTypes.map((type, i) => (
                  <li
                    key={i}
                    className={
                      resourceType && type.Id === resourceType.Id
                        ? 'active'
                        : ''
                    }
                    role="menuitem"
                    tabIndex="-1"
                  >
                    <Link href={linkTo(router, { group: null, type: type.Id })}>
                      <a>
                        <span className="dropdown-menu--images__title">
                          {type.Name}
                        </span>
                        <span className="dropdown-menu--images__subtitle">
                          {t('{{count}} resource', {
                            count: type.resources.length,
                          })}
                        </span>
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            {resourceGroups.map((group, i) => {
              var selectedResourceInThisGroup =
                group.resources.filter((r) => r.Id == router.query.resource_id)
                  .length > 0;
              return (
                <li
                  key={i}
                  className={
                    selectedResourceInThisGroup ||
                    (group.groupName && router.query.group == group.groupName)
                      ? 'active'
                      : ''
                  }
                >
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {t(group.groupName ?? 'Resources')}
                    <span className="caret"></span>
                  </a>
                  <ul className="dropdown-menu " role="menu">
                    <li role="menuitem" tabIndex="-1">
                      <Link
                        href={linkTo(router, {
                          group: group.groupName,
                          resource_id: null,
                          type: null,
                        })}
                      >
                        <a>
                          <span className="dropdown-menu--images__title">
                            {t('All resources')}
                          </span>
                          <span className="dropdown-menu--images__subtitle">
                            {t('{{count}} resource', {
                              count: group.resources.length,
                            })}
                          </span>
                        </a>
                      </Link>
                    </li>
                    {group.resources.map((resource, k) => (
                      <li
                        key={k}
                        className={resource_id == resource.Id ? 'active' : ''}
                        role="menuitem"
                        tabIndex="-1"
                      >
                        <Link
                          href={routes.calendar_by({
                            resource_id: resource.Id,
                          })}
                        >
                          <a>
                            <span className="dropdown-menu--images__title">
                              {resource.Name}
                            </span>

                            <span className="dropdown-menu--images__subtitle">
                              {resource.ResourceType.Name}
                            </span>
                          </a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              );
            })}
          </>
        );
      })
    )
  )
);
