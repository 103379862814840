module.exports = {
  apiRoot:
    process.env.PUBLIC_URL != ''
      ? process.env.PUBLIC_URL
      : 'https://immodea-development.spaces.nexudus.com',
  storageUrl:
    process.env.STORAGE_URL != ''
      ? process.env.STORAGE_URL
      : 'https://storage.nexudus.com',
  platformRoot:
    process.env.API_URL != ''
      ? process.env.API_URL
      : 'https://spaces.nexudus.com',
  dashboardUrl:
    process.env.DASHBOARD_URL != ''
      ? process.env.DASHBOARD_URL
      : 'https://dashboard.nexudus.com',
};
