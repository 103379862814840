import React from 'react';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import FloorPlanById from 'pages/bookings/floorplans/[floorplan_id]/[slug]';
import { withCheckTerms } from 'env/withCheckTerms';

const FloorPlans = withCustomComponent('FloorPlan')(
  withCheckTerms(
    inject('appStore')(
      observer(({}) => {
        return <FloorPlanById />;
      })
    )
  )
);

export default FloorPlans;
