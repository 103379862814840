import React from 'react';
import { observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import CommunityBoardPage from 'pages/community/index';
import { privateRoute } from 'env/privateRoute';

const CommunityPrivatePage = privateRoute(
  withCustomComponent('CommunityPrivatePage')(
    observer(({ componentName }) => {
      return (
        <CommunityBoardPage
          componentName={componentName}
          privateThreads={true}
        />
      );
    })
  )
);

export default CommunityPrivatePage;
