import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import Link from 'next/link';
import { routes } from 'env/routes';
import _ from 'lodash';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import ReactMarkdown from 'react-markdown';
import { removeHtml } from 'env/utils/strings';

export const CoursePageDetails = withCustomComponent('CoursePageDetails')(
  inject(
    'appStore',
    'authStore',
    'coursesStore'
  )(
    withRouter(
      observer(({ componentName, authStore, appStore, router, coursesStore }) => {
        const { t } = useTranslation();
        const { Course, Host, Lessons: lessons } = coursesStore.coursePage;

        const showCourseDescription = Course.FullDescription || Course.OverviewText
        const showCourseOutline = lessons && lessons.length > 0;
        const showHostProfile = Host && Host.ProfileIsPublic && Host.ProfileSummaryHtml;

        const createList = (Lessons) => {
          let list = [];
      
          for (let i = 0; i < Lessons.length; i++) {
            const lesson = Lessons[i];
      
            if (!lesson.Section) {
              list.push(
                <li key={i.toString()}>
                  <div className="list-item d-flex">
                    <span className="text-gray-900 font-weight-normal">{lesson.Title}</span>
                  </div>
                </li>
              );
              
              continue;
            }
      
            let currSectionId = lesson.Section.Id;
      
            // if lesson has a section, prepare all lessons in that section
            let items = [];
            let j = i;
            while (j < Lessons.length && Lessons[j]?.Section?.Id === currSectionId) {
              items.push(
                <li
                  key={j.toString()}
                >
                  <div className="list-item d-flex">
                    <span className="text-gray-900 font-weight-normal">{Lessons[j].Title}</span>
                  </div>
                </li>
              );
      
              j++;
            }
      
            // add section name and respective lessons
            list.push(
              <li
                key={lesson.Section.Title}
              >
                <div className="list-item-collapsible d-flex" onClick={() => toggleCollapse(lesson.Section.Title)}>
                  <i className={'icon icon-dropdown-large'} id={`${lesson.Section.Title}-dropdown-icon`}></i>
                  <span className="text-gray-900 font-weight-normal">{lesson.Section.Title}</span>
                </div>
      
                <ul className='d-none' id={`${lesson.Section.Title}-list`}>{items}</ul>
              </li>
            );
      
            if (i < Lessons.length - 1) {
              i = j - 1; // skip to next element outside current section
            }
          }
      
          return list;
        };

        const toggleCollapse = (sectionTitle) => {
          document.getElementById(`${sectionTitle}-list`).classList.toggle('d-none');
          document.getElementById(`${sectionTitle}-dropdown-icon`).classList.toggle('upside-down');
        }
        
        return (
          <div
            data-component-name={componentName}
            className="course-page-details card p-16 p-md-32 pb-16 mt-24 h-auto"
          >
            <div className="article-content fr-view">
              {showCourseDescription && (
                <>
                  <h5>{t("What's this course about?")}</h5>
  
                  <ReactMarkdown
                    className="fs-16"
                    source={removeHtml(Course.FullDescription)}
                  />

                  {Course.ShowOverview && Course.OverviewText && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: Course.OverviewText ?? '',
                      }}
                    ></div>
                  )}
                </>
              )}

              {((showCourseDescription && showCourseOutline) || (showCourseOutline && showHostProfile)) && (<hr></hr>)}

              {showCourseOutline && (
                <>
                  <h5>{t("Course Outline")}</h5>

                  <nav
                    className="nav-lateral lessons"
                    id="js-nav-smooth"
                    role="navigation"
                  >
                    <ul className="course-outline-list">
                      {createList(lessons)}
                    </ul>
                  </nav>
                </>
              )}

              {((showCourseDescription && showHostProfile) || (showCourseOutline && showHostProfile)) && (<hr></hr>)}

              {showHostProfile && (
                <>
                  <h5>{t("About Lecturer")}</h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: Host.ProfileSummaryHtml,
                    }}
                  ></p>
                </>
              )}
            </div>
          </div>
        );
      })
    )
  )
);
