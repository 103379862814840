import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {action} from 'mobx';
import PropTypes from 'prop-types';
import Link from 'next/link';
import {routes} from 'env/routes';
import {withTranslation} from 'react-i18next';
import {slugify, removeHtml} from 'env/utils/strings';
import withCustomComponent from 'ui/components/withCustomComponent';
import moment from 'moment';

@withCustomComponent('CardSupport')
@withTranslation()
@inject('appStore')
@observer
class CardSupport extends Component {
  static propTypes = {
    message: PropTypes.object,
  };

  render() {
    const {message, t, componentName, appStore} = this.props;
    const {business} = appStore;

    return (
      <tr data-component-name={componentName} className={`card-support`}>
        {message && (
          <>
            <td data-title="Issue" className="table-item-name">
              <Link
                href={routes.support_message('[message_id]')}
                as={routes.support_message(message.Id)}>
                <a className="card-support__content p-0 m-0">
                  <span>{message.Subject}</span>
                </a>
              </Link>
            </td>
            <td data-title="Department">
              {message.Department && (
                <span className="d-inline-flex tag m-0">
                      {message.Department.Name}
                    </span>
              )}
            </td>
            <td data-title="Date created  ">
              <div className="fs-14">
                <time>
                  {moment(message.CreatedOnUtc + 'Z').format('ll')}
                </time>
                {' - '}
                <time>
                  {moment(message.CreatedOnUtc + 'Z').format('LT')}
                </time>
              </div>
            </td>
            <td data-title="Created by	">
              <img
                className="avatar mr-8"
                src={`${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${message.Coworker.Id}?h=64&w=64&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}
                alt={message.Coworker.FullName}
              />
              <span className="fs-14">{message.Coworker.FullName}</span>
            </td>
            <td data-title="Replies">
              <div className="d-flex align-itema-center">
                <i className="icon-replies fs-24 mr-4"></i>
                <span>{message.Comments.length}</span>
              </div>
            </td>
            <td data-title="Status">
              {message.Closed ? (
                <span className="d-inline-flex tag m-0 with-icon-left bg-red-02">
                      <i className="icon-remove"></i>
                  {t('Closed')}
                    </span>
              ) : (
                <>
                  {message.Comments.length > 0 ? (
                    <span className="d-inline-flex tag m-0 with-icon-left bg-green-02">
                          <i className="icon-reply-received"></i>
                      {t('Replied')}
                        </span>
                  ) : (
                    <span className="d-inline-flex tag m-0 with-icon-left bg-orange-02">
                          <i className="icon-alert"></i>
                      {t('Open')}
                        </span>
                  )}
                </>
              )}
            </td>
          </>
        )}
      </tr>
    );
  }
}

export default CardSupport;
