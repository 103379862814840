import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import AdditionalChargesRow from 'ui/_pages/home/components/TeamKpiWidget/AdditionalChargesRow';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import SortableTableHeader from 'ui/components/tables/SortableTableHeader';
import Link from 'next/link';
import i18next from 'i18next';
import { routes } from 'env/routes';
import withCustomComponent from 'ui/components/withCustomComponent';
import CardDashboard from 'ui/components/cards/CardDashboard';

@withCustomComponent('AdditionalChargesColumn')
@withTranslation()
@inject('appStore', 'invoicingStore')
@observer
class AdditionalChargesColumn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortField: 'CoworkerFullName',
      sortDirection: 'asc',
    };
  }

  render() {
    const { componentName, metrics, t, appStore } = this.props;
    const { business } = appStore;
    const totalFormatted = new Intl.NumberFormat(i18next.language, {
      style: 'currency',
      currency: business.Currency.Code,
    }).format(metrics.TotalUninvoiced);

    const sortedKpi = _.orderBy(
      metrics.KPI,
      this.state.sortField,
      this.state.sortDirection
    );

    return (
      <CardDashboard
        headerMainTitle={t('Additional charges')}
        headerActionTitle={t('View all')}
        headerActionUrl={routes.profile_team}
        componentName={this.props.componentName}
      >
        <div className="d-flex flex-column justify-content-between h-100">

          <table className="table table--sortable table--kpi mt-16">
            <thead>
              <tr>
                <SortableTableHeader
                  onClick={({ sortField, sortDirection }) =>
                    this.setState({ sortField, sortDirection })
                  }
                  label={t('Name')}
                  sortField="CoworkerFullName"
                  currentSortField={this.state.sortField}
                  currentSortDirection={this.state.sortDirection}
                />
                <SortableTableHeader
                  onClick={({ sortField, sortDirection }) =>
                    this.setState({ sortField, sortDirection })
                  }
                  className={'text-right'}
                  label={t('Amount')}
                  sortField="TotalUninvoiced"
                  currentSortField={this.state.sortField}
                  currentSortDirection={this.state.sortDirection}
                />
              </tr>
            </thead>
            <tbody>
              {sortedKpi.map((benefit, i) => (
                <AdditionalChargesRow key={i} benefit={benefit} />
              ))}
            </tbody>
          </table>

          <div>
            <div className="text-gray-700 fs-14 border-top pt-16 mb-4">{t('Total to invoice')}</div>
            <h4 className="mb-0">{totalFormatted}</h4>
          </div>

        </div>
      </CardDashboard>
    );
  }
}
export default AdditionalChargesColumn;
