import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

import CardBlogPost from 'ui/components/cards/CardBlogPost';

export const BlogPageList = withCustomComponent('BlogPageList')(
  inject(
    'appStore',
    'blogStore'
  )(
    withRouter(
      observer(({ router, componentName, blogStore }) => {
        const { t } = useTranslation();
        const { blogPosts } = blogStore;
        return (
          <div
            data-component-name={componentName}
            className="blog-page-list"
          >
            <ul className="list-grid list-grid--33 list-grid--lg-50 list-grid--md-50 list-grid--sm-100">
              {blogPosts.BlogPosts.map((blogPost, i) => (
                <li key={i}>
                  <CardBlogPost post={blogPost} />
                </li>
              ))}
            </ul>
          </div>
        );
      })
    )
  )
);
