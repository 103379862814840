import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import usePrevious from 'env/usePrevious';

import DefaultLayout from 'ui/layouts/DefaultLayout';
import withCustomComponent from 'ui/components/withCustomComponent';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { withRouter } from 'next/router';
import { CommunityBoardHeader } from 'ui/_pages/community/list/CommunityBoardHeader';
import { CommunityBoardFilters } from 'ui/_pages/community/list/CommunityBoardFilters';
import { CommunityBoardZeroCase } from 'ui/_pages/community/list/CommunityBoardZeroCase';
import { CommunityBoardList } from 'ui/_pages/community/list/CommunityBoardList';
import { initTimeAgo } from 'env/utils/dateUtil';
import { Pagination } from 'ui/components/listings/Pagination';
import { NoAccessNotice } from 'ui/components/NoAccessNotice';


const CommunityBoardPage = withCustomComponent('CommunityBoardPage')(
  inject(
    'appStore',
    'authStore',
    'communityStore'
  )(
    withRouter(
      observer(({
        appStore,
        communityStore,
        privateThreads,
        me,
        componentName,
        router
      }) => {
        const [error, setError] = useState(null);
        const prevQuery = usePrevious(router.query);
        const { t } = useTranslation();

        const { business } = appStore;
        const { isLoadingCommunityPage, communityPage } = communityStore;
    
        const group = communityPage.Group;
        const threads = communityPage.Threads;
        const hasError = error != null;

        useEffect(() => {
          communityStore
            .loadCommunityPage({ me, privateThreads, ...router.query })
            .catch((err) => {
              setError(t("You currently don't have access to the community board."));
            })
            .then(initTimeAgo);
        }, []);

        useEffect(() => {
          const currentQuery = router.query;
          if (JSON.stringify(currentQuery) != JSON.stringify(prevQuery))
            communityStore
              .loadCommunityPage({ me, privateThreads, ...router.query })
              .catch((err) => {
                setError(t("You currently don't have access to the community board."));
              })
              .then(initTimeAgo);
        }, [router.query]);

        return (
          <DefaultLayout
            componentName={componentName}
            title={t('Community Boards - {{Name}}', { Name: t(business.Name) })}
          >
            <main className="content content--custom" role="main">
              <div className="container">
                <CommunityBoardHeader
                  group={communityPage.Group}
                  canStartConversation={communityPage.CanStartConversation}
                  privateThreads={communityPage.PrivateThreads}
                />
                <CommunityBoardFilters
                  group={group}
                  groups={communityPage.Groups}
                />
                <NoAccessNotice error={error} />
                {isLoadingCommunityPage && <LoadingSpinner />}
                {!isLoadingCommunityPage && (
                  <>
                    {!hasError && (
                      <CommunityBoardZeroCase
                        group={group}
                        threads={threads}
                        privateThreads={communityPage.PrivateThreads}
                        canStartConversation={communityPage.CanStartConversation}
                      />
                    )}
                    <CommunityBoardList threads={threads} />
                    <Pagination
                      page={router.query.page}
                      records={threads}
                      top={router.query.top}
                    />
                  </>
                )}
              </div>
            </main>
          </DefaultLayout>
        );
      })
    )
  )
);

export default CommunityBoardPage;
