import {required} from 'validx';
import global from 'global';

export const planDetailsValidationSchema = () => {
  const t = global.t;

  return {
    // The schema maps to the fields we are validating.
    startDate: [required({msg: t('Start date is required')})],
  };
};
