import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const CommunityBoardItemSeeRepliesButton = withCustomComponent(
  'CommunityBoardItemSeeRepliesButton'
)(
  inject(
    'appStore',
    'authStore',
    'communityStore'
  )(
    withRouter(
      observer(({ thread, router, componentName }) => {
        const { t } = useTranslation();

        return (
          <Link
            href={{
              pathname: routes.thread_by_id('[thread_id]'),
              query: {
                _top: router.query.top,
                _page: router.query.page,
              },
            }}
            as={routes.thread_by_id(thread) + '#comments-list'}
          >
            <a className="btn btn-icon btn-link text-gray-900">
              <i className="icon-arrow-bend-down mr-4"></i>
              <span className="fs-14 lh-13">{t('View all replies')}</span>
            </a>
          </Link>
        );
      })
    )
  )
);
