import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import ProfilePageLayout from 'ui/layouts/ProfilePageLayout';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import { privateRoute } from 'env/privateRoute';
import { withRouter } from 'next/router';
import { routes } from 'env/routes';
import ResponsiveTable from 'ui/components/tables/ResponsiveTable';
import moment from 'moment';
import Link from 'next/link';
import { withCustomComponent } from 'ui/components/withCustomComponent';
import Head from 'next/head';

@withCustomComponent('ProfileEventsPage')
@privateRoute
@withRouter
@withTranslation()
@inject('appStore', 'authStore', 'eventsStore')
@observer
class ProfileEventsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { t, appStore, eventsStore } = this.props;
    eventsStore.loadMyEvents().catch(() => {
      appStore.setPopMessage(t('Could not load your event tickets.'));
    });
  }

  cancelTicket = (ticket) => {
    const { t, eventsStore } = this.props;
    const { appStore } = this.props;
    appStore
      .setYesNotQuestion(t('Do you really want to cancel this ticket?'))
      .then((response) => {
        if (response)
          eventsStore
            .cancelTicket(ticket.Id)
            .then(() => eventsStore.loadMyEvents())
            .catch((err) =>
              appStore.setPopMessage(
                t('We could not cancel this ticket. ' + err)
              )
            )
            .finally(() =>
              this.setState({
                ['clicked' + ticket.Id]: true,
              })
            );
      });
  };

  sendTicket = (ticket) => {
    const { t, appStore, eventsStore } = this.props;
    eventsStore
      .sendTicket(ticket.Id)
      .then(() =>
        appStore.setPopMessage(
          t('Your ticket has been sent to {{Email}}.', {
            Email: ticket.Email,
          })
        )
      )
      .catch((err) =>
        appStore.setPopMessage(t('We could not send your ticket. ' + err))
      )
      .finally(() =>
        this.setState({
          ['clicked' + ticket.Id]: true,
        })
      );
  };

  render() {
    const { t, appStore, eventsStore } = this.props;
    const { myEvents, hasLoadedMyEvents, isSendingTicket, isCancellingTicket } =
      eventsStore;
    const hasLoadedData = hasLoadedMyEvents;

    return (
      <ProfilePageLayout componentName={this.props.componentName}>
        <Head>
          <title>
            {t('My events')} - {appStore.business?.Name}
          </title>
        </Head>
        {!hasLoadedData && (
          <form id="profile-form">
            <fieldset className="section--settings" id="your-plan">
              <legend className="h3 section__title">
                <LoadingSpinner />
              </legend>
            </fieldset>
          </form>
        )}
        {hasLoadedData && (
          <form className="profile-form">
            <div className="card card-dashboard mb-32">
              <fieldset>
                <h5 className="section__title">{t('My upcoming events')}</h5>

                {myEvents.UpcomingEvents.length == 0 && (
                  <div className="alert alert-outline">
                    <i className="icon-calendar-view"></i>
                    <h5>{t(`You've got no upcoming events.`)}</h5>
                    <p>
                      {t(
                        'You can book an event by checking available events. These are some links you may find useful:'
                      )}
                    </p>
                    <div className="btn-group">
                      <Link href={routes.events}>
                        <a className="btn btn-outline-gray btn-icon">
                          <i className="icon-calendar-view"></i>
                          {t('Book an event')}
                        </a>
                      </Link>
                    </div>
                  </div>
                )}

                {myEvents.UpcomingEvents.length > 0 && (
                  <>
                    <ResponsiveTable>
                      <thead>
                        <tr>
                          <th>{t('Event')}</th>
                          <th>{t('Attendee')}</th>
                          <th>{t('Date')}</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {myEvents.UpcomingEvents.map((ticket, i) => (
                          <tr key={i}>
                            <td valign="middle">
                              <div className="d-flex align-items-center">
                                <div className="pr-12">
                                  <Link
                                    href={routes.event_by_id('[event_id]')}
                                    as={routes.event_by_id(
                                      ticket.CalendarEvent
                                    )}
                                  >
                                    <img
                                      className="avatar avatar--40 rounded"
                                      src={`${appStore.business.NativeHomeUrlWithLanguage}/events/getlargeimage?id=${ticket.CalendarEvent.Id}&w=80&h=80&cache=${ticket.CalendarEvent.UpdatedOn}`}
                                    />
                                  </Link>
                                </div>
                                <div>
                                  <Link
                                    href={routes.event_by_id('[event_id]')}
                                    as={routes.event_by_id(
                                      ticket.CalendarEvent
                                    )}
                                  >
                                    <a className="text-gray-900 fw-500">
                                      {ticket.CalendarEvent.Name}
                                    </a>
                                  </Link>
                                  <small className="mt-2">
                                    {ticket.EventProductName}
                                  </small>
                                </div>
                              </div>
                            </td>
                            <td valign="middle">
                              <div>{ticket.FullName}</div>
                              <small className="mt-2">{ticket.Email}</small>
                            </td>
                            <td valign="middle">
                              {moment(ticket.CalendarEvent.StartDate).format(
                                'L'
                              )}
                            </td>
                            <td valign="middle">
                              <div className="table--settings__payment-options">
                                <a
                                  className="btn btn-icon btn-link btn-xs text-gray-900"
                                  href="#"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    this.setState({
                                      ['clicked' + ticket.Id]: true,
                                    });
                                    this.sendTicket(ticket);
                                  }}
                                >
                                  {this.state['clicked' + ticket.Id] &&
                                  isSendingTicket ? (
                                    <LoadingSpinner />
                                  ) : (
                                    <>
                                      <i
                                        aria-hidden="true"
                                        className="icon-email"
                                      ></i>
                                      {t('Send ticket')}
                                    </>
                                  )}
                                </a>
                                <a
                                  className="btn btn-icon btn-link btn-xs text-gray-900"
                                  href="#"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    this.setState({
                                      ['clicked' + ticket.Id]: true,
                                    });
                                    this.cancelTicket(ticket);
                                  }}
                                >
                                  {this.state['clicked' + ticket.Id] &&
                                  isCancellingTicket ? (
                                    <LoadingSpinner />
                                  ) : (
                                    <>
                                      <i
                                        aria-hidden="true"
                                        className="icon-remove"
                                      ></i>
                                      {t('Cancel ticket')}
                                    </>
                                  )}
                                </a>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </ResponsiveTable>
                    <div className="btn-group mt-32">
                      <Link href={routes.events}>
                        <a className="btn btn-icon">
                          <i className="icon-ticket"></i>
                          {'See more events'}
                        </a>
                      </Link>
                    </div>
                  </>
                )}
              </fieldset>
            </div>
            {myEvents.PastEvents.length > 0 && (
              <div className="card card-dashboard mb-32">
                <fieldset>
                  <h5 className="section__title">{t('My past events')}</h5>

                  <ResponsiveTable>
                    <thead>
                      <tr>
                        <th>{t('Event')}</th>
                        <th>{t('Attendee')}</th>
                        <th>{t('Date')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {myEvents.PastEvents.map((ticket, i) => (
                        <tr key={i}>
                          <td valign="middle">
                            <div className="d-flex align-items-center">
                              <div className="pr-12">
                                <Link
                                  href={routes.event_by_id('[event_id]')}
                                  as={routes.event_by_id(ticket.CalendarEvent)}
                                >
                                  <img
                                    className="avatar avatar--40 rounded"
                                    src={`${appStore.business.NativeHomeUrlWithLanguage}/events/getlargeimage?id=${ticket.CalendarEvent.Id}&w=80&h=80&cache=${ticket.CalendarEvent.UpdatedOn}`}
                                  />
                                </Link>
                              </div>
                              <div>
                                <Link
                                  href={routes.event_by_id('[event_id]')}
                                  as={routes.event_by_id(ticket.CalendarEvent)}
                                >
                                  <a className="text-gray-900 fw-500">
                                    {ticket.CalendarEvent.Name}
                                  </a>
                                </Link>
                                <small className="mt-2">
                                  {ticket.EventProductName}
                                </small>
                              </div>
                            </div>
                          </td>
                          <td valign="middle">
                            <div>{ticket.FullName}</div>
                            <small className="mt-2">{ticket.Email}</small>
                          </td>
                          <td valign="middle">
                            {moment(ticket.CalendarEvent.StartDate).format('L')}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </ResponsiveTable>
                </fieldset>
              </div>
            )}
          </form>
        )}
      </ProfilePageLayout>
    );
  }
}

export default ProfileEventsPage;
