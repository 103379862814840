import React from 'react';
import Link from 'next/link';
import {routes} from 'env/routes';
import {useTranslation} from 'react-i18next';
import {withRouter} from 'next/router';
import {inject, observer} from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import asForm from 'ui/components/forms/asForm';
import {CoworkerAvatar} from 'ui/components/CoworkerAvatar';
import ActionButton from 'ui/components/forms/ActionButton';
import TagsInputField from 'ui/components/forms/TagsInputField';
import {action, toJS} from 'mobx';

const revokeOnlineAccessTpl = `
Hi!

I am "[my_name]" with "[my_email]" email and I am the admin of the "[my_team_name]" team.

Please revoke online access for "[member_name]" with "[member_email]" user.

Kind regards
`;

export const TeamMemberPermissionsRow = asForm(
  withCustomComponent('TeamMemberPermissionsRow')(
    inject(
      'appStore',
      'authStore',
      'supportStore'
    )(
      withRouter(
        observer(
          ({
             appStore,
             authStore,
             supportStore,
             updateProperty,
             member,
             componentName,
             team,
             key
           }) => {
            const {t} = useTranslation();
            const {configuration} = appStore;
            const fields = [
              {
                name: 'IsTeamAdministrator',
                title: t('Team administrator'),
              },
              {
                name: 'CanMakeBookings',
                title: t('Can make bookings'),
              },
              {
                name: 'CanPurchaseProducts',
                title: t('Can purchase products'),
              },
              // {
              //   name: 'CanPurchaseEvents',
              //   title: t('Can purchase events'),
              // },
              // {
              //   name: 'CanAccessCommunity',
              //   title: t('Can access community'),
              // },
              // {
              //   name: 'AllowNetworkCheckin',
              //   title: t('Can access network'),
              // },
            ];

            const manageAccount = (member) => {
              appStore
                .setYesNotQuestion(
                  t(
                    'This will log you in as {{FullName}}. Do you want to continue?',
                    {FullName: member.FullName}
                  )
                )
                .then((res) => {
                  if (res) {
                    authStore
                      .getTeamMemberAccessToken(member.Id)
                      .then((response) => {
                        authStore.setImpersonationToken(
                          authStore.me.AccessToken
                        );
                        if (response.token) {
                          window.location = `/login?imp=1&t=${
                            response.token
                          }&returnurl=${encodeURIComponent('/profile')}`;
                          return;
                        } else {
                          appStore.setPopMessage(
                            t('Sorry, we could not access this account.')
                          );
                        }
                      })
                      .catch(() =>
                        appStore.setPopMessage(
                          t('Sorry, we could not access this account.')
                        )
                      );
                  }
                });
            };

            const revokeOnlineAccess = action(member => {
              supportStore.createNewMessage();
              supportStore.newMessage.Subject = `Revoke online access for ${member.Email}`;
              supportStore.newMessage.MessageText = t(revokeOnlineAccessTpl).
                replaceAll('[my_name]', authStore.me.FullName).
                replaceAll('[my_email]', authStore.me.Email).
                replaceAll('[my_team_name]', team.Name).
                replaceAll('[member_name]', member.FullName).
                replaceAll('[member_email]', member.Email).
                trim();

              supportStore.submitNewMessage().then(() => {
                appStore.setPopMessage(
                  t(
                    'Thank you for your message. We will get back to you as soon as possible.',
                  ),
                ).then(() => {});
              }).catch((err) => {
                appStore.setPopMessage(
                  err.data ? err.data.ErrorMessage : err,
                );
              });
            });

            return (
              <tr key={key} data-component-name={componentName}>
                <td className="align-middle">
                  <div className="d-flex align-items-center">
                    <CoworkerAvatar
                      title={member.FullName}
                      coworker_id={member.Id}
                      size={96}
                      imgClassName="avatar avatar--48"
                    />
                    <span className="fs-16 ml-12">{member.FullName}</span>
                  </div>
                </td>

                {fields.map((field, i) => (
                  <td key={i} className="align-middle">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={member.Id + '-' + field.name + '-' + team.Id}
                        onChange={(ev) =>
                          updateProperty(ev.target.name, ev.target.checked)
                        }
                        name={field.name}
                        checked={member[field.name]}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={member.Id + '-' + field.name + '-' + team.Id}
                      >
                      </label>
                    </div>
                  </td>
                ))}

                {configuration['Forms.TeamAdminsCanUpdateCards'] && (
                  <td className="align-middle">
                    <TagsInputField
                      name="AccessCardId"
                      noLabel
                      value={member.AccessCardId}
                      onChange={updateProperty}
                    />
                  </td>
                )}

                <td className="access-cell align-middle">
                  <div
                    className="access d-flex align-items-center"
                    onClick={(ev) => {
                      ev.preventDefault();
                      manageAccount(member);
                    }}
                  >
                    <a
                      className="text-gray-900 align-middle mr-8 access-text"
                    >
                      {t('Access')}
                    </a>
                    <i className="icon-chevron-right-new"></i>
                  </div>
                </td>

                <td className="access-cell align-middle">
                  <div
                    className="access d-flex align-items-center"
                    onClick={(ev) => {
                      ev.preventDefault();
                      revokeOnlineAccess(member);
                    }}
                  >
                    <div className="btn btn-sm">
                      {t('Revoke access')}
                    </div>
                  </div>
                </td>
              </tr>
            );
          }
        )
      )
    )
  ),
  'member'
);
