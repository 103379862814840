import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { CoworkerAvatar } from 'ui/components/CoworkerAvatar';
import TextAreaInputField from 'ui/components/forms/TextAreaInputField';
import InputField from 'ui/components/forms/InputField';
import asForm from 'ui/components/forms/asForm';
import LoadingSpinner from 'ui/components/LoadingSpinner';
import NxMentionsInput from 'ui/components/forms/NxMentionsInput/NxMentionsInput';

export const BlogPostCommentsForm = asForm(
  withCustomComponent('BlogPostCommentsForm')(
    inject(
      'appStore',
      'authStore',
      'blogStore'
    )(
      withRouter(
        observer(
          ({
            newComment,
            updateProperty,
            authStore,
            router,
            appStore,
            blogStore,
            componentName,
          }) => {
            const { t } = useTranslation();
            const { isSavingNewComment } = blogStore;
            const { isLoggedIn, customer } = authStore;

            const blogPost = blogStore.blogPostPage.BlogPost;

            const saveNewMessage = () => {
              blogStore
                .saveNewComment({ blog_id: blogPost.Id })
                .then(() => blogStore.loadBlogPostPage(blogPost.Id))
                .catch((err) => {
                  appStore.setPopMessage(
                    t('Sorry, we could post this comment. {{err}}', { err })
                  );
                });
            };

            return (
              <>
                {blogPost.AllowComments && (
                  <div
                    data-component-name={componentName}
                    className="blog-post-comments-form"
                  >
                    {!isLoggedIn && (
                      <Link
                        href={{
                          pathname: routes.login,
                          query: {
                            returnurl: encodeURIComponent(
                              routes.post_by_id(blogPost)
                            ),
                          },
                        }}
                      >
                        <a className="btn btn-xs btn-outline mt-24">
                          {t('Sign in or join to post new comments')}
                        </a>
                      </Link>
                    )}
                    {isLoggedIn && (
                      <form noValidate className="d-flex mt-24">
                        <div className="pr-8">
                          <CoworkerAvatar
                            size={80}
                            link
                            imgClassName={'avatar avatar--40'}
                            coworker_id={customer.Id}
                          />
                        </div>
                        <div className="w-100">
                          <div>
                            <div className="row">
                              <div className="form-group col-12 ">
                                <InputField
                                  id="newComment-title"
                                  label={t('Subject')}
                                  name="Title"
                                  placeholder={t(
                                    'Keep it relevant, less is more...'
                                  )}
                                  errors={
                                    blogStore.newCommentValidation.errors.Title
                                  }
                                  value={newComment.Title}
                                  onChange={updateProperty}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col-12 ">
                                <NxMentionsInput
                                  label={t('Message')}
                                  name="Comment"
                                  placeholder={t(
                                    t('Type your comment here...')
                                  )}
                                  errors={
                                    blogStore.newCommentValidation.errors
                                      .Comment
                                  }
                                  value={newComment.Comment}
                                  onChange={updateProperty}
                                  onKeyDown={(ev) => {
                                    if (
                                      (ev.keyCode == 10 || ev.keyCode == 13) &&
                                      (ev.ctrlKey || ev.metaKey)
                                    ) {
                                      if (isSavingNewComment) return;
                                      ev.preventDefault();
                                      saveNewMessage();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <button
                            className="btn"
                            disabled={isSavingNewComment}
                            onClick={(ev) => {
                              if (isSavingNewComment) return;
                              ev.preventDefault();
                              saveNewMessage();
                              ev.target.blur();
                            }}
                          >
                            {isSavingNewComment ? (
                              <LoadingSpinner />
                            ) : (
                              t('Comment')
                            )}
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                )}
              </>
            );
          }
        )
      )
    )
  ),
  'newComment'
);
