import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import { linkTo } from 'env/utils/strings';
import withCustomComponent from 'ui/components/withCustomComponent';
export const DirectoryFilters = withCustomComponent('DirectoryFilters')(
  inject(
    'appStore',
    'communityStore'
  )(
    withRouter(
      observer(({ topTags, customFields, appStore, componentName, router }) => {
        const { t } = useTranslation();
        const { configuration } = appStore;
        const filtered =
          router.query.onlyNew ||
          router.query.onlyAdmins ||
          router.query.onlyCheckedIn;

        return (
          <div className="filters" data-component-name={componentName}>
            <ul className="nav filters__nav">
              <li className={filtered ? 'active' : ''}>
                <a
                  href="#"
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {[
                    router.query.onlyNew && t('New Members'),
                    router.query.onlyAdmins && t('Staff'),
                    router.query.onlyCheckedIn && t('Checked in'),
                  ]
                    .filter((x) => x)
                    .join(', ')}
                  {!filtered && t('All Members')}{' '}
                  <span className="caret"></span>
                </a>
                <ul className="dropdown-menu" role="menu">
                  <li
                    className={!filtered ? 'active' : ''}
                    role="menuitem"
                    tabIndex="-1"
                  >
                    <Link href={routes.directory_members}>
                      <a>{t('All members')}</a>
                    </Link>
                  </li>
                  <li
                    className={router.query.onlyNew ? 'active' : ''}
                    role="menuitem"
                    tabIndex="-1"
                  >
                    <Link
                      href={linkTo(router, {
                        page: 1,
                        onlyNew: router.query.onlyNew ? null : 'true',
                      })}
                    >
                      <a>{t('New Members')}</a>
                    </Link>
                  </li>
                  <li
                    className={router.query.onlyAdmins ? 'active' : ''}
                    role="menuitem"
                    tabIndex="-1"
                  >
                    <Link
                      href={linkTo(router, {
                        page: 1,
                        onlyAdmins: router.query.onlyAdmins ? null : 'true',
                      })}
                    >
                      <a>{t('Staff')}</a>
                    </Link>
                  </li>
                  <li
                    className={router.query.onlyCheckedIn ? 'active' : ''}
                    role="menuitem"
                    tabIndex="-1"
                  >
                    <Link
                      href={linkTo(router, {
                        page: 1,
                        onlyCheckedIn: router.query.onlyCheckedIn
                          ? null
                          : 'true',
                      })}
                    >
                      <a>{t('Checked in')}</a>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className={router.query.tag ? 'active' : ''}>
                <a
                  href="#"
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  {t('Skills')} {router.query.tag && ': ' + router.query.tag}
                  <span className="caret"></span>
                </a>
                <ul className="dropdown-menu" role="menu">
                  <li
                    className={!router.query.tag ? 'active' : ''}
                    role="menuitem"
                    tabIndex="-1"
                  >
                    <Link href={routes.directory_members}>
                      <a>{t('Show all')}</a>
                    </Link>
                  </li>

                  {topTags.map(
                    (tag, i) =>
                      tag.Tag &&
                      tag.Tag !== 'null' && (
                        <li
                          key={i}
                          className={
                            router.query.tag === tag.Tag ? 'active' : ''
                          }
                          role="menuitem"
                          tabIndex="-1"
                        >
                          <Link
                            href={linkTo(router, { page: 1, tag: tag.Tag })}
                          >
                            <a
                              data-selected={
                                router.query.tag === tag.Tag ? 'true' : false
                              }
                              data-tag={tag.Tag}
                              className="tag-filter-link"
                            >
                              {tag.Tag} ({tag.Count})
                            </a>
                          </Link>
                        </li>
                      )
                  )}

                  <li role="separator" className="divider"></li>
                  {/* <li role="menuitem" tabIndex="-1">
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#modal-industries"
                  >
                    {t('Select additional skills')}
                  </a>
                </li> */}
                </ul>
              </li>
              {customFields.map((cf, index) => (
                <li className={router.query[cf.FieldName] ? 'active' : ''}>
                  <a
                    href="#"
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {cf.NameInSearch}

                    {router.query[cf.FieldName] &&
                      ': ' +
                        (cf.FieldType === 'Boolean'
                          ? router.query[cf.FieldName] == 'True'
                            ? 'Yes'
                            : ''
                          : router.query[cf.FieldName])}
                    <span className="caret"></span>
                  </a>
                  <ul className="dropdown-menu" role="menu">
                    {cf.FieldType === 'Dropdown' && (
                      <>
                        <li
                          className={
                            !router.query[cf.FieldName] ? 'active' : ''
                          }
                          role="menuitem"
                          tabIndex="-1"
                        >
                          <Link
                            href={linkTo(
                              router,
                              (() => {
                                let obj = { page: 1 };
                                obj[cf.FieldName] = null;
                                return obj;
                              })()
                            )}
                          >
                            <a
                              className="custom-filter-link"
                              data-cmindex={cf.CustomFieldIndex}
                            >
                              {t('Show all')}
                            </a>
                          </Link>
                        </li>
                        {cf.AvailableOptionArray.map((opt, j) => (
                          <li
                            key={j}
                            className={
                              router.query[cf.FieldName] === opt ? 'active' : ''
                            }
                            role="menuitem"
                            tabIndex="-1"
                          >
                            <Link
                              href={linkTo(
                                router,
                                (() => {
                                  let obj = { page: 1 };
                                  obj[cf.FieldName] = opt;
                                  return obj;
                                })()
                              )}
                            >
                              <a
                                className="custom-filter-link"
                                data-selected={
                                  router.query[cf.FieldName] === opt
                                    ? 'true'
                                    : 'false'
                                }
                                data-value={opt}
                                data-cmindex={cf.CustomFieldIndex}
                              >
                                {opt}
                              </a>
                            </Link>
                          </li>
                        ))}
                      </>
                    )}
                    {cf.FieldType === 'Boolean' && (
                      <>
                        <li
                          className={
                            !router.query[cf.FieldName] ? 'active' : ''
                          }
                          role="menuitem"
                          tabIndex="-1"
                        >
                          <Link
                            href={linkTo(
                              router,
                              (() => {
                                let obj = { page: 1 };
                                obj[cf.FieldName] = null;
                                return obj;
                              })()
                            )}
                          >
                            <a
                              className="custom-filter-link"
                              data-cmindex="{{ cf.CustomFieldIndex }}"
                              href="#"
                            >
                              {t('Show all')}
                            </a>
                          </Link>
                        </li>
                        {cf.AvailableOptionArray.map((opt, j) => {
                          <li
                            key={j}
                            className={
                              router.query[cf.FieldName] === opt ? 'active' : ''
                            }
                            role="menuitem"
                            tabIndex="-1"
                          >
                            <a
                              className="custom-filter-link"
                              data-selected={
                                router.query[cf.FieldName] === opt
                                  ? 'true'
                                  : 'false'
                              }
                              href="#"
                              data-value="True"
                              data-cmindex={cf.CustomFieldIndex}
                            >
                              {t('Yes')}
                            </a>
                          </li>;
                        })}
                      </>
                    )}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        );
      })
    )
  )
);
