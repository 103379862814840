import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';
import { linkTo } from 'env/utils/strings';

export const BlogPageZeroCase = withCustomComponent('BlogPageZeroCase')(
  inject(
    'appStore',
    'blogStore'
  )(
    withRouter(
      observer(({ router, blogStore, componentName }) => {
        const { t } = useTranslation();
        const { blogPosts } = blogStore;
        return (
          blogPosts.BlogPosts.length == 0 && (
            <div
              data-component-name={componentName}
              className="text-center pt-32"
            >
              <i className="icon-ticket fs-64 text-gray-600"></i>
              <h3 className="mt-12 mb-24">{t('There are no articles to display')}</h3>
            </div>            
          )
        );
      })
    )
  )
);
