import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'next/router';
import withCustomComponent from 'ui/components/withCustomComponent';
import Head from 'next/head';

@withCustomComponent('FullPageLayout')
@withRouter
@withTranslation()
@inject('appStore', 'authStore')
@observer
class FullPageLayout extends Component {
  render() {
    const {t, router, appStore, title, description, topSection} = this.props;
    const {business} = appStore;
    return (
      <div data-component-name={this.props.componentName}>
        <Head>
          <meta charSet="utf-8"/>
          <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1"/>
          <title>
            {title} - {t(business.Name)}
          </title>
        </Head>

        <header className="site-header site-header--lo">
          <div className="site-header__content">
            <nav className="navbar container">
              <a className="navbar-brand" href="/">
                <img
                  src={`${business.NativeHomeUrlWithLanguage}/business/getlogo?h=96&mode=pad`}
                  alt={t(business.Name)}
                />
                <div className="spacer d-none d-md-inline-block"></div>
                <span className="d-none d-md-inline">{t(business.Name)}</span>
              </a>
              <a
                href="#"
                onClick={() => router.back()}
                className="btn btn-single-icon btn-white"
              >
                <i className="icon-close-large fs-18"></i>
              </a>
            </nav>
          </div>
        </header>

        <main className="content content--fullpage" role="main">
          {(title || description || topSection) && (
            <>
              <div className="portal-title-wrapper">
                <div className="container portal-title-container">
                  {title && <h1 className="portal-title">{title}</h1>}
                </div>
              </div>
              <div className="container">
                {description && (
                  <p>{description}</p>
                )}
                {topSection}
              </div>
            </>
          )}
          <div className="container">{this.props.children}</div>
        </main>
      </div>
    );
  }
}

export default FullPageLayout;
