import React from 'react';
import Link from 'next/link';
import { routes } from 'env/routes';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';
import withCustomComponent from 'ui/components/withCustomComponent';

export const CommunityBoardFilters = withCustomComponent(
  'CommunityBoardFilters'
)(
  inject('appStore')(
    withRouter(
      observer(({ componentName, group, groups = [], router }) => {
        const { t } = useTranslation();

        return (
          <div
            data-component-name={componentName}
            className={`community-board-filters d-block ${
              groups.length > 3 ? 'd-md-flex' : 'd-xl-flex'
            } align-items-center justify-content-start mt-20 mt-lg-32 mb-24`}
          >
            <div className="list-inline-4 w-auto">
              {groups.length > 3 ? (
                <>
                  <div className="dropdown">
                    <button
                      className={`btn btn-gray-light btn-md-sm -xs dropdown-toggle mr-0 mr-lg-4 mr-xl-0 ${
                        router.pathname == routes.forum ? 'active-bg-black' : ''
                      } ${group ? 'active-bg-black' : ''}`}
                      data-toggle="dropdown"
                    >
                      <span>
                        {group ? <>{group.Name}</> : <>{t('All messages')}</>}
                      </span>
                      <i className="caret"></i>
                    </button>
                    <div
                      className="dropdown-menu"
                      style={{ maxHeight: 250, overflowY: 'auto' }}
                    >
                      <Link href={routes.forum}>
                        <a
                          className={`dropdown-item ${
                            router.pathname == routes.forum ? 'active' : ''
                          }`}
                        >
                          {t('All messages')}
                        </a>
                      </Link>
                      {groups.map((g, i) => (
                        <Link
                          href={{
                            pathname: routes.forum_by_group('[group_id]'),
                          }}
                          as={{
                            pathname: routes.forum_by_group(g.Id),
                          }}
                          key={i}
                        >
                          <a
                            className={`dropdown-item ${
                              group && g.Id == group.Id ? 'active' : ''
                            }`}
                          >
                            {g.Name}
                          </a>
                        </Link>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Link href={routes.forum}>
                    <a
                      className={`btn btn-gray-light btn-md-sm -xs ${
                        router.pathname == routes.forum ? 'active-bg-black' : ''
                      }`}
                    >
                      {t('All messages')}
                    </a>
                  </Link>
                  {groups.map((g, i) => (
                    <Link
                      href={{
                        pathname: routes.forum_by_group('[group_id]'),
                      }}
                      as={{
                        pathname: routes.forum_by_group(g.Id),
                      }}
                      key={i}
                    >
                      <a
                        className={`btn btn-gray-light btn-md-sm -xs ${
                          group && g.Id == group.Id ? 'active-bg-black' : ''
                        }`}
                      >
                        {g.Name}
                      </a>
                    </Link>
                  ))}
                </>
              )}
            </div>

            <div className="d-none d-xl-block">
              <div className="btn-spacer mx-16"></div>
            </div>
            <div
              className={`d-block ${
                groups.length > 3 ? 'd-md-none' : 'd-xl-none'
              }`}
            >
              <div className="mt-4"></div>
            </div>

            <div className="list-inline-4 w-auto">
              {/* BB-TODO: Link to threads that current user is folowing */}
              {/* <Link
                  href="#"
                >
                  <a className="btn btn-icon btn-gray-light btn-md-sm -xs">
                    <i className="icon-bookmark-tabs"></i>
                    {t('Following')}
                  </a>
                </Link> */}
              <Link
                href={{
                  pathname: routes.my_threads,
                  query: router.query.query,
                }}
              >
                <a
                  className={`btn btn-icon btn-gray-light btn-md-sm -xs ${
                    router.pathname == routes.my_threads
                      ? 'active-bg-black'
                      : ''
                  }`}
                >
                  <i className="icon-my-discussion-board"></i>
                  {t('My Conversations')}
                </a>
              </Link>
              <Link
                href={{
                  pathname: routes.private_threads,
                  query: router.query.query,
                }}
              >
                <a
                  className={`btn btn-icon btn-gray-light btn-md-sm -xs ${
                    router.pathname == routes.private_threads
                      ? 'active-bg-black'
                      : ''
                  }`}
                >
                  <i className="icon-inbox"></i>
                  {t('My Inbox')}
                </a>
              </Link>
            </div>
          </div>
        );
      })
    )
  )
);
