export const CancelContractSteps = (activeIndex, t) => {
  return [
    {
      title: t('Cancellation feedback'),
      active: activeIndex == 1,
    },
    {
      title: t('Confirmation'),
      active: activeIndex == 2,
    },
  ];
};
