/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { observer } from 'mobx-react';
import Link from 'next/link';
import withCustomComponent from 'ui/components/withCustomComponent';
@withCustomComponent('PlanTermsModal')
@withTranslation()
@observer
class PlanTermsModal extends Component {

  componentDidMount() {
    window.$('#terms-conditions-modal').modal({
      backdrop: 'static',
      keyboard: false,
      show: true,
    });
  }

  componentWillUnmount() {
    window.$('#terms-conditions-modal').modal('hide');
  }

  render() {
    const { t } = this.props;
    return (
      <div
        data-component-name={this.props.componentName}
        className="modal modal-terms-conditions"
        id="terms-conditions-modal"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="mb-0">{t('Terms and Conditions')}</h4>
            </div>
            <div className="modal-body">
              <ReactMarkdown source={this.props.terms} />
            </div>
            <div className="modal-footer">
              <a
                onClick={(ev) => {
                  ev.preventDefault();
                  this.props.close();
                }}
                href="#"
                className="btn"
              >
                {t('Close')}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PlanTermsModal;
