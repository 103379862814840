import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { routes } from 'env/routes';
import Link from 'next/link';
import withCustomComponent from 'ui/components/withCustomComponent';
//import { BookingFeaturesList } from 'ui/_pages/home/components/BookingsWidget/BookingFeaturesList';

@withCustomComponent('CardBookingsBooked')
@withTranslation()
@inject('appStore', 'authStore', 'bookingsStore')
@observer
class CardBookingsBooked extends Component {
  static propTypes = {
    booking: PropTypes.object.isRequired,
    index: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      resourceProducts: [],
    };
  }

  componentDidMount() {
    const { booking } = this.props;
    if (booking?.ResourceId) {
      // TODO: this causes n+1 requests, ideally we get the booking product information directly with the booking listings
      this.loadResourceProducts(booking).then((data) =>
        this.setState({ resourceProducts: data.filter((p) => p.Quantity > 0) })
      );
    }

    const $ = window.$;
    if ($('[data-toggle="tooltip"]').length > 0) {
      $('[data-toggle="tooltip"]').tooltip({
        html: true,
      });
    }
  }

  loadResourceProducts = (booking) => {
    const { bookingsStore } = this.props;
    return bookingsStore.loadResourceProducts({
      bookingId: booking.Id,
      resourceId: booking.ResourceId,
    });
  };

  render() {
    const {
      t,
      team,
      appStore,
      authStore,
      bookingsStore,
      booking,
      index,
      componentName,
    } = this.props;
    const { business } = appStore;
    const { customer } = authStore;

    const { resourceProducts } = this.state;

    let highlightColor = bookingsStore.getResourceColor(booking.Resource.Id);
    if (index == 1) highlightColor = 'yellow';
    if (index == 2) highlightColor = 'red';

    return (
      <div
        className="card card-bookings-booked"
        data-component-name={componentName}
      >
        <div className="card-bookings-booked__header">
          <div className="title-wrapper">
            <div className={`higlight bg-${highlightColor}`}></div>
            <div className="title">
              <Link
                href={routes.booking_by_id('[booking_id]')}
                as={routes.booking_by_id(booking.Id)}
              >
                <a>
                  <h4>
                    {booking.ResourceName}{' '}
                    {`${
                      booking.FloorPlanDeskId &&
                      booking.ResourceName != booking.FloorPlanDeskName
                        ? `(${booking.FloorPlanDeskName})`
                        : ''
                    }`}
                  </h4>
                </a>
              </Link>
              <ul className="list-tags">
                <li>{booking.Resource.ResourceTypeName}</li>
                <li>{booking.Resource.GroupName}</li>
              </ul>
            </div>
          </div>
          <div className="dropdown">
            <button
              className="btn btn-single-icon btn-white-black dropdown-toggle"
              data-toggle="dropdown"
            >
              <i className="icon-more"></i>
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              {customer.Id == booking.CoworkerId && (
                <Link
                  href={routes.booking_by_id('[booking_id]')}
                  as={routes.booking_by_id(booking.Id)}
                >
                  <a className="dropdown-item">{t('Change booking')}</a>
                </Link>
              )}
              <Link href={routes.calendar_by_resource_id(booking.Resource.Id)}>
                <a className="dropdown-item">{t('View in calendar')}</a>
              </Link>
              {booking.FloorPlanDeskId && (
                <Link
                  href={{
                    pathname: routes.floorplan_by_id('[floorplan_id]'),
                    query: { item_id: booking.FloorPlanDeskId },
                  }}
                  as={{
                    pathname: routes.floorplan_by_id({
                      Id: booking.FloorPlanDesk.FloorPlanId,
                      Name: booking.FloorPlanDeskName,
                    }),
                    query: { item_id: booking.FloorPlanDeskId },
                  }}
                >
                  <a className="dropdown-item">{t('View in floor plan')}</a>
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="card-bookings-booked__content">
          <time>
            <span>{t('Start Time')}</span>
            {moment(booking.FromTime).format('lll')}
          </time>
          <i className="icon-next-date"></i>
          <time>
            <span>{t('End Time')}</span>
            {moment(booking.ToTime).format('lll')}
          </time>
        </div>
        <div className="card-bookings-booked__footer">
          <div className="d-block">
            {resourceProducts.length > 0 && (
              <div className="resources">
                <i className="icon-add-small"></i>
                {resourceProducts.map((p, i) => (
                  <span key={i}>{p.ProductName}</span>
                ))}
              </div>
            )}
            {team && (
              <div
                className={`d-flex align-items-center justify-content-start ${
                  resourceProducts.length > 0 ? 'mt-20' : ''
                }`}
              >
                <img
                  className="avatar mr-8"
                  src={`${business.NativeHomeUrlWithLanguage}/coworker/getavatar/${booking.CoworkerId}?cache=${booking.UpdatedOn}&h=256&w=256&noavatar=%2Fcontent%2Fthemes%2Fpublic%2Fdos%2Fimg%2Favatar-default.png`}
                />
                <span className="fs-14 text-gray-700">
                  {booking.Coworker.FullName}
                </span>
              </div>
            )}
          </div>
          <div
            className={`status ${booking.Tentative ? 'pending' : 'confirmed'}`}
            data-toggle="tooltip"
            title={`${
              booking.Tentative
                ? `${t('This booking has not yet been confirmed!')}`
                : `${t('This booking has been confirmed!')}`
            }`}
          >
            {booking.Tentative ? (
              <>
                <i className="icon-pending"></i>
                {t('Pending')}
              </>
            ) : (
              <>
                <i className="icon-confimred"></i>
                {t('Confirmed')}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CardBookingsBooked;
