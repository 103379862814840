import React, {Component, useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {withTranslation, Trans} from 'react-i18next';
import {withCustomComponent} from 'ui/components/withCustomComponent';
import SignupPageLayout from 'ui/layouts/SignupPageLayout';
import {SignupForm} from 'ui/_pages/signup/SignupForm';
import {withRouter} from 'next/router';
import LoadingLayout from 'ui/layouts/LoadingLayout';
import {routes} from 'env/routes';
import qs from 'query-string';
import FullPageLayout from '../../ui/layouts/FullPageLayout';
import Head from 'next/head';
import LoadingSpinner from '../../ui/components/LoadingSpinner';
import Link from 'next/link';

let SignupPage = props => {
  const {
    router,
    t,
    authStore,
    appStore,
  } = props;
  const {customer, isLoggedIn, hasLoadedSignupPage} = authStore;
  const {business, configuration} = appStore;

  useEffect(() => {
    const query = qs.parse(location.search);
    const signupToken = query.t;
    const {tariff_guid, team_guid} = query;

    if (isLoggedIn) {
      authStore.loadCustomFields();
    } else {
      authStore.loadSignupCustomFields({tariff_guid, team_guid, signupToken});
      if (!configuration['Members.CanSignup']) {
        if (!router.query.returnurl) {
          router.push(routes.contact);
          return;
        }
      }

      authStore.createCustomer({
        tourDate: null,
        business: appStore.business,
        newsletter: configuration['Newsletter.DefaultOptIn'] == 'true',
        createPassword: !configuration['Signup.AskForPassword'],
      });
    }

    authStore.loadSignupPage({
      t: signupToken,
      tariff_guid,
      team_guid,
    }).then((data) => {
      console.log('Load sign up page');
      console.log(data);
      if (!isLoggedIn && !router.query.returnurl) {
        if (data.RedirectTo?.indexOf('missingInvite') > -1)
          router.push(routes.contact);
      }
    }).catch((err) => {
      appStore.setPopMessage(
          t('Sorry, we could not load this page. {{err}}', {err}),
      );
    });
  }, []);

  if (!(customer && hasLoadedSignupPage)) {
    return <LoadingLayout/>;
  }

  return (
      <main
          data-component-name={props.componentName}
          className="content content--signup signup py-0"
          role="main"
      >
        <Head>
          <title>
            {t('Sign up')} - {t(business?.Name)}
          </title>
        </Head>

        <header className="site-header site-header--lo">
          <div className="site-header__content">
            <nav className="navbar container">
              <a className="navbar-brand" href="/">
                <img
                    src={`${business.NativeHomeUrlWithLanguage}/business/getlogo?h=96&mode=pad`}
                    alt={t(business.Name)}
                    height="48"
                />
                <div className="spacer d-none d-md-inline-block"></div>
                <span className="d-none d-md-inline">{t(business.Name)}</span>
              </a>
              <a href="/" className="btn btn-single-icon btn-white">
                <i className="icon-close-large fs-18"></i>
              </a>
            </nav>
          </div>
        </header>
        <div
            className="signup__content"
            style={{
              backgroundImage: `url(${business.NativeHomeUrlWithLanguage}/business/GetBannerFor?id=${business.Id}&w=1920&h=1080&mode=max)`,
            }}
        >
          <div className="card signup__card">
            <header className="signup__card__header">
              <h4>{t('Sign up to {{Name}}', {Name: t(business.Name)})}</h4>
            </header>
            <div className="signup__card__content">
              <SignupForm
                  returnUrl={router.query.returnurl ?? routes.home}
              />
            </div>
          </div>
        </div>
      </main>
      /*<SignupPageLayout componentName={props.componentName}>
        <SignupForm/>
      </SignupPageLayout>*/
  );
};

export default SignupPage = withCustomComponent('SignupPage')(
    withRouter(
        withTranslation()(
            inject('appStore', 'authStore')(
                observer(SignupPage),
            ),
        ),
    ),
);
