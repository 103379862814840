import StoreBase from './storeBase';
import {action, observable, observe, toJS} from 'mobx';
import {isServer} from 'env/ssr/ServerSideRenderManager';

class PlanStore extends StoreBase {

  constructor({data, host} = {}) {
    super({data, host});

    if (!isServer() && window.localStorage) {
      try {
        this.plan = JSON.parse(window.localStorage.getItem('nx-plan')) || {};
      } catch (ex) {
        this.plan = {};
      }
    }

    if (this.plan) {
      observe(this.plan,
        () => {
          this.save();
        },
      );
    }
  }

  @observable plan = {};

  save() {
    window.localStorage.setItem('nx-plan', JSON.stringify(toJS(this.plan)));
  }

  @action
  setPlan({Id, UniqueId}) {
    this.plan.Id = Id;
    this.plan.UniqueId = UniqueId;
  }

}

export default PlanStore;